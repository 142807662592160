import axios from '~/libs/http/axios';

const { baseURL } = axios.defaults;

const accountTeam = {
  companyList: (params = {}) => axios.get(`${baseURL}/api/profile/team/`, { params }),
  addCompany: (options = {}) => axios.post(`${baseURL}/api/profile/team/`, options),
  deleteCompany: (params = {}) => axios.delete(`${baseURL}/api/profile/team/`, { params }),
  editCompany: (options = {}) => axios.patch(`${baseURL}/api/profile/team/`, options),
};

export default accountTeam;
