import { useState } from 'react';

const useLoading = (initialState = false) => {
  const [state, setState] = useState(initialState);

  const loadingCallback = async fn => {
    setState(true);

    try {
      const data = await fn;

      setState(false);
      return Promise.resolve(data);
    } catch (err) {
      setState(false);
      return Promise.reject(err);
    }
  };

  return [state, loadingCallback];
};

export default useLoading;
