import React from 'react';
import { Input as AntdInput, InputNumber } from 'antd';
import { withDebounceChange } from '~/hoc';

const Input = withDebounceChange()(
  ({ debounce, value, scopeValue, setState, onDebounceChange, onChange, ...props }) => {
    const onChangeHandler = ({ target }) => onDebounceChange(target.value);

    return <AntdInput {...props} value={scopeValue} onChange={onChangeHandler} />;
  },
);

Input.Search = withDebounceChange()(
  ({ debounce, value, scopeValue, setState, onDebounceChange, onChange, ...props }) => {
    const onChangeHandler = ({ target }) => onDebounceChange(target.value);

    return <AntdInput.Search {...props} value={scopeValue} onChange={onChangeHandler} />;
  },
);

Input.Number = withDebounceChange()(
  ({ debounce, value, scopeValue, setState, onDebounceChange, onChange, ...props }) => {
    const onChangeHandler = number => onDebounceChange(number);

    return (
      <InputNumber
        {...props}
        formatter={v => `${v}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        value={scopeValue}
        onChange={onChangeHandler}
      />
    );
  },
);

export default Input;
