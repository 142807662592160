import axios from '~/libs/http/axios';

const { baseURL } = axios.defaults;

const managementInvoices = {
  invoice: (params = {}) =>
    axios.request({
      url: `${baseURL}/api/management/billing/invoice`,
      method: 'GET',
      responseType: 'blob',
      params,
    }),
  list: (params = {}) => axios.get(`${baseURL}/api/management/billing/wire`, { params }),
  approve: (option = {}) => axios.post(`${baseURL}/api/management/billing/wire`, option),
  delete: (params = {}) => axios.delete(`${baseURL}/api/management/billing/wire`, { params }),
};

export default managementInvoices;
