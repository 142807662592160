import React from 'react';
import env from '@beam-australia/react-env';
import { useHistory } from 'react-router-dom';
import { Popover } from 'antd';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { deleteToken } from '~/store/actions/AuthenticationActions';
import { useInjectIntl } from '~/libs/localization';
import { Confirmation, PersonCard } from '~/components/ui';
import { ProfileLinks } from '~/components/profile';
import { Icon } from '~/components/ui/Icon/Icon';

import '~/assets/scss/components/ui/_ProfileSection.scss';

const ProfileSection = () => {
  const { t } = useInjectIntl();
  const { params } = useSelector(({ authentication }) => ({ params: authentication.params }), shallowEqual);
  const dispatch = useDispatch();
  const history = useHistory();

  const onLogout = () => {
    dispatch(deleteToken());
    return history.push('/');
  };

  const photo = params.photo ? `${env('API_URL') || ''}/${params.photo || ''}` : null;

  return (
    <Popover
      content={
        <>
          <div className="profile-section-item">
            <PersonCard
              title={`${params.first_name || ''}${params.last_name ? ` ${params.last_name}` : ''}`}
              desc={params.job_title}
              photo={photo}
            />
          </div>

          <div className="profile-section-item small">
            <ul>
              <ProfileLinks type="profile-list" />
            </ul>
          </div>

          <div className="profile-section-item small">
            <ul className="logout">
              <li role="presentation">
                <div className="svg-container">
                  <Icon type="user-section-logout" />
                </div>

                <Confirmation type="logout" onConfirm={onLogout}>
                  <span>{t('profile.logout')}</span>
                </Confirmation>
              </li>
            </ul>
          </div>
        </>
      }
      trigger="click"
      placement="bottomRight"
      overlayClassName="zh-fixed-tooltip profile-section-tooltip"
    >
      <li className="user-svg active">
        <Icon type="user-normal" />
        <Icon type="user-active" />
      </li>
    </Popover>
  );
};

export default ProfileSection;
