import React, { useEffect, useState, useCallback } from 'react';
import { Menu, DatePicker, Tree, Popover } from 'antd';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useStateHandlers } from '~/hooks';
import { useInjectIntl } from '~/libs/localization';
import { Loader } from '~/components/ui';
import { Icon } from '~/components/ui/Icon/Icon';
import { saveLastOpenItem } from '~/store/actions/MonitoringSidebarActions';

import '~/assets/scss/components/_SidebarMonitoring.scss';

const SidebarMonitoring = ({
  showMonitoringNotifications,
  showListSettings,
  showModalAddCounterparty,
  dataTypes,
  loadingMonitoringList,
  dataMonitoringList,
  // dataUsersMonitoringLists,
  onFetchCompaniesFromList,
  onFilterNotifications,
  onChangeDateFrom,
  onChangeDateTo,
  onSetListMonitoringId,
  onChangePage,
  onUpdatePage,
  onCurrentSubMenu,
  onRefreshCompany,
  clearId,
  idProfile,
  setListType,
  mainMenuItem,
}) => {
  const { t } = useInjectIntl();
  const dispatch = useDispatch();
  const store = useSelector(({ monitoringSidebar }) => ({ monitoringSidebar }), shallowEqual);
  const [tree, setTree] = useState({});
  const [checkedKeysTree, setCheckedKeysTree] = useState([]);
  // const [newDataUsersMonitoringLists, setNewDataUsersMonitoringLists] = useState([]);

  const rootSubmenuKeys = ['companyMonitoring', 'updatesFilterMenu', 'userCompanies'];

  const [state, setState] = useStateHandlers({
    openKeys: ['companyMonitoring'],
  });

  useEffect(() => {
    if (store.monitoringSidebar.id && idProfile) {
      onGetCompaniesFromList(store.monitoringSidebar.id, idProfile);
    }
  }, [store.monitoringSidebar.id, idProfile]);

  useEffect(() => {
    onCurrentSubMenu(state.openKeys);
    if (state.openKeys[0] === 'companyMonitoring') {
      onUpdatePage();
    }
  }, [state]);

  useEffect(() => {
    if (dataTypes.length !== 0) {
      generateTree();
    }
  }, [dataTypes]);

  // useEffect(() => {
  //   if (dataUsersMonitoringLists.length > 0) {
  //     const newArr = dataUsersMonitoringLists.filter(arr => arr.lists_count > 0);
  //     setNewDataUsersMonitoringLists(newArr);
  //   }
  // }, [dataUsersMonitoringLists]);

  const onOpenChangeMenu = openKeys => {
    const latestOpenKey = openKeys.find(key => !state.openKeys.includes(key));

    if (!rootSubmenuKeys.includes(latestOpenKey)) {
      setState({ openKeys });
    } else {
      setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }

    showMonitoringNotifications(openKeys.includes('updatesFilterMenu'));
  };

  const onCheck = async checkedKeys => {
    setCheckedKeysTree(checkedKeys);
    await onChangePage(1);
    onFilterNotifications({ checkedKeys });
  };

  const generateTree = useCallback(() => {
    const parentData = [];
    // eslint-disable-next-line array-callback-return
    Object.keys(dataTypes).map(item => {
      const children = [];
      // eslint-disable-next-line array-callback-return
      dataTypes[item].map(itemChildren => {
        const childrenItem = {
          title: t(`monitorig.types.${item}.${itemChildren}`),
          key: itemChildren,
        };
        children.push(childrenItem);
      });

      const data = {
        title: t(`monitorig.types.${item}`),
        key: item,
        children,
      };
      parentData.push(data);
    });

    setTree(parentData);
  }, [dataTypes]);

  const onShowList = async listId => {
    await onChangePage(1);
    onSetListMonitoringId(listId || null);
    showModalAddCounterparty(true);
  };

  const onGetCompaniesFromList = async (id, userId) => {
    dispatch(saveLastOpenItem(id));
    await onChangePage(1);
    onSetListMonitoringId(id || null);
    onFetchCompaniesFromList(id, userId);
    onRefreshCompany(false);
  };

  const onAllCompanyList = async () => {
    dispatch(saveLastOpenItem(null));
    clearId(null);
    setListType('comapnies');
    // onUpdatePage();
    mainMenuItem();
    onRefreshCompany(true);
  };

  return (
    <div className="sidebar">
      <Loader loading={loadingMonitoringList}>
        <Menu
          className="messages-menu monitoring-menu"
          mode="inline"
          onOpenChange={onOpenChangeMenu}
          openKeys={state.openKeys}
        >
          <Menu.SubMenu
            title={
              <>
                <Icon type="folder" />
                {t('monitorig.sidebar.monitoredCompanies')}
              </>
            }
            key="companyMonitoring"
          >
            <Menu.Item
              className={`blue ${store.monitoringSidebar.id === null ? 'active-item' : ''}`}
              onClick={onAllCompanyList}
            >
              <div className="link-list">
                <Icon type="task-list-approve" />
                {t('monitorig.sidebar.allLists')}
              </div>
            </Menu.Item>

            {dataMonitoringList?.length > 0 &&
              dataMonitoringList.map(list => (
                <Menu.Item
                  className={`${list.color || 'blue'} ${list.id === store.monitoringSidebar.id ? 'active-item' : ''}`}
                  key={`${list.id}.${list.name}`}
                  onClick={() => onGetCompaniesFromList(list.id)}
                >
                  <div className="link-list">
                    <Icon type="unread-list-monitoring" />
                    {list.name} {list.company_counter > 0 && ` (${list.company_counter})`}
                    <div className="tools">
                      <Popover
                        placement="bottomRight"
                        content={list.description && <div className="monitoring-popover">{list.description}</div>}
                        trigger="hover"
                        overlayClassName="menu-description-popover"
                      >
                        {/* <span className="gear" onClick={() => showListSettings(true, list.id)}>
                          <Icon type="gear" />
                        </span> */}
                      </Popover>
                      <span className="plus" onClick={() => onShowList(list.id)}>
                        <Icon type="circle-plus" />
                      </span>

                      <Icon type="monitoring-arrow" />
                    </div>
                  </div>
                </Menu.Item>
              ))}

            <Menu.Item className="add-list-item">
              <div className="link-list" onClick={() => showListSettings(true)}>
                <Icon type="plus" />
                {t('monitorig.sidebar.addTheList')}
              </div>
            </Menu.Item>
          </Menu.SubMenu>

          <Menu.SubMenu
            title={
              <>
                <Icon type="calendar-clock" />
                {t('monitorig.sidebar.updates')}
                {/* <span className="tag">55</span> */}
              </>
            }
            className="updates-wrap"
            key="updatesFilterMenu"
          >
            <div className="inner-list">
              <div className="date-picker-period">
                <DatePicker placeholder={t('monitorig.dateFrom')} onChange={onChangeDateFrom} />
                <DatePicker placeholder={t('monitorig.dateTo')} onChange={onChangeDateTo} />
              </div>

              <div className="checks-wrap">
                <Tree checkable onCheck={onCheck} treeData={tree} checkedKeys={checkedKeysTree} />
              </div>
            </div>
          </Menu.SubMenu>

          {/* <Menu.SubMenu
            title={
              <>
                <Icon type="user-active" />
                {t('monitorig.sidebar.usersMonitoredCompanies')}
              </>
            }
            key="userCompanies"
          >
            {newDataUsersMonitoringLists?.length > 0 &&
              newDataUsersMonitoringLists.map(company => (
                <Menu.SubMenu
                  title={company.company_title}
                  className="company-title"
                  key={company.company_title.toLowerCase()}
                >
                  {company.users?.length > 0 &&
                    company.users.map(user =>
                      user.lists.map(list => (
                        <Menu.Item
                          className={`${list.color || 'blue'}`}
                          key={`${list.id}.${list.name.toLowerCase()}`}
                          onClick={() => onGetCompaniesFromList(list.id, list.user.id)}
                        >
                          <Popover
                            placement="right"
                            content={
                              user.first_name && (
                                <div className="monitoring-popover">
                                  {user.first_name} {user?.last_name ? user?.last_name : ''}
                                </div>
                              )
                            }
                            trigger="hover"
                            overlayClassName="menu-description-popover"
                          >
                            <div className="link-list">
                              <Icon type="unread-list-monitoring" />
                              {list.name} {list.company_counter > 0 && ` (${list.company_counter})`}
                            </div>
                          </Popover>
                        </Menu.Item>
                      )),
                    )}
                </Menu.SubMenu>
              ))}
          </Menu.SubMenu> */}
        </Menu>
      </Loader>
    </div>
  );
};

export default SidebarMonitoring;
