import React, { Suspense, useRef } from 'react';
import { Route as PublicRoute } from 'react-router-dom';
import { routes, PrivateRoute } from '~/libs/router';
import { SuspenseFallback } from '~/components/suspense';
import { Loader } from '~/components/ui';
import NotFoundPage from '~/pages/NotFoundPage';

const Route = ({ isPublic, loading, component: Component, setIsNotFound, ...route }) => {
  // change the variable to test suspense view for route
  const testSuspense = false;
  if (testSuspense) {
    return <SuspenseFallback type={route.suspense} />;
  }

  if (isPublic) {
    return (
      <PublicRoute
        {...route}
        render={routeProps => {
          setIsNotFound();

          return (
            <Loader.Screen loading={loading}>
              <Suspense fallback={<SuspenseFallback type={route.suspense} />}>
                <Component {...routeProps} />
              </Suspense>
            </Loader.Screen>
          );
        }}
      />
    );
  }

  return <PrivateRoute {...route} loading={loading} component={Component} setIsNotFound={setIsNotFound} />;
};

const Router = ({ loading, ...props }) => {
  const isNotFound = useRef(true);

  const setIsNotFound = () => (isNotFound.current = false);

  return (
    <>
      {routes.map(({ name, ...route }) => (
        <Route key={name} loading={loading} setIsNotFound={setIsNotFound} {...route} />
      ))}

      {isNotFound.current && !loading && (
        <PublicRoute key="NoMatchPage" render={routeProps => <NotFoundPage isNotFound {...routeProps} />} />
      )}
    </>
  );
};

export default Router;
