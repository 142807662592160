import React from 'react';

const Filter = ({ type }) => {
  if (type === 'visit') {
    return <Filter.Clock />;
  }

  if (type === 'alert') {
    return <Filter.Notification />;
  }

  if (type === 'disconnection') {
    return <Filter.Folder />;
  }

  if (type === 'export') {
    return <Filter.Export />;
  }

  if (type === 'lists') {
    return <Filter.List />;
  }

  if (type === 'search') {
    return <Filter.Search />;
  }

  return <Filter.Folder />;
};

Filter.Folder = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24.15px" height="21.15px">
    <path d="M21.676,20.050 L12.050,20.050 L2.424,20.050 C1.666,20.050 1.050,19.442 1.050,18.693 L1.050,5.122 L1.050,2.407 C1.050,1.657 1.666,1.050 2.424,1.050 L12.050,1.050 C12.809,1.050 13.425,1.658 13.425,2.407 L13.425,3.764 L21.676,3.764 C22.435,3.764 23.050,4.372 23.050,5.122 L23.050,18.693 C23.050,19.443 22.435,20.050 21.676,20.050 L21.676,20.050 ZM21.676,5.122 L12.050,5.122 L12.050,2.407 L2.424,2.407 L2.424,5.122 L2.424,7.157 L2.424,7.836 L21.676,7.836 L21.676,5.122 L21.676,5.122 ZM21.676,9.193 L2.424,9.193 L2.424,18.693 L21.676,18.693 L21.676,9.193 L21.676,9.193 Z" />
  </svg>
);

Filter.Notification = ({ small }: { small?: boolean }) =>
  small ? (
    <svg xmlns="http://www.w3.org/2000/svg" width="15.15px" height="17.15px">
      <path
        stroke="rgb(31, 163, 255)"
        fill="rgb(31, 163, 255)"
        d="M7.995,0.488 C8.580,0.488 9.170,0.567 9.716,0.790 C10.318,1.036 10.882,1.414 11.367,1.934 C11.998,2.610 12.402,3.430 12.608,4.294 C12.657,4.394 12.691,4.500 12.691,4.611 L12.691,4.611 L12.692,8.325 L14.882,12.549 L14.873,12.554 C15.008,12.816 14.981,13.148 14.774,13.369 C14.649,13.504 14.485,13.571 14.319,13.571 L14.319,13.571 L10.833,13.571 C10.772,14.560 10.295,15.525 9.339,16.095 C8.491,16.600 7.425,16.599 6.578,16.092 C5.624,15.521 5.148,14.558 5.088,13.572 L1.601,13.571 L1.601,13.571 C1.435,13.571 1.271,13.503 1.146,13.370 C0.939,13.148 0.912,12.816 1.047,12.555 L1.038,12.549 L3.228,8.325 L3.228,4.611 L3.228,4.611 C3.229,4.501 3.262,4.394 3.311,4.294 C3.518,3.430 3.922,2.610 4.553,1.934 C5.039,1.414 5.604,1.035 6.207,0.789 C6.750,0.568 7.336,0.489 7.918,0.489 L7.995,0.488 ZM6.824,14.586 C7.452,15.258 8.469,15.258 9.096,14.586 C9.361,14.302 9.507,13.943 9.549,13.571 L6.372,13.571 C6.414,13.942 6.559,14.302 6.824,14.586 L6.824,14.586 ZM5.462,2.907 C5.005,3.397 4.704,3.988 4.552,4.611 L4.513,4.611 L4.513,4.611 L4.514,8.504 L4.514,8.504 C4.514,8.620 4.477,8.729 4.424,8.833 L4.433,8.838 L2.691,12.195 L13.228,12.196 L11.486,8.838 L11.496,8.833 C11.443,8.729 11.406,8.620 11.406,8.504 L11.406,8.504 L11.406,4.611 L11.406,4.611 L11.406,4.611 L11.370,4.611 C11.216,3.986 10.915,3.397 10.459,2.907 C9.079,1.429 6.842,1.429 5.462,2.907 L5.462,2.907 Z"
      />
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="23px">
      <path d="M10.644,0.439 C11.479,0.438 12.320,0.546 13.100,0.854 C13.959,1.192 14.764,1.712 15.457,2.427 C16.357,3.356 16.934,4.484 17.227,5.671 C17.297,5.809 17.345,5.955 17.346,6.107 L17.346,6.107 L17.347,11.214 L20.473,17.021 L20.460,17.030 C20.652,17.389 20.615,17.845 20.319,18.150 C20.140,18.335 19.907,18.427 19.670,18.428 L19.670,18.428 L14.694,18.428 C14.608,19.787 13.926,21.114 12.561,21.898 C11.351,22.592 9.830,22.591 8.621,21.894 C7.259,21.109 6.580,19.785 6.495,18.428 L1.518,18.427 L1.518,18.427 C1.282,18.427 1.047,18.335 0.869,18.151 C0.573,17.845 0.535,17.389 0.728,17.030 L0.715,17.022 L3.841,11.215 L3.841,6.107 L3.841,6.107 C3.842,5.956 3.889,5.809 3.959,5.671 C4.254,4.483 4.830,3.356 5.731,2.427 C6.425,1.711 7.231,1.191 8.091,0.853 C8.867,0.548 9.703,0.440 10.534,0.439 L10.644,0.439 ZM8.973,19.823 C9.868,20.747 11.320,20.747 12.215,19.824 C12.594,19.433 12.802,18.938 12.861,18.428 L8.327,18.427 C8.387,18.938 8.594,19.432 8.973,19.823 L8.973,19.823 ZM7.028,3.765 C6.376,4.438 5.946,5.250 5.729,6.107 L5.675,6.107 L5.675,6.107 L5.675,11.460 L5.675,11.460 C5.675,11.620 5.623,11.769 5.547,11.913 L5.559,11.919 L3.074,16.536 L18.112,16.536 L15.627,11.920 L15.640,11.912 C15.564,11.770 15.512,11.620 15.512,11.460 L15.512,11.460 L15.512,6.108 L15.512,6.108 L15.512,6.108 L15.460,6.108 C15.241,5.249 14.811,4.438 14.160,3.765 C12.190,1.732 8.998,1.732 7.028,3.765 L7.028,3.765 Z" />
    </svg>
  );

Filter.List = ({ small }: { small?: boolean }) =>
  small ? (
    <svg xmlns="http://www.w3.org/2000/svg" width="18.15px" height="14.15px">
      <path
        stroke="rgb(31, 163, 255)"
        fill="rgb(31, 163, 255)"
        d="M16.266,8.111 L5.603,8.111 C5.194,8.111 4.862,7.787 4.862,7.390 C4.862,6.992 5.194,6.668 5.603,6.668 L16.266,6.668 C16.674,6.668 17.006,6.992 17.006,7.390 C17.006,7.787 16.674,8.111 16.266,8.111 ZM16.266,2.914 L5.603,2.914 C5.194,2.914 4.862,2.590 4.862,2.192 C4.862,1.794 5.194,1.470 5.603,1.470 L16.266,1.470 C16.674,1.470 17.006,1.794 17.006,2.192 C17.006,2.590 16.674,2.914 16.266,2.914 ZM2.344,13.887 C1.609,13.887 1.011,13.304 1.011,12.587 C1.011,11.871 1.609,11.288 2.344,11.288 C3.079,11.288 3.677,11.871 3.677,12.587 C3.677,13.304 3.079,13.887 2.344,13.887 ZM2.344,8.689 C1.609,8.689 1.011,8.106 1.011,7.390 C1.011,6.673 1.609,6.090 2.344,6.090 C3.079,6.090 3.677,6.673 3.677,7.390 C3.677,8.106 3.079,8.689 2.344,8.689 ZM2.344,3.492 C1.609,3.492 1.011,2.908 1.011,2.192 C1.011,1.475 1.609,0.892 2.344,0.892 C3.079,0.892 3.677,1.475 3.677,2.192 C3.677,2.908 3.079,3.492 2.344,3.492 ZM5.603,11.866 L16.266,11.866 C16.674,11.866 17.006,12.189 17.006,12.587 C17.006,12.985 16.674,13.309 16.266,13.309 L5.603,13.309 C5.194,13.309 4.862,12.985 4.862,12.587 C4.862,12.189 5.194,11.866 5.603,11.866 Z"
      />
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width="23px" height="18px">
      <path d="M21.775,10.282 L7.895,10.282 C7.364,10.282 6.931,9.859 6.931,9.339 C6.931,8.818 7.364,8.395 7.895,8.395 L21.775,8.395 C22.306,8.395 22.738,8.818 22.738,9.339 C22.738,9.859 22.306,10.282 21.775,10.282 ZM21.775,3.486 L7.895,3.486 C7.364,3.486 6.931,3.062 6.931,2.541 C6.931,2.021 7.364,1.598 7.895,1.598 L21.775,1.598 C22.306,1.598 22.738,2.021 22.738,2.541 C22.738,3.062 22.306,3.486 21.775,3.486 ZM2.498,17.835 C1.540,17.835 0.762,17.072 0.762,16.135 C0.762,15.198 1.540,14.436 2.498,14.436 C3.454,14.436 4.233,15.198 4.233,16.135 C4.233,17.072 3.454,17.835 2.498,17.835 ZM2.498,11.038 C1.540,11.038 0.762,10.275 0.762,9.339 C0.762,8.402 1.540,7.640 2.498,7.640 C3.454,7.640 4.233,8.402 4.233,9.339 C4.233,10.275 3.454,11.038 2.498,11.038 ZM2.498,4.241 C1.540,4.241 0.762,3.478 0.762,2.541 C0.762,1.604 1.540,0.842 2.498,0.842 C3.454,0.842 4.233,1.604 4.233,2.541 C4.233,3.478 3.454,4.241 2.498,4.241 ZM7.895,15.192 L21.775,15.192 C22.306,15.192 22.738,15.615 22.738,16.135 C22.738,16.656 22.306,17.080 21.775,17.080 L7.895,17.080 C7.364,17.080 6.931,16.656 6.931,16.135 C6.931,15.615 7.364,15.192 7.895,15.192 Z" />
    </svg>
  );

Filter.Search = ({ small }: { small?: boolean }) =>
  small ? (
    <svg xmlns="http://www.w3.org/2000/svg" width="15.15px" height="18.15px">
      <path
        stroke="rgb(31, 163, 255)"
        fill="rgb(31, 163, 255)"
        d="M14.912,16.369 C14.912,16.708 14.649,16.984 14.326,16.984 C14.170,16.984 14.024,16.920 13.912,16.803 L12.395,15.210 C11.726,15.727 10.938,16.000 10.110,16.000 C7.979,16.000 6.244,14.178 6.244,11.938 C6.244,9.698 7.979,7.876 10.110,7.876 C12.241,7.876 13.975,9.698 13.975,11.938 C13.975,12.809 13.715,13.637 13.223,14.340 L14.740,15.934 C14.851,16.049 14.912,16.204 14.912,16.369 ZM10.110,9.107 C8.625,9.107 7.416,10.377 7.416,11.938 C7.416,13.499 8.625,14.769 10.110,14.769 C11.595,14.769 12.804,13.499 12.804,11.938 C12.804,10.377 11.595,9.107 10.110,9.107 ZM11.301,7.540 L11.231,7.507 L11.231,6.153 L8.537,6.153 C7.956,6.153 7.483,5.656 7.483,5.045 L7.483,2.215 L2.095,2.215 L2.095,15.754 L6.710,15.754 L6.741,15.779 C7.538,16.406 8.483,16.738 9.473,16.738 L9.473,16.984 L1.978,16.984 C1.397,16.984 0.924,16.487 0.924,15.877 L0.924,2.091 C0.924,1.480 1.397,0.983 1.978,0.983 L8.585,0.983 L12.402,4.994 L12.402,8.253 L12.214,8.104 C11.927,7.876 11.620,7.686 11.301,7.540 ZM8.654,2.388 L8.654,4.922 L11.065,4.922 L8.654,2.388 Z"
      />
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width="23px" height="25px">
      <path d="M22.786,24.010 C22.786,24.519 22.373,24.933 21.865,24.933 C21.620,24.933 21.389,24.836 21.214,24.661 L18.828,22.271 C17.776,23.047 16.537,23.456 15.234,23.456 C11.882,23.456 9.155,20.723 9.155,17.363 C9.155,14.004 11.882,11.271 15.234,11.271 C18.586,11.271 21.313,14.004 21.313,17.363 C21.313,18.670 20.905,19.911 20.130,20.966 L22.516,23.357 C22.690,23.530 22.786,23.762 22.786,24.010 ZM15.234,13.117 C12.898,13.117 10.998,15.022 10.998,17.363 C10.998,19.705 12.898,21.610 15.234,21.610 C17.570,21.610 19.471,19.705 19.471,17.363 C19.471,15.022 17.570,13.117 15.234,13.117 ZM17.107,10.766 L16.997,10.717 L16.997,8.686 L12.760,8.686 C11.847,8.686 11.103,7.941 11.103,7.025 L11.103,2.778 L2.629,2.778 L2.629,23.087 L9.887,23.087 L9.936,23.124 C11.190,24.065 12.676,24.563 14.232,24.563 L14.232,24.932 L2.445,24.932 C1.531,24.932 0.787,24.187 0.787,23.272 L0.787,2.593 C0.787,1.677 1.531,0.931 2.445,0.931 L12.837,0.931 L18.839,6.947 L18.839,11.836 L18.544,11.613 C18.092,11.270 17.609,10.986 17.107,10.766 ZM12.945,3.039 L12.945,6.839 L16.737,6.839 L12.945,3.039 Z" />
    </svg>
  );

Filter.Export = ({ small }: { small?: boolean }) =>
  small ? (
    <svg xmlns="http://www.w3.org/2000/svg" width="18.15px" height="18.15px">
      <path
        stroke="rgb(31, 163, 255)"
        fill="rgb(31, 163, 255)"
        d="M15.926,17.026 L2.029,17.026 C1.449,17.026 0.976,16.553 0.976,15.972 L0.976,2.073 C0.976,1.492 1.449,1.019 2.029,1.019 L15.926,1.019 C16.507,1.019 16.980,1.492 16.980,2.073 L16.980,15.972 C16.980,16.553 16.507,17.026 15.926,17.026 ZM12.886,2.135 L5.070,2.135 L5.070,6.975 L12.886,6.975 L12.886,2.135 ZM15.865,2.135 L14.003,2.135 L14.003,7.037 C14.003,7.619 13.530,8.092 12.948,8.092 L5.007,8.092 C4.426,8.092 3.953,7.619 3.953,7.037 L3.953,2.135 L2.092,2.135 L2.092,15.910 L15.865,15.910 L15.865,2.135 ZM4.511,10.946 L13.444,10.946 C13.752,10.946 14.003,11.197 14.003,11.505 C14.003,11.812 13.752,12.063 13.444,12.063 L4.511,12.063 C4.203,12.063 3.953,11.812 3.953,11.505 C3.953,11.197 4.203,10.946 4.511,10.946 ZM4.511,12.932 L13.444,12.932 C13.752,12.932 14.003,13.182 14.003,13.490 C14.003,13.798 13.752,14.048 13.444,14.048 L4.511,14.048 C4.203,14.048 3.953,13.798 3.953,13.490 C3.953,13.182 4.203,12.932 4.511,12.932 ZM10.467,3.004 C10.774,3.004 11.025,3.254 11.025,3.562 L11.025,5.548 C11.025,5.855 10.774,6.106 10.467,6.106 C10.159,6.106 9.908,5.855 9.908,5.548 L9.908,3.562 C9.908,3.254 10.159,3.004 10.467,3.004 Z"
      />
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width="23px" height="23px">
      <path d="M21.488,22.979 L2.395,22.979 C1.597,22.979 0.947,22.329 0.947,21.530 L0.947,2.419 C0.947,1.619 1.597,0.969 2.395,0.969 L21.488,0.969 C22.287,0.969 22.936,1.619 22.936,2.419 L22.936,21.530 C22.936,22.329 22.287,22.979 21.488,22.979 ZM17.311,2.504 L6.572,2.504 L6.572,9.158 L17.311,9.158 L17.311,2.504 ZM21.404,2.504 L18.845,2.504 L18.845,9.244 C18.845,10.044 18.196,10.694 17.397,10.694 L6.487,10.694 C5.688,10.694 5.038,10.044 5.038,9.244 L5.038,2.504 L2.481,2.504 L2.481,21.445 L21.404,21.445 L21.404,2.504 ZM5.805,14.619 L18.078,14.619 C18.501,14.619 18.845,14.964 18.845,15.387 C18.845,15.810 18.501,16.155 18.078,16.155 L5.805,16.155 C5.382,16.155 5.038,15.810 5.038,15.387 C5.038,14.964 5.382,14.619 5.805,14.619 ZM5.805,17.349 L18.078,17.349 C18.501,17.349 18.845,17.694 18.845,18.117 C18.845,18.540 18.501,18.885 18.078,18.885 L5.805,18.885 C5.382,18.885 5.038,18.540 5.038,18.117 C5.038,17.694 5.382,17.349 5.805,17.349 ZM13.987,3.698 C14.410,3.698 14.754,4.043 14.754,4.466 L14.754,7.196 C14.754,7.619 14.410,7.964 13.987,7.964 C13.564,7.964 13.220,7.619 13.220,7.196 L13.220,4.466 C13.220,4.043 13.564,3.698 13.987,3.698 Z" />
    </svg>
  );

Filter.Clock = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px">
    <path d="M12.000,22.000 C17.523,22.000 22.000,17.523 22.000,12.000 C22.000,6.477 17.523,2.000 12.000,2.000 C6.477,2.000 2.000,6.477 2.000,12.000 C2.000,17.523 6.477,22.000 12.000,22.000 ZM11.500,12.000 L11.500,6.997 C11.500,6.447 11.052,6.000 10.500,6.000 C9.944,6.000 9.500,6.446 9.500,6.997 L9.500,13.003 C9.500,13.278 9.612,13.527 9.793,13.707 C9.975,13.889 10.226,14.000 10.503,14.000 L15.497,14.000 C16.051,14.000 16.500,13.552 16.500,13.000 C16.500,12.444 16.051,12.000 15.497,12.000 L11.500,12.000 ZM12.000,24.000 C5.372,24.000 -0.000,18.627 -0.000,12.000 C-0.000,5.372 5.372,-0.000 12.000,-0.000 C18.627,-0.000 24.000,5.372 24.000,12.000 C24.000,18.627 18.627,24.000 12.000,24.000 Z" />
  </svg>
);

Filter.Download = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="13px" height="16px">
    <path d="M6.686,6.468 L8.686,6.468 L8.686,5.106 L10.688,5.106 C11.423,5.106 12.018,5.715 12.018,6.464 L12.018,14.642 C12.018,15.392 11.423,16.000 10.688,16.000 L1.351,16.000 C0.616,16.000 0.021,15.391 0.021,14.642 L0.021,6.464 C0.021,5.714 0.616,5.106 1.351,5.106 L3.354,5.106 L3.354,6.468 L5.353,6.468 L5.353,0.681 C5.353,0.305 5.649,-0.000 6.020,-0.000 C6.388,-0.000 6.686,0.303 6.686,0.681 L6.686,6.468 ZM5.353,6.468 L1.354,6.468 L1.354,14.638 L10.685,14.638 L10.685,6.468 L6.686,6.468 L6.686,9.930 L7.439,9.161 C7.697,8.897 8.115,8.897 8.377,9.165 C8.637,9.431 8.636,9.864 8.381,10.124 L6.488,12.058 C6.359,12.189 6.191,12.255 6.023,12.255 L6.018,12.253 C5.848,12.253 5.680,12.187 5.554,12.058 L3.660,10.124 C3.402,9.860 3.402,9.433 3.664,9.165 C3.924,8.899 4.348,8.901 4.602,9.161 L5.353,9.927 L5.353,6.468 Z" />
  </svg>
);
Filter.Feedback = () => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512">
    <g>
      <g>
        <g>
          <path d="M117.333,149.333H352c5.896,0,10.667-4.771,10.667-10.667c0-5.896-4.771-10.667-10.667-10.667H117.333     c-5.896,0-10.667,4.771-10.667,10.667C106.667,144.563,111.438,149.333,117.333,149.333z" />
          <path d="M245.333,256h-128c-5.896,0-10.667,4.771-10.667,10.667c0,5.896,4.771,10.667,10.667,10.667h128     c5.896,0,10.667-4.771,10.667-10.667C256,260.771,251.229,256,245.333,256z" />
          <path d="M471.167,64c-0.618,0-1.217,0.155-1.833,0.184V64c0-23.531-19.146-42.667-42.667-42.667h-384     C19.146,21.333,0,40.469,0,64v416c0,4.313,2.604,8.208,6.583,9.854c1.313,0.552,2.708,0.813,4.083,0.813     c2.771,0,5.5-1.083,7.542-3.125L121.75,384h304.917c23.521,0,42.667-19.135,42.667-42.667V164.411l30.708-30.703c0,0,0,0,0-0.01     c7.604-7.604,11.958-18.125,11.958-28.865C512,82.313,493.688,64,471.167,64z M448,341.333c0,11.76-9.563,21.333-21.333,21.333     H117.333c-2.833,0-5.542,1.125-7.542,3.125L21.333,454.25V64c0-11.76,9.563-21.333,21.333-21.333h384     C438.438,42.667,448,52.24,448,64v7.286c-2.025,1.392-3.962,2.923-5.708,4.672L326.232,192H117.333     c-5.896,0-10.667,4.771-10.667,10.667c0,5.896,4.771,10.667,10.667,10.667h191.785l-10.243,51.24     c-0.708,3.5,0.396,7.115,2.917,9.635c2.021,2.021,4.75,3.125,7.542,3.125c0.688,0,1.396-0.073,2.083-0.208l53.313-10.667     c2.083-0.417,3.979-1.427,5.458-2.917L448,185.742V341.333z M357.396,246.177l-34.458,6.896l6.896-34.5l96.828-96.828     l27.587,27.587L357.396,246.177z M484.958,118.625l-15.625,15.625l-27.589-27.589l15.63-15.63     c3.625-3.615,8.646-5.698,13.792-5.698c10.75,0,19.5,8.75,19.5,19.5C490.667,109.958,488.583,114.99,484.958,118.625z" />
        </g>
      </g>
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
);

Filter.Circle = ({ className, children }) => <div className={`home-filter-circle ${className}`}>{children}</div>;

export default Filter;
