import * as React from 'react';
import moment from 'moment';
import { DatePicker, Select } from 'antd';
import { Tree, Checkbox, Input, TreePopup, Radio } from '~/components/ui';
import { Icon } from '~/components/ui/Icon/Icon';

export const FilterTarget = ({ t, item, onChange, years, value: $value }) => (
  <div data-testid="filter-target-wrapper">
    {item.type === 'input-search' ? (
      <Input.Search
        key={item.key}
        placeholder={item.placeholder ? t({ id: item.placeholder }) : ''}
        value={$value}
        onChange={onChange}
      />
    ) : null}

    {item.type === 'radiobox' ? (
      <Radio
        key={item.key}
        options={
          item.children
            ? item.children.map(({ title, localization, key }) => ({
                label: localization ? t({ id: localization }) : title,
                value: key,
              }))
            : []
        }
        value={$value}
        onChange={onChange}
      />
    ) : null}

    {item.type === 'input' ? (
      <Input
        prefix={item.icon ? <Icon type={item.icon} /> : ''}
        key={item.key}
        placeholder={item.placeholder ? t({ id: item.placeholder }) : ''}
        value={$value}
        onChange={onChange}
      />
    ) : null}

    {item.type === 'checkbox' ? (
      <Checkbox
        options={
          item.children
            ? item.children.map(({ title, localization, key }) => ({
                label: localization ? t({ id: localization }) : title,
                value: key,
              }))
            : []
        }
        value={$value}
        onChange={onChange}
      />
    ) : null}

    {item.type === 'percentages'
      ? item.children.map((child, key) => (
          <Input.Number
            key={child.key}
            min={0}
            max={100}
            formatter={value => `${value}%`}
            parser={value => value.replace('%', '')}
            placeholder={child.placeholder ? t({ id: child.placeholder }) : ''}
            value={$value && $value[child.key]}
            onChange={value => onChange(value, child.key)}
          />
        ))
      : null}

    {item.type === 'turnover'
      ? item.children.map((child, key) =>
          key <= 1 ? (
            <Input.Number
              key={child.key}
              // min={0}
              placeholder={child.placeholder ? t({ id: child.placeholder }) : ''}
              value={$value && $value[child.key]}
              onChange={value => onChange(value, child.key)}
            />
          ) : (
            <Select
              key="year-select"
              className="filter-select"
              placeholder={child.placeholder ? t({ id: child.placeholder }) : ''}
              onChange={value => onChange(value, child.key)}
              getPopupContainer={trigger => trigger.parentNode}
            >
              {years.map(year => (
                <Select.Option key={year}>{year}</Select.Option>
              ))}
            </Select>
          ),
        )
      : null}

    {item.type === 'numbers'
      ? item.children.map((child, key) => (
          <Input.Number
            key={child.key}
            min={0}
            placeholder={child.placeholder ? t({ id: child.placeholder }) : ''}
            value={$value && $value[child.key]}
            onChange={value => onChange(value, child.key)}
          />
        ))
      : null}

    {item.type === 'dates'
      ? item.children.map((child, key) => (
          <DatePicker
            key={child.key}
            placeholder={child.placeholder ? t({ id: child.placeholder }) : ''}
            format="YYYY-MM-DD"
            value={$value && $value[child.key] && moment($value[child.key], 'YYYY-MM-DD')}
            onChange={value => onChange(value, child.key)}
          />
        ))
      : null}

    {item.type === 'tree' ? (
      <Tree
        options={
          item.children
            ? item.children.map(child => ({
                id: child.id,
                title: child.title,
                children: child.children,
              }))
            : []
        }
        value={$value}
        onChange={onChange}
      />
    ) : null}

    {item.type === 'tree-popup' && (
      <TreePopup
        filterName={item.name}
        title={t(item.title)}
        options={
          item.children
            ? item.children.map(child => ({
                id: child.id,
                title: child.title,
                children: child.children,
              }))
            : []
        }
        value={$value}
        onChange={onChange}
      />
    )}
  </div>
);
