import React from 'react';

const Publication = props => (
  <div className="publication-item">
    <small>{props.createdAt}</small>
    <h2>{props.title}</h2>
    <p>{props.description}</p>
  </div>
);

Publication.Section = ({ children, className }) => <div className={`publications ${className || ''}`}>{children}</div>;

export default Publication;
