const initialState = {
  id: null,
};

export const monitoringSidebar = (state = initialState, action) => {
  switch (action.type) {
    case 'SAVE_MENU_ITEM':
      return { ...state, id: action.id };
    default:
      return state;
  }
};
