import React from 'react';
import env from '@beam-australia/react-env';
import { injectIntl } from '~/libs/localization';
import { Avatar } from '~/components/profile';
import { Link } from '~/libs/router';
import { Icon } from '~/components/ui/Icon/Icon';

import '~/assets/scss/components/ui/_CompanyCart.scss';

const CompanyCart = ({ item, t, permissions }) => (
  <div className="zh-company-cart">
    <Avatar.Small img={item.photo ? `${env('API_URL') || ''}/${item.photo}` : null} alt={item.name} />

    <div className="text">
      <span className="name">{item.name}</span>

      {permissions === 3 && (
        <Link route="EditCompanyPage" params={{ id: item.id }} className="edit-link">
          <Icon type="pencil" />
          {t({ id: 'team.edit' })}
        </Link>
      )}
    </div>
  </div>
);

export default injectIntl(CompanyCart);
