import React from 'react';
import { useParams } from 'react-router-dom';
import { Input } from 'antd';
import { injectIntl } from '~/libs/localization';
import { Pagination, Empty, Table } from '~/components/ui';
import { Time } from '~/components/other';
import { TableHeader, TableFooter } from '~/components/table';

const DownloadsHistory = ({ t, total, onSearch, data, filter, onChangeSearch, ...props }) => {
  const { page } = useParams();

  const pages = Math.ceil(total / 25);

  const columns = [
    {
      title: t({ id: 'other.name' }),
      width: 330,
      className: 'text-blue',
      dataIndex: 'name',
      render: name => name || <Empty />,
    },
    {
      title: t({ id: 'other.date' }),
      width: 100,
      className: 'collapsing',
      dataIndex: 'date_download',
      render: date => <Time prefix date={date} format="date" />,
    },
  ];

  return (
    <>
      <Table
        rowKey="date_download"
        defaultExpandAllRows
        className="basic table table-clean table-header"
        fixed
        columns={columns}
        dataSource={data}
        title={() => {
          return (
            <TableHeader
              leftSide={
                <h1>
                  {t({ id: 'download.title' })} ({total})
                </h1>
              }
              rightSide={
                <div className="form-inline">
                  <Input.Search
                    placeholder={t({ id: 'searches.search' })}
                    enterButton
                    onSearch={onChangeSearch}
                    defaultValue={filter.search}
                  />
                </div>
              }
            />
          );
        }}
        footer={() =>
          pages > 1 ? (
            <TableFooter
              params={{ type: 'list' }}
              rightSide={<Pagination page={page} total={pages} route="DownloadsPage" />}
            />
          ) : (
            false
          )
        }
      />
    </>
  );
};

export default injectIntl(DownloadsHistory);
