import React from 'react';
import { Modal, Radio } from 'antd';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroller';
import { useInjectIntl } from '~/libs/localization';
import { Icon } from '../ui/Icon/Icon';
import { Loader, Button } from '~/components/ui';
import { Filter } from '~/components/svg';

const ModalMonitoringLists = ({
  dataMonitoringList,
  onClose,
  onSubmitAddToList,
  onSetListMonitoringId,
  listMonitoringId,
  loadingAddMonitoringList,
}) => {
  const { t } = useInjectIntl();

  return (
    <Modal
      visible
      className="add-monitoring-modal"
      clean
      title={t('list.addMonitoringList')}
      onCancel={onClose}
      footer={
        <>
          {listMonitoringId ? (
            <Button
              fade
              type="primary"
              onClick={onSubmitAddToList}
              style={{ display: listMonitoringId ? 'block' : 'none' }}
              loading={loadingAddMonitoringList}
            >
              {t('list.add_to_list')}
            </Button>
          ) : (
            <p>{t('list.select_list')}</p>
          )}
        </>
      }
    >
      <Loader loading={false}>
        <div className="add-company-list">
          <InfiniteScroll initialLoad={false} pageStart={1} useWindow={false}>
            <Radio.Group onChange={e => onSetListMonitoringId(e.target.value)}>
              {dataMonitoringList?.length > 0
                ? dataMonitoringList.map(list => (
                    <Radio key={list.id} value={list.id}>
                      <span className="radio-flex">
                        <Filter.Circle className={list.color || 'blue'}>
                          <Icon type="filter-list" />
                        </Filter.Circle>

                        <span className="radio-flex-content">
                          <h2>
                            <span>{list.name}</span>
                          </h2>

                          <small>
                            {list.company_counter || 0} {t('list.company')} <span className="span-separator">•</span>
                            {t('list.created1')} {moment(list.date_created).format('YYYY.MM.DD')}
                          </small>
                        </span>
                      </span>
                    </Radio>
                  ))
                : t('list.noLists')}
            </Radio.Group>
          </InfiniteScroll>
        </div>
      </Loader>
    </Modal>
  );
};

export default ModalMonitoringLists;
