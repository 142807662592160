import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { Timeline } from 'antd';
import { useRequest } from 'estafette';
import { setDocumentTitle } from '~/hooks';
import withCompany from '~/hoc/withCompany';
import { company as companyDetails } from '~/libs/http/api';
import { Link } from '~/libs/router';
import { has } from '~/libs/object';
import { commaThousands } from '~/libs/number';
import { injectIntl } from '~/libs/localization';
import { CompanyLayout } from '~/components/layouts';
import { Segment, CompanyInfo, Chart } from '~/components/company';
import { Loader, Empty, Text, Rows, Table, Pagination } from '~/components/ui';
import { Time } from '~/components/other';
import { TableFooter } from '~/components/table';

export const GeneralsPage = props => {
  const { company, t } = props;
  const api = company.data;
  const { request, data: dataHistory, loading } = useRequest();

  const [pageNum, setPageNum] = useState(1);

  useEffect(() => {
    if (company.id) {
      const options = {
        id: company.id,
        page: pageNum,
        per_page: 5,
      };
      request(companyDetails.getCompanyHistory(options));
    }
  }, [company.id, pageNum]);

  useEffect(() => {
    setDocumentTitle(props.t('page.generals'));
  }, []);

  const hasActivityData = (arr, key) => {
    const data = [];

    arr.map((item, id) => (item[key] && item.title.title ? data.push(id) : null));

    return data.length >= 1;
  };

  const changesColumns = [
    {
      title: t('other.name2'),
      dataIndex: 'event_name',
      render: record => <b>{record}</b>,
    },
    {
      title: t('other.description'),
      dataIndex: 'description',
      render: record => <span dangerouslySetInnerHTML={{ __html: record }} />,
    },
    {
      title: t('other.date'),
      dataIndex: 'date',
      render: record => <Time prefix date={record} format="dateTime" />,
    },
  ];

  const columnsSimilarCompanies = [
    {
      title: t('other.name2'),
      dataIndex: 'name',
      render: (record, item) => (
        <Link route={item.parent_id ? 'SubdivisionPage' : 'GeneralsPage'} params={{ id: item.id }}>
          {has(item, 'name', <Empty />)}
        </Link>
      ),
    },
    {
      title: t('searcher.industry'),
      dataIndex: 'industry',
      render: (record, item) => has(item, 'industry', <Empty />),
    },
    {
      title: t('other.location'),
      dataIndex: 'location',
      render: (record, item) => has(item, 'location', <Empty />),
    },
    {
      title: t('other.website'),
      dataIndex: 'event_name',
      render: (record, item) =>
        has(item, 'site') ? (
          <a href={item.site} target="_blank" rel="noopener noreferrer">
            {item.site}
          </a>
        ) : (
          <Empty />
        ),
    },
    {
      title: t('other.email'),
      dataIndex: 'email',
      render: (record, item) =>
        has(item, 'email') ? (
          <a href={`mailto:${item.email}`} target="_blank" rel="noopener noreferrer">
            {item.email}
          </a>
        ) : (
          <Empty />
        ),
    },
    {
      title: t('other.telephone'),
      dataIndex: 'phone',
      render: (record, item) => has(item, 'phone', <Empty />),
    },
  ];

  const onChangePage = newPage => {
    setPageNum(newPage);
  };

  return (
    <CompanyLayout>
      <Loader loading={props.loading || Object.keys(api).length === 0}>
        <Rows
          rows={[
            {
              label: t({ id: 'company.idno' }),
              value: has(api, 'general_data.idno'),
              pure: true,
            },
            {
              label: t({
                id: has(api, 'general_data.creation_date') ? 'company.registredDate' : 'company.registredYear',
              }),
              value: has(api, 'general_data.creation_date') || has(api, 'general_data.creation_year'),
              pure: true,
            },
            {
              label: t({ id: 'company.employee' }),
              value: has(api, 'general_data.size.name'),
            },
            {
              label: t({ id: 'company.subdivisions' }),
              value: has(api, 'children', []).length || undefined,
            },
          ]}
        />

        <Segment.Halves>
          <Segment title={t({ id: 'company.profile' })}>
            <p>{has(api, 'general_data.description', t('company.no_description'))}</p>
          </Segment>

          {has(api, 'general_data.business_hours.length') ? (
            <Segment title={t({ id: 'company.schedule' })} noPadding>
              <Table
                rowKey="id"
                showHeader={false}
                dataSource={api.general_data.business_hours}
                className="company-shelude"
                rowClassName={(record, index) => {
                  return index === new Date().getDay() - 1 ? 'today' : '';
                }}
                columns={[
                  {
                    className: 'center aligned',
                    dataIndex: 'title',
                    render: (record, item) => item.title,
                  },
                  {
                    // className={index === new Date().getDay() - 1 ? 'today' : ''}
                    dataIndex: 'value',
                    render: (record, item) => item.value,
                  },
                ]}
              />
            </Segment>
          ) : null}
        </Segment.Halves>

        <CompanyInfo data={api.general_data} />

        {api.general_data ? (
          <Segment title={t({ id: 'company.turnover' })} className="company-graph" classContent="relative">
            {has(api, 'general_data.turnover.last') ? (
              <p className="chart-info">{commaThousands(api.general_data.turnover.last)} MDL</p>
            ) : (
              <div className="chart-empty">
                <span>{t({ id: 'company.empty' })}</span>
              </div>
            )}

            <Chart data={api.general_data.turnover.graph || []} />
          </Segment>
        ) : null}
        {has(api, 'general_data.types_of_activity.products_and_services.length') ||
        has(api, 'general_data.types_of_activity.activity_unlicensed.length') ||
        has(api, 'general_data.types_of_activity.activity_licensed.length') ? (
          <Segment title={t({ id: 'company.economy' })} classContent="container-flex" noPadding>
            {has(api, 'general_data.types_of_activity.products_and_services.length') ? (
              <Segment.Section
                title={`${t({ id: 'company.productsServices' })} (${
                  api.general_data.types_of_activity.products_and_services.length
                })`}
              >
                <Timeline>
                  {api.general_data.types_of_activity.products_and_services.map(row => (
                    <Timeline.Item key={row.title.id}>{has(row, 'title.title', <Empty />)}</Timeline.Item>
                  ))}
                </Timeline>
              </Segment.Section>
            ) : null}

            {has(api, 'general_data.types_of_activity.activity_unlicensed.length') ? (
              <Segment.Section
                title={`${t({ id: 'company.activityUnlicType' })} (${
                  api.general_data.types_of_activity.activity_unlicensed.length
                })`}
              >
                <Timeline>
                  {api.general_data.types_of_activity.activity_unlicensed.map(row => (
                    <Timeline.Item key={row.title.id}>{has(row, 'title.title', <Empty />)}</Timeline.Item>
                  ))}

                  {/* <Timeline.Item className="timeline-more">
                    Mai mult <ArrowRight />
                  </Timeline.Item> */}
                </Timeline>
              </Segment.Section>
            ) : null}

            {has(api, 'general_data.types_of_activity.activity_licensed.length') ? (
              <Segment.Section
                title={`${t({ id: 'company.activityLicType' })} (${
                  api.general_data.types_of_activity.activity_licensed.length
                })`}
              >
                {api.general_data.types_of_activity.activity_licensed.map(row => (
                  <Timeline.Item key={row.title.id}>{has(row, 'title.title', <Empty />)}</Timeline.Item>
                ))}
              </Segment.Section>
            ) : null}
          </Segment>
        ) : null}

        {has(api, 'general_data.types_of_activity.products_and_services') ? (
          <Segment classContent="container-flex" title={t({ id: 'company.productsServicesCompany' })} noPadding>
            {hasActivityData(api.general_data.types_of_activity.products_and_services, 'production') && (
              <Segment.Section title={t({ id: 'company.producer' })} gray>
                <Timeline>
                  {api.general_data.types_of_activity.products_and_services.map(item =>
                    item.production && item.title.title ? (
                      <Timeline.Item key={item.title.id}>{item.title.title}</Timeline.Item>
                    ) : null,
                  )}
                </Timeline>
              </Segment.Section>
            )}

            {hasActivityData(api.general_data.types_of_activity.products_and_services, 'service') && (
              <Segment.Section title={t({ id: 'company.serviceProvider' })}>
                <Timeline>
                  {api.general_data.types_of_activity.products_and_services.map(item =>
                    item.service && item.title.title ? (
                      <Timeline.Item key={item.title.id}>{item.title.title}</Timeline.Item>
                    ) : null,
                  )}
                </Timeline>
              </Segment.Section>
            )}

            {hasActivityData(api.general_data.types_of_activity.products_and_services, 'trade') && (
              <Segment.Section title={t({ id: 'company.trader' })} gray>
                <Timeline>
                  {api.general_data.types_of_activity.products_and_services.map(item =>
                    item.trade && item.title.title ? (
                      <Timeline.Item key={item.title.id}>{item.title.title}</Timeline.Item>
                    ) : null,
                  )}
                </Timeline>
              </Segment.Section>
            )}
          </Segment>
        ) : null}

        {has(api, 'general_data.social_capital.value') ? (
          <Rows
            style={{ marginTop: 20 }}
            rows={[
              {
                label: t({ id: 'company.capitalSize' }),
                value: (
                  <>
                    {has(api, 'general_data.social_capital.value', '').toLocaleString()} <b>MDL</b>
                  </>
                ),
              },
              {
                label: t({ id: 'company.capitalType' }),
                value: has(api, 'general_data.social_capital.type.title'),
              },
              {
                label: t({ id: 'company.countryInvest' }),
                value: has(api, 'general_data.social_capital.countries')
                  ? api.general_data.social_capital.countries.map(n => n.title).join(', ')
                  : '---',
              },
              {
                label: t({ id: 'company.description' }),
                value: has(api, 'general_data.description'),
              },
            ]}
          />
        ) : null}

        {has(api, 'history', []).length ? (
          <Segment title={t('company.history')} noPadding>
            <Loader loading={loading}>
              <Table
                rowKey="changes"
                columns={changesColumns}
                dataSource={dataHistory.history}
                className="table-clean table-header table-odd table-alerts-company"
              />
              <div className="personal-page-pagination">
                <TableFooter
                  leftSide={<Pagination page={pageNum} total={dataHistory.pages} onChange={onChangePage} />}
                />
              </div>
            </Loader>
          </Segment>
        ) : null}

        {has(api, 'similar_companies') ? (
          <Segment title={<Text id="other.similarCompanies" />} noPadding>
            {api.similar_companies !== undefined && (
              <Table
                rowKey="similar_companies"
                columns={columnsSimilarCompanies}
                dataSource={api.similar_companies}
                className="table-clean table-header table-odd table-alerts-company"
                conditionalWrapper
              />
            )}
          </Segment>
        ) : null}
      </Loader>
    </CompanyLayout>
  );
};

const enhance = compose(injectIntl, withCompany());

export default enhance(GeneralsPage);
