import axios from '~/libs/http/axios';

const { baseURL } = axios.defaults;

const managementUsers = {
  add: (option = {}) => axios.post(`${baseURL}/api/management/user-account/detail`, option),
  delete: (params = {}) => axios.delete(`${baseURL}/api/management/user-account/detail`, { params }),
  edit: (option = {}) => axios.patch(`${baseURL}/api/management/user-account/detail`, option),
  getUserById: (params = {}) => axios.get(`${baseURL}/api/management/user-account/detail`, { params }),
  list: (params = {}) => axios.get(`${baseURL}/api/management/user-account/list`, { params }),
  permissionList: (params = {}) => axios.get(`${baseURL}/api/management/user-account/type-permissions`, { params }),
};

export default managementUsers;
