import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Checkbox } from 'antd';
import { useStateHandlers } from '~/hooks';
import { parseQuery } from '~/libs/http/url';
import { injectIntl } from '~/libs/localization';
import { Table } from '~/components/ui';
import { Icon } from '~/components/ui/Icon/Icon';

import '~/assets/scss/pages/_Order.scss';

const Order = ({ t, currency, collapseAll, record = [], isCheckable, onSetDelete, ...props }) => {
  const location = useLocation();
  const [state, setState] = useStateHandlers({
    checkedList: [],
    selectedRowKeys: [],
    indeterminate: false,
    checkAll: false,
    open: false,
  });

  const query = parseQuery(location.search);
  useEffect(() => {
    const onCheckDefaultActive = () => {
      if (query.cart_id && record.length > 0 && record[1].map(({ id }) => id).includes(+query.cart_id)) {
        onClickHandler();
      }
    };

    onCheckDefaultActive();
  }, []);

  useEffect(() => {
    setState({ open: collapseAll });
  }, [collapseAll]);

  const detailsColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: text => (
        <span
          className="zh-text"
          dangerouslySetInnerHTML={{
            __html: String(text),
          }}
        />
      ),
    },
    {
      title: 'Price',
      dataIndex: 'amount',
      render: price => (
        <span className="price-info">
          {price} {currency}
        </span>
      ),
      align: 'right',
    },
  ];
  const setOpenState = () => setState({ open: !state.open });
  const onClickHandler = () => record[1] && setOpenState();

  const calculate = (target, arr = []) => {
    let sum = 0;
    arr.forEach(item => item[target] && (sum += Number(item[target])));

    return sum;
  };

  const rowSelection = {
    onChange: (selectedRowKeys, checkedList) => {
      setState({
        checkedList,
        selectedRowKeys: getId(checkedList),
        indeterminate: !!checkedList.length && checkedList.length < record[1].length,
        checkAll: checkedList.length === record[1].length,
      });

      onSetDelete(record[0], selectedRowKeys);
    },
    selectedRowKeys: state.selectedRowKeys,
  };

  const getId = list => {
    const newList = [];
    list.map((item, key) => newList.push(item.id));
    return newList;
  };

  const onCheckAllChange = e => {
    const selectedRowKeys = e.target.checked ? getId(record[1]) : [];

    setState({
      selectedRowKeys,
      checkedList: e.target.checked ? record[1] : [],
      indeterminate: false,
      checkAll: e.target.checked,
    });

    onSetDelete(record[0], selectedRowKeys);
  };

  return (
    <div className="billing-table-details billing-table-check">
      <div className={`dropdown ${state.open || collapseAll ? 'active' : ''}`}>
        {isCheckable && (
          <Checkbox indeterminate={state.indeterminate} onChange={onCheckAllChange} checked={state.checkAll} />
        )}

        <div onClick={onClickHandler} className="dropdown-head">
          {record[1] && <Icon type={state.open ? 'caret-down' : 'menu-filter-arrow'} />}

          <span className="dropdown-title">{record[0]}</span>
        </div>

        <span className="dropdown-details">
          {calculate('amount', record[1])} {currency}
        </span>
      </div>

      {(record[1] && state.open) || (record[1] && collapseAll) ? (
        <Table
          rowSelection={isCheckable ? rowSelection : null}
          rowKey="id"
          columns={detailsColumns}
          dataSource={record[1]}
          showHeader={false}
          rowClassName={({ id }) => (query.cart_id === id.toString() ? 'ant-table-row-active' : '')}
        />
      ) : null}
    </div>
  );
};

export default injectIntl(Order);
