import React, { useEffect, useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Form, Button, Select } from 'antd';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { getRoute } from '~/libs/router';
// import { useRequest } from 'estafette';
import {
  searchPageList,
  searchPageListEmployee,
  setCompaniesLoading,
  resetCompanies,
} from '~/store/actions/SearchCompaniesActions';
import { setSearchType } from '~/store/actions/SearchActions';
import { useInjectIntl } from '~/libs/localization';
import SearchBox from './SearchBox';
import { SearchItem } from '~/components/search';
// import { Icon } from '~/components/ui/Icon/Icon';

import '~/assets/scss/components/_SearchStart.scss';

const SearchForm = () => {
  const { t } = useInjectIntl();
  const match = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const store = useSelector(
    ({ companies, searches }) => ({
      companies,
      searches,
    }),
    shallowEqual,
  );

  const [value, setValue] = useState(match.params.query || '');
  const [search, setSearch] = useState(match.params.query || '');
  const [changeType, setChangeType] = useState({ type_name: 'search.companySearch' });

  // const [isActiveCountryPopup] = useState(false);
  // const [country] = useState('md');

  const onSetValue = newValue => {
    setValue(newValue);
  };

  const onChangeSearchType = newValue => {
    setChangeType({ type_name: newValue.type_name });
    dispatch(setSearchType(newValue.active_type));
    clearAllValues();
    history.push(getRoute('Search', { page: 1, query: '' }));
  };

  const clearAllValues = () => {
    setValue('');
    // setSearch('');
    dispatch(resetCompanies());
  };

  const onFetch = () => {
    if (search && !store.searches.search_type) {
      dispatch(setCompaniesLoading(true));
      dispatch(resetCompanies());

      dispatch(searchPageList({ page: match.params.page || 1, perPage: store.companies.perPage || 5, name: search }));
    } else {
      dispatch(setCompaniesLoading(true));
      dispatch(resetCompanies());

      search &&
        dispatch(
          searchPageListEmployee({ page: match.params.page || 1, perPage: store.companies.perPage || 5, name: search }),
        );
    }
  };

  const onSearch = () => {
    if (value) {
      history.push(getRoute('Search', { page: 1, query: value }));
      onFetch();
    }
  };

  useEffect(() => {
    if (store.searches.search_type) {
      setChangeType({ type_name: 'search.companyEmployee' });
    } else {
      setChangeType({ type_name: 'search.companySearch' });
    }
  }, [store.searches.search_type]);

  useEffect(() => {
    onFetch();
  }, [store.companies.perPage, match.params.page, search]);

  useEffect(() => {
    if (!match.params.query) {
      dispatch(resetCompanies());
    }

    setValue(match.params.query);
    setSearch(match.params.query);
  }, [match.params.query]);

  return (
    <Form name="search-form">
      <div className="search-field-group search-field-group-nocountry" id="search-field-group">
        {/* <Dropdown
          onVisibleChange={onChangeCountry}
          getPopupContainer={() => document.getElementById('search-field-group')}
          overlayClassName="countries-popup-overlay"
          overlay={
            <div className="countries-popup">
              <div className="title">Europe</div>
              <ul className="countries-list">
                <li className="left country-item active">
                  <Icon type="flag-ro" className="flag" />
                  Moldova
                  <Icon type="menu-check" />
                </li>
                <li className="left country-item">
                  <Icon type="flag-ro" className="flag" />
                  Russia
                </li>
                <li className="left country-item">
                  <Icon type="flag-ro" className="flag" />
                  Romania
                </li>
                <li className="left country-item">
                  <Icon type="flag-ro" className="flag" />
                  Ukraine
                </li>
                <li className="left country-item">
                  <Icon type="flag-ro" className="flag" />
                  Bulgaria
                </li>
                <li className="left country-item">
                  <Icon type="flag-ro" className="flag" />
                  Turkey
                </li>
                <li className="left country-item">
                  <Icon type="flag-ro" className="flag" />
                  Germany
                </li>
                <li className="left country-item">
                  <Icon type="flag-ro" className="flag" />
                  Italy
                </li>
                <li className="left country-item">
                  <Icon type="flag-ro" className="flag" />
                  Spain
                </li>
                <li className="left country-item">
                  <Icon type="flag-ro" className="flag" />
                  Moldova
                  <Icon type="menu-check" />
                </li>
                <li className="left country-item">
                  <Icon type="flag-ro" className="flag" />
                  Russia
                </li>
                <li className="left country-item">
                  <Icon type="flag-ro" className="flag" />
                  Romania
                </li>
                <li className="left country-item">
                  <Icon type="flag-ro" className="flag" />
                  Ukraine
                </li>
                <li className="left country-item">
                  <Icon type="flag-ro" className="flag" />
                  Bulgaria
                </li>
                <li className="left country-item">
                  <Icon type="flag-ro" className="flag" />
                  Turkey
                </li>
                <li className="left country-item">
                  <Icon type="flag-ro" className="flag" />
                  Germany
                </li>
                <li className="left country-item">
                  <Icon type="flag-ro" className="flag" />
                  Italy
                </li>
                <li className="left country-item">
                  <Icon type="flag-ro" className="flag" />
                  Spain
                </li>
              </ul>

              <div className="title">Asia</div>
              <ul className="countries-list">
                <li key={0} className="left country-item">
                  <Icon type="flag-ro" className="flag" />
                  Moldova
                  <Icon type="menu-check" />
                </li>
                <li key={1} className="left country-item">
                  <Icon type="flag-ro" className="flag" />
                  Russia
                </li>
                <li key={2} className="left country-item">
                  <Icon type="flag-ro" className="flag" />
                  Romania
                </li>
                <li key={3} className="left country-item">
                  <Icon type="flag-ro" className="flag" />
                  Ukraine
                </li>
                <li key={4} className="left country-item">
                  <Icon type="flag-ro" className="flag" />
                  Bulgaria
                </li>
                <li key={5} className="left country-item">
                  <Icon type="flag-ro" className="flag" />
                  Turkey
                </li>
                <li key={6} className="left country-item">
                  <Icon type="flag-ro" className="flag" />
                  Germany
                </li>
                <li key={6} className="left country-item">
                  <Icon type="flag-ro" className="flag" />
                  Italy
                </li>
                <li key={6} className="left country-item">
                  <Icon type="flag-ro" className="flag" />
                  Spain
                </li>
              </ul>
            </div>
          }
          trigger={['click']}
        >
          <Button className={`country-btn ${isActiveCountryPopup ? ' active' : ''}`}>
            <span className="flag-wrap">
              <Icon type={`flag-${country}`} />
            </span>
            Moldova
            <Icon type="lang-arrow-right" />
          </Button>
        </Dropdown> */}

        <div className="center-search">
          <SearchBox placeholder="other.enterYourRrequest" onSetValue={onSetValue} value={value} filterOption={false} />
        </div>

        <div className="change-type-select">
          <Select value={t(changeType.type_name)} onSelect={onChangeSearchType} filterOption={false}>
            <Select.Option
              value={{ type_name: 'search.companySearch', active_type: false }}
              key="company"
              className="change-item"
            >
              <SearchItem.Empty title={t('search.companySearch')} />
            </Select.Option>
            <Select.Option
              value={{ type_name: 'search.companyEmployee', active_type: true }}
              key="employee"
              className="change-item"
            >
              <SearchItem.Empty title={t('search.companyEmployee')} />
            </Select.Option>
          </Select>
        </div>

        <Button type="primary" className="search-button" htmlType="submit" onClick={onSearch}>
          {t('search.search')}
        </Button>
      </div>
    </Form>
  );
};

export default SearchForm;
