import React from 'react';
import { Icon } from '~/components/ui/Icon/Icon';

export const Tag = ({ type, name, fullName, onDelete, onSelect, ...rest }) => {
  return (
    <div className="filter-tag" {...rest}>
      <div className="filter-tag-text" onClick={onSelect}>
        {type && <small>{type}</small>}
        {name && <span>{name}</span>}
      </div>

      <Icon type="download-close" onClick={onDelete} />
    </div>
  );
};
