import React, { useMemo, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useRequest } from 'estafette';
import { Tabs, Button as FilterButton } from 'antd';
import { DraggableModal } from 'ant-design-draggable-modal';
import { useSelector, shallowEqual } from 'react-redux';
import { useStateHandlers } from '~/hooks';
import { number } from '~/libs/number';
import { company } from '~/libs/http/api';
import { useInjectIntl } from '~/libs/localization';
import { Text, Tree, Loader, Mask } from '~/components/ui';
import { Icon } from '~/components/ui/Icon/Icon';

import '~/assets/scss/components/ui/_TreePopup.scss';
import 'ant-design-draggable-modal/dist/index.css';

const getTitle = (id, options) => {
  let result;

  options &&
    options.some(field => {
      if (field.id === id) {
        result = field.title;
        return true;
      }

      result = getTitle(id, field.children);
      return result !== undefined;
    });

  return result;
};

let loadingTimer;
let timer;

export const TreePopup = ({ values = [], options = [], title, filterName, ...props }) => {
  const { t } = useInjectIntl();
  const { request, data, loading } = useRequest();
  const { activeFilter, employeesKeys } = useSelector(
    ({ filter }) => ({ activeFilter: filter.activeFilter, employeesKeys: filter.employeesKeys }),
    shallowEqual,
  );

  const [state, setState] = useStateHandlers({
    open: false,
    checked: [],
    searchValue: '',
    searchTree: [],
    defaultExpandAll: false,
    loading: false,
    tags: {},
  });

  useEffect(() => {
    if (props.value) {
      setState({ checked: props.value });
    }
  }, [props.value]);

  useEffect(() => {
    const onCountTotal = () => {
      if (state.open) {
        const isCompanySearch =
          // get an array with initial filter's keys and current name which may not be in initial
          [filterName, ...Object.keys(activeFilter)].filter(key => employeesKeys.includes(key)).length === 0;

        request(
          company[isCompanySearch ? 'list' : 'employees']({
            page: 1,
            filters: {
              must: {
                ...activeFilter,
                ...(state.checked.length > 0 && { [filterName]: state.checked }),
              },
            },
          }),
        );
      }
    };

    onCountTotal();
  }, [state.checked, state.open]);

  useEffect(() => {
    const onUpdateTags = () => {
      const tags = {};

      const allKeys = Object.keys(state.tags);
      const newKeys = [];

      state.checked.forEach(key => (allKeys.includes(key) ? (tags[key] = state.tags[key]) : newKeys.push(key)));

      newKeys.map(key => (tags[key] = getTitle(key, options) || key));

      setState({ tags });
    };

    onUpdateTags();
  }, [state.checked]);

  useEffect(() => {
    const onChangeLoading = () => {
      if (state.open) {
        loadingTimer = setTimeout(() => {
          setState({ loading: false });
        }, 2000);
      }
    };

    onChangeLoading();
  }, [state.open]);

  useEffect(() => {
    return () => {
      loadingTimer && clearTimeout(loadingTimer);
      timer && clearTimeout(timer);
    };
  }, []);

  const onAccept = () => {
    setState({ open: false });

    if (JSON.stringify(values) !== JSON.stringify(state.checked)) {
      props.onChange(state.checked);
    }
  };

  const onChangeTree = checked => {
    setState({ checked });

    props.onChange(checked);
  };

  const onRemoveTag = removed => setState({ checked: state.checked.filter(tag => tag.toString() !== removed) });
  const onChangePopup = checked => setState({ checked });
  const onOpenPopup = () => setState({ open: true, loading: true, checked: state.checked });
  const onClosePopup = () => setState({ open: false, checked: state.checked });

  const tagKeys = useMemo(() => Object.keys(state.tags), [state.tags]);

  return (
    <>
      {state.open &&
        createPortal(
          <>
            <Mask black onClose={onClosePopup} />

            <DraggableModal
              clean
              title={
                <>
                  {title}

                  <span className="tree-popup-count">
                    <Loader.Inline loading={loading}>
                      {number(data.total_results || 0)} <Text id="other.results" />
                    </Loader.Inline>
                  </span>
                </>
              }
              onClose={onClosePopup}
              onCancel={onClosePopup}
              visible
              className="tree-popup"
              footer={
                <>
                  <FilterButton onClick={onClosePopup}>{t({ id: 'other.reset' })}</FilterButton>

                  <FilterButton type="primary" onClick={onAccept}>
                    {t({ id: 'other.submit' })}
                  </FilterButton>
                </>
              }
            >
              <Loader loading={state.loading} height="100%">
                <div className="tree-popup-content">
                  <Tree
                    hint={
                      Object.keys(state.tags).length > 0 && (
                        <div className="selected-tags-box">
                          <div className="selected-tags-list">
                            <Tabs mode="horizontal" className="zh-filter-tags">
                              {Object.keys(state.tags).map(key => (
                                <Tabs.TabPane
                                  tab={
                                    <div key={key} className="filter-tag">
                                      <div className="tag-name">
                                        <span>{title}</span>
                                        <strong>
                                          {state.tags[key] === 'select-all'
                                            ? t('other.selected-all')
                                            : `${
                                                tagKeys.length > 1 && tagKeys.includes('select-all')
                                                  ? `${t('other.except')} `
                                                  : ''
                                              }${state.tags[key]}`}
                                        </strong>
                                      </div>

                                      <Icon type="alerts-close" onClick={() => onRemoveTag(key)} />
                                    </div>
                                  }
                                  key={key}
                                />
                              ))}
                            </Tabs>
                          </div>
                        </div>
                      )
                    }
                    options={options}
                    value={state.checked}
                    onChange={onChangePopup}
                  />
                </div>
              </Loader>
            </DraggableModal>
          </>,
          document.getElementById('portal'),
        )}

      <Tree type="search" options={options} value={state.checked} onChange={onChangeTree} />

      <FilterButton className="zh-filter-button" type="primary" size="small" icon="plus" ghost onClick={onOpenPopup}>
        <Text id="filter.button.treePopupMore" />
      </FilterButton>
    </>
  );
};
