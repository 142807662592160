import { adapterIntl } from '~/libs/localization/helpers';

const homeHistory = adapterIntl({
  'homeHistory.saved_list': {
    ro: 'Salvare listă',
    ru: 'Сохранение списка',
    en: 'Saving list',
  },
  'homeHistory.saved_search': {
    ro: 'Salvare căutare',
    ru: 'Сохранение поиска',
    en: 'Saving seach',
  },
  'homeHistory.exports_download_pdf': {
    ro: 'Export date',
    ru: 'Экпорт данных',
    en: 'Export data',
  },
  'homeHistory.exports_download_xls': {
    ro: '---',
    ru: '---',
    en: '---',
  },
  'homeHistory.monitoring_create_list': {
    ro: 'Creare lista de monitorizare',
    ru: 'Создание списка отслеживаемых',
    en: 'Create tracking list',
  },
  'homeHistory.monitoring_add_company_to_list': {
    ro: 'Start monitorizare',
    ru: 'Начало отслеживание',
    en: 'Start tracking',
  },
  'homeHistory.monitoring_remove_company_from_list': {
    ro: 'Stop monitorizare',
    ru: 'Прекращение отслеживания',
    en: 'Stop tracking',
  },
  'homeHistory.removed_list': {
    ro: 'Ștergere listă',
    ru: 'Удаление списка',
    en: 'Delete list',
  },
  'homeHistory.removed_search': {
    ro: 'Ștergere căutare',
    ru: 'Удаление поиска',
    en: 'Delete search',
  },
  'homeHistory.exports_delete': {
    ro: 'Ștergere descărcare',
    ru: 'Удаление загрузки',
    en: 'Delete download',
  },
});

export default homeHistory;
