import React from 'react';
import { injectIntl } from '~/libs/localization';
import { Icon } from '~/components/ui/Icon/Icon';

const JuridicalCardTabs = ({ t, isActive, onChangeTab, ...props }) => {
  const Tab = ({ icon, tab, children, active }) =>
    active ? (
      <li className={isActive(tab)} onClick={() => onChangeTab(tab)} role="presentation">
        <Icon type={icon} />
        {children}
      </li>
    ) : (
      <li className="unactive">
        <Icon type={icon} />
        {children}
      </li>
    );

  return (
    <ul className="juridical-info-tabs">
      <Tab icon="juridical-info-generals" tab={1} active>
        {t({ id: 'juridical.infoGen' })}
      </Tab>

      <Tab icon="juridical-info-process" tab={2} active={props.processParticipantsLength}>
        {t({ id: 'juridical.processParts' })}
      </Tab>

      <Tab icon="juridical-info-meeting" tab={3} active={props.juridicalProccessSessionsLength}>
        {t({ id: 'juridical.judgeMeeting' })}
      </Tab>

      <Tab icon="juridical-info-solution" tab={4} active={props.processSessionResult}>
        {t({ id: 'juridical.solution' })}
      </Tab>

      <Tab icon="juridical-info-ads" tab={5} active={props.juridicalProcessMonitorOfficialLength}>
        {t({ id: 'juridical.ads' })}
      </Tab>
    </ul>
  );
};

export default injectIntl(JuridicalCardTabs);
