import React, { useState, useEffect } from 'react';
import { Modal, Checkbox, Input as AntdInput } from 'antd';
import { useSelector, shallowEqual } from 'react-redux';
import { useRequest } from 'estafette';
import { useStateHandlers } from '~/hooks';
import { legalBulletin } from '~/libs/http/api';
import { useInjectIntl } from '~/libs/localization';
import { Icon } from '~/components/ui/Icon/Icon';
import { TableFooter } from '~/components/table';
import { Pagination, Loader, Input, EmptyBlock, Button } from '~/components/ui';
import { LegalBulletinModalAddAct } from './LegalBulletinModalAddAct';
import { LegalBulletinModalEditAct } from './LegalBulletinModalEditAct';

import '~/assets/scss/components/_LegalBulletinContent.scss';

const LegalBulletinContentActs = () => {
  const { t } = useInjectIntl();
  const { request: requestFilterActItem, data: filteredActs, loading: loadingFilterActItem } = useRequest({
    data: { data: [] },
  });
  const { request: requestDeleteActItem, loading: loadingDeleteActItem, errors: errorActItemDelete } = useRequest();
  const store = useSelector(
    ({ legalBulletin: legalBulletinStore }) => ({
      legalBulletin: legalBulletinStore,
    }),
    shallowEqual,
  );

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [deleteActItem, setDeleteActItem] = useState(false);
  const [editActItem, setEditActItem] = useState(false);
  const [selectedActItems, setSelectedActItems] = useState([]);
  const [folderId, setFolderId] = useState(null);
  const [actEditValue, setActEditValue] = useState(null);
  const [filter, setFilter] = useStateHandlers({
    searchActs: null,
    page: 1,
  });

  useEffect(() => {
    if (selectedActItems.length === 1) {
      const find = filteredActs.data.find(item => item.id === selectedActItems[0]);

      setActEditValue(find);
    }
  }, [selectedActItems, filteredActs]);

  useEffect(() => {
    if (store.legalBulletin.acts) {
      setFolderId(store.legalBulletin.acts.actId);
    }
  }, [store]);

  useEffect(() => {
    setFilter({ page: 1 });
    setSelectedActItems([]);
  }, [folderId]);

  useEffect(() => {
    getFilteredActItem();
  }, [filter.searchActs, filter.page, folderId]);

  const saveSearchActs = value => setFilter({ page: 1, searchActs: value });
  const onChangePage = pageNumber => setFilter({ page: pageNumber });
  const showModalAct = () => setIsModalVisible(true);

  const getFilteredActItem = async () => {
    if (!folderId) {
      return;
    }

    const params = { type: folderId, page: filter.page };

    if (!filter.searchActs) {
      params.search = filter.searchActs;
    }

    await requestFilterActItem(legalBulletin.filterActItemList(params));
  };

  const onSelectActItem = ({ target }, id) =>
    setSelectedActItems(prevSelectedActItems =>
      target.checked ? [...prevSelectedActItems, id] : prevSelectedActItems.filter(currentId => currentId !== id),
    );

  const deleteOk = async () => {
    if (selectedActItems.length >= 1) {
      await requestDeleteActItem(
        legalBulletin.deleteActItem({
          ids: selectedActItems.join(','),
        }),
      );

      setSelectedActItems([]);
      setDeleteActItem(false);
      getFilteredActItem();
    }
  };

  const handleCancel = () => setIsModalVisible(false);
  const deleteCancel = () => setDeleteActItem(false);
  const confirmDelete = () => setDeleteActItem(true);
  const confirmEdit = () => setEditActItem(true);
  const editCancel = () => setEditActItem(false);

  return (
    <>
      {isModalVisible && (
        <LegalBulletinModalAddAct type={folderId} handleCancel={handleCancel} onRefetch={getFilteredActItem} />
      )}

      {editActItem && (
        <LegalBulletinModalEditAct
          onRefetch={getFilteredActItem}
          handleCancel={editCancel}
          type={selectedActItems}
          actEditValue={actEditValue}
        />
      )}

      {deleteActItem && (
        <>
          <Modal
            title={t('legalBulletin.deleteAct')}
            visible={deleteActItem}
            onOk={deleteOk}
            confirmLoading={loadingDeleteActItem}
            onCancel={deleteCancel}
          >
            <h4>{t('legalBulletin.deleteCheck')}</h4>

            {Object.keys(errorActItemDelete).length ? (
              <span className="modal-error-messages">{errorActItemDelete[0]}</span>
            ) : null}
          </Modal>
        </>
      )}

      <div className="legal-bulletin-content legal-bulletin-content-acts">
        <div className="page-header-bulletin">
          <div className="left">
            <div className="left-title">{t('legalBulletin.legalBulletins')}</div>

            <AntdInput.Group compact>
              <Input
                allowclear="true"
                placeholder={t('legalBulletin.searchFor')}
                value={filter.searchActs}
                onChange={saveSearchActs}
              />
            </AntdInput.Group>
          </div>

          <div className="right">
            {!selectedActItems.length >= 1 ? (
              <Button onClick={showModalAct} type="primary">
                <Icon type="bulletin-plus" />
                {t('legalBulletin.addAct')}
              </Button>
            ) : (
              <>
                {selectedActItems.length > 1 ? null : (
                  <Button onClick={confirmEdit} type="primary">
                    <Icon type="edit" />
                    {t('legalBulletin.edit')}
                  </Button>
                )}

                <Button onClick={confirmDelete} type="primary">
                  <Icon type="trash" />
                  {t('legalBulletin.delete')}
                </Button>
              </>
            )}
          </div>
        </div>

        <Loader loading={loadingFilterActItem}>
          <ul className="wrap-code-links">
            {filteredActs.data.length ? (
              filteredActs.data.map(item => (
                <li key={item.id}>
                  <Checkbox className="act-item-check" onChange={e => onSelectActItem(e, item.id)} />

                  <a rel="noopener noreferrer" target="_blank" href={item.link} className="code-link">
                    <Icon type="bulletin-scales" />

                    {item.title}

                    <Icon type="bulletin-arrow-right-big" />
                  </a>
                </li>
              ))
            ) : (
              <EmptyBlock />
            )}
          </ul>
        </Loader>

        <TableFooter
          leftSide={
            filteredActs && <Pagination page={filter.page} total={filteredActs.pages} onChange={onChangePage} />
          }
        />
      </div>
    </>
  );
};

export default LegalBulletinContentActs;
