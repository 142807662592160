import React from 'react';

const Status = ({ status, statusText, classText }) => (
  <span className="iconed-status">
    <div className={`status-dot status-${status}`} />

    <span className={classText}>{statusText || status}</span>
  </span>
);

export default Status;
