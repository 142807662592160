import { adapterIntl } from '~/libs/localization/helpers';

const signup = adapterIntl({
  'signup.success': {
    ro: 'Vă mulțumim pentru înregistrare',
    ru: 'Спасибо за регистрацию',
    en: 'Thank you for registering',
  },
  'signup.useAccount': {
    ro: 'Acum puteți utiliza contul dvs',
    ru: 'Теперь вы можете использовать свой аккаунт',
    en: 'Now you can use your account',
  },
  'signup.accessAccount': {
    ro: 'Accesați contul',
    ru: 'Войдите в свой аккаунт',
    en: 'Sign in to your account',
  },
  'signup.confirmSms': {
    ro: 'Confirmare SMS',
    ru: 'Подтвердите СМС',
    en: 'Confirm SMS',
  },
  'signup.enterSms': {
    ro: 'Vă rugăm să introduceți codul format din patru cifre din SMS trimis la numărul:',
    ru: 'Пожалуйста, введите четырехзначный код SMS высланный на номер:',
    en: 'Please enter a four-digit code in the SMS format sent to the number:',
  },
  'signup.wrongSms': {
    ro: 'Incorect! Vă rugăm să introduceți codul din SMS',
    ru: 'Неправильно! Пожалуйста, введите код из SMS',
    en: 'Incorrect! Please enter the code from the SMS',
  },
  'signup.resendSms': {
    ro: 'Retrimite SMS',
    ru: 'Переслать СМС',
    en: 'Resend SMS',
  },
  'signup.changePhone': {
    ro: 'Schimbați numărul de telefon',
    ru: 'Изменить номер телефона',
    en: 'Change phone number',
  },
  'signup.applyAndSend': {
    ro: 'Aplică și trimite SMS',
    ru: 'Применить и отправить SMS',
    en: 'Apply and send SMS',
  },
  'signup.cancel': {
    ro: 'Anulează',
    ru: 'Отмена',
    en: 'Cancel',
  },
  'signup.resendEmail': {
    ro: 'Retrimite email',
    ru: 'Переслать email',
    en: 'Resend email',
  },
  'signup.selectedCompany': {
    ro: 'Ați ales compania',
    ru: 'Вы выбрали компанию',
    en: 'You chose company',
  },
  'signup.companyNotFound': {
    ro: 'Compania căutată nu a fost gasită',
    ru: 'Компания, которую вы искали, не была найдена',
    en: 'The company you were looking for was not found',
  },
  'signup.companyAvailableON': {
    ro: 'Probabil compania dvs. va fi disponibila la data de',
    ru: 'Компания, которую вы искали, не была найдена',
    en: 'Your company will probably be available on',
  },
  'signup.loading': {
    ro: 'Se încărcă',
    ru: 'Загрузка',
    en: 'Loading',
  },
  'signup.phoneNotification': {
    ro: 'La acest număr va fi trimis un mesaj cu cod de confirmare',
    ru: 'На этот номер будет отправлено сообщение с кодом подтверждения',
    en: 'A message with an access code will be sent to this number',
  },
  'signup.continue': {
    ro: 'Continuă',
    ru: 'Продолжить',
    en: 'Continue',
  },
  'signup.personalData': {
    ro: 'Date personale',
    ru: 'Личные данные',
    en: 'Personal data',
  },
  'signup.step': {
    ro: 'Pasul',
    ru: 'Шаг',
    en: 'Step',
  },
  'signup.enterData': {
    ro: 'Introduceți compania/IDNO și adresa dvs de email',
    ru: 'Введите вашу компанию / IDNO и ваш email',
    en: 'Enter your company / IDNO and your email address',
  },
  'signup.nextStep': {
    ro: 'După aceea, veți fi redirecționat la pasul următor',
    ru: 'После этого вы будете перенаправлены на следующий шаг',
    en: "After that, you'll be redirected to the next step",
  },
  'signup.idnoCompany': {
    ro: 'IDNO/Compania',
    ru: 'IDNO/Компания',
    en: 'IDNO/Company',
  },
  'signup.selectCompany': {
    ro: 'Selectați compania',
    ru: 'Выберите компанию',
    en: 'Select company',
  },
  'signup.enterEmail': {
    ro: 'Introduceți adresa de email',
    ru: 'Введите email',
    en: 'Enter your email',
  },
  'signup.requiredField': {
    ro: 'Acest câmp este obligatoriu',
    ru: 'Это поле обязательно',
    en: 'This field is required',
  },
  'signup.recaptchaRequired': {
    ro: 'reCAPTCHA este necesară',
    ru: 'Требуется reCAPTCHA',
    en: 'reCAPTCHA is required',
  },
  'signup.confirmEmailSend': {
    ro: 'V-am trimis un email de confirmare',
    ru: 'Подтверждение отправлено вам по электронной почте',
    en: 'Confirmation sent to your email',
  },
  'signup.confirmEmail': {
    ro: 'Vă rugăm să confirmați pentru a finaliza înregistrarea dvs',
    ru: 'Пожалуйста, подтвердите, чтобы завершить регистрацию',
    en: 'Please confirm to complete your registration',
  },
});

export default signup;
