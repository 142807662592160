import React from 'react';
import cookies from 'react-cookies';
import { compose } from 'redux';
import { Form, Input, Button } from 'antd';
import { Editor } from '@tinymce/tinymce-react';
import env from '@beam-australia/react-env';
import { injectIntl } from '~/libs/localization';
import { withRequest } from '~/hoc';

const TINYMCE_API_KEY = env('TINYMCE_API_KEY');
const PUBLIC_URL = env('API_URL');
const UPLOAD_URL = `${env('API_URL')}/api/management/help/file-upload`;

const AddUpdateForm = ({ text, name, validate, onChangeName, onChangeText, lang, ...props }) => {
  const { errors = {} } = props.api;

  const settingsEditor = {
    plugins: 'link image code fullscreen lists',
    branding: false,
    menubar: false,
    statusbar: false,
    height: 500,
    images_upload_handler: (blobInfo, success, failure) => {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', UPLOAD_URL);
      // eslint-disable-next-line i18n-text/no-en
      xhr.setRequestHeader('Authorization', `Token ${cookies.load('sso-token')}`);

      xhr.onload = () => {
        if (xhr.status !== 200) {
          failure(`HTTP Error:  ${xhr.status}`);
          return;
        }

        const json = JSON.parse(xhr.responseText);

        if (!json || typeof json !== 'string') {
          // eslint-disable-next-line i18n-text/no-en
          failure(`Invalid JSON: ${xhr.responseText}`);
          return;
        }
        success(`${PUBLIC_URL}/${json}`);
      };

      const formData = new FormData();
      formData.append('file', blobInfo.blob(), `${PUBLIC_URL}/${blobInfo.filename()}`);

      xhr.send(formData);
    },
  };

  return (
    <>
      <Form.Item
        label={props.t('filter.child.name')}
        {...(errors.name ? { validateStatus: 'error', help: errors.name } : null)}
      >
        <Input
          placeholder={props.t('filter.child.name')}
          name="name"
          onChange={e => onChangeName(e.target.value, lang)}
          value={name[lang]}
        />
      </Form.Item>

      <Form.Item
        label={props.t('other.content')}
        {...(errors.description ? { validateStatus: 'error', help: errors.description } : null)}
      >
        <div className="styled-article">
          <Editor
            apiKey={TINYMCE_API_KEY}
            value={text[lang]}
            cloudChannel="dev"
            init={settingsEditor}
            onEditorChange={e => onChangeText(e, lang)}
          />
        </div>
      </Form.Item>

      <Form.Item>
        <Button htmlType="submit" type="primary">
          {props.t('other.submit')}
        </Button>
      </Form.Item>
    </>
  );
};

const enhance = compose(withRequest(), injectIntl);

export default enhance(AddUpdateForm);
