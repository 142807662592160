import axios from '~/libs/http/axios';

export const { isCancel, CancelToken } = axios;

export const cancellation = {};
export const setCancelToken = token => ({
  cancelToken: new CancelToken(c => (cancellation[token] = c)),
});

export const cancelRequests = token =>
  Array.isArray(token)
    ? token.forEach(item => cancellation[item] && cancellation[item]())
    : cancellation[token] && cancellation[token]();

const cancellationExport = { setCancelToken, cancellation, cancelRequests };

export default cancellationExport;
