import React from 'react';
import { Redirect as OldRedirect } from 'react-router-dom';
import { getRoute } from '~/libs/router';

const Redirect = ({ from, to, fromRoute, toRoute, fromParams, toParams, ...props }) => (
  <OldRedirect
    {...props}
    from={fromRoute ? getRoute(fromRoute, fromParams) : from}
    to={toRoute ? getRoute(toRoute, toParams) : to}
  />
);

export default Redirect;
