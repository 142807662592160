import React from 'react';
import { Link as OldLink } from 'react-router-dom';
import { getRoute, getQueryString } from '~/libs/router';

const Link = ({ to, route, query = '', params = {}, children, match, disabled, ...props }) => {
  // prettier-ignore
  const toLinkParam =
    query ? `?${getQueryString(query)}` :
    route ? getRoute(route, params) : to;

  if (disabled) {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a href="#" className="disabled-link">
        {children}
      </a>
    );
  }

  return (
    <OldLink to={toLinkParam} {...props}>
      {children}
    </OldLink>
  );
};

export default Link;
