import React from 'react';
import { compose } from 'redux';
import withCompany from '~/hoc/withCompany';
import { setDocumentTitle } from '~/hooks';
import { injectIntl } from '~/libs/localization';
import moment from '~/libs/moment';
import { has } from '~/libs/object';
import { CompanyLayout } from '~/components/layouts';
import { Segment, Publication } from '~/components/company';
import { Loader } from '~/components/ui';

class ReorganizationPage extends React.PureComponent {
  state = {};

  componentDidMount() {
    setDocumentTitle(this.props.t('page.reorganization'));
  }

  render() {
    const api = this.props.company.data;
    const { t } = this.props;

    return (
      <CompanyLayout juridical>
        <Loader loading={this.props.loading}>
          {has(api, 'juridical.reorganisation.length') ? (
            <React.Fragment>
              <Segment.Header
                className="juridical-header"
                noBorder
                title={`${t({ id: 'juridical.reorganization' })} (${api.juridical.reorganisation.length})`}
              />
              <Publication.Section>
                {api.juridical.reorganisation.map(juridical =>
                  juridical.steps.map(step => (
                    <Publication
                      key={step.id}
                      createdAt={moment(step.solution_date).format('DD.MM.YYYY')}
                      title={step.subject.title}
                      description={step.solution_type.name.title}
                    />
                  )),
                )}
              </Publication.Section>
            </React.Fragment>
          ) : null}
        </Loader>
      </CompanyLayout>
    );
  }
}

const enhance = compose(injectIntl, withCompany());

export default enhance(ReorganizationPage);
