import { useState, useEffect } from 'react';

const defaultTitle = 'Informer';
const getDocumentTitle = (title) => `${title ? `${title} · ` : ''}${defaultTitle}`;

export const setDocumentTitle = (title) => (document.title = getDocumentTitle(title || defaultTitle));

export default function useDocumentTitle(defaultValue) {
  const [value, setValue] = useState('');

  useEffect(() => {
    const onSetDefaultValue = () => {
      if (defaultValue) {
        setValue(defaultValue);
      }
    };

    onSetDefaultValue();

    return () => {
      setDocumentTitle();
    };
  }, []);

  useEffect(() => {
    setDocumentTitle(value);
  }, [value]);

  return setValue;
}
