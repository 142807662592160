export const findParentByTagName = (parent, tag) => {
  let element = parent;

  while (element.parentNode) {
    element = element.parentNode;
    if (element.nodeName === tag.toUpperCase()) {
      return element;
    }
  }

  return undefined;
};

export const filterTree = target => {
  const list = target.parentElement.parentElement.getElementsByTagName('ul')[0].getElementsByTagName('li');

  for (let i = 0; list.length > i; i += 1) {
    if (
      !list[i]
        .getElementsByClassName('ant-tree-title')[0]
        .innerText.toLowerCase()
        .match(target.value.toLowerCase())
    ) {
      list[i].style.display = 'none';
    } else {
      list[i].style.display = 'block';
    }
  }
};

export const filterCheckbox = target => {
  const list = target.parentElement.parentElement
    .getElementsByClassName('filter-checkbox-wrapper')[0]
    .getElementsByTagName('div')[0]
    .getElementsByTagName('label');

  for (let i = 0; list.length > i; i += 1) {
    if (!list[i].innerText.toLowerCase().match(target.value.toLowerCase())) {
      list[i].style.display = 'none';
    } else {
      list[i].style.display = 'block';
    }
  }
};

export const clearClassName = str => str.replace(/\s\s+/g, ' ');
