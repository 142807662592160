import React, { useEffect, useState } from 'react';
import { Button, Input, message } from 'antd';
import { useRequest } from 'estafette';
import { useDocumentTitle } from '~/hooks';
import { company } from '~/libs/http/api';
import { useInjectIntl } from '~/libs/localization';
import { Layout } from '~/components/layouts';
import { ManagementMenu } from '~/components/management';

import '~/assets/scss/pages/_StatisticSettingsPage.scss';

const StatisticSettingsPage = () => {
  const { request, loading, errors } = useRequest();
  const { request: requestSettings, data: dataSettings } = useRequest({ data: {} });
  const { t } = useInjectIntl();
  useDocumentTitle(t('page.accounts'));

  const [refreshRateName, setRefreshRateName] = useState({ ro: '', ru: '', en: '' });
  const [refreshRateValue, setRefreshRateValue] = useState('');
  const [headerFirstFieldName, setHeaderFirstFieldName] = useState({ ro: '', ru: '', en: '' });
  const [headerFirstFieldValue, setHeaderFirstFieldValue] = useState('');
  const [headerLastFieldName, setHeaderLastFieldName] = useState({ ro: '', ru: '', en: '' });
  const [headerLastFieldValue, setHeaderLastFieldValue] = useState('');
  const [headerThirdFieldName, setHeaderThirdFieldName] = useState({ ro: '', ru: '', en: '' });
  const [headerThirdFieldValue, setHeaderThirdFieldValue] = useState('');
  const [footerFirstFieldName, setFooterFirstFieldName] = useState({ ro: '', ru: '', en: '' });
  const [footerFirstFieldValue, setFooterFirstFieldValue] = useState('');
  const [footerLastFieldName, setFooterLastFieldName] = useState({ ro: '', ru: '', en: '' });
  const [footerLastFieldValue, setFooterLastFieldValue] = useState('');
  const [footerThirdFieldName, setFooterThirdFieldName] = useState({ ro: '', ru: '', en: '' });
  const [footerThirdFieldValue, setFooterThirdFieldValue] = useState('');

  useEffect(() => {
    requestSettings(company.publicSettings());
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      message.config({ top: 80 });
      message.error(t('profile.error_fields'));
    }
  }, [errors]);

  useEffect(() => {
    if (Object.keys(dataSettings).length > 0) {
      setRefreshRateName({ ...dataSettings.refresh_rate_names });

      setRefreshRateValue(dataSettings.refresh_rate_value);
      setHeaderFirstFieldName({ ...dataSettings.header_first_field_names });

      setHeaderFirstFieldValue(dataSettings.header_first_field_value);
      setHeaderLastFieldName({ ...dataSettings.header_last_field_names });

      setHeaderLastFieldValue(dataSettings.header_last_field_value);
      setHeaderThirdFieldName({ ...dataSettings.header_third_field_names });

      setHeaderThirdFieldValue(dataSettings.header_third_field_value);
      setFooterFirstFieldName({ ...dataSettings.footer_first_field_names });

      setFooterFirstFieldValue(dataSettings.footer_first_field_value);
      setFooterLastFieldName({ ...dataSettings.footer_last_field_names });

      setFooterLastFieldValue(dataSettings.footer_last_field_value);
      setFooterThirdFieldName({ ...dataSettings.footer_third_field_names });

      setFooterThirdFieldValue(dataSettings.footer_third_field_value);
    }
  }, [dataSettings]);

  const onSubmit = () => {
    request(
      company.changeSettings({
        refresh_rate_names: refreshRateName,
        refresh_rate_value: refreshRateValue,
        header_first_field_names: headerFirstFieldName,
        header_first_field_value: headerFirstFieldValue,
        header_last_field_names: headerLastFieldName,
        header_last_field_value: headerLastFieldValue,
        header_third_field_names: headerThirdFieldName,
        header_third_field_value: headerThirdFieldValue,
        footer_first_field_names: footerFirstFieldName,
        footer_first_field_value: footerFirstFieldValue,
        footer_last_field_names: footerLastFieldName,
        footer_last_field_value: footerLastFieldValue,
        footer_third_field_names: footerThirdFieldName,
        footer_third_field_value: footerThirdFieldValue,
      }),
    );
  };

  const onChangeTextRefreshRateName = React.useCallback(
    key => ({ target }) => setRefreshRateName(prevState => ({ ...prevState, [key]: target.value })),
    [refreshRateName],
  );

  const onChangeTextHeaderFirstFieldName = React.useCallback(
    key => ({ target }) => setHeaderFirstFieldName(prevState => ({ ...prevState, [key]: target.value })),
    [headerFirstFieldName],
  );

  const onChangeTextHeaderLastFieldName = React.useCallback(
    key => ({ target }) => setHeaderLastFieldName(prevState => ({ ...prevState, [key]: target.value })),
    [headerLastFieldName],
  );

  const onChangeTextHeaderThirdFieldName = React.useCallback(
    key => ({ target }) => setHeaderThirdFieldName(prevState => ({ ...prevState, [key]: target.value })),
    [headerThirdFieldName],
  );

  const onChangeTextFooterFirstFieldName = React.useCallback(
    key => ({ target }) => setFooterFirstFieldName(prevState => ({ ...prevState, [key]: target.value })),
    [footerFirstFieldName],
  );

  const onChangeTextFooterLastFieldName = React.useCallback(
    key => ({ target }) => setFooterLastFieldName(prevState => ({ ...prevState, [key]: target.value })),
    [footerLastFieldName],
  );

  const onChangeTextFooterThirdFieldName = React.useCallback(
    key => ({ target }) => setFooterThirdFieldName(prevState => ({ ...prevState, [key]: target.value })),
    [footerThirdFieldName],
  );

  const numberRefreshRateValue = ({ target }) => setRefreshRateValue(target.value);

  const numberHeaderFirstFieldValue = ({ target }) => setHeaderFirstFieldValue(target.value);

  const numberHeaderLastFieldValue = ({ target }) => setHeaderLastFieldValue(target.value);

  const numberHeaderThirdFieldValue = ({ target }) => setHeaderThirdFieldValue(target.value);

  const numberFooterFirstFieldValue = ({ target }) => setFooterFirstFieldValue(target.value);

  const numberFooterLastFieldValue = ({ target }) => setFooterLastFieldValue(target.value);

  const numberFooterThirdFieldValue = ({ target }) => setFooterThirdFieldValue(target.value);

  return (
    <Layout>
      <ManagementMenu />

      <div className="statistic-settings-container">
        <div className="statistic-settings-container__content">
          <span>Добавить настройки статистики</span>
          <div className="content__item">
            <span className="title">Actualizare completă a datelor</span>
            <span>Text RO</span>
            <Input onChange={onChangeTextRefreshRateName('ro')} defaultValue={refreshRateName.ro} />

            <span>Text RU</span>
            <Input onChange={onChangeTextRefreshRateName('ru')} defaultValue={refreshRateName.ru} />

            <span>Text EN</span>
            <Input onChange={onChangeTextRefreshRateName('en')} defaultValue={refreshRateName.en} />

            <span>Number value</span>
            <Input onChange={numberRefreshRateValue} defaultValue={refreshRateValue} />
          </div>

          <div className="content__item">
            <span className="title">Actualizare completă a datelor</span>
            <span>Text RO</span>
            <Input onChange={onChangeTextHeaderFirstFieldName('ro')} defaultValue={headerFirstFieldName.ro} />

            <span>Text RU</span>
            <Input onChange={onChangeTextHeaderFirstFieldName('ru')} defaultValue={headerFirstFieldName.ru} />

            <span>Text EN</span>
            <Input onChange={onChangeTextHeaderFirstFieldName('en')} defaultValue={headerFirstFieldName.en} />

            <span>Number value</span>
            <Input onChange={numberHeaderFirstFieldValue} defaultValue={headerFirstFieldValue} />
          </div>

          <div className="content__item">
            <span className="title">Actualizare completă a datelor</span>
            <span>Text RO</span>
            <Input onChange={onChangeTextHeaderLastFieldName('ro')} defaultValue={headerLastFieldName.ro} />

            <span>Text RU</span>
            <Input onChange={onChangeTextHeaderLastFieldName('ru')} defaultValue={headerLastFieldName.ru} />

            <span>Text EN</span>
            <Input onChange={onChangeTextHeaderLastFieldName('en')} defaultValue={headerLastFieldName.en} />

            <span>Number value</span>
            <Input onChange={numberHeaderLastFieldValue} defaultValue={headerLastFieldValue} />
          </div>

          <div className="content__item">
            <span className="title">Actualizare completă a datelor</span>
            <span>Text RO</span>
            <Input onChange={onChangeTextHeaderThirdFieldName('ro')} defaultValue={headerThirdFieldName.ro} />

            <span>Text RU</span>
            <Input onChange={onChangeTextHeaderThirdFieldName('ru')} defaultValue={headerThirdFieldName.ru} />

            <span>Text EN</span>
            <Input onChange={onChangeTextHeaderThirdFieldName('en')} defaultValue={headerThirdFieldName.en} />

            <span>Number value</span>
            <Input onChange={numberHeaderThirdFieldValue} defaultValue={headerThirdFieldValue} />
          </div>

          <div className="content__item">
            <span className="title">Actualizare completă a datelor</span>
            <span>Text RO</span>
            <Input onChange={onChangeTextFooterFirstFieldName('ro')} defaultValue={footerFirstFieldName.ro} />

            <span>Text RU</span>
            <Input onChange={onChangeTextFooterFirstFieldName('ru')} defaultValue={footerFirstFieldName.ru} />

            <span>Text EN</span>
            <Input onChange={onChangeTextFooterFirstFieldName('en')} defaultValue={footerFirstFieldName.en} />

            <span>Number value</span>
            <Input onChange={numberFooterFirstFieldValue} defaultValue={footerFirstFieldValue} />
          </div>

          <div className="content__item">
            <span className="title">Actualizare completă a datelor</span>
            <span>Text RO</span>
            <Input onChange={onChangeTextFooterLastFieldName('ro')} defaultValue={footerLastFieldName.ro} />

            <span>Text RU</span>
            <Input onChange={onChangeTextFooterLastFieldName('ru')} defaultValue={footerLastFieldName.ru} />

            <span>Text EN</span>
            <Input onChange={onChangeTextFooterLastFieldName('en')} defaultValue={footerLastFieldName.en} />

            <span>Number value</span>
            <Input onChange={numberFooterLastFieldValue} defaultValue={footerLastFieldValue} />
          </div>

          <div className="content__item">
            <span className="title">Actualizare completă a datelor</span>
            <span>Text RO</span>
            <Input onChange={onChangeTextFooterThirdFieldName('ro')} defaultValue={footerThirdFieldName.ro} />

            <span>Text RU</span>
            <Input onChange={onChangeTextFooterThirdFieldName('ru')} defaultValue={footerThirdFieldName.ru} />

            <span>Text EN</span>
            <Input onChange={onChangeTextFooterThirdFieldName('en')} defaultValue={footerThirdFieldName.en} />

            <span>Number value</span>
            <Input onChange={numberFooterThirdFieldValue} defaultValue={footerThirdFieldValue} />
          </div>

          <Button type="primary" className="submit-top-btn" onClick={onSubmit} loading={loading}>
            Сохранить
          </Button>
        </div>
      </div>
    </Layout>
  );
};

export default StatisticSettingsPage;
