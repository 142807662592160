import { adapterIntl } from '~/libs/localization/helpers';

const profile = adapterIntl({
  'profile.error_fields': {
    ro: 'Completați toate câmpurile!',
    ru: 'Заполните все поля!',
    en: 'This field should not be blank.',
  },
  'profile.site_admin': { ro: 'Administrator site', ru: 'Администратор сайта', en: 'Site administrator' },
  'profile.approved': { ro: 'Aprobat', ru: 'Одобрен', en: 'Approved' },
  'profile.not_approved': { ro: 'Neaprobat', ru: 'Не одобрен', en: 'Not Approved' },
  'profile.profile': { ro: 'Profilul meu', ru: 'Мой профиль', en: 'Profile' },
  'profile.myPackage': { ro: 'Pachetul meu', ru: 'Мой пакет', en: 'My package' },
  'profile.notification': { ro: 'Notificări', ru: 'Оповещения', en: 'Notifications' },
  'profile.history': { ro: 'Descărcări', ru: 'Загрузки', en: 'Downloads' },
  'profile.billing': { ro: 'Facturare', ru: 'Счета', en: 'Billing' },
  'profile.credits': { ro: 'Credite', ru: 'Кредиты', en: 'Credits' },
  'profile.api': { ro: 'Integrarea', ru: 'Интеграции', en: 'Integration' },
  'profile.metrics': { ro: 'Profilul companiei', ru: 'Профиль компании', en: 'Company profile' },
  'profile.myTeam': { ro: 'Echipa mea', ru: 'Моя команда', en: 'My team' },
  'profile.admin': { ro: 'Management ', ru: 'Менеджмент ', en: 'Management' },
  'profile.basket': { ro: 'Coșul meu', ru: 'Моя корзина', en: 'My basket' },
  'profile.help': { ro: 'Ajutor și Asistență', ru: 'Помощь и Поддержка', en: 'Help & Support' },
  'profile.logout': { ro: 'Iesire', ru: 'Выход', en: 'Logout' },
  'profile.uploadAvatar': { ro: 'Încărcați fotografia', ru: 'Загрузить фотографию', en: 'Upload avatar' },
  'profile.uploadImage': { ro: 'Încărcați o imagine', ru: 'Загрузить фотографию', en: 'Upload image' },
  'profile.deleteImage': { ro: 'Ștergeți imaginea', ru: 'Удалить фотографию', en: 'Delete image' },
  'profile.settings': { ro: 'Setări personale', ru: 'Личные настройки', en: 'Profile settings' },
  'profile.juridical': { ro: 'Juridic', ru: 'Юридический', en: 'Juridical' },
  'profile.notJuridical': { ro: 'Nu juridical', ru: 'Не юридический', en: 'Not juridical' },
  'profile.lastName': { ro: 'Numele', ru: 'Фамилия', en: 'Last name' },
  'profile.name': { ro: 'Prenumele', ru: 'Имя', en: 'First name' },
  'profile.address': { ro: 'Adresa', ru: 'Адрес', en: 'Address' },
  'profile.staff': { ro: 'Angajat', ru: 'Сотрудник', en: 'Staff' },
  'profile.card': { ro: 'Numar card', ru: 'Номер карты', en: 'Card number' },
  'profile.staffSettings': { ro: 'Setări de lucrator', ru: 'Настройки сотрудника', en: 'Staff settings' },
  'profile.companyName': { ro: 'Denumirea companiei', ru: 'Название компании', en: 'Company name' },
  'profile.companyEmail': { ro: 'E-mail companiei', ru: 'Email компании', en: 'Company email' },
  'profile.serviceEmail': { ro: 'Email de serviciul', ru: 'Рабочий email', en: 'Service email' },
  'profile.serviceTelephone': { ro: 'Telefon de serviciul', ru: 'Рабочий телефон', en: 'Service phone' },
  'profile.level': { ro: 'Nivelul de conducere', ru: 'Уровень управления', en: 'Management level' },
  'profile.selectLevel': {
    ro: 'Selectați nivelul de conducere',
    ru: 'Выберите уровень управления',
    en: 'Select management level',
  },
  'profile.job': { ro: 'Funcția', ru: 'Должность', en: 'Position' },
  'profile.passwordTitle': {
    ro: 'Schimbați parola',
    ru: 'Изменить пароль',
    en: 'Change the password',
  },
  'profile.password': {
    ro: 'Parola',
    ru: 'Пароль',
    en: 'Password',
  },
  'profile.confirmPassword': {
    ro: 'Confirmă parola',
    ru: 'Повторите пароль',
    en: 'Confirm password',
  },
  'profile.currentPassword': {
    ro: 'Parola curentă',
    ru: 'Текущий пароль',
    en: 'Current password',
  },
  'profile.newPassword': {
    ro: 'Parola nouă',
    ru: 'Новый пароль',
    en: 'New password',
  },
  'profile.mustCurrentPassword': {
    ro: 'Trebuie să introduceți parola curentă',
    ru: 'Вы должны ввести текущий пароль',
    en: 'You must enter your current password',
  },
  'profile.judeSettings': { ro: 'Setări de juridical', ru: 'Юридические настройки', en: 'Juridical settings' },
  'profile.companyAddress': { ro: 'Adresa companiei', ru: 'Адрес компании', en: 'Company address' },
  'notification.settings': { ro: 'Setări de notificare', ru: 'Настройки оповещений', en: 'Notifications settings' },
  'notification.companySettings': {
    ro: 'Notificări ale companiei',
    ru: 'Оповещения компании',
    en: 'Company notifications',
  },
  'notification.frequency': {
    ro: 'Frecvență notificărilor',
    ru: 'Периодичность оповещений',
    en: 'Notifications frequency',
  },
  'profile.success-save': {
    ro: 'Date salvate!',
    ru: 'Данные сохранены!',
    en: 'Data saved!',
  },
  'notification.no': { ro: 'Fără notificări', ru: 'Без оповещений', en: 'No notifications' },
  notification: { ro: 'Notificări', ru: 'Оповещения', en: 'Notifications' },
  'notification.daily': { ro: 'Zilnic', ru: 'Ежедневно', en: 'Daily notifications' },
  'notification.weekly': { ro: 'O dată pe săptămână', ru: 'Раз в неделю', en: 'Weekly notifications' },
  'notification.realTime': { ro: 'În timp real', ru: 'В реальном времени', en: 'Real time notifications' },
  downloads: { ro: 'Descărcări', ru: 'Загрузки', en: 'Downloads' },
  'download.title': { ro: 'Istoria descărcărilor', ru: 'История загрузок', en: 'Download history' },
  'download.empty': { ro: 'Nu aveți nici o descărcarea', ru: 'Нет ни  одной загрузки', en: 'Download empty' },
  'credits.title': { ro: 'Credite', ru: 'Кредиты', en: 'Credits' },
  'credits.used': { ro: 'Utilizare curentă', ru: 'Текущее использование', en: 'Used credits' },
  'credits.limit': { ro: 'Limită', ru: 'Ограничение', en: 'Credits limit' },
  'credits.moreCredits': {
    ro: 'Aveți nevoie de mai multe credite?',
    ru: 'Необходимо больше кредитов?',
    en: 'Need more credits?',
  },
  'credits.moreCreditsButton': { ro: 'Contactați-ne', ru: 'Свяжитесь с нами', en: 'Contact us' },
  'api.title': { ro: 'Integrare API', ru: 'Интеграция API', en: 'API Integration' },
  'api.desc': {
    ro: 'Integrarea cu API',
    ru: 'Интеграция API',
    en: 'API Integration',
  },
  'api.btn': { ro: 'Accesați pagina API', ru: 'Откройте страницу API', en: 'Go to the API page' },
  'team.myTeam': { ro: 'Echipa mea', ru: 'Моя команда', en: 'My team' },
  'team.addPerson': { ro: 'Adaugă persoană', ru: 'Добавить пользователя', en: 'Add person' },
  'team.addCompany': { ro: 'Adaugă companie', ru: 'Добавить компанию', en: 'Add company' },
  'team.edit': { ro: 'Editează', ru: 'Редактировать', en: 'Edit' },
  'team.registred': { ro: 'Înregistrat pe', ru: 'Зарегистрирован на', en: 'Registred' },
  'team.visited': { ro: 'Ultima vizită', ru: 'Последнее посещение', en: 'Last visit' },
  'order.orders': { ro: 'În coșul meu {count} produse', ru: 'В корзине {count} товаров', en: 'Orders in my basket' },
  'order.subtotal': { ro: 'Subtotal', ru: 'Итого', en: 'Subtotal orders' },
  'order.total': { ro: 'Total', ru: 'Итого', en: 'Total orders' },
  'order.comand': { ro: 'Comandă', ru: 'Закажите', en: 'Order' },
  'profile.status.pending': { ro: 'În proces', ru: 'В процессе', en: 'In progress' },
  'profile.status.running': { ro: 'În proces', ru: 'В процессе', en: 'In progress' },
  'profile.status.finish': { ro: 'Terminat', ru: 'Завершено', en: 'Finish' },
  'profile.status.fail': { ro: 'Eşuat', ru: 'Неулачно', en: 'Fail' },
  'profile.events': {
    ro: 'Abonările la deconectări',
    ru: 'Подписки',
    en: 'Subscribes',
  },
  'events.eventHeader': { ro: 'Abonările la deconectări', ru: 'Подписки на отключения', en: 'Subscription events' },
  'events.addEvent': { ro: 'Adaugă locație', ru: 'Добавить местоположение', en: 'Add location' },
  'events.empty-block': { ro: 'Nu aveți nici o abonarea', ru: 'Нет подписок', en: 'No subscription' },
  'billing.details': { ro: 'Detalii comandă', ru: 'Подробности заказа', en: 'Order details' },
  'billing.history': { ro: 'Istoricul facturării', ru: 'История платежей', en: 'Billing History' },
  'billing.taxation': { ro: 'Impozit : TVA', ru: 'Налог : НДС', en: 'Billing : VAT' },
  'billing.invoices': { ro: 'Facturi', ru: 'Счет', en: 'Invoices' },
  'billing.date': { ro: 'Data', ru: 'Дата', en: 'Date' },
  'billing.description': { ro: 'Descriere', ru: 'Описание', en: 'Description' },
  'billing.amount': { ro: 'Suma', ru: 'Сумма', en: 'Amount' },
  'billing.download': { ro: 'Descarcă', ru: 'Скачать', en: 'Download' },
  'billing.update': { ro: 'Actualizați', ru: 'Изменить', en: 'Update' },
  'billing.status': { ro: 'Statut', ru: 'Статус', en: 'Status' },
  'billing.due': { ro: 'Dată scadentă', ru: 'Установленная дата', en: 'Due date' },
  'billing.created': { ro: 'Creat la', ru: 'Создано', en: 'Created at' },
  'billing.invoices.number': { ro: 'Numărul facturilor', ru: 'Номер счета', en: 'Invoices Number' },
  'billing.action': { ro: 'Acțiune', ru: 'Действие', en: 'Action' },
  'billing.vat.enter': {
    ro:
      'Dacă sunteți o companie înregistrată, introduceți numărul dvs. de certificare TVA pentru a elimina TVA din factura dvs. lunară',
    ru:
      'Если вы являетесь зарегистрированным предприятием, введите идентификационный номер НДС, чтобы удалить НДС из ежемесячного счета',
    en: 'If you are a registered business, enter your VAT indentification number to remove VAT from your monthly bill.',
  },
  'billing.vat.number': {
    ro: 'Număr de identificare TVA',
    ru: 'Идентификационный номер НДС',
    en: 'VAT Identification Number',
  },
  'billing.vat.info': {
    ro:
      ' Adresa dvs. de facturare indică faptul că vă aflați în Regatul Unit. TVA pentru locația dvs. este de 20,0% și se va aplica automat utilizării dvs. lunare.',
    ru:
      'Ваш платежный адрес указывает, что вы находитесь в Великобритании. НДС для вашего местоположения составляет 20,0% и будет автоматически применяться к вашему ежемесячному использованию.',
    en:
      'Your billing address indicates you are located in United Kingdom. VAT for your location is 20.0% and will be applied automatically to your monthly usage.',
  },
  'billing.vat.learn': { ro: 'Aflați mai multe despre TVA.', ru: 'Узнайте больше о НДС.', en: 'Learn more about VAT.' },
  'billing.vat.address': {
    ro:
      'Dacă considerați că am primit o adresă de facturare greșită, vor rugăm să deschideți un ticket/solicitare de asistență. Astfel, vom reuși să actualizăm detaliile dumneavoastră în timp util.',
    ru:
      'Мы неправильно указали ваш адрес для выставления счетов? Пожалуйста, откройте заявку в службу поддержки, чтобы мы могли Изменить ваши данные прямо сейчас.',
    en:
      'Have we gotten your billings address wrong? Please open a support ticket so we can update your details right away.',
  },
  'billing.vat.input': {
    ro: 'Introduceți numărul de înregistrare',
    ru: 'Введите регистрационный номер',
    en: 'Enter registration number',
  },
  'billing.vat.changed': {
    ro: 'TVA a fost modificat cu success',
    ru: 'Успешно изменен номер НДС',
    en: 'Successfully changed VAT number',
  },
  'billing.pay': {
    ro: 'Plătește în siguranță acum',
    ru: 'Оплатить безопасно сейчас',
    en: 'Pay securely now',
  },
  'billing.payment': {
    ro: 'Plata',
    ru: 'Оплата',
    en: 'Payment',
  },
  'billing.info': {
    ro:
      'Vom folosi aceste informații pentru a vă completa datele și pentru a procesa mai rapid achitarea. Detaliile plății dumneavoastră sunt în siguranță',
    ru:
      'Мы использмуем эту информация для автозаполнения, чтобы вы могли быстрее оплачивать. Ваши данные о оплате находятся в безопасности',
    en:
      "We'll use this information to autofill your details and make it quicker for you to pay. Your payment details are stored securely",
  },
  'billing.card': {
    ro: 'Alege tipul cardului',
    ru: 'Выберите тип карты',
    en: 'Highlight your payment option below',
  },
  'billing.month-expire': {
    ro: 'Data expirării (luna)',
    ru: 'Срок годности (месяц)',
    en: 'Expiry date (month)',
  },
  'billing.year-expire': {
    ro: 'Data expirării (anul)',
    ru: 'Срок годности (год)',
    en: 'Expiry date (year)',
  },
  'billing.cvv': {
    ro: 'Cod securitate (CVV)',
    ru: 'Защитный номер (CVV)',
    en: 'Security number (CVV) ',
  },
  'billing.back': {
    ro: 'Înapoi',
    ru: 'Назад',
    en: 'Back',
  },
  'billing.process': {
    ro: 'Confirma plata',
    ru: 'Подвердить оплату',
    en: 'Confirm payment',
  },
  'billing.success': {
    ro: 'Cu succes',
    ru: 'Успешно',
    en: 'Successfully',
  },
  'manager.ask': {
    ro: 'Scrie-ne mesaj direct',
    ru: 'Напишите нам прямое сообщение',
    en: 'Write us a direct message',
  },
  'manager.success': {
    ro: 'Expediat cu succes',
    ru: 'Успешно отправлено',
    en: 'Sent successfully',
  },
  'manager.account': {
    ro: 'Managerul contului dvs.',
    ru: 'Ваш аккаунт-менеджер',
    en: 'Your account manager',
  },
  'manager.phoneTitle': {
    ro: 'Telefon',
    ru: 'Телефон',
    en: 'Phone',
  },
  'manager.hoursTitle': {
    ro: 'Ore de lucru',
    ru: 'Рабочее время',
    en: 'Working hours',
  },
  'manager.shedule': {
    ro: 'Luni - vineri',
    ru: 'Понедельник - Пятница',
    en: 'Monday - Friday',
  },
  'manager.sheduleTitle': {
    ro: 'Disponibil de la',
    ru: 'Доступна с',
    en: 'Available from',
  },
  'manager.supportTitle': {
    ro: 'Suport',
    ru: 'Техническая поддержка',
    en: 'Support',
  },
  'manager.supportNote': {
    ro:
      'Dacă aveți o problemă tehnică sau doriți să primiți asistență folosind portalul nostru, vă rugăm să contactați managerul de cont',
    ru:
      'Если у вас возникла техническая проблема или вы хотите получить поддержку через наш портал, обратитесь к менеджеру своего аккаунта',
    en:
      'If you have a technical problem or would like to receive support using our portal, please contact your account manager',
  },
  'manager.support': {
    ro:
      'Dacă aveți o problemă tehnică sau doriți să primiți asistență folosind portalul nostru, vă rugăm să contactați managerul dvs',
    ru:
      'Если у вас возникла техническая проблема или вы хотите получить поддержку при использовании нашего портала, пожалуйста, свяжитесь с вашим менеджером',
    en:
      'If you have a technical problem or would like to receive support using our portal, please contact your manager',
  },
  'manager.answer': {
    ro:
      'Întrebarea dvs. a fost transmisă și este procesată. Poate dura până la 2-3 ore de lucru pentru ca managerul să vă răspundă.',
    ru: 'Ваш вопрос был отправлен и обрабатывается. Ваш менеджер может ответить вам в течение 2-3 рабочих часов.',
    en:
      'Your question has been sent and is being processed. It can take up to 2-3 working hours for you manager to answer you.',
  },
  'manager.api': {
    ro: 'API',
    ru: 'API',
    en: 'API',
  },
  'manager.topCompany': {
    ro: 'Top companii',
    ru: 'Топовые компании',
    en: 'Top companies',
  },
  'manager.statisticSettings': {
    ro: 'Statistic setari',
    ru: 'Настройки статистики',
    en: 'Statistic settings',
  },
  'manager.invoices': {
    ro: 'Facturi',
    ru: 'Накладные',
    en: 'Invoices',
  },
  'manager.terms': {
    ro: 'Politica de confidențialitate a INFORMER.MD',
    ru: 'Политика конфиденциальности INFORMER.MD',
    en: 'Privacy policy of INFORMER.MD',
  },
  'manager.terms-introduction': {
    ro: 'Introducere',
    ru: 'Введение',
    en: 'Introduction',
  },
  'manager.terms-definitions': {
    ro: 'Definiție',
    ru: 'Определения',
    en: 'Definitions',
  },
  'manager.common-terms-rules': {
    ro: 'Regulile comune pentru utilizarea Site-ului',
    ru: 'Общие правила пользования Сайтом',
    en: 'General rules for Site use',
  },
  'manager.info-terms-rules': {
    ro: 'Regulile destinate postărilor efectuate pe Site',
    ru: 'Правила размещения информации на Сайте',
    en: 'Rules for posting information on the Site',
  },
  'manager.admin-terms-rules': {
    ro: 'Drepturile Administrației Site-ului',
    ru: 'Права Администрации Сайта',
    en: 'Site Administration Rights',
  },
  'disconnections.disconnections': {
    ro: 'Deconectări',
    ru: 'Отключения',
    en: 'Disconnections',
  },
  'disconnections.localities': {
    ro: 'Localitățile mele',
    ru: 'Мои местоположения',
    en: 'My localities',
  },
  'disconnections.addLocation': {
    ro: 'Adaugă locație',
    ru: 'Добавить местоположение',
    en: 'Add location',
  },
  'disconnections.sortBy': {
    ro: 'Sortează după',
    ru: 'Сортировать по',
    en: 'Sort by',
  },
  'disconnections.city': {
    ro: 'Oraș',
    ru: 'Город',
    en: 'City',
  },
  'disconnections.village': {
    ro: 'Sat',
    ru: 'Деревня',
    en: 'Village',
  },
  'disconnections.perPage': {
    ro: 'pe 1 pagină',
    ru: 'на 1 страницe',
    en: 'per 1 page',
  },
  'disconnections.notificationSettings': {
    ro: 'Setări de notificare',
    ru: 'Настройки уведомлений',
    en: 'Notification settings',
  },
  'disconnections.disconnectNotifications': {
    ro: 'Notificări de deconectări',
    ru: 'Уведомления об отключении',
    en: 'Disconnect notifications',
  },
  'disconnections.notificationType': {
    ro: 'Tip',
    ru: 'Тип',
    en: 'Type',
  },
  'disconnections.waterDelivery': {
    ro: 'Livrare apă',
    ru: 'Вода',
    en: 'Water delivery',
  },
  'disconnections.electricity': {
    ro: 'Energie Electrică',
    ru: 'Электроэнергия',
    en: 'Electricity',
  },
  'disconnections.internetServices': {
    ro: 'Servicii Internet',
    ru: 'Интернет услуги',
    en: 'Internet Services',
  },
  'disconnections.controlServices': {
    ro: 'Controale',
    ru: 'Проверки',
    en: 'Controls',
  },
  'disconnections.frequencyNotifications': {
    ro: 'Frecvența notificărilor',
    ru: 'Частота уведомлений',
    en: 'Frequency of notifications',
  },
  'disconnections.frequency': {
    ro: 'Frecvența',
    ru: 'Частота',
    en: 'Frequency',
  },
  'disconnections.weekBefore': {
    ro: '1 săptămănă înainte',
    ru: 'За 1 неделю',
    en: '1 week before',
  },
  'disconnections.daysBefore': {
    ro: '3 zile înainte',
    ru: 'За 3 дня',
    en: '3 days before',
  },
  'disconnections.dayBefore': {
    ro: '1 zi înainte',
    ru: 'За 1 день',
    en: '1 day before',
  },
  'disconnections.methodOfNotification': {
    ro: 'Metoda de notificare',
    ru: 'Метод уведомления',
    en: 'Method of notification',
  },
  'disconnections.enterTextHere': {
    ro: 'Introduceți textul aici',
    ru: 'Введите текст здесь',
    en: 'Enter text here',
  },
  'disconnections.phone': {
    ro: 'Telefon',
    ru: 'Телефон',
    en: 'Phone',
  },
  'packages.packagesAvailable': {
    ro: 'Pachete disponibile',
    ru: 'Доступные пакеты',
    en: 'Available packages',
  },
  'packages.searchView': {
    ro: 'Căutare / vizulizare',
    ru: 'Поиск / просмотр',
    en: 'Search / view',
  },
  'packages.views': {
    ro: 'Vizualizare',
    ru: 'Просмотры',
    en: 'Views',
  },
  'packages.alerts': {
    ro: 'Alerte',
    ru: 'Оповещения',
    en: 'Alerts',
  },
  'packages.monitoring': {
    ro: 'Monitorizare',
    ru: 'Мониторинг',
    en: 'Monitoring',
  },
  'packages.use': {
    ro: 'Utilizatori',
    ru: 'Пользователи',
    en: 'Users',
  },
  'packages.exploration': {
    ro: 'Prospectare',
    ru: 'Исследование',
    en: 'Exploration',
  },
  'packages.exportCompanies': {
    ro: 'Export companii',
    ru: 'Экспорт компаний',
    en: 'Export companies',
  },
  'packages.legalBulletin': {
    ro: 'Buletin Juridic',
    ru: 'Юр. бюллетень',
    en: 'Legal Bulletin',
  },
  'packages.primary': {
    ro: 'Primar',
    ru: 'Primary',
    en: 'Primary',
  },
  'packages.free': {
    ro: 'Gratuit',
    ru: 'Бесплатно',
    en: 'Free of charge',
  },
  'packages.profiles': {
    ro: 'profile',
    ru: 'профилей',
    en: 'profiles',
  },
  'packages.perDay': {
    ro: 'Pe zi',
    ru: 'В день',
    en: 'Per day',
  },
  'packages.address': {
    ro: 'adresă',
    ru: 'адрес',
    en: 'Address',
  },
  'packages.companies': {
    ro: 'companii',
    ru: 'компании',
    en: 'companies',
  },
  'packages.activePackage': {
    ro: 'Pachet activ',
    ru: 'Активный пакет',
    en: 'Active package',
  },
  'packages.theMostPopular': {
    ro: 'Cel mai popular',
    ru: 'Самый популярный',
    en: 'The most popular',
  },
  'packages.business': {
    ro: 'Business',
    ru: 'Business',
    en: 'Business',
  },
  'packages.month': {
    ro: 'Lună',
    ru: 'Месяц',
    en: 'Month',
  },
  'packages.annualPayment': {
    ro: 'achitare anuală',
    ru: 'ежегодный платеж',
    en: 'annual payment',
  },
  'packages.yourIndustry': {
    ro: 'Industria ta',
    ru: 'Ваша отрасль',
    en: 'Your industry',
  },
  'packages.purchaseNow': {
    ro: 'Cumpară acum',
    ru: 'Купить сейчас',
    en: 'Purchase now',
  },
  'packages.negotiable': {
    ro: 'Negociabil',
    ru: 'Договорная',
    en: 'Negotiable',
  },
  'packages.enterprise': {
    ro: 'Enterprise',
    ru: 'Enterprise',
    en: 'Enterprise',
  },
  'packages.unlimited': {
    ro: 'Nelimitat',
    ru: 'Безлимитный',
    en: 'Unlimited',
  },
  'packages.allIndustries': {
    ro: 'Toate industriile',
    ru: 'Все отрасли',
    en: 'All industries',
  },
  'packages.plan': {
    ro: 'Plan',
    ru: 'План',
    en: 'Plan',
  },
  'profile.packageName': {
    ro: 'Nume pachet',
    ru: 'Название пакета',
    en: 'Package name',
  },
  'profile.packagePrice': {
    ro: 'Preț pachet',
    ru: 'Стоимость пакета',
    en: 'Package price',
  },
  'profile.no': {
    ro: 'Nu',
    ru: 'Нет',
    en: 'No',
  },
  'profile.yes': {
    ro: 'Da',
    ru: 'Да',
    en: 'Yes',
  },
  'profile.frequency': {
    ro: 'Frecvență',
    ru: 'Частота',
    en: 'Frequency',
  },
  'profile.more': {
    ro: 'Mai mult',
    ru: 'Больше',
    en: 'More',
  },
  'profile.monthly': {
    ro: 'Lunară',
    ru: 'Ежемесячно',
    en: 'Monthly',
  },
  'profile.From': {
    ro: 'Din',
    ru: 'От',
    en: 'from',
  },
  'profile.from': {
    ro: 'din',
    ru: 'от',
    en: 'from',
  },
  'profile.billingDate': {
    ro: 'Data facturării',
    ru: 'Дата оплаты',
    en: 'Billing date',
  },
  'profile.metric': {
    ro: 'Metrică',
    ru: 'Метрика',
    en: 'Metric',
  },
  'profile.available': {
    ro: 'Disponibil',
    ru: 'Имеется в наличии',
    en: 'Available',
  },
  'profile.searches': {
    ro: 'Căutări',
    ru: 'Поиски',
    en: 'Searches',
  },
  'profile.companyProfiles': {
    ro: 'Profile Companii',
    ru: 'Профили компании',
    en: 'Company Profiles',
  },
  'profile.alertAddresses': {
    ro: 'Adrese Alerte',
    ru: 'Адреса предупреждений',
    en: 'Alert addresses',
  },
  'profile.export': {
    ro: 'Export',
    ru: 'Экспорт',
    en: 'Export',
  },
  'profile.generalData': {
    ro: 'Date Generale',
    ru: 'Общие данные',
    en: 'General Data',
  },
  'profile.contactsCompanies': {
    ro: 'Contacte Companii',
    ru: 'Контакты Компании',
    en: 'Contacts Companies',
  },
  'profile.people': {
    ro: 'Persoane',
    ru: 'Люди',
    en: 'People',
  },
  'profile.financialData': {
    ro: 'Date Financiare',
    ru: 'Финансовые данные',
    en: 'Financial data',
  },
  'profile.contactsPeople': {
    ro: 'Contacte persoane',
    ru: 'Контакты люди',
    en: 'Contacts people',
  },
  'packages.edit': {
    ro: 'Editează',
    ru: 'Редактировать',
    en: 'Edit',
  },
  'profile.addCompanyTitle': {
    ro: 'Adaugă companie',
    ru: 'Добавить компанию',
    en: 'Add company',
  },
  'profile.editCompanyTitle': {
    ro: 'Editează companie',
    ru: 'Редактировать компанию',
    en: 'Edit company',
  },
  'profile.uploadCompanyLogo': {
    ro: 'Încărcați logou companie',
    ru: 'Загрузить логотип компании',
    en: 'Upload company logo',
  },
  'profile.uploadAnImage': {
    ro: 'Încărcați o imagine',
    ru: 'Загрузите изображение',
    en: 'Upload an image',
  },
  'profile.selectCompany': {
    ro: 'Selecteaza companie',
    ru: 'Выберите компанию',
    en: 'Select company',
  },
  'profile.companyProfile': {
    ro: 'Profilul companiei',
    ru: 'Профиль Компании',
    en: 'Company Profile',
  },
  'profile.contactInformation': {
    ro: 'Informație de contact',
    ru: 'Контактная информация',
    en: 'Contact information',
  },
  'profile.website': {
    ro: 'Website',
    ru: 'Website',
    en: 'Website',
  },
  'profile.email': {
    ro: 'Email',
    ru: 'Email',
    en: 'Email',
  },
  'profile.phone': {
    ro: 'Telefon',
    ru: 'Телефон',
    en: 'Phone',
  },
  'profile.fax': {
    ro: 'Fax',
    ru: 'Факс',
    en: 'Fax',
  },
  'profile.removeSelection': {
    ro: 'Șterge selectia',
    ru: 'Снять выделение',
    en: 'Remove selection',
  },
  'profile.removeCompany': {
    ro: 'Șterge compania',
    ru: 'Удалить компанию',
    en: 'Remove company',
  },
  'profile.detailsOfThePerson': {
    ro: 'Detaliile persoanei',
    ru: 'Подробная информация о человеке',
    en: 'Details of the person',
  },
  'profile.select': {
    ro: 'Selectați',
    ru: 'Выберать',
    en: 'Select',
  },
  'profile.personSettings': {
    ro: 'Setările persoanei',
    ru: 'Персональные настройки',
    en: 'Person settings',
  },
  'profile.isAdmin': {
    ro: 'Administrator',
    ru: 'Администратор',
    en: 'Admin',
  },
  'profile.accessSettings': {
    ro: 'Setări de acces',
    ru: 'Настройки доступа',
    en: 'Access settings',
  },
  'profile.deletePerson': {
    ro: 'Șterge persoană',
    ru: 'Удалить пользователя',
    en: 'Delete person',
  },
  'profile.companyDetails': {
    ro: 'Detaliile companiei',
    ru: 'Сведения о компании',
    en: 'Company details',
  },
  'profile.deletePersonSuccess': {
    ro: 'Utilizator șters',
    ru: 'Пользователь удалён',
    en: 'User deleted',
  },
  'profile.deletePersonError': {
    ro: 'Nu s-a șters utilizatorul',
    ru: 'Не удалось удалить пользователя',
    en: 'Failed to delete user',
  },
});

export default profile;
