import React, { useState, useEffect } from 'react';
import { Button, Select } from 'antd';
import { useInjectIntl } from '~/libs/localization';
import { company } from '~/libs/http/api';
import { useRequest } from 'estafette';
import { indexed } from '~/libs/array';
import { Link } from '~/libs/router';
import { Text } from '~/components/ui';
import { SearchAddItem } from '~/components/search';
import { Icon } from '~/components/ui/Icon/Icon';

import '~/assets/scss/components/_SearchCompanyBox.scss';

const SearchAddCompanyBox = ({ placeholder, helpNode, saveFilds }) => {
  const { t } = useInjectIntl();
  const { request: companyList, data: companyData, loading } = useRequest();
  const [results, setResults] = useState([]);
  const [total, setTotal] = useState(0);
  const [value, setValue] = useState('');

  const onSearch = (newValue = '') => {
    setValue(newValue);

    if (newValue) {
      companyList(company.list({ page: 1, per_page: 3, filters: { must: { company_name: newValue } } }));
    }
  };

  useEffect(() => {
    if (companyData) {
      setResults(indexed(companyData.data));
      setTotal(companyData.total_results);
    }
  }, [companyData]);

  const onSelect = async (newValue = '') => {
    const { id } = results.find(item => item.name === newValue) || {};
    if (!id) {
      setValue('');
    }
  };

  return (
    <div className="search-box search-company-box search-size-default">
      <Select
        allowClear
        showSearch
        mode="combobox"
        filterOption={false}
        onSearch={onSearch}
        onSelect={onSelect}
        value={value}
        placeholder={t(placeholder)}
      >
        {value &&
          results &&
          results.map(result => (
            <Select.Option key={`company-${result.id.toString()}`} value={result.name}>
              <SearchAddItem.Fragment
                company={result.name}
                activity={result.industry}
                idno={result.idno}
                name={result.name}
                address={result.full_location}
                onSaveDate={saveFilds}
              />
            </Select.Option>
          ))}

        {!total && (
          <Select.Option key="empty-value" disabled>
            <SearchAddItem.Empty title={<Text id="filter.emptySearch" />} />
          </Select.Option>
        )}

        {loading && (
          <Select.Option key="loading-value" value={value} disabled>
            <SearchAddItem.Empty title="Loading..." />
          </Select.Option>
        )}
      </Select>

      {helpNode && (
        <Link route="HomePage" className="help-link">
          <Icon type="search-help" className="zh-icon icon-size-small" />
        </Link>
      )}

      <Icon type="add-company-search" />

      <Button className="search-btn">{t('filter.search')}</Button>
    </div>
  );
};

export default SearchAddCompanyBox;
