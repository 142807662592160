import React from 'react';
import { Radio as AntdRadio } from 'antd';
import { withDebounceChange } from '~/hoc';

const Radio = withDebounceChange()(
  ({ debounce, value, scopeValue, setState, onDebounceChange, onChange, elements, ...props }) => {
    const onChangeHandler = ({ target }) => onDebounceChange(target.value);

    return <AntdRadio.Group value={scopeValue} onChange={onChangeHandler} {...props} />;
  },
);

export default Radio;
