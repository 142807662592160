import React, { useState } from 'react';
import { Modal, Input, Alert } from 'antd';
import { useRequest } from 'estafette';
import { legalBulletin } from '~/libs/http/api';
import { useInjectIntl } from '~/libs/localization';
import { Button } from '~/components/ui';

export const LegalBulletinModalAddFolder = ({ onRefetch, handleCancel }) => {
  const { t } = useInjectIntl();
  const { request, loading, errors } = useRequest();

  const [actName, setActName] = useState('');

  const onSubmit = async () => {
    const folder = {
      title: actName,
    };

    await request(legalBulletin.addFolder(folder));

    handleCancel();
    onRefetch();
  };

  const onChangeActName = ({ target }) => setActName(target.value);

  return (
    <Modal
      title={t('legalBulletin.addFolder')}
      width="700px"
      visible
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          {t('legalBulletin.cancel')}
        </Button>,
        <Button key="submit" type="primary" onClick={onSubmit} loading={loading}>
          {t('legalBulletin.apply')}
        </Button>,
      ]}
      className="normative-act-modal"
    >
      {errors.detail || errors.non_field_errors ? (
        <Alert message={errors.detail || errors.non_field_errors} className="mb-20" type="warning" />
      ) : null}
      <div className="normative-act-form">
        <label>
          <div>{t('legalBulletin.addList')}</div>
          <Input placeholder={t('legalBulletin.nameList')} onChange={onChangeActName} />
          {errors.title ? <span className="modal-error-messages">{errors.title}</span> : null}
        </label>
      </div>
    </Modal>
  );
};
