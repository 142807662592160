import { adapterIntl } from '~/libs/localization/helpers';

const footer = adapterIntl({
  'footer.searchCompanies': {
    ro: 'Caută companii',
    ru: 'Поиск компаний',
    en: 'Search for companies',
  },
  'footer.searchPage': {
    ro: 'Căutare',
    ru: 'Поиск',
    en: 'Search',
  },
  'footer.lookEmployees': {
    ro: 'Caută angajați',
    ru: 'Искать сотрудников',
    en: 'Look for employees',
  },
  'footer.savedLists': {
    ro: 'Liste salvate',
    ru: 'Сохраненные списки',
    en: 'Saved lists',
  },
  'footer.news': {
    ro: 'Știri',
    ru: 'Новости',
    en: 'News',
  },
  'footer.soon': {
    ro: 'In curând',
    ru: 'Скоро',
    en: 'Soon',
  },
  'footer.mySearches': {
    ro: 'Căutările mele',
    ru: 'Мои поиски',
    en: 'My searches',
  },
  'footer.downloads': {
    ro: 'Descărcări',
    ru: 'Загрузки',
    en: 'Downloads',
  },
  'footer.userBoard': {
    ro: 'Bordul utilizatorului',
    ru: 'Пользовательская панель',
    en: 'User board',
  },
  'footer.howItWorks': {
    ro: 'Cum funcționează',
    ru: 'Как это работает',
    en: 'How it works',
  },
  'footer.prices': {
    ro: 'Prețuri',
    ru: 'Цены',
    en: 'Prices',
  },
  'footer.updateYourAccount': {
    ro: 'Actualizați contul',
    ru: 'Обновить аккаунт',
    en: 'Update your account',
  },
  'footer.helpCenter': {
    ro: 'Centrul de ajutor',
    ru: 'Центр поддержки',
    en: 'Support center',
  },
  'footer.mobileApplication': {
    ro: 'Aplicație mobilă',
    ru: 'Мобильное приложение',
    en: 'Mobile application',
  },
  'footer.reportProblem': {
    ro: 'Raportați o problemă',
    ru: 'Сообщить о проблеме',
    en: 'Report a problem',
  },
  'footer.information': {
    ro: 'Informație',
    ru: 'Информация',
    en: 'Information',
  },
  'footer.aboutInformer': {
    ro: 'Despre Informer',
    ru: 'О Informer',
    en: 'About Informer',
  },
  'footer.team': {
    ro: 'Echipă',
    ru: 'Команда',
    en: 'Team',
  },
  'footer.careers': {
    ro: 'Cariere',
    ru: 'Карьера',
    en: 'Careers',
  },
  'footer.affiliates': {
    ro: 'Afiliați',
    ru: 'Филиалы',
    en: 'Affiliates',
  },
  'footer.blog': {
    ro: 'Blog',
    ru: 'Блог',
    en: 'Blog',
  },
  'footer.scheduleOpen': {
    ro: '(deschis 8 de ore pe zi, 5 zile pe săptămână)',
    ru: '(открыт 8 часа в сутки, 5 дней в неделю)',
    en: '(open 8 hours a day, 5 days a week)',
  },
  'footer.privacyPolicy': {
    ro: 'Politica de Confidențialitate',
    ru: 'Политика конфиденциальности',
    en: 'Privacy policy',
  },
  'footer.termsAndConditions': {
    ro: 'Termeni și condiții',
    ru: 'Условия и положения',
    en: 'Terms and conditions',
  },
});

export default footer;
