import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect, useDispatch, useSelector, shallowEqual } from 'react-redux';
import { getEmployee, setEmployeeLoading } from '~/store/actions/EmployeeActions';
import { getRecents } from '~/store/actions/FilterActions';

const enhance = connect(
  ({ employee }) => ({ employee }),
  dispatch => bindActionCreators({ setEmployeeLoading, getEmployee, getRecents }, dispatch),
);

const withEmployee = () => Component =>
  enhance(({ match, employee = {}, ...props }) => {
    useEffect(() => {
      const onFetchEmployee = () => {
        window.scrollTo(0, 0);

        const onFetch = id => {
          props.setEmployeeLoading(true);

          return props.getEmployee(id);
        };

        if (Number(match.params.id) !== employee.id) {
          onFetch(match.params.id);
        }
      };

      onFetchEmployee();
    }, [match.params.id]);

    useEffect(() => {
      if (Object.keys(employee.data).length > 0) {
        props.getRecents();
      }
    }, [employee.data]);

    return <Component {...props} employee={employee} loading={employee.loading} />;
  });

export const useEmployee = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const store = useSelector(({ employee }) => ({ employee }), shallowEqual);

  const onFetch = id => {
    dispatch(setEmployeeLoading(true));

    return dispatch(getEmployee(id));
  };

  useEffect(() => {
    const onFetchEmployee = () => {
      window.scrollTo(0, 0);

      if (Number(params.id) !== store.employee.id) {
        onFetch(params.id);
      }
    };

    onFetchEmployee();
  }, [params.id]);

  useEffect(() => {
    if (store.employee.data && Object.keys(store.employee.data).length > 0) {
      getRecents();
    }
  }, [store.employee.data]);

  return {
    employee: store.employee,
    loading: store.employee.loading,
  };
};

export default withEmployee;
