import { adapterIntl } from '~/libs/localization/helpers';

const prospect = adapterIntl({
  'prospect.year': {
    ro: 'an',
    ru: 'год',
    en: 'year',
  },
  'prospect.years': {
    ro: 'ani',
    ru: 'лет',
    en: 'years',
  },
  'prospect.show_all': {
    ro: 'Arată toate',
    ru: 'Показать все',
    en: 'Show all',
  },
  'prospect.description': {
    ro: 'Ajutăm companiile din Republica Moldova să își crească sustenabil afacerea și să-și reducă riscurile.',
    ru: 'Помогаем компаниям Республики Молдовы маштабировать собственный бизнес и минимизировать возможные риски.',
    en: "We help Republic of Moldova's companies upscale their business and minimize potential risks.",
  },
  'prospect.search_in': {
    ro: 'Caută în',
    ru: 'Поиск в',
    en: 'Search in',
  },
  'prospect.discover_all': {
    ro: 'Descoperă toate',
    ru: 'Откройте для себя все',
    en: 'Discover all',
  },
  'prospect.companies': {
    ro: 'de companiile',
    ru: 'компании',
    en: 'companies',
  },
  'prospect.companies_plural': {
    ro: 'de companii',
    ru: 'компаний',
    en: 'companies',
  },
  'prospect.employees': {
    ro: 'de angajați',
    ru: 'работников',
    en: 'employees',
  },
  'prospect.employees_plural': {
    ro: 'de angajați',
    ru: 'работников',
    en: 'employees',
  },
  'prospect.radio_companies': {
    ro: 'Căutare companie',
    ru: 'Поиск компаний',
    en: 'Company search',
  },
  'prospect.radio_employees': {
    ro: 'Căutați angajați',
    ru: 'Поиск сотрудников',
    en: 'Search for employees',
  },
  'prospect.radio_type_companies': {
    ro: 'Companii',
    ru: 'Компании',
    en: 'Companies',
  },
  'prospect.radio_type_employees': {
    ro: 'Persoane',
    ru: 'Люди',
    en: 'Persons',
  },
  'prospect.search': {
    ro: 'Caută',
    ru: 'Поиск',
    en: 'Search',
  },
  'prospect.search_from': {
    ro: 'Caută din',
    ru: 'Поиск из',
    en: 'Search from',
  },
});

export default prospect;
