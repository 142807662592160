import axios from 'axios';
import cookies from 'react-cookies';
import env from '@beam-australia/react-env';
import { parseObject } from '~/libs/http/url';
import { history, getRoute } from '~/libs/router';

export const updateHeaders = application => {
  if (!cookies.load('localization')) {
    cookies.save('localization', 'ro', { path: '/' });
  }

  axios.defaults.headers['Accept-Language'] = cookies.load('localization');

  if (cookies.load('token')) {
    // eslint-disable-next-line i18n-text/no-en
    axios.defaults.headers.common.Authorization = `Token ${cookies.load('token')}`;
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
};
updateHeaders();

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

axios.defaults.baseURL = env('API_URL') || '';

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      // if user doesn't have permission/limit
      if (error.response.status === 403) {
        history.push(
          `${getRoute('MetricsPage')}${parseObject({
            detail: error.response.data.message_guard,
          })}`,
        );
      }

      // if user isn't logged
      if (error.response.status === 401) {
        cookies.remove('token', { path: '/' });
        cookies.save('redirect_url', window.location.pathname, { path: getRoute('SigninPage') });

        document.location.assign(getRoute('SigninPage'));
      }
    }

    return Promise.reject(error);
  },
);

const methodsToExport = () => {
  const toExport = {};

  ['get', 'post', 'put', 'delete', 'patch'].forEach(
    method =>
      (toExport[method] = (...params) => {
        updateHeaders();

        return axios[method](...params);
      }),
  );

  return toExport;
};

const axiosExport = { ...axios, ...methodsToExport() };

export default axiosExport;
