import React, { useState, useEffect } from 'react';
import { Button, Dropdown, Menu, Input, DatePicker, message } from 'antd';
import { useRequest } from 'estafette';
import { useSelector, shallowEqual } from 'react-redux';
import { useStateHandlers } from '~/hooks';
import { legalBulletin } from '~/libs/http/api';
import { useInjectIntl } from '~/libs/localization';
import { Icon } from '~/components/ui/Icon/Icon';
import { TableFooter } from '~/components/table';
import { Pagination, Loader } from '~/components/ui';
import { LegalBulletinInfo } from '~/components/legal-bulletin';

import '~/assets/scss/components/_LegalBulletinContent.scss';

const LegalBulletinContentMain = () => {
  const { t } = useInjectIntl();

  const store = useSelector(
    ({ legalBulletin: legalBulletinStore }) => ({
      legalBulletin: legalBulletinStore,
    }),
    shallowEqual,
  );

  const { request: requestBulletins, data: dataBulletins, loading: loadingBulletins } = useRequest();

  const [perPage] = useState(2);
  const [selectedBulletins, setSelectedBulletins] = useState([]);
  const [filter, setFilter] = useStateHandlers({
    search: null,
    page: 1,
    dateStart: null,
    dateEnd: null,
  });

  useEffect(() => {
    setFilter({ page: 1 });
  }, [store.legalBulletin.isRead]);

  useEffect(() => {
    if (store.legalBulletin?.bulletins) {
      onFetchBulletins();
    }
  }, [filter, store.legalBulletin.bulletins]);

  const onFetchBulletins = async () => {
    try {
      let params = {
        page: filter.page,
        per_page: perPage,
        date_start: filter.dateStart || '',
        date_end: filter.dateEnd || '',
        title: filter.search || '',
      };

      if (store.legalBulletin.isRead !== null && store.legalBulletin.isRead !== undefined) {
        params = {
          ...params,
          is_read: store.legalBulletin.isRead,
        };
      }

      if (store.legalBulletin.bulletins?.length) {
        const list = store.legalBulletin.bulletins.map(item => item).join(',');
        params = {
          ...params,
          industry: list,
        };
      }

      requestBulletins(legalBulletin.getBulletins(params));
    } catch {
      message.error(t('other.error_module'));
    }
  };

  const onAddUserList = () => {
    try {
      const params = {
        ids: selectedBulletins,
      };

      requestBulletins(legalBulletin.addToUserList(params));
      onFetchBulletins();
    } catch {
      message.error(t('other.error_module'));
    }
  };

  const onSetSearch = ({ target }) => setFilter({ search: target.value });

  const onChangePeriod = date => {
    if (date[0] && date[1]) {
      setFilter({ dateStart: date[0].format('YYYY-MM-DDThh:mm') });
      setFilter({ dateEnd: date[1].format('YYYY-MM-DDThh:mm') });
    } else {
      setFilter({ dateStart: null });
      setFilter({ dateEnd: null });
    }
  };

  const onChangePage = newPage => setFilter({ page: newPage });

  return (
    <div className="legal-bulletin-content">
      {selectedBulletins.length === 0 ? (
        <div className="page-header-bulletin normal">
          <div className="left">
            <div className="left-title">{t('legalBulletin.legalBulletins')}</div>

            <Input.Group compact>
              <Input.Search placeholder={t('legalBulletin.searchFor')} onChange={onSetSearch} />
            </Input.Group>
          </div>

          <div className="right">
            <DatePicker.RangePicker
              onChange={onChangePeriod}
              placeholder={[t('alerts.startDate'), t('alerts.endDate')]}
            />
          </div>
        </div>
      ) : (
        <div className="page-header-bulletin active">
          <div className="left">
            <div className="left-title">
              {selectedBulletins.length} {t('legalBulletin.selectedBulletin')}
            </div>
          </div>

          <div className="right">
            <Dropdown
              overlay={
                <Menu className="table-menu-tools">
                  {/* <Menu.Item>
                    <span>
                      <Icon type="bulletin-unload" />
                      {t('legalBulletin.unload')}
                    </span>
                  </Menu.Item> */}
                  <Menu.Item onClick={onAddUserList}>
                    <Icon type="bulletin-add-list" />
                    {t('legalBulletin.addToList')}
                  </Menu.Item>
                </Menu>
              }
              placement="bottomRight"
            >
              <Button>
                <Icon type="add-list-plus" />
                {t('legalBulletin.actions')}
              </Button>
            </Dropdown>
          </div>
        </div>
      )}

      <Loader loading={loadingBulletins}>
        <LegalBulletinInfo
          dataBulletins={dataBulletins}
          selectedBulletins={selectedBulletins}
          setSelectedBulletins={setSelectedBulletins}
        />
      </Loader>

      {dataBulletins?.pages && (
        <TableFooter leftSide={<Pagination page={filter.page} total={dataBulletins.pages} onChange={onChangePage} />} />
      )}
    </div>
  );
};

export default LegalBulletinContentMain;
