import { lazyRouter as lazy } from '~/libs/fs';

const EmailPage = lazy('public/signup/EmailPage/EmailPage');
const AdminAddProductPage = lazy('management/products/AdminAddProductPage');
const AdminAddUserPage = lazy('management/accounts/AdminAddUserPage');
const AdminAddAccountPage = lazy('management/accounts/AdminAddAccountPage');

// prettier-ignore
const routes = [
  { name: 'SigninPage', path: '/public/signin/:language?', component: lazy('public/SigninPage'), suspense: 'auth', isPublic: true, strict:true },
  { name: 'RecoveryPage', path: '/public/recovery-password', component: lazy('public/RecoveryPage'), suspense: 'auth', isPublic: true },
  { name: 'ResetPage', path: '/public/reset-password', component: lazy('public/ResetPage'), suspense: 'auth', isPublic: true },

  { name: 'SubscriptionSignupPage', path: '/public/subscription-sign-up/email/:id/:language?', component: EmailPage, suspense: 'auth', isPublic: true },
  { name: 'SubscriptionPaymentPage', path: '/public/subscription-payment/package', component: lazy('public/SubscriptionPage'), suspense: 'auth', isPublic: true },

  { name: 'SignupEmailPage', path: '/public/sign-up/email', component: EmailPage, suspense: 'auth', isPublic: true },
  { name: 'SignupPersonalPage', path: '/public/sign-up/personal', component: lazy('public/signup/PersonalPage'), suspense: 'auth', isPublic: true },
  { name: 'SignupSmsPage', path: '/public/sign-up/sms', component: lazy('public/signup/SmsPage'), suspense: 'auth', isPublic: true },
  { name: 'SignupValidationPage', path: '/public/sign-up/status/:type', component: lazy('public/signup/ValidationPage'), suspense: 'auth', isPublic: true },

  { name: 'HomePage', exact: true, path: '/', component: lazy('HomePage'), permission:'dashboard' },
  { name: 'ActivityPage', exact: true, path: '/activity', component: lazy('ActivityPage'), isPublic: true },
  { name: 'TermsAndConditionsPage', path: '/terms-and-conditions', component: lazy('profile/account/TermsAndConditionsPage') ,isPublic: true},

  { name: 'MyProfilePage', path: '/profile/my-settings', component: lazy('profile/MyProfilePage'), suspense: 'profile' , isPublic: true},
  { name: 'NotificationPage', path: '/profile/notifications', component: lazy('profile/NotificationPage'), suspense: 'profile' , isPublic: true},
  { name: 'DownloadsPage', path: '/profile/downloads/:type/page/:page', component: lazy('profile/downloads/DownloadsPage'), suspense: 'profile' , isPublic: true},
  { name: 'EventsPage', path: '/profile/events/page/:page', component: lazy('profile/EventsPage'), suspense: 'profile', isPublic: true },
  { name: 'HelpPage', path: '/profile/help', component: lazy('profile/HelpPage'), suspense: 'profile' , isPublic: true},
  { name: 'MyPackagePage', path: '/profile/my-package', component: lazy('profile/MyPackagePage'), suspense: 'profile', isPublic: true },
  { name: 'AddCompanyPage', path: '/profile/add-company', component: lazy('profile/company/AddCompanyPage'), suspense: 'profile', isPublic: true },
  { name: 'EditCompanyPage', path: '/account-profile/edit-company/:id', component: lazy('profile/company/EditCompanyPage'), suspense: 'profile', isPublic: true },

  { name: 'ApiPage', path: '/account-profile/api', component: lazy('profile/ApiPage'), suspense: 'profile', isPublic:true },
  { name: 'CreditsPage', path: '/account-profile/credits', component: lazy('profile/account/CreditsPage'), suspense: 'profile' , isPublic:true},
  { name: 'TeamPage', path: '/account-profile/team', exact: true, component: lazy('profile/account/TeamPage'), suspense: 'profile' , isPublic: true},
  { name: 'TeamChangePage', path: '/account-profile/team/change/:action/:id?', component: lazy('profile/account/TeamChangePage'), suspense: 'profile' , isPublic:true},
  { name: 'CompanyPage', path: '/account-profile/company', component: lazy('profile/account/CompanyPage'), suspense: 'profile', isPublic:true },
  { name: 'OrderPage', path: '/account-profile/order', component: lazy('profile/OrderPage'), suspense: 'profile' , isPublic:true},
  { name: 'OrderPaymentPage', path: '/account-profile/payment', component: lazy('profile/OrderPaymentPage'), suspense: 'profile', isPublic:true },
  { name: 'BillingPage', path: '/account-profile/billing', component: lazy('profile/account/BillingPage'), suspense: 'profile', isPublic:true },
  { name: 'MetricsPage', path: '/account-profile/metrics', component: lazy('profile/account/MetricsPage'), suspense: 'profile' , isPublic:true},

  { name: 'ExecutivePage', path: '/employee/:id', component: lazy('company/ExecutivePage') , isPublic:true},
  { name: 'GeneralsPage', path: '/company/:id/generals', component: lazy('company/GeneralsPage') , permission:'general_data_tab'},
  { name: 'EconomyPage', path: '/company/:id/economy', component: lazy('company/EconomyPage') , isPublic:true},
  { name: 'PersonalPage', path: '/company/:id/personal', component: lazy('company/PersonalPage'), isPublic:true },
  { name: 'PublicationsPage', path: '/company/:id/publications', component: lazy('company/PublicationsPage') , isPublic:true},
  { name: 'SubdivisionsPage', path: '/company/:id/subdivisions', component: lazy('company/SubdivisionsPage') , isPublic:true},
  { name: 'SubdivisionPage', path: '/subdivision/:id', component: lazy('company/SubdivisionPage'), isPublic:true },
  { name: 'ReorganizationPage', path: '/company/:id/juridical/reorganization', component: lazy('company/juridical/ReorganizationPage'), isPublic:true },
  { name: 'InsolvencyPage', path: '/company/:id/juridical/insolvency', component: lazy('company/juridical/InsolvencyPage'), isPublic:true},
  { name: 'JurisprudencePage', path: '/company/:id/juridical/jurisprudence', component: lazy('company/juridical/JurisprudencePage'), permission:'juridical_tab' },

  { name: 'AdminPlatformPage', path: '/management/updates/edit/:id', component: lazy('management/updates/AdminPlatformPage') ,permission:'managment'},
  { name: 'AdminAddPlatformPage', path: '/management/updates/add', component: lazy('management/updates/AdminAddPlatformPage') ,permission:'managment'},

  { name: 'AdminAccountsPage', exact: true, path: '/management/accounts', component: lazy('management/accounts/AdminAccountsPage') ,permission:'managment'},
  { name: 'AdminAddAccountPage',exact: true, path: '/management/accounts/add', component: AdminAddAccountPage ,permission:'managment'},
  { name: 'AdminUpdateAccountPage',exact: true, path: '/management/accounts/update/:id', component: AdminAddAccountPage ,permission:'managment'},
  
  { name: 'AdminUsersPage',exact: true, path: '/management/account-users/:id', component: lazy('management/accounts/AdminUsersPage') ,permission:'managment'},
  { name: 'AdminAddUserPage', exact: true, path: '/management/account-users/add/:id', component: AdminAddUserPage ,permission:'managment'},
  { name: 'AdminUpdateUserPage',exact: true, path: '/management/account-users/update/:id/:userId', component: AdminAddUserPage ,permission:'managment'},
  { name: 'AdminAccountProductPage', exact: true, path: '/management/account-product/:id', component: lazy('management/accounts/AdminAccountProductPage') ,permission:'managment'},
  { name: 'AdminAccountMetricsPage', path: '/management/account-activity/metrics/:id', component: lazy('management/activity/AdminAccountMetricsPage') ,permission:'managment'},
  { name: 'AdminAccountRequestsPage', path: '/management/account-activity/requests/:id', component: lazy('management/activity/AdminAccountRequestsPage') ,permission:'managment'},
  { name: 'TopCompanyPage', path: '/managment/top-comapny', component: lazy('management/company/TopCompanyPage'),permission:'managment'},
  { name: 'StatisticSettingsPage', path: '/managment/statistic-settings', component: lazy('management/statisticSettings/StatisticSettingsPage'),permission:'managment'},
  { name: 'AdminProductsPage', path: '/management/products', component: lazy('management/products/AdminProductsPage') ,permission:'managment'},
  { name: 'AdminAddProductPage', path: '/management/product/add', component: AdminAddProductPage ,permission:'managment'},
  
  { name: 'AdminUpdateProductPage', path: '/management/product/update/:id', component: AdminAddProductPage ,permission:'managment'},
  
  { name: 'AdminSupportPage', path: '/management/support/:type/page/:page', component: lazy('management/support/AdminSupportPage') ,permission:'managment'},

  { name: 'AdminFeedbacksPage', path: '/management/feedback/:type/page/:page', component: lazy('management/feedbacks/AdminFeedbacksPage') ,permission:'managment'},

  { name: 'AdminApiPage', path: '/management/api/', component: lazy('management/api/AdminApiPage') ,permission:'managment'},

  { name: 'AdminInvoicesPage', path: '/management/invoices/', component: lazy('management/invoices/AdminInvoicesPage') ,permission:'managment'},

  { name: 'SearchResultsPage', path: '/search/:type/mode/:mode', component: lazy('SearchResultsPage'), permission: 'exploration'},

  { name: 'Search', path: '/search/query/:page/:query?', component: lazy('Search'), permission:'search'},

  { name: 'Alerts', path: '/alerts/:mode/:filter?', component: lazy('Alerts'), permission:'alerts' },

  { name: 'Monitoring', path: '/monitoring/', component: lazy('Monitoring'), permission:'monitoring' },

  { name: 'Messages', path: '/messages/:mode/:filter?', component: lazy('Messages'), permission:'messages' },

  { name: 'LegalBulletin', path: '/legal-bulletin/', component: lazy('LegalBulletin'), permission:'legal_documents' },
  
  { name: 'LegalBulletinDetail', path: '/legal-bulletin-detail/:id', component: lazy('LegalBulletinDetail'), permission:'legal_documents' },

  { name: 'UpdatesPage', path: '/updates/:id', component: lazy('updates/UpdatesPage'), isPublic:true },
  
  { name: 'AlertsListsPage', path: '/saved/list/alerts/:id/page/:page', component: lazy('lists/AlertsListsPage') , isPublic:true},
  { name: 'SavedListsPage', path: '/saved/lists/page/:page', component: lazy('lists/SavedListsPage'), isPublic:true },
  { name: 'SavedSearchesPage', path: '/saved/searches/page/:page', component: lazy('lists/SavedListsPage') , isPublic:true},

  { name: 'DisconnectionsPage', path: '/profile/disconnections/list/:page', component: lazy('profile/disconnections/DisconnectionsPage'), suspense: 'profile' , isPublic: true},
  { name: 'DisconnectionsLocationPage', path: '/profile/disconnections/location/:id', component: lazy('profile/disconnections/DisconnectionsLocationPage'), suspense: 'profile' , isPublic: true},
];

export default routes;
