import React, { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Button, Popover, Icon, Select } from 'antd';
import { useRequest } from 'estafette';
import { useStateHandlers, useDocumentTitle } from '~/hooks';
import { indexedFirst } from '~/libs/array';
import { saveBlob } from '~/libs/saveBlob';
import { managementInvoices } from '~/libs/http/api';
import { useInjectIntl } from '~/libs/localization';
import { Layout } from '~/components/layouts';
import { PopAction } from '~/components/profile';
import { Loader, Confirmation, Empty, Table } from '~/components/ui';
import { ManagementMenu } from '~/components/management';
import { TableHeader } from '~/components/table';
import { Time } from '~/components/other';
import NotFoundPage from '~/pages/NotFoundPage';

const { Option } = Select;

const AdminInvoicesPage = () => {
  const { request, data, loading } = useRequest({ data: { data: [] }, loading: true });
  const { request: requestDelete, loading: loadingDelete } = useRequest();
  const { request: requestApprove, loading: loadingApprove } = useRequest();
  const [state, setState] = useStateHandlers({
    page: 1,
    loadingExport: false,
    loadingExportId: null,
    status: false,
  });
  const { t } = useInjectIntl();

  const mainLoading = loading && data.data.length === 0;

  useDocumentTitle(t('page.list'));

  const onFetch = () => {
    request(managementInvoices.list({ page: state.page, per_page: 15, status: state.status }), {
      concat: state.page > 1 && 'data',
    });
  };

  useEffect(() => {
    onFetch();
  }, [state.page, loadingDelete, loadingApprove, state.status]);

  const onChangePage = async page => {
    window.scrollTo(0, window.scrollY - 25);

    setState({ page });
  };

  const onApprove = id => {
    requestApprove(managementInvoices.approve({ id }));
  };

  const onDelete = id => {
    requestDelete(managementInvoices.delete({ id }));
  };

  const getInvoice = async (id, fileName = 'invoice.pdf') => {
    setState({ loadingExport: true, loadingExportId: id });

    const responseInvoice = await managementInvoices.invoice({ id });

    setState({ loadingExport: false });

    saveBlob(responseInvoice.data, fileName);
  };

  const onChangeStatus = status => setState({ status: status !== 'wait_payment' });

  const columns = [
    {
      title: t({ id: 'management.N' }),
      dataIndex: 'key',
      render: key => <span className="title">{key}</span>,
    },
    {
      title: t({ id: 'other.name' }),
      dataIndex: 'username',
      render: (username, record) => (
        <>
          <div>
            <span className="title">
              <b>{record.account_name}</b>
            </span>
          </div>
          <div>
            <span className="sub-title">{username}</span>
          </div>
        </>
      ),
    },
    {
      title: t({ id: 'management.export_name' }),
      dataIndex: 'export_name',
      render: exportName => <span className="title" dangerouslySetInnerHTML={{ __html: exportName || <Empty /> }} />,
    },
    {
      title: t({ id: 'management.invoice_nr' }),
      dataIndex: 'invoice',
      render: invoice => <span className="title">{invoice || <Empty />}</span>,
    },
    {
      title: t({ id: 'management.created_data' }),
      dataIndex: 'date_created',
      render: (date, record) => {
        return (
          <span className="title fix-width">
            <Time date={record.date_created} relative prefix />
          </span>
        );
      },
    },
    {
      dataIndex: 'get_invoice',
      render: (row, record) => (
        <Button
          loading={state.loadingExport && state.loadingExportId === record.id}
          disabled={state.loadingExport}
          onClick={() => {
            getInvoice(record.id, `${record.invoice}.pdf`);
          }}
        >
          {t('management.invoice')}
        </Button>
      ),
    },
    {
      dataIndex: 'approve',
      render: (row, record) => (
        <Button
          type="primary"
          disabled={state.status}
          onClick={() => {
            onApprove(record.id);
          }}
        >
          {t('management.approve')}
        </Button>
      ),
    },
    {
      dataIndex: 'delete',
      render: (row, record) =>
        !state.status && (
          <Popover
            content={
              <PopAction
                icon="delete"
                value={
                  <Confirmation onConfirm={() => onDelete(record.id)}>
                    <span>{t('management.delete_auth_key')}</span>
                  </Confirmation>
                }
              />
            }
            trigger="click"
          >
            <div className="popover-more">
              <Icon type="ellipsis" className="btn-ellipsis" />
            </div>
          </Popover>
        ),
    },
  ];

  const statuses = {
    wait_payment: t({ id: 'other.wait_payment' }),
    paid: t({ id: 'other.paid' }),
  };

  return (
    <Layout>
      <ManagementMenu />

      <Loader loading={mainLoading || loadingDelete || loadingApprove}>
        <InfiniteScroll
          initialLoad={false}
          pageStart={1}
          loadMore={onChangePage}
          hasMore={state.page < data.pages && !loading}
        >
          {data.data.length ? (
            <Table
              className="table-clean table-header table-odd table-alerts-company"
              columns={columns}
              dataSource={indexedFirst(data.data)}
              rowKey="id"
              title={() => (
                <TableHeader
                  rightSide={
                    <div className="form-inline">
                      <Select
                        allowClear
                        placeholder={t({ id: 'other.status' })}
                        defaultValue={statuses.wait_payment}
                        dropdownClassName="credit-alerts-dropdown"
                        onChange={onChangeStatus}
                        dropdownMatchSelectWidth={false}
                      >
                        {Object.keys(statuses).map(item => (
                          <Option value={item} key={item}>
                            {/* {statuses[item]} */}
                            {t({ id: `other.${item}` })}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  }
                />
              )}
            />
          ) : (
            <NotFoundPage />
          )}
        </InfiniteScroll>
      </Loader>
    </Layout>
  );
};

export default AdminInvoicesPage;
