/* eslint-disable */
export const has = (baseObj, pathToAttr, defaultValue) =>
  (typeof pathToAttr === 'string' &&
    pathToAttr
      .replace(/\[|\]\[|\]\./g, '.')
      .split('.')
      .reduce((prev, curr) => prev && prev[curr], baseObj)) ||
  defaultValue ||
  undefined;
/* eslint-enable */

export const validate = (errors, key, validateStatus = 'error') =>
  errors[key]
    ? {
        validateStatus,
        help: errors[key],
      }
    : null;
