import cookies from 'react-cookies';

const initialState = {
  isAuth: cookies.load('token') !== undefined,
  token: cookies.load('token') || '',
  permissions: [],
  product: {},
  params: {},
  loading: true,
};

export const authentication = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_USER_PARAMS': {
      const { permissions, product, ...params } = action.payload;

      return Object.assign({}, state, {
        permissions,
        product,
        params,
        loading: false,
      });
    }
    case 'UPDATE_SETTINGS': {
      return JSON.parse(JSON.stringify({ ...state, params: action.payload }));
    }
    case 'UPDATE_LOADING': {
      return { ...state, loading: action.payload };
    }
    case 'UPDATE_TOKEN': {
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + (action.payload.rememberMe ? 30 : 2));
      cookies.save('token', action.payload.token, { path: '/', expires: expirationDate });

      return Object.assign({}, state, {
        isAuth: true,
        token: action.payload,
      });
    }
    case 'DELETE_TOKEN': {
      cookies.remove('token', { path: '/' });

      return Object.assign({}, state, {
        isAuth: false,
        token: action.payload,
      });
    }
    default:
      return state;
  }
};
