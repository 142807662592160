import React, { useEffect } from 'react';
import { LocaleProvider } from 'antd';
import ruRU from 'antd/lib/locale-provider/ru_RU';
import enGB from 'antd/lib/locale-provider/en_GB';
import { Router as BrowserRouter, Switch } from 'react-router-dom';
import { Provider, useDispatch, useSelector, shallowEqual } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { useNotificationSocket } from '~/hooks';
import { Router, history, getRoute } from '~/libs/router';
import { notification } from '~/libs/notification';
import { useInjectIntl } from '~/libs/localization';
import { localization as $localization } from '~/libs/localization/translations';
import { configureStore } from '~/store';
import { getParams } from '~/store/actions/AuthenticationActions';
import { getNomenclatures } from '~/store/actions/FilterActions';
import { setLocalizationLoading } from '~/store/actions/LocalizationActions';

const configuredStore = configureStore();

let timer;
const Providers = props => {
  const { t } = useInjectIntl();
  const { data } = useNotificationSocket();
  const dispatch = useDispatch();
  const store = useSelector(({ localization, authentication }) => ({ localization, authentication }), shallowEqual);

  useEffect(() => {
    if (store.authentication.isAuth) {
      if (data && data.event_type === 'export') {
        notification({
          duration: 5,
          message: t('notification.export.message', { title: data.details || '' }),
          direction: t('notification.export.direction'),
          onClick: () => history.push(getRoute('DownloadsPage', { type: 'list', page: 1 })),
        });
      }

      if (data && data.event_type === 'company') {
        notification({
          duration: 5,
          message: t('notification.company_update.message', { title: data.details || '' }),
          direction: t('notification.company_update.direction'),
          onClick: () => history.push(getRoute('GeneralsPage', { id: data.object_id })),
        });
      }
    }
  }, [data, store.authentication.isAuth]);

  useEffect(() => {
    if (store.authentication.isAuth) {
      dispatch(getNomenclatures());
      dispatch(getParams());
    }
  }, [store.authentication.isAuth, store.localization.current]);

  useEffect(() => {
    const onChangeLoading = () => {
      if (store.localization.current !== undefined && store.localization.current !== null) {
        if (Object.keys(store.localization.current).length > 0) {
          !store.localization.loading && dispatch(setLocalizationLoading(true));

          timer = setTimeout(() => {
            dispatch(setLocalizationLoading(false));
          }, 1000);
        }
      }
    };

    onChangeLoading();

    return () => {
      timer && clearTimeout(timer);
    };
  }, [store.localization.current]);

  return (
    <LocaleProvider locale={store.localization.current?.shortName === 'ru' ? ruRU : enGB}>
      <BrowserRouter history={history}>
        <Switch>
          <Router loading={store.localization.loading} />
        </Switch>
      </BrowserRouter>
    </LocaleProvider>
  );
};

const IntlLocaleProvider = ({ children }) => {
  const store = useSelector(({ localization }) => ({ localization }), shallowEqual);

  return (
    <IntlProvider
      locale={store.localization.current ? store.localization.current.shortName : 'ro'}
      messages={$localization[store.localization.current ? store.localization.current.shortName : 'ro']}
    >
      <Providers />
    </IntlProvider>
  );
};

export const CreateRouter = () => (
  <Provider store={configuredStore}>
    <IntlLocaleProvider>
      <Providers />
    </IntlLocaleProvider>
  </Provider>
);
