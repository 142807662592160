import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import 'core-js/features/url-search-params';
import { DraggableModalProvider } from 'ant-design-draggable-modal';

import React from 'react';
import { render } from 'react-dom';
import * as Sentry from '@sentry/browser';
import env from '@beam-australia/react-env';
import { CreateRouter } from '~/libs/router';
import ErrorBoundary from '~/components/ErrorBoundary';

import '~/assets/scss/index.scss';

if (env('NODE_ENV') === 'production' && env('SENTRY_DSN')) {
  Sentry.init({ dsn: env('SENTRY_DSN') });
}

export const App = () => (
  <ErrorBoundary>
    <DraggableModalProvider>
      <CreateRouter />
    </DraggableModalProvider>
  </ErrorBoundary>
);

render(<App />, document.getElementById('root'));
