import React, { useState, useEffect } from 'react';
import { Modal, Input, Alert } from 'antd';
import { useRequest } from 'estafette';
import { legalBulletin } from '~/libs/http/api';
import { useInjectIntl } from '~/libs/localization';
import { Button } from '~/components/ui';

export const LegalBulletinModalEditFolder = ({ handleCancel, onRefetch, onRefetchDelete, folderEditName, type }) => {
  const { t } = useInjectIntl();
  const { request, loading, errors } = useRequest();
  const { request: requestDelete, loading: loadingDelete } = useRequest();

  const [actName, setActName] = useState('');

  useEffect(() => {
    if (!folderEditName || !type) {
      setActName(folderEditName);
    }
  }, [folderEditName]);

  const onSubmit = async () => {
    await request(
      legalBulletin.editFolder({
        title: actName,
        id: type,
      }),
    );

    handleCancel();
    onRefetch();
  };

  const onDelete = async () => {
    await requestDelete(
      legalBulletin.deleteFolder({
        id: type,
      }),
    );

    handleCancel();
    onRefetchDelete();
  };

  const onChangeActName = ({ target }) => setActName(target.value);

  return (
    <Modal
      title={t('legalBulletin.editFolder')}
      width="700px"
      visible
      editOk={onSubmit}
      onCancel={handleCancel}
      footer={null}
      className="normative-act-modal"
    >
      {errors.detail || errors.non_field_errors ? (
        <Alert message={errors.detail || errors.non_field_errors} className="mb-20" type="warning" />
      ) : null}

      <div className="normative-act-form">
        <label>
          <div>{t('legalBulletin.editFolder')}</div>

          <Input defaultValue={folderEditName || t('legalBulletin.nameList')} onChange={onChangeActName} />

          {errors.title ? <span className="modal-error-messages">{errors.title}</span> : null}
        </label>
      </div>

      <div className="edit-act-modal">
        <div className="right-side">
          <Button key="submit" type="primary" onClick={onSubmit} loading={loading}>
            {t('legalBulletin.apply')}
          </Button>

          <Button key="back" onClick={handleCancel}>
            {t('legalBulletin.cancel')}
          </Button>
        </div>

        <div className="left-side">
          <Button key="back" onClick={onDelete} loading={loadingDelete}>
            {t('legalBulletin.deleteFolder')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
