import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useRequest } from 'estafette';
import { useInjectIntl } from '~/libs/localization';
import { Input, Radio, Checkbox } from 'antd';
import env from '@beam-australia/react-env';
import { Link, getRoute } from '~/libs/router';
import { profile, members } from '~/libs/http/api';
import { ProfileLayout } from '~/components/layouts';
import { Avatar } from '~/components/profile';
import { Section, Form, Button } from '~/components/ui';
import { validate } from '~/libs/object';
import { Icon } from '~/components/ui/Icon/Icon';

import '~/assets/scss/components/_MyProfile.scss';
import '~/assets/scss/components/_TeamChange.scss';

const TeamChangePage = () => {
  const { t } = useInjectIntl();
  const params = useParams();
  const history = useHistory();
  const { request, loading: loadingData, data } = useRequest({ data: { permission_types: [] } });
  const { request: requestSeniorities, errors } = useRequest();
  const { request: requestSubmit, loading: loadingSubmit, errors: errorsSubmit } = useRequest();
  const { request: requestCompanyDetails, data: companyName } = useRequest();
  const { request: requestDelete } = useRequest();

  const [api, setApi] = useState({ permission_types: [] });
  const [preview, setPreview] = useState('');
  const [companyId, setCompanyId] = useState(null);
  const [valueRadio, setValueRadio] = React.useState();
  const [savePhoto, setSavePhoto] = useState(null);

  useEffect(() => {
    const onChangeApi = async () => {
      if (params.action === 'edit') {
        const res = await request(members.get({ id: params.id }));

        setApi({
          id: res.id,
          first_name: res.first_name,
          last_name: res.last_name,
          phone: res.phone,
          seniority_id: res.seniority_id,
          perm_type: res.permission_types.filter(item => item.active).map(item => item.id),
          is_approved: res.is_approved,
          photo: res.photo,
          email: res.email,
        });
      }

      requestSeniorities(profile.profileSeniority());
    };

    onChangeApi();
  }, []);

  useEffect(() => {
    const onPermission = async () => {
      if (params.action === 'add') {
        setCompanyId(params.id);
        await request(profile.get());
      }
    };
    onPermission();
  }, []);

  useEffect(() => {
    if (data) {
      setValueRadio(data.seniority_id);
    }
  }, [data]);

  useEffect(() => {
    if (params.id && params.action === 'add') {
      requestCompanyDetails(members.companyDetailId({ id: params.id }));
    }
  }, [params.id]);

  const submit = async () => {
    const { photo, perm_type: permType = [], job_title: _, ...rest } = api;
    const isEdit = params.action === 'edit';

    const mappedPermType = data.permission_types.map(item => ({
      permission_type: item.id,
      active: permType.includes(item.id),
    }));

    if (isEdit) {
      if (photo && !savePhoto) {
        await requestSubmit(members.edit({ ...rest, perm_type: mappedPermType }));
        history.push(getRoute('TeamPage'));
      }

      if (!photo) {
        const formData = new FormData();
        formData.append('delete_photo', true);
        formData.append('id', rest.id);
        formData.append('is_approved', true);

        await requestSubmit(members.edit({ ...rest, perm_type: mappedPermType }));
        await requestSubmit(members.edit(formData));

        history.push(getRoute('TeamPage'));
      }

      const formData = new FormData();
      formData.append('id', rest.id);
      formData.append('photo', api.photo);
      formData.append('is_approved', true);

      await requestSubmit(members.edit({ ...rest, perm_type: mappedPermType }));
      if (savePhoto) {
        await requestSubmit(members.edit(formData));
      }
      history.push(getRoute('TeamPage'));
    }

    if (!isEdit) {
      const paramsData = {
        first_name: rest.first_name || '',
        last_name: rest.last_name || '',
        email: rest.email || '',
        phone: rest.phone || '',
        job_title: '',
        seniority_id: rest.seniority_id || '',
        address: '',
        redirect_url: getRoute('RecoveryPage'),
        account: Number(companyId),
      };

      const { id } = await requestSubmit(members.add({ ...paramsData, perm_type: mappedPermType }));

      if (preview) {
        const formData = new FormData();
        formData.append('photo', savePhoto);
        formData.append('id', id);
        formData.append('is_approved', true);
        requestSubmit(members.edit(formData));
      }

      history.push(getRoute('TeamPage'));
    }
  };

  const onDeleteUser = id => {
    requestDelete(members.delete({ id })).then(() => {
      history.push(getRoute('TeamPage'));
    });
  };

  const onChangeFile = ({ target }) => {
    const photo = target.files[0];
    const reader = new FileReader();

    reader.readAsDataURL(photo);

    reader.onloadend = () => {
      setApi({
        ...api,
        photo,
      });

      setPreview(reader.result);
    };

    setSavePhoto(photo);
  };

  const onDeletePhoto = () => {
    setPreview('');
    setApi({ ...api, photo: '' });
  };

  const changeSeniority = event => {
    setApi({ ...api, seniority_id: event.target.value });
    setValueRadio(event.target.value);
  };

  const onChange = newPermType => {
    setApi(prevApi => ({ ...prevApi, perm_type: newPermType }));
  };

  const plainOptions = React.useMemo(
    () => data.permission_types.map(item => ({ value: item.id, label: item.languages.en })),
    [data],
  );

  return (
    <ProfileLayout loading={loadingData} className="person-detail">
      <div className="title">{t({ id: 'profile.detailsOfThePerson' })}</div>

      <div className="half-sections no-half-section team-section">
        <Section>
          <div className="profile-avatar">
            <Avatar.Editable
              img={preview.length ? preview : api.photo ? `${env('API_URL') || ''}/${api.photo || ''}` : null}
              alt={`${api.first_name || ''} ${api.last_name || ''}`}
              onClose={onDeletePhoto}
            />

            <h2>{t('team.upload_photo')}</h2>

            <label className="atn-file">
              <input type="file" accept="image/*" onChange={onChangeFile} />
              {t('team.upload_a_photo')}
            </label>

            <Button onClick={onDeletePhoto}>{t('team.delete_photo')}</Button>
          </div>
        </Section>

        <Section>
          <Form>
            <Form.Error message={errors.detail} />

            <div className="sub-title sub-first-title">{t('profile.personSettings')}</div>

            <Form.Item label={t('profile.companyName')} {...validate(errorsSubmit, 'first_name')}>
              <h4>{params.action === 'edit' ? data.company_name : companyName.name}</h4>
            </Form.Item>

            <div className="main-info">
              <Form.Half>
                <Form.Item label={t('profile.name')} {...validate(errorsSubmit, 'first_name')}>
                  <Input
                    type="text"
                    placeholder={t('other.typeHere')}
                    value={api.first_name}
                    onChange={({ target }) => {
                      setApi({ ...api, first_name: target.value });
                    }}
                  />
                </Form.Item>

                <Form.Item label={t('profile.lastName')} {...validate(errorsSubmit, 'last_name')}>
                  <Input
                    type="text"
                    placeholder={t('other.typeHere')}
                    value={api.last_name}
                    onChange={({ target }) => {
                      setApi({ ...api, last_name: target.value });
                    }}
                  />
                </Form.Item>
              </Form.Half>

              <Form.Half>
                <Form.Item label={t('other.email')} {...validate(errorsSubmit, 'email')}>
                  <Input
                    type="text"
                    placeholder={t('other.typeHere')}
                    value={api.email}
                    onChange={({ target }) => {
                      setApi({ ...api, email: target.value });
                    }}
                  />
                </Form.Item>

                <Form.Item label={t('other.telephone')} {...validate(errorsSubmit, 'phone')}>
                  <Input
                    type="text"
                    placeholder={t('other.typeHere')}
                    value={api.phone}
                    onChange={({ target }) => {
                      setApi({ ...api, phone: target.value });
                    }}
                  />
                </Form.Item>
              </Form.Half>

              <Form.Item {...validate(errorsSubmit, 'seniority_id')}>
                <div className="sub-title sub-title-2">{t('profile.isAdmin')}</div>

                <Radio.Group
                  onChange={e => {
                    changeSeniority(e);
                  }}
                  value={valueRadio}
                >
                  <Radio value={1}>{t('profile.no')}</Radio>
                  <Radio value={2}>{t('profile.yes')}</Radio>
                </Radio.Group>
              </Form.Item>
            </div>

            <Form.Item {...validate(errorsSubmit, 'perm_types')}>
              <div className="sub-title">{t('profile.accessSettings')}</div>
              <div className="col">
                <Checkbox.Group
                  options={plainOptions}
                  onChange={onChange}
                  value={api.perm_type}
                  className="access-settings-group"
                />
              </div>
            </Form.Item>

            <div className="form-footer">
              <Link route="TeamPage">
                <Button>{t('other.reset')}</Button>
              </Link>

              <Button type="primary" onClick={() => submit()} loading={loadingSubmit}>
                {t('other.submit')}
              </Button>

              {params.action === 'edit' && (
                <Link route="TeamPage">
                  <span className="remove-person" onClick={() => onDeleteUser(api.id)}>
                    <Icon type="trash" />
                    {t('profile.deletePerson')}
                  </span>
                </Link>
              )}
            </div>
          </Form>
        </Section>
      </div>
    </ProfileLayout>
  );
};

export default TeamChangePage;
