import React from 'react';
import { connect } from 'react-redux';
import { Tooltip } from 'antd';
import { Button, Text, Confirmation } from '~/components/ui';
import { Icon } from '~/components/ui/Icon/Icon';

import '~/assets/scss/components/ui/_ListDetailsSelected.scss';

const ListDetailsSelected = ({ permissions, ...props }) => (
  <div className="list-details-selected">
    <div className="counter-section">
      <Icon type="checked" />

      <span className="counter">
        {props.counter} <Text id="other.selections" />
      </span>
    </div>

    <div className="actions">
      {props.onSaveList !== undefined && permissions.includes('saved_list') && (
        <Tooltip title={<Text id="other.save" />} overlayClassName="zh-fixed-tooltip" onClick={props.onSaveList}>
          <div className="action">
            <Icon type="save-list" />
          </div>
        </Tooltip>
      )}

      {props.onDownload !== undefined && permissions.includes('export') && (
        <Tooltip title={<Text id="other.download" />} overlayClassName="zh-fixed-tooltip" onClick={props.onDownload}>
          <div className="action">
            <Icon type="export" />
          </div>
        </Tooltip>
      )}

      {props.onInvertSelections !== undefined && (
        <Tooltip
          title={<Text id="other.invertSelection" />}
          overlayClassName="zh-fixed-tooltip"
          onClick={props.onInvertSelections}
        >
          <div className="action">
            <Icon type="invert" />
          </div>
        </Tooltip>
      )}

      {props.onMarkAsRead !== undefined && (
        <Tooltip
          title={<Text id="other.markAsRead" />}
          overlayClassName="zh-fixed-tooltip"
          onClick={props.onMarkAsRead}
        >
          <div className="action">
            <Icon type="read" />
          </div>
        </Tooltip>
      )}

      {props.onDeleteItems !== undefined && (
        <Confirmation type="delete" onConfirm={() => props.onDeleteItems()}>
          <Tooltip title={<Text id="other.deleteItems" />} overlayClassName="zh-fixed-tooltip">
            <div className="action">
              <Icon type="delete" />
            </div>
          </Tooltip>
        </Confirmation>
      )}

      {props.onClearAll !== undefined && (
        <Button smooth prefix={<Icon type="close" />} onClick={props.onClearAll} className="clear-all-button">
          <span>
            <Text id="other.cleanSelection" />
          </span>
        </Button>
      )}
    </div>
  </div>
);

export default connect(({ authentication }) => ({ permissions: authentication.permissions }))(ListDetailsSelected);
