import React, { memo, useState } from 'react';
import classnames from 'classnames';
import { compose } from 'redux';
import { injectIntl } from '~/libs/localization';
import { parseWebsite } from '~/libs/string';
import { Tooltip } from 'antd';

const HiddenContact = ({ value, icon, type, t, target }) => {
  const [show, setShow] = useState(false);

  const onShow = () => {
    if (value) {
      setShow(true);
    }
  };

  return (
    <span onClick={onShow} className={classnames('zh-hidden-contact', { cursor: !show, disabled: !value })}>
      {show ? (
        <>
          {type === 'email' && (
            <Tooltip title={value.length > 19 && value}>
              <a href={`mailto://${value}`}>
                {icon} {value}
              </a>
            </Tooltip>
          )}

          {type === 'website' && (
            <a
              href={value}
              className="hidden-link"
              title={parseWebsite(value)}
              target={target ?? target}
              rel="noopener noreferrer"
            >
              {parseWebsite(value) || '---'}
            </a>
          )}

          {type !== 'email' && type !== 'website' && (
            <span>
              {icon} {value}
            </span>
          )}
        </>
      ) : (
        <span>
          {icon} {t(`other.hidden_${type}`)}
        </span>
      )}
    </span>
  );
};

const enhance = compose(injectIntl, memo);

export default enhance(HiddenContact);
