import { filterStore } from '~/store';
import { getFilterKeys } from '~/libs/filter';
import { mutationByDefault, mutationWithinChildren, synchronization } from '~/libs/mutation';

export const initialState = {
  loading: true,
  currentFilter: 1,
  showFilter: false,
  showFilterInner: false,
  filter: filterStore,
  activeFilter: {},
  employeesKeys: getFilterKeys('employee', filterStore),
  subdivisionsKeys: getFilterKeys('subdivision', filterStore),
  recents: [],
  nomenclatures: {},
};

export const filter = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_RECENTS':
      return Object.assign({}, state, { recents: action.payload });
    case 'GET_NOMENCLATURES':
      return {
        ...synchronization(state, action.payload),
        nomenclatures: action.payload,
      };
    case 'CHANGE_FILTER': {
      const cloneState = Object.assign({}, state);
      cloneState.filter.forEach(item => (item.open = action.payload === item.id));

      return {
        ...cloneState,
        showFilter: true,
        showFilterInner: true,
        filter: [...cloneState.filter],
      };
    }
    case 'CHANGE_SUB_FILTER': {
      const cloneState = Object.assign({}, state);
      cloneState.filter[action.payload.key].children.forEach(
        item => (item.open = !item.open && action.payload.name === item.name),
      );

      return {
        ...cloneState,
        filter: [...cloneState.filter],
      };
    }
    case 'OPEN_FILTER':
      return Object.assign({}, state, { showFilter: true, currentFilter: action.payload || state.currentFilter });
    case 'CLOSE_FILTER':
      return Object.assign({}, state, { showFilter: false });
    case 'OPEN_FILTER_INNER':
      return Object.assign({}, state, { showFilterInner: true });
    case 'CLOSE_FILTER_INNER':
      return Object.assign({}, state, { showFilterInner: false });
    case 'SET_ACTIVE_FILTER':
      return Object.assign({}, state, { activeFilter: action.payload });
    case 'SET_COMPANY_NAME':
      return {
        ...state,
        activeFilter: { company_name: action.payload },
      };

    case 'DELETE_TAG': {
      const activeFilter = { ...state.activeFilter };
      delete activeFilter[action.payload];

      return {
        ...state,
        activeFilter,
      };
    }
    case 'MUTATE_FILTER_INPUT_SEARCH':
      return mutationByDefault(action.payload.name, state, action.payload.value, action.payload.companyHasCriterion);
    case 'MUTATE_FILTER_INPUT':
      return mutationByDefault(action.payload.name, state, action.payload.value, action.payload.companyHasCriterion);
    case 'MUTATE_FILTER_CHECKBOX':
      return mutationByDefault(action.payload.name, state, action.payload.value, action.payload.companyHasCriterion);
    case 'MUTATE_FILTER_RADIOBOX':
      return mutationByDefault(action.payload.name, state, action.payload.value, action.payload.companyHasCriterion);
    case 'MUTATE_FILTER_TREE':
      return mutationByDefault(action.payload.name, state, action.payload.value, action.payload.companyHasCriterion);
    case 'MUTATE_FILTER_TREE_POPUP':
      return mutationByDefault(action.payload.name, state, action.payload.value, action.payload.companyHasCriterion);
    case 'MUTATE_FILTER_NUMBERS':
      return mutationWithinChildren(
        action.payload.name,
        state,
        action.payload.value,
        action.payload.key,
        action.payload.companyHasCriterion,
      );
    case 'MUTATE_FILTER_TURNOVER':
      return mutationWithinChildren(
        action.payload.name,
        state,
        action.payload.value,
        action.payload.key,
        action.payload.companyHasCriterion,
      );
    case 'MUTATE_FILTER_DATES':
      return mutationWithinChildren(
        action.payload.name,
        state,
        action.payload.value,
        action.payload.key,
        action.payload.companyHasCriterion,
      );
    case 'MUTATE_FILTER_PERCENTAGES':
      return mutationWithinChildren(
        action.payload.name,
        state,
        action.payload.value,
        action.payload.key,
        action.payload.companyHasCriterion,
      );
    case 'MUTATE_FILTER_DATE':
      return mutationWithinChildren(
        action.payload.name,
        state,
        action.payload.value,
        action.payload.key,
        action.payload.companyHasCriterion,
      );
    default:
      return state;
  }
};
