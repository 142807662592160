import { Component } from 'react';
import * as Sentry from '@sentry/browser';
import env from '@beam-australia/react-env';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      eventId: null,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (env('NODE_ENV') === 'production') {
      Sentry.withScope(scope => {
        scope.setExtras(errorInfo);

        const eventId = Sentry.captureException(error);

        this.setState({ eventId });
      });
    }
  }

  render() {
    // TODO: in case when this.state.hasError = true is necessary to show for user some view
    return this.props.children;
  }
}

export default ErrorBoundary;
