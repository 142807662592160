import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Layout } from '~/components/layouts';
import { useRouteMatch } from 'react-router-dom';
import { SearchStart, SearchResults, SearchInstruction } from '../components/search';

import '~/assets/scss/components/_Search.scss';

const Search = () => {
  const match = useRouteMatch();
  const store = useSelector(
    ({ filter, searches, authentication, companies }) => ({
      showFilter: filter.showFilter,
      permissions: authentication.permissions,
      activeFilter: filter.activeFilter,
      filter: filter.filter,
      searches,
      companies,
    }),
    shallowEqual,
  );

  return (
    <Layout filter rootClass={store.showFilter ? 'filter-open' : ''} containerClass="search-container">
      <div className="search-start search-results">
        <SearchStart />

        {match.params.query && <SearchResults />}
      </div>

      {!store.companies.data?.total_results && <SearchInstruction />}
    </Layout>
  );
};

export default Search;
