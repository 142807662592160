import React, { Fragment } from 'react';

const SeparatedList = ({ data }) => {
  const filteredData = data.filter(i => i);

  return filteredData.length > 0
    ? filteredData.map((item, key) => (
        <Fragment key={item}>
          {key !== 0 && <span className="span-separator">•</span>}

          {item}
        </Fragment>
      ))
    : null;
};

export default SeparatedList;
