import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import {
  HighchartsChart,
  Chart as Hightchart,
  withHighcharts,
  LineSeries,
  YAxis,
  XAxis,
  Tooltip,
} from 'react-jsx-highcharts';
import { injectIntl } from '~/libs/localization';
import { Loader } from '~/components/ui';

let loadingTimer;
const Chart = injectIntl(({ data, t }) => {
  const [loading, setLoading] = useState(true);
  const [graphData, setGraphData] = useState([]);

  useEffect(() => () => clearTimeout(loading), []);

  useEffect(() => {
    const rearageData = graph => {
      if (graph && graph.length > 0)
        graph.sort((a, b) => {
          const keyA = a.year;
          const keyB = b.year;
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        });

      setGraphData(graph);
    };

    rearageData(data);
  }, [data]);

  useEffect(() => {
    const onChangeLoading = () => {
      clearTimeout(loadingTimer);

      setLoading(true);

      loadingTimer = setTimeout(() => {
        setLoading(false);
      }, 1000);
    };

    onChangeLoading();
  }, [data]);

  if (loading) {
    return <Loader loading={loading} height={400} />;
  }

  return (
    <HighchartsChart
      plotOptions={{
        series: {
          pointStart: graphData && graphData.length ? graphData[0].year : 0,
        },
      }}
    >
      <Hightchart />
      <XAxis allowDecimals={false}>
        <XAxis.Title />
      </XAxis>

      <Tooltip
        useHTML
        borderWidth={1}
        shadow={false}
        hideDelay={1000}
        backgroundColor="#fff"
        borderColor="#e8f2fb"
        borderRadius={0}
        padding={5}
        headerFormat={'<span style="display: none;"></span>'}
        pointFormat={'<b class="chart-tooltip">{point.y}</b>'}
      />
      <YAxis id="number">
        <LineSeries
          name={t({ id: 'other.chart' })}
          data={
            graphData && graphData.length
              ? graphData.map(key => key.turnover)
              : Array.from({ length: 6 }, () => Math.floor(Math.random() * 600))
          }
        />
      </YAxis>
    </HighchartsChart>
  );
});

export default withHighcharts(Chart, Highcharts);
