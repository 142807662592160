import axios from '~/libs/http/axios';

const { baseURL } = axios.defaults;

const profile = {
  country: () => axios.get('https://ipinfo.io?token=ca6b9d9c4db7f7'),
  get: (params = {}) => axios.get(`${baseURL}/api/profile/`, { params }),
  edit: (option = {}) => axios.patch(`${baseURL}/api/profile/`, option),
  userSettings: (option = {}) => axios.post(`${baseURL}/api/profile/settings/`, option),
  newsletterEdit: (option = {}) => axios.patch(`${baseURL}/api/profile/newsletter`, option),
  newsletterGet: (params = {}) => axios.get(`${baseURL}/api/profile/newsletter`, { params }),
  profileSeniority: (params = {}) => axios.get(`${baseURL}/api/profile/seniority`, { params }),
  juridicalUser: (option = {}) => axios.post(`${baseURL}/api/profile/add`, option),
  helpSend: (option = {}) => axios.post(`${baseURL}/api/profile/support/message`, option),
  registerStep1: (option = {}) => axios.post(`${baseURL}/api/profile/register/step1`, option),
  registerStep2: (option = {}) => axios.post(`${baseURL}/api/profile/register/step2`, option),
  registerStep3: (option = {}) => axios.post(`${baseURL}/api/profile/register/step3`, option),
  resendSms: (option = {}) => axios.post(`${baseURL}/api/profile/register/resend-sms`, option),
  resendEmail: (option = {}) => axios.post(`${baseURL}/api/profile/register/resend-email`, option),
};

export default profile;
