import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { Progress } from 'antd';
import { useRequest } from 'estafette';
import withCompany from '~/hoc/withCompany';
import { monitoring } from '~/libs/http/api';
import { has } from '~/libs/object';
import { Link } from '~/libs/router';
import { useInjectIntl, injectIntl } from '~/libs/localization';
import { CompanyLayout } from '~/components/layouts';
import { Segment } from '~/components/company';
import { Loader, Empty, HiddenContact, Rows, Table, Pagination, EmptyBlock } from '~/components/ui';
import { Time } from '~/components/other';
import { TableFooter } from '~/components/table';
import { Icon } from '~/components/ui/Icon/Icon';

export const PersonalPage = props => {
  const { t } = useInjectIntl();
  const { request, data, loading } = useRequest();

  const [pageNum, setPageNum] = useState(1);

  const personal = React.useMemo(() => props.company.data.personal, [props.company.data]);

  const seniority = {
    PRT: t('prt'),
    ADM: t('adm'),
    VC: t('vc'),
    CXO: t('cxo'),
    MAN: t('man'),
    EMP: t('emp'),
  };

  useEffect(() => {
    if (props.company.id) {
      const options = {
        id: props.company.id,
        page: pageNum,
        per_page: 5,
      };
      request(monitoring.getHistory(options));
    }
  }, [props.company.id, pageNum]);

  const onChangePage = newPage => {
    setPageNum(newPage);
  };

  return (
    <CompanyLayout>
      <Loader loading={props.company.loading}>
        {personal && (
          <Rows
            rows={Object.keys(personal).map(key => ({
              label: seniority[key],
              value: personal[key].length,
            }))}
          />
        )}

        {has(personal, 'PRT') ? (
          <Segment
            title={`${t({ id: 'company.fond' })}`}
            textAlign="center"
            noPadding
            classContent="flexible company-founding"
          >
            {personal.PRT.map(row => (
              <React.Fragment key={row.id}>
                <Segment.Row gray>
                  <small>{t({ id: 'company.fondOrder' })}</small>
                  <p>
                    <Link route="ExecutivePage" params={{ id: row.id }}>
                      {row.name}
                    </Link>{' '}
                    / {has(row, 'seniority.title', <Empty />)}
                  </p>
                </Segment.Row>

                <Segment.Row gray>
                  <small>{t({ id: 'company.fondSize' })}</small>
                  <div className="progress-social">
                    {row.share_value && row.share_value === 100 ? <p className="ant-progress-text">100%</p> : null}
                    <Progress percent={row.share_value ? row.share_value : 0} />
                  </div>
                </Segment.Row>
              </React.Fragment>
            ))}
          </Segment>
        ) : null}
        {personal &&
          Object.keys(personal).map(key => (
            <Segment title={seniority[key]} noPadding noBorder key={key}>
              <Table
                rowKey="id"
                className="ui table table-clean table-header table-odd"
                dataSource={personal[key]}
                columns={[
                  {
                    dataIndex: 'name',
                    title: t({ id: 'other.name' }),
                    className: 'text-blue',
                    render: (record, item) => (
                      <Link route="ExecutivePage" params={{ id: item.id }}>
                        {item.name}
                      </Link>
                    ),
                  },
                  {
                    dataIndex: 'job',
                    title: t({ id: 'other.job' }),
                    className: 'table-cell-function',
                    render: (record, item) => has(item, 'function.title', <Empty />),
                  },
                  {
                    dataIndex: 'telephone',
                    title: t({ id: 'other.telephone' }),
                    className: 'table-cell-contacts',
                    render: (record, item) => (
                      <HiddenContact
                        value={has(item, 'phone.number')}
                        type="phone"
                        icon={<Icon type="company-telephone" />}
                      />
                    ),
                  },
                  {
                    dataIndex: 'email',
                    title: t({ id: 'other.email' }),
                    className: 'table-cell-contacts',
                    render: (record, item) => (
                      <HiddenContact value={item.email} type="email" icon={<Icon type="company-mail" />} />
                    ),
                  },
                ]}
              />
            </Segment>
          ))}
        {has(data, 'history') ? (
          <Segment title={`${t({ id: 'company.historyPersonal' })}`} noPadding>
            <Loader loading={loading}>
              {data.history.length ? (
                <Table
                  rowKey="history-table"
                  className="ui table table-clean table-header table-odd"
                  dataSource={data.history}
                  columns={[
                    {
                      dataIndex: 'date',
                      title: t({ id: 'other.data' }),
                      render: record => <Time date={record} format="date" />,
                      className: 'date-column',
                    },
                    {
                      dataIndex: 'group',
                      title: t({ id: 'other.gen' }),
                      render: record => record.title,
                    },
                    {
                      dataIndex: 'event_name',
                      title: t({ id: 'other.type' }),
                      render: record => record,
                    },
                    {
                      dataIndex: 'type',
                      title: t({ id: 'other.formOfChanges' }),
                      render: record => record,
                      className: 'type-column',
                    },
                    {
                      dataIndex: 'description',
                      title: t({ id: 'other.description' }),
                      render: record => (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: record,
                          }}
                        />
                      ),
                    },
                  ]}
                />
              ) : (
                <EmptyBlock />
              )}
            </Loader>
            {data?.pages && (
              <div className="personal-page-pagination">
                <TableFooter leftSide={<Pagination page={pageNum} total={data.pages} onChange={onChangePage} />} />
              </div>
            )}
          </Segment>
        ) : null}
      </Loader>
    </CompanyLayout>
  );
};

const enhance = compose(injectIntl, withCompany());

export default enhance(PersonalPage);
