import React, { useCallback, useEffect } from 'react';
import { Checkbox } from 'antd';
import { useParams } from 'react-router-dom';
import { useStateHandlers } from '~/hooks';
import { injectIntl } from '~/libs/localization';
import { Segment } from '~/components/company';
import { Empty, Text, Tabs, HiddenContact, Table } from '~/components/ui';
import { Time } from '~/components/other';
import ListDetailsSelected from '~/components/other/ListDetailsSelected';
import { SupportDrawer } from '~/components/management';
import NotFoundPage from '~/pages/NotFoundPage';
import { Icon } from '~/components/ui/Icon/Icon';

const TABS = [
  { label: <Text id="other.unread" />, value: 'unread' },
  { label: <Text id="other.read" />, value: 'read' },
  { label: <Text id="other.deleted" />, value: 'deleted' },
];

const SupportTable = ({ t, data, title, pagination, onChangeStatus, ...props }) => {
  const { type } = useParams();
  const [state, setState] = useStateHandlers({
    checked: [],
    indeterminate: false,
    checkedAll: false,
    page: 1,
    currentDrawer: {},
  });

  const checkAll = useCallback(() => {
    const checkedAll = state.checked.length === data.length;

    setState({ indeterminate: false });
    setState({ checked: checkedAll ? [] : data.map(({ id }) => id) });
    setState({ checkedAll: !checkedAll });
  }, [state.checked, data]);

  const check = useCallback(
    key => {
      let checkedData = [...state.checked];
      const index = checkedData.indexOf(key);

      index !== -1 ? checkedData.splice(index, 1) : (checkedData = [...checkedData, key]);
      const checkedAll = checkedData.length === data.length;

      setState({ checked: checkedData });
      setState({ indeterminate: checkedData.length && !checkedAll });
      setState({ checkedAll });
    },
    [state.checked],
  );

  const clearCheck = () => {
    setState({ checkedAll: false });
    setState({ indeterminate: false });
    setState({ checked: [] });
  };

  const onSetStatus = (items, status) => {
    onChangeStatus(items, status);
    closeDrawer();
  };

  useEffect(() => {
    clearCheck();
  }, [data]);

  const openDrawer = useCallback(currentDrawer => setState({ currentDrawer }), []);
  const closeDrawer = () => setState({ currentDrawer: {} });

  const columns = [
    {
      title: <Checkbox checked={state.checkedAll} indeterminate={state.indeterminate} onChange={() => checkAll()} />,
      className: 'collapsing',
      render: (text, record) => {
        return (
          <Checkbox className="chekbox" checked={state.checked.includes(record.id)} onChange={() => check(record.id)} />
        );
      },
    },
    {
      title: t({ id: 'other.subject' }),
      width: 260,
      render: (text, record) => {
        return (
          <span className="title" onClick={() => openDrawer(record)}>
            {record.title || <Empty />}
          </span>
        );
      },
    },
    {
      title: t({ id: 'other.message' }),
      width: 260,
      render: (text, record) => record.text || <Empty />,
    },
    {
      title: t({ id: 'management.sender' }),
      render: (text, record) => (
        <HiddenContact value={record.sender} type="email" icon={<Icon type="company-mail" />} />
      ),
    },
    {
      title: t({ id: 'management.recipient' }),
      render: (text, record) => {
        return record.reciever ? (
          <HiddenContact value={record.receiver} type="email" icon={<Icon type="company-mail" />} />
        ) : (
          <span className="grey">{t('management.everyone')}</span>
        );
      },
    },
    {
      title: t({ id: 'management.sentDate' }),
      width: 160,
      render: (text, record) => <Time date={record.date_created} relative prefix />,
    },
  ];

  return data ? (
    <div className="alerts">
      {state.currentDrawer && Object.keys(state.currentDrawer).length > 0 && (
        <SupportDrawer id={state.currentDrawer.id} onClose={closeDrawer} onChangeStatus={onSetStatus} />
      )}
      <Segment noPadding noBorder>
        {state.checked.length ? (
          <ListDetailsSelected
            counter={state.checked.length}
            onMarkAsRead={type !== 'read' ? () => onChangeStatus(state.checked, 'read') : undefined}
            onDeleteItems={type !== 'deleted' ? () => onChangeStatus(state.checked, 'deleted') : undefined}
            onClearAll={clearCheck}
          />
        ) : (
          <div className="alert-header">
            <Checkbox
              className="check"
              checked={state.checkedAll}
              indeterminate={state.indeterminate}
              onChange={checkAll}
            />
            <Tabs currentTab={props.status} onChange={props.onChangeTab} data={TABS} classList="tabs-dotted" />
          </div>
        )}
        {data.length ? (
          <>
            <Table
              defaultExpandAllRows
              className="table-clean table-header table-odd table-alerts-company single-line"
              fixed
              columns={columns}
              dataSource={data}
            />
            {pagination}
          </>
        ) : (
          <NotFoundPage />
        )}
      </Segment>
    </div>
  ) : null;
};

export default injectIntl(SupportTable);
