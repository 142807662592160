import axios from '~/libs/http/axios';

const { baseURL } = axios.defaults;

const managementProduct = {
  list: (params = {}) => axios.get(`${baseURL}/api/management/product/list`, { params }),
  get: (params = {}) => axios.get(`${baseURL}/api/management/product/detail`, { params }),
  edit: (option = {}) => axios.patch(`${baseURL}/api/management/product/detail`, option),
  add: (option = {}) => axios.post(`${baseURL}/api/management/product/detail`, option),
  permissions: (params = {}) => axios.get(`${baseURL}/api/management/product/permissions`, { params }),
};

export default managementProduct;
