import React, { cloneElement, useState } from 'react';
import { injectIntl } from '~/libs/localization';
import ConfirmationModal from '~/components/modal/ConfirmationModal';

const Confirmation = ({ type, children, onConfirm, onConfirmLink, disabled, t }) => {
  const [modalProps, setModalProps] = useState({ visible: false });

  const hideModal = () => {
    setModalProps({ visible: false });
  };

  const openModal = () => {
    let title;

    if (type === 'logout-alert') {
      title = t('other.logout');
    } else if (type === 'download') {
      title = t('other.download-alert');
    } else if (type === 'delete') {
      title = t('other.delete-alert');
    } else {
      title = t('other.action-alert');
    }

    setModalProps({ title, visible: true });
  };

  const onConfirmHandler = () => {
    onConfirm();
    setModalProps({ visible: false });
  };

  return (
    <>
      {cloneElement(children, { onClick: openModal })}

      {modalProps.visible && !disabled && (
        <ConfirmationModal
          {...modalProps}
          onConfirm={onConfirm !== undefined && onConfirmHandler}
          onConfirmLink={onConfirmLink}
          hideModal={hideModal}
          t={t}
        />
      )}
    </>
  );
};

export default injectIntl(Confirmation);
