import React, { useCallback, useEffect } from 'react';
import { Modal, Button } from '~/components/ui';
import { Icon } from '~/components/ui/Icon/Icon';

const ConfirmationModal = ({ title, onConfirm, onConfirmLink, hideModal, t }) => {
  const event = [
    'keydown',
    useCallback(({ key }) => {
      if (onConfirm && key === 'Enter') {
        onConfirm();
      }
    }, []),
  ];

  useEffect(() => {
    window.addEventListener(...event);
    return () => {
      window.removeEventListener(...event);
    };
  }, []);

  return (
    <Modal
      className="confirmation-modal center"
      clean
      width="500px"
      onClose={hideModal}
      footerCenter={
        <>
          <Button onClick={hideModal}>{t('other.reset')}</Button>

          {onConfirm && (
            <Button type="primary" onClick={onConfirm}>
              {t('other.confirm')}
            </Button>
          )}

          {onConfirmLink && (
            <a href={onConfirmLink} target="_blank" rel="noopener noreferrer">
              <Button type="primary" onClick={hideModal}>
                {t('other.confirm')}
              </Button>
            </a>
          )}
        </>
      }
    >
      <div className="ant-modal-title">{title}</div>
      <Icon type="alert" />
    </Modal>
  );
};

export default ConfirmationModal;
