import React, { useEffect, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { compose } from 'redux';
import { getRoute } from '~/libs/router';
import { updates } from '~/libs/http/api';
import { withRequest } from '~/hoc';
import { useStateHandlers, useDocumentTitle } from '~/hooks';
import { injectIntl } from '~/libs/localization';
import { UpdatesContent } from '~/components/management';
import { EmptyBlock, Loader } from '~/components/ui';
import { Layout } from '~/components/layouts';

const UpdatesPage = ({ request, validate, t, ...props }) => {
  useDocumentTitle(t('page.updates'));
  const [state, setState] = useStateHandlers({
    list: [],
    page: 1,
    total: 0,
    hasMore: true,
    loading: false,
    currentTab: null,
  });

  useEffect(() => {
    onFetch();
  }, [state.page]);

  const onFetch = useCallback(async () => {
    setState({ loading: true });
    try {
      const { data } = await updates.listPublic({ type: props.match.params.type, page: state.page });

      setState({
        list: state.list.concat(data.data),
        total: data.count,
        loading: false,
        hasMore: state.page < data.pages,
        page: state.page,
      });

      if (props.match.params.id === 'unset' && data.data.length > 0) {
        props.history.push(getRoute('UpdatesPage', { id: data.data[0].id, type: props.match.params.type }));
        setState({ currentTab: data.data[0].id });
      } else {
        setState({ currentTab: props.match.params.id });
      }
    } catch (err) {
      global.console.log(err);
    }
  }, [state.page]);

  const onChangePage = () => {
    if (!state.loading && state.hasMore) setState({ page: state.page + 1 });
  };

  const onTabClick = id => {
    props.history.push(getRoute('UpdatesPage', { id }));
    setState({ currentTab: id });
  };

  return (
    <Layout containerClass="updates-container">
      <div className="tabs-flex">
        <div className="custom-tabs static-tabs">
          <div className="custom-tabs-header">
            <span>
              {t('updates.title')} ({state.total})
            </span>
          </div>
          <InfiniteScroll
            initialLoad={false}
            pageStart={1}
            loadMore={onChangePage}
            hasMore={!state.loading && state.hasMore}
            useWindow={false}
          >
            {state.loading && state.list.length === 0 ? (
              <div className="skeleton-list-loading">
                {[...Array(6).keys()].map(key => (
                  <div className="skeleton" key={key} />
                ))}
              </div>
            ) : null}
            {state.list.map(item => (
              <div
                className={`tabs-elem ${Number(props.match.params.id) === item.id ? 'tab-active' : ''}`}
                key={item.id}
                onClick={() => onTabClick(item.id)}
              >
                <span>{item.title}</span>
              </div>
            ))}
          </InfiniteScroll>
        </div>
        <div className="tabs-content">
          {state.loading ? (
            <Loader loading />
          ) : state.currentTab !== 'unset' && state.currentTab !== null ? (
            <UpdatesContent id={state.currentTab} />
          ) : (
            <EmptyBlock style={{ marginTop: 10 }} />
          )}
        </div>
      </div>
    </Layout>
  );
};

const enhance = compose(injectIntl, withRequest());

export default enhance(UpdatesPage);
