import React, { useEffect } from 'react';
import { useStateHandlers } from '~/hooks';

let timer;
const setTimer = (fn, time = 300) => {
  timer = setTimeout(async () => {
    fn();
    timer = false;
  }, time);
};

const withDebounceChange = type => Component => ({ value, checkedKeys, debounce, ...props }) => {
  const [state, setState] = useStateHandlers({
    scopeValue: value,
    scopeCheckedKeys: checkedKeys,
  });

  useEffect(() => {
    setState({ scopeValue: value });
  }, [value]);

  useEffect(() => {
    setState({ scopeCheckedKeys: checkedKeys });
  }, [checkedKeys]);

  const onDebounceChange = scopeValue => {
    timer && clearTimeout(timer);

    setState({ scopeValue });

    if (scopeValue) {
      setTimer(() => props.onChange(scopeValue), debounce);
    } else {
      props.onChange(scopeValue);
    }
  };

  const onDebounceCheck = scopeCheckedKeys => {
    timer && clearTimeout(timer);

    setState({ scopeCheckedKeys });

    if (scopeCheckedKeys) {
      setTimer(() => props.onCheck(scopeCheckedKeys), debounce);
    } else {
      props.onCheck(scopeCheckedKeys);
    }
  };

  if (type === 'tree') {
    return <Component {...props} scopeCheckedKeys={state.scopeCheckedKeys} onDebounceCheck={onDebounceCheck} />;
  }

  return <Component {...props} scopeValue={state.scopeValue} onDebounceChange={onDebounceChange} />;
};

export default withDebounceChange;
