import React from 'react';
import { render } from '@testing-library/react';
import { createMemoryHistory } from 'history';
import { Router } from 'react-router-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { rootReducer } from '~/store/reducers';
import { localization } from '~/libs/localization/translations';

const testLocale = 'en';

export const providedRender = (component, { initialState, store = createStore(rootReducer, initialState) } = {}) => {
  const history = createMemoryHistory();

  return {
    ...render(
      <Provider store={store}>
        <IntlProvider locale={testLocale} messages={localization[testLocale]}>
          <Router history={history}>{component}</Router>
        </IntlProvider>
      </Provider>,
      // adding `store` to the returned utilities to allow us
      // to reference it in our tests (just try to avoid using
      // this to test implementation details).
      store,
    ),
  };
};

export const t = (id, options = {}) => {
  let text = localization[testLocale][id];

  if (Object.keys(options).length > 0) {
    Object.keys(options).map(key => (text = text.replace(`{${key}}`, options[key])));
  }

  return text;
};
