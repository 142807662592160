import React from 'react';
import { useDocumentTitle } from '~/hooks';
import { useInjectIntl } from '~/libs/localization';
import { ProfileLayout } from '~/components/layouts';
import DisconnectionsInfo from '~/components/disconnections/DisconnectionsInfo';

const DisconnectionsLocationPage = () => {
  const { t } = useInjectIntl();
  useDocumentTitle(t('disconnections.disconnections'));

  const onDelete = () => {};

  return (
    <ProfileLayout>
      <DisconnectionsInfo props={onDelete} />
    </ProfileLayout>
  );
};

export default DisconnectionsLocationPage;
