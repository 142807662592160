import React, { useMemo, useState, useEffect } from 'react';
import { Checkbox as AntdCheckbox, Input } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import { useInjectIntl } from '~/libs/localization';
import { withDebounceChange } from '~/hoc';
import { useStateHandlers } from '~/hooks';
import { push } from '~/libs/array';
import { cleanString } from '~/libs/string';
import { Icon } from '~/components/ui/Icon/Icon';

import '~/assets/scss/components/ui/_Tree.scss';

export const getChildren = (value, tree) => {
  const data = [];

  if (tree.children) {
    for (let i = 0; i < tree.children.length; i += 1) {
      if (cleanString(JSON.stringify(tree.children[i])).match(value)) {
        const matches = cleanString(tree.children[i].title).match(value);

        data.push({
          id: tree.children[i].id,
          title: tree.children[i].title,
          color: !matches ? '#ccc' : null,
          children: getChildren(value, tree.children[i]),
        });
      }
    }
  }

  return data;
};

const DebounceTreeNode = ({ step = 1, expandedAll, selectedAll, values, onChange, options = [] }) => {
  const [expanded, setExpanded] = useState([]);

  useEffect(() => {
    const onChangeExpand = () => {
      if (expandedAll) {
        setExpanded(options.map((_, key) => key));
      }
    };

    onChangeExpand();
  }, [options, expandedAll]);

  const onToggleExpand = key =>
    setExpanded(prevExpanded =>
      prevExpanded.includes(key) ? prevExpanded.filter(id => key !== id) : [...prevExpanded, key],
    );

  return options.map((option, key) => {
    const hasChildren = option.children && option.children.length > 0;
    const isExpanded = expanded.includes(key);

    return (
      <div className="zh-tree-step" style={{ marginLeft: step > 1 ? step * 10 : 15 }} key={option.id}>
        {hasChildren && (
          <span className={`zh-tree-expand ${isExpanded ? 'expanded' : ''}`} onClick={() => onToggleExpand(key)}>
            <Icon type="arrow-modal" />
          </span>
        )}

        <AntdCheckbox
          value={option.id}
          onChange={onChange}
          checked={(selectedAll && !values.includes(option.id)) || (!selectedAll && values.includes(option.id))}
        >
          <span style={{ color: option.color }}>{option.title}</span>
        </AntdCheckbox>

        {isExpanded && hasChildren && (
          <DebounceTreeNode
            expandedAll={expandedAll}
            step={step + 1}
            selectedAll={selectedAll}
            values={values}
            onChange={onChange}
            options={option.children}
          />
        )}
      </div>
    );
  });
};

const DebounceTree = withDebounceChange()(
  ({ expandedAll, debounce, value, scopeValue = [], setState, onDebounceChange, onChange, options = [], ...props }) => {
    const { t } = useInjectIntl();
    const selectedAll = useMemo(() => scopeValue.includes('select-all'), [scopeValue]);

    const onChangeHandler = ({ target }) => {
      let $scopeValue = [...scopeValue];

      if (selectedAll) {
        $scopeValue = $scopeValue.includes(target.value)
          ? // when selected all and it excluded
            $scopeValue.filter($value => target.value !== $value)
          : [...$scopeValue, target.value];
      } else {
        $scopeValue = $scopeValue.includes(target.value)
          ? $scopeValue.filter($value => target.value !== $value)
          : [...$scopeValue, target.value];
      }

      onDebounceChange($scopeValue);
    };

    const onClickSelectAll = () => {
      if (expandedAll && selectedAll) {
        onDebounceChange([]);
      }

      if (!expandedAll) {
        onDebounceChange(selectedAll ? [] : ['select-all']);
      }
    };

    return (
      <div className="zh-tree">
        {options.length >= 10 && (
          <div className="tree-select-all-wrapper">
            <div
              className={`
                tree-select-all 
                ${selectedAll && scopeValue.length === 1 ? 'selected' : ''} 
                ${selectedAll && scopeValue.length > 1 ? 'selected-excluded' : ''}
                ${!selectedAll && expandedAll ? 'unactive' : ''}
              `.replace(/\s\s+/g, ' ')}
              onClick={onClickSelectAll}
            >
              {t('other.select-all')}
            </div>
          </div>
        )}

        <DebounceTreeNode
          expandedAll={expandedAll}
          selectedAll={selectedAll}
          values={scopeValue}
          onChange={onChangeHandler}
          options={options}
        />
      </div>
    );
  },
);

export const Tree = ({ options, scrollHeight = 200, type = 'default', ...props }) => {
  const { t } = useInjectIntl();
  const [state, setState] = useStateHandlers({
    filtered: [],
    data: [],
    perPage: 30,
    pages: 1,
    total: 0,
    expandedAll: false,
  });

  const [filter, setFilter] = useStateHandlers({
    search: '',
    page: 1,
  });

  useEffect(() => {
    const onPaginated = () => {
      const indexOfLast = filter.page * state.perPage;
      const indexOfFirst = indexOfLast - state.perPage;
      const total = !filter.search.length ? options.length : state.filtered.length;

      setState({
        total,
        pages: Math.ceil(total / state.perPage),
        data: push(
          state.data,
          !filter.search.length
            ? options.slice(indexOfFirst, indexOfLast)
            : state.filtered.slice(indexOfFirst, indexOfLast),
        ),
      });
    };

    onPaginated();
  }, [filter, state.filtered]);

  const onSearch = value => {
    setFilter({
      page: 1,
      search: value,
    });

    const newOptions = [];
    const searchValue = cleanString(value);

    for (let i = 0; options.length > i; i += 1) {
      if (cleanString(JSON.stringify(options[i])).match(searchValue)) {
        const matches = cleanString(options[i].title).match(searchValue);

        newOptions.push({
          id: options[i].id,
          title: options[i].title,
          color: !matches ? '#ccc' : null,
          children: getChildren(searchValue, options[i]),
        });
      }
    }

    setState({
      data: [],
      filtered: newOptions,
      expandedAll: Boolean(value),
    });
  };

  const onChangeHandler = ({ target }) => onSearch(target.value);
  const onChangePage = () => setFilter({ page: filter.page + 1 });

  return (
    <div className="zh-tree">
      {options.length > 5 && (
        <Input.Search className="autocomplete" placeholder={t('other.keywords')} onChange={onChangeHandler} autoFocus />
      )}

      {props.hint}

      {type !== 'search' || (type === 'search' && filter.search) ? (
        state.data.length === 0 ? (
          <span className="tree-empty">
            <span>{t('other.empty')}</span>
          </span>
        ) : (
          <div className="zh-tree-scroll" style={{ maxHeight: scrollHeight }}>
            <InfiniteScroll
              initialLoad={false}
              pageStart={1}
              loadMore={onChangePage}
              hasMore={filter.page < state.pages}
              useWindow={false}
            >
              <DebounceTree {...props} expandedAll={state.expandedAll} options={state.data} />
            </InfiniteScroll>
          </div>
        )
      ) : null}
    </div>
  );
};
