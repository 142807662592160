import React, { useEffect, useCallback } from 'react';
import cookies from 'react-cookies';
import { Form, message, Tabs } from 'antd';
import { compose } from 'redux';
import { injectIntl } from '~/libs/localization';
import { useStateHandlers } from '~/hooks';
import { withRequest } from '~/hoc';
import { updates } from '~/libs/http/api';
import { AddUpdateForm } from '~/components/management';

const { TabPane } = Tabs;

const lang = ['en', 'ro', 'ru'];

const initialState = {
  en: '',
  ro: '',
  ru: '',
};

const activeTab = cookies.load('localization') || 'ro';

const AddUpdate = ({ id, request, validate, refreshList, ...props }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [state, setState] = useStateHandlers({
    languages: initialState,
    text: initialState,
  });

  const onChangeName = useCallback(
    (val, ln) => {
      setState({ languages: { ...state.languages, [ln]: val } });
    },
    [state.languages],
  );

  const onChangeText = useCallback(
    (text, ln) => {
      setState({ text: { ...state.text, [ln]: text } });
    },
    [state.text],
  );

  const onSubmit = async e => {
    e.preventDefault();
    if (id) {
      try {
        await updates.edit({
          id: Number(id),
          languages: state.languages,
          text: state.text,
        });
        refreshList();
        // eslint-disable-next-line i18n-text/no-en
        message.success(props.t({ id: 'other.success.changed' }));
      } catch (err) {
        global.console.log(err);
      }
    } else {
      try {
        await updates.add({
          languages: state.languages,
          text: state.text,
        });

        // eslint-disable-next-line i18n-text/no-en
        message.success(props.t({ id: 'other.success.added' }));
      } catch (err) {
        global.console.log(err);
      }
    }
  };

  const onFetchDetails = async () => {
    try {
      const { data } = await updates.details({ id });
      setState({ text: data.text, languages: data.languages });
    } catch (err) {
      global.console.log(err);
    }
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    setState({ text: initialState, languages: initialState });
    if (id !== null) onFetchDetails();
  }, [id]);

  return id !== null ? (
    <Form layout="vertical" onSubmit={onSubmit}>
      <Tabs defaultActiveKey={activeTab} className="dot-tabs">
        {lang.map(ln => {
          return (
            <TabPane tab={ln} key={ln}>
              <AddUpdateForm
                lang={ln}
                text={state.text}
                name={state.languages}
                onChangeName={onChangeName}
                onChangeText={onChangeText}
              />
            </TabPane>
          );
        })}
      </Tabs>
    </Form>
  ) : null;
};

const enhance = compose(withRequest(), injectIntl);

export default enhance(AddUpdate);
