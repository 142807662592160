import config from '~/libs/localization/config';

export const adapterIntl = (localization = {}) => {
  const parsedLocalization = {};
  config.languages.map(language => (parsedLocalization[language] = {}));

  Object.keys(localization).map(key =>
    Object.keys(localization[key]).map(language => (parsedLocalization[language][key] = localization[key][language])),
  );

  return parsedLocalization;
};

export const adapterCombiner = (localization = {}) => {
  const parsedLocalization = {};

  Object.keys(localization).map(key =>
    config.languages.map(
      language =>
        (parsedLocalization[language] = {
          ...parsedLocalization[language],
          ...localization[key][language],
        }),
    ),
  );

  return parsedLocalization;
};
