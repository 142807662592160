import React from 'react';
import { injectIntl } from '~/libs/localization';
import { Button } from 'antd';
import { Mask } from '~/components/ui';
import { Icon } from '~/components/ui/Icon/Icon';

class UserGuide extends React.PureComponent {
  constructor(props) {
    super(props);

    this.timer = {};

    this.state = {
      show: false,
      loading: true,
    };
  }

  componentWillMount() {
    this.timer = setTimeout(() => this.setState({ loading: false }), 1000);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        {this.state.show && <Mask onClose={() => this.setState({ show: false })} />}

        {this.state.show && (
          <div className="user-guide" key="guide-block">
            <div className="user-guide-header">
              <Icon type="question-circle" />
              <span>{t({ id: 'statics.userGuide' })}</span>
            </div>

            <ul className="with-scroll">
              <li>
                <a href="/" target="_blank">
                  It always seems impossible until it’s done
                </a>
              </li>
              <li>
                <a href="/" target="_blank">
                  Education needs to work by pull, not push.
                </a>
              </li>
              <li>
                <a href="/" target="_blank">
                  The way to get started is to quit talking and start doing.
                </a>
              </li>
              <li>
                <a href="/" target="_blank">
                  Old age ain’t no place for sissies.
                </a>
              </li>
              <li>
                <a href="/" target="_blank">
                  I drink to make other people more interesting.
                </a>
              </li>
              <li>
                <a href="/" target="_blank">
                  I drink to make other people more interesting.
                </a>
              </li>
              <li>
                <a href="/" target="_blank">
                  It always seems impossible until it’s done
                </a>
              </li>
              <li>
                <a href="/" target="_blank">
                  Education needs to work by pull, not push.
                </a>
              </li>
              <li>
                <a href="/" target="_blank">
                  The way to get started is to quit talking and start doing.
                </a>
              </li>
              <li>
                <a href="/" target="_blank">
                  Old age ain’t no place for sissies.
                </a>
              </li>
              <li>
                <a href="/" target="_blank">
                  I drink to make other people more interesting.
                </a>
              </li>
            </ul>
          </div>
        )}

        <Button
          key="guide-button"
          className="user-guide-button"
          type="primary"
          shape="circle"
          loading={this.state.loading}
          icon={this.state.show ? 'close' : 'question-circle-o'}
          size="large"
          onClick={() => (!this.state.loading ? this.setState(({ show }) => ({ show: !show })) : null)}
          onKeyUp={e => (e.key === 'Escape' ? this.setState({ show: false }) : null)}
        />
      </React.Fragment>
    );
  }
}

export default injectIntl(UserGuide);
