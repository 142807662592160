import React from 'react';
import { Icon } from '~/components/ui/Icon/Icon';

const CompanyLogo = props =>
  props.img ? (
    <img src={props.img} alt="" />
  ) : (
    <div className="avatar-alt">
      <Icon type="image" />
    </div>
  );

CompanyLogo.Editable = props => (
  <div className="change-avatar">
    {props.img && !props.loading ? (
      <div className="close" onClick={() => props.onClose()} role="presentation">
        <Icon type="delete-avatar" />
      </div>
    ) : null}

    {props.img ? (
      <img src={props.img} alt={props.alt} />
    ) : (
      <div className="avatar-alt">
        <Icon type="image" />
      </div>
    )}

    {props.loading && (
      <div className="avatar-loading">
        <Icon type="loading" />
      </div>
    )}
  </div>
);

CompanyLogo.Small = props =>
  props.img ? (
    <img src={props.img} alt={props.alt} className="small-avatar-img" />
  ) : (
    <div className="small-avatar">
      <Icon type="image" />
    </div>
  );

export default CompanyLogo;
