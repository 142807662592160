import React, { useEffect } from 'react';
import { message, Button } from 'antd';
import { injectIntl } from '~/libs/localization';
import { Order } from '~/components/profile';
import { Loader, Confirmation, Table } from '~/components/ui';
import { useStateHandlers } from '~/hooks';
import NotFoundPage from '~/pages/NotFoundPage';

import '~/assets/scss/components/_Billing.scss';

const Orders = ({ t, ...props }) => {
  const [state, setState] = useStateHandlers({
    deleteLoading: false,
    collapseAll: false,
    deleteData: false,
    hasDelete: false,
  });

  useEffect(() => {
    setState({ hasDelete: state.deleteData && Object.keys(state.deleteData).length > 0 });
  }, [state.deleteData]);

  const onDeleteHandler = async () => {
    let dataSource = [];

    setState({ deleteLoading: true });
    Object.keys(state.deleteData).forEach(key => {
      dataSource = [...dataSource, ...state.deleteData[key]];
    });

    try {
      await props.onDelete(dataSource);
      setState({ deleteData: {} });

      message.success(t('other.success.deleted'));
      setState({ deleteLoading: false });
    } catch (err) {
      message.error(t('other.error_module'));
      setState({ deleteLoading: false });
    }
  };

  const onCollapseAll = () => setState({ collapseAll: !state.collapseAll });
  const onSetDelete = (id, list) => {
    const dataSource = { ...state.deleteData };

    if (list.length > 0) {
      setState({ deleteData: { ...dataSource, [id]: list } });
    }

    if (dataSource[id] && list.length === 0) {
      delete dataSource[id];
      setState({ deleteData: dataSource });
    }
  };

  const columns = [
    {
      className: 'align right',
      dataIndex: 'currency',
      render: () => {
        return <b>{t({ id: 'other.total' })} :</b>;
      },
    },
    {
      className: 'collapsing',
      width: 145,
      dataIndex: 'total_price',
      render: () => {
        return (
          <span className="table-price">
            {props.data.total_price || 0}&nbsp;
            {props.data.currency || ''}
          </span>
        );
      },
    },
  ];

  return (
    <Loader loading={props.loading}>
      <div className="billing-subheader">
        <span className="subheader-title">{t('billing.details')}</span>

        {props.data.length && (
          <span className="subheader-action" onClick={onCollapseAll} role="presentation">
            {state.collapseAll ? t('other.collapse-all') : t('filter.placeholder.show')}
          </span>
        )}
      </div>

      {props.data.summary && Object.keys(props.data.summary).length > 0 ? (
        <div className="billing-body">
          {Object.entries(props.data.summary).map((item, title) => (
            <Order
              isCheckable={props.isCheckable}
              key={item}
              currency={props.data.currency}
              record={item}
              collapseAll={state.collapseAll}
              onSetDelete={onSetDelete}
            />
          ))}

          <Table showHeader={false} className="table-grey table-price-bottom" columns={columns} dataSource={[{}]} />
        </div>
      ) : (
        <NotFoundPage />
      )}

      <div className="billing-footer">
        {state.hasDelete ? (
          <Confirmation type="delete" onConfirm={onDeleteHandler}>
            <Button type="danger" loading={state.deleteLoading}>
              {t('other.delete')}
            </Button>
          </Confirmation>
        ) : null}

        {props.footer !== undefined && !state.hasDelete ? props.footer : null}
      </div>
    </Loader>
  );
};

export default injectIntl(Orders);
