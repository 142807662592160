import React from 'react';
import { firstLetters } from '~/libs/string';
import { Text } from '~/components/ui';
import { Icon } from '~/components/ui/Icon/Icon';

const SearchItem = {};

SearchItem.Total = ({ value }) => (
  <span className="search-block-total">
    <Text id="filter.searchResult" values={{ value }} />
    <Icon type="new-arrow-right" />
  </span>
);

SearchItem.Empty = ({ title }) => <span className="search-block-total">{title}</span>;

SearchItem.Fragment = props => (
  <div className="search-block">
    {props.img && <img src={props.img} alt="" />}
    {!props.img && (
      <div className="search-logo">
        <span>{firstLetters(props.company)}</span>
      </div>
    )}

    <div className="search-block-text">
      <span>{props.company}</span>
      {props.activity && <small>{props.activity}</small>}
    </div>
  </div>
);

export default SearchItem;
