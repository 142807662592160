import React from 'react';
import { Avatar } from '~/components/profile';

import '~/assets/scss/components/ui/_PersonCard.scss';

const PersonCard = ({ className = '', photo, title, desc }) => (
  <div className={`person-card ${className}`}>
    <Avatar alt={title} img={photo} />

    <div className="person-card-data">
      <div className="person-card-name">{title}</div>
      <div className="person-card-desc">{desc}</div>
    </div>
  </div>
);

export default PersonCard;
