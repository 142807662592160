import React from 'react';

export default props => (
  <div className="description">
    {props.svg}

    {props.img && <img src={props.img} alt="Description" />}
    <h2>{props.title}</h2>
    <p>{props.text}</p>

    {props.footer && <div className="description-footer">{props.footer}</div>}
  </div>
);
