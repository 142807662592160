import env from '@beam-australia/react-env';

export const firstLetters = target =>
  target
    ? target
        .split(' ')
        .map(n => n[0])
        .join('')
        .toUpperCase()
        .substr(0, 2)
    : '';

export const cleanString = str =>
  str
    ? str
        .toLowerCase()
        .replace(/ă/g, 'a')
        .replace(/â/g, 'a')
        .replace(/ț/g, 't')
        .replace(/ţ/g, 't')
        .replace(/î/g, 'i')
        .replace(/ş/g, 's')
        .replace(/[.-]/g, '')
        .replace(/ţ/g, 'o')
        .replace(/\(/g, '')
        .replace(/\\/g, '')
        .replace(/\//g, '')
    : '';

export const truncate = (str, limit = 5) => (str.length > limit ? `${str.substring(0, limit)} ...` : str);

export const parseWebsite = website =>
  website
    ? website
        .replace('www.', '')
        .replace('https://', '')
        .replace('http://', '')
        .toLowerCase()
    : '';

export const parseLink = website =>
  website && typeof website === 'string' ? (website.match(/^http:|^https:/) ? website : `//${website}`) : '';

export const replaceBy = (str, from, to) => (str === from ? to : str);

export const capitalize = (str = '') => str.charAt(0).toUpperCase() + str.slice(1);

/* eslint-disable */
export const generateMap = address => {
  const addressFormat = address.replace(/\,/g, ' ');

  return `http://maps.google.com/maps?q=${addressFormat.replace(/\ /g, '%20')}`;
};
/* eslint-enable */

export const getLogotypeLink = src =>
  `https://account.globaldatabase.com/logo/${src.replace('https://', '').replace('http://', '')}/`;

export const getAvatarLink = src => `${env('API_URL') || ''}/${src}`;

export const cleanUnderscore = str => (str ? str.replace('_', ' ') : '');

export const validateEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const validatePhone = email => {
  const re = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
  return re.test(email);
};
