import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Dropdown, Menu, Checkbox, Input as AntdInput } from 'antd';
import { useRequest } from 'estafette';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { setFilterList } from '~/store/actions/legalBulletinActions';
import { legalBulletin, cancelRequests } from '~/libs/http/api';
import { getRoute } from '~/libs/router';
import { useInjectIntl } from '~/libs/localization';
import { Icon } from '~/components/ui/Icon/Icon';
import { TableFooter } from '~/components/table';
import { Pagination, Loader, Input, EmptyBlock } from '~/components/ui';

import '~/assets/scss/components/_LegalBulletinContent.scss';

const LegalBulletinContentList = () => {
  const { t } = useInjectIntl();
  const history = useHistory();
  const { request: requestDeleteListItem, loading: loadingDeleteListItem } = useRequest();
  const { request, data, loading } = useRequest({ data: { data: [] } });
  const dispatch = useDispatch();
  const store = useSelector(
    ({ legalBulletin: $legalBulletin }) => ({ filterList: $legalBulletin.filterList }),
    shallowEqual,
  );

  const [selectedBulletins, setSelectedBulletins] = useState([]);

  useEffect(() => {
    onFetchFilter();
  }, [store.filterList]);

  const onFetchFilter = () => {
    if (store.filterList.page === null && !store.filterList.from_date && !store.filterList.to_date) {
      return;
    }

    const { all: _, ...options } = store.filterList;

    cancelRequests('legalBulletin.filterUserList');
    request(
      legalBulletin.filterUserList({
        ...options,
        per_page: 10,
      }),
    );
  };

  const onDeleteList = async () => {
    await requestDeleteListItem(
      legalBulletin.deleteFromUserList({
        ids: selectedBulletins.join(','),
      }),
    );

    setSelectedBulletins([]);

    onFetchFilter();
  };

  const onSetSearch = search => dispatch(setFilterList({ page: 1, title: search }));

  const onChangePage = page => dispatch(setFilterList({ page }));

  const onSelectBulletin = ({ target }, id) =>
    setSelectedBulletins(prevSelectedBulletins =>
      target.checked ? [...prevSelectedBulletins, id] : prevSelectedBulletins.filter(currentId => currentId !== id),
    );

  const bulletinDetail = id => {
    history.push(getRoute('LegalBulletinDetail', { id }));
  };

  return (
    <div className="legal-bulletin-content legal-bulletin-content-list">
      {selectedBulletins.length === 0 ? (
        <div className="page-header-bulletin normal">
          <div className="left">
            <div className="left-title">{t('legalBulletin.legalBulletins')}</div>

            <AntdInput.Group compact>
              <Input.Search
                allowclear="true"
                placeholder={t('legalBulletin.searchFor')}
                value={store.filterList.search}
                onChange={onSetSearch}
              />
            </AntdInput.Group>
          </div>
        </div>
      ) : (
        <div className="page-header-bulletin">
          <div className="left">
            <div className="left-title">
              {selectedBulletins.length} {t('legalBulletin.selectedBulletin')}
            </div>
          </div>

          <div className="right">
            <Dropdown
              overlay={
                <Menu className="table-menu-tools">
                  {/* <Menu.Item>
                    <span>
                      <Icon type="bulletin-unload" />
                      {t('legalBulletin.unload')}
                    </span>
                  </Menu.Item> */}

                  <Menu.Item>
                    <span onClick={onDeleteList}>
                      <Icon type="trash" />
                      {t('legalBulletin.deleteFromList')}
                    </span>
                  </Menu.Item>
                </Menu>
              }
              placement="bottomRight"
            >
              <Button>
                <Icon type="add-list-plus" />
                {t('legalBulletin.actions')}
              </Button>
            </Dropdown>
          </div>
        </div>
      )}

      <Loader loading={loading || loadingDeleteListItem}>
        {data.data.length ? (
          data.data.map(list => (
            <div
              className={`bulletin${selectedBulletins.find(currentId => currentId === list.id) ? ' active' : ''}`}
              key={list.id}
            >
              <div className="bulletin-header" onClick={() => bulletinDetail(list.id)}>
                <div className="icon-wrap">
                  <Icon type="bulletin" />
                </div>

                <div className="title 2">
                  {list.headers.name_acts} • {list.headers.number_acts} • {list.headers.date_acts}
                  <div className="date">
                    <Icon type="clock" />
                    {list.headers.date_created.slice(0, 10)}
                  </div>
                  <ul className="bulletin-list-info">
                    <li>{list.headers.pages_count || 0} pagini</li>
                    <li>{list.headers.read_minute_count || 0} minute de citit</li>
                    <li>{list.headers.laws_count || 0} legi</li>
                    <li>{list.headers.decisions_count || 0} Hotarari de Guvern</li>
                    <li>{list.headers.opinions_count || 0} Avize</li>
                  </ul>
                </div>
              </div>

              <div className="wrap-industries">
                <div className="title">{t('legalBulletin.ind_Afectate')}</div>

                <div className="industries">
                  <div className="industries__items">{list.message_text && list.message_text.map(item => item)}</div>
                  <Checkbox onChange={e => onSelectBulletin(e, list.id)} className="bulletin-checkbox" />
                </div>
              </div>
            </div>
          ))
        ) : (
          <EmptyBlock />
        )}
      </Loader>

      <TableFooter leftSide={<Pagination page={store.filterList.page} total={data.pages} onChange={onChangePage} />} />
    </div>
  );
};

export default LegalBulletinContentList;
