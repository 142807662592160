import { adapterIntl } from '~/libs/localization/helpers';

const pages = adapterIntl({
  'page.search': { ro: 'Căutare', ru: 'Поиск', en: 'Search' },
  'page.savedList': { ro: 'Liste salvate', ru: 'Мои списки', en: 'Saved lists' },
  'page.accounts': { ro: 'Conturi', ru: 'Учетные записи', en: 'Accounts' },
  'page.admin': { ro: 'Management ', ru: 'Менеджмент ', en: 'Management' },
  'page.company': { ro: 'Compania', ru: 'Компания', en: 'Company' },
  'page.insolvency': { ro: 'Insolvabilitate', ru: 'Несостоятельность', en: 'Insolvency' },
  'page.jurisprudence': { ro: 'Jurisprudenţă', ru: 'Юриспруденция', en: 'Jurisprudence' },
  'page.reorganization': { ro: 'Reorganizarea', ru: 'Реорганизация', en: 'Reorganization' },
  'page.economy': { ro: 'Activitate economică', ru: 'Экономическая деятельность', en: 'Economic activities' },
  'page.executive': { ro: 'Activitate economică', ru: 'Экономическая деятельность', en: 'Economic activities' },
  'page.employees': { ro: 'Angajați', ru: 'Сотрудники', en: 'Employees' },
  'page.generals': { ro: 'Date Generale', ru: 'Общая информация', en: 'General data' },
  'page.personal': { ro: 'Personalul', ru: 'Сотрудники', en: 'Staff' },
  'page.publications': { ro: 'Publicații', ru: 'Публикации', en: 'Publications' },
  'page.subdivision': { ro: 'Subdiviziuni', ru: 'Подразделения', en: 'Subdivisions' },
  'page.alerts': { ro: 'Alerte', ru: 'Оповещения', en: 'Alerts' },
  'page.billing': { ro: 'Facturare', ru: 'Счета', en: 'Billing' },
  'page.downloads': { ro: 'Descărcări', ru: 'Загрузки', en: 'Downloads' },
  'page.api': { ro: 'Integrarea', ru: 'Интеграции', en: 'Integration' },
  'page.events': { ro: 'Abonările la deconectari', ru: 'Подписки', en: 'Subscribes' },
  'page.profile': { ro: 'Profilul meu', ru: 'Мой профиль', en: 'Profile' },
  'page.notification': { ro: 'Notificări', ru: 'Оповещения', en: 'Notifications' },
  'page.order': { ro: 'Detalii comandă', ru: 'Подробности заказа', en: 'Order details' },
  'page.payment': { ro: 'Plata', ru: 'Оплата', en: 'Payment' },
  'page.recovery': { ro: 'Reseteaza parola', ru: 'Измените пароль', en: 'Reset password' },
  'page.signIn': { ro: 'Autentificare', ru: 'Войти в систему', en: 'Sign up' },
  'page.signUp': { ro: 'Înregistrare', ru: 'Регистрация', en: 'Sign up' },
  'page.updates': { ro: 'Actualizări', ru: 'Обновления', en: 'Updates' },
  'page.home': { ro: 'Acasă', ru: 'Главная', en: 'Home' },
  'page.products': { ro: 'Abonamente', ru: 'Абонемент', en: 'Subscriptions' },
  'page.account-product': { ro: 'Abonamentul acountului', ru: 'Абонемент аккаунта', en: 'Account subscriptions' },
  'page.feedback': { ro: 'Feedback', ru: 'Обратная связь', en: 'Feedback' },
  'page.list': { ro: 'Listă', ru: 'Список', en: 'List' },
});

export default pages;
