import React, { useMemo, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Input } from 'antd';
import { useRequest } from 'estafette';
import { useStateHandlers, useDocumentTitle } from '~/hooks';
import { Link } from '~/libs/router';
import { managementAccounts } from '~/libs/http/api';
import { useInjectIntl } from '~/libs/localization';
import { Layout } from '~/components/layouts';
import { Text, Empty, Status, Loader, Button, Table } from '~/components/ui';
import { ManagementMenu } from '~/components/management';
import { TableHeader } from '~/components/table';
import { Time } from '~/components/other';
import NotFoundPage from '~/pages/NotFoundPage';

import '~/assets/scss/components/_AdminAccountsPage.scss';

const AdminAccountsPage = () => {
  const { t } = useInjectIntl();
  useDocumentTitle(t('page.accounts'));
  const { request, data, loading } = useRequest({ data: { data: [] }, loading: false });

  const [filter, setFilter] = useStateHandlers({
    page: 1,
    search: '',
  });

  const mainLoading = useMemo(() => loading && data.data.length === 0, [loading, data]);
  const classLoading = useMemo(() => (loading ? 'skeleton skeleton-text' : ''), [loading]);

  useEffect(() => {
    request(managementAccounts.list({ ...filter, per_page: 25 }), { concat: filter.page > 1 && 'data' });
  }, [filter]);

  const onChangePage = () => {
    window.scrollTo(0, window.scrollY - 25);
    if (!loading) {
      setFilter({ page: filter.page + 1 });
    }
  };

  const onChangeSearch = search => {
    setFilter({
      page: 1,
      search,
    });
  };

  const columns = [
    {
      title: t('other.N'),
      className: 'td-key',
      render: (_, __, key) => <span className={classLoading}>{key + 1}</span>,
      width: 20,
    },
    {
      title: t('other.name'),
      dataIndex: 'name',
      render: (item, data2, key) => (
        <div className={`item-name ${classLoading}`}>
          <Link route="AdminUsersPage" params={{ id: data2.id }} className="title">
            {data2.name || <Empty />}
          </Link>
        </div>
      ),
      width: 170,
    },
    {
      title: t('other.users'),
      dataIndex: 'user_count',
      render: (item, data3, key) => <div className={`item-users ${classLoading}`}>{data3.user_count || <Empty />}</div>,
      width: 90,
    },
    {
      title: t('other.manager'),
      dataIndex: 'management.manager',
      render: (item, data4, key) => (
        <div className={`item-manager ${classLoading}`}>{data4.manager_name || <Empty />}</div>
      ),
      width: 100,
    },
    {
      title: t('other.created-at'),
      dataIndex: 'billing.created',
      render: (item, data5, key) => (
        <div className={`item-created-at ${classLoading}`}>
          {<Time date={data5.date_created} format="dateTime" prefix /> || <Empty />}
        </div>
      ),
      width: 90,
    },
    {
      title: t('other.expired-at'),
      dataIndex: 'management.expired',
      render: (item, data6, key) => (
        <div className={`item-expired-at ${classLoading}`}>
          {<Time date={data6.date_deleted} format="dateTime" prefix /> || <Empty />}
        </div>
      ),
      width: 90,
    },
    {
      title: t('other.status'),
      dataIndex: 'other.status',
      render: (item, data7, key) => (
        <div className={`item-status ${classLoading}`}>
          {<Status status={data7.is_active ? 'active' : 'inactive'} /> || <Empty />}
        </div>
      ),
      width: 100,
    },
  ];

  return (
    <Layout>
      <ManagementMenu />

      <Loader loading={mainLoading}>
        <TableHeader
          leftSide={
            <h1 className="table-results">
              {t('management.results')} {data.total_results ? `(${data.total_results || 0})` : null}
            </h1>
          }
          rightSide={
            <div className="filter-header-button-group form-inline">
              <Input.Search
                placeholder={t({ id: 'searches.search' })}
                enterButton
                onSearch={onChangeSearch}
                defaultValue={filter.search}
              />
              <Link route="AdminAddAccountPage">
                <Button>
                  <Text id="other.addInList" />
                </Button>
              </Link>
            </div>
          }
        />
        <InfiniteScroll
          initialLoad={false}
          pageStart={1}
          loadMore={onChangePage}
          hasMore={!loading && data.pages > filter.page}
          useWindow
        >
          <Table className="table-layout-fixed" columns={columns} dataSource={data.data} />

          {!data.data.length && <NotFoundPage />}
        </InfiniteScroll>
      </Loader>
    </Layout>
  );
};

export default AdminAccountsPage;
