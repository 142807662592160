import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Menu, Dropdown, Input, Button, Modal, message } from 'antd';
import { useSelector, shallowEqual } from 'react-redux';
import { useRequest } from 'estafette';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/ro';
import 'dayjs/locale/ru';
import { useInjectIntl } from '~/libs/localization';
import { getRoute } from '~/libs/router';
import { monitoring } from '~/libs/http/api';
import { TableHeader, TableFooter } from '~/components/table';
import { Pagination, Table, Loader, Logo } from '~/components/ui';
import { Icon } from '../ui/Icon/Icon';

import '~/assets/scss/components/_MonitoringAll.scss';

const MonitoringAll = ({
  showModalCompanyInformation,
  dataMonitoring,
  listType,
  setListId,
  onChangeFilterName,
  onDeleteFromList,
  loadingDeleteFromList,
  showModalAddCounterparty,
  onChangeList,
  onChangePage,
  page: startPage,
  currentSubMenu,
  listId,
  refetchData,
  showListSettings,
}) => {
  const { t } = useInjectIntl();
  const history = useHistory();
  const store = useSelector(({ localization, authentication }) => ({ localization, authentication }), shallowEqual);
  const [page, setPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [showTextArea, setShowTextArea] = useState(null);
  const { request: requestDeleteMonitoringList, loading: loadingDeleteMonitoringList } = useRequest();

  const redirectToInformation = useCallback((check, data) => {
    if (data?.industry && data.more_industries) {
      history.push(getRoute('GeneralsPage', { id: data.id }));
    } else {
      showModalCompanyInformation(check, data);
    }
  }, []);

  const showMoreText = listIdValue => {
    if (listIdValue === showTextArea) {
      setShowTextArea(null);
    } else {
      setShowTextArea(listIdValue);
    }
  };

  const onDeleteList = async () => {
    if (listId) {
      try {
        await requestDeleteMonitoringList(monitoring.deleteMonitoringList({ list_id: listId }));
        setOpenModal(false);
        setListId('companiesFromList');
        refetchData();
      } catch {
        message.error(t('other.error_module'));
      }
    }
  };

  const columns = [
    {
      title: t('monitorig.name'),
      dataIndex: 'name',
      key: 'name',
      className: 'name-column',
      render: (text, record) => (
        <div className="name-wrap" onClick={() => showModalCompanyInformation(true, record)}>
          <Logo website={record?.website} name={text} />
          <div className="name">
            {text} {record.updated > 0 && <span className="updated-count">{record.updated}</span>}
          </div>
        </div>
      ),
    },
    {
      title: 'IDNO',
      dataIndex: 'idno',
      key: 'idno',
      className: 'idno-column',
      render: data => data,
    },
    {
      title: t('monitorig.companyProductsServices'),
      dataIndex: 'industry',
      key: 'industry',
      className: 'products-services-column',
      render: (_, record) => (
        <>
          {record.industry ? (
            record.industry.length > 100 ? (
              <>
                {showTextArea !== record.list_id ? record.industry.slice(0, 100) : record.industry}{' '}
                <span className="text-check" onClick={() => showMoreText(record.list_id)}>
                  {showTextArea !== record.list_id ? t('monitorig.moreText') : t('monitorig.lessText')}
                </span>
              </>
            ) : (
              <>{record.industry}</>
            )
          ) : (
            ''
          )}
        </>
      ),
    },
    {
      title: t('monitorig.type'),
      dataIndex: 'list_name',
      key: 'list_name',
      className: 'type-column',
    },
    {
      title: '',
      dataIndex: 'tools-column',
      key: 'tools-column',
      className: 'tools-column',
      render: (_, record) =>
        listType !== 'companies' ? (
          <>
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu className="table-menu-tools">
                  <Menu.Item>
                    <span onClick={() => onChangeList({ companyId: record.id, listId: record.list_id })}>
                      <Icon type="add-list-plus" />
                      {t('monitorig.addToAnotherList')}
                    </span>
                  </Menu.Item>
                  <Menu.Item>
                    <Loader.Inline loading={loadingDeleteFromList}>
                      <span onClick={() => onDeleteFromList({ companyId: record.id, listId: record.list_id })}>
                        <Icon type="trash" />
                        {t('monitorig.deleteFromList')}
                      </span>
                    </Loader.Inline>
                  </Menu.Item>
                </Menu>
              }
              placement="bottomRight"
            >
              <div>
                <Icon type="dots" />
              </div>
            </Dropdown>
          </>
        ) : null,
    },
  ];

  const eventsColumns = [
    {
      title: t('alerts.name'),
      dataIndex: 'name',
      key: 'name',
      className: 'name-column',
      render: (text, record) => (
        <div className="name-wrap" onClick={() => redirectToInformation(true, record)}>
          <Logo website={record?.website} name={text} />
          <div className="name">
            {text} {record.status === 'NEW' && <span className="tag-new">{t('alerts.new')}</span>}
          </div>
        </div>
      ),
    },
    {
      title: t('alerts.lastEdited'),
      dataIndex: 'message',
      key: 'message',
      className: 'edited-column',
      render: text => <div dangerouslySetInnerHTML={{ __html: text }} />,
    },
    {
      title: t('alerts.type'),
      dataIndex: 'event_type_name',
      key: 'event_type_name',
      className: 'type-column',
      render: data => data,
    },
    {
      title: t('alerts.dateOfPublishing'),
      dataIndex: 'date_created',
      key: 'date_created',
      className: 'date-column',
      render: data => (
        <>
          <Icon type="clock" />
          {dayjs(data).fromNow()}
        </>
      ),
    },
  ];

  useEffect(() => {
    dayjs.extend(relativeTime);
    dayjs.locale(store.localization.current.shortName);
  }, []);

  useEffect(() => {
    onChangePage(page);
  }, [page]);

  useEffect(() => {
    setPage(startPage);
  }, [startPage]);

  const handleCancel = () => {
    setOpenModal(false);
  };

  const openEditList = () => {
    if (listId) {
      showListSettings(true, listId);
    }
  };

  return (
    <div className="monitoring-all">
      {openModal && (
        <Modal
          title={t('monitorig.delete_monitoring_list')}
          visible
          className="modal-add-counterparty"
          width={500}
          footer={[
            <Button key="submit" type="primary" onClick={onDeleteList} loading={loadingDeleteMonitoringList}>
              {t('home.modal-package-submit')}
            </Button>,
          ]}
          onCancel={handleCancel}
        >
          <h4>{t('monitorig.list_delete_question')}</h4>
        </Modal>
      )}
      {/* onChangeFilterName */}
      <TableHeader
        leftSide={
          <>
            <h1>{t('monitorig.all.monitoredCompanies')}</h1>
            {listType === 'events' && (
              <Input.Search placeholder={t('searches.search')} onChange={e => onChangeFilterName(e.target.value)} />
            )}
          </>
        }
        rightSide={
          listId && (
            <>
              <Button className="delete-list-button" onClick={() => setOpenModal(true)}>
                {t('other.delete')}
              </Button>

              <Button className="edit-list-button" onClick={() => openEditList()}>
                {t('legalBulletin.editFolder')}
              </Button>

              <Button className="add-contractors-button" onClick={() => showModalAddCounterparty(true)}>
                <Icon type="add-list-plus" /> {t('monitorig.all.addContractors')}
              </Button>
            </>
          )
        }
      />

      {dataMonitoring && listType === 'companies' && dataMonitoring.results && (
        <Table dataSource={dataMonitoring.results} columns={columns} className="monitoring-table" rowKey="1" />
      )}

      {dataMonitoring && listType === 'companiesFromList' && dataMonitoring.companies && (
        <Table dataSource={dataMonitoring.companies} columns={columns} className="monitoring-table" rowKey="2" />
      )}

      {dataMonitoring && listType === 'events' && dataMonitoring.results && (
        <Table
          dataSource={dataMonitoring.results}
          columns={eventsColumns}
          className="monitoring-table events-table"
          rowKey="3"
        />
      )}

      {dataMonitoring && dataMonitoring?.total_pages > 0 && (
        <TableFooter
          leftSide={<Pagination page={page} total={Number(dataMonitoring.total_pages)} onChange={setPage} />}
        />
      )}
    </div>
  );
};

export default MonitoringAll;
