import React, { useState } from 'react';
import { Input as AntdInput } from 'antd';
import { Icon } from '~/components/ui/Icon/Icon';

const InputPassword = ({ className = '', ...props }) => {
  const [hidePassword, setHidePassword] = useState(true);

  const handlerOnClick = () => setHidePassword(prevState => !prevState);

  return (
    <AntdInput
      className={`${className} input-password-public`}
      {...props}
      type={hidePassword ? 'password' : 'text'}
      suffix={
        <span onClick={handlerOnClick}>
          <Icon type="public-icon-eye" />
        </span>
      }
    />
  );
};

export default InputPassword;
