import React from 'react';
import { useInjectIntl } from '~/libs/localization';
import { useSelector, shallowEqual } from 'react-redux';
import { spaceThousands } from '~/libs/number';
// import { Icon } from '~/components/ui/Icon/Icon';
import SearchForm from './SearchForm';

import '~/assets/scss/components/_SearchStart.scss';

const SearchStart = () => {
  const { t } = useInjectIntl();
  const store = useSelector(
    ({ companies, searches, filter }) => ({
      companies,
      searches,
      nomenclatures: filter.nomenclatures.company_statistics.all_time.all,
    }),
    shallowEqual,
  );

  // const [isActiveCountryPopup] = useState(false);
  // const [country] = useState('md');

  // const onChangeCountry = visible => {
  //   console.log(visible);
  // };

  const onSearch = values => {
    console.log('Success:', values);
  };

  const onSearchFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <div className="title" dangerouslySetInnerHTML={{ __html: t('search.theLargestDatabase') }} />

      <div className="sub-title">
        {t('search.searchFrom')}{' '}
        <span className="count">{store.nomenclatures ? spaceThousands(store.nomenclatures) : '235 659'}</span>{' '}
        {t('search.ofCompanies')}
      </div>

      <SearchForm onSearch={onSearch} onSearchFailed={onSearchFailed} />

      {/* <div className="popular-searches">{t('search.popularSearches')}</div> */}

      <div className="list-count">
        {store.companies.data?.total_results > 0 &&
          `${String(store.companies.data.total_results).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')} ${t(
            store.searches.search_type ? 'search.employees' : 'search.companies',
          )}`}
      </div>
    </>
  );
};

export default SearchStart;
