import React from 'react';

const SkeletonLayout = ({ children }) => (
  <>
    <div className="skeleton skeleton-header" />

    <div className="skeleton-left-tools">
      {[...Array(6).keys()].map(item => (
        <div key={item}>
          <div className="skeleton" />
        </div>
      ))}
    </div>

    <div className="skeleton-layout">{children}</div>

    <div className="skeleton-user-guide">
      <div className="skeleton" />
    </div>
  </>
);

export default SkeletonLayout;
