import React from 'react';
import { injectIntl as withInjectIntl, useIntl } from 'react-intl';

const injectIntl = Component =>
  withInjectIntl(({ intl, ...props }) => {
    const t = (id = '', params = {}) => intl.formatMessage(typeof id === 'string' ? { id } : id, params);

    return <Component {...props} t={t} />;
  });

export const useInjectIntl = () => {
  const intl = useIntl();

  return {
    t: (id = '', params = {}) => intl.formatMessage(typeof id === 'string' ? { id } : id, params),
  };
};

export default injectIntl;
