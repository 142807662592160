import React from 'react';
import { Drawer } from 'antd';
import { useInjectIntl } from '~/libs/localization';
import { Link } from '~/libs/router';
import { Icon } from '../ui/Icon/Icon';
import { Table, Loader } from '~/components/ui';
import Logo from '../company/Logo';

import '~/assets/scss/components/_ModalCompanyInformation.scss';

const ModalCompanyInformation = ({ onClose, dataCompanyNotifications }) => {
  const { t } = useInjectIntl();

  const getDate = date => {
    const formatDate = new Date(date);

    let dd = formatDate.getDate();
    if (dd < 10) dd = `0${dd}`;

    let mm = formatDate.getMonth() + 1;
    if (mm < 10) mm = `0${mm}`;

    let yy = formatDate.getFullYear() % 100;
    if (yy < 10) yy = `0${yy}`;

    const hh = formatDate.getHours();

    let m = formatDate.getMinutes();
    if (m < 10) m = `0${m}`;

    return `${dd}.${mm}.${yy}, ${hh}:${m}`;
  };

  const columns = [
    {
      title: t('monitorig.changes'),
      dataIndex: 'message',
      render: message => <span className="title" dangerouslySetInnerHTML={{ __html: message }} />,
    },
    {
      title: t('monitorig.date'),
      dataIndex: 'date_created',
      width: '170px',
      render: (_, record) => (
        <>
          <Icon type="clock" />
          {getDate(record.date_created)}
        </>
      ),
    },
  ];

  const handleCancel = () => onClose(false);

  return (
    <Drawer
      placement="right"
      closable={false}
      onClose={handleCancel}
      visible
      className="modal-company-information"
      width="745px"
    >
      <Loader loading={!dataCompanyNotifications?.company_id}>
        <div className="header-info">
          <div className="wrap-logo">
            {dataCompanyNotifications?.website && <Logo website={dataCompanyNotifications.website} />}
          </div>
          <div className="company-name">
            <div className="name">{dataCompanyNotifications.name}</div>
            {/* <div className="note">Furnizor</div> */}
          </div>
        </div>

        <div className="header-list">
          <div className="left">{t('monitorig.recentUpdates')}</div>
          <div className="right">
            <Link route="GeneralsPage" params={{ id: dataCompanyNotifications.company_id }} className="link-more">
              {t('monitorig.moreDetails')}
              <Icon type="arrow-right" />
            </Link>
          </div>
        </div>

        <div className="zh-tree-scroll wrap-table">
          {dataCompanyNotifications?.messages && (
            <Table
              defaultExpandAllRows
              className="table-clean table-header"
              fixed
              columns={columns}
              dataSource={dataCompanyNotifications.messages.data}
            />
          )}
        </div>
      </Loader>
    </Drawer>
  );
};

export default ModalCompanyInformation;
