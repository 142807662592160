import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect, useDispatch, useSelector, shallowEqual } from 'react-redux';
import { bindActionCreators } from 'redux';
import { search as searcher, setSearcherLoading } from '~/store/actions/SearchActions';

const enhance = connect(
  ({ filter, searches }) => ({
    loading: searches.loading,
    filter: filter.filter,
    activeFilter: filter.activeFilter,
    employeesKeys: filter.employeesKeys,
    subdivisionsKeys: filter.subdivisionsKeys,
  }),
  dispatch => bindActionCreators({ searcher, setSearcherLoading }, dispatch),
);

const withSearch = () => Component =>
  enhance(props => {
    const history = useHistory();
    const params = useParams();

    const fetch = (type, options = {}, filter) =>
      props.searcher({
        page: options.page || 1,
        type,
        filter,
      });

    const search = async ({ type, ...options } = {}) => {
      if (options && (!options.page || options.page === 1)) {
        window.scrollTo(0, 0);
      }

      await props.setSearcherLoading(true);

      fetch(type || params.type, options, props.activeFilter);
    };

    return <Component {...props} loading={props.loading} search={search} history={history} />;
  });

export const useSearch = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const store = useSelector(
    ({ filter, searches }) => ({
      loading: searches.loading,
      filter: filter.filter,
      companyHasCriterion: filter.companyHasCriterion,
      activeFilter: filter.activeFilter,
      employeesKeys: filter.employeesKeys,
      subdivisionsKeys: filter.subdivisionsKeys,
    }),
    shallowEqual,
  );

  const fetch = (type, options = {}, filter, companyHasCriterion) => {
    dispatch(
      searcher({
        page: options.page || 1,
        type,
        filter,
        companyHasCriterion,
      }),
    );
  };

  const search = async ({ type, ...options } = {}) => {
    if (options && (!options.page || options.page === 1)) {
      window.scrollTo(0, 0);
      dispatch(setSearcherLoading(true));
    }

    return fetch(type || params.type, options, store.activeFilter, store.companyHasCriterion);
  };

  return {
    search,
    searches: store.searches,
    loading: store.loading,
  };
};

export default withSearch;
