import React from 'react';
import { useDocumentTitle } from '~/hooks';
import { useInjectIntl } from '~/libs/localization';
import { ProfileLayout } from '~/components/layouts';
import { DisconnectionsList } from '~/components/disconnections';

const DisconnectionsPage = () => {
  const { t } = useInjectIntl();
  useDocumentTitle(t('disconnections.disconnections'));

  const onDelete = id => {};

  const onAddLocation = () => {};

  return (
    <ProfileLayout>
      <DisconnectionsList props={{ onDelete, onAddLocation }} />
    </ProfileLayout>
  );
};

export default DisconnectionsPage;
