import { adapterIntl } from '~/libs/localization/helpers';

const searcher = adapterIntl({
  'searcher.industry': { ro: 'Industrie', ru: 'Вид деятельности', en: 'Industry' },
  'searcher.empty': {
    ro: 'Nu au fost gasite nici o companie',
    ru: 'Не было найдено ни одной компании',
    en: 'Search empty',
  },
});

export default searcher;
