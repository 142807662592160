import React, { Suspense } from 'react';
import { Route, Redirect } from 'react-router-dom';
import cookies from 'react-cookies';
import { SuspenseFallback } from '~/components/suspense';
import { Loader } from '~/components/ui';
import { PermissionChecker } from '~/libs/router';

const PrivateRoute = ({ loading, suspense, component: Component, setIsNotFound, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      setIsNotFound();

      return cookies.load('token') !== undefined ? (
        <Loader.Screen loading={loading}>
          <PermissionChecker permission={rest.permission}>
            <Suspense fallback={<SuspenseFallback type={suspense} />}>
              <Component {...props} />
            </Suspense>
          </PermissionChecker>
        </Loader.Screen>
      ) : (
        <Redirect
          to={{
            pathname: '/public/signin',
            state: { from: props.location },
          }}
        />
      );
    }}
  />
);

export default PrivateRoute;
