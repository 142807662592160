import React from 'react';
import { Form, Alert } from 'antd';

// That's simple attachments for antd forms

Form.Half = props => (
  <div className={`form-half ${props.three ? 'form-half-three' : ''} ${props.fade ? 'fade-in' : ''}`}>
    {props.children}
  </div>
);

Form.Header = props => <div className="form-title">{props.children}</div>;

Form.Helper = props => <div className={`form-helper ${props.large ? 'form-helper-large' : ''}`}>{props.children}</div>;

Form.Error = props => (props.message ? <Alert message={props.message} type="error" /> : null);

export default Form;
