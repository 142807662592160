export const filterStore = [
  {
    id: 'general',
    name: 'filter.title.generalData',
    icon: 'General',
    type: 'company',
    open: true,
    children: [
      {
        raw: true,
        open: false,
        title: 'filter.child.name',
        name: 'company_name',
        type: 'input-search',
        placeholder: 'filter.placeholder.searchCompany',
      },
      {
        open: true,
        title: 'filter.child.regDate',
        name: 'registration_period',
        type: 'dates',
        children: [
          { placeholder: 'filter.placeholder.from', key: 'from' },
          { placeholder: 'filter.placeholder.to', key: 'to' },
        ],
      },
      {
        open: false,
        title: 'filter.child.formJuridical',
        name: 'form_organization',
        nomenclature: 'enterprise_type',
        type: 'checkbox',
        children: [],
      },
      {
        open: false,
        title: 'filter.child.hq',
        name: 'headquarters',
        nomenclature: 'address',
        type: 'tree',
        children: [],
      },
      {
        open: false,
        title: 'filter.child.contactData',
        name: 'company_has',
        type: 'checkbox',
        children: [
          { localization: 'other.telephone', key: 'phones' },
          { localization: 'other.email', key: 'emails' },
          { localization: 'other.website', key: 'sites' },
          { localization: 'other.mobile', key: 'mobile' },
        ],
      },
      {
        open: false,
        title: 'filter.child.companyActivity',
        name: 'company_active_status',
        nomenclature: 'legal_status',
        type: 'checkbox',
        children: [],
      },
      {
        open: false,
        title: 'filter.child.subdivisions',
        subdivision: true,
        name: 'subdivision',
        type: 'radiobox',
        children: [
          { localization: 'other.yes', key: '1' },
          { localization: 'other.no', key: '0' },
        ],
      },
      {
        open: false,
        title: 'filter.child.subdivisionsType',
        subdivision: true,
        name: 'type_subdivision',
        nomenclature: 'branch_type',
        type: 'checkbox',
        children: [],
        rule: { target: 'subdivision', value: true },
      },
      {
        open: false,
        title: 'filter.child.specialDescription',
        name: 'special_description',
        nomenclature: 'special_description',
        type: 'checkbox',
        children: [],
        rule: { target: 'subdivision', value: false },
      },
    ],
  },
  {
    id: 'employee',
    name: 'filter.title.staff',
    icon: 'Employee',
    open: false,
    children: [
      {
        raw: true,
        open: false,
        employee: true,
        title: 'filter.child.name2',
        name: 'employee_name',
        icon: 'user',
        type: 'input',
        placeholder: 'filter.placeholder.name',
      },
      {
        open: true,
        employee: true,
        title: 'filter.child.level',
        name: 'seniority_level',
        nomenclature: 'seniority_type',
        type: 'checkbox',
        children: [],
      },
      // {
      //   open: false,
      //   employee: true,
      //   title: 'filter.child.function',
      //   name: 'job_function',
      //   nomenclature: 'function',
      //   type: 'checkbox',
      //   children: [],
      //   extra: 'filter.job.extra',
      // },
      // {
      //   open: false,
      //   employee: true,
      //   title: 'filter.child.contactData',
      //   name: 'features',
      //   type: 'checkbox',
      //   children: [
      //     { localization: 'other.telephone', key: 'phone' },
      //     { localization: 'other.email', key: 'email' },
      //   ],
      // },
    ],
  },
  {
    id: 'activity',
    name: 'filter.title.activityEconomy',
    icon: 'Activity',
    type: 'company',
    open: false,
    children: [
      {
        open: true,
        title: 'filter.child.productsServices',
        name: 'products_services_classification',
        nomenclature: 'activities',
        type: 'tree-popup',
        children: [],
      },
      {
        open: false,
        title: 'filter.child.productsServices2',
        name: 'branch_classification',
        nomenclature: 'branch',
        type: 'tree-popup',
        children: [],
      },
      {
        open: false,
        title: 'filter.child.import',
        name: 'country_import',
        nomenclature: 'capital_origin',
        type: 'checkbox',
        children: [],
      },
      {
        open: false,
        title: 'filter.child.export',
        name: 'country_export',
        nomenclature: 'capital_origin',
        type: 'checkbox',
        children: [],
      },
    ],
  },
  {
    id: 'finance',
    name: 'filter.title.economyData',
    icon: 'Finance',
    type: 'company',
    open: false,
    children: [
      {
        open: true,
        title: 'filter.child.capitalSize',
        name: 'capital_social_size',
        type: 'numbers',
        placeholder: 'filter.placeholder.size',
        children: [
          { placeholder: 'filter.placeholder.from', key: 'from' },
          { placeholder: 'filter.placeholder.to', key: 'to' },
        ],
      },
      {
        open: false,
        title: 'filter.child.capitalType',
        name: 'capital_social_type',
        nomenclature: 'capital_type',
        type: 'checkbox',
        children: [],
      },
      {
        open: false,
        title: 'filter.child.country',
        name: 'capital_social_country',
        nomenclature: 'capital_origin',
        type: 'checkbox',
        children: [],
      },
      {
        open: false,
        title: 'filter.child.turnover',
        name: 'turnover',
        type: 'turnover',
        placeholder: 'filter.placeholder.size',
        children: [
          { placeholder: 'filter.placeholder.from', key: 'from' },
          { placeholder: 'filter.placeholder.to', key: 'to' },
          { placeholder: 'filter.placeholder.year', key: 'year' },
        ],
      },
      {
        open: false,
        title: 'filter.child.employeeCount',
        name: 'number_employees',
        nomenclature: 'enterprise_size',
        type: 'checkbox',
        children: [],
      },
      {
        open: false,
        title: 'filter.child.intelectual',
        name: 'intellectual_brands_type',
        nomenclature: 'intellectual_brand_type',
        type: 'checkbox',
        children: [],
      },
      {
        open: false,
        title: 'filter.child.brands',
        name: 'trademarks',
        icon: 'idcard',
        type: 'input',
        placeholder: 'filter.placeholder.trademarks',
      },
      {
        open: false,
        title: 'filter.child.payers',
        name: 'payer',
        type: 'checkbox',
        children: [
          { localization: 'filter.title.tva', key: 'tva' },
          { localization: 'filter.title.excise', key: 'excise' },
        ],
      },
      {
        open: false,
        title: 'filter.child.serviceBank',
        name: 'bank_service',
        nomenclature: 'banks',
        type: 'checkbox',
        children: [],
      },
    ],
  },

  {
    id: 'publications',
    name: 'filter.title.publication',
    icon: 'Publications',
    type: 'company',
    open: false,
    children: [
      {
        open: true,
        title: 'filter.child.publicationType',
        name: 'publication_type',
        nomenclature: 'publication_type',
        type: 'tree',
        children: [],
      },
      {
        open: false,
        title: 'filter.child.publicationPeriod',
        name: 'publication_period',
        type: 'dates',
        children: [
          { placeholder: 'filter.placeholder.from', key: 'from' },
          { placeholder: 'filter.placeholder.to', key: 'to' },
        ],
      },
    ],
  },
  {
    id: 'juridical',
    name: 'filter.title.juridical',
    icon: 'Juridical',
    type: 'company',
    open: false,
    children: [
      {
        open: true,
        title: 'filter.child.parts',
        name: 'juridical_participant_process_type',
        type: 'radiobox',
        children: [
          { localization: 'other.yes', key: '1' },
          { localization: 'other.no', key: '0' },
        ],
      },
      {
        open: false,
        title: 'filter.child.partsType',
        name: 'juridical_process_type',
        nomenclature: 'juridical_process_types',
        type: 'checkbox',
        children: [],
      },
    ],
  },
];

export const ignoredKeys = ['subdivision', 'subdivision_and_parent'];
