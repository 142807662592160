import axios from '~/libs/http/axios';

const { baseURL } = axios.defaults;

const managementTemplate = {
  get: (params = {}) => axios.get(`${baseURL}/api/subscription/added_value`, { params }),
  permissions: (params = {}) => axios.get(`${baseURL}/api/subscription/permissions`, { params }),
  getStatus: (params = {}) => axios.get(`${baseURL}/api/subscription/status/nomenclature`, { params }),
  byId: (params = {}) => axios.get(`${baseURL}/api/subscription/template`, { params }),
  list: (params = {}) => axios.get(`${baseURL}/api/subscription/template/list`, { params }),
  edit: (option = {}) => axios.post(`${baseURL}/api/subscription/template/save`, option),
  add: (option = {}) => axios.post(`${baseURL}/api/subscription/template/save`, option),
};

export default managementTemplate;
