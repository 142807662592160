import axios from '~/libs/http/axios';

const { baseURL } = axios.defaults;

const managementSupport = {
  list: (params = {}) => axios.get(`${baseURL}/api/management/support/list`, { params }),
  details: (params = {}) => axios.get(`${baseURL}/api/management/support/detail`, { params }),
  setStatus: (option = {}) => axios.patch(`${baseURL}/api/management/support/detail`, option),
};

export default managementSupport;
