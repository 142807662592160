import React, { useEffect, useState } from 'react';
import { Button, Input } from 'antd';
import { useRequest } from 'estafette';
import { useDocumentTitle } from '~/hooks';
import { company } from '~/libs/http/api';
import { useInjectIntl } from '~/libs/localization';
import { Layout } from '~/components/layouts';
import { ManagementMenu } from '~/components/management';
import { Loader } from '~/components/ui';

import '~/assets/scss/pages/_TopCompanyPage.scss';

const TopCompanyPage = () => {
  const { request, data, loading } = useRequest();
  const { request: requestTopCompany, data: dataTopCompany, loading: loadingTopCompany } = useRequest();
  const { t } = useInjectIntl();

  const [dataList, setDataList] = useState([]);
  const [saveData, setSaveData] = useState(false);
  const [idno, setIdno] = useState(null);

  useDocumentTitle(t('page.accounts'));

  useEffect(() => {
    requestTopCompany(company.topCompany());
  }, []);

  useEffect(() => {
    if (dataTopCompany) {
      setDataList(dataTopCompany);
    }
  }, [dataTopCompany]);

  const onSetIdno = event => {
    event.preventDefault();
    setIdno(event.target.value);
  };

  useEffect(() => {
    if (saveData) {
      setDataList(data);
      setSaveData(false);
    }
  }, [saveData]);

  const onChangeIsTop = async companyIdno => {
    await request(company.addTopCompany({ idno: companyIdno, is_top_promote: false }));
    setSaveData(true);
    setIdno(null);
  };

  const onSubmit = async () => {
    await request(company.addTopCompany({ idno, is_top_promote: true }));
    setSaveData(true);
    setIdno(null);
  };

  return (
    <Layout>
      <ManagementMenu />

      <div className="top-company-settings-container">
        <div className="top-company-settings-container__leftside">
          <span>Добавить компанию в топ</span>
          <Input placeholder="Введите IDNO компании" onChange={event => onSetIdno(event)} value={idno} />
          <Button type="primary" className="submit-top-btn" onClick={onSubmit} loading={loading}>
            Сохранить
          </Button>
        </div>
        <div className="top-company-settings-container__rightside">
          <span>Список активных компаний в топе</span>
          <div className="top-list">
            {dataList.length ? (
              dataList.map(item => (
                <div key={item.id}>
                  <span>{item.name}</span>
                  <Button key={item.idno} onClick={() => onChangeIsTop(item.idno)} loading={loadingTopCompany}>
                    Убрать
                  </Button>
                </div>
              ))
            ) : (
              <Loader loading />
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TopCompanyPage;
