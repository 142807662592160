import React from 'react';
import { useCurrentLanguage } from '~/hooks';
import { Section, Icon } from '~/components/ui';

const Auth = {};

Auth.Logo = () => (
  // eslint-disable-next-line react-hooks/rules-of-hooks
  <a href={useCurrentLanguage()} className="auth-logo">
    <div className="logo-svg">
      <Icon type="public-logo" />
    </div>
  </a>
);

Auth.Container = ({ children, footer, header, loading, wide }) => (
  <div className={`auth-container ${wide ? 'auth-container-wide' : ''}`}>
    <Section loading={loading}>
      {header || null}

      <div className="auth-block">{children}</div>

      {footer && <div className="auth-footer">{footer}</div>}
    </Section>
  </div>
);

export default Auth;
