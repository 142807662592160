import React from 'react';
import { fireEvent } from '@testing-library/react';
import { providedRender, t } from '~/libs/testing';
import EmailPage from './EmailPage';

describe('<EmailPage />', () => {
  it('changes email value', () => {
    const { getByPlaceholderText } = providedRender(<EmailPage />);

    const input = getByPlaceholderText(t('signup.enterEmail'));

    fireEvent.change(input, { target: { value: 'test' } });

    expect(input.value).toBe('test');
  });

  it('changes password value', () => {
    const { getByPlaceholderText } = providedRender(<EmailPage />);

    const input = getByPlaceholderText(t('other.typePassword'));

    fireEvent.change(input, { target: { value: 'password test' } });

    expect(input.value).toBe('password test');
  });

  it('changes password confirm value', () => {
    const { getByPlaceholderText } = providedRender(<EmailPage />);

    const input = getByPlaceholderText(t('other.repeat_password'));

    fireEvent.change(input, { target: { value: 'password confirm test' } });

    expect(input.value).toBe('password confirm test');
  });
});
