import React from 'react';
import classnames from 'classnames';
import { Icon } from '~/components/ui/Icon/Icon';

const Loader = ({ children, loading, height = 300, className = '', ...props }) => (
  <>
    {loading ? (
      <div className={`loader-block ${className}`} style={{ height, ...props }}>
        <div className="lds-ring">
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    ) : null}

    {children ? (
      <div className="fade-in" style={{ display: loading ? 'none' : 'block' }}>
        {children}
      </div>
    ) : null}
  </>
);

Loader.Block = ({ pure }) => <div className={`block-loader ${pure ? 'pure-block-loader' : ''}`} />;

Loader.Inline = ({ loading, className, children }) =>
  loading ? (
    <span className={classnames('zh-loader-inline', className)}>
      <Icon type="loading" />
      Loading ..
    </span>
  ) : (
    children || null
  );

Loader.Screen = ({ loading, children }) => {
  if (loading) {
    return (
      <div className="zh-loader-screen">
        <Icon type="loading" className="zh-spinner-ring" />
      </div>
    );
  }

  return children;
};

Loader.Partial = ({ loading, children, top }) => (
  <div className="zh-spinner-partial">
    <div
      className="zh-partial-ring fade-slowed-in"
      style={{ top, height: `calc(100% - ${top || 0}px)`, display: loading ? 'block' : 'none' }}
    >
      <Icon type="loading" className="zh-spinner-ring" />
    </div>

    {children}
  </div>
);

export default Loader;
