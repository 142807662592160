import axios from '~/libs/http/axios';

const { baseURL } = axios.defaults;

const managementActivity = {
  requests: (params = {}) => axios.get(`${baseURL}/api/management/activity/requests`, { params }),
  requestDetails: (params = {}) => axios.get(`${baseURL}/api/management/activity/requests/detail`, { params }),
  metrics: (params = {}) => axios.get(`${baseURL}/api/management/activity/metrics`, { params }),
};

export default managementActivity;
