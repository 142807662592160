import React from 'react';
import { Checkbox as OldCheckBox } from 'antd';

const Checkbox = ({ className = '', label, ...props }) => {
  return (
    <OldCheckBox {...props} className={`${className} 'checkbox-public'`}>
      {label}
    </OldCheckBox>
  );
};

export default Checkbox;
