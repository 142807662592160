import React from 'react';
import { injectIntl } from '~/libs/localization';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Input, Button, Radio, Checkbox } from 'antd';
import { lists } from '~/libs/http/api';
import { Form, Text } from '~/components/ui';
import { Icon } from '~/components/ui/Icon/Icon';

class SaveListEdit extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      criteria: [],
      notification: '',
    };
  }

  componentWillMount() {
    this.setState((state, props) => ({
      name: props.item.name,
      criteria: props.item.alert_criteria,
      notification: props.item.alert_frequency,
    }));
  }

  getDefaultValue(itemId) {
    const id = this.state.criteria.find(key => itemId === key);

    return id !== undefined;
  }

  submit(e) {
    e.preventDefault();

    lists
      .edit({
        id: this.props.item.id,
        name: this.state.name,
        alert_frequency: this.state.notification,
        alert_criteria: this.state.criteria,
      })
      .then(() => {
        this.props.refetch();
        this.props.onClose();
      });
  }

  selectItem(value) {
    const { criteria } = Object.assign({}, this.state);

    if (criteria.find(item => item === value)) {
      criteria.splice(value, 1);
    } else {
      criteria.push(value);
    }

    this.setState({ criteria });
  }

  render() {
    const { t, changeTypes } = this.props;

    return (
      <Form onSubmit={e => this.submit(e)}>
        <div className="save-list-edit">
          <Icon type="close" className="save-list-close" onClick={() => this.props.onClose()} />

          <div className="save-list-inline">
            <span>{t({ id: 'other.name' })}:</span>
            <Input defaultValue={this.state.name} onChange={({ target }) => this.setState({ name: target.value })} />
          </div>

          <div className="save-list-blocks">
            <Form.Item label={t('notification.settings')} className="save-list-item like-header">
              <Radio.Group
                onChange={({ target }) => this.setState({ notification: target.value })}
                defaultValue={`${this.state.notification}`}
              >
                <Radio value="never">{t({ id: 'notification.no' })}</Radio>
                <Radio value="daily">{t({ id: 'notification.daily' })}</Radio>
                <Radio value="weekly">{t({ id: 'notification.weekly' })}</Radio>
                {/* <Radio value="monthly">Alerte lunare</Radio> */}
              </Radio.Group>
            </Form.Item>

            {changeTypes.map(type => (
              <Form.Item key={type.id} label={type.title} className="save-list-item like-header with-scroll">
                {type.fields.map(field => (
                  <Checkbox
                    key={field.key}
                    value={field.key}
                    onChange={({ target }) => this.selectItem(target.value)}
                    defaultChecked={this.getDefaultValue(field.key)}
                  >
                    {field.title}
                  </Checkbox>
                ))}
              </Form.Item>
            ))}
          </div>

          <div className="save-list-buttons">
            <Button onClick={() => this.props.onClose()}>{t({ id: 'other.reset' })}</Button>

            <Button type="primary" htmlType="submit">
              <Text id="other.save" />
            </Button>
          </div>
        </div>
      </Form>
    );
  }
}

const enhance = compose(
  injectIntl,
  connect(({ filter }) => ({ changeTypes: filter.nomenclatures.change_types })),
);

export default enhance(SaveListEdit);
