import axios from '~/libs/http/axios';

const { baseURL } = axios.defaults;

const updates = {
  listPublic: (params = {}) => axios.get(`${baseURL}/api/updates/public/list`, { params }),
  detailsPublic: (params = {}) => axios.get(`${baseURL}/api/updates/public/detail`, { params }),
  list: (params = {}) => axios.get(`${baseURL}/api/updates/list`, { params }),
  details: (params = {}) => axios.get(`${baseURL}/api/updates/details`, { params }),
  edit: (option = {}) => axios.patch(`${baseURL}/api/updates/list`, option),
  add: (option = {}) => axios.post(`${baseURL}/api/updates/list`, option),
  delete: (params = {}) => axios.delete(`${baseURL}/api/updates/list`, { params }),
};

export default updates;
