import { Input, Select } from 'antd';
import React, { useEffect, useReducer, useState } from 'react';
import { Time } from '~/components/other';
import { TableFooter, TableHeader } from '~/components/table';
import { Loader, Pagination, Table } from '~/components/ui';
import withRequest from '~/hoc/withRequest';
import { billing } from '~/libs/http/api';
import { injectIntl } from '~/libs/localization';
import { number } from '~/libs/number';
import '~/assets/scss/components/_BillingHistory.scss';

const initialFilter = {
  page: 1,
  ordering: '-date_created',
  search: '',
};

const filterReducer = (state = initialFilter, action) => {
  switch (action.type) {
    case 'SET_PAGE': {
      return { ...state, page: Number(action.page) };
    }
    case 'SET_ORDERING': {
      return { ...state, ordering: action.ordering, page: 1 };
    }
    case 'SET_SEARCH': {
      return { ...state, search: action.search, page: 1 };
    }
    default:
      return state;
  }
};

let totalAmount = 0;
const BillingHistory = ({ request, t, ...props }) => {
  const [filter, dispatch] = useReducer(filterReducer, initialFilter);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const onFetch = () => {
      const options = { page: filter.page };
      filter.ordering && (options.ordering = filter.ordering);
      filter.search && (options.search = filter.search);

      request(billing.history(options));
    };

    onFetch();
  }, [filter]);

  useEffect(() => {
    props.api.data.length &&
      props.api.data.forEach(item => {
        totalAmount += item.amount;
      });

    setTotal(totalAmount);
  }, [props.api]);

  const onChangeSearch = ({ target }) => dispatch({ type: 'SET_SEARCH', search: target.value });
  const onChangeOrder = ordering => dispatch({ type: 'SET_ORDERING', ordering });
  const onChangePage = page => dispatch({ type: 'SET_PAGE', page });

  const columns = [
    {
      title: t({ id: 'billing.date' }),
      dataIndex: 'date_created',
      render: date => <Time date={date} format="fromNow" />,
      width: 200,
    },
    {
      title: t({ id: 'billing.description' }),
      dataIndex: 'name',
      render: text => <span className="zh-text" dangerouslySetInnerHTML={{ __html: text || '---' }} />,
    },
    {
      title: t({ id: 'billing.amount' }),
      dataIndex: 'amount',
      render: count => `${count || 'N/A'} ${props.api.parsedCurrency || ''}`,
      width: 200,
    },
  ];

  const columns2 = [
    {
      dataIndex: 'key',
      className: 'align right',
      render: () => <b>{t({ id: 'order.total' })} : </b>,
    },
    {
      className: 'collapsing',
      width: 200,
      render: () => <span className="table-price">{number(total)}</span>,
    },
  ];

  return (
    <Loader loading={props.api.loading}>
      <div className="billing-table">
        <TableHeader
          leftSide={<h1>{t({ id: 'billing.history' })}</h1>}
          rightSide={
            props.api.data.length > 0 && (
              <div className="form-inline">
                <span className="form-inline-label">{t({ id: 'other.orderBy' })} </span>

                <Select
                  showSearch
                  placeholder={t({ id: 'other.select' })}
                  optionFilterProp="children"
                  onChange={onChangeOrder}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Select.Option value="jack">{t({ id: 'billing.date' })}</Select.Option>
                </Select>

                <Input.Search placeholder={t('searches.search')} enterButton onChange={onChangeSearch} />
              </div>
            )
          }
        />

        <Table rowKey="id" columns={columns} dataSource={props.api.data} className="table-grey no-margin" showHeader />

        {props.api.data.length > 0 && (
          <Table
            rowKey="date_created"
            columns={columns2}
            dataSource={[props.api.data[0]]}
            showHeader={false}
            className="table-grey table-clean table-no-header no-margin"
          />
        )}

        <TableFooter rightSide={<Pagination page={filter.page} total={props.api.pages} onChange={onChangePage} />} />
      </div>
    </Loader>
  );
};

export default withRequest()(injectIntl(BillingHistory));
