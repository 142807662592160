import axios from '~/libs/http/axios';

const { baseURL } = axios.defaults;

const billing = {
  addCart: ({ type, ...option } = {}) => axios.post(`${baseURL}/api/billing/cart/add/${type}`, option),
  myCart: (params = {}) => axios.get(`${baseURL}/api/billing/cart`, { params }),
  delete: (option = {}) => axios.post(`${baseURL}/api/billing/cart/delete`, option),
  payCart: (option = {}) => axios.post(`${baseURL}/api/billing/cart/pay`, option),
  summary: (params = {}) => axios.get(`${baseURL}/api/billing/history/summary`, { params }),
  history: (params = {}) => axios.get(`${baseURL}/api/billing/history`, { params }),
  usersPrice: (params = {}) => axios.get(`${baseURL}/api/billing/users-price`, { params }),
  buyUsers: (option = {}) => axios.post(`${baseURL}/api/billing/buy-users`, option),
};

export default billing;
