import React from 'react';
import { Form, Radio, Select, Checkbox, Input } from 'antd';
import NumberFormat from 'react-number-format';
import { useStateHandlers } from '~/hooks';
import { injectIntl } from '~/libs/localization';
import { Button } from '~/components/ui';
import { Link } from '~/libs/router';
import { Icon } from '~/components/ui/Icon/Icon';

import '~/assets/scss/components/_PaymentForm.scss';

const years = [2019, 2020, 2021, 2022];
const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const PaymentForm = ({ t, errors, loading, ...props }) => {
  const [state, setState] = useStateHandlers({
    terms: false,
    method: 'Visa',
    firstName: '',
    lastName: '',
    card: undefined,
    expiryMonth: undefined,
    expiryYear: undefined,
    cvv: undefined,
  });

  const onChangeMethod = ({ target }) => setState({ method: target.value });
  const onChangeCard = ({ target }) => setState({ card: target.value });
  const onChangeExpireMonth = expiryMonth => setState({ expiryMonth });
  const onChangeExpireYear = expiryYear => setState({ expiryYear });
  const onChangeCvv = ({ target }) => setState({ cvv: target.value });
  const onChangeTerms = ({ target }) => setState({ terms: target.checked });
  const onChangeFirstName = ({ target }) => setState({ firstName: target.value });
  const onChangeLastName = ({ target }) => setState({ lastName: target.value });

  const onSubmit = ev => {
    ev.preventDefault();

    props.onSubmit(state);
  };

  return (
    <Form onSubmit={onSubmit}>
      <div className="payment-block">
        <div className="title">{t('billing.card')}</div>
        <Form.Item className="card-block">
          <Radio.Group value={state.method} onChange={onChangeMethod}>
            <Radio value="Visa">
              <div className="card-block--visa">
                <Icon type="payment-icons-visa" />
              </div>
            </Radio>

            <Radio value="Maestro">
              <Icon type="payment-icons-maestro" />
            </Radio>

            <Radio value="MasterCard">
              <Icon type="payment-icons-master" />
            </Radio>
          </Radio.Group>
        </Form.Item>

        <div className="form-half">
          <Form.Item
            label={
              <span>
                {t('profile.name')} <sup>*</sup>
              </span>
            }
            {...(errors.first_name && { validateStatus: 'error', help: errors.first_name })}
          >
            <Input
              placeholder={t('profile.name')}
              value={state.lastName}
              onChange={onChangeLastName}
              className="ant-input-inline"
            />
          </Form.Item>
          <Form.Item
            label={
              <span>
                {t('profile.lastName')} <sup>*</sup>
              </span>
            }
            {...(errors.last_name && { validateStatus: 'error', help: errors.last_name })}
          >
            <Input
              placeholder={t('profile.lastName')}
              value={state.firstName}
              onChange={onChangeFirstName}
              className="ant-input-inline"
            />
          </Form.Item>
        </div>

        <Form.Item
          label={
            <span>
              {t('profile.card')} <sup>*</sup>
            </span>
          }
          {...(errors.card_number && { validateStatus: 'error', help: errors.card_number })}
        >
          <NumberFormat
            format="#### #### #### ####"
            placeholder="0000 0000 0000 0000"
            className="ant-input"
            value={state.card}
            onChange={onChangeCard}
          />
        </Form.Item>

        <div className="form-half">
          <Form.Item
            label={
              <span>
                {t('billing.month-expire')} <sup>*</sup>
              </span>
            }
            {...(errors.month && { validateStatus: 'error', help: errors.month })}
          >
            <Select
              allowClear
              placeholder={t('other.month')}
              placement="bottomRight"
              dropdownClassName="responsive-table-dropdown"
              value={state.expiryMonth}
              onChange={onChangeExpireMonth}
            >
              {months.map(month => (
                <Select.Option value={month} key={month}>
                  {month}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={
              <span>
                {t('billing.year-expire')} <sup>*</sup>
              </span>
            }
            {...(errors.year && { validateStatus: 'error', help: errors.year })}
          >
            <Select
              allowClear
              placeholder={t('other.oneyear')}
              dropdownClassName="responsive-table-dropdown"
              value={state.expiryYear}
              onChange={onChangeExpireYear}
            >
              {years.map(year => (
                <Select.Option value={year} key={year}>
                  {year}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <Form.Item
          label={
            <span>
              {t('billing.cvv')} <sup>*</sup>
            </span>
          }
          {...(errors.cvv && { validateStatus: 'error', help: errors.cvv })}
        >
          <NumberFormat format="###" placeholder="000" className="ant-input" value={state.cvv} onChange={onChangeCvv} />
        </Form.Item>

        <Form.Item className="zh-card-block">
          <Checkbox checked={state.terms} onChange={onChangeTerms}>
            {t('other.agree')}
            <Link route="TermsAndConditionsPage" params="">
              <span className="link"> {t('other.terms')}</span>
            </Link>
          </Checkbox>
        </Form.Item>
      </div>

      <div className="submit-wrapper">
        {props.onBack !== undefined && (
          <Button className="billing-back" onClick={props.onBack}>
            {t('billing.back')}
          </Button>
        )}

        <Button type="primary" htmlType="submit" loading={loading} disabled={!state.terms || loading}>
          {t('billing.process')}
        </Button>
      </div>
    </Form>
  );
};

export default injectIntl(PaymentForm);
