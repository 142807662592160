import React, { useState, useEffect } from 'react';
import MediaQuery from 'react-responsive';
// import { useRequest } from 'estafette';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Dropdown, Menu, Button } from 'antd';
import { closeFilter, openFilter } from '~/store/actions/FilterActions';
// import { updateUserSettings } from '~/store/actions/AuthenticationActions';
// import { profile } from '~/libs/http/api';
import responsive from '~/libs/responsive';
import { Link, getRoute, isRouteActive } from '~/libs/router';
import { useInjectIntl } from '~/libs/localization';
import { ProfileSection, SearchBox, MenuLinks } from '~/components/header';
import { Icon } from '~/components/ui/Icon/Icon';

import '~/assets/scss/components/_Header.scss';

const Header = () => {
  const match = useRouteMatch();
  const history = useHistory();
  // const { request: requestProfileEdit } = useRequest();
  const { t } = useInjectIntl();
  const dispatch = useDispatch();
  const store = useSelector(
    ({ filter, localization, authentication }) => ({
      showFilter: filter.showFilter,
      localization,
      permissions: authentication.permissions,
      authenticationParams: authentication.params,
    }),
    shallowEqual,
  );

  const [isVisibleMenu, setIsVisibleMenu] = useState(false);

  useEffect(() => {
    if (isVisibleMenu) {
      document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
    } else {
      document.getElementsByTagName('body')[0].style.overflowY = 'auto';
    }
  }, [isVisibleMenu]);

  const isActive = route => (isRouteActive(match, route) ? 'active' : '');

  const onOpen = () => {
    history.push(getRoute('SearchResultsPage', { type: 'company', mode: 'basic' }));
    dispatch(openFilter());
  };

  const closeMenu = () => {
    setIsVisibleMenu(prevState => !prevState);
  };

  const onClickHandler = () => {
    if (store.permissions.includes('company_search')) {
      if (store.showFilter) {
        dispatch(closeFilter());
      } else {
        onOpen();
      }
    }
  };

  const onVisibleChangeMenu = visible => setIsVisibleMenu(visible);

  // const onChangeMenuItem = nameApp => {
  //   const options = {
  //     ...store.authenticationParams.settings.menu_payload,
  //     [nameApp]: !store.authenticationParams.settings.menu_payload[nameApp],
  //   };

  //   dispatch(
  //     updateUserSettings({
  //       ...store.authenticationParams,
  //       settings: { ...store.authenticationParams.settings, menu_payload: options },
  //     }),
  //   );

  //   requestProfileEdit(profile.userSettings({ menu_payload: options }));
  // };

  const linkAccess = React.useMemo(
    () =>
      store.authenticationParams.permissions_group
        ? store.authenticationParams.permissions_group.map(item => item.codename)
        : [],
    [store.authenticationParams.permissions_group],
  );

  const shortcuts = React.useMemo(
    () =>
      store.authenticationParams.settings && store.authenticationParams.settings.menu_payload
        ? [
            {
              key: 'dashboard',
              title: t('menu.dashboard'),
              route: 'HomePage',
              params: {},
              icon: 'menu-dashboard',
              accessible: store.authenticationParams.settings.menu_payload.dashboard,
            },
            {
              key: 'search',
              title: t('menu.find'),
              route: 'Search',
              params: { page: 1, query: '' },
              icon: 'menu-find',
              accessible: store.authenticationParams.settings.menu_payload.search,
              hidden: !linkAccess.includes('search'),
            },
            {
              key: 'messages',
              title: t('menu.messages'),
              route: 'Messages',
              params: { filter: 'all', mode: 'messages' },
              icon: 'menu-messages',
              accessible: store.authenticationParams.settings.menu_payload.messages,
              hidden: !linkAccess.includes('messages'),
            },
            {
              key: 'exploration',
              title: t('menu.exploration'),
              route: 'SearchResultsPage',
              params: { type: 'company', mode: 'basic' },
              icon: 'menu-filter',
              accessible: store.authenticationParams.settings.menu_payload.exploration,
              hidden: !linkAccess.includes('exploration'),
            },
            {
              key: 'alerts',
              title: t('menu.alerts'),
              route: 'Alerts',
              params: { filter: 'all', mode: 'list' },
              icon: 'menu-alerts',
              accessible: store.authenticationParams.settings.menu_payload.alerts,
              hidden: !linkAccess.includes('alerts'),
            },
            {
              key: 'monitoring',
              title: t('menu.monitoring'),
              route: 'Monitoring',
              params: {},
              icon: 'menu-monitoring',
              accessible: store.authenticationParams.settings.menu_payload.monitoring,
              hidden: !linkAccess.includes('monitoring'),
            },
            {
              key: 'legal_documents',
              title: t('menu.legal_bulletin'),
              route: 'LegalBulletin',
              params: {},
              icon: 'menu-legal-bulletin',
              accessible: store.authenticationParams.settings.menu_payload.legal_documents,
              hidden: !linkAccess.includes('legal_documents'),
            },
            {
              key: 'management',
              title: t('menu.management'),
              route: 'AdminAccountsPage',
              params: {},
              icon: 'menu-management',
              accessible: store.authenticationParams.settings.menu_payload.management,
              hidden: store.authenticationParams.is_superuser !== true,
            },
          ]
        : [],
    [store.authenticationParams.settings],
  );

  return (
    <>
      <header>
        <ul className="menu-left pull-left">
          <li
            onClick={onClickHandler}
            role="presentation"
            className={!store.permissions.includes('company_search') ? 'menu-left-no-filter' : ''}
          >
            <Icon type="logo-i" />

            {store.showFilter && (
              <div className="filter-close">
                <Icon type="user-section-close-it" />
              </div>
            )}
          </li>

          <li className="logo-wrap">
            <Link route="HomePage">
              <MediaQuery minWidth={responsive.smallDesktop}>
                <div className="logo-svg">
                  <Icon type="logo-svg" />
                </div>
              </MediaQuery>

              <MediaQuery maxWidth={responsive.smallDesktop}>
                <Icon type="logo-i" />
              </MediaQuery>
            </Link>
          </li>
        </ul>

        <div className="menu-center">
          <MenuLinks className="menu-links" />

          {store.authenticationParams.settings && (
            <Dropdown
              trigger={['click']}
              placement="bottomRight"
              onVisibleChange={onVisibleChangeMenu}
              visible={isVisibleMenu}
              overlay={
                <Menu className="shortcuts-menu">
                  {shortcuts
                    .filter(i => !i.hidden)
                    .map(item => {
                      const active = isActive(item.route);

                      return (
                        <Menu.Item key={item.key} className={`shortcuts-menu-item ${active}`}>
                          <Link route={item.route} className={`link ${active}`} params={item.params}>
                            <Icon type={item.icon} />

                            {item.title}
                          </Link>

                          {/* <span className="custom-checkbox" onClick={() => onChangeMenuItem(item.key)}>
                            {item.accessible ? <Icon type="menu-check" /> : null}
                          </span> */}
                        </Menu.Item>
                      );
                    })}

                  {/* {dataProfile?.is_superuser && (
                    <Menu.Item key="8" className="shortcuts-menu-item active">
                      <Link route="AdminAccountsPage" className={`link ${isActive('AdminAccountsPage')}`}>
                        <Icon type="menu-management" />
                        {t('menu.management')}
                      </Link>

                      <span className="custom-checkbox" onClick={() => onChangeMenuItem('management')}>
                        {dataProfile.settings.menu_payload.management ? <Icon type="menu-check" /> : null}
                      </span>
                    </Menu.Item>
                  )} */}
                </Menu>
              }
            >
              <Button className={`menu-navigation-button ${isVisibleMenu ? ' active' : ''}`} onClick={closeMenu}>
                <Icon type={`${isVisibleMenu ? 'menu-navigation-close' : 'menu-navigation'}`} />
              </Button>
            </Dropdown>
          )}
          <SearchBox placeholder="other.searchBox" />
        </div>

        <ul className="menu-right pull-right">
          {/* <Link route="UpdatesPage" params={{ id: isActive('UpdatesPage') ? match.params.id : 'unset' }}>
            <li className={`notification-svg ${isActive('UpdatesPage')}`}>
              <Icon type="updates-normal" />
            </li>
          </Link> */}

          {/* <li className="notification-svg">
            <Link route="UpdatesPage" params={{ id: isActive('UpdatesPage') ? match.params.id : 'unset' }}>
              <Icon type="alarm-bell" />
            </Link>
          </li> */}

          <ProfileSection />
        </ul>
      </header>
    </>
  );
};

export default Header;
