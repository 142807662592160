import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { message, Dropdown, Menu, Button, Radio } from 'antd';
import { useRequest } from 'estafette';
import { getRoute } from '~/libs/router';
import { useInjectIntl } from '~/libs/localization';
import { legalBulletin } from '~/libs/http/api';
import { Layout } from '~/components/layouts';
import { Loader } from '~/components/ui';
import { Icon } from '~/components/ui/Icon/Icon';
import {
  LegalBulletinInfo,
  // LegalBulletinContentMain,
  LegalBulletinContentList,
  LegalBulletinContentActs,
} from '~/components/legal-bulletin';

import '~/assets/scss/components/_LegalBulletinContent.scss';

const LegalBulletinDetail = () => {
  const { t } = useInjectIntl();
  const { id } = useParams();
  const history = useHistory();
  const { request: requestBulletins, data: dataBulletins, loading: loadingBulletins } = useRequest();

  const [selectedBulletins, setSelectedBulletins] = useState([]);
  const [type, setType] = useState('info');

  useEffect(() => {
    if (id) {
      onFetchBulletins();
    }
  }, [id]);

  const onFetchBulletins = async () => {
    try {
      requestBulletins(legalBulletin.getBulletins({ page: 1, per_page: 1, id: Number(id) }));
    } catch {
      message.error(t('other.error_module'));
    }
  };

  const onHandleNavigation = newTab => setType(newTab.target.value);

  const LegalBulletinContent = () => {
    switch (type) {
      case 'main':
        return history.push(getRoute('LegalBulletin'));
      case 'list':
        return <LegalBulletinContentList />;
      case 'acts':
        return <LegalBulletinContentActs />;
      default:
        return (
          <LegalBulletinInfo
            dataBulletins={dataBulletins}
            detail
            selectedBulletins={selectedBulletins}
            setSelectedBulletins={setSelectedBulletins}
          />
        );
    }
  };

  const onAddUserList = () => {
    try {
      const params = {
        ids: selectedBulletins,
      };

      requestBulletins(legalBulletin.addToUserList(params));
      onFetchBulletins();
    } catch {
      message.error(t('other.error_module'));
    }
  };

  return (
    <>
      <Layout containerClass="legal-bulletin-detail-container">
        {/* Hidden for detail */}
        <Radio.Group className="navigation" value={type} onChange={onHandleNavigation}>
          <Radio.Button value="main">{t('legalBulletin.legalBulletin')}</Radio.Button>
          {/* <Radio.Button value="list">{t('legalBulletin.list')}</Radio.Button>
          <Radio.Button value="acts">{t('legalBulletin.legalDocuments')}</Radio.Button> */}
        </Radio.Group>

        <div className="legal-bulletin-content">
          <Loader loading={loadingBulletins}>
            {selectedBulletins.length !== 0 && (
              <div className="page-header-bulletin active">
                <div className="left">
                  <div className="left-title">
                    {selectedBulletins.length} {t('legalBulletin.selectedBulletin')}
                  </div>
                </div>

                <div className="right">
                  <Dropdown
                    overlay={
                      <Menu className="table-menu-tools">
                        {/* <Menu.Item>
                              <span>
                                <Icon type="bulletin-unload" />
                                {t('legalBulletin.unload')}
                              </span>
                            </Menu.Item> */}
                        <Menu.Item onClick={onAddUserList}>
                          <Icon type="bulletin-add-list" />
                          {t('legalBulletin.addToList')}
                        </Menu.Item>
                      </Menu>
                    }
                    placement="bottomRight"
                  >
                    <Button>
                      <Icon type="add-list-plus" />
                      {t('legalBulletin.actions')}
                    </Button>
                  </Dropdown>
                </div>
              </div>
            )}
            <LegalBulletinContent />
          </Loader>
        </div>
      </Layout>
    </>
  );
};

export default LegalBulletinDetail;
