import React from 'react';
import { createPortal } from 'react-dom';
import { Mask } from '~/components/ui';

const Drawer = ({ children, width, height, onClose, placement = 'left', loading }) =>
  createPortal(
    <div className="drawer-wrapper">
      <Mask black onClose={onClose} />

      <div className={`drawer drawer-${placement}`} style={{ maxWidth: width, maxHeight: height }}>
        <div className="drawer-content-wrapper">
          <div className="drawer-content">{children}</div>
        </div>
      </div>
    </div>,
    document.getElementById('portal'),
  );

export default Drawer;
