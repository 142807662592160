import React, { useEffect } from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/ro';
import 'dayjs/locale/ru';
import { useSelector, shallowEqual } from 'react-redux';
import { useInjectIntl } from '~/libs/localization';
import { TableHeader, TableFooter } from '~/components/table';
import { Table, Loader, Logo, EmptyBlock, Pagination, Empty } from '~/components/ui';
import { Icon } from '../ui/Icon/Icon';

import '~/assets/scss/components/_MessagesArea.scss';

const MessagesArea = ({
  data: dataMessages,
  onChangeDateStart,
  onChangeDateEnd,
  onChangePage,
  mode,
  page,
  loading,
}) => {
  const { t } = useInjectIntl();
  const store = useSelector(({ localization, authentication }) => ({ localization, authentication }), shallowEqual);

  const onChangePeriod = date => {
    if (date[0] && date[1]) {
      onChangeDateStart(date[0].format());
      onChangeDateEnd(date[1].format());
    } else {
      onChangeDateStart(null);
      onChangeDateEnd(null);
    }
  };

  const messagesColumns = [
    {
      title: t('alerts.name'),
      dataIndex: 'title',
      key: 'title',
      className: 'name-column',
      render: (text, record) => (
        <div className="name-wrap">
          <SwitchImage
            type={record.type}
            website={record?.website}
            text={record.authority && record.message_type === 'control' ? record.authority : text}
          />
          <div className="name">
            {record.authority && record.message_type === 'control' ? record.authority : text}
            {record.is_new && <span className="tag-new">{t('alerts.new')}</span>}
          </div>
        </div>
      ),
    },
    {
      title: t('alerts.lastEdited'),
      dataIndex: 'description',
      key: 'description',
      className: 'edited-column',
      render: record => <span dangerouslySetInnerHTML={{ __html: record }} /> || <Empty />,
    },
    {
      title: t('alerts.dateOfPublishing'),
      dataIndex: 'date_created',
      key: 'date_created',
      className: 'date-column',
      render: data => (
        <>
          <Icon type="clock" />
          {dayjs(data).fromNow()}
        </>
      ),
    },
  ];

  const alertsColumns = [
    {
      title: t('alerts.name'),
      dataIndex: 'description',
      key: 'description',
      className: 'name-column',
      render: (text, record) => (
        <div className="name-wrap">
          <SwitchImage
            type={record.type}
            website={record?.website}
            text={record.authority && record.type === 'control' ? record.authority : text}
          />
          <div className="name">
            {record.authority && record.type === 'control' ? record.authority : text}
            {record.status === 'UNREAD' && <span className="tag-new">{t('alerts.new')}</span>}
          </div>
        </div>
      ),
    },
    {
      title: t('alerts.lastEdited'),
      dataIndex: 'message_text',
      key: 'message_text',
      className: 'edited-column',
      render: (text, record) => (
        <>
          {<span dangerouslySetInnerHTML={{ __html: text }} /> || <Empty />}{' '}
          {record.link && (
            <a href={record.link} target="_blank" rel="noopener noreferrer">
              {t('alerts.moreDetails')} <Icon type="arrow-right" />
            </a>
          )}
        </>
      ),
    },
    {
      title: t('alerts.dateOfPublishing'),
      dataIndex: 'date_created',
      key: 'date_created',
      className: 'date-column',
      render: record =>
        record ? (
          <>
            <Icon type="clock" />
            {dayjs(record).fromNow()}
          </>
        ) : (
          <></>
        ),
    },
  ];

  const monitoringColumns = [
    {
      title: t('alerts.name'),
      dataIndex: 'name',
      key: 'name',
      className: 'name-column',
      render: (text, record) => (
        <div className="name-wrap">
          <SwitchImage type={record.type} website={record?.website} text={text} />
          {text && record.status ? (
            <div className="name">
              {text} {record.status === 'NEW' && <span className="tag-new">{t('alerts.new')}</span>}
            </div>
          ) : (
            <Empty />
          )}
        </div>
      ),
    },
    {
      title: t('alerts.lastEdited'),
      dataIndex: 'message',
      key: 'message',
      className: 'edited-column',
      render: (text, record) =>
        text ? (
          <>
            <div dangerouslySetInnerHTML={{ __html: text }} />{' '}
            {record.website && (
              <a href={record.website} target="_blank" rel="noopener noreferrer">
                {t('alerts.moreDetails')} <Icon type="arrow-right" />
              </a>
            )}
          </>
        ) : (
          <Empty />
        ),
    },
    {
      title: t('alerts.type'),
      dataIndex: 'event_type_name',
      key: 'event_type_name',
      className: 'type-column',
      render: data => data,
    },
    {
      title: t('alerts.dateOfPublishing'),
      dataIndex: 'date_created',
      key: 'date_created',
      className: 'date-column',
      render: data => (
        <>
          <Icon type="clock" />
          {dayjs(data).fromNow()}
        </>
      ),
    },
  ];

  const legalColumns = [
    {
      title: t('alerts.name'),
      dataIndex: 'name',
      key: 'name',
      className: 'name-column',
      render: (text, record) => (
        <div className="name-wrap">
          <SwitchImage type={record.type} website={record?.website} text={text} />
          {record.headers?.name_acts ? (
            <div className="name">
              {record.headers.name_acts} • {record.headers.number_acts} • {record.headers.date_acts}{' '}
              {record.status === 'NEW' && <span className="tag-new">{t('alerts.new')}</span>}
            </div>
          ) : (
            <Empty />
          )}
        </div>
      ),
    },
    {
      title: t('alerts.lastEdited'),
      dataIndex: 'message_text',
      key: 'message_text',
      className: 'edited-column',
      render: (text, record) =>
        text ? (
          <>
            <div dangerouslySetInnerHTML={{ __html: text }} />{' '}
            {record.link && (
              <a href={record.link} target="_blank" rel="noopener noreferrer">
                {t('alerts.moreDetails')} <Icon type="arrow-right" />
              </a>
            )}
          </>
        ) : (
          <Empty />
        ),
    },
    {
      title: t('alerts.dateOfPublishing'),
      dataIndex: 'headers',
      key: 'headers',
      className: 'date-column',
      render: data => (
        <>
          <Icon type="clock" />
          {dayjs(data.date_created).fromNow()}
        </>
      ),
    },
  ];

  useEffect(() => {
    dayjs.extend(relativeTime);
    dayjs.locale(store.localization.current.shortName);
  }, []);

  if (!dataMessages) {
    return null;
  }

  return (
    <div className="messages-area monitoring-all">
      {mode === 'messages' && (
        <Loader loading={!dataMessages?.results || loading}>
          <TableHeader
            leftSide={
              <h1>
                {dataMessages.total_results} {t('alerts.messages')}
              </h1>
            }
            rightSide={
              <DatePicker.RangePicker
                onChange={onChangePeriod}
                placeholder={[t('alerts.startDate'), t('alerts.endDate')]}
              />
            }
          />

          {dataMessages.results?.length ? (
            <>
              <Table
                dataSource={dataMessages.results}
                columns={messagesColumns}
                className="messages-table"
                rowClassName={record =>
                  record.is_new || ['UNREAD', 'NEW'].includes(record.status) ? 'new-unread-message' : ''
                }
              />
              <TableFooter leftSide={<Pagination page={page} total={dataMessages.pages} onChange={onChangePage} />} />
            </>
          ) : (
            <EmptyBlock />
          )}
        </Loader>
      )}

      {(mode === 'alerts' || mode === 'list') && (
        <Loader loading={!dataMessages?.data || loading}>
          <TableHeader
            leftSide={
              <h1>
                {dataMessages.total_results} {t('alerts.messages')}
              </h1>
            }
            rightSide={
              <DatePicker.RangePicker
                onChange={onChangePeriod}
                placeholder={[t('alerts.startDate'), t('alerts.endDate')]}
              />
            }
          />

          {dataMessages.data?.length ? (
            <>
              <Table
                dataSource={dataMessages.data}
                columns={alertsColumns}
                className="messages-table"
                rowClassName={record =>
                  record.is_new || ['UNREAD', 'NEW'].includes(record.status) ? 'new-unread-message' : ''
                }
              />
              <TableFooter leftSide={<Pagination page={page} total={dataMessages.pages} onChange={onChangePage} />} />
            </>
          ) : (
            <EmptyBlock />
          )}
        </Loader>
      )}

      {mode === 'monitoring' && (
        <Loader loading={!dataMessages?.results || loading}>
          <TableHeader
            leftSide={
              <h1>
                {dataMessages?.total_results} {t('alerts.messages')}
              </h1>
            }
            rightSide={
              <DatePicker.RangePicker
                onChange={onChangePeriod}
                placeholder={[t('alerts.startDate'), t('alerts.endDate')]}
              />
            }
          />
          {dataMessages?.total_results > 0 ? (
            <>
              <Table
                dataSource={dataMessages.results}
                columns={monitoringColumns}
                className="messages-table"
                rowClassName={record =>
                  record.is_new || ['UNREAD', 'NEW'].includes(record.status) ? 'new-unread-message' : ''
                }
              />
              <TableFooter
                leftSide={<Pagination page={page} total={dataMessages.total_pages} onChange={onChangePage} />}
              />
            </>
          ) : (
            <EmptyBlock />
          )}
        </Loader>
      )}

      {mode === 'legal-bulletin' && (
        <Loader loading={!dataMessages?.data > 0 || loading}>
          <TableHeader
            leftSide={
              <h1>
                {dataMessages?.total_results} {t('alerts.messages')}
              </h1>
            }
            rightSide={
              <DatePicker.RangePicker
                onChange={onChangePeriod}
                placeholder={[t('alerts.startDate'), t('alerts.endDate')]}
              />
            }
          />
          {dataMessages?.total_results > 0 ? (
            <>
              <Table
                dataSource={dataMessages.data}
                columns={legalColumns}
                className="messages-table"
                rowClassName={record =>
                  record.is_new || ['UNREAD', 'NEW'].includes(record.status) ? 'new-unread-message' : ''
                }
              />
              <TableFooter leftSide={<Pagination page={page} total={dataMessages.pages} onChange={onChangePage} />} />
            </>
          ) : (
            <EmptyBlock />
          )}
        </Loader>
      )}
    </div>
  );
};

export default MessagesArea;

export const SwitchImage = ({ type, website, text }) => {
  const images = {
    electric_fenosa: 'MonitorizareBlock.png',
    apa_canal_chisinau: 'ApaChisinau.png',
    legal_act: 'MonitorizareBlock.png',
    premier_energy: 'PremierEnergy.png',
    changes_app: 'MonitorizareBlock.png',
    control: 'ANRCETI.png',
  };

  return images[type] ? (
    <img src={`/assets/img/alerts/${images[type]}`} alt={images[type]} />
  ) : (
    <Logo website={website || ''} name={text} />
  );
};
