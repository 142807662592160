import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Tabs } from 'antd';
import { ignoredKeys } from '~/store/filterStore';
import { useInjectIntl } from '~/libs/localization';
import { FilterTag } from '~/components/filter';

export const FilterActive = props => {
  const { t } = useInjectIntl();
  const store = useSelector(
    ({ filter }) => ({
      activeFilter: filter.activeFilter,
      filter: filter.filter,
    }),
    shallowEqual,
  );

  return (
    <Tabs className="filter-active-tags zh-filter-tags">
      {Object.keys(store.activeFilter)
        .filter(key => !ignoredKeys.includes(key))
        .map(filter => (
          <Tabs.TabPane
            key={filter}
            tab={
              <FilterTag
                key={filter}
                listDetails={filter === 'list_id' ? props.listDetails : {}}
                filterKey={filter}
                t={t}
                filterData={store.activeFilter[filter]}
                deleteTag={props.deleteTag}
                changeSubFilter={props.changeSubFilter}
                filter={store.filter}
              />
            }
          />
        ))}
    </Tabs>
  );
};
