import React, { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { message } from 'antd';
import { useStateHandlers, useDocumentTitle } from '~/hooks';
import { getRoute, Link } from '~/libs/router';
import { updates } from '~/libs/http/api';
import { translated } from '~/libs/translated';
import { Confirmation, EmptyBlock, Loader } from '~/components/ui';
import { AddUpdate, ManagementMenu } from '~/components/management';
import { injectIntl } from '~/libs/localization';
import { Layout } from '~/components/layouts';
import { Icon } from '~/components/ui/Icon/Icon';

const AdminPlatformPage = ({ t, get, validate, ...props }) => {
  const [state, setState] = useStateHandlers({
    list: [],
    page: 1,
    total: 0,
    hasMore: true,
    loading: false,
    currentTab: null,
  });

  useDocumentTitle(t('page.admin'));

  useEffect(() => {
    onFetch();
  }, [state.page]);

  useEffect(() => {
    if (props.match.params.id === 'unset') {
      if (state.page === 1) {
        onFetch();
      } else {
        setState({ page: 1 });
      }
    }
  }, [props.match.params.id]);

  const onFetch = async () => {
    setState({ loading: true });
    try {
      const { data } = await updates.list({ page: state.page });

      setState({
        list: state.page === 1 ? data.data : state.list.concat(data.data),
        total: data.count,
        loading: false,
        hasMore: state.page < data.pages,
      });

      if (props.match.params.id === 'unset' && data.data.length > 0) {
        props.history.push(getRoute('AdminPlatformPage', { id: data.data[0].id }));
        setState({ currentTab: data.data[0].id });
      } else {
        setState({ currentTab: props.match.params.id });
      }
    } catch (err) {
      global.console.log(err);
    }
  };

  const onTabClick = id => {
    props.history.push(getRoute('AdminPlatformPage', { id }));
    setState({ currentTab: id });
  };

  const onDelete = async id => {
    try {
      await updates.delete({ id });
      setState({ list: [] });
      props.history.push(getRoute('AdminPlatformPage', { id: 'unset' }));

      message.success(t('other.success.deleted'));
    } catch (err) {
      message.error(t('other.try_again'));
    }
  };

  const onChangePage = () => !state.loading && state.hasMore && setState({ page: state.page + 1 });

  const refreshList = () => {
    if (state.page === 1) {
      onFetch();
    } else {
      setState({ page: 1 });
    }
  };

  return (
    <Layout containerClass="updatesEdit-container">
      <ManagementMenu />

      {props.loading && <Loader loading />}

      <div className="tabs-flex">
        <div className="custom-tabs">
          <div className="custom-tabs-header">
            <span>
              {t('updates.title')} ({state.total})
            </span>

            <Link className="add-p" route="AdminAddPlatformPage">
              <Icon type="plus" />
            </Link>
          </div>
          <InfiniteScroll
            initialLoad={false}
            pageStart={1}
            loadMore={onChangePage}
            hasMore={!state.loading && state.hasMore}
            useWindow={false}
          >
            {state.loading && state.list.length === 0 ? (
              <div className="skeleton-list-loading">
                {[...Array(6).keys()].map(key => (
                  <div className="skeleton" key={key} />
                ))}
              </div>
            ) : null}
            {state.list.map(item => (
              <div
                className={`tabs-elem ${Number(props.match.params.id) === item.id ? 'tab-active' : ''}`}
                key={item.id}
                onClick={() => onTabClick(item.id)}
              >
                {props.match.params.id === item.id}

                <span>{translated(item.languages)}</span>

                <Confirmation type="delete" onConfirm={() => onDelete(item.id)}>
                  <Icon type="delete" style={{ color: '#ff4d4f' }} />
                </Confirmation>
              </div>
            ))}
          </InfiniteScroll>
        </div>

        <div className="tabs-content">
          {state.loading ? (
            <Loader loading />
          ) : state.currentTab !== 'unset' && state.currentTab !== null ? (
            <AddUpdate validate={validate} id={state.currentTab} refreshList={refreshList} />
          ) : (
            <EmptyBlock style={{ marginTop: 10 }} />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default injectIntl(AdminPlatformPage);
