import React, { useState } from 'react';
import { Rate, Input, Form } from 'antd';
import { useRequest } from 'estafette';
import { notification } from '~/libs/notification';
import { feedback } from '~/libs/http/api';
import { validate } from '~/libs/object';
import { Modal, Button } from '~/components/ui';
import { useInjectIntl } from '~/libs/localization';

const FeedbackModal = ({ onClose, ...props }) => {
  const { t } = useInjectIntl();
  const { request, errors, loading } = useRequest();
  const [rating, setRate] = useState();
  const [text, onChangeText] = useState();

  const onSubmit = async () => {
    await request(feedback.send({ content_type: 'exports', object_id: props.activeExport, rating, text }));

    notification({
      title: t('other.feedbackSuccess'),
      message: t('other.success'),
    });

    onClose();
  };

  const onChangeRate = value => setRate(value);
  const onChangeMeesage = ({ target }) => onChangeText(target.value);

  return (
    <Modal
      onClose={onClose}
      className="feedback-modal"
      clean
      width="500px"
      title={t('other.sendFeedback')}
      footer={
        <>
          <Button key="back" onClick={onClose}>
            {t('other.reset')}
          </Button>

          <Button key="submit" type="primary" loading={loading} onClick={onSubmit}>
            {t('other.submit')}
          </Button>
        </>
      }
    >
      <Form className="feedback-form">
        <div className="feedback-form-title">{t('other.rating')}</div>

        <Form.Item>
          <Rate onChange={onChangeRate} />
        </Form.Item>

        <Form.Item label={t('other.description')} {...validate(errors, 'text')} extra={t('other.feedbackInfo')}>
          <Input.TextArea onChange={onChangeMeesage} autosize={{ minRows: 4, maxRows: 6 }} onPressEnter={onSubmit} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default FeedbackModal;
