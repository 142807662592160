import axios from '~/libs/http/axios';

const { baseURL } = axios.defaults;

const exportings = {
  delete: (option = {}) => axios.post(`${baseURL}/api/exports/delete`, option),
  download: (params = {}) => axios.get(`${baseURL}/api/exports/download`, { params }),
  filters: (option = {}) => axios.post(`${baseURL}/api/exports/save`, option),
  limit: (option = {}) => axios.post(`${baseURL}/api/exports/limit`, option),
  list: (option = {}) => axios.post(`${baseURL}/api/exports/list`, option),
  providersPdf: (option = {}) => axios.post(`${baseURL}/api/exports/providers/pdf`, option),
  providersXls: (option = {}) => axios.post(`${baseURL}/api/exports/providers/xls`, option),
  getList: (params = {}) => axios.get(`${baseURL}/api/exports/list`, { params }),
  getHistory: (params = {}) => axios.get(`${baseURL}/api/exports/history`, { params }),
  credits: (params = {}) => axios.get(`${baseURL}/api/exports/credits`, { params }),
  fields: (params = {}) => axios.get(`${baseURL}/api/exports/fields`, { params }),
  cart: (option = {}) => axios.post(`${baseURL}/api/billing/cart/add/export`, option),
  wire: (params = {}) =>
    axios.request({
      url: `${baseURL}/api/billing/wire/add/export`,
      method: 'POST',
      responseType: 'blob',
      data: params,
    }),
  exampleExport: (params = {}) =>
    axios.request({
      url: `${baseURL}/api/exports/example`,
      method: 'POST',
      responseType: 'blob',
      data: params,
    }),
};

export default exportings;
