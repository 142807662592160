import React, { useEffect, memo } from 'react';
import { Tree, Tooltip, message } from 'antd';
import { compose } from 'redux';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useStateHandlers, useLoading } from '~/hooks';
import { saveBlob } from '~/libs/saveBlob';
import { injectIntl } from '~/libs/localization';
import { notification } from '~/libs/notification';
import { mapDataExport } from '~/libs/adapter';
import { getRoute, Link } from '~/libs/router';
import { exportings } from '~/libs/http/api';
import { capitalize } from '~/libs/string';
import { number } from '~/libs/number';
import { has } from '~/libs/object';
import { Modal, Button, Loader } from '~/components/ui';
import { PaymentData } from '~/components/cart/payment';
import { ValidationModal } from '~/components/modal';
import { ExportSearchModal, ExportDetailsModal } from '.';
import { Icon } from '~/components/ui/Icon/Icon';

const renderTreeNodes = children =>
  children &&
  children.map(item => {
    if (item.children) {
      return (
        <Tree.TreeNode title={item.title} key={item.key} dataRef={item}>
          {renderTreeNodes(item.children)}
        </Tree.TreeNode>
      );
    }

    return <Tree.TreeNode {...item} />;
  });

export const providers = {
  subdivision: {
    header: 'company_information',
    name: 'companies_xls',
    plural: 'companies',
    dataExport: ['company_information', 'company_location'],
  },
  company: {
    header: 'company_information',
    name: 'companies_xls',
    plural: 'companies',
    dataExport: ['company_information', 'company_location'],
  },
  employee: {
    header: 'employees',
    name: 'companies_xls',
    plural: 'employees',
    dataExport: ['company_information/name', 'employees', 'employee_contacts'],
  },
  company_details: {
    header: 'company_information',
    name: 'companies_xls',
    plural: 'companies',
    dataExport: [],
    details: true,
  },
  employee_details: {
    header: 'employees',
    name: 'companies_xls',
    plural: 'employees',
    dataExport: [],
    details: true,
  },
};

const ExportModal = ({ t, provider, data = [], ...props }) => {
  const history = useHistory();
  const [limitLoading, limitCallback] = useLoading(true);
  const [submitLoading] = useLoading(false);
  const [cartLoading, cartCallback] = useLoading(false);
  const [state, setState] = useStateHandlers({
    changedData: false,
    errors: {},
    dataExport: [],
    limits: {},
    selected: 0,
    changedName: false,
    name: '',
    upgrade: false,
    paymentRequired: false,
    loading: false,
    mappedDataExport: {},
    successExport: false,
    loadingExport: false,
    exportName: '',
    loadingExportExample: false,
  });

  useEffect(() => {
    const onFetchFields = async () => {
      if (!providers[provider].details) {
        setState({ loading: true });
        const dataFields = (await exportings.fields({ export_type: providers[provider].name })).data;

        // Get default export parents, after that get parents' children
        const { dataExport } = providers[provider];
        dataFields
          .filter(({ codename }) => dataExport.includes(codename))
          .forEach(({ codename, fields = [] }) => {
            fields.forEach(child => dataExport.push(`${codename}/${child.codename}`));
          });

        setState({
          changedData: true,
          loading: false,
          fields: dataFields.map(parent => ({
            key: parent.codename,
            title: parent.name,
            children: parent.fields.map(child => ({
              key: `${parent.codename}/${child.codename}`,
              title: child.name,
            })),
          })),
          dataExport,
          mappedDataExport: mapDataExport(dataExport),
        });
      }
    };

    onFetchFields();
  }, []);

  useEffect(() => {
    const onFetchLimits = async () => {
      if (state.changedData || providers[provider].details) {
        try {
          const { data: limits } = await limitCallback(
            exportings.limit({
              export_type: providers[provider].name,
              filters: {
                must:
                  Object.keys(data).length === 0 ? props.activeFilter : { [`${providers[provider].plural}_id`]: data },
              },
              data_to_export: state.mappedDataExport,
            }),
          );

          setState({
            limits,
            paymentRequired: limits.total_price > 0,
          });
        } catch (errors) {
          setState({ limitsLoading: false, errors: has(errors, 'response.data', {}) });
        }
      }
    };

    onFetchLimits();
  }, [state.changedData, state.mappedDataExport]);

  const onGenerateName = () => t('company.export_name', { name: capitalize(provider) });

  const onToggleUpgrade = () => setState({ upgrade: !state.upgrade });

  const onCloseSuccessExport = () => {
    setState({ successExport: false });
    props.onClose();
  };

  // const onOkSuccessExport = () => {
  //   history.push(
  //     getRoute('DownloadsPage', {
  //       type: 'list',
  //       page: 1,
  //     }),
  //   );
  // };

  const successSave = () => {
    setState({ successExport: true });
  };

  const onSaveExport = async options => {
    const exportName = props.name || state.name || onGenerateName();
    setState({ loadingExport: true });
    setState({ exportName });
    const blobData = await exportings.wire({
      name: exportName,
      export_type: providers[provider].name,
      data_to_export: state.mappedDataExport,
      filters: {
        must: data.length === 0 ? props.activeFilter : { [`${providers[provider].plural}_id`]: data },
      },
      ...options,
    });
    setState({ loadingExport: false });

    saveBlob(blobData.data, 'invoice.pdf');
    successSave();
  };

  const onSubmit = async ev => {
    ev.preventDefault();

    await onSaveExport();
    onSuccess();
  };

  const onAddCart = async () => {
    try {
      await cartCallback(
        exportings.cart({
          name: props.name || state.name || onGenerateName(),
          export_type: providers[provider].name,
          data_to_export: state.mappedDataExport,
          filters: {
            must: data.length === 0 ? props.activeFilter : { [`${providers[provider].plural}_id`]: data },
          },
        }),
      );

      message.success(t('company.added_success'));

      history.push(getRoute('OrderPage', { page: 1 }));
    } catch (err) {
      message.error(t('other.error_module'));
    }
  };

  const onChangeName = ({ target }) => setState({ changedName: true, name: target.value });

  const onChangeExport = dataExport => {
    let newDataExport = [];
    let hasEmployeeInfo = false;
    dataExport.forEach(val => {
      if (val.match('employees_info') || val.match('employee_contacts')) {
        hasEmployeeInfo = true;
      }
    });

    if (hasEmployeeInfo) {
      newDataExport = ['employees/first_name', 'employees/last_name', ...dataExport];
    } else {
      newDataExport = ['company_information/name', ...dataExport];
    }

    setState({ dataExport: newDataExport, mappedDataExport: mapDataExport(newDataExport) });
  };

  const onSuccess = () => {
    notification({
      key: 'pending',
      type: 'info',
      duration: 5,
      icon: <Icon type="loading" />,
      title: `${state.name || t('other.exportWordModal')} ${t('other.addQueue')}`,
      direction: t('notification.export.direction'),
      onClick: () => history.push(getRoute('DownloadsPage', { page: 1, type: 'list' })),
    });
  };

  const onExportExample = async () => {
    const exportName = props.name || state.name || onGenerateName();
    setState({ loadingExportExample: true });
    const blobData = await exportings.exampleExport({
      name: exportName,
      export_type: providers[provider].name,
      filters: {
        must: Object.keys(data).length === 0 ? props.activeFilter : { [`${providers[provider].plural}_id`]: data },
      },
    });
    saveBlob(blobData.data, 'export_example.xlsx');
    setState({ loadingExportExample: false });
  };

  const renderTotal = () => {
    return (
      <>
        <>
          {state.limits.rows_for_free ? (
            <li key="general-total-free" className={`zh-iconed-text ${limitLoading ? 'grey' : ''}`}>
              <Icon type="download-verify-checked" />
              {number(state.limits.rows_for_free)} general data <span className="credit-badge">Free</span>
            </li>
          ) : null}

          {state.limits.rows_to_pay ? (
            <li key="general-total-to-pay" className={`zh-iconed-text ${limitLoading ? 'grey' : ''}`}>
              <Icon type="download-verify-checked" />
              {number(state.limits.rows_to_pay)} general data for {Number(state.limits.rows_price)}{' '}
              {state.limits.currency}
            </li>
          ) : null}
        </>
        {Object.keys(has(state, 'limits.fields', {})).map(key => {
          const { count = state.limits.total[key], price, free, vas, name } = state.limits.fields[key] || {};

          return (
            count && (
              <li key={key}>
                {free ? (
                  <div className={`zh-iconed-text clearfix ${limitLoading ? 'grey' : ''}`}>
                    <Icon type="download-verify-checked" />
                    {`${number(free > count ? count : free)} ${name} `}
                    <span className="credit-badge">Free</span>
                  </div>
                ) : null}

                {price ? (
                  <div className={`zh-iconed-text clearfix ${limitLoading ? 'grey' : ''}`}>
                    <Icon type="download-verify-checked" />

                    <div className="pull-left">
                      {`${number(count - free)} ${name}`}

                      <Tooltip placement="top" title={vas || 0}>
                        <span className="info-badge default">?</span>
                      </Tooltip>
                    </div>

                    <div className="pull-right">{`${price} ${state.limits.currency}`}</div>
                  </div>
                ) : null}
              </li>
            )
          );
        })}
      </>
    );
  };

  const renderModal = () => {
    const exportProps = {
      renderTotal,
      provider,
      limitLoading,
      name: props.name,
      limits: state.limits,
      fields: state.fields,
      loading: state.loading,
      paymentRequired: state.paymentRequired,
      onAddCart,
    };

    if (!providers[provider].details) {
      return (
        <ExportSearchModal
          {...exportProps}
          renderTreeNodes={renderTreeNodes}
          onChangeName={onChangeName}
          onChangeExport={onChangeExport}
          dataExport={state.dataExport}
        />
      );
    }

    if (providers[provider].details) {
      return <ExportDetailsModal {...exportProps} />;
    }

    return null;
  };

  if (state.errors.detail || state.errors.non_field_errors) {
    return (
      <ValidationModal
        type="error"
        message={state.errors.detail || state.errors.non_field_errors}
        onClose={props.onClose}
      />
    );
  }

  if (state.successExport) {
    return (
      <ValidationModal
        type="success"
        message={t('company.save')}
        onClose={onCloseSuccessExport}
        customButton={
          <>
            <br />
            <Link route="DownloadsPage" params={{ type: 'list', page: 1 }}>
              <span className="link">{state.exportName}</span>
            </Link>
          </>
        }
      />
    );
  }

  return (
    <>
      {state.upgrade && (
        <PaymentData
          modal
          onSaveExport={onSaveExport}
          onClose={onToggleUpgrade}
          onSuccess={onSuccess}
          route="DownloadsPage"
          redirectParams={{ page: 1, type: 'list' }}
          parent="export"
        />
      )}

      <Modal
        {...(state.upgrade ? { style: { display: 'none ' } } : {})}
        className="zh-modal-export modal-nocontent"
        width="500px"
        height="600px"
        onClose={props.onClose}
        footer={
          <div className="export-footer">
            <Button onClick={props.onClose} disabled={submitLoading || limitLoading}>
              {t('other.reset')}
            </Button>

            <Button type="primary" onClick={onExportExample} loading={state.loadingExportExample}>
              {t({ id: 'other.downloadExample' })}
            </Button>

            <Button
              type="primary"
              onClick={onSubmit}
              loading={submitLoading || limitLoading || state.loadingExport}
              disabled={
                (Object.keys(state.mappedDataExport).length === 0 && !providers[provider].details) ||
                state.loadingExport
              }
            >
              {t({ id: 'other.download' })}
            </Button>
          </div>
        }
      >
        <Loader height={536} loading={cartLoading}>
          {renderModal()}
        </Loader>
      </Modal>
    </>
  );
};

const enhance = compose(
  injectIntl,
  connect(({ filter, searches }) => ({
    total: searches.total,
    activeFilter: filter.activeFilter,
  })),
  memo,
);

export default enhance(ExportModal);
