import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Checkbox, Switch } from 'antd';
import { useRequest } from 'estafette';
import MediaQuery from 'react-responsive';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { changeSubFilter, onChangeFilter, closeFilterInner } from '~/store/actions/FilterActions';
import { ignoredKeys } from '~/store/filterStore';
import { getRoute } from '~/libs/router';
import { useInjectIntl } from '~/libs/localization';
import company from '~/libs/http/api/company';
import dashboard from '~/libs/http/api/dashboard';
import { Text, Button, Loader } from '~/components/ui';
import { FilterDropItem, FilterCounts, FilterTarget, FilterItems } from '~/components/filter';

const wide = 1279;

const hideBlock = false;

export const FilterChild = () => {
  const { t } = useInjectIntl();
  const { request, data } = useRequest();
  const { request: requestTopFive, data: dataTopFive, loading: loadingTopFive } = useRequest();

  const history = useHistory();
  const dispatch = useDispatch();

  const store = useSelector(
    ({ filter, searches }) => ({
      showFilterInner: filter.showFilterInner,
      filter: filter.filter,
      activeFilter: filter.activeFilter,
      total: searches.total,
      searchesLoading: searches.loading,
      recents: filter.recents,
      filterLoading: filter.loading,
    }),
    shallowEqual,
  );

  const [switchValue, setSwitchValue] = React.useState(false);
  const [companyHas, setCompanyHas] = React.useState(null);

  useState(() => {
    request(dashboard.nomenclatures());
    requestTopFive(company.fiveLastCompanies());
  }, []);

  const onChangeTarget = async (type, name, value, key) => {
    if (name === 'company_has') {
      setCompanyHas({ type, name, value, key });
    }
    dispatch(onChangeFilter(type, name, value, key, switchValue));
  };

  const onChangeSwitch = () => {
    if (companyHas) {
      const { type, name, value, key } = companyHas;
      dispatch(onChangeFilter(type, name, value, key, !switchValue));
    }
    setSwitchValue(!switchValue);
  };

  const onCheckSubdivision = ({ target }) => {
    dispatch(onChangeFilter('radiobox', 'subdivision', target.checked ? '1' : '', 0, switchValue));
    dispatch(onChangeFilter('radiobox', 'subdivision_and_parent', 0, switchValue));
    onRemoveDependencies();
  };

  const onCheckParentsSubdivision = ({ target }) => {
    dispatch(onChangeFilter('radiobox', 'subdivision_and_parent', target.checked ? '1' : '', 0, switchValue));
    dispatch(onChangeFilter('radiobox', 'subdivision', 0, switchValue));
  };

  const onRemoveDependencies = () => {
    store.filter.forEach(item => {
      item.children.forEach(element => {
        if (!checkRule(element)) {
          dispatch(onChangeFilter(element.type, element.name, '', 0, switchValue));
        }
      });
    });
  };

  const checkRule = item => {
    if (item.rule) {
      if (store.activeFilter[item.rule.target]) {
        return item.rule.value;
      }

      return !item.rule.value;
    }

    return true;
  };

  const onCompanyInfo = companyId => history.push(getRoute('GeneralsPage', { id: companyId }));

  const onCloseFilter = () => dispatch(closeFilterInner(false));

  return (
    <div
      data-testid="filter-child"
      className={`filter-general hidden-scroll ${store.showFilterInner ? 'filter-general-mobile-open' : ''}`}
    >
      <div className="filter-block">
        <h2 className="filter-header filter-header-border">{t('filter.startSearch')}</h2>

        <FilterItems />

        <Checkbox
          className="filter-subdivision-check"
          checked={store.activeFilter.subdivision === '1'}
          onClick={onCheckSubdivision}
        >
          {t('filter.subdivisionSearch')}
        </Checkbox>

        <div className="top-comapnies-block">
          <Loader loading={loadingTopFive}>
            <h2 className="filter-header">{t('filter.recents')}</h2>
            <ul className="company-list">
              {dataTopFive.map(item => (
                <li
                  className="company-list__item"
                  key={item.id}
                  role="presentation"
                  onClick={() => onCompanyInfo(item.id)}
                >
                  {item.name}
                </li>
              ))}
            </ul>
          </Loader>
        </div>

        {/* TODO: hidden for later */}
        {/* <div className="fade-in" style={{ display: store.recents.length ? 'block' : 'none' }}>
          <h2 className="filter-header">{t('filter.recents')}</h2>

          <div data-testid="filter-recent" className="filter-recent-wrapper">
            {store.recents.map(recent => (
              <div key={recent.date_created} className="filter-recent">
                <Link route={recent.parent_id ? 'SubdivisionPage' : 'GeneralsPage'} params={{ id: recent.company_id }}>
                  <Truncate>{recent.name || '---'}</Truncate>

                  <small>
                    <Time date={recent.date_created} relative />
                  </small>
                </Link>
              </div>
            ))}
          </div>
        </div> */}
      </div>

      <div className={`filter-block filter-block-${store.showFilterInner ? 'open' : 'close'}`}>
        <i className="anticon anticon-arrow-left go-back" onClick={onCloseFilter} />

        <FilterCounts />

        <div data-testid="filter-dropdown" className="filter-dropdown-wrapper">
          <Checkbox
            value={store.activeFilter.subdivision_and_parent}
            checked={store.activeFilter.subdivision_and_parent}
            onChange={onCheckParentsSubdivision}
            className={`subdivision-checkbox ${store.activeFilter.subdivision_and_parent ? 'subdivision-checked' : ''}`}
          >
            {t('filter.addSubdivisions')}
          </Checkbox>

          {store.filter.map(
            ({ id, children, open, type: filterType }, filterKey) =>
              open &&
              children.map((item, itemKey) =>
                !ignoredKeys.includes(item.name) && checkRule(item) ? (
                  <FilterDropItem
                    key={item.name}
                    item={item}
                    loading={store.filterLoading}
                    onChange={() => dispatch(changeSubFilter(filterKey, item.name))}
                  >
                    {item.extra && (
                      <span className="extra">
                        <Text id={item.extra} />
                      </span>
                    )}

                    {hideBlock && item.name === 'company_has' && (
                      <div className="company_has-switch">
                        <span className="status-switch-title margin-right">{` ${t('other.all')}`}</span>
                        <Switch
                          className="switch--always-true"
                          checked={switchValue}
                          size="small"
                          onChange={onChangeSwitch}
                        />
                        <span className="status-switch-title margin-left"> {`${t('other.any')} `}</span>
                      </div>
                    )}

                    <FilterTarget
                      years={data.financial_years}
                      t={t}
                      item={item}
                      onChange={(value, childKey) => onChangeTarget(item.type, item.name, value, childKey)}
                      value={store.activeFilter[item.name]}
                    />
                  </FilterDropItem>
                ) : null,
              ),
          )}
        </div>

        <MediaQuery maxWidth={wide}>
          <div className="zh-filter-buttons" style={{ bottom: 0 }}>
            <Button className="zh-btn-secondary" onClick={onCloseFilter}>
              {t('other.cancel-btn')}
            </Button>

            <Button className="zh-btn-primary" onClick={onCloseFilter}>
              {t('other.apply-btn')}
            </Button>
          </div>
        </MediaQuery>
      </div>
    </div>
  );
};
