import React from 'react';
import { createPortal } from 'react-dom';
import { injectIntl } from '~/libs/localization';
import { Button, Mask } from '~/components/ui';
import { Icon } from '~/components/ui/Icon/Icon';

const DownloadModal = ({ t, onClose, loading, ...props }) => {
  const onDownloadHandler = () => !loading && props.link && onClose();

  return createPortal(
    <div className="zh-portal">
      <Mask black onClose={onClose} />

      <div className="modal cart-modal">
        <span role="presentation" className="close-icon" onClick={onClose}>
          <Icon type="download-close" />
        </span>

        <div className="header-wrapper">
          {loading ? <Icon type="loading" className="zh-history-spin" /> : <Icon type="download-success" />}

          <div className="title">{props.title || t('other.download')}</div>
          <div className="text">{loading ? props.loadingText : props.finalText}</div>
        </div>

        <a
          {...(props.link ? { href: props.link } : {})}
          download={props.download}
          target="_blank"
          rel="noopener noreferrer"
          onClick={onDownloadHandler}
        >
          <Button salat disabled={loading}>
            <span>{t('other.download')}</span>
          </Button>
        </a>
      </div>
    </div>,
    document.getElementById('portal'),
  );
};

export default injectIntl(DownloadModal);
