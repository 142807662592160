import React from 'react';
import { compose } from 'redux';
import withCompany from '~/hoc/withCompany';
import { setDocumentTitle } from '~/hooks';
import { has } from '~/libs/object';
import { injectIntl } from '~/libs/localization';
import { CompanyLayout } from '~/components/layouts';
import { Segment, Publication } from '~/components/company';
import { Loader, Empty } from '~/components/ui';

class PublicationsPage extends React.PureComponent {
  state = {};

  componentDidMount() {
    setDocumentTitle(this.props.t('page.publications'));
  }

  render() {
    const api = this.props.company.data;

    return (
      <CompanyLayout>
        <Loader loading={this.props.loading}>
          {has(api, 'publications.length') ? (
            <React.Fragment>
              <Segment.Header
                noBorder
                title={`${this.props.t({ id: 'company.monitorOfficial' })} (${api.publications.length})`}
              />

              <Publication.Section>
                {api.publications.map((item, key) => (
                  <Publication
                    key={item.id}
                    createdAt={item.date}
                    title={has(item, 'text.title', <Empty />)}
                    description={has(item, 'type.title', <Empty />)}
                  />
                ))}
              </Publication.Section>
            </React.Fragment>
          ) : null}
        </Loader>
      </CompanyLayout>
    );
  }
}

const enhance = compose(injectIntl, withCompany());

export default enhance(PublicationsPage);
