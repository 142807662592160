import React, { useMemo, useEffect } from 'react';
import { Checkbox as AntdCheckbox, Input } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import { useInjectIntl } from '~/libs/localization';
import { withDebounceChange } from '~/hoc';
import { useStateHandlers } from '~/hooks';
import { push } from '~/libs/array';
import { cleanString } from '~/libs/string';

import '~/assets/scss/components/ui/_Checkbox.scss';

const DebounceCheckboxes = withDebounceChange()(
  ({ debounce, search, value, scopeValue = [], setState, onDebounceChange, onChange, options = [], ...props }) => {
    const { t } = useInjectIntl();
    const selectedAll = useMemo(() => scopeValue.includes('select-all'), [scopeValue]);

    const onChangeHandler = ({ target }) => {
      let $scopeValue = [...scopeValue];
      if (selectedAll) {
        $scopeValue = $scopeValue.includes(target.value)
          ? // when selected all and it excluded
            $scopeValue.filter($value => target.value !== $value)
          : [...$scopeValue, target.value];
      } else {
        $scopeValue = $scopeValue.includes(target.value)
          ? $scopeValue.filter($value => target.value !== $value)
          : [...$scopeValue, target.value];
      }

      onDebounceChange($scopeValue);
    };

    const onClickSelectAll = () => {
      if (search && selectedAll) {
        onDebounceChange([]);
      }

      if (!search) {
        onDebounceChange(selectedAll ? [] : ['select-all']);
      }
    };

    return (
      <div className="zh-checkbox">
        {options.length >= 10 && (
          <div className="checkbox-select-all-wrapper">
            <div
              className={`
              checkbox-select-all 
                ${selectedAll && scopeValue.length === 1 ? 'selected' : ''} 
                ${selectedAll && scopeValue.length > 1 ? 'selected-excluded' : ''}
                ${!selectedAll && search ? 'unactive' : ''}
              `.replace(/\s\s+/g, ' ')}
              onClick={onClickSelectAll}
            >
              {t('other.selected-all')}
            </div>
          </div>
        )}

        {options.map(option => (
          <AntdCheckbox
            key={option.value}
            value={option.value}
            onChange={onChangeHandler}
            checked={
              (selectedAll && !scopeValue.includes(option.value)) || (!selectedAll && scopeValue.includes(option.value))
            }
          >
            {option.label}
          </AntdCheckbox>
        ))}
      </div>
    );
  },
);

export const Checkbox = ({ options, scrollHeight = 200, ...props }) => {
  const { t } = useInjectIntl();
  const [state, setState] = useStateHandlers({
    filtered: [],
    data: [],
    perPage: 30,
    pages: 1,
    total: 0,
  });

  const [filter, setFilter] = useStateHandlers({
    search: '',
    page: 1,
  });

  useEffect(() => {
    const onPaginated = () => {
      const indexOfLast = filter.page * state.perPage;
      const indexOfFirst = indexOfLast - state.perPage;
      const total = !filter.search.length ? options.length : state.filtered.length;

      setState({
        total,
        pages: Math.ceil(total / state.perPage),
        data: push(
          state.data,
          !filter.search.length
            ? options.slice(indexOfFirst, indexOfLast)
            : state.filtered.slice(indexOfFirst, indexOfLast),
        ),
      });
    };

    onPaginated();
  }, [filter]);

  const onSearch = value => {
    setFilter({
      page: 1,
      search: value,
    });

    const searchValue = cleanString(value);

    setState({
      data: [],
      filtered: options.filter(({ label }) => cleanString(label).match(searchValue)),
    });
  };

  const onChangeHandler = ({ target }) => onSearch(target.value);
  const onChangePage = () => setFilter({ page: filter.page + 1 });

  return (
    <div className="zh-checkbox">
      {options.length > 5 && (
        <Input.Search className="autocomplete" placeholder={t('other.keywords')} onChange={onChangeHandler} autoFocus />
      )}

      {state.data.length === 0 ? (
        <span className="tree-empty">
          <span>{t('other.empty')}</span>
        </span>
      ) : (
        <div className="zh-checkbox-scroll" style={{ maxHeight: scrollHeight }}>
          <InfiniteScroll
            initialLoad={false}
            pageStart={1}
            loadMore={onChangePage}
            hasMore={filter.page < state.pages}
            useWindow={false}
          >
            <DebounceCheckboxes {...props} options={state.data} search={filter.search} />
          </InfiniteScroll>
        </div>
      )}
    </div>
  );
};
