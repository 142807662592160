import { adapterIntl } from '~/libs/localization/helpers';

const searches = adapterIntl({
  'statics.notFound': { ro: 'Pagina nu a fost găsită', ru: 'Страница не найдена', en: 'Page is not found' },
  'statics.home': { ro: 'Acasă', ru: 'Главная', en: 'Home' },
  'statics.findCompanies': { ro: 'Caută companii', ru: 'Поиск компаний', en: 'Find companies' },
  'statics.successResetPassword': {
    ro: 'Linkul pentru resetarea parolei a fost expediat pe adresa',
    ru: 'Ссылка на изменение пароля была выслана на ',
    en: 'Reset password link',
  },
  'statics.dont-have-acc': { ro: 'Nu aveţi cont?', ru: 'Нет учетной записи?', en: "Don't have an account?" },
  'statics.recoveryPassword': { ro: 'Restabilirea parolei', ru: 'Восстановление пароля', en: 'Recovery paassword' },
  'statics.needHelp': { ro: 'Aveți nevoie de ajutor?', ru: 'Нужна помощь?', en: 'Need help?' },
  'statics.resetPassword': { ro: 'Resetează parola', ru: 'Сбросить пароль', en: 'Reset password' },
  'statics.newPassword': { ro: 'Parolă nouă', ru: 'Новый пароль', en: 'New password' },
  'statics.password': { ro: 'Parola', ru: 'Пароль', en: 'Password' },
  'statics.password-auth': { ro: 'Parola Dvs', ru: 'Ваш пароль', en: 'Your password' },
  'statics.passwordChanged': {
    ro: 'Parola Dvs. a fost schimbată cu succes',
    ru: 'Ваш пароль был успешно заменен',
    en: 'Password changed',
  },
  'statics.reg-now': { ro: 'Înregistraţi-vă', ru: 'Войти', en: 'Sign in' },
  'statics.reg-sms-verification': {
    ro: 'Activați contul dumneavoastră',
    ru: 'Активируйте вашу учетную запись',
    en: 'Activate your account',
  },
  'statics.reg-sms-description': {
    ro: 'Vă rugam sa introduceți codul din SMS',
    ru: 'Пожалуйста, введите код в СМС',
    en: 'Please enter the code in the SMS',
  },
  'statics.join-now': { ro: 'acum!', ru: 'сейчас!', en: 'now!' },
  'statics.welcome': { ro: 'Bine ați venit', ru: 'Добро пожаловать', en: 'Welcome' },
  'statics.typeEmailPassword': {
    ro: 'Introduceți emailul si parola dumneavoastră',
    ru: 'Введите ваш e-mail и пароль',
    en: 'Enter your email and password',
  },
  'statics.passwordRepeat': {
    ro: 'Parolele nu sunt identice.',
    ru: 'Пароли не совпадают.',
    en: 'Passwords are not equals.',
  },
  'statics.forgotPassword': { ro: 'Ați uitat parola?', ru: 'Забыли пароль?', en: 'Forgot password?' },
  'statics.goLogin': {
    ro: 'Deja sunteți înregistrat?',
    ru: 'Вы уже зарегистрированы?',
    en: 'Are you already registered?',
  },
  'statics.login': { ro: 'Login', ru: 'Логин', en: 'Login' },
  'statics.new-login': { ro: 'Autentificare', ru: 'Авторизоваться', en: 'Login' },
  'statics.userGuide': { ro: 'Manualul utilizatorului', ru: 'Инструкция пользователя', en: 'User Guide' },
  'statics.signup': { ro: 'Înregistrare', ru: 'Регистрация', en: 'Sign up' },
  'statics.signup_footer': {
    ro: 'Completați formularul de jos pentru a avea acces la platforma noastră',
    ru: 'Заполните форму, чтобы получить доступ к нашей платформе',
    en: 'Fill out the form below to access our platform',
  },
  'statics.help-assist': {
    ro: 'Ajutor și Asistență',
    ru: 'Помощь и поддержка',
    en: 'Help and Assistance',
  },
  'statics.type_country': {
    ro: 'Introduceți țara',
    ru: 'Введите страну',
    en: 'Enter country',
  },
  'statics.country': {
    ro: 'Țară',
    ru: 'Страна',
    en: 'Country',
  },
  'statics.new-data': {
    ro: 'Introduceți datele necesare',
    ru: 'Введите необходимые данные',
    en: 'Enter your details',
  },
  'statics.type_town': {
    ro: 'Introduceți orașul',
    ru: 'Введите город',
    en: 'Enter town',
  },
  'statics.town': {
    ro: 'Orașul',
    ru: 'Город',
    en: 'Town',
  },
  'statics.code_postal': {
    ro: 'Cod poștal',
    ru: 'Почтовый индекс',
    en: 'Code postal',
  },
  'statics.type_code_postal': {
    ro: 'Introduceți cod poștal',
    ru: 'Введите почтовый индекс',
    en: 'Enter code postal',
  },
  'statics.company': {
    ro: 'Compania',
    ru: 'Компания',
    en: 'Company',
  },
  'statics.type_company': {
    ro: 'Introduceți compania',
    ru: 'Введите компанию',
    en: 'Enter company',
  },
  'statics.remember_me': {
    ro: 'Reține-mă',
    ru: 'Запомнить',
    en: 'Remember me',
  },
  'statics.back_sign_in': {
    ro: 'Înapoi spre Autentificare',
    ru: 'Назад к Аутентификации',
    en: 'Return to Login',
  },
});

export default searches;
