import React from 'react';
import { injectIntl } from '~/libs/localization';
import { Gear } from '~/components/svg';

type ActionType =
  | {
      Modification: (props: Object) => React.Node,
      Notification: (props: Object) => React.Node,
      Edit: (props: Object) => React.Node,
      Section: (props: Object) => React.Node,
    }
  | Object;

const Action: ActionType = {};

Action.Modification = injectIntl(({ t, ...props }: Object & InjectIntlType) => (
  <span className="action-modification" onClick={props.onClick} role="presentation">
    <Gear />
    <span>{t('other.modify')}</span>
  </span>
));

Action.Notification = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14.3px" height="18.3px">
    <path
      stroke="rgb(4, 124, 192)"
      fill="rgb(4, 124, 192)"
      d="M12.670,12.629 C12.289,12.008 11.767,11.157 11.767,8.736 L11.767,7.645 C11.767,5.344 10.358,3.397 8.433,2.769 L8.433,2.555 C8.433,1.752 7.835,1.100 7.100,1.100 C6.365,1.100 5.767,1.752 5.767,2.555 L5.767,2.769 C3.842,3.397 2.433,5.344 2.433,7.645 L2.433,8.736 C2.433,11.157 1.911,12.008 1.530,12.629 C1.309,12.988 1.100,13.328 1.100,13.827 C1.100,15.013 3.365,15.429 5.202,15.572 C5.460,16.472 6.216,17.100 7.100,17.100 C7.984,17.100 8.740,16.472 8.998,15.572 C10.834,15.429 13.100,15.013 13.100,13.827 C13.100,13.328 12.891,12.988 12.670,12.629 ZM6.433,2.555 C6.433,2.154 6.732,1.827 7.100,1.827 C7.468,1.827 7.767,2.154 7.767,2.555 L7.767,2.613 C7.548,2.578 7.327,2.555 7.100,2.555 C6.873,2.555 6.652,2.578 6.433,2.613 L6.433,2.555 L6.433,2.555 ZM7.100,16.373 C6.602,16.373 6.164,16.073 5.937,15.618 C6.409,15.639 6.819,15.645 7.100,15.645 C7.381,15.645 7.791,15.639 8.263,15.618 C8.036,16.073 7.598,16.373 7.100,16.373 ZM7.100,14.918 C4.047,14.918 1.767,14.342 1.767,13.827 C1.767,13.561 1.870,13.382 2.084,13.034 C2.488,12.374 3.100,11.378 3.100,8.736 L3.100,7.645 C3.100,5.239 4.894,3.282 7.100,3.282 C9.306,3.282 11.100,5.239 11.100,7.645 L11.100,8.736 C11.100,11.378 11.712,12.374 12.116,13.034 C12.330,13.382 12.433,13.561 12.433,13.827 C12.433,14.342 10.153,14.918 7.100,14.918 Z"
    />
  </svg>
);

Action.Edit = (props: Object) => (
  <span className="action-edit" onClick={props.onClick} role="presentation">
    <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px">
      <path d="M15.832,4.404 L15.832,4.404 L7.261,12.975 L7.261,12.975 C7.213,13.023 7.155,13.060 7.092,13.089 L7.094,13.092 L0.808,15.948 L0.807,15.946 C0.735,15.979 0.655,16.000 0.571,16.000 C0.256,16.000 -0.000,15.744 -0.000,15.429 C-0.000,15.345 0.021,15.265 0.054,15.193 L0.051,15.192 L2.908,8.906 L2.911,8.908 C2.940,8.846 2.977,8.787 3.025,8.739 L3.025,8.739 L11.596,0.168 L11.596,0.168 C11.700,0.063 11.843,-0.000 12.000,-0.000 C12.158,-0.000 12.300,0.063 12.405,0.167 L12.405,0.167 L15.832,3.595 L15.832,3.595 C15.937,3.699 16.000,3.842 16.000,4.000 C16.000,4.158 15.937,4.301 15.832,4.404 L15.832,4.404 ZM3.906,9.474 L1.722,14.278 L6.526,12.094 L6.620,12.000 L4.000,9.379 L3.906,9.474 L3.906,9.474 ZM4.808,8.571 L7.429,11.192 L12.335,6.286 L9.715,3.665 L4.808,8.571 L4.808,8.571 ZM12.000,1.379 L10.523,2.857 L13.144,5.477 L14.621,4.000 L12.000,1.379 L12.000,1.379 Z" />
    </svg>
  </span>
);

Action.Section = ({ children }: Object) => <div className="action-section">{children}</div>;

export default Action;
