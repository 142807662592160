import React, { useEffect, useRef, useState } from 'react';
import { Alert, Input } from 'antd';
import { useRequest } from 'estafette';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useStateHandlers, useDocumentTitle } from '~/hooks';
import { company, profile, cancelRequests } from '~/libs/http/api';
import { useInjectIntl } from '~/libs/localization';
import { parseQuery } from '~/libs/http/url';
import { getRoute } from '~/libs/router';
import { validate } from '~/libs/object';
import { changeLocalization } from '~/store/actions/LocalizationActions';
import { SelectIdno, PopoverSignUp } from '~/components/signup';
import { Header, Footer, InputPassword, Button } from '~/components/public';
import { Auth, Form, Icon, Button as ButtonContactUs } from '~/components/ui';

let timer;
const EmailPage = () => {
  const { t } = useInjectIntl();
  // eslint-disable-next-line i18n-text/no-en
  useDocumentTitle(`${t('signup.step')} 1`, t('statics.signup'));

  const packagePublic = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const query = parseQuery(location.search);
  const { request, data, loading } = useRequest({ data: { data: [] } });
  const { request: requestStep, loading: loadingStep, errors: errorsStep } = useRequest();
  const [state, setState] = useStateHandlers({
    step: 1,
    email: '',
    idno: '',
    password: '',
    passwordRepeat: '',
  });
  const [openPopover, setOpenPopover] = useState();

  const contactFormRef = useRef(null);

  useEffect(() => {
    if (openPopover) {
      setTimeout(() => {
        contactFormRef.current.scrollIntoView({ behavior: 'smooth', inline: 'start' });
      }, 400);
    }
  }, [openPopover]);

  useEffect(() => {
    if (packagePublic.language) {
      dispatch(changeLocalization(packagePublic.language));
    }
  }, [packagePublic.language]);

  useEffect(() => {
    const onSearchCompany = () => {
      timer && clearTimeout(timer);

      if (state.idno) {
        timer = setTimeout(() => {
          cancelRequests('company.search');
          request(company.search({ company_name: state.idno, per_page: 5, subdivision: 0 }));
        }, 300);
      }
    };

    onSearchCompany();
  }, [state.idno]);

  const onSubmit = async ev => {
    ev.preventDefault();

    if (state.step === 1) {
      await requestStep(
        profile
          .registerStep1({
            email: state.email,
            password: state.password,
            confirm_password: state.passwordRepeat,
            idno: state.idno,
            redirect_url: getRoute('SignupPersonalPage'),
            package: query.package || packagePublic.id,
          })
          .catch(e => {
            if (e.response.status === 406) {
              setOpenPopover(true);
            }
            return Promise.reject(e);
          }),
      );

      setState({ step: 2 });
    }

    if (state.step === 2) {
      await requestStep(
        profile
          .resendEmail({
            email: state.email,
          })
          .catch(e => {
            if (e.response.status === 406) {
              setOpenPopover(true);
            }
            return Promise.reject(e);
          }),
      );
    }
  };

  const onSelectIdno = idno => setState({ idno });
  const onChangeIdno = idno => setState({ idno });
  const onChangeEmail = ({ target }) => setState({ email: target.value });
  const onChangePassword = ({ target }) => setState({ password: target.value });
  const onChangePasswordRepeat = ({ target }) => setState({ passwordRepeat: target.value });

  return (
    <div className={openPopover ? 'email-page-disabled' : ''}>
      <Header />

      <Auth.Container
        wide
        key="container"
        header={
          <div>
            <h1>{t('statics.signup')}</h1>
            <span className="reg-text">{t('statics.new-data')}</span>
          </div>
        }
      >
        <Form onSubmit={onSubmit} className="email-page">
          {state.step === 1 && (
            <>
              {errorsStep.detail && <Alert message={errorsStep.detail} className="mb-20" type="warning" />}

              <Form.Item {...validate(errorsStep, 'idno')}>
                <SelectIdno
                  onChange={onChangeIdno}
                  onSelect={onSelectIdno}
                  loading={loading}
                  data={data.data}
                  disabled={openPopover}
                />
              </Form.Item>

              <Form.Item {...validate(errorsStep, 'email')}>
                <Input
                  placeholder={t('signup.enterEmail')}
                  type="text"
                  value={state.email}
                  onChange={onChangeEmail}
                  disabled={openPopover}
                />
              </Form.Item>

              <Form.Item {...validate(errorsStep, 'password')}>
                <InputPassword
                  disabled={openPopover}
                  placeholder={t('other.typePassword')}
                  type="password"
                  onChange={onChangePassword}
                  value={state.password}
                />
              </Form.Item>

              <Form.Item {...validate(errorsStep, 'confirm_password')}>
                <InputPassword
                  disabled={openPopover}
                  placeholder={t('other.repeat_password')}
                  type="password"
                  onChange={onChangePasswordRepeat}
                  value={state.passwordRepeat}
                />
              </Form.Item>

              <Form.Item className="mt-30">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loadingStep}
                  disabled={loadingStep || openPopover}
                  text={t('signup.continue')}
                />
              </Form.Item>
              <Form.Item>
                <div className="email-page__contact-us">
                  <span>{t('other.signUpSupportContact')}</span>
                  <ButtonContactUs type="link" onClick={() => setOpenPopover(true)}>
                    {t('other.contactUs')}
                  </ButtonContactUs>
                </div>
              </Form.Item>
            </>
          )}

          {state.step === 2 && (
            <div className="signup-email-validation">
              <Icon type="sign-up-email-success" />

              <div className="signup-title center">{t('signup.confirmEmailSend')}</div>
              <span className="signup-desc center">{t('signup.confirmEmail')}</span>

              <Button type="primary" htmlType="submit" loading={loadingStep} text={t('signup.resendEmail')} />
            </div>
          )}
        </Form>
        {openPopover && <PopoverSignUp contactFormRef={contactFormRef} onClose={() => setOpenPopover(false)} />}
      </Auth.Container>

      <Footer />
    </div>
  );
};

export default EmailPage;
