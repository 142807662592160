import React from 'react';
import { useParams } from 'react-router-dom';
import { useDocumentTitle } from '~/hooks';
import { Link } from '~/libs/router';
import { useInjectIntl } from '~/libs/localization';
import { Auth, Button, Form } from '~/components/ui';
import { Icon } from '~/components/ui/Icon/Icon';

const SmsVerificationPage = () => {
  const { t } = useInjectIntl();
  const { type } = useParams();
  // eslint-disable-next-line i18n-text/no-en
  useDocumentTitle(t('statics.signup'));

  return (
    <>
      <Auth.Logo key="logo" />

      <Auth.Container
        wide
        key="container"
        header={
          <div>
            <h1>{t('statics.signup')}</h1>
            <p />
          </div>
        }
      >
        <Form>
          <div className="signup-email-validation">
            <Icon type={type} />

            <span className="signup-title center">{t('signup.success')}</span>
            <span className="signup-desc center">{t('signup.useAccount')}</span>

            <Form.Item className="mt-20">
              <Link route="HomePage">
                <Button type="primary" htmlType="submit" className="btn-block">
                  {t('signup.accessAccount')}
                </Button>
              </Link>
            </Form.Item>
          </div>
        </Form>
      </Auth.Container>
    </>
  );
};

export default SmsVerificationPage;
