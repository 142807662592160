import { adapterIntl } from '~/libs/localization/helpers';

const list = adapterIntl({
  'list.search_name_type': {
    ro: 'Introduceți denumirea căutării',
    ru: 'Введите название поиска',
    en: 'Enter name of search',
  },
  'list.list_name_type': {
    ro: 'Introduceți denumirea listei',
    ru: 'Введите название списка',
    en: 'Enter name of list',
  },
  'list.create': {
    ro: 'Va fi creată',
    ru: 'Будет создано',
    en: 'Will be created',
  },
  'list.company': {
    ro: 'Companii',
    ru: 'Компании',
    en: 'Companies',
  },
  'list.noLists': {
    ro: 'Nu s-au creat liste',
    ru: 'Нет созданных списков',
    en: 'No lists created',
  },
  'list.employee': {
    ro: 'Angajați',
    ru: 'Сотрудников',
    en: 'Employees',
  },
  'list.create_list': {
    ro: 'Adaugă listă',
    ru: 'Добавить список',
    en: 'Add list',
  },
  'list.add_to_list': {
    ro: 'Adaugă',
    ru: 'Добавить',
    en: 'Submit',
  },
  'list.select_list': {
    ro: 'Te rugăm sa alegi o listă',
    ru: 'Пожалуйста, выберите список',
    en: 'Please choose a list',
  },
  'list.add_list': {
    ro: 'Adaugă companii în lista existentă',
    ru: 'Добавить компании в существующий список',
    en: 'Add companies in list',
  },
  'list.add_employee_list': {
    ro: 'Adaugă angajați în lista existentă',
    ru: 'Добавить сотрудников в существующий список',
    en: 'Add employees in list',
  },
  'list.add_search': {
    ro: 'Adaugă căutarea',
    ru: 'Добавить поиск',
    en: 'Add search',
  },
  'list.save': {
    ro: 'Salvare',
    ru: 'Сохранить',
    en: 'Save',
  },
  'list.addMonitoringList': {
    ro: 'Adăugați la lista de monitorizare',
    ru: 'Добавить в список мониторинга',
    en: 'Add to monitoring list',
  },
  'list.dublication': {
    ro: 'Duplicare',
    ru: 'Дублировать',
    en: 'Dublicate',
  },
  'list.empty': {
    ro: 'Nu aveți nici o lista',
    ru: 'Нет списков',
    en: "There're not lists",
  },
  'list.empty_by_search': {
    ro: 'Nu sunt liste după căutările tale',
    ru: 'Нет списков по вашему запросу',
    en: "There're not lists by your search",
  },
  'list.companies': {
    ro: 'companii',
    ru: 'компании',
    en: 'companies',
  },
  'list.created': {
    ro: 'Creat',
    ru: 'Создан',
    en: 'Created',
  },
  'list.created1': {
    ro: 'Creată',
    ru: 'Создан',
    en: 'Created',
  },
  'list.search': {
    ro: 'Căutare',
    ru: 'Поиск',
    en: 'Search',
  },
  'list.list': {
    ro: 'Lista',
    ru: 'Список',
    en: 'List',
  },
  'list.save_as_list': {
    ro: 'Salvați ca listă',
    ru: 'Сохранить как список',
    en: 'Save as a list',
  },
  'list.save_as_list_desc': {
    ro: 'Urmărește și ține evidența companiilor alese',
    ru: 'Отслеживайте и храните выбранные компании',
    en: 'Track and keep selected companies',
  },
  'list.save_as_search': {
    ro: 'Salvează căutarea',
    ru: 'Сохранить поиск',
    en: 'Save search',
  },
  'list.save_as_search_desc': {
    ro: 'Re-rulați această căutare în orice moment',
    ru: 'Повторно запускайте этот поиск в любое время',
    en: 'Re-run this search at any time',
  },
  'list.export_for': {
    ro: 'Exportul pentru {target}',
    ru: 'Экспорт для {target}',
    en: 'Export for {target}',
  },
  'list.edit_list_name': {
    ro: 'Editeazâ denumire listă',
    ru: 'Изменение название списка',
    en: 'Edit list name',
  },
  'list.type_list_name': {
    ro: 'Introduceți denumirea listei',
    ru: 'Введите название списка',
    en: 'Enter list name',
  },
  'list.add_list_limits': {
    ro: 'Puteți adăuga maxim {limit} de {type} într-o listă, ați încercat să adăugați {current}',
    ru: 'Вы можете добавить максимум {limit} {type} в список, вы пытались добавить {current}',
    en: 'You can add a maximum of {limit} {type} in a list, you tried to add {current}',
  },
  'list.add_list_hint': {
    ro: 'Puteți adăuga maxim {limit} de {type} într-o listă',
    ru: 'Вы можете добавить максимум {limit} {type} в список',
    en: 'You can add a maximum of {limit} {type} in a list',
  },
  'list.export_list_limits': {
    ro: 'Puteți descarcă maxim {limit} de {type}, ați încercat {current}',
    ru: 'Вы можете выгрузить максимум {limit} {type}, вы пытались {current}',
    en: 'You can add a maximum of {limit} {type} in a list, you tried {current}',
  },
});

export default list;
