import React from 'react';

const JuridicalCard = ({ children, type, number }) =>
  number ? (
    <div className="juridical-card-number">
      <span>{number}</span>
      <div className={`juridical-card juridical-card-${type || 'none'}`}>{children}</div>
    </div>
  ) : (
    <div className={`juridical-card juridical-card-${type || 'none'}`}>{children}</div>
  );

JuridicalCard.Section = ({ children, style, type }) => (
  <div className={`juridical-card-section juridical-card-section-${type || 'none'}`} style={style}>
    {children}
  </div>
);

export default JuridicalCard;
