const initialState = {
  filterList: {
    page: 1,
    from_date: null,
    to_date: null,
    search: '',
    is_read: null,
    all: true,
  },
};

export const legalBulletin = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_INDUSTRIES': {
      return {
        ...state,
        bulletins: action.payload,
      };
    }

    case 'GET_INDUSTRIES': {
      return state;
    }

    case 'SET_IS_READ': {
      return {
        ...state,
        isRead: action.payload,
      };
    }

    case 'SET_FILTER_LIST': {
      return {
        ...state,
        filterList: { ...state.filterList, ...action.payload },
      };
    }

    case 'SET_ACT_ID': {
      return {
        ...state,
        acts: action.payload,
      };
    }

    default:
      return state;
  }
};
