import axios from '~/libs/http/axios';

const { baseURL } = axios.defaults;

const managementSubscription = {
  list: (params = {}) => axios.get(`${baseURL}/api/management/subscription`, { params }),
  save: (option = {}) => axios.post(`${baseURL}/api/management/subscription`, option),
};

export default managementSubscription;
