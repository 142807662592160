import React, { useMemo } from 'react';
import { InputNumber, Switch } from 'antd';
import { useInjectIntl } from '~/libs/localization';
import { Icon } from '~/components/ui/Icon/Icon';

const Permission = ({
  onClick,
  onChange,
  title = 'Title',
  limitPeriod = 'month',
  active,
  dailyLimit,
  limit,
  vas,
  id,
  switchValue,
  defaultValue: $defaultValue,
  type,
}) => {
  const { t } = useInjectIntl();

  const switchValues = [50, 51];
  const unusedPermission = [47];

  const defaultValue = useMemo(
    () =>
      Boolean(
        type === 'abonament'
          ? switchValue
            ? (switchValue.find(item => item.id === id) || {}).switch_on
            : false
          : $defaultValue,
      ),
    [type, $defaultValue, switchValue],
  );

  if (unusedPermission.includes(id)) {
    return null;
  }

  return (
    <div className={`mn-perm ${active ? 'active' : ''}`}>
      <span className="mn-perm-title">{title}</span>

      <div className="mn-perm-input">
        {switchValues.includes(id) ? (
          <div className="permission-switch">
            <p>Own industry</p>
            <Switch checked={defaultValue} onChange={value => onChange('switch_on', value)} disabled={!active} />
            <p>All industry</p>
          </div>
        ) : (
          <>
            <InputNumber
              size="small"
              min={0}
              placeholder={t('management.frequency.day')}
              disabled={!active}
              value={dailyLimit}
              formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              onChange={value => onChange('daily_limit', value)}
            />

            <InputNumber
              size="small"
              min={0}
              placeholder={t(`management.frequency.${limitPeriod || 'month'}`)}
              disabled={!active}
              value={limit}
              formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              onChange={value => onChange('limit', value)}
            />

            <InputNumber
              size="small"
              min={0}
              placeholder="VAS"
              disabled={!active}
              value={vas}
              formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              onChange={value => onChange('vas', value)}
            />
          </>
        )}
      </div>

      <Icon type="bulb" onClick={onClick} />
    </div>
  );
};

export const PermissionBlock = ({ children }) => <div className="mn-permissions">{children}</div>;

export default Permission;
