import React from 'react';
import { injectIntl } from '~/libs/localization';
import { compose } from 'redux';
import withCompany from '~/hoc/withCompany';
import { setDocumentTitle } from '~/hooks';
import { has } from '~/libs/object';
import { Link } from '~/libs/router';
import { CompanyLayout } from '~/components/layouts';
import { Segment, CompanyInfo, Info } from '~/components/company';
import { Loader } from '~/components/ui';
import { Icon } from '~/components/ui/Icon/Icon';

class SubdivisionsPage extends React.PureComponent {
  state = {};

  componentDidMount() {
    setDocumentTitle(this.props.t('page.subdivision'));
  }

  /* eslint-disable */
  generateMap(address: string) {
    const addressFormat: string = address.replace(/\,/g, ' ');

    return `http://maps.google.com/maps?q=${addressFormat.replace(/\ /g, '%20')}`;
  }
  /* eslint-enable */

  render() {
    const { t, company } = this.props;
    const api = company.data;

    return (
      <CompanyLayout>
        <Loader loading={this.props.loading}>
          <CompanyInfo data={api.general_data} noMargin />

          {has(api, 'children', []).length ? (
            <React.Fragment>
              <Segment.Header title={`${t({ id: 'company.subdivisions' })} (${api.children.length})`} />

              {api.children.map((row, key) => (
                <Segment
                  number={`${(key + 1).toLocaleString('en-US', { minimumIntegerDigits: 2 })}`}
                  gray={!row.title}
                  key={row.id}
                  title={row.title ? row.title.title : t({ id: 'company.noTitle' })}
                  titleDesc={row.branch_type ? row.branch_type.title : undefined}
                >
                  <Info.Halves>
                    {has(row, 'general_data.contact_info.address_de_facto.title', undefined) && (
                      <Info label={t({ id: 'company.addressFacto' })}>
                        <Info.Detail
                          icon={<Icon type="company-marker" />}
                          title={has(row, 'general_data.contact_info.address_de_facto.title', undefined)}
                        />

                        <a
                          href={`http://maps.google.com/maps?q=${this.generateMap(
                            has(row, 'general_data.contact_info.address_de_facto.title', 'Moldova'),
                          )}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="desc"
                        >
                          {t({ id: 'company.showMap' })} &#8594;
                        </a>
                      </Info>
                    )}

                    {has(row, 'general_data.contact_info.address_de_jur.title', undefined) && (
                      <Info label={t({ id: 'company.addressJuridical' })}>
                        <Info.Detail
                          icon={<Icon type="company-marker" />}
                          title={has(row, 'general_data.contact_info.address_de_jur.title', undefined)}
                        />

                        <a
                          href={`http://maps.google.com/maps?q=${this.generateMap(
                            row.general_data.contact_info.address_de_jur.title,
                          )}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="desc"
                        >
                          {t({ id: 'company.showMap' })} &#8594;
                        </a>
                      </Info>
                    )}

                    <Info label={t({ id: 'other.contacts' })}>
                      <Info.More
                        icon={<Icon type="company-telephone" />}
                        data={has(row, 'general_data.contact_info.phones', [])}
                      />
                      <Info.More
                        icon={<Icon type="company-mobile" />}
                        data={has(row, 'general_data.contact_info.mobile', [])}
                      />
                      <Info.More
                        icon={<Icon type="company-fax" />}
                        data={has(row, 'general_data.contact_info.faxes', [])}
                      />
                    </Info>

                    <Info label={t({ id: 'other.email' })}>
                      <Info.More
                        icon={<Icon type="company-mail" />}
                        data={has(row, 'general_data.contact_info.emails', [])}
                        textBlue
                      />
                    </Info>

                    <Info label={t({ id: 'other.website' })}>
                      <Info.Detail
                        icon={<Icon type="company-website" />}
                        title={
                          has(row, 'general_data.contact_info.sites')
                            ? row.general_data.contact_info.sites.join(', ')
                            : undefined
                        }
                        websites={
                          has(row, 'general_data.contact_info.sites') ? row.general_data.contact_info.sites : []
                        }
                        textBlue
                      />
                    </Info>

                    <Info label={t({ id: 'company.employeeCount' })}>
                      <Info.Detail title={row.employees.length || '0'} />
                    </Info>
                  </Info.Halves>

                  <Link route="SubdivisionPage" params={{ id: row.id }} className="whole-information">
                    <span>
                      {t({ id: 'other.showAll' })} <Icon type="arrow-right" />
                    </span>
                  </Link>
                </Segment>
              ))}
            </React.Fragment>
          ) : null}
        </Loader>
      </CompanyLayout>
    );
  }
}

const enhance = compose(injectIntl, withCompany());

export default enhance(SubdivisionsPage);
