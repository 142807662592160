import React, { useEffect, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Button } from 'antd';
import { useRequest } from 'estafette';
import { useStateHandlers, useDocumentTitle } from '~/hooks';
import { Link } from '~/libs/router';
import { managementProduct } from '~/libs/http/api';
import { has } from '~/libs/object';
import { useInjectIntl } from '~/libs/localization';
import { number } from '~/libs/number';
import { Layout } from '~/components/layouts';
import { Loader, Status, Empty, Table } from '~/components/ui';
import { ManagementMenu } from '~/components/management';
import { TableHeader } from '~/components/table';
import NotFoundPage from '~/pages/NotFoundPage';

const AdminProductsPage = () => {
  const { request, data, loading } = useRequest({ data: { data: [] }, loading: true });
  const { t } = useInjectIntl();
  const [state, setState] = useStateHandlers({
    page: 1,
    hasMore: true,
  });

  useDocumentTitle(t('page.accounts'));

  const onFetch = useCallback(async () => {
    const { data: list } = await request(managementProduct.list({ page: state.page }), {
      concat: state.page > 1 && 'data',
    });

    setState({
      hasMore: state.page < Math.ceil(list.total_results / 25),
    });
  }, [state.page]);

  useEffect(() => {
    if (state.hasMore) {
      onFetch();
    }
  }, [state.page]);

  const onChangePage = async page => {
    window.scrollTo(0, window.scrollY - 25);

    setState({ page });
  };

  const classLoading = loading ? 'skeleton skeleton-text' : '';

  const columns = [
    {
      title: t('management.N'),
      className: 'td-key',
      render: (_, __, key) => <span className={classLoading}>{key + 1}</span>,
      width: 20,
    },
    {
      title: 'Title',
      render: (item, data2, key) => (
        <Link route="AdminUpdateProductPage" params={{ id: data2.id }} className={`title ${classLoading}`}>
          {data2.title || has(data2, 'languages.ro')}
        </Link>
      ),
      width: 170,
    },
    {
      title: t('metrics.price'),

      render: (item, data3, key) => <b className={classLoading}>{number(data3.price || 0)} MDL</b>,
      width: 90,
    },
    {
      title: t('metrics.frequency'),

      render: (item, data4, key) => <span className={classLoading}>{data4.frequency || <Empty />}</span>,
      width: 100,
    },
    {
      title: t('management.request_demo'),
      render: (item, data5, key) => (
        <Status
          classText={classLoading}
          status={data5.request_demo ? 'active' : 'inactive'}
          statusText={t('management.request_demo')}
        />
      ),
      width: 90,
    },
    {
      title: t('management.private'),
      render: (item, data6, key) => (
        <Status
          classText={classLoading}
          status={data6.status ? 'active' : 'inactive'}
          statusText={t('management.private')}
        />
      ),
      width: 90,
    },
  ];

  return (
    <Layout>
      <ManagementMenu />

      <Loader loading={loading && data.data.length === 0}>
        <TableHeader
          leftSide={
            <h1>
              {t('management.results')} {data.total_results ? `(${data.total_results || 0})` : null}
            </h1>
          }
          rightSide={
            <Link route="AdminAddProductPage">
              <Button>{t('other.addInList')}</Button>
            </Link>
          }
        />
        <InfiniteScroll initialLoad={false} pageStart={1} loadMore={onChangePage} hasMore={state.hasMore}>
          <Table className="table-layout-fixed" columns={columns} dataSource={data.data} />

          {!data.data.length && <NotFoundPage />}
        </InfiniteScroll>
      </Loader>
    </Layout>
  );
};

export default AdminProductsPage;
