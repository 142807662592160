import React from 'react';
import moment from '~/libs/moment';
import { Icon } from '~/components/ui/Icon/Icon';

const Time = ({ date, format, noParse, prefix, relative, loading }) => {
  const classLoading = loading ? 'skeleton skeleton-text' : '';

  return date ? (
    <span className="iconed-text">
      {prefix && <Icon type="clock" />}

      {relative ? (
        <span className={classLoading}>{moment(date).fromNow()}</span>
      ) : (
        <span className={classLoading}>{!noParse ? moment(date).format(formats[format] || formats.date) : date}</span>
      )}
    </span>
  ) : (
    '---'
  );
};
const formats = {
  long: 'l',
  year: 'YYYY',
  hour: 'HH:MM',
  month: 'MMMM',
  date: 'DD.MM.YYYY',
  visit: 'l, h:mm a',
  dateTime: 'DD.MM.YYYY HH:MM',
};

export default Time;
