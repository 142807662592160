import React, { useState, useEffect } from 'react';
import cookies from 'react-cookies';
import moment from 'moment';
import { notification, Modal, Button } from 'antd';
import { useRequest } from 'estafette';
import { useHistory } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import { useDocumentTitle } from '~/hooks';
import { dashboard } from '~/libs/http/api';
import { Link, getRoute } from '~/libs/router';
import { has } from '~/libs/object';
import { twentyMinutesinSeconds } from '~/libs/date';
import { useInjectIntl } from '~/libs/localization';
import { Segment } from '~/components/company';
import { Layout } from '~/components/layouts';
import { MonthActivity, LastActivity } from '~/components/dashboard';
import { ValidationModal } from '~/components/modal';
import { PersonCard, Counter, Rows, Loader } from '~/components/ui';

import '~/assets/scss/components/_Dashboard.scss';

const DashboardPage = () => {
  const history = useHistory();
  const { t } = useInjectIntl();

  useDocumentTitle(t('page.home'));

  const { request: requestActivity, data: activity, loading: loadingActivity } = useRequest({ data: { data: [] } });
  const { request: requestInformation, data: info, loading: loadingInformation } = useRequest();
  const { request: requestNomenclatures, data: nomenclatures } = useRequest();
  const { request: requestChangePackage, loading: loadingPackage } = useRequest();

  const [openPermissionModal, showPermissionModal] = useState(false);
  const [openAprrovedModal, showApprovedModal] = useState(false);
  const [openChangePackage, setOpenChangePackage] = useState(false);

  const openNotificationSubscription = date => {
    notification.open({
      className: 'end-subscription',
      message: t('other.end-subscription'),
      description: `${t('other.end-subscription-text')} ${date}`,
    });
  };

  const classLoading = loadingInformation ? 'skeleton skeleton-text' : '';

  const { product, permissions, permissionsLoading, params, counts = {}, manager, ...store } = useSelector(
    ({ filter, authentication }) => ({
      counts: filter.nomenclatures.company_statistics,
      manager: authentication.params.manager,
      permissions: authentication.permissions,
      permissionsLoading: authentication.loading,
      params: authentication.params,
      authLoading: authentication.loading,
      product: authentication.product,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (product.warning_product_subscription && product.next_requiring) {
      const date = moment(product.next_requiring).format('DD.MM.YYYY');

      openNotificationSubscription(date);
    }
    setOpenChangePackage(product.product_term_expired);
  }, [product.warning_product_subscription]);

  useEffect(() => {
    setOpenChangePackage(product.product_term_expired);
  }, [product.product_term_expired]);

  useEffect(() => {
    const onCheckPermissions = () => {
      if (!permissionsLoading && permissions.length === 0 && !cookies.load('hide_subscription_info')) {
        showPermissionModal(true);
      }
    };

    onCheckPermissions();
  }, [permissions, permissionsLoading]);

  useEffect(() => {
    const onCheckPermissions = () => {
      if (!permissionsLoading && !params.is_approved && !cookies.load('hide_approved_info')) {
        showApprovedModal(true);
      }
    };

    onCheckPermissions();
  }, [params, permissionsLoading]);

  useEffect(() => {
    requestActivity(
      dashboard.list({
        page: 1,
        per_page: 5,
        type: '',
      }),
    );
    requestInformation(dashboard.info());
    requestNomenclatures(dashboard.nomenclatures());
  }, []);

  const onClosePermissionModal = () => {
    cookies.save('hide_subscription_info', true, { path: '/', maxAge: twentyMinutesinSeconds });
    showPermissionModal(false);
  };

  const onViewSubscriptions = () => {
    cookies.save('hide_subscription_info', true, { path: '/', maxAge: twentyMinutesinSeconds });
    history.push(getRoute('MetricsPage'));
  };

  const onCloseApprovedModal = () => {
    cookies.save('hide_approved_info', true, { path: '/', maxAge: twentyMinutesinSeconds });
    showApprovedModal(false);
  };

  const onCloseChangePackage = () => {
    setOpenChangePackage(false);
    requestChangePackage(dashboard.changePackage());
  };

  return (
    <>
      {openChangePackage && (
        <Modal
          style={{ top: 150 }}
          title="Абонамент закончился!"
          visible={openChangePackage}
          footer={[
            <Button key="submit" type="primary" onClick={() => onCloseChangePackage()} loading={loadingPackage}>
              {t('home.modal-package-submit')}
            </Button>,
          ]}
          closable={false}
        >
          <span>{t('home.modal-package-text')}</span>
        </Modal>
      )}

      {openPermissionModal && (
        <ValidationModal
          type="error"
          message={
            <div>
              <div className="mb-10 validation-title">{t('non-abonament')}</div>
              <div>{t('home.non-abonament-text')}</div>
            </div>
          }
          buttonText="Vizualizare abonamente"
          onClose={onClosePermissionModal}
          onClick={onViewSubscriptions}
        />
      )}

      {openAprrovedModal && (
        <ValidationModal
          type="error"
          message={
            <div>
              <div className="mb-10 validation-title">{t('home.approve-abonament')}</div>
              <div>{t('home.approve-abonament-text')}</div>
            </div>
          }
          buttonText={t('home.close')}
          onClose={onCloseApprovedModal}
        />
      )}

      <Layout containerClass="dashboard-container">
        <h2 className="dashboard-header">{t('home.header')}</h2>

        <Rows
          loading={loadingInformation || Object.keys(counts).length === 0}
          rows={[
            {
              label: t('home.companyTotal'),
              value: has(counts, 'all_time.all'),
            },
            {
              label: t('home.companyActive'),
              value: has(counts, 'all_time.status_active'),
            },
            {
              label: t('home.companyLiquidated'),
              value: has(counts, 'all_time.status_removed'),
            },
          ]}
        />

        <Rows
          className="second"
          loading={loadingInformation || Object.keys(counts).length === 0}
          rows={[
            {
              label: t('home.companyUpdates'),
              value: has(nomenclatures, 'company_statistics.last_month.checked'),
            },
            {
              label: t('home.companyLiquidatedLastMoth'),
              value: has(nomenclatures, 'company_statistics.last_month.removed') || (
                <span className="grey">{t('other.nothing')}</span>
              ),
            },
            {
              label: t('home.companyAddedLastMoth'),
              value: has(nomenclatures, 'company_statistics.last_month.added') || (
                <span className="grey">{t('other.nothing')}</span>
              ),
            },
          ]}
        />

        <Segment.Halves>
          <MonthActivity info={info} loading={loadingInformation} />

          <div style={{ width: '100%' }}>
            <Segment
              title={t('home.info_license')}
              titleDesc={
                <span className="text-blue">
                  {t('home.validUntil')}{' '}
                  {moment()
                    .add(1, 'months')
                    .date(0)
                    .format('DD.MM.YYYY')}
                </span>
              }
              noPadding
            >
              <div className="dashboard-cards">
                <div className="dashboard-card">
                  <small>{t('home.companyInformation')}</small>
                  <span className={classLoading}>
                    {loadingInformation ? (
                      '1000000'
                    ) : typeof info.company_count === 'number' ? (
                      <Counter total={info.company_count} />
                    ) : (
                      'N/A'
                    )}
                  </span>
                </div>

                <div className="dashboard-card">
                  <small>{t('home.companyContacts')}</small>
                  <span className={classLoading}>
                    {loadingInformation ? (
                      '1000000'
                    ) : typeof info.company_contacts_all === 'number' ? (
                      <Counter total={info.company_contacts_all} />
                    ) : (
                      'N/A'
                    )}
                  </span>
                </div>

                <div className="dashboard-card">
                  <small>{t('home.companyShareHolder')}</small>
                  <span className={classLoading}>
                    {loadingInformation ? (
                      '1000000'
                    ) : typeof info.employee_partner === 'number' ? (
                      <Counter total={info.employee_partner} />
                    ) : (
                      'N/A'
                    )}
                  </span>
                </div>

                <div className="dashboard-card">
                  <small>{t('home.financialSummary')}</small>
                  <span className={classLoading}>
                    {loadingInformation ? (
                      '1000000'
                    ) : typeof info.company_financial === 'number' ? (
                      <Counter total={info.company_financial} />
                    ) : (
                      'N/A'
                    )}
                  </span>
                </div>
              </div>
            </Segment>

            {manager && Object.keys(manager).length > 0 && (
              <Loader loading={store.authLoading} height={285}>
                <Segment
                  title={
                    <span className="support-header">
                      <span>{t('manager.account')}</span>

                      <span className="support-redirect">
                        <Link route="HelpPage">{t('home.support_redirect')}</Link>
                      </span>
                    </span>
                  }
                  footerDesc={<div className="dashboard-manager-footer">{t('manager.support')}</div>}
                >
                  <PersonCard
                    className="dashboard-manager"
                    title={`${manager.first_name || ''} ${manager.last_name || ''}`.replace('  ', '')}
                    desc={manager.job_title || 'Manager'}
                    photo={manager.photo}
                  />

                  <div className="dashboard-manager-contacts">
                    {manager.email && (
                      <div className="dashboard-manager-contact">
                        <small>{t('other.email')}:</small>
                        {/* <Link route="HelpPage">{manager.email}</Link> */}
                        <span className="manager-email">{manager.email}</span>
                      </div>
                    )}

                    <div className="dashboard-manager-contact">
                      <small>{t('other.telephone')}:</small>
                      <span>{manager.phone || '---'}</span>
                    </div>
                  </div>
                </Segment>
              </Loader>
            )}
          </div>
        </Segment.Halves>

        <LastActivity loading={loadingActivity} data={activity.data} />
      </Layout>
    </>
  );
};

export default DashboardPage;
