export const saveBlob = async (blobData, fileName) => {
  const file = new Blob([blobData], { type: 'application/pdf' });
  const downloadUrl = window.URL.createObjectURL(file);
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};
