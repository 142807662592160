import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Tooltip, Alert, Form, Select, Button } from 'antd';
import { useRequest } from 'estafette';
import { connect } from 'react-redux';
import { useStateHandlers, useDocumentTitle } from '~/hooks';
import { notification } from '~/libs/notification';
import { useInjectIntl } from '~/libs/localization';
import { Link } from '~/libs/router';
import { managementProduct, managementAccounts } from '~/libs/http/api';
import { PermissionBlock, Permission, ManagementMenu } from '~/components/management';
import { Loader } from '~/components/ui';
import { Layout } from '~/components/layouts';
import { Icon } from '~/components/ui/Icon/Icon';

export const addOrRemovePermission = (perms, perm) =>
  perms.find(item => item.id === perm.id) ? perms.filter(item => item.id !== perm.id) : [...perms, { ...perm }];

export const findPermissionFromState = (perms, permId) => {
  perms.filter(item => permId === item.id);
  const x = perms.filter(item => permId === item.id).map(item => item.active);
  return x[0];
};

const AdminAccountProductPage = ({ currentLanguage }) => {
  const { t } = useInjectIntl();
  const { id } = useParams();
  const { request, data: dataCurrentPermission, loading } = useRequest();
  const { request: requestSubmit, errors, loading: loadingSubmit } = useRequest();
  const { request: requestTemplates, data: dataTemplates, loading: loadingTemplates } = useRequest({
    data: { data: [] },
  });
  const { request: requestProduct, loading: loadingProduct } = useRequest();

  useDocumentTitle(t('page.account-product'));

  const [state, setState] = useStateHandlers({
    template: undefined,
    templates: [],
    permissions: [],
    changed: false,
  });

  const [companyId, setCompanyId] = useState(undefined);
  const [productId, setProductId] = useState(undefined);

  useEffect(() => {
    requestTemplates(managementProduct.list({ page: 1, per_page: 50 }));
  }, []);

  useEffect(() => {
    onFetch();
    setCompanyId(id);
  }, [id]);

  useEffect(() => {
    if (dataCurrentPermission) {
      setState({
        permissions: dataCurrentPermission
          .reduce(
            (acc, current) =>
              !acc.map(i => i.id).includes(current.permission_type.id) ? [...acc, current.permission_type] : acc,
            [],
          )
          .map(item => ({
            ...item,
            data: dataCurrentPermission.filter(i => i.permission_type.id === item.id),
          })),
      });
    }
  }, [dataCurrentPermission]);

  const onFetch = () => request(managementAccounts.product({ id }));

  const onSubmit = async ev => {
    ev.preventDefault();

    await requestSubmit(
      managementAccounts.editProduct(
        state.permissions.reduce(
          (acc, { data, ...permissionType }) => [
            ...acc,
            ...data.map(i => ({
              ...i,
              permission_type: permissionType,
              daily_limit: i.daily_limit >= 0 ? i.daily_limit : null,
              limit: i.limit >= 0 ? i.limit : null,
              vas: i.vas >= 0 ? i.vas : null,
            })),
          ],
          [],
        ),
        companyId,
        productId,
      ),
    );

    notification({
      title: t('page.account-product'),
      message: t(`notification.product.message.update`),
    });

    setState({ changed: false, template: undefined });
  };

  const onReset = () => {
    setState({ changed: true, template: undefined });
    onFetch();
  };

  const onChangeTemplate = async template => {
    setProductId(template);

    const templateName = dataTemplates.data.filter(item => item.id === template);

    const data = await requestProduct(managementAccounts.product({ product_id: template }));

    setState({
      changed: true,
      template: templateName[0].codename,
      permissions: data
        .reduce(
          (acc, current) =>
            !acc.map(i => i.id).includes(current.permission_type.id) ? [...acc, current.permission_type] : acc,
          [],
        )
        .map(item => ({
          ...item,
          data: data.filter(i => i.permission_type.id === item.id),
        })),
    });
  };

  const onChangePermission = React.useCallback(({ group, permission, type, value }) => {
    setState(prevState => ({
      changed: true,
      permissions: JSON.parse(
        JSON.stringify(
          prevState.permissions.map(g =>
            g.id === group ? { ...g, data: g.data.map(i => (i.id === permission ? { ...i, [type]: value } : i)) } : g,
          ),
        ),
      ),
    }));
  }, []);

  const onTogglePermission = React.useCallback(({ group, permission }) => {
    setState(prevState => ({
      changed: true,
      permissions: JSON.parse(
        JSON.stringify(
          prevState.permissions.map(g => {
            if (g.id !== group) {
              return g;
            }

            const data = g.data.map(i => (i.id === permission ? { ...i, active: !i.active } : i));
            return { ...g, active: data.filter(i => i.active).length > 0, data };
          }),
        ),
      ),
    }));
  }, []);

  const onToggleGroup = React.useCallback(group => {
    setState(prevState => ({
      changed: true,
      permissions: JSON.parse(
        JSON.stringify(
          prevState.permissions.map(g =>
            g.id === group ? { ...g, data: g.data.map(i => ({ ...i, active: !g.active })), active: !g.active } : g,
          ),
        ),
      ),
    }));
  }, []);

  return (
    <Layout>
      <ManagementMenu />

      <ManagementMenu provider="account" />

      <Loader loading={state.permissions.length === 0 && loading}>
        <Form onSubmit={onSubmit} className="mn-product-form">
          <div className="half-form pure">
            <div className="title">{t('management.update_product')}</div>

            <Form.Half>
              <Form.Item label={t('management.template')}>
                <Select
                  allowClear
                  placeholder={t('management.select_template_subscription')}
                  dropdownClassName="responsive-table-dropdown"
                  value={loadingTemplates ? 'Loading ...' : state.template}
                  disabled={loadingTemplates}
                  onChange={onChangeTemplate}
                >
                  {dataTemplates.data.map(template => (
                    <Select.Option value={template.id} key={template.id}>
                      {/* eslint-disable-next-line i18n-text/no-en */}
                      {template.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Form.Half>
          </div>

          <div className="half-form pure">
            <div className="title mn-permissions-title">
              {t('management.permissions')}

              <div className="actions">
                <Tooltip
                  title={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t('management.permissions.guide'),
                      }}
                    />
                  }
                >
                  {' '}
                  <Icon type="question-circle" />
                </Tooltip>

                {state.changed && (
                  <span onClick={onReset} className={loading ? 'loading' : ''}>
                    Reset to default
                  </span>
                )}
              </div>
            </div>
          </div>

          {errors.data && (
            <Alert className="mn-permissions-alert" type="warning" message={t('management.permissions.error')} />
          )}

          <Loader loading={(loadingProduct && state.permissions.length > 0) || loading} height={307.5}>
            <PermissionBlock>
              <div className="permission-block">
                {state.permissions.map(item => {
                  return (
                    <div key={item.id} className="permission-container">
                      <div
                        className={`all-category-permission ${item.active ? 'active-category' : ''}`}
                        onClick={() => onToggleGroup(item.id)}
                      >
                        <span>{item.title}</span>
                        <Icon type="bulb" />
                      </div>

                      {!loading &&
                        item.data.map(permission => (
                          <Permission
                            key={permission.id}
                            type="account"
                            defaultValue={permission.switch_on}
                            switchValue={state.permissions}
                            id={permission.id}
                            loading={loading}
                            title={permission.title}
                            limitPeriod={permission.limit_period}
                            dailyLimit={permission.daily_limit}
                            limit={permission.limit}
                            vas={permission.vas}
                            active={permission.active}
                            onChange={(type, value) =>
                              onChangePermission({ group: item.id, permission: permission.id, type, value })
                            }
                            onClick={() => onTogglePermission({ group: item.id, permission: permission.id })}
                          />
                        ))}
                    </div>
                  );
                })}
              </div>
            </PermissionBlock>
          </Loader>

          <div className="mn-product-footer">
            <Link route="AdminUsersPage" params={{ id }}>
              <Button>{t('billing.back')}</Button>
            </Link>

            <Button type="primary" htmlType="submit" loading={loadingSubmit} disabled={!state.changed}>
              {t('other.submit')}
            </Button>
          </div>
        </Form>
      </Loader>
    </Layout>
  );
};

export default connect(({ localization }) => ({ currentLanguage: localization.current.shortName }))(
  AdminAccountProductPage,
);
