import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useRequest } from 'estafette';
import { useRouteMatch, useHistory } from 'react-router-dom';
import moment from 'moment';
import { monitoring, company as employApi } from '~/libs/http/api';
// import { setCompaniesPerPage } from '~/store/actions/SearchCompaniesActions';
import { Radio, message } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import { getRoute, Link } from '~/libs/router';
import { useInjectIntl } from '~/libs/localization';
import { Icon } from '~/components/ui/Icon/Icon';
import { TableFooter } from '~/components/table';
import { Button, Pagination, HiddenContact, Modal, Loader, ProspectList } from '~/components/ui';
import { Filter } from '~/components/svg';

import '~/assets/scss/components/_SearchResults.scss';
import '~/assets/scss/components/_Monitoring.scss';

const SearchResults = () => {
  const dispatch = useDispatch();
  const { t } = useInjectIntl();
  const match = useRouteMatch();
  const history = useHistory();
  const store = useSelector(
    ({ companies, searches, localization }) => ({
      companies,
      searches,
      localization,
    }),
    shallowEqual,
  );

  const { request: requestMonitoringList, data: dataMonitoringList } = useRequest({
    data: { data: [] },
  });
  const { request: requestAddMonitoringList } = useRequest({
    data: { data: [] },
  });

  const [companies, setCompanies] = useState(null);
  const [addMonitoringList, setAddMonitoringList] = useState(false);
  const [listMonitoring, setListMonitoring] = useState(null);
  const [activeCompanyId, setActiveCompanyId] = useState(null);

  // const [isActiveCountryPopup] = useState(false);
  // const [country] = useState('md');

  // const onChangeCountry = visible => {
  //   console.log(visible);
  // };

  // const onSetPerPage = perPage => dispatch(setCompaniesPerPage(perPage));

  const onChangePage = page => history.push(getRoute('Search', { page }));

  const getAge = creationYear => {
    const year = new Date().getFullYear();
    return year - creationYear;
  };

  const getCreationDate = date => {
    const d = new Date(date);
    const fullDate = new Intl.DateTimeFormat(store.localization.current.shortName, {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    }).format(d);
    return fullDate;
  };

  const Partners = ({ company }) => {
    if (company.partners.length > 0) {
      return (
        <>
          <span className="desc">{company.partners.slice(0, 2).join(', ')}</span>
          {company.partners.length - 2 > 0 && (
            <>
              <span> & </span>
              <Link route="PersonalPage" params={{ id: company.id }}>
                {company.partners.length - 2} {t('search.others')}
              </Link>
            </>
          )}
        </>
      );
    }

    return null;
  };

  const onFetchMonitoringList = async () => {
    try {
      if (store.searches.search_type) {
        await requestMonitoringList(
          monitoring.getList({
            page: 1,
            per_page: 2,
          }),
        );
      } else {
        await requestMonitoringList(
          employApi.employees({
            page: 1,
            per_page: 2,
          }),
        );
      }
    } catch {
      message.error(t('other.error_module'));
    }
  };

  const updateList = async () => {
    await onFetchMonitoringList();

    if (companies?.data.length) {
      const newCompanies = companies.data.map(array => {
        if (array.id === activeCompanyId) {
          array.monitoring = true;
        }
        return array;
      });

      if (store.searches.search_type) {
        newCompanies &&
          dispatch({
            type: 'GET_SEARCH_LIST_EMPLOYEE',
            payload: {
              data: { ...companies, data: newCompanies },
            },
          });
      } else {
        newCompanies &&
          dispatch({
            type: 'GET_SEARCH_LIST',
            payload: {
              data: { ...companies, data: newCompanies },
            },
          });
      }
    }
  };

  const onSubmit = async () => {
    const activeArr = dataMonitoringList.filter(arr => arr.id === listMonitoring);

    try {
      const options = {
        list_id: listMonitoring,
        companies_ids: [activeCompanyId],
        name: activeArr[0].name,
      };

      await requestAddMonitoringList(monitoring.addList(options));

      updateList();

      message.success(t('other.success.added'));

      onClose();
    } catch ({ response }) {
      message.error(t('other.error_module'));
    }
  };

  const onClose = () => setAddMonitoringList(false);

  const onFadeModalList = companyId => {
    setActiveCompanyId(companyId);
    setAddMonitoringList(true);
  };

  useEffect(() => {
    onFetchMonitoringList();
  }, []);

  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' });
    if (store.companies && store.companies?.data) {
      setCompanies(store.companies.data);
    }
  }, [store.companies]);

  if (!store.companies || store.companies?.loading) {
    return <Loader loading />;
  }

  if (store.companies.data?.total_results === 0) {
    return <h2>{t('other.noResults')}</h2>;
  }

  if (!store?.companies?.data?.data) {
    return null;
  }

  return (
    <>
      {addMonitoringList && (
        <Modal
          className="add-monitoring-modal"
          clean
          title={t('list.addMonitoringList')}
          onClose={onClose}
          footer={
            <>
              {listMonitoring ? (
                <Button fade type="primary" onClick={onSubmit} style={{ display: listMonitoring ? 'block' : 'none' }}>
                  {t('list.add_to_list')}
                </Button>
              ) : (
                <p>{t('list.select_list')}</p>
              )}
            </>
          }
        >
          {/* {!isSearch && <Input placeholder={t('filter.searchTheList')} onChange={onSearch} />} */}

          <Loader loading={false}>
            <div className="add-company-list">
              <InfiniteScroll initialLoad={false} pageStart={1} useWindow={false}>
                <Radio.Group onChange={e => setListMonitoring(e.target.value)}>
                  {dataMonitoringList?.length > 0
                    ? dataMonitoringList.map(list => (
                        <Radio key={list.id} value={list.id}>
                          <span className="radio-flex">
                            <Filter.Circle className={list.color || 'blue'}>
                              <Icon type="filter-list" />
                            </Filter.Circle>

                            <span className="radio-flex-content">
                              <h2>
                                <span>{list.name}</span>
                              </h2>

                              <small>
                                {list.company_counter || 0} {t('list.company')}
                                <span className="span-separator">•</span>
                                {t('list.created1')} {moment(list.date_created).format('YYYY.MM.DD')}
                              </small>
                            </span>
                          </span>
                        </Radio>
                      ))
                    : t('list.noLists')}
                </Radio.Group>
              </InfiniteScroll>
            </div>
          </Loader>
        </Modal>
      )}

      <div className="search-start search-results">
        {!store.searches.search_type ? (
          store.companies.data.data.map(company => (
            <div className="company-info" key={company.id}>
              <div className="company-header">
                <div className="left">
                  <div className="wrap-icon">
                    <Icon type="buildings" />
                  </div>
                </div>

                <div className="right">
                  <div className="title">
                    <Link route="GeneralsPage" params={{ id: company.id }}>
                      {company.name}
                    </Link>

                    {company.monitoring ? (
                      <Button className="monitoring" disabled>
                        <Icon type="menu-check" />
                        {t('search.alreadyMonitoring')}
                      </Button>
                    ) : (
                      <Button className="monitoring" onClick={() => onFadeModalList(company.id)}>
                        <Icon type="monitoring" />
                        {t('search.monitoring')}
                      </Button>
                    )}
                  </div>

                  {company.location && (
                    <div className="address">
                      <Icon type="location" />
                      {t('search.address')}: {company.location}
                    </div>
                  )}
                </div>
              </div>

              <div className="info">
                <div className="column">
                  <div className="item">
                    <div className="sub-title">{t('search.registrationNumber')}:</div>
                    <div className="desc">{company.idno ? company.idno : '---'}</div>
                  </div>

                  <div className="item">
                    <div className="sub-title">{t('search.status')}:</div>
                    <div className="desc">
                      {company.status ? (
                        <span className={`status ${company.status.toLowerCase()}`}>
                          {t(`search.${company.status.toLowerCase()}`)}
                        </span>
                      ) : (
                        <span>---</span>
                      )}
                    </div>
                  </div>

                  <div className="item">
                    <div className="sub-title">{t('search.dateOfEstablishment')}:</div>
                    <div className="desc">{company.creation_date ? getCreationDate(company.creation_date) : '---'}</div>
                  </div>

                  <div className="item">
                    <div className="sub-title">{t('search.age')}:</div>
                    <div className="desc">{company.creation_year ? getAge(company.creation_year) : '---'}</div>
                  </div>
                </div>

                <div className="column">
                  <div className="item">
                    <div className="sub-title">{t('search.nrByTheEmployees')}:</div>
                    <div className="desc">{company?.employees ? company.employees : '---'}</div>
                  </div>

                  <div className="item">
                    <div className="sub-title">{t('search.turnover')}:</div>
                    <div className="desc">
                      {company.turnover
                        ? `${company.turnover.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} MDL`
                        : '---'}
                    </div>
                  </div>

                  <div className="item">
                    <div className="sub-title">{t('search.industry')}:</div>
                    <div className="desc">{company.industry ? company.industry : '---'}</div>
                  </div>
                </div>

                <div className="column">
                  <div className="item">
                    <div className="sub-title">{t('search.contacts')}:</div>
                    {company.phone || company.email || company.website ? (
                      <div className="desc contacts">
                        {company.phone && company.phone.length && (
                          <div className="p">
                            <HiddenContact value={company.phone} type="phone" icon={<Icon type="search-phone" />} />
                          </div>
                        )}

                        {company.email && company.email.length && (
                          <div className="p">
                            <HiddenContact value={company.email} type="email" icon={<Icon type="search-mail" />} />
                          </div>
                        )}

                        {company.website && company.website.length && (
                          <div className="p">
                            <HiddenContact
                              value={company.website}
                              type="website"
                              icon={<Icon type="search-website" />}
                            />
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="desc">---</div>
                    )}
                  </div>
                </div>
              </div>

              <div className="company-footer">
                <span className="sub-title">{t('search.foundersAndDelegates')}:</span>
                {company.partners && company.partners.length > 0 ? (
                  <Partners company={company} />
                ) : (
                  <span className="desc">---</span>
                )}
              </div>
            </div>
          ))
        ) : (
          <ProspectList
            loading={store.companies.data.loading}
            // title={
            //   <>
            //     <Icons type={type} />
            //     {number(prospect.total)} {t('prospectListPage.of_results')}
            //   </>
            // }
            // provider={type === 'companies' || type === 'employees' ? type : undefined}
            provider="employees"
            // pagination={pagination}
            // rows={[4]}
            // onSelectRows={onSelectRows}
            // actions={
            //   <Button type="primary" icon={<DownloadOutlined />} onClick={onExportData}>
            //     Descarcă {prospect.rows.length ? `(${prospect.rows.length})` : ''}
            //   </Button>
            // }
            dataSource={store.companies.data.data}
          />
        )}

        <TableFooter
          rightSide={
            <Pagination
              onClick={onChangePage}
              page={match.params.page}
              total={companies?.pages}
              route="Search"
              params={{ query: match.params.query }}
            />
          }
          // rightSide={
          //   <div className="per-page">
          //     {store.companies.perPage} pe 1 pagă
          //     <Select defaultValue={store.companies.perPage} onChange={onSetPerPage}>
          //       <Select.Option value="5">5</Select.Option>
          //       <Select.Option value="10">10</Select.Option>
          //       <Select.Option value="15">15</Select.Option>
          //       <Select.Option value="30">30</Select.Option>
          //       <Select.Option value="50">50</Select.Option>
          //     </Select>
          //   </div>
          // }
        />
      </div>
    </>
  );
};

export default SearchResults;
