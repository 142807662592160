import { adapterIntl } from '~/libs/localization/helpers';

const bluredData = adapterIntl({
  'bluredData.info_only_subscribed': {
    ro: 'Aceste informații sunt disponibile numai pentru utilizatorii abonați',
    ru: 'Эта информация доступна только для абонентов',
    en: 'This information is only available to subscribed users',
  },
  'bluredData.view_products': {
    ro: 'Vizualizează produsele',
    ru: 'Просмотр продуктов',
    en: 'View products',
  },
});

export default bluredData;
