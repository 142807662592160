import { adapterIntl } from '~/libs/localization/helpers';

const list = adapterIntl({
  'search.by.list': {
    ro: 'Căutare după lista salvată',
    ru: 'Пойск по сохраненному списку',
    en: 'Search by saved list',
  },
  'filter.subdivisionSearch': {
    ro: 'Căutați în subdiviziuni',
    ru: 'Искать в Подразделениях',
    en: 'Search in subdivisions',
  },
  'filter.result': { ro: 'Au fost găsite {total} mărfuri', ru: 'Найдено {total} товаров', en: '{total} goods found' },
  'filter.recents': { ro: 'Văzute recent', ru: 'Недавние просмотры', en: 'Recent views' },
  'filter.startSearch': { ro: 'Începeți căutarea', ru: 'Начать поиск', en: 'Start search' },
  'filter.showAll': { ro: 'Arată toate', ru: 'Показать все', en: 'Show all' },
  'filter.from': { ro: 'De la', ru: 'От', en: 'From' },
  'filter.to': { ro: 'Până la', ru: 'До', en: 'To' },
  'filter.fromTo': { ro: 'De la {from} până la {to}', ru: 'От {from} до {to}', en: 'From {from} to {to}' },
  'filter.emptySearch': {
    ro: 'Nu există nici o companie',
    ru: 'Не найдено ни одной компании',
    en: 'Company is not found',
  },
  'filter.search': { ro: 'Căutare', ru: 'Поиск', en: 'Search' },
  'filter.searchResult': {
    ro: 'Arată toate {value} rezultatele',
    ru: 'Показать все {value} результаты',
    en: 'Show all {value} results',
  },
  'filter.title.generalData': { ro: 'Date Generale', ru: 'Общие данные', en: 'General data' },
  'filter.title.staff': { ro: 'Personalul', ru: 'Сотрудники', en: 'Staff' },
  'filter.title.economyData': { ro: 'Date Economice', ru: 'Экономические данные', en: 'Economic data' },
  'filter.title.activityEconomy': {
    ro: 'Produse și servicii',
    ru: 'Продукты и услуги',
    en: 'Products and services',
  },
  'filter.title.publication': { ro: 'Publicații', ru: 'Публикации', en: 'Publications' },
  'filter.title.juridical': { ro: 'Date Juridice', ru: 'Юридические данные', en: 'Juridical data' },
  'filter.child.name': { ro: 'Compania/IDNO', ru: 'Компания/IDNO', en: 'Company/IDNO' },
  'filter.child.mail': { ro: 'Email', ru: 'Email', en: 'Email' },
  'filter.child.idno': { ro: 'Codul Fiscal - IDNO', ru: 'Фискальный код - IDNO', en: 'Fiscal code - IDNO' },
  'filter.child.regDate': { ro: 'Data înregistrării', ru: 'Дата регистрации', en: 'Registration date' },
  'filter.child.formJuridical': {
    ro: 'Forma juridică de organizare',
    ru: 'Организационно-правовая форма',
    en: 'Juridical form',
  },
  'filter.child.hq': { ro: 'Adresa', ru: 'Адрес', en: 'Address' },
  'filter.child.contactData': { ro: 'Date de contact', ru: 'Контактные данные', en: 'Contact data' },
  'filter.child.companyActivity': {
    ro: 'Statutul activitații companiei',
    ru: 'Статус активности предприятия',
    en: 'Status of company activity',
  },
  'filter.child.subdivisions': {
    ro: 'Subdiviziunile companiei',
    ru: 'Подразделения компании',
    en: 'Subdivisions of company',
  },
  'filter.child.specialDescription': {
    ro: 'Tipul companiei',
    ru: 'Тип предприятия',
    en: 'Company type',
  },
  'filter.child.subdivisionsType': { ro: 'Tipul subdiviziunilor', ru: 'Тип подразделения', en: 'Subdivisions type' },
  'filter.child.subdivisionsLocation': {
    ro: 'Locația subdiviziunilor',
    ru: 'Месторасположение подразделений',
    en: 'Location of subdivisions',
  },
  'filter.child.level': { ro: 'Nivelul de conducere', ru: 'Уровень управления', en: 'Management level' },
  'filter.child.function': {
    ro: 'Funcția și competențele',
    ru: 'Должность и полномочия',
    en: 'Position and competencies',
  },
  'filter.child.name2': { ro: 'Angajat', ru: 'Сотрудник', en: 'Employee' },
  'filter.child.shareSize': { ro: 'Mărimea cotei sociale', ru: 'Размер уставного доли', en: 'Social quota size' },
  'filter.child.capitalSize': {
    ro: 'Mărimea Capitalului Social',
    ru: 'Размер уставного капитала',
    en: 'Social capital size',
  },
  'filter.child.capitalType': {
    ro: 'Tipul capitalului social',
    ru: 'Тип социального капитала',
    en: 'Social capital type',
  },
  'filter.child.country': {
    ro: 'Țara de origine a investițiilor',
    ru: 'Источник инвестиций',
    en: 'Origin of investment',
  },
  'filter.child.turnover': { ro: 'Cifra de Afaceri (MDL)', ru: 'Оборот (MDL)', en: 'Turnover (MDL)' },
  'filter.child.employeeCount': {
    ro: 'Numărul persoanelor angajate',
    ru: 'Количество сотрудников',
    en: 'Number of employees',
  },
  'filter.child.intelectual': {
    ro: 'Obiectele proprietații intelectuale',
    ru: 'Объекты интеллектуальной собственности',
    en: 'Intellectual property objects',
  },
  'filter.child.brands': { ro: 'Mărci utilizate', ru: 'Используемые бренды', en: 'Used brands' },
  'filter.child.payers': { ro: 'Plătitor', ru: 'Плательщики', en: 'Payers' },
  'filter.child.serviceBank': { ro: 'Banca de deservire', ru: 'Обслуживающие банки', en: 'Bank service' },
  'filter.child.productsServices': {
    ro: 'Clasificator de Activități',
    ru: 'Классификатор деятельности',
    en: 'Activity Classification',
  },
  'filter.child.productsServices2': {
    ro: 'Produse și servicii',
    ru: 'Товары и услуги',
    en: 'Products and services',
  },
  'filter.child.import': { ro: 'Țări import', ru: 'Страны импорта', en: 'Import countries' },
  'filter.child.export': { ro: 'Țări export', ru: 'Страны экспорта', en: 'Export countries' },
  'filter.child.publicationType': { ro: 'Tipul publicațiilor', ru: 'Тип публикаций', en: 'Publication type' },
  'filter.child.publicationPeriod': { ro: 'Data de publicare', ru: 'Дата публикаций', en: 'Publication date' },
  'filter.child.parts': {
    ro: 'Participant al procesului judiciar',
    ru: 'Участник судебного процесса',
    en: 'Participant of juridical process',
  },
  'filter.child.partsType': {
    ro: 'Tipul procesului judiciar',
    ru: 'Тип судебного процесса',
    en: 'Type of juridical process',
  },
  'filter.placeholder.searchCompany': { ro: 'Caută compania', ru: 'Поиск компании', en: 'Search company' },
  'filter.placeholder.from': { ro: 'De la', ru: 'От', en: 'From' },
  'filter.placeholder.to': { ro: 'Până la', ru: 'До', en: 'To' },
  'filter.placeholder.name': {
    ro: 'Introduceți numele/prenumele angajatorului',
    ru: 'Поиск сотрудника',
    en: "Enter employee's name",
  },
  'filter.placeholder.year': { ro: 'An', ru: 'Год', en: 'Year' },
  'filter.placeholder.size': { ro: 'Introduceți mărimea', ru: 'Введите размер', en: 'Enter size' },
  'filter.placeholder.trademarks': { ro: 'Caută marca', ru: 'Поиск товарного знака', en: 'Search trademark' },
  'filter.title.tva': { ro: 'TVA', ru: 'НДС', en: 'VAT' },
  'filter.title.excise': { ro: 'Accize', ru: 'Акцизы', en: 'Excise' },
  'filter.searchByList': { ro: 'Căutare după listă', ru: 'Поиск по списку', en: 'Search by list' },
  'filter.button.treePopupMore': { ro: 'Vezi mai multe', ru: 'Показать доступные', en: 'View more' },
  'filter.placeholder.removeAll': { ro: 'Șterge toate', ru: 'Убрать все', en: 'Remove all' },
  'filter.placeholder.show': { ro: 'Arată toate', ru: 'Просмотреть все', en: 'Show all' },
  'filter.placeholder.hide': { ro: 'Ascunde', ru: 'Спрятать', en: 'Hide' },
  'filter.companies': { ro: 'companii', ru: 'компаний', en: 'companies' },
  'filter.employees': { ro: 'angajați', ru: 'сотрудников', en: 'executives' },
  'filter.subdivisions': { ro: 'subdiviziuni', ru: 'подразделений', en: 'subdivisions' },
  'filter.job.extra': {
    ro:
      'Identificați tipul exact de titlu al postului pe care doriți să îl vizați, de exemplu: CFO, CTO, securitate cibernetică',
    ru:
      'Укажите точный тип должности, на которую вы хотите ориентироваться, например: финансовый директор, технический директор, кибербезопасность',
    en: 'Identify the exact type of job title you wish to target, for ex: CFO, CTO, Cybersecurity',
  },
  'filter.addSubdivisions': {
    ro: 'Adăugați subdiviziuni',
    ru: 'Добавить подразделения',
    en: 'Add subdivisions',
  },
  'filter.searchTheList': {
    ro: 'Căută listă',
    ru: 'Искать в списке',
    en: 'Search the list',
  },
});

export default list;
