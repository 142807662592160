import React from 'react';
import { Avatar } from '~/components/profile';
import { injectIntl } from '~/libs/localization';

const AvatarManager = ({ data, t, loading }) => {
  const name = `${data.first_name || ''} ${data.last_name || ''}`.replace('  ', '');

  return (
    <div className="avatar-column avatar-manager">
      <Avatar loading={loading} img={data.photo} alt={name} />

      {!loading && (
        <>
          <span className="title">{name}</span>

          <span className="advise">Manager{data.job_title || ''}</span>
        </>
      )}
    </div>
  );
};

export default injectIntl(AvatarManager);
