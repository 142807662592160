import React from 'react';

export const useStateHandlers = initialState => {
  const [state, setState] = React.useState(initialState);

  const setNewState = newState => {
    if (typeof newState === 'function') {
      setState(prevState => ({ ...prevState, ...newState(prevState) }));
    } else {
      setState(prevState => ({
        ...prevState,
        ...newState,
      }));
    }
  };

  return [state, setNewState, () => setState(initialState)];
};
