import React from 'react';
import { notification } from 'antd';
import { getRoute } from '~/libs/router';
import { PaymentData } from '~/components/cart/payment';
import { ProfileLayout } from '~/components/layouts';
import { injectIntl } from '~/libs/localization';
import { useDocumentTitle } from '~/hooks';

const OrderPaymentPage = ({ t, ...props }) => {
  useDocumentTitle(t('page.payment'));

  const onBack = () => props.history.push(getRoute('OrderPage'));

  const onSuccess = () => {
    notification.success({
      message: t('profile.basket'),
      description: t('billing.success'),
    });

    props.history.push(getRoute('OrderPage'));
  };

  return (
    <ProfileLayout>
      <PaymentData title="Cart" route="OrderPaymentPage" parent="cart" onClose={onBack} onSuccess={onSuccess} />
    </ProfileLayout>
  );
};

export default injectIntl(OrderPaymentPage);
