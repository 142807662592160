export default [
  {
    url: 'company_list',
    access: ['company_preview'],
    user_id: 19,
    user: 'vladimir.ebs@gmail.com',
    account_id: 20,
    type: 'visit',
    name: 'FPC EUROPROMEX SRL',
    parent_id: null,
    company_id: 10698,
    date_created: '2019-11-14T13:47:10.440749Z',
    params: { id: ['10698'] },
    first_name: 'Josan',
    last_name: 'Vladimir',
    photo: null,
  },
  {
    url: 'company_list',
    access: ['company_preview'],
    user_id: 19,
    user: 'vladimir.ebs@gmail.com',
    account_id: 20,
    type: 'visit',
    name: 'FPC EUROPROMEX SRL',
    parent_id: null,
    company_id: 10698,
    date_created: '2019-11-14T13:42:03.099192Z',
    params: { id: ['10698'] },
    first_name: 'Josan',
    last_name: 'Vladimir',
    photo: null,
  },
  {
    url: 'company_list',
    access: ['company_preview'],
    user_id: 19,
    user: 'vladimir.ebs@gmail.com',
    account_id: 20,
    type: 'visit',
    name: 'ENTERPRISE BUSINESS SOLUTIONS',
    parent_id: null,
    company_id: 2836,
    date_created: '2019-11-14T13:34:16.478487Z',
    params: { id: ['2836'] },
    first_name: 'Josan',
    last_name: 'Vladimir',
    photo: null,
  },
  {
    url: 'company_list',
    access: ['company_preview'],
    user_id: 19,
    user: 'vladimir.ebs@gmail.com',
    account_id: 20,
    type: 'visit',
    name: 'FPC EUROPROMEX SRL',
    parent_id: null,
    company_id: 10698,
    date_created: '2019-11-14T13:34:10.176412Z',
    params: { id: ['10698'] },
    first_name: 'Josan',
    last_name: 'Vladimir',
    photo: null,
  },
  {
    url: 'company_list',
    access: ['company_preview'],
    user_id: 19,
    user: 'vladimir.ebs@gmail.com',
    account_id: 20,
    type: 'visit',
    name: 'FPC EUROPROMEX SRL',
    parent_id: null,
    company_id: 10698,
    date_created: '2019-11-14T11:50:51.792347Z',
    params: { id: ['10698'] },
    first_name: 'Josan',
    last_name: 'Vladimir',
    photo: null,
  },
];
