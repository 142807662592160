import React from 'react';
import { Tabs } from 'antd';
import MediaQuery from 'react-responsive';
import { useRouteMatch } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import { injectIntl } from '~/libs/localization';
import responsive from '~/libs/responsive';
import { Link, isNavTabActive, getRouteByPath } from '~/libs/router';
import { Icon } from '~/components/ui/Icon/Icon';

const MenuLinks = ({ className, t }) => {
  const match = useRouteMatch();
  const currentNavTab = getRouteByPath(match.path);

  const store = useSelector(
    ({ authentication }) => ({
      authenticationParams: authentication.params,
      permissions: authentication.permissions,
    }),
    shallowEqual,
  );

  // const linkAccess = React.useMemo(
  //   () =>
  //     store.authenticationParams.permissions_group
  //       ? store.authenticationParams.permissions_group.map(item => item.codename)
  //       : [],
  //   [store.authenticationParams.permissions_group],
  // );

  const shortcuts = React.useMemo(
    () =>
      store.authenticationParams.settings && store.authenticationParams.settings.menu_payload
        ? [
            // {
            //   key: 'dashboard',
            //   title: t('menu.dashboard'),
            //   route: 'HomePage',
            //   params: {},
            //   icon: 'menu-dashboard',
            //   accessible: store.authenticationParams.settings.menu_payload.dashboard,
            // },
            // {
            //   key: 'search',
            //   title: t('menu.find'),
            //   route: 'Search',
            //   params: { page: 1, query: '' },
            //   icon: 'menu-find',
            //   accessible: store.authenticationParams.settings.menu_payload.search,
            //   hidden: !linkAccess.includes('search'),
            // },
            // {
            //   key: 'messages',
            //   title: t('menu.messages'),
            //   route: 'Messages',
            //   params: { filter: 'all', mode: 'messages' },
            //   icon: 'menu-messages',
            //   accessible: store.authenticationParams.settings.menu_payload.messages,
            //   hidden: !linkAccess.includes('messages'),
            // },
            // {
            //   key: 'exploration',
            //   title: t('menu.exploration'),
            //   route: 'SearchResultsPage',
            //   params: { type: 'company', mode: 'basic' },
            //   icon: 'menu-filter',
            //   accessible: store.authenticationParams.settings.menu_payload.exploration,
            //   hidden: !linkAccess.includes('exploration'),
            // },
            // {
            //   key: 'alerts',
            //   title: t('menu.alerts'),
            //   route: 'Alerts',
            //   params: { filter: 'all', mode: 'list' },
            //   icon: 'menu-alerts',
            //   accessible: store.authenticationParams.settings.menu_payload.alerts,
            //   hidden: !linkAccess.includes('alerts'),
            // },
            // {
            //   key: 'monitoring',
            //   title: t('menu.monitoring'),
            //   route: 'Monitoring',
            //   params: {},
            //   icon: 'menu-monitoring',
            //   accessible: store.authenticationParams.settings.menu_payload.monitoring,
            //   hidden: !linkAccess.includes('monitoring'),
            // },
            // {
            //   key: 'legal_documents',
            //   title: t('menu.legal_bulletin'),
            //   route: 'LegalBulletin',
            //   params: {},
            //   icon: 'menu-legal-bulletin',
            //   accessible: store.authenticationParams.settings.menu_payload.legal_documents,
            //   hidden: !linkAccess.includes('legal_documents'),
            // },
            // {
            //   key: 'management',
            //   title: t('menu.management'),
            //   route: 'AdminAccountsPage',
            //   params: {},
            //   icon: 'menu-management',
            //   accessible: store.authenticationParams.settings.menu_payload.management,
            //   hidden: store.authenticationParams.is_superuser !== true,
            // },
          ]
        : [],
    [store],
  );

  return (
    <>
      <MediaQuery minWidth={responsive.desktop}>
        {store.authenticationParams.settings && store.authenticationParams.settings.menu_payload ? (
          <Tabs mode="horizontal" className={`zh-filter-tags ${className || 'header-menu-item'}`}>
            {shortcuts
              .filter(i => !i.hidden)
              .map(item => {
                const active = isNavTabActive(item.route);

                return item.accessible ? (
                  <Tabs.TabPane
                    tab={
                      <Link
                        route={item.route}
                        className={active === currentNavTab ? 'active' : undefined}
                        params={item.params}
                      >
                        <Icon type={item.icon} />
                        {item.title}
                      </Link>
                    }
                    key={item.key}
                  />
                ) : null;
              })}
          </Tabs>
        ) : null}
      </MediaQuery>
    </>
  );
};

export default injectIntl(MenuLinks);
