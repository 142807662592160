import React, { useState } from 'react';
import { connect } from 'react-redux';
import { ProfileLayout } from '~/components/layouts';
import PackageInfo from '../../components/profile/PackageInfo';
import Packages from '../../components/profile/Packages';

import '~/assets/scss/components/_MyProfile.scss';

const MyPackegePage = ({ manager = {}, userLoading, ...props }) => {
  const [isChangePackage, setIsChangePackage] = useState(false);

  const onChangePackage = isChange => setIsChangePackage(isChange);

  return (
    <ProfileLayout>
      {!isChangePackage ? (
        <PackageInfo onChangePackage={onChangePackage} />
      ) : (
        <Packages onChangePackage={onChangePackage} />
      )}
    </ProfileLayout>
  );
};

export default connect(({ authentication }) => ({
  userLoading: authentication.loading,
  manager: authentication.params.manager,
}))(MyPackegePage);
