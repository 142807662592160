import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useInjectIntl } from '~/libs/localization';
import { Layout } from '~/components/layouts';
import { EmptyBlock, Button } from '~/components/ui';
import { getRoute } from '~/libs/router';
import { useDocumentTitle } from '~/hooks';

const NotFoundPage = ({ isNotFound }) => {
  const history = useHistory();
  const { t } = useInjectIntl();
  const isAuth = useSelector(({ authentication }) => authentication.isAuth);
  useDocumentTitle(t('page.home'));

  const emptyBlockView = (
    <EmptyBlock
      title={t({ id: 'statics.notFound' })}
      desc=" "
      button={
        <>
          <Button type="primary" onClick={() => history.push(getRoute('HomePage'))}>
            {t({ id: 'statics.home' })}
          </Button>

          <Button
            type="secondary"
            onClick={() => history.push(getRoute('SearchResultsPage', { type: 'company', mode: 'basic' }))}
          >
            {t({ id: 'home.searchCompanies' })}
          </Button>
        </>
      }
      type={isNotFound && 'notFound'}
    />
  );

  return isAuth ? <Layout>{emptyBlockView}</Layout> : emptyBlockView;
};

export default NotFoundPage;
