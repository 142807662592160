import * as React from 'react';
import { Popover } from 'antd';
import { useInjectIntl } from '~/libs/localization';
import { Link } from '~/libs/router';
import { ButtonPublic } from '~/components/ui';

const UnavailableData = ({ placement, fixed, children }) => {
  const { t } = useInjectIntl();

  return (
    <Popover
      placement={placement}
      overlayClassName={`unavailable-data-popover ${fixed ? 'position-fixed' : ''}`}
      content={
        <div className="unavailable-data-popover-section">
          <span>{t('unavailableData.only_subscribed_users')}</span>

          <Link route="ab">
            <ButtonPublic size="small" type="primary">
              {t('bluredData.view_products')}
            </ButtonPublic>
          </Link>
        </div>
      }
      title=""
      trigger="click"
    >
      {children}
    </Popover>
  );
};

export default UnavailableData;
