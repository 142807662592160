import React, { useState } from 'react';
import MediaQuery from 'react-responsive';
import { Radio, Button } from 'antd';
import responsive from '~/libs/responsive';
import { useInjectIntl } from '~/libs/localization';
import { Layout } from '~/components/layouts';
import { Icon } from '~/components/ui/Icon/Icon';
import {
  LegalBulletinContentMain,
  LegalBulletinContentList,
  LegalBulletinContentActs,
  SidebarLegalBulletin,
} from '../components/legal-bulletin';

import '../assets/scss/components/_LegalBulletin.scss';

const LegalBulletin = () => {
  const { t } = useInjectIntl();

  const [type, setType] = useState('main');
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const onHandleNavigation = newTab => setType(newTab.target.value);

  const onShowSidebar = () => setIsDrawerVisible(true);

  const onCloseSidebar = () => setIsDrawerVisible(false);

  const LegalBulletinContent = () => {
    switch (type) {
      case 'main':
        return <LegalBulletinContentMain />;
      case 'list':
        return <LegalBulletinContentList />;
      case 'acts':
        return <LegalBulletinContentActs />;
      default:
        return <LegalBulletinContentMain />;
    }
  };

  return (
    <>
      <Layout containerClass="legal-bulletin-container">
        <Radio.Group className="navigation" value={type} onChange={onHandleNavigation}>
          <Radio.Button value="main">{t('legalBulletin.legalBulletin')}</Radio.Button>
          <Radio.Button value="list">{t('legalBulletin.list')}</Radio.Button>
          <Radio.Button value="acts">{t('legalBulletin.legalDocuments')}</Radio.Button>
        </Radio.Group>

        <div className="middle-container">
          <MediaQuery maxWidth={responsive.desktop}>
            <Button type="primary" className="filter-btn" onClick={onShowSidebar}>
              <Icon type="menu-navigation" />
              {t('legalBulletin.filter')}
            </Button>
          </MediaQuery>

          <SidebarLegalBulletin type={type} onCloseSidebar={onCloseSidebar} isDrawerVisible={isDrawerVisible} />

          <LegalBulletinContent />
        </div>
      </Layout>
    </>
  );
};

export default LegalBulletin;
