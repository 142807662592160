import React, { useState, useEffect } from 'react';
import MediaQuery from 'react-responsive';
import { message, Button, Drawer } from 'antd';
import { useRequest } from 'estafette';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { monitoring, profile } from '~/libs/http/api';
import { useInjectIntl } from '~/libs/localization';
import responsive from '~/libs/responsive';
import { saveLastOpenItem } from '~/store/actions/MonitoringSidebarActions';
import { Icon } from '~/components/ui/Icon/Icon';
import { Layout } from '~/components/layouts';
import { Loader } from '~/components/ui';
import { ModalAddCounterparty, ModalCompanyInformation, ModalMonitoringLists } from '../components/monitoring';
import SidebarMonitoring from '../components/monitoring/SidebarMonitoring';
import MonitoringAll from '../components/monitoring/MonitoringAll';
import ListSettings from '../components/monitoring/ListSettings';

import '../assets/scss/components/_Monitoring.scss';

const Monitoring = () => {
  const { t } = useInjectIntl();

  const { request: requestMonitoringList, loading: loadingMonitoringList, data: dataMonitoringList } = useRequest({
    data: { data: [] },
  });
  const { request: requestUsersMonitoringLists, data: dataUsersMonitoringLists } = useRequest({
    data: { data: [] },
  });

  const { request: requestCompanies, loading: loadingCompanies } = useRequest();
  const { request: requestTypes, data: dataTypes } = useRequest();
  const { request: requestProfile, data: dataProfile } = useRequest();
  const { request: requestList, loading: loadingList } = useRequest();
  const { request: requestNotifications } = useRequest();
  const { request: requestCompanyNotifications, data: dataCompanyNotifications } = useRequest();
  const { request: requestAddMonitoringList, loading: loadingAddMonitoringList } = useRequest();
  const { request: requestDeleteFromList, loading: loadingDeleteFromList } = useRequest();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [perPage] = useState(10);
  const [isListSettings, setIsListSettings] = useState(false);
  const [listId, setListId] = useState(null);
  const [removeCompanyId, setRemoveCompanyId] = useState(null);
  const [isModalAddCounterparty, setIsModalAddCounterparty] = useState(false);
  const [isModalCompanyInformation, setIsModalCompanyInformation] = useState(false);
  const [isModalMonitoringLists, setIsModalMonitoringLists] = useState(false);
  const [dataMonitoring, setDataMonitoring] = useState({});
  const [listType, setListType] = useState(null);
  const [checkedListTypes, setCheckedListTypes] = useState([]);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [filterName, setFilterName] = useState(null);
  const [idnos, setIdnos] = useState([]);
  const [listMonitoringId, setListMonitoringId] = useState(null);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [currentItemId, setCurrentItemId] = useState(null);
  const [currentSubMenu, setCurrentSubMenu] = useState('companyMonitoring');
  const [refreshCompany, setRefreshCompany] = useState(false);
  const [modalAction, setModalAction] = useState(false);

  const store = useSelector(({ monitoringSidebar }) => ({ monitoringSidebar }), shallowEqual);

  useEffect(() => {
    onUpdatePage();
    requestProfile(profile.get());
    onFetchUsersMonitoringLists();
  }, []);

  useEffect(() => {
    // if (checkedListTypes.length > 0 || filterName) {
    //   onFetchNotifications();
    // }
    onFetchNotifications();
  }, [checkedListTypes, dateFrom, dateTo, filterName]);

  useEffect(() => {
    switch (listType) {
      case 'companies':
        onFetchCompanies();
        break;
      case 'companiesFromList':
        onFetchCompaniesFromList(listId);
        break;
      case 'events':
        onFetchNotifications();
        break;
      default:
        break;
    }
  }, [page]);

  useEffect(() => {
    if (modalAction) {
      onFetchMonitoringListAndItems(listId);
    }
  }, [modalAction]);

  const onSetIdnos = list => setIdnos(list);

  const onShowSidebar = () => setIsDrawerVisible(true);

  const onCloseSidebar = () => setIsDrawerVisible(false);

  const onFetchMonitoringListAndItems = async id => {
    await onFetchMonitoringList();
    onFetchCompaniesFromList(id);
  };

  const onFetchMonitoringList = async () => {
    if (requestMonitoringList)
      try {
        await requestMonitoringList(monitoring.getList());
      } catch {
        message.error(t('other.error_module'));
      }
  };

  const onFetchUsersMonitoringLists = async () => {
    try {
      await requestUsersMonitoringLists(monitoring.getUsersLists());
    } catch {
      message.error(t('other.error_module'));
    }
  };

  const onFetchCompanies = async () => {
    if (store.monitoringSidebar.id) {
      return;
    }

    try {
      if (listType !== 'companies') {
        setPage(1);
      }

      const params = {
        list_type: [],
        per_page: perPage,
        page,
      };
      const obj = await requestCompanies(monitoring.getCompanies(params));
      setDataMonitoring(obj);

      setListType('companies');
    } catch {
      message.error(t('other.error_module'));
    }
  };

  const onDeleteList = () => {
    // setListType('companies');
    dispatch(saveLastOpenItem(null));
    mainMenuItem();
    onUpdatePage();
    setListId(null);
  };

  const mainMenuItem = async () => {
    try {
      if (listType !== 'companies') {
        setPage(1);
      }
      const params = {
        list_type: [],
        per_page: perPage,
        page,
      };
      const obj = await requestCompanies(monitoring.getCompanies(params));
      setDataMonitoring(obj);

      setListType('companies');
    } catch {
      message.error(t('other.error_module'));
    }
  };

  const onFetchCompaniesFromList = async (id, userId) => {
    if (!id) {
      return;
    }

    try {
      if (listType !== 'companiesFromList') {
        setPage(1);
      }

      const params = {
        list_id: id,
        user_id: userId || dataProfile.id,
        per_page: perPage,
        page,
      };

      const obj = await requestList(monitoring.getListInfo(params));
      setDataMonitoring(obj);

      setListType('companiesFromList');

      setListId(id);

      setCurrentItemId(id);
    } catch {
      message.error(t('other.error_module'));
    }
  };

  const onSaveSettings = () => {
    onFetchMonitoringList();
    setIsListSettings(false);
  };

  const onFetchTypes = () => {
    try {
      requestTypes(monitoring.getTypes());
    } catch {
      message.error(t('other.error_module'));
    }
  };

  const onFetchNotifications = async () => {
    try {
      if (listType !== 'events') {
        setPage(1);
      }

      // setListType(null);
      let stringParams = '';
      stringParams += `name=${filterName || ''}`;
      stringParams += `&from_date=${dateFrom || ''}`;
      stringParams += `&to_date=${dateTo || ''}`;
      stringParams += `&per_page=${perPage}`;
      stringParams += `&page=${page}`;

      if (checkedListTypes.length > 0) {
        // eslint-disable-next-line array-callback-return
        checkedListTypes.map(item => {
          stringParams += `&events_types=${item}`;
        });
      }

      const obj = await requestNotifications(monitoring.getNotifications(stringParams));
      setDataMonitoring(obj);

      setListType('events');
    } catch {
      message.config({ top: 80 });
      message.error(t('other.error_module'));
    }
  };

  const onFilterNotifications = ({ checkedKeys }) => setCheckedListTypes(checkedKeys);

  const onFetchCompanyNotifications = recordCompany => {
    try {
      const params = {
        list_id: recordCompany.list_id,
        user_id: recordCompany.user_id,
        id: recordCompany.id,
      };
      requestCompanyNotifications(monitoring.getCompanyNotifications(params));
    } catch {
      message.error(t('other.error_module'));
    }
  };

  const onDeleteFromList = async ({ companyId, listId: oldListId }) => {
    try {
      const params = {
        list_id: oldListId,
        companies_ids: companyId,
      };
      await requestDeleteFromList(monitoring.deleteFromList(params));

      onFetchCompaniesFromList(oldListId);
      onUpdatePage();
    } catch {
      message.error(t('other.error_module'));
    }
  };

  const showListSettings = async (show, id) => {
    await setIsListSettings(false);
    await setListId(id || null);
    setIsListSettings(show);
  };

  const showMonitoringNotifications = show => {
    if (show) {
      onFetchNotifications();
    }
  };

  const showModalAddCounterparty = show => setIsModalAddCounterparty(show);

  const showModalAddCompanyAnotherList = show => setIsModalMonitoringLists(show);

  const showModalCompanyInformationList = show => setIsModalCompanyInformation(show);

  const showModalCompanyInformation = (show, recordCompany) => {
    setIsModalCompanyInformation(show);

    if (show) {
      onFetchCompanyNotifications(recordCompany);
    }
  };

  const onChangeDateFrom = date => (date ? setDateFrom(date.format('YYYY-MM-DD')) : null);

  const onChangeDateTo = date => (date ? setDateTo(date.format('YYYY-MM-DD')) : null);

  const onApplyCounterparty = () => {
    if (idnos.length > 0) {
      if (currentItemId && !refreshCompany) {
        onSubmitAddToList();
      } else {
        setIsModalMonitoringLists(true);
      }
    } else {
      message.error(t('monitorig.firstAddIDNO'));
    }
  };

  const onSubmitAddToList = async () => {
    const activeArr = dataMonitoringList.filter(arr => arr.id === listMonitoringId);

    if (removeCompanyId) {
      await onDeleteFromList({ companyId: removeCompanyId, listId });
    }

    try {
      const options = {
        list_id: listMonitoringId,
        companies_ids: idnos,
        name: activeArr[0].name,
      };

      await requestAddMonitoringList(monitoring.addList(options));

      message.success(t('other.success.added'));

      showModalAddCompanyAnotherList(false);
      showModalAddCounterparty(false);

      setListMonitoringId(listId);

      onFetchCompaniesFromList(listId);

      onUpdatePage();
    } catch ({ response }) {
      message.error(t('other.error_module'));
    }
  };

  const onChangeList = ({ companyId: activeCompanyId, listId: activeListId }) => {
    onSetIdnos([activeCompanyId]);
    setListId(activeListId);
    setRemoveCompanyId(activeCompanyId);

    setListType('companiesFromList');
    onFetchCompaniesFromList(activeListId);

    setIsModalMonitoringLists(true);
  };

  const onUpdatePage = () => {
    setModalAction(false);
    onFetchCompanies();
    onFetchMonitoringList();
    onFetchTypes();
  };

  const onSetListMonitoringId = id => setListMonitoringId(id);

  const onCloseModalAddCompanyAnotherList = () => {
    showModalAddCompanyAnotherList(false);
    setRemoveCompanyId(null);
  };

  const onChangePage = newPage => setPage(newPage);

  const onRefreshCompany = value => setRefreshCompany(value);

  return (
    <>
      {isModalMonitoringLists && (
        <ModalMonitoringLists
          onClose={onCloseModalAddCompanyAnotherList}
          dataMonitoringList={dataMonitoringList}
          onSubmitAddToList={onSubmitAddToList}
          onSetListMonitoringId={onSetListMonitoringId}
          listMonitoringId={listMonitoringId}
          loadingAddMonitoringList={loadingAddMonitoringList}
        />
      )}

      {isModalAddCounterparty && (
        <ModalAddCounterparty
          onClose={showModalAddCounterparty}
          idnos={idnos}
          onSetIdnos={onSetIdnos}
          onApplyCounterparty={onApplyCounterparty}
          loadingAddMonitoringList={loadingAddMonitoringList}
          updateData={onFetchCompaniesFromList}
          listId={listId}
          setListType={setListType}
          modalAction={setModalAction}
        />
      )}

      {isModalCompanyInformation && (
        <ModalCompanyInformation
          onClose={showModalCompanyInformationList}
          dataCompanyNotifications={dataCompanyNotifications}
        />
      )}

      <Layout containerClass="monitoring-container">
        <MediaQuery maxWidth={responsive.desktop}>
          <Button type="primary" className="filter-btn" onClick={onShowSidebar}>
            <Icon type="menu-navigation" />
            {t('monitorig.menu')}
          </Button>
        </MediaQuery>

        <MediaQuery maxWidth={responsive.desktop}>
          <Drawer
            placement="left"
            closable={false}
            onClose={onCloseSidebar}
            visible={isDrawerVisible}
            className="drawer-legal-sidebar"
            width="auto"
          >
            <SidebarMonitoring
              showListSettings={showListSettings}
              showModalAddCounterparty={showModalAddCounterparty}
              showMonitoringNotifications={showMonitoringNotifications}
              dataTypes={dataTypes}
              loadingMonitoringList={loadingMonitoringList}
              dataMonitoringList={dataMonitoringList}
              dataUsersMonitoringLists={dataUsersMonitoringLists}
              onFetchCompaniesFromList={onFetchCompaniesFromList}
              onFilterNotifications={onFilterNotifications}
              onChangeDateFrom={onChangeDateFrom}
              onChangeDateTo={onChangeDateTo}
              onSetListMonitoringId={onSetListMonitoringId}
              onChangePage={onChangePage}
              onUpdatePage={onUpdatePage}
              onCurrentSubMenu={setCurrentSubMenu}
              onRefreshCompany={onRefreshCompany}
              clearId={setListId}
              idProfile={dataProfile.id}
              setListType={setListType}
              mainMenuItem={mainMenuItem}
            />
          </Drawer>
        </MediaQuery>

        <MediaQuery minWidth={responsive.desktop}>
          <SidebarMonitoring
            showListSettings={showListSettings}
            showModalAddCounterparty={showModalAddCounterparty}
            showMonitoringNotifications={showMonitoringNotifications}
            dataTypes={dataTypes}
            loadingMonitoringList={loadingMonitoringList}
            dataMonitoringList={dataMonitoringList}
            dataUsersMonitoringLists={dataUsersMonitoringLists}
            onFetchCompaniesFromList={onFetchCompaniesFromList}
            onFilterNotifications={onFilterNotifications}
            onChangeDateFrom={onChangeDateFrom}
            onChangeDateTo={onChangeDateTo}
            onSetListMonitoringId={onSetListMonitoringId}
            onChangePage={onChangePage}
            onUpdatePage={onUpdatePage}
            onCurrentSubMenu={setCurrentSubMenu}
            onRefreshCompany={onRefreshCompany}
            clearId={setListId}
            idProfile={dataProfile.id}
            setListType={setListType}
            mainMenuItem={mainMenuItem}
          />
        </MediaQuery>

        <div className="monitoring-area">
          {!isListSettings && (
            <Loader loading={loadingCompanies || loadingList}>
              <MonitoringAll
                showModalCompanyInformation={showModalCompanyInformation}
                dataMonitoring={dataMonitoring}
                listType={listType}
                onChangeFilterName={setFilterName}
                onDeleteFromList={onDeleteFromList}
                loadingDeleteFromList={loadingDeleteFromList}
                showModalAddCounterparty={showModalAddCounterparty}
                onChangeList={onChangeList}
                page={page}
                onChangePage={onChangePage}
                currentSubMenu={currentSubMenu}
                listId={listId}
                refetchData={onDeleteList}
                showListSettings={showListSettings}
                setListId={setListId}
              />
            </Loader>
          )}

          {isListSettings && (
            <ListSettings
              refreshAfterSave={onFetchUsersMonitoringLists}
              showListSettings={showListSettings}
              dataTypes={dataTypes}
              onSaveSettings={onSaveSettings}
              listId={listId}
              setListId={setListId}
            />
          )}
        </div>
      </Layout>
    </>
  );
};

export default Monitoring;
