import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Pagination } from 'antd';
// import moment from 'moment';
import { EnvironmentOutlined, MobileOutlined, PhoneOutlined, MailOutlined } from '@ant-design/icons';
import { useInjectIntl } from '~/libs/localization';
import { addOrRemove } from '~/libs/array';
import { Link, getRoute } from '~/libs/router';
// import { getLogotypeLink, parseWebsite } from '~/libs/string';
import { Contact, CardList, CardListItem, CardListItemRow, CardListItemValue } from '~/components/ui';

// const currentYear = moment().format('YYYY');

const ProspectList = ({ provider = 'companies', loading, pagination, rows, dataSource, onSelectRows, ...props }) => {
  //   const [clicked, setClicked] = React.useState();
  const isEmployee = provider === 'employees';
  const classLoading = loading ? 'skeleton skeleton-text' : '';
  const { t } = useInjectIntl();
  const history = useHistory();

  const onCheck = React.useCallback(slug => onSelectRows && onSelectRows(addOrRemove(rows, slug)), [
    onSelectRows,
    rows,
  ]);

  //   const onClickListItem = React.useCallback(id => setClicked(id), []);

  return (
    <CardList title={props.title} actions={props.actions}>
      {/* {dataSource.length === 0 && <Empty style={{ marginTop: 100 }} description={false} />} */}

      {dataSource.length > 0 &&
        dataSource.map(item => {
          //   const companyAge =
          //     'creation_year' in item && item.creation_year ? Number(currentYear) - item.creation_year : 0;

          return (
            <CardListItem
              hideCheck
              // checked={rows.includes(item.slug)}
              {...(onSelectRows ? { onCheck: () => onCheck(item.slug) } : {})}
              key={item.id}
              //   loading={clicked === item.slug}
              {...('partners' in item && item.partners && item.partners.length > 0
                ? {
                    footer: (
                      <span>
                        {t('prospectList.founders')}: <b>{item.partners.join(', ')}</b>
                      </span>
                    ),
                  }
                : null)}
            >
              <div className="prospect-list-card-header">
                {/* <Avatar
                  //   {...('website' in item ? { src: getLogotypeLink(item.website) } : {})}
                  {...('website' in item ? '' : {})}
                  loading={loading}
                  className="card-header-avatar"
                  alt={item.name}
                /> */}

                <div
                  className={`card-header-info ${
                    'location' in item && !item.location ? 'card-header-info-padding' : ''
                  }`}
                >
                  {loading ? (
                    <span className="link skeleton skeleton-text">{item.name}</span>
                  ) : item.slug ? (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <Link
                      className="link"
                      //   route="pf"
                      //   params={{ type: isEmployee ? 'employee' : 'company', slug: item.slug }}
                      //   onClick={() => onClickListItem(item.slug)}
                      onClick={() => history.push(getRoute('ExecutivePage', { page: 1, id: item.id }))}
                    >
                      {item.name}
                    </Link>
                  ) : (
                    <span className="link-disabled">{item.name}</span>
                  )}

                  {'location' in item && item.location && (
                    <div className="card-header-avatar-address">
                      <span className={classLoading}>
                        <EnvironmentOutlined /> {item.location}
                      </span>
                    </div>
                  )}

                  {'company_id' in item && (
                    <div className="card-header-avatar-company">
                      {item.company_slug ? (
                        <Link
                          className={`link ${classLoading}`}
                          route="pf"
                          params={{ type: 'company', slug: item.company_slug }}
                        >
                          {item.company_name}
                        </Link>
                      ) : (
                        <span className="list list-disabled">{item.company_slug}</span>
                      )}
                    </div>
                  )}
                </div>
              </div>

              <CardListItemRow>
                {isEmployee && (
                  <CardListItemValue title={t('prospectList.seniority')}>
                    <span className={classLoading}>{('seniority' in item && item.seniority) || '---'}</span>
                  </CardListItemValue>
                )}

                {isEmployee && (
                  <CardListItemValue title={t('prospectList.function')}>
                    <span className={classLoading}>{('function' in item && item.function) || '---'}</span>
                  </CardListItemValue>
                )}

                {'idno' in item && (
                  <CardListItemValue title="IDNO">
                    <span className={classLoading}>{item.idno || '---'}</span>
                  </CardListItemValue>
                )}

                {/* <CardListItemValue title={t('prospectList.status')}>
                  <span className={classLoading}>
                    <Status keyword="ACTIVE" status="Activ" />
                  </span>
                </CardListItemValue> */}

                {/* {'creation_year' in item && (
                  <>
                    <CardListItemValue title={t('prospectList.date_of_establishment')}>
                      <span className={classLoading}>{item.creation_year}</span>
                    </CardListItemValue>

                    <CardListItemValue title="Vărsta">
                      <span className={classLoading}>
                        {item.creation_year
                          ? `${companyAge || 1} ${t(companyAge === 1 ? 'prospect.year' : 'prospect.years')}`
                          : '---'}
                      </span>
                    </CardListItemValue>
                  </>
                )} */}
              </CardListItemRow>

              <CardListItemRow>
                {'employees' in item && (
                  <CardListItemValue title={t('prospectList.nr_employees')}>
                    <span className={classLoading}>{item.employees || '---'}</span>
                  </CardListItemValue>
                )}

                {'turnover' in item && (
                  <CardListItemValue title={t('prospectList.turnover')}>
                    <span className={classLoading}>{item.turnover ? `${item.turnover} MLD` : '---'}</span>
                  </CardListItemValue>
                )}
              </CardListItemRow>

              <CardListItemRow>
                <CardListItemValue title={t('prospectList.contacts')}>
                  {'mobile' in item && <Contact icon={<MobileOutlined />} type="mobile" value={item.mobile} />}

                  <Contact icon={<PhoneOutlined />} type="phone" value={item.phone} />

                  <Contact icon={<MailOutlined />} type="email" value={item.email} />
                </CardListItemValue>
              </CardListItemRow>
            </CardListItem>
          );
        })}

      {pagination && <Pagination {...pagination} hideOnSinglePage />}
    </CardList>
  );
};

export default ProspectList;
