import { adapterIntl } from '~/libs/localization/helpers';

const notification = adapterIntl({
  // Sign up
  'notification.signup.token-title': {
    ro: 'Înregistrare',
    ru: 'Регистрация',
    en: 'Signup',
  },
  'notification.signup.token-message': {
    ro: 'Timpul pentru înscriere a expirat.',
    ru: 'Время для регистрации истекло',
    en: 'Your time for signup has been expired.',
  },
  'notification.signup.title': {
    ro: 'Înregistrare',
    ru: 'Регистрация',
    en: 'Signup',
  },
  'notification.signup.message': {
    ro: 'Ați trecut cu succes înregistrarea! Un email cu invitație a fost trimis la adresa dvs. de e-mail.',
    ru: 'Вы успешно прошли регистрацию! Код подтверждения был выслан на ваш e-mail.',
    en: 'You have successfully registered! A confirmation code has been sent to your e-mail.',
  },
  'notification.signup-subscription.message': {
    ro: 'Ați trecut cu succes prima etapă de înregistrare!',
    ru: 'Вы успешно прошли первый этап регистрации!',
    en: 'You have successfully passed the first step of registration!',
  },

  // Metrics
  'notification.metrics.title': {
    ro: 'Abonament',
    ru: 'Подписка',
    en: 'Subscription',
  },
  'notification.metrics.message': {
    ro: 'V-ați înscris cu succes!',
    ru: 'Вы успешно подписались!',
    en: 'You have successfully subscribed!',
  },

  // My cart
  'notification.cart.message': {
    ro: 'Adăugat cu succes în coș!',
    ru: 'Успешно добавлено в корзину!',
    en: 'Successfully added in cart!',
  },
  'notification.cart.direction': {
    ro: 'Coșul meu',
    ru: 'Моя корзина',
    en: 'My cart',
  },

  // Export
  'notification.export.message': {
    ro: 'Export {title} este gata pentru descărcare!',
    ru: 'Выгрузка {title} доступен к загрузки!',
    en: 'Export {title} is ready for download!',
  },
  'notification.export.direction': {
    ro: 'Descărcările mele',
    ru: 'Мои загрузки',
    en: 'My downloads',
  },

  // Company update
  'notification.company_update.message': {
    ro: 'Compania {title} a fost actualizată!',
    ru: 'Компания {title} была обновлена!',
    en: 'Company {title} was updated!',
  },
  'notification.company_update.direction': {
    ro: 'Profil',
    ru: 'Открыть профиль',
    en: 'Show profile',
  },

  // Payment
  'notification.payment.message': {
    ro: 'Cumpărat cu succes!',
    ru: 'Успешно куплено!',
    en: 'Successfully purchased!',
  },

  // Add product
  'notification.product.message.update': {
    ro: 'Actualizat cu succes!',
    ru: 'Успешно отредактирован!',
    en: 'Successfully updated!',
  },
  'notification.product.message.create': {
    ro: 'Creat cu succes!',
    ru: 'Успешно создан!',
    en: 'Successfully created!',
  },
});

export default notification;
