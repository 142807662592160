import React, { useEffect, useReducer, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useRequest } from 'estafette';
import { Input } from 'antd';
import { Loader, Pagination } from '~/components/ui';
import { ManagementMenu, SupportTable } from '~/components/management';
import { Layout } from '~/components/layouts';
import { TableHeader } from '~/components/table';
import { useDocumentTitle } from '~/hooks';
import { getRoute } from '~/libs/router';
import { injectIntl } from '~/libs/localization';
import { managementSupport } from '~/libs/http/api';
import { notification } from '~/libs/notification';

const filterReducer = (state, action) => {
  switch (action.type) {
    case 'SET_PAGE': {
      return { ...state, page: Number(action.page) };
    }
    case 'SET_STATUS': {
      return { ...state, status: action.status, page: 1 };
    }
    case 'SET_SEARCH': {
      return { ...state, search: action.search, page: 1 };
    }
    default:
      return state;
  }
};

const AdminSupportPage = ({ t }) => {
  useDocumentTitle(t('manager.supportTitle'));

  const { type, page } = useParams();
  const history = useHistory();
  const [filter, dispatch] = useReducer(filterReducer, {
    page,
    status: type,
    search: undefined,
  });
  const { request, data, loading } = useRequest({ data: { data: [] }, loading: true });
  const { request: requestMark, loading: loadingMark } = useRequest();

  const onChangeTab = status => {
    dispatch({ type: 'SET_STATUS', status });
  };
  const onChangePage = pageN => {
    dispatch({ type: 'SET_PAGE', page: pageN });
  };
  const onChangeSearch = search => {
    dispatch({ type: 'SET_SEARCH', search });
  };
  const refreshList = () => {
    dispatch({ type: 'SET_PAGE', page: 1 });
  };

  const onChangeStatus = useCallback(async (items, status) => {
    await requestMark(managementSupport.setStatus({ ids: items, status_message: status }));

    let text = '';
    if (status === 'deleted') {
      text = t({ id: 'other.success.deleted' });
    } else {
      text = t({ id: 'other.success.changed' });
    }

    notification({
      type: 'success',
      message: t('billing.success'),
      description: text,
    });
    refreshList();
  }, []);

  useEffect(() => {
    const onFetch = () => {
      history.push(getRoute('AdminSupportPage', { type: filter.status, page: filter.page }));

      request(
        managementSupport.list({
          page: filter.page,
          status_message: filter.status,
          search: filter.search,
        }),
      );
    };

    onFetch();
  }, [filter]);

  return (
    <Layout containerClass="updatesEdit-container">
      <ManagementMenu />

      <TableHeader
        leftSide={
          <h1>
            {t('other.total')} {loading || loadingMark ? '' : `(${data.total_results || 0})`}
          </h1>
        }
        rightSide={
          <div className="form-inline">
            <Input.Search
              placeholder={t({ id: 'searches.search' })}
              enterButton
              onSearch={onChangeSearch}
              defaultValue={filter.search}
            />
          </div>
        }
      />
      <Loader loading={loading || loadingMark}>
        <SupportTable
          status={filter.status}
          data={data.data}
          onChangeTab={onChangeTab}
          onChangeStatus={onChangeStatus}
          pagination={<Pagination page={filter.page} total={data.pages} onChange={value => onChangePage(value)} />}
        />
      </Loader>
    </Layout>
  );
};

export default injectIntl(AdminSupportPage);
