import React from 'react';
import { Button } from 'antd';
import { injectIntl } from '~/libs/localization';
import { ProfileLayout } from '~/components/layouts';
import { setDocumentTitle } from '~/hooks';

class ApiPage extends React.PureComponent {
  state = {};

  componentDidMount() {
    setDocumentTitle(this.props.t('page.api'));
  }

  render(): React.Node {
    return (
      <ProfileLayout loading={false}>
        <div className="api-access">
          <img src="/assets/img/api.png" alt="api access" width="144" height="144" />

          <h1>{this.props.t({ id: 'api.title' })}</h1>
          <h2>{this.props.t({ id: 'api.desc' })}</h2>

          <Button type="primary">{this.props.t({ id: 'api.btn' })}</Button>
        </div>
      </ProfileLayout>
    );
  }
}

export default injectIntl(ApiPage);
