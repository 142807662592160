import React, { useEffect, useState } from 'react';
import cookies from 'react-cookies';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { updateToken } from '~/store/actions/AuthenticationActions';
import { changeLocalization } from '~/store/actions/LocalizationActions';
import { useDocumentTitle } from '~/hooks';
import { getRoute, Link } from '~/libs/router';
import { useInjectIntl } from '~/libs/localization';
import { auth } from '~/libs/http/api';
import { parseObject, parseQuery } from '~/libs/http/url';
import { Header, Footer, Input, InputPassword, Checkbox, Button } from '~/components/public';
import { Auth, Form, Icon } from '~/components/ui';

const SigninPage = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useInjectIntl();

  useDocumentTitle(t('page.signIn'));

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const getLanguage = useParams();
  const store = useSelector(({ localization, authentication }) => ({ localization, authentication }), shallowEqual);

  useEffect(() => {
    if (getLanguage.language && getLanguage.language !== ':language') {
      dispatch(changeLocalization(getLanguage.language));
    } else {
      dispatch(changeLocalization(store.localization.current.shortName));
    }
  }, [getLanguage.language]);

  useEffect(() => {
    const onSetDefaultEmail = () => {
      const query = parseQuery(location.search);

      if (query.default_email) {
        setEmail(query.default_email);
      }
    };

    onSetDefaultEmail();
  }, [location.search]);

  const onSubmit = async ev => {
    ev.preventDefault();

    const query = parseQuery(location.search);
    const redirectUrl = cookies.load('redirect_url');
    const signInUrl = getRoute('SigninPage');
    const homePageUrl = getRoute('HomePage');

    setLoading(true);
    setErrors({});

    try {
      const { data } = await auth.signin({ username: email, password });

      dispatch(updateToken({ token: data.token, rememberMe }));

      if (query.package) {
        return history.push(`${getRoute('SubscriptionPaymentPage')}${parseObject({ package: query.package })}`);
      }

      if (typeof redirectUrl !== 'undefined') {
        cookies.remove('redirect_url', { path: signInUrl });

        return history.push(redirectUrl === signInUrl ? homePageUrl : redirectUrl);
      }

      return history.push(homePageUrl);
    } catch ({ response }) {
      setLoading(false);
      setErrors(response ? response.data : {});
      setPassword('');
    }

    return false;
  };

  const onChangeEmail = ({ target }) => setEmail(target.value);
  const onChangePassword = ({ target }) => setPassword(target.value);
  const onChangeRememberMe = () => setRememberMe(!rememberMe);

  return (
    <>
      <Header />

      <Auth.Container
        wide
        key="container"
        header={
          <div>
            <h1>{t({ id: 'statics.welcome' })}</h1>
            <p>{t({ id: 'statics.typeEmailPassword' })}</p>
          </div>
        }
      >
        <Form onSubmit={onSubmit} className="registration-step-one">
          <Form.Error message={errors.non_field_errors} />

          <Form.Item {...(errors.username ? { validateStatus: 'error', help: errors.username } : null)}>
            <Input placeholder={t({ id: 'other.email-auth' })} value={email} onChange={onChangeEmail} />
          </Form.Item>

          <Form.Item {...(errors.password ? { validateStatus: 'error', help: errors.password } : null)}>
            <InputPassword
              placeholder={t({ id: 'statics.password-auth' })}
              value={password}
              onChange={onChangePassword}
            />
          </Form.Item>

          <Form.Item className="auth-form-item public-forget-password">
            <Checkbox onChange={onChangeRememberMe} label={t({ id: 'statics.remember_me' })} />

            <Link route="RecoveryPage" className="pull-right">
              <Icon type="circle-question" />
              {t({ id: 'statics.forgotPassword' })}
            </Link>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading} text={t({ id: 'statics.new-login' })} />
          </Form.Item>

          <Form.Item>
            <div className="reg-now">
              <span>{t('statics.dont-have-acc')}</span>
              <Link route="SignupEmailPage" className="reg-now__link">
                {t('statics.reg-now')}
              </Link>
              <span> {t('statics.join-now')}</span>
            </div>
          </Form.Item>
        </Form>
      </Auth.Container>

      <Footer />
    </>
  );
};

export default SigninPage;
