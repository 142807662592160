import React from 'react';
import { Menu } from 'antd';
import { indexed } from '~/libs/array';
import { useInjectIntl } from '~/libs/localization';
import { Layout } from '~/components/layouts';

const TermsPage = () => {
  const { t } = useInjectIntl();

  const terms = [
    {
      name: t({ id: 'manager.terms-introduction' }),
      content: (
        <ol>
          <li>
            Внимательно ознакомьтесь, прежде чем пользоваться любой информацией и сервисами сайта INFORMER.MD
            (далее-Сайт).
          </li>
          <li>
            Сайт является собственностью VARO-INFORM S.R.L. и защищен законодательством Республики Молдова, в частности
            «Законом об авторском праве и смежных правах», а также другими законами и нормами международного права.
            Любое копирование Сайта, выполненное не в целях персонального использования, является нарушением закона.
          </li>
          <li>
            Настоящее &quot;Пользовательское Соглашение INFORMER.MD &quot; (далее – &quot;Соглашение&quot;) определяет
            условия использования Сайта как в его существующем, так и в  последующем (обновленном) виде.
          </li>
        </ol>
      ),
    },
    {
      name: t({ id: 'manager.terms-definitions' }),
      content: (
        <ul>
          <li>
            <b>Администрация Сайта</b> – уполномоченные представители VARO-INFORM S.R.L., наделенные полными правами
            руководства всем информационным и сервисным комплексом Сайта.
          </li>
          <li>
            <b>Информация</b> – совокупность данных предприятия, полученных путем заполнения анкеты уполномоченным
            представителем предприятия. Включает в себя: название предприятия, фактический адрес предприятия, номера
            телефонов, сайт, электронная почта, ключевые слова деятельности предприятия.
          </li>
          <li>
            <b>Пользователь</b> – юридическое или физическое лицо, пользующееся информацией, размещенной на Сайте.
          </li>
          <li>
            <b>Зарегистрированный пользователь</b> – юридическое лицо, пользующееся информацией и сервисами Сайта и
            зарегистрированное в установленном Администрацией Сайта порядке для изменения размещенной информации данного
            юридического лица.
          </li>
          <li>
            <i>
              Далее в Соглашении при наличии общих правил или требований для Пользователя и Зарегистрированного
              пользователя  применяется термин Пользователь.
            </i>
          </li>
        </ul>
      ),
    },
    {
      name: t({ id: 'manager.common-terms-rules' }),
      content: (
        <ul>
          <li>
            При регистрации на сайте Зарегистрированный пользователь соглашается с настоящим Соглашением и принимает на
            себя указанные в них правила, связанные с использованием Сайта.
          </li>
          <li>Цитирование информации, размещенной на Сайте, допускается только со ссылкой на первоисточник.</li>
          <li>
            Настоящее Соглашение (в том числе любая из его частей) может быть изменено Администрацией  Сайта без
            какого-либо специального уведомления. Новая редакция Соглашения вступает в силу с момента ее размещения на
            Сайте либо доведения до сведения Пользователя в иной удобной форме, если иное не предусмотрено новой
            редакцией Соглашения.
          </li>
          <li>
            Администрация сайта, равно как и правообладатель Сайта в целом, не несут ответственности за достоверность
            информации, предоставленной предприятием, а также за любой ущерб или упущенную выгоду как Пользователя, так
            и любых третьих лиц, даже если это стало результатом использования либо невозможности использования Сайта.
          </li>
          <li>
            Информация, полученная в результате использования Сайта, не может передаваться другим лицам и использоваться
            для разработки Интернет-ресурса или компьютерной программы, схожей с Сайтом, перепечатки или для
            осуществления других действий, нарушающих авторское право.
          </li>
        </ul>
      ),
    },
    {
      name: t({ id: 'manager.info-terms-rules' }),
      content: (
        <ul>
          <li>К публикации на Сайте принимается информация на румынском и русском языках.</li>
          <li>На Сайте публикуется информация, предоставленная только юридическими лицами.</li>
          <li>
            Предприятие дает свое согласие на размещение информации, представленной в анкете в информационных проектах
            Varo-Inform.Согласие на обработку персональных данных может быть отозвано в любой момент, в соответствии с
            пунктом 2 статьи 5 Закона «О защите персональных данных».
          </li>
          <li>Информация о предприятиях, не являющихся резидентами Республики Молдова, не публикуется.</li>
          <li>
            Рекламная информация предприятий размещается в соответствии с установленными прайс-листами и должна отвечать
            требованиям Закона о рекламе РМ.
          </li>
        </ul>
      ),
    },
    {
      name: t({ id: 'manager.admin-terms-rules' }),
      content: (
        <>
          <b>Администрация сайта оставляет за собой право:</b>
          <div>
            <ul>
              <li>На редактирование информации предприятий в соответствии с рубрикатором сайта.</li>
              <li>
                На изменение требований к содержанию и условиям публикации контактных данных и рекламных продуктов.
              </li>
              <li>
                На отклонение запросов юридических лиц, включая Зарегистрированных пользователей, на добавление или
                редактирование информации в случае их несоответствия Правилам размещения информации на Сайте
              </li>
            </ul>
          </div>
          <b>Доступ к личной информации Зарегистрированных пользователей</b>
          <div>
            <ul>
              <li>
                Вся информация, размещаемая на Сайте, за исключением логина и пароля для доступа к личному кабинету
                Зарегистрированного пользователя и содержащейся в данном разделе информации, является общедоступной.
              </li>
              <li>
                Доступ к изменению информации, содержащейся в кабинете Зарегистрированного пользователя, производится
                только на основе уникального логина и пароля.
              </li>
              <li>
                Логин и пароль являются необходимой и достаточной информацией для пользования сервисами Сайта. 
                Зарегистрированный пользователь несет полную ответственность за  сохранность логина и пароля,
                самостоятельно выбирая способ их хранения.
              </li>
              <li>
                Администрация сайта имеет право хранить информацию обо всех подключениях Зарегистрированных
                пользователей, включая IP-адреса, cookies, адреса запрашиваемых страниц и иную информацию, если такая
                информация была получена в ходе посещения Пользователем Сайта.
              </li>
            </ul>
          </div>
          <b>
            VARO-INFORM S.R.L отказывается от всех видов гарантии, как явных, так и предполагаемых, включая гарантии на
            соответствие Сайта специальным требованиям Пользователя. Все иные вопросы, не урегулированные настоящими
            условиями и правилами, разрешаются в соответствии с действующим законодательством Республики Молдова, а
            также положениями нормативных документов Администрации сайта.
          </b>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <div className="terms-wrapper">
        <div>
          <div>
            <div className="table-title">
              <b>{t({ id: 'manager.terms' })}</b>
            </div>

            <Menu mode="inline" selectable={false} className="terms-and-conditions">
              {indexed(terms).map(item => (
                <Menu.SubMenu
                  key={item.key}
                  title={
                    <span className="item-title">
                      {item.key + 1}. {item.name}
                    </span>
                  }
                >
                  <Menu.Item key={item.key}>
                    <div className="terms-content">{item.content}</div>
                  </Menu.Item>
                </Menu.SubMenu>
              ))}
            </Menu>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TermsPage;
