import React, { useEffect } from 'react';
import moment from 'moment';
import { compose } from 'redux';
import { billing } from '~/libs/http/api';
import { Orders } from '~/components/profile';
import { injectIntl } from '~/libs/localization';
import withRequest from '~/hoc/withRequest';

const formatDate = 'YYYY-MM-DD';

const BillingSummary = ({ t, request, ...props }) => {
  useEffect(() => {
    request(billing.summary());
  }, []);

  const lastUpdate = t('other.yesterday');

  const dateTo = moment().format(formatDate);
  const dateFrom = moment()
    .startOf('month')
    .format(formatDate);

  return (
    <div className="billing-table">
      <Orders
        hideActions
        isCheckable={false}
        data={props.api.data}
        loading={props.api.loading}
        estimatedTotal
        download
        footer={
          <div className="billing-footer">
            *{t('other.lastUpdated')} {lastUpdate}
          </div>
        }
        title={
          <div className="info">
            <div className="title">
              {t('other.monthToDate')} {moment(dateFrom).format('MMM D')} - {moment(dateTo).format('DD, YYYY')}
            </div>

            <div className="description">{t('other.changes')}</div>
          </div>
        }
      />
    </div>
  );
};

const enhance = compose(injectIntl, withRequest());

export default enhance(BillingSummary);
