import React, { useEffect, useState, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Input, Switch, Button, Select, Checkbox } from 'antd';
import { useRequest } from 'estafette';
import { useStateHandlers, useDocumentTitle } from '~/hooks';
import { Loader, Confirmation } from '~/components/ui';
import { ManagementMenu } from '~/components/management';
import { Layout } from '~/components/layouts';
import { validate } from '~/libs/object';
import { getRoute } from '~/libs/router';
import { injectIntl } from '~/libs/localization';
import { managementUsers, profile } from '~/libs/http/api';

const AdminAddUserPage = ({ t, ...props }) => {
  useDocumentTitle(t('page.accounts'));
  const { userId: id, id: account } = useParams();
  const { request: requestPermission, data: dataPermission } = useRequest();
  const { request: requestDetails, loading: loadingDetails } = useRequest({ loading: Boolean(id) });
  const { request: requestSubmit, errors, loading: loadingSubmit } = useRequest();
  const { request: requestDelete, loading: loadingDelete } = useRequest();
  const { request: requestSeniority, data: seniorities, loading: loadingSeniority } = useRequest();

  const history = useHistory();
  const params = useParams();

  const [state, setState] = useStateHandlers({
    firstName: '',
    lastName: '',
    email: '',
    phone: null,
    job: null,
    address: null,
    approved: true,
    active: true,
    staff: false,
    seniority: null,
    perm_types: [],
  });

  const [api, setApi] = useState({ permission_types: [] });
  const [changedPermission, setChangedPermission] = useState([]);

  useEffect(() => {
    requestSeniority(profile.profileSeniority());
  }, []);

  useEffect(() => {
    setChangedPermission(
      (!params.userId ? dataPermission : state.perm_types).map(i => ({ ...i, active: api.perm_type.includes(i.id) })),
    );
  }, [api]);

  useEffect(() => {
    if (id) {
      getDetails();
    }
  }, [id]);

  useEffect(() => {
    if (id && state.perm_types) {
      setApi(prevApi => ({
        ...prevApi,
        perm_type: state.perm_types.filter(item => item.active === true).map(i => i.id),
      }));
    }
  }, [state.perm_types, id]);

  useEffect(() => {
    const onChangeApi = async () => {
      if (params.id) {
        requestPermission(managementUsers.permissionList({ id: params.id }));
      }
    };

    onChangeApi();
  }, [params.id]);

  const onGoBack = () => history.goBack();

  const onSubmit = async ev => {
    ev.preventDefault();

    const options = {
      approved: state.approved,
      is_active: state.active,
      account: account || undefined,
      first_name: state.firstName || undefined,
      last_name: state.lastName || undefined,
      email: state.email || undefined,
      phone: state.phone || undefined,
      address: state.address || undefined,
      is_staff: state.staff,
      seniority_id: state.seniority || undefined,
      job_title: state.job || undefined,
      redirect_url: getRoute('RecoveryPage'),
      perm_types: changedPermission.map(item => ({
        permission_type: item.id,
        active: item.active,
        title: item.codename,
      })),
    };

    id && (options.user_id = id);

    await requestSubmit(managementUsers[id ? 'edit' : 'add'](options));

    history.push(getRoute('AdminUsersPage', { id: account }));
  };

  const onChangeFirstName = ({ target }) => setState({ firstName: target.value });
  const onChangeLastName = ({ target }) => setState({ lastName: target.value });
  const onChangeEmail = ({ target }) => setState({ email: target.value });
  const onChangeAddress = ({ target }) => setState({ address: target.value });
  const onChangePhone = ({ target }) => setState({ phone: target.value });
  const onChangeSeniority = level => setState({ seniority: level });
  const onChangeJob = ({ target }) => setState({ job: target.value });
  const onChangeApproved = approved => setState({ approved });
  const onChangeActive = active => setState({ active });
  const onChangeStaff = staff => setState({ staff });

  const onConfirm = async () => {
    await requestDelete(managementUsers.delete({ id }));

    history.push(getRoute('AdminUsersPage', { id: account }));
  };

  const getDetails = async () => {
    if (id) {
      const res = await requestDetails(managementUsers.getUserById({ id }));

      setState({
        firstName: res.first_name,
        lastName: res.last_name,
        email: res.email,
        phone: res.phone,
        job: res.job_title,
        address: res.address,
        approved: res.is_approved,
        active: res.is_active,
        staff: res.is_staff,
        seniority: res.seniority ? res.seniority.id : null,
        perm_types: res.permission_types,
      });
    }
  };

  const plainOptions = useMemo(
    () => (dataPermission ? dataPermission.map(item => ({ value: item.id, label: item.languages.en })) : []),
    [dataPermission],
  );

  const plainOptions2 = useMemo(
    () => (state.perm_types ? state.perm_types.map(item => ({ value: item.id, label: item.title })) : []),
    [state.perm_types],
  );

  const onChange = newPermType => setApi(prevApi => ({ ...prevApi, perm_type: newPermType }));

  return (
    <Layout containerClass="updatesEdit-container">
      <ManagementMenu />

      <ManagementMenu provider="account" />

      <Loader loading={loadingDetails || loadingSeniority}>
        <div className="half-form">
          <div className="title">{t(`management.${id ? 'update' : 'create'}_account_user`)}</div>

          <Form onSubmit={onSubmit}>
            <Form.Half three>
              <Form.Item label={t('management.accountStatus')}>
                <span className="status-switch" style={{ width: 110 }}>
                  <Switch checked={state.active} size="small" onChange={onChangeActive} />
                  <span className="status-switch-title">
                    {state.active ? t('management.activated') : t('management.activated')}
                  </span>
                </span>
              </Form.Item>

              <Form.Item label={t('management.approvedStatus')}>
                <span className="status-switch" style={{ width: 110 }}>
                  <Switch checked={state.approved} size="small" onChange={onChangeApproved} />
                  <span className="status-switch-title">
                    {state.approved ? t('profile.approved') : t('profile.not_approved')}
                  </span>
                </span>
              </Form.Item>

              <Form.Item label={t('management.admin')}>
                <span className="status-switch" style={{ width: 110 }}>
                  <Switch checked={state.staff} size="small" onChange={onChangeStaff} />
                  <span className="status-switch-title">{state.staff ? t('other.yes') : t('other.no')}</span>
                </span>
              </Form.Item>
            </Form.Half>

            <Form.Half>
              <Form.Item label={t('other.first_name')} {...validate(errors, 'first_name')}>
                <Input placeholder={t('other.first_name')} value={state.firstName} onChange={onChangeFirstName} />
              </Form.Item>

              <Form.Item label={t('other.last_name')} {...validate(errors, 'last_name')}>
                <Input placeholder={t('other.last_name')} value={state.lastName} onChange={onChangeLastName} />
              </Form.Item>
            </Form.Half>

            <Form.Half>
              <Form.Item label={t('other.email')} {...validate(errors, 'email')}>
                <Input placeholder={t('other.email')} value={state.email} onChange={onChangeEmail} />
              </Form.Item>

              <Form.Item label={t('other.telephone')} {...validate(errors, 'telephone')}>
                <Input placeholder={t('other.telephone')} value={state.phone} onChange={onChangePhone} />
              </Form.Item>
            </Form.Half>

            <Form.Item label={t('profile.address')} {...validate(errors, 'address')}>
              <Input placeholder={t('profile.address')} value={state.address} onChange={onChangeAddress} />
            </Form.Item>

            <Form.Half>
              <Form.Item label={t('other.job')} {...validate(errors, 'job_title')}>
                <Input placeholder={t('other.job')} value={state.job} onChange={onChangeJob} />
              </Form.Item>

              <Form.Item label={t('profile.level')} {...validate(errors, 'seniority_id')}>
                <Select
                  showSearch
                  loading={loadingSeniority}
                  placeholder={t({ id: 'profile.level' })}
                  optionFilterProp="children"
                  value={state.seniority}
                  onChange={onChangeSeniority}
                >
                  {seniorities.map(level => (
                    <Select.Option key={level.id} value={level.id}>
                      {level.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Form.Half>

            <Form.Item>
              <div className="sub-title">{t('profile.accessSettings')}</div>
              <div className="col">
                <Checkbox.Group
                  options={params.userId ? plainOptions2 : plainOptions}
                  onChange={onChange}
                  value={api.perm_type}
                  className="access-settings-group"
                />
              </div>
            </Form.Item>

            <div className="half-form-footer">
              {id && (
                <Confirmation type="delete" onConfirm={onConfirm}>
                  <Button loading={loadingDelete} className="cancel-btn">
                    {t('other.delete')}
                  </Button>
                </Confirmation>
              )}

              <div className="half-form-footer-right">
                <Button onClick={onGoBack}>{t('billing.back')}</Button>

                <Button type="primary" htmlType="submit" loading={loadingSubmit || loadingDelete}>
                  {t('other.submit')}
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Loader>
    </Layout>
  );
};

export default injectIntl(AdminAddUserPage);
