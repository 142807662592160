import React from 'react';

const Marker = () => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="10px" height="14px">
    <path
      fillRule="evenodd"
      fill="rgb(52, 111, 147)"
      d="M4.987,-0.000 C2.224,-0.000 -0.010,2.358 -0.010,5.274 C-0.010,6.792 0.708,8.166 1.416,9.435 C2.280,11.011 3.314,12.481 4.515,13.785 C4.773,14.069 5.200,14.069 5.469,13.785 C6.525,12.682 7.457,11.438 8.243,10.110 C9.085,8.676 9.995,7.017 9.995,5.274 C9.983,2.370 7.749,-0.000 4.987,-0.000 ZM4.987,8.333 C3.314,8.333 1.955,6.898 1.955,5.132 C1.955,3.366 3.314,1.932 4.987,1.932 C6.660,1.932 8.018,3.354 8.018,5.132 C8.018,6.910 6.660,8.333 4.987,8.333 Z"
    />
  </svg>
);

Marker.Small = () => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fal"
    data-icon="map-marker-alt"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 384 512"
    width="23"
    height="18"
  >
    <path
      fill="#1987c5"
      d="M192 96c-52.935 0-96 43.065-96 96s43.065 96 96 96 96-43.065 96-96-43.065-96-96-96zm0 160c-35.29 0-64-28.71-64-64s28.71-64 64-64 64 28.71 64 64-28.71 64-64 64zm0-256C85.961 0 0 85.961 0 192c0 77.413 26.97 99.031 172.268 309.67 9.534 13.772 29.929 13.774 39.465 0C357.03 291.031 384 269.413 384 192 384 85.961 298.039 0 192 0zm0 473.931C52.705 272.488 32 256.494 32 192c0-42.738 16.643-82.917 46.863-113.137S149.262 32 192 32s82.917 16.643 113.137 46.863S352 149.262 352 192c0 64.49-20.692 80.47-160 281.931z"
    />
  </svg>
);

export default Marker;
