import React from 'react';
import { cleanUnderscore } from '~/libs/string';
import { injectIntl } from '~/libs/localization';
import { number } from '~/libs/number';
import { has } from '~/libs/object';
import { providers } from './ExportModal';
import { Icon } from '~/components/ui/Icon/Icon';

const ExportDetailsModal = ({ renderTotal, provider, limitLoading, limits = {}, t, ...props }) => (
  <div className=" export-column-wrapper">
    <div className="export-right-column">
      <span className="title">{t('company.summary')}</span>

      <div className="sum-wrapper">
        <ul className="credits-list">
          {has(limits, `fields.${providers[provider].header}`) && (
            <>
              <span className="sum-title">Total {cleanUnderscore(provider)} info</span>
              <span className={`sum ${limitLoading ? 'grey' : ''}`}>
                {number(limits.total[providers[provider].header]) || 0}
              </span>
            </>
          )}

          {limitLoading && Object.keys(has(limits, 'fields', {})).length === 0 ? (
            <li className="grey zh-iconed-text">
              <Icon type="loading" />
              {t('company.summary_loading')}
            </li>
          ) : null}

          {renderTotal()}
        </ul>

        {props.paymentRequired ? (
          <>
            <div className="sum-payment">
              <div>
                <span>Total</span>

                <span>
                  {number(limits.total_price)} {limits.currency}
                </span>
              </div>
            </div>

            {/* <div className="modal-footer-alert">
              <span>
                {t('other.addToCart')}&nbsp;
                <span className="link bold" onClick={props.onAddCart}>
                  {t('other.addOrder')}
                </span>
              </span>
            </div> */}
          </>
        ) : null}
      </div>
    </div>
  </div>
);

export default injectIntl(ExportDetailsModal);
