import { lazy as loadable } from 'react';
import env from '@beam-australia/react-env';

export const lazyRouter = page =>
  env('NODE_ENV') === 'production'
    ? loadable(() =>
        import(
          /* webpackMode: "lazy" */
          `../pages/${page}`
        ),
      )
    : loadable(() =>
        import(
          /* webpackMode: "eager" */
          `../pages/${page}`
        ),
      );
