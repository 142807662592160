const initialState = {
  loading: false,
  id: null,
  data: {},
};

export const subdivision = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_SUBDIVISION_LOADING':
      return { ...state, loading: action.payload };
    case 'RESET_SUBDIVISION':
      return { ...state, loading: true, id: null };
    case 'GET_SUBDIVISION':
      return Object.assign({}, state, {
        id: action.payload.id,
        data: action.payload.data,
        loading: false,
      });
    default:
      return state;
  }
};
