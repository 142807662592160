import moment from 'moment';

export const formatDate = 'DD-MM-YYYY';
export const formatDateTime = 'DD-MM-YYYY HH:mm';
export const formatDateText = 'LL';
export const formatMonth = 'MMMM';
export const formatMonthYear = 'MMMM YYYY';
export const formatMonthDoYear = 'MMMM Do YYYY';
export const formatYear = 'YYYY';

export const pureToday = moment(new Date());

export const today = moment(new Date()).format(formatDate);

export const tomorrow = moment(new Date())
  .add(1, 'days')
  .format(formatDate);

export const twentyMinutesinSeconds = 1200;

export const yesterday = moment(new Date())
  .add(-1, 'days')
  .format(formatDate);

export const month = moment()
  .add(1, 'M')
  .format(formatDate);

export const monthAgo = moment()
  .add(-1, 'M')
  .format(formatDate);

export const parseMonthLess = (value, conditional) =>
  moment()
    .month(value - conditional)
    .format(formatMonth);

export const getMonth = m => {
  let name = '';

  if (m === 1) {
    name = 'management.oneMonth';
  } else if (m > 1 && m < 12) {
    name = `${m} months`;
  } else if (m === 12) {
    name = 'management.oneYear';
  }

  return name;
};

export const parseDate = (date, format) => moment(date, format).format(formatDate);
export const parseDateText = (date, format) => moment(date, format).format(formatDateText);
export const parseDateNow = (date, format) => moment(date, format).fromNow();
export const parseDateTime = (date, format) => moment(date, format).format(formatDateTime);
export const parseMonth = (date, format) => moment(date, format).format(formatMonth);
export const parseMonthYear = (date, format) => moment(date, format).format(formatMonthYear);
export const parseYear = (date, format) => moment(date, format).format(formatYear);

export const getPeriodByInterval = (interval = {}, defaultText = 'management.results') => {
  const periods = [];

  if (Object.keys(interval).length > 0) {
    const [from, to] = [parseDate(interval.date_from), parseDate(interval.date_to)];

    switch (from) {
      case today:
        periods.push('management.today');
        break;
      case tomorrow:
        periods.push('management.tomorrow');
        break;
      case yesterday:
        periods.push('management.yesterday');
        break;
      case monthAgo:
        periods.push('management.monthAgo');
        break;
      case month:
        periods.push('management.nextMonth');
        break;
      default:
        periods.push(from);
        break;
    }

    switch (to) {
      case today:
        periods.push('management.today');
        break;
      case tomorrow:
        periods.push('management.tomorrow');
        break;
      case yesterday:
        periods.push('management.yesterday');
        break;
      case monthAgo:
        periods.push('management.monthAgo');
        break;
      case month:
        periods.push('management.nextMonth');
        break;
      default:
        periods.push(to);
        break;
    }
  }

  return periods.length > 0
    ? periods[0] !== periods[1]
      ? { from: periods[0], until: periods[1] }
      : periods[0]
    : defaultText;
};
