import React, { useEffect } from 'react';
import { useRequest } from 'estafette';
import { useDispatch } from 'react-redux';
import { getParams } from '~/store/actions/AuthenticationActions';
import { useStateHandlers } from '~/hooks';
import { withQueryValidation } from '~/hoc';
import { subscription } from '~/libs/http/api';
import { notification } from '~/libs/notification';
import { useInjectIntl } from '~/libs/localization';
import { has } from '~/libs/object';
import { getPercentage } from '~/libs/number';
import moment from '~/libs/moment';
import { ProfileLayout } from '~/components/layouts';
import { ProgressBar, Rows } from '~/components/ui';
import { PaymentData } from '~/components/cart/payment';
import NotFoundPage from '~/pages/NotFoundPage';

import '~/assets/scss/components/_MyProfile.scss';
import '~/assets/scss/pages/_Metrics.scss';

let interval;
const MetricsPage = props => {
  const { t } = useInjectIntl();
  const dispatch = useDispatch();
  const { request: requestPackages, loading: loadingPackages } = useRequest({ loading: true });
  const { request: requestMetrics, data: metrics, loading: loadingMetrics } = useRequest({ loading: true });
  const [state, setState] = useStateHandlers({
    upgradeId: null,
    activePackage: {},
    updatedDate: moment(new Date()).fromNow(),
    upgradeModal: false,
    metricsChanged: false,
  });

  useEffect(() => {
    onFetch();

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (state.activePackage) {
      clearInterval(interval);
      interval = setInterval(() => requestMetrics(subscription.metrics()), 5000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [state.activePackage]);

  const onFetch = async () => {
    const res = await Promise.all([requestPackages(subscription.packages()), requestMetrics(subscription.metrics())]);

    setState({ metricsChanged: true });

    if (res && res.length > 0) {
      const activePackage = res[0].find(({ owned }) => owned);
      setState({ activePackage });
    }
  };

  const onSuccessUpgrade = () => {
    setState({ upgradeId: null, upgradeModal: false });

    onFetch();

    dispatch(getParams());

    notification({
      type: 'success',
      title: t('notification.metrics.title'),
      message: t('notification.metrics.message'),
    });
  };

  // const onUpgrade = useCallback(upgradeId => setState({ upgradeId, upgradeModal: true }), []);
  const onCloseUpgradeModal = () => setState({ upgradeId: null, upgradeModal: false });
  // const checkDisable = item => item.price <= has(state, 'activePackage.price', {});

  return (
    <>
      {state.upgradeModal && (
        <PaymentData
          modal
          id={state.upgradeId}
          parent="applications"
          redirect="MetricsPage"
          onClose={onCloseUpgradeModal}
          onSuccess={onSuccessUpgrade}
        />
      )}

      <ProfileLayout loading={(loadingMetrics && !state.metricsChanged) || loadingPackages}>
        {state.activePackage && (
          <Rows
            rows={[
              {
                label: t('metrics.title'),
                value: state.activePackage.title,
              },
              {
                label: t('metrics.price'),
                value: state.activePackage ? `${state.activePackage.price || 0} MDL` : null,
              },
              {
                label: t('metrics.frequency'),
                value: t(`metrics.frequency-${has(state, 'activePackage.frequency', 'month')}`),
              },
            ]}
          />
        )}

        {/* {packages.length > 0 ? (
          <div className="product-access-list">
            <div className="packages-list">
              {packages.map(item => (
                <div className="package-item" key={item.id}>
                  <div className="title">{item.title}</div>

                  <ul>
                    {item.public_description &&
                      item.public_description.map(description => (
                        <li key={description}>
                          <Check /> {description}
                        </li>
                      ))}
                  </ul>

                  <div className="package-price">
                    <span className="package-price-currency">MDL</span>
                    <span className="package-price-price">{number(item.price)}</span>
                    <span className="package-price-frequency">/ {t(`management.${item.frequency}`)}</span>
                  </div>

                  <Button disabled={checkDisable(item)} onClick={() => onUpgrade(item.id)}>
                    <span>{t('management.select')}</span>
                  </Button>
                </div>
              ))}
            </div>
          </div>
        ) : null} */}

        {state.activePackage && (
          <div className="zh-metrics">
            <div className="zh-metrics-title">
              <span>{t('metrics.daily_limits')}</span>

              <span>{t('metrics.monthly_limits')}</span>
            </div>

            {metrics.length > 0 ? (
              metrics.map((item, key) => {
                const fieldName = t({ id: `metrics.${item.codename}` });

                return (
                  <div className="zh-metric" key={item.id}>
                    <div className="zh-daily-metric">
                      <b>{fieldName}</b>{' '}
                      {t('metrics.day_limit', {
                        min: item.daily ? item.daily.used : 0,
                        max: item.daily ? item.daily.total : 0,
                      })}
                      <ProgressBar
                        progressIndicator
                        progress={getPercentage(
                          (item.daily && item.daily.used) || 0,
                          (item.daily && item.daily.total) || 0,
                        )}
                      />
                    </div>

                    <div className="zh-monthly-metric">
                      <b>{fieldName}</b>{' '}
                      {t('metrics.month_limit', {
                        min: item.limit ? item.limit.used : 0,
                        max: item.limit ? item.limit.total : 0,
                      })}
                      <ProgressBar
                        progressIndicator
                        progress={getPercentage(item.limit ? item.limit.used : 0, item.limit ? item.limit.total : 0)}
                      />
                    </div>
                  </div>
                );
              })
            ) : (
              <NotFoundPage />
            )}

            {metrics.length > 0 && (
              <div className="zh-metric-update">
                {t('metrics.last_update')}: {state.updatedDate}
              </div>
            )}
          </div>
        )}
      </ProfileLayout>
    </>
  );
};

export default withQueryValidation({ buttonText: 'Find out' })(MetricsPage);
