export const drop = (arr, key) => {
  const newArr = arr.slice();

  newArr.splice(key, 1);

  return newArr;
};

export const addOrRemove = (array, item) => {
  const exists = array.includes(item);

  if (exists) {
    return array.filter(c => c !== item);
  }

  const result = array.slice();
  result.push(item);

  return result;
};

export const setAttachments = (arr, attachments) => {
  const newArr = [];

  arr.map(item =>
    newArr.push({
      ...item,
      ...attachments,
    }),
  );

  return newArr;
};

export const add = (arr, item) => {
  const newArr = arr.slice();

  newArr.unshift(item);

  return newArr;
};

export const push = (arr, item) => [...arr, ...item];

export const indexed = arr => arr && arr.map((item, key) => ({ ...item, key }));

export const indexedFirst = arr => arr && arr.map((item, key) => ({ ...item, key: key + 1 }));

export const excludedValues = (arr, field, items) => arr.every(item => !items.includes(item[field]));
