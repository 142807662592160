import React, { useState, useEffect } from 'react';
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import { getRoute, getRouteByPath } from '~/libs/router';
import { parseQuery, parseObject } from '~/libs/http/url';
import { ValidationModal } from '~/components/modal';

const withQueryValidation = (options = {}) => Component => () => {
  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const [error, setError] = useState('');

  useEffect(() => {
    const onCheckErrors = () => {
      const { [options.target || 'detail']: target, ...query } = parseQuery(location.search);

      if (target) {
        setError(target);
        history.push(`${getRoute(getRouteByPath(match.path, match.params), match.params)}${parseObject(query)}`);
      }
    };

    onCheckErrors();
  }, [error]);

  const onCloseValidation = () => setError('');

  return (
    <>
      {error ? (
        <ValidationModal type="error" message={error} onClose={onCloseValidation} buttonText={options.buttonText} />
      ) : null}

      <Component />
    </>
  );
};

export default withQueryValidation;
