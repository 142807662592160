import React from 'react';
import { useInjectIntl } from '~/libs/localization';
import { Payment } from '~/components/svg';
import { Button, Modal } from '~/components/ui';

import '~/assets/scss/components/ui/_ValidationModal.scss';

const ValidationModal = ({ type = 'success', onClick, onClose, ...props }) => {
  const { t } = useInjectIntl();

  return (
    <Modal onClose={onClose} className="modal-nofooter" width={500} footer={false}>
      <div className="success-modal-wrapper">
        {type === 'success' && <Payment.Success />}
        {type === 'error' && <Payment.Fail />}

        <div className="title">{props.message}</div>
        {!props.customButton ? (
          <Button fail onClick={onClick || onClose}>
            <span>{props.buttonText || t('other.try_again')}</span>
          </Button>
        ) : (
          props.customButton
        )}
      </div>
    </Modal>
  );
};

export default ValidationModal;
