import axios from '~/libs/http/axios';
import { setCancelToken } from '~/libs/http/cancellation';

const { baseURL } = axios.defaults;

const company = {
  search: (params = {}) => axios.get(`${baseURL}/api/public/search`, { params, ...setCancelToken('company.search') }),
  details: (params = {}) => axios.get(`${baseURL}/api/companies/lists`, { params }),
  employee: (params = {}) => axios.get(`${baseURL}/api/companies/employees`, { params }),
  employees: (option = {}) => axios.post(`${baseURL}/api/companies/employees`, option),
  list: (option = {}) => axios.post(`${baseURL}/api/companies/lists`, option),
  companiesSearch: (params = {}) => axios.get(`${baseURL}/api/companies/search`, { params }),
  subdivision: (params = {}) => axios.get(`${baseURL}/api/companies/subdivision`, { params }),
  update: (option = {}) => axios.post(`${baseURL}/api/companies/update`, option),
  getCompanyDetails: option => axios.get(`${baseURL}/api/companies/lists?id=${option}`, option),
  getCompanyHistory: (params = {}) => axios.get(`${baseURL}/api/companies/history/company`, { params }),
  addTopCompany: (params = {}) => axios.post(`${baseURL}/api/management/enterprise/top`, params),
  topCompany: (params = {}) => axios.get(`${baseURL}/api/public/companies/top`, params),
  companyInfoYears: (params = {}) => axios.get(`${baseURL}/api/public/companies/top`, params),
  changeSettings: (params = {}) => axios.post(`${baseURL}/api/management/enterprise/statistics`, params),
  publicSettings: (params = {}) => axios.get(`${baseURL}/api/public/companies/statistics/schedule/languages`, params),
  fiveLastCompanies: (params = {}) => axios.get(`${baseURL}/api/companies/last/five`, params),
};

export default company;
