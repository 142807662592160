import React, { useState, useEffect } from 'react';
import { number } from '~/libs/number';

const Counter = ({ total }) => {
  const [counter, setCounter] = useState(0);
  const step = Math.ceil(total / 300);

  useEffect(() => {
    const tick = () => {
      if (typeof total === 'number') {
        if (counter < total && total - step > counter) {
          setCounter(counter + step);
        } else {
          setCounter(total);
        }
      }
    };

    tick();
  }, [counter]);

  return <span>{number(counter)}</span>;
};

export default Counter;
