import React, { useState, useEffect } from 'react';
import { Menu, Button } from 'antd';
import { useDispatch } from 'react-redux';
import { useRequest } from 'estafette';
import { setActId } from '~/store/actions/legalBulletinActions';
import { legalBulletin } from '~/libs/http/api';
import { useInjectIntl } from '~/libs/localization';
import { Icon } from '~/components/ui/Icon/Icon';
import { Loader } from '~/components/ui';
import { LegalBulletinModalAddFolder } from './LegalBulletinModalAddFolder';
import { LegalBulletinModalEditFolder } from './LegalBulletinModalEditFolder';

const SidebarLegalBulletinActs = () => {
  const { t } = useInjectIntl();
  const { request: requestFolderList, data: folderList, loading: loadingFolderList } = useRequest({ folderList: {} });
  const { request: requestFolderItems, loading: loadingDeleteRefetch } = useRequest();
  const dispatch = useDispatch();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const [folderEditName, setFolderEditName] = useState(null);
  const [editFolderType, setEditFolderType] = useState(null);
  const [openFolder, setOperFolder] = useState(null);

  useEffect(() => {
    requestFolderList(legalBulletin.getFolderList());
  }, []);

  useEffect(() => {
    if (folderList.data && folderList.data.length > 1) {
      dispatch(setActId(folderList.data[0].id));
      setOperFolder(`${folderList.data[0].id}`);
    }
  }, [folderList, loadingDeleteRefetch]);

  useEffect(() => {
    if (folderList.data && editFolderType) {
      const getName = folderList.data.find(item => item.id === editFolderType);
      setFolderEditName(getName.title);
    }
  }, [isModalEditVisible]);

  const onRefetch = () => requestFolderList(legalBulletin.getFolderList());

  const onRefetchDelete = () => {
    setOperFolder(null);

    requestFolderList(legalBulletin.getFolderList());
    requestFolderItems(legalBulletin.getActList());
  };

  const onActItemId = id => {
    dispatch(setActId(id));
    setOperFolder(`${id}`);
  };

  const showModalEditAct = id => {
    setEditFolderType(id);
    setIsModalEditVisible(true);
  };

  const editCancel = () => {
    setFolderEditName(null);
    setIsModalEditVisible(false);
  };

  const showModalAct = () => setIsModalVisible(true);
  const handleCancel = () => setIsModalVisible(false);

  return (
    <div className="wrap-sidebar-legal-bulletin">
      {isModalVisible && <LegalBulletinModalAddFolder handleCancel={handleCancel} onRefetch={onRefetch} />}

      {isModalEditVisible && (
        <LegalBulletinModalEditFolder
          onRefetch={onRefetch}
          onRefetchDelete={onRefetchDelete}
          handleCancel={editCancel}
          folderEditName={folderEditName}
          type={editFolderType}
        />
      )}

      <div className="sidebar-legal-bulletin sidebar-legal-bulletin-acts">
        <div className="main-title">{t('legalBulletin.startBrowsing')}</div>

        <Loader loading={loadingFolderList}>
          {folderList ? (
            <Menu className="legal-menu" mode="inline" selectedKeys={[openFolder]} forceSubMenuRender>
              {folderList.data &&
                folderList.data.map(item => (
                  <Menu.Item onClick={() => onActItemId(item.id)} key={item.id}>
                    <Icon type="bulletin-menu-folder" />

                    <span className="act-folder">{item.title}</span>

                    <Icon type="edit" onClick={() => showModalEditAct(item.id)} />
                  </Menu.Item>
                ))}

              <div className="add-list-item">
                <Button className="link-list" onClick={showModalAct}>
                  <Icon type="plus" />

                  {t('monitorig.sidebar.addTheList')}
                </Button>
              </div>
            </Menu>
          ) : null}
        </Loader>
      </div>
    </div>
  );
};

export default SidebarLegalBulletinActs;
