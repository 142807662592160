import React, { useState } from 'react';
import { Select, message } from 'antd';
import { useInjectIntl } from '~/libs/localization';
import { company } from '~/libs/http/api';
import { indexed } from '~/libs/array';
import { Link } from '~/libs/router';
import { Text, Button } from '~/components/ui';
import { SearchItem } from '~/components/search';
import { Icon } from '~/components/ui/Icon/Icon';

import '~/assets/scss/components/_SearchBox.scss';

const SearchBox = ({ placeholder, helpNode, onSetIdno }) => {
  const { t } = useInjectIntl();
  const [results, setResults] = useState([]);
  const [total, setTotal] = useState(0);
  const [value, setValue] = useState('');

  const onSearch = (newValue = '') => {
    setValue(newValue);

    return (
      newValue &&
      company.list({ page: 1, per_page: 3, filters: { must: { company_name: newValue } } }).then(({ data }) => {
        setResults(indexed(data.data));
        setTotal(data.total_results);
      })
    );
  };

  const onSelect = async (newValue = '') => {
    const { id, idno } = results.find(item => item.name === newValue) || {};

    if (!id) {
      setValue('');
    }

    onSetIdno({ id, idno });
  };

  const onAdd = () => {
    if (!value) {
      message.config({ top: 100 });
      message.error(t('other.firstEnterQuery'));
    } else if (results.length === 0) {
      message.config({ top: 100 });
      message.error(t('filter.emptySearch'));
    } else {
      setValue('');
      onSetIdno({ id: results[0].id, idno: results[0].idno });
    }
  };

  return (
    <>
      <div className="center-search">
        <div className="search-box search-size-default">
          <Select
            allowClear={!helpNode}
            showSearch
            mode="combobox"
            filterOption={false}
            onSearch={onSearch}
            onSelect={onSelect}
            value={value}
            placeholder={t(placeholder)}
          >
            {value &&
              results.map(result => (
                <Select.Option key={`company-${result.id.toString()}`} value={result.name}>
                  <SearchItem.Fragment company={result.name} activity={result.industry} />
                  {result.name}
                </Select.Option>
              ))}

            {!total && value && (
              <Select.Option key="empty-value" value={value} disabled>
                <SearchItem.Empty title={<Text id="filter.emptySearch" />} />
              </Select.Option>
            )}
          </Select>

          {helpNode && (
            <Link route="HomePage" className="help-link">
              <Icon type="search-help" className="zh-icon icon-size-small" />
            </Link>
          )}

          <Icon type="menu-find" className="zh-icon icon-size-small zh-icon-search-billing" />
        </div>
      </div>

      <Button type="primary" className="search-button" onClick={onAdd}>
        {t('search.add')}
      </Button>
    </>
  );
};

export default SearchBox;
