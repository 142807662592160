import React from 'react';
import classnames from 'classnames';
import { Input, Radio, message, Alert } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import { compose } from 'redux';
import { connect } from 'react-redux';
import withRequest from '~/hoc/withRequest';
import { ValidationModal } from '~/components/modal';
import { injectIntl } from '~/libs/localization';
import { lists } from '~/libs/http/api';
import { number } from '~/libs/number';
import { Modal, Button, Loader } from '~/components/ui';
import { Filter } from '~/components/svg';
import { Time } from '~/components/other';
import { Icon } from '~/components/ui/Icon/Icon';

import '~/assets/scss/components/ui/_ManageListSearch.scss';

const dateNow = new Date();

class ManageListSearch extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      loading: true,
      createList: false,
      listName: '',
      lists: {
        pages: 0,
        data: [],
      },
      foundLists: [],
      list: null,
      error: '',
      search: '',
    };
  }

  async componentWillMount() {
    const { props } = this;
    const count = props.data.length || props.total;

    if (props.type !== 'search' && count >= props.limits.save_lists) {
      this.setState({
        error: props.t('list.add_list_limits', {
          limit: number(props.limits.save_lists),
          type: props.t(`list.${props.type === 'employee' ? 'employee' : 'company'}`).toLowerCase(),
          current: number(count),
        }),
      });
    }

    const { data = [] } = await this.onFetch();

    if (props.type === 'search' || data.length === 0) {
      this.onCreateList();
    }
  }

  componentWillUnmount() {
    this.timer && clearTimeout(this.timer);
  }

  onFetch = async (page = 1) => {
    const isSearch = this.props.type === 'search';

    this.setState({ page, loading: true });

    const { data } = await lists[isSearch ? 'search' : 'list']({
      page,
      per_page: isSearch ? 4 : 10,
      type: this.props.type === 'subdivision' ? 'company' : this.props.type,
      ...(this.state.search ? { search: this.state.search } : {}),
    });

    this.setState(prevState => ({
      lists: {
        pages: data.pages,
        data: page !== 1 ? [...prevState.lists.data, ...data.data] : data.data,
      },
      loading: false,
    }));

    return data;
  };

  onSubmit = async (ev = {}) => {
    ev.preventDefault && ev.preventDefault();

    const options = {
      type: this.props.type,
      filters: {},
    };
    this.state.list && (options.id = this.state.list);
    this.state.listName && (options.name = this.state.listName);

    if (!this.props.data.length) {
      options.filters.must = this.props.activeFilter;
    } else {
      options.filters[this.props.type === 'employee' ? 'employees' : 'companies'] = this.props.data;
    }

    try {
      await this.props.request(lists.add(options));

      message.success(this.props.t('other.success.added'));

      if (this.props.onCloseAll) {
        this.props.onCloseAll();
      }

      this.props.onClose();
    } catch ({ response }) {
      if (response.data.filters) {
        this.setState({ error: response.data.filters[0] });
      }
    }
  };

  onSearch = async ({ target }) => {
    this.setState({ list: null });
    await this.setState({ search: target.value });

    this.timer && clearTimeout(this.timer);

    if (target.value === '' || (target.value.length >= 2 && target.value.length <= 30)) {
      this.timer = setTimeout(() => {
        this.onFetch(1, target.value);
      }, 400);
    }
  };

  onClose = () => {
    if (this.props.type !== 'search' && this.state.createList) {
      return this.onCloseCreateList();
    }

    return this.props.onClose();
  };

  onCreateList = () => this.setState(({ search }) => ({ createList: true, listName: search, list: 0 }));

  onCloseCreateList = () => this.props.type !== 'search' && this.setState({ createList: false });

  onChangeListName = ({ target }) => this.setState({ listName: target.value });

  render() {
    const { props, state } = this;
    const { t } = props;
    const isSearch = props.type === 'search';
    const hasMore = state.page < state.lists.pages;

    const classLoading = state.loading ? 'skeleton skeleton-text' : '';

    const titles = {
      company: t('list.add_list'),
      employee: t('list.add_employee_list'),
    };

    if (state.error) {
      return <ValidationModal type="error" message={state.error} onClose={props.onClose} />;
    }

    return (
      <Modal
        className="add-company-modal"
        clean
        title={isSearch ? t('list.add_search') : titles[props.type]}
        onClose={props.onClose}
        footer={
          <>
            {state.createList ? (
              <Button className="pull-left" onClick={this.onClose}>
                {t('other.reset')}
              </Button>
            ) : (
              <Button className="create-btn" onClick={this.onCreateList} disabled={state.createList}>
                <Icon type="list-default" />
                {t('list.create_list')}
              </Button>
            )}

            {state.list || state.createList ? (
              <Button fade type="primary" onClick={this.onSubmit} style={{ display: state.list ? 'block' : 'none' }}>
                {t('list.add_to_list')}
              </Button>
            ) : (
              <p>{t('list.select_list')}</p>
            )}
          </>
        }
      >
        {(!isSearch || !state.createList) && (
          <Input placeholder={t('filter.searchTheList')} onChange={this.onSearch} disabled={state.createList} />
        )}

        {state.createList && props.api.errors.name && <Alert message={props.api.errors.name} />}

        <Loader loading={state.loading && state.lists.data.length === 0}>
          <div className="add-company-list">
            <InfiniteScroll
              initialLoad={false}
              pageStart={1}
              loadMore={this.onFetch}
              hasMore={hasMore}
              useWindow={false}
            >
              <Radio.Group
                value={this.state.list}
                onChange={e => this.setState({ list: e.target.value })}
                className={classnames({ 'add-company-list-unactive': state.createList })}
              >
                {state.createList && (
                  <Radio value={0}>
                    <form onSubmit={this.onSubmit}>
                      <span className="radio-flex">
                        <Filter.Circle>
                          <Icon type="filter-list" />
                        </Filter.Circle>

                        <span className="radio-flex-content">
                          <h2>
                            <input
                              type="text"
                              placeholder={isSearch ? t('list.search_name_type') : t('list.list_name_type')}
                              className="zh-input-hidden"
                              // eslint-disable-next-line jsx-a11y/no-autofocus
                              autoFocus={!state.loading}
                              value={state.listName}
                              onChange={this.onChangeListName}
                            />
                          </h2>

                          <small>
                            {isSearch ? t('list.search') : t('list.list')} <span className="span-separator">•</span>
                            {t('list.create')} <Time date={dateNow} format="long" />
                          </small>
                        </span>
                      </span>
                    </form>
                  </Radio>
                )}

                {!state.createList && state.lists.data.length === 0 && (
                  <Icon type="list-empty" label={t('list.empty_by_search')} />
                )}

                {state.lists.data.map(list => (
                  <Radio key={list.id} value={list.id} disabled={state.createList}>
                    <span className="radio-flex">
                      <Filter.Circle>
                        <Icon type="filter-list" />
                      </Filter.Circle>

                      <span className="radio-flex-content">
                        <h2 className={state.createList ? 'disabled' : 'enabled'}>
                          <span className={classLoading}>{list.name}</span>
                        </h2>

                        <small className={classLoading}>
                          {list.companies ? (
                            <>
                              {list.companies} {t('list.company')} <span className="span-separator">•</span>
                            </>
                          ) : null}
                          {t('list.created1')} <Time date={list.date_created} format="long" />
                        </small>
                      </span>
                    </span>
                  </Radio>
                ))}
              </Radio.Group>
            </InfiniteScroll>
          </div>
        </Loader>
      </Modal>
    );
  }
}

const enhance = compose(
  injectIntl,
  connect(({ filter, searches, limits }) => ({
    activeFilter: filter.activeFilter,
    total: searches.total,
    limits: limits.limits,
  })),
  withRequest(),
);

export default enhance(ManageListSearch);
