const isActive = obj => obj !== null;

const isJuridicalActive = (obj, key) => obj && obj[key].length >= 1;

const initialState = {
  loading: false,
  id: null,
  data: {},
  availability: {},
};

export const company = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_COMPANY_LOADING':
      return { ...state, loading: action.payload };
    case 'RESET_COMPANY':
      return { ...state, loading: true, id: null };
    case 'RESET_AVAILABILITY':
      return { ...state, availability: {} };
    case 'GET_COMPANY':
      return Object.assign({}, state, {
        loading: false,
        id: action.payload.id,
        data: action.payload.data,
        availability: {
          generals: isActive(action.payload.data.general_data),
          personal: isActive(action.payload.data.personal),
          subdivisions: isActive(action.payload.data.children),
          economy: isActive(action.payload.data.financial_data),
          publications: isActive(action.payload.data.publications),
          juridical: {
            insolvency: isJuridicalActive(action.payload.data.juridical, 'insolvency'),
            jurisprudence: isJuridicalActive(action.payload.data.juridical, 'jurisprudence'),
            reorganization: isJuridicalActive(action.payload.data.juridical, 'reorganisation'),
          },
        },
      });
    default:
      return state;
  }
};
