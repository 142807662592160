import { adapterIntl } from '~/libs/localization/helpers';

const prospectList = adapterIntl({
  'prospectList.founders': {
    ro: 'Fondatori compananiei',
    ru: 'Основатели компании',
    en: 'Founders of the company',
  },
  'prospectList.seniority': {
    ro: 'Stagiu',
    ru: 'Трудовой стаж',
    en: 'Seniority',
  },
  'prospectList.function': {
    ro: 'Funcție',
    ru: 'Функция',
    en: 'Function',
  },
  'prospectList.status': {
    ro: 'Status',
    ru: 'Статус',
    en: 'Status',
  },
  'prospectList.date_of_establishment': {
    ro: 'Data înfiinţării',
    ru: 'Дата создания',
    en: 'Date of establishment',
  },
  'prospectList.nr_employees': {
    ro: 'Nr. de angajați',
    ru: 'Количество сотрудников',
    en: 'Nr. of the employees',
  },
  'prospectList.turnover': {
    ro: 'Cifra da afaceri',
    ru: 'Оборот',
    en: 'Turnover',
  },
  'prospectList.industry': {
    ro: 'Industrie',
    ru: 'Отрасль',
    en: 'Industry',
  },
  'prospectList.contacts': {
    ro: 'Contacte',
    ru: 'Контакты',
    en: 'Contacts',
  },
});

export default prospectList;
