import React from 'react';
import { Form, message } from 'antd';
import { setDocumentTitle } from '~/hooks';
import { auth } from '~/libs/http/api';
import { parseQuery } from '~/libs/http/url';
import { injectIntl } from '~/libs/localization';
import { getRoute } from '~/libs/router';
import { Header, Footer, Button, InputPassword } from '~/components/public';
import { Auth } from '~/components/ui';

class RecoveryPage extends React.PureComponent {
  state = {
    password: '',
    passwordConf: '',
    errors: {},
    loading: false,
  };

  componentDidMount() {
    setDocumentTitle(this.props.t('page.recovery'));
  }

  submit(e) {
    e.preventDefault();

    this.setState({ loading: true });

    const query = parseQuery(this.props.location.search);

    auth
      .confirmResetPassword({
        token: query.token,
        password: this.state.password,
        repeat_password: this.state.passwordConf,
      })
      .then(() => {
        message.success(this.props.t({ id: 'statics.passwordChanged' }));

        return this.props.history.push(getRoute('SigninPage'));
      })
      .catch(({ response }) => this.setState({ loading: false, errors: response.data }));
  }

  render() {
    const { t } = this.props;

    return [
      <Header />,
      <Auth.Container
        wide
        key="container"
        loading={this.state.loading}
        header={
          <div>
            <h1 className="auth-wide-h1">{t({ id: 'statics.recoveryPassword' })}</h1>
          </div>
        }
        // footer={<Link route="HomePage">{t({ id: 'statics.needHelp' })}</Link>}
      >
        <Form onSubmit={e => this.submit(e)}>
          <Form.Error message={this.state.errors.non_field_errors || this.state.errors.token} />

          <Form.Item
            label={t({ id: 'statics.newPassword' })}
            {...(this.state.errors.password ? { validateStatus: 'error', help: this.state.errors.password } : null)}
          >
            <InputPassword
              // prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder={t({ id: 'statics.newPassword' })}
              onChange={e => this.setState({ password: e.target.value })}
            />
          </Form.Item>

          <Form.Item
            label={t({ id: 'statics.newPassword' })}
            {...(this.state.errors.repeat_password
              ? { validateStatus: 'error', help: this.state.errors.repeat_password }
              : null)}
          >
            <InputPassword
              // prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder={t({ id: 'statics.newPassword' })}
              onChange={e => this.setState({ passwordConf: e.target.value })}
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" size="large" text={t('statics.resetPassword')} />
          </Form.Item>
        </Form>
      </Auth.Container>,
      <Footer positionBottom="position-bottom" />,
    ];
  }
}

export default injectIntl(RecoveryPage);
