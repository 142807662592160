import React from 'react';
import { Tabs } from 'antd';
import { useRouteMatch } from 'react-router-dom';
import { Link, isRouteActive } from '~/libs/router';
import { Text } from '~/components/ui';
import { Icon } from '~/components/ui/Icon/Icon';

const ProfileLinks = ({ type }) => {
  const match = useRouteMatch();
  const isActive = routes => (isRouteActive(match, routes) ? 'active' : '');

  const links = [
    {
      showFor: ['tabs', 'profile-list'],
      text: 'profile.profile',
      icon: <Icon type="user-section-profile" />,
      route: 'MyProfilePage',
    },
    {
      showFor: ['tabs', 'profile-list'],
      text: 'profile.myPackage',
      icon: <Icon type="shipment-box" />,
      route: 'MyPackagePage',
    },
    {
      showFor: ['tabs'],
      text: 'profile.notification',
      icon: <Icon type="user-section-notification" />,
      route: 'NotificationPage',
    },
    {
      showFor: ['tabs', 'profile-list'],
      text: 'profile.myTeam',
      icon: <Icon type="user-section-team" />,
      route: 'TeamPage',
      subRoutes: ['TeamChangePage'],
    },
    // {
    //   showFor: ['tabs', 'profile-list'],
    //   text: 'profile.metrics',
    //   icon: <Icon type="user-section-api" />,
    //   route: 'MetricsPage',
    // },
    // {
    //   showFor: ['tabs'],
    //   text: 'profile.events',
    //   icon: <UserSection.Events />,
    //   route: 'EventsPage',
    //   params: { page: 1 },
    // },
    {
      showFor: ['tabs', 'profile-list'],
      text: 'profile.history',
      icon: <Icon type="user-section-downloads" />,
      route: 'DownloadsPage',
      params: { page: 1, type: 'list' },
    },
    {
      showFor: ['tabs'],
      text: 'profile.billing',
      icon: <Icon type="user-section-downloads" />,
      route: 'BillingPage',
    },
    // {
    //   showFor: ['tabs', 'profile-list'],
    //   text: 'profile.basket',
    //   icon: <UserSection.Cart />,
    //   route: 'OrderPage',
    //   subRoutes: ['OrderPaymentPage'],
    // },
    {
      showFor: ['tabs', 'profile-list'],
      text: 'profile.help',
      icon: <Icon type="help-menu" />,
      route: 'HelpPage',
    },
    // {
    //   showFor: ['tabs'],
    //   text: 'disconnections.disconnections',
    //   route: 'DisconnectionsList',
    // },
  ];

  if (type === 'profile-list') {
    return links
      .filter(({ showFor }) => showFor.includes('profile-list'))
      .map(({ subRoutes = [], ...link }) => (
        <li className={`item ${isActive([link.route, ...subRoutes])}`} key={link.route}>
          {!link.disabled ? (
            <Link route={link.route} params={link.params}>
              {link.icon ? <div className="svg-container">{link.icon}</div> : null}

              <Text id={link.text} />
            </Link>
          ) : (
            <span className="grey">
              {link.icon ? <div className="svg-container">{link.icon}</div> : null}

              <Text id={link.text} />
            </span>
          )}
        </li>
      ));
  }

  if (type === 'tabs') {
    return (
      <Tabs mode="horizontal" className="zh-profile-tabs" activeKey="">
        {links
          .filter(({ showFor }) => showFor.includes('tabs'))
          .map(({ subRoutes = [], ...link }) => (
            <Tabs.TabPane
              key={link.route}
              tab={
                !link.disabled ? (
                  <Link route={link.route} params={link.params} className={isActive([link.route, ...subRoutes])}>
                    <Text id={link.text} />
                  </Link>
                ) : (
                  <span className="grey">
                    <Text id={link.text} />
                  </span>
                )
              }
            />
          ))}
      </Tabs>
    );
  }

  return null;
};

export default ProfileLinks;
