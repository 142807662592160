import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect, useDispatch, useSelector, shallowEqual } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getSubdivision, setSubdivisionLoading } from '~/store/actions/SubdivisionActions';
import { getRecents } from '~/store/actions/FilterActions';

const enhance = connect(
  ({ subdivision }) => ({ subdivision }),
  dispatch => bindActionCreators({ setSubdivisionLoading, getSubdivision, getRecents }, dispatch),
);

const withSubdivision = () => Component =>
  enhance(({ match, subdivision = {}, ...props }) => {
    const onFetch = id => {
      props.setSubdivisionLoading(true);

      return props.getSubdivision(id);
    };

    useEffect(() => {
      const onFetchSubdivision = () => {
        window.scrollTo(0, 0);

        if (Number(match.params.id) !== subdivision.id) {
          onFetch(match.params.id);
        }
      };

      onFetchSubdivision();
    }, [match.params.id]);

    useEffect(() => {
      if (Object.keys(subdivision).length > 0) {
        props.getRecents();
      }
    }, [subdivision.data]);

    return <Component {...props} subdivision={subdivision} loading={subdivision.loading} onFetch={onFetch} />;
  });

export const useSubdivision = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const store = useSelector(({ subdivision }) => ({ subdivision }), shallowEqual);

  const onFetch = id => {
    dispatch(setSubdivisionLoading(true));

    return dispatch(getSubdivision(id));
  };

  useEffect(() => {
    const onFetchSubdivision = () => {
      window.scrollTo(0, 0);

      if (Number(params.id) !== store.subdivision.id) {
        onFetch(params.id);
      }
    };

    onFetchSubdivision();
  }, [params.id]);

  useEffect(() => {
    if (store.subdivision.data && Object.keys(store.subdivision.data).length > 0) {
      getRecents();
    }
  }, [store.subdivision.data]);

  return {
    subdivision: store.subdivision,
    loading: store.subdivision.loading,
    onFetch,
  };
};

export default withSubdivision;
