import React from 'react';
import { injectIntl } from '~/libs/localization';
import { firstLetters, getLogotypeLink, getAvatarLink } from '~/libs/string';
import { Empty } from '~/components/ui';

const Logo = ({ t, website, name = '', ...props }) => {
  const img = website ? getLogotypeLink(website) : props.img ? getAvatarLink(props.img) : '';

  const classLoading = props.loading ? 'skeleton skeleton-text' : '';

  return (
    <table className="company-logo">
      <tbody>
        <tr>
          <td>
            <div className={classLoading}>
              <div>
                <span>{name ? firstLetters(name) : '?'}</span>
              </div>

              {img && <img src={img} alt="crushed" />}
            </div>
          </td>

          <td>
            <div className={classLoading}>
              <h1 className={name.length > 110 ? 'logo-text-small' : null}>
                {name || <Empty />}

                {props.status && <span>{props.status}</span>}

                {props.isResident === false && <span className="inactive">{t('company.no_resident')}</span>}
              </h1>
            </div>

            {props.desc && (
              <div className={classLoading}>
                <p>{props.desc || <Empty />}</p>
              </div>
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default injectIntl(Logo);
