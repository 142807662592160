import React from 'react';

const Segment = props => (
  <div className={`segment ${props.className || ''}`}>
    {props.leftSide && <div className="pull-left">{props.leftSide}</div>}
    {props.rightSide && <div className="pull-right">{props.rightSide}</div>}
  </div>
);

export default Segment;
