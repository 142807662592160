import React from 'react';
import { Tooltip } from 'antd';
import { Icon } from '~/components/ui/Icon/Icon';

import '~/assets/scss/components/ui/_Button.scss';

const InitialButton = props => (
  <button
    disabled={props.loading || props.disabled}
    onClick={props.onClick}
    type={props.htmlType || 'button'}
    // prettier-ignore
    className={`
        zh-btn 
        ${props.prefix || props.loading || props.hint ? 'zh-btn-prefixed' : ''}
        ${props.aqua ? 'zh-btn-aqua' : ''}
        zh-btn-${props.smooth ? 'smooth-' : ''}${props.type || 'secondary'} 
        ${props.block ? 'zh-btn-block' : ''} 
        ${props.className || ''} 
        ${props.long ? 'zh-btn-long' : ''} 
        ${props.fade ? 'fade-in' : ''} 
        ${props.loading ? 'zh-btn-clicked' : ''}
        ${props.disabled ? 'zh-btn-disabled' : ''}
      `.replace(/\s\s+/g, ' ')}
  >
    {props.loading ? (
      <Icon type="loading" style={{ fontSize: 14 }} spin />
    ) : props.hintType ? (
      <Icon type="hint-icons" />
    ) : (
      props.prefix
    )}

    {props.children}
  </button>
);

const Button = props =>
  props.hint ? (
    <Tooltip title={props.hint} placement="bottom" overlayClassName="zh-fixed-tooltip zh-fixed-button-tooltip">
      <InitialButton {...props} />{' '}
    </Tooltip>
  ) : (
    <InitialButton {...props} />
  );

export default Button;
