import React, { useState, useEffect, useMemo } from 'react';
import { Input, Button, Select, AutoComplete, Checkbox, Radio, message } from 'antd';
import { useRequest } from 'estafette';
import { locations } from '~/libs/http/api';
import { validate } from '~/libs/object';
import { useInjectIntl } from '~/libs/localization';
import { Confirmation, Form, Loader } from '~/components/ui';
import { InputPhone } from '~/components/ui/InputPhone/InputPhone';
import { Icon } from '~/components/ui/Icon/Icon';
import { validateEmail, validatePhone } from '~/libs/string';

const periods = {
  ONE_WEEK: {
    period: 'ONE_WEEK',
    value: 7,
  },
  THREE_DAYS: {
    period: 'THREE_DAYS',
    value: 3,
  },
  ONE_DAY: {
    period: 'ONE_DAY',
    value: 1,
  },
};

export const LocationInfo = ({ onCancel, onDelete, loadingDeleteLocation, data = {}, setData, editLocation }) => {
  const { t } = useInjectIntl();
  const { request: requestRegions, data: dataRegions } = useRequest();
  const { request: requestTowns, data: dataTowns, loading: loadingTowns } = useRequest();
  const { request: requestStreets, data: dataStreets, loading: loadingStreets } = useRequest();
  const { request: requestNumbers, data: dataNumbers, loading: loadingNumbers } = useRequest();
  const { request: requestPostLocation, errors: errorPostLocation, loading: loadingPostLocation } = useRequest();

  const [errors, setErrors] = useState({});
  const [phoneValue, setPhoneValue] = useState('');
  const [mailValue, setMailValue] = useState('');
  const [locationName, setLocationName] = useState(null);
  const [region, setRegion] = useState(null);
  const [town, setTown] = useState(null);
  const [street, setStreet] = useState(data.payload?.street || null);
  const [streetId, setStreetId] = useState(null);
  const [number, setNumber] = useState(null);
  const [numberId, setNumberId] = useState(null);
  const [notificationsTypes, setNotificationsTypes] = useState(null);
  const [period, setPeriod] = useState(null);
  const [phones, setPhones] = useState([]);
  const [mails, setMails] = useState([]);
  const [isNotifyEmail, setIsNotifyEmail] = useState(data?.notify_email || false);
  const [isNotifySms, setIsNotifySms] = useState(data?.notify_sms || false);

  useEffect(() => {
    getAddress({ type: 'region' });
  }, []);

  useEffect(() => {
    if (data.period === 1) {
      setPeriod('ONE_DAY');
    }

    if (data.period === 3) {
      setPeriod('THREE_DAYS');
    }

    if (data.period === 7) {
      setPeriod('ONE_WEEK');
    }
  }, [data.period]);

  useEffect(() => {
    if (editLocation) {
      setLocationName(data?.title);
      setRegion(data.addresses?.[0]);
      setTown(data.addresses?.[1]);
      setStreet(data.addresses_info[data?.addresses[2]]?.name || data.payload?.street);
      setNumber(data.addresses_info[data?.addresses[3]]?.name || data.payload?.number);
      setNumberId(data.addresses?.[3]);
      setStreetId(data.addresses?.[2]);
      setNotificationsTypes(data?.type);
      setIsNotifyEmail(data?.notify_email);
      setIsNotifySms(data?.notify_sms);
    }
  }, [editLocation]);

  useEffect(() => {
    if (!editLocation) {
      setPhoneValue('');
      setMailValue();
      setLocationName(null);
      setRegion(null);
      setTown(null);
      setStreet(null);
      setStreetId(null);
      setNumber(null);
      setNumberId(null);
      setNotificationsTypes(null);
      setPhones([]);
      setMails([]);
      setIsNotifyEmail(false);
      setIsNotifySms(false);
    }
  }, [editLocation]);

  const dataRegionsOptions = useMemo(
    () => dataRegions.filter(item => item.name).map(item => ({ name: item.name, id: item.id })),
    [dataRegions],
  );

  const dataStreetsOptions = useMemo(() => dataStreets.filter(item => item.name).map(item => item.name), [dataStreets]);

  const dataNumbersOptions = useMemo(() => dataNumbers.filter(item => item.name).map(item => item.name), [dataNumbers]);

  useEffect(() => {
    if (dataRegions.length && data?.addresses && data?.addresses[0]) {
      getAddress({ type: 'town', parent: data?.addresses[0] });
    }
  }, [dataRegions]);

  useEffect(() => {
    if (dataTowns.length && data?.addresses && data?.addresses[1]) {
      getAddress({ type: 'street', parent: data?.addresses[1] });
    }
  }, [dataTowns]);

  useEffect(() => {
    if (dataStreets.length && data?.addresses && data?.addresses[2]) {
      getAddress({ type: 'number', parent: data?.addresses[2] });
    }
  }, [dataStreets]);

  useEffect(() => {
    if (editLocation) {
      if (data?.recipients) {
        const { sms, email } = data.recipients
          .filter(({ type }) => type)
          .reduce(
            (acc, current) => {
              const type = current.type.toLowerCase();
              return { ...acc, [type]: [...acc[type], current.value] };
            },
            { sms: [], email: [] },
          );

        setPhones(sms);
        setMails(email);
      }
    }
  }, [data, editLocation]);

  useEffect(() => {
    if (editLocation && data?.addresses_info && !region) {
      setRegion(data.addresses[0]);
      setTown(data.addresses[1]);
      setStreetId(data.addresses[2]);
      setNumberId(data.addresses[3]);
      setStreet(data.addresses_info[data?.addresses[2]]?.name || data.payload?.street);
      setNumber(data.addresses_info[data?.addresses[3]]?.name || data.payload?.number);
    }
  }, [data, region, town, street, number]);

  useEffect(() => {
    if (data?.period) {
      // eslint-disable-next-line array-callback-return
      Object.keys(periods).forEach(item => {
        if (periods[item].period === data.period && periods[item].value === data.value) {
          setPeriod(item);
        }
      });
    }
  }, [data]);

  const onSubmitForm = async () => {
    const addresses = [region, town];
    const payload = {};

    if (streetId) {
      addresses.push(streetId);
    } else if (street) {
      payload.street = street;
    }

    if (numberId) {
      addresses.push(numberId);
    } else if (number) {
      payload.number = number;
    }

    const params = {
      title: locationName,
      addresses,
      payload,
      period: periods[period]?.value,
      recipients: [
        ...phones.map(phone => ({ type: 'SMS', value: phone })),
        ...mails.map(mail => ({ type: 'EMAIL', value: mail })),
      ],
      type: notificationsTypes,
      notify_email: Boolean(isNotifyEmail),
      notify_sms: Boolean(isNotifySms),
    };

    try {
      let api = locations.addAddress;

      if (data?.id) {
        params.id = data.id;
        api = locations.editAddress;
      }

      await requestPostLocation(api(params));

      message.success(t('other.success.added'));

      onCancel();
    } catch (error) {
      message.error(t('other.error_module'));
    }
  };

  const onChangePhone = (_, value) => {
    setPhoneValue('+373'.concat(value));
  };

  const onAddPhone = () => {
    if (phones.length > 0) {
      setErrors({ ...errors, phone: t('alerts.maximumPhones') });
    } else if (phoneValue && validatePhone(phoneValue)) {
      const arrayClone = [...phones, phoneValue];
      setPhones(arrayClone);
      setPhoneValue('');

      setErrors({ ...errors, phone: false });
    } else if (phoneValue) {
      setErrors({ ...errors, phone: t('alerts.phoneWrong') });
    } else {
      setErrors({});
    }
  };

  const onChangeMail = ({ target }) => setMailValue(target.value);

  const onAddMail = ({ target }) => {
    if (mails.length > 2) {
      setErrors({ ...errors, email: t('alerts.maximumEmails') });
    } else if (target.value && validateEmail(target.value)) {
      const arrayClone = [...mails, target.value];
      setMails(arrayClone);
      setMailValue('');

      setErrors({ ...errors, email: false });
    } else if (target.value) {
      setErrors({ ...errors, email: t('alerts.emailWrong') });
    } else {
      setErrors({ ...errors });
    }
  };

  const onRemovePhone = target => setPhones(prevPhones => prevPhones.filter(i => target !== i));

  const onRemoveMail = target => setMails(prevMails => prevMails.filter(i => target !== i));

  const onChangeLocationName = ({ target }) => setLocationName(target.value);

  const clearAddress = () => {
    if (data?.addresses) {
      setData(prevData => ({ ...prevData, addresses: [], addresses_info: {} }));
    }
  };

  const getAddress = params => {
    try {
      const requests = {
        region: requestRegions,
        town: requestTowns,
        street: requestStreets,
        number: requestNumbers,
      };

      if (!Object.keys(requests).includes(params.type)) {
        throw new Error();
      }

      requests[params.type](locations.getAddress(params));
    } catch ({ response }) {
      message.error(t('other.error_module'));
    }
  };

  const onChangeRegion = val => {
    setRegion(val);
    setTown(null);
    setStreet(null);
    setStreetId(null);
    setNumber(null);
    setNumberId(null);

    clearAddress();

    getAddress({ type: 'town', parent: val });
  };

  const onChangeTown = val => {
    setTown(val);
    setStreet(null);
    setStreetId(null);
    setNumber(null);
    setNumberId(null);

    clearAddress();

    getAddress({ type: 'street', parent: val });
  };

  const onChangeStreet = val => {
    setNumber(null);
    setNumberId(null);

    const obj = dataStreets.find(el => el.name === val);

    setStreet(obj ? obj.name : val);
    setStreetId(obj ? obj.id : null);

    if (obj !== undefined) {
      getAddress({ type: 'number', parent: obj.id });
    }
  };

  const onChangeNumber = val => {
    const obj = dataNumbers.find(el => el.name === val);

    setNumber(obj ? obj.name : val);
    setNumberId(obj ? obj.id : null);
  };

  const onNotificationsTypes = values => setNotificationsTypes(values);
  const onChangePeriod = ({ target }) => setPeriod(target.value);
  const onNotifyEmail = () => setIsNotifyEmail(!isNotifyEmail);
  const onNotifySms = () => setIsNotifySms(!isNotifySms);
  const onClear = () => onCancel();

  return (
    <Form className="notification-form">
      {data?.title ? (
        <div className="clearfix">
          <div className="pull-left">
            <div className="location-name">
              <div className="ico-block">
                <Icon type="marker-small" />
              </div>

              <span>{data.title}</span>
            </div>
          </div>

          <div className="pull-right">
            <Confirmation type="delete" onConfirm={() => onDelete(data.id)}>
              <span className="table-action-text cursor">
                <Loader.Inline loading={loadingDeleteLocation}>
                  <Icon type="delete" />

                  {t('other.delete')}
                </Loader.Inline>
              </span>
            </Confirmation>
          </div>
        </div>
      ) : (
        <div className="clearfix">
          <div className="pull-left">
            <div className="location-name">
              <div className="ico-block">
                <Icon type="marker-small" />
              </div>
              <span>{t('alerts.addTheAddress')}</span>
            </div>
          </div>
        </div>
      )}

      <div className="address-rows">
        <div className="clearfix half">
          <Form.Half>
            <Form.Item label={t('alerts.nameOfLocality')} {...validate(errorPostLocation, 'title')}>
              <Input
                type="text"
                placeholder={t('alerts.standardName')}
                value={locationName}
                onChange={onChangeLocationName}
              />
            </Form.Item>
          </Form.Half>
        </div>

        <div className="clearfix">
          <Form.Half>
            <Form.Item
              label={t('alerts.selectTheRegion')}
              {...(errorPostLocation?.addresses && errorPostLocation?.addresses[0]
                ? { validateStatus: 'error', help: errorPostLocation?.addresses[0] }
                : null)}
            >
              <Select
                showSearch
                optionFilterProp="children"
                placeholder={t('alerts.selectTheRegion')}
                onChange={onChangeRegion}
                value={region}
                overlayStyle={{ zIndex: 1 }}
                overlayClassName="location_index_fix"
              >
                {dataRegionsOptions?.length &&
                  dataRegionsOptions.map(item => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Form.Half>

          <Form.Half>
            <Form.Item
              label={t('alerts.selectTheCity')}
              {...(errorPostLocation?.addresses && errorPostLocation?.addresses[1]
                ? { validateStatus: 'error', help: errorPostLocation?.addresses[1] }
                : null)}
            >
              <Loader.Inline loading={loadingTowns}>
                <Select
                  placeholder={t('alerts.select')}
                  onChange={onChangeTown}
                  value={town}
                  showSearch
                  optionFilterProp="children"
                >
                  {dataTowns?.length &&
                    dataTowns.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                </Select>
              </Loader.Inline>
            </Form.Item>
          </Form.Half>
        </div>

        <div className="clearfix">
          <Form.Half>
            <Form.Item label={t('alerts.street')}>
              <Loader.Inline loading={loadingStreets}>
                <AutoComplete
                  placeholder={t('alerts.street')}
                  value={street}
                  onChange={onChangeStreet}
                  dataSource={dataStreetsOptions}
                  filterOption={(inputValue, option) =>
                    option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                  }
                />
              </Loader.Inline>
            </Form.Item>
          </Form.Half>

          <Form.Half>
            <Form.Item label={t('alerts.nr')}>
              <Loader.Inline loading={loadingNumbers}>
                <AutoComplete
                  placeholder={t('alerts.nr')}
                  value={number}
                  onChange={onChangeNumber}
                  dataSource={dataNumbersOptions}
                  filterOption={(inputValue, option) =>
                    option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                  }
                />
              </Loader.Inline>
            </Form.Item>
          </Form.Half>
        </div>
      </div>

      <div className="notifications-info">
        <div className="title">{t('disconnections.notificationSettings')}</div>

        <div className="notifications-wrap">
          <div className="setting-rows">
            <Form.Item className="checkbox-block" {...validate(errorPostLocation, 'type')}>
              <Form.Helper large>{t({ id: 'disconnections.disconnectNotifications' })}</Form.Helper>
              <Checkbox.Group value={notificationsTypes} onChange={onNotificationsTypes}>
                <Checkbox value="ENERGY">{t({ id: 'disconnections.waterDelivery' })}</Checkbox>
                <Checkbox value="WATER">{t({ id: 'disconnections.electricity' })}</Checkbox>
                <Checkbox value="CONTROL">{t({ id: 'disconnections.controlServices' })}</Checkbox>
              </Checkbox.Group>
            </Form.Item>

            <Form.Item className="checkbox-block">
              <Form.Helper large>{t({ id: 'disconnections.frequencyNotifications' })}</Form.Helper>
              <Radio.Group value={period} onChange={onChangePeriod}>
                <Radio value="ONE_WEEK">{t({ id: 'disconnections.weekBefore' })}</Radio>
                <Radio value="THREE_DAYS">{t({ id: 'disconnections.daysBefore' })}</Radio>
                <Radio value="ONE_DAY">{t({ id: 'disconnections.dayBefore' })}</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item className="method-wrap">
              <Form.Helper large>{t({ id: 'disconnections.methodOfNotification' })}</Form.Helper>

              <Form.Item label="Email" colon={false} {...validate(errors, 'email')}>
                <Checkbox checked={isNotifyEmail} onChange={onNotifyEmail} />

                <Input
                  type="text"
                  placeholder={t({ id: 'disconnections.enterTextHere' })}
                  onPressEnter={onAddMail}
                  onBlur={onAddMail}
                  value={mailValue}
                  onChange={onChangeMail}
                />

                <div className="wrap-options">
                  {mails.map(item => (
                    <div key={item}>
                      {item} <Icon type="close" className="remove-option" onClick={() => onRemoveMail(item)} />
                    </div>
                  ))}
                </div>
              </Form.Item>

              <div className="separator" />

              <Form.Item label={t({ id: 'disconnections.phone' })} colon={false} {...validate(errors, 'phone')}>
                <Checkbox checked={isNotifySms} onChange={onNotifySms} />
                <InputPhone onChangePhone={onChangePhone} onAddPhone={onAddPhone} />

                <div className="wrap-options">
                  {phones.map(item => (
                    <div key={item}>
                      {item} <Icon type="close" className="remove-option" onClick={() => onRemovePhone(item)} />
                    </div>
                  ))}
                </div>
              </Form.Item>
            </Form.Item>
          </div>
        </div>
      </div>

      <div className="form-footer">
        <Button type="reset" onClick={onClear}>
          {t({ id: 'other.reset' })}
        </Button>

        <Button type="primary" onClick={onSubmitForm} loading={loadingPostLocation}>
          {t({ id: 'other.submit' })}
        </Button>
      </div>
    </Form>
  );
};
