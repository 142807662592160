export const number = value => (typeof value === 'number' ? value.toLocaleString() : value);

export const withVat = (vat, cost) => (cost / 100) * (vat + 100);

export const parseEmployees = employees =>
  typeof employees === 'string' && parseFloat(employees.replace(/^[0-9]+-/, ''));

export const getPercentage = (used, total) => {
  const percentage = (used / total) * 100;

  return !isNaN(percentage) ? percentage : 0;
};

export const rowCount = index =>
  `${(index + 1).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}`;

export const spaceThousands = thousand => thousand.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

export const commaThousands = thousand => thousand.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
