import { message } from 'antd';
import { profile } from '~/libs/http/api';

export const getParams = () => async dispatch => {
  try {
    const { data } = await profile.get();

    dispatch({
      type: 'GET_USER_PARAMS',
      payload: data,
    });
  } catch (error) {
    message.error("Oops! We've got some problems ...");
    message.error('Please, try a bit later.');
  }
};

export function updateAuthenticationLoading(payload) {
  return {
    type: 'UPDATE_LOADING',
    payload,
  };
}

export function updateUserSettings(payload) {
  return {
    type: 'UPDATE_SETTINGS',
    payload,
  };
}

export function updateToken(payload) {
  return {
    type: 'UPDATE_TOKEN',
    payload,
  };
}

export function deleteToken() {
  return {
    type: 'DELETE_TOKEN',
    payload: '',
  };
}
