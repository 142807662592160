import * as React from 'react';
import { Checkbox } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const CardList = ({ className, title, classTitle, actions, classActions, children }) => (
  <>
    <div className={`card-list ${className || ''}`}>
      {title && <div className={`card-list-title ${classTitle || ''}`}>{title}</div>}

      {actions && <div className={`card-list-actions ${classActions || ''}`}>{actions}</div>}
    </div>

    <div className="card-list-content">{children}</div>
  </>
);

export const CardListItem = ({ className, footer, onCheck, loading, hideCheck, ...props }) => {
  const onCheckChange = ({ target }) => onCheck && onCheck(target.checked);

  return (
    <div className={`card-list-item ${className || ''}`}>
      {props.children}

      {!loading && onCheck && !hideCheck && (
        <Checkbox checked={props.checked} disabled={props.disabled} onChange={onCheckChange} />
      )}

      {loading && <LoadingOutlined className="card-list-item-spin" />}

      {footer && <div className="card-list-item-footer">{footer}</div>}
    </div>
  );
};

export const CardListItemRow = ({ className, children }) => (
  <div className={`card-list-item-row ${className || ''}`}>{children}</div>
);

export const CardListItemValue = ({ title, children }) => (
  <div className="card-list-item-value">
    <div className="card-list-item-value-title">{title}:</div>
    <div className="card-list-item-value-content">{children}</div>
  </div>
);

export default CardList;
