const initialState = {
  loading: false,
  perPage: 5,
  data: {},
};

export const companies = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_COMPANIES_LOADING':
      return {
        ...initialState,
        loading: true,
      };
    case 'RESET_COMPANIES':
      return { loading: false, perPage: 5, data: {} };
    case 'GET_COMPANIES':
      return {
        ...initialState,
        data: action.payload.data,
        loading: false,
      };
    case 'GET_SEARCH_LIST':
      return {
        ...initialState,
        data: action.payload.data,
        loading: false,
      };
    case 'GET_SEARCH_LIST_EMPLOYEE':
      return {
        ...initialState,
        data: action.payload.data,
        loading: false,
      };
    case 'SET_COMPANIES_PERPAGE':
      return {
        ...initialState,
        perPage: action.payload.perPage,
      };
    default:
      return state;
  }
};
