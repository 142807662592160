import { adapterIntl } from '~/libs/localization/helpers';

const company = adapterIntl({
  'company.tabs.1': { ro: 'Date Generale', ru: 'Общая информация', en: 'General data' },
  'company.tabs.2': { ro: 'Personal', ru: 'Сотрудники', en: 'Staff' },
  'company.tabs.3': { ro: 'Subdiviziuni', ru: 'Подразделения', en: 'Subdivisions' },
  'company.tabs.4': { ro: 'Date Economice', ru: 'Экономические данные', en: 'Economic Data' },
  'company.tabs.5': { ro: 'Publicații', ru: 'Публикации', en: 'Publications' },
  'company.tabs.6': { ro: 'Date juridice', ru: 'Юридические данные', en: 'Legal data' },
  'company.idno': { ro: 'IDNO', ru: 'IDNO', en: 'IDNO' },
  'company.registredDate': { ro: 'Data înregistrării', ru: 'Дата регистрации', en: 'Registration date' },
  'company.registredYear': { ro: 'Anul înregistrării', ru: 'Год регистрации', en: 'Registration year' },
  'company.employee': { ro: 'Angajați', ru: 'Сотрудники', en: 'Employees' },
  'company.employee2': { ro: 'angajați', ru: 'сотрудников', en: 'employees' },
  'company.subdivisions': { ro: 'Subdiviziuni', ru: 'Подразделения', en: 'Subdivisions' },
  'company.profile': { ro: 'Profilul companiei', ru: 'Профиль компании', en: 'Company profile' },
  'company.schedule': { ro: 'Ore de lucru', ru: 'График работы', en: 'Working hours' },
  'company.economy': { ro: 'Activitate economica', ru: 'Экономическая деятельность', en: 'Economic activities' },
  'company.productsServices': {
    ro: 'Produsele si Serviciile Companiei',
    ru: 'Товары и Услуги Компании',
    en: 'Company Products and Services',
  },
  'company.activityUnlicType': {
    ro: 'Genurile de Activitate Nelicențiate',
    ru: 'Нелицензированные Виды Деятельности',
    en: 'Unlicensed Activities',
  },
  'company.activityLicType': {
    ro: 'Genurile de Activitate Licențiate',
    ru: 'Лицензированные Виды Деятельности',
    en: 'Licenses Activities',
  },
  'company.turnover': { ro: 'Cifra de afaceri', ru: 'Оборот', en: 'Turnover' },
  'company.empty': { ro: 'Nu sunt date', ru: 'Нет данных', en: 'No data' },
  'company.capitalSize': {
    ro: 'Mărimea Capitalului Social',
    ru: 'Размер Уставного Капитала',
    en: 'Joint Stock Amount',
  },
  'company.capitalType': {
    ro: 'Tipul Capitalului Social',
    ru: 'Тип Уставного Капитала',
    en: 'Joint Stock Type',
  },
  'company.countryInvest': {
    ro: 'Țara de Origine a Investițiilor',
    ru: 'Страна Происхождения Инвестиций',
    en: 'Country of Origin of Investments',
  },
  'company.description': { ro: 'Descrierea Companiei', ru: 'Описание компании', en: 'Company Description' },
  'company.history': {
    ro: 'Istoricul Modificărilor Companiei',
    ru: 'История Изменений Компании',
    en: 'History of Changes in Company',
  },
  'company.fond': { ro: 'Fondatori', ru: 'Учредители', en: 'Founders' },
  'company.administrators': { ro: 'Administratori', ru: 'Руководители', en: 'Administrators' },
  'company.historyPersonal': {
    ro: 'Istoria Activității Personalului',
    ru: 'История Деятельности Сотрудников',
    en: 'Employees Activity History',
  },
  'company.fondOrder': {
    ro: 'Numele Asociatului/Asociaților/Acționarilor',
    ru: 'Имя Партнера/Партнеров/Акционеров',
    en: 'Name of Associate/Assosiasion/Shareholder',
  },
  'company.fondSize': { ro: 'Marimea Cotei Sociale', ru: 'Размер Социальной Квоты', en: 'Social Quota Amount' },
  'company.noTitle': { ro: 'Fără titlu', ru: 'Без названия', en: 'No title' },
  'company.addressFacto': { ro: 'Adresa', ru: 'Адрес', en: 'Address' },
  'company.no_resident_address': { ro: 'Adresa', ru: 'Адрес', en: 'Address' },
  'company.addressEmpty': {
    ro: 'Nu este informație despre locația',
    ru: 'Нет информации о расположении',
    en: 'No location data',
  },
  'company.addressJuridical': { ro: 'Adresa juridică', ru: 'Юридический адрес', en: 'Legal address' },
  'company.showMap': { ro: 'Arată pe hartă', ru: 'Указать на карте', en: 'Show on map' },
  'company.employeeCount': { ro: 'Persoane Angajate', ru: 'Сотрудники', en: 'Staff members' },
  'company.offday': { ro: 'Zi liberă', ru: 'Выходной день', en: 'Day off' },
  'company.personalContact': { ro: 'Persoană de contact', ru: 'Контактное лицо', en: 'Contact person' },
  'company.productsSubdivisions': {
    ro: 'Produsele si Serviciile Subdiviziunei',
    ru: 'Товары и Услуги Подразделения',
    en: 'Products and Services of Subdivisions',
  },
  'company.producer': { ro: 'Producător', ru: 'Производитель', en: 'Producer' },
  'company.provider': { ro: 'Prestator de Servicii', ru: 'Поставщик Услуг', en: 'Service provider' },
  'company.trader': { ro: 'Comerciant', ru: 'Продавец', en: 'Seller' },
  'company.incomeSales': { ro: 'Venituri din vânzări', ru: 'Доход от продаж', en: 'Sales income' },
  'company.incomeNet': { ro: 'Profit net', ru: 'Чистая прибыль', en: 'Net profit' },
  'company.totalActiveCirc': { ro: 'Total active circulante', ru: 'Оборотные активы', en: 'Total current assets' },
  'company.totalActiveImobil': {
    ro: 'Total active imobilizante',
    ru: 'Недвижимые активы',
    en: 'Total fixed assets',
  },
  'company.totalCapital': { ro: 'Total capital propriu', ru: 'Собственный капитал', en: 'Total equity' },
  'company.totalCurrentLiabil': {
    ro: 'Total datorii curente',
    ru: 'Итого текущих задолженностей',
    en: 'Total current liabilities',
  },
  'company.totalLongLiabil': {
    ro: 'Total datorii pe termen lung',
    ru: 'Итого длительных задолженностей',
    en: 'Total long-term liabilities',
  },
  'company.statusCompany': {
    ro: 'Statutul companiei',
    ru: 'Статус компании',
    en: 'Company status',
  },
  'company.tva': { ro: 'Plătitor TVA', ru: 'Плательщик НДС', en: 'VAT payer' },
  'company.payer': { ro: 'Plătitor de Accize', ru: 'Плательщик Акцизов', en: 'Excise Tax Payer' },
  'company.importExport': { ro: 'Import/Export', ru: 'Импорт/Экспорт', en: 'Import/Export' },
  'company.infoIntelectual': {
    ro: 'Informații privind Proprietatea Intelectuală a Companiei',
    ru: 'Информация об Интеллектуальной Собственности Компании',
    en: 'Information about Company Intelectual Property',
  },
  'company.intelectualObjects': {
    ro: 'Obiectele Proprietății Intelectuale',
    ru: 'Объекты Интеллектуальной Собственности',
    en: 'Intelectual Property Objects',
  },
  'company.intelectualDescription': {
    ro: 'Descrierea Proprietății Intelectuale',
    ru: 'Описание Объектов Интеллектуальной Собственности',
    en: 'Intelectual Property Objects Description',
  },
  'company.brandsUsed': { ro: 'Mărci Terțe Utilizate', ru: 'Используемые Бренды', en: 'Brands Used' },
  'company.brandsDesc': {
    ro: 'Descrierea Mărcilor Terțe Utilizate',
    ru: 'Описание Используемых Брендов',
    en: 'Description of Brands Used',
  },
  'company.partnersInfo': {
    ro: 'Informații privind Clienții/Asociații Companiei',
    ru: 'Информация о Клиентах/Партнерах Компании',
    en: 'Information about company Clients/Partners ',
  },
  'company.clientsParents': { ro: 'Clienți/Asociați', ru: 'Клиенты/Партнеры', en: 'Clients/Partners' },
  'company.productsServicesCompany': {
    ro: 'Produsele și Serviciile Companiei',
    ru: 'Товары и услуги Компании',
    en: 'Products and Services of Company',
  },
  'company.bankService': { ro: 'Bancă de deservire', ru: 'Обслуживающие банки', en: 'Serving bank' },
  'company.serviceProvider': { ro: 'Prestator de Servicii', ru: 'Поставщик Услуги', en: 'Serive Provider' },
  'company.unlic': { ro: 'Nelicențiate', ru: 'Нелицензированные', en: 'Unlicensed' },
  'company.lic': { ro: 'Licențiate', ru: 'Лицензированные', en: 'Licensed' },
  'company.financialDate': { ro: 'Datele privind finanțele', ru: 'Данные о финансах', en: 'Financial data' },
  'company.monitorOfficial': {
    ro: 'Publicațiile Compania în Monitorul Oficial',
    ru: 'Публикации Компании в Monitorul Oficial',
    en: 'Publications of Company in the Official Monitoring Services',
  },
  'company.company': { ro: 'Comapania', ru: 'Компания', en: 'Company' },
  'company.fullName': { ro: 'Denumirea completă', ru: 'Полное название', en: 'Full name' },
  'company.shareSize': { ro: 'Marimea cotei sociale', ru: 'Размер уставного капитала', en: 'Joint stock size' },
  'company.employee1': { ro: 'Angajat', ru: 'Сотрудник', en: 'Employee' },
  'company.activityEmloyeeInfo': {
    ro: 'Informații cu privire la activitatea angajatului',
    ru: 'Информация о деятельности сотрудника',
    en: 'Information on activities of employee',
  },
  'company.formModification': { ro: 'Forma modificarilor', ru: 'Изменения', en: 'Change form' },
  'juridical.infoGen': { ro: 'Informaţii generale', ru: 'Общая информация', en: 'General information' },
  'juridical.numFile': { ro: 'Numărul Dosarului', ru: 'Номер Дела', en: 'Case number' },
  'juridical.instanceName': {
    ro: 'Denumirea Instanței de Insolvabilitate',
    ru: 'Название инстанции по несостоятельности',
    en: 'Name of the Insolvency Court',
  },
  'juridical.panelJudge': { ro: 'Completul de Judecată', ru: 'Состав суда', en: 'Trial Unit' },
  'juridical.instanceAddress': {
    ro: 'Adresa Instanței de Insolvabilitate',
    ru: 'Адрес инстанции по несостоятельности',
    en: 'Address of the Insolvency Court',
  },
  'juridical.subject': { ro: 'Obiectul Cauzei', ru: 'Предмет судебного разбирательства', en: 'Case subject' },
  'juridical.subjectLevel': {
    ro: 'Stadiul de Examinare a Cauzei',
    ru: 'Этап судебного разбирательства',
    en: 'Trial stage',
  },
  'juridical.juridicalType': {
    ro: 'Tipul Procesului Judiciar',
    ru: 'Тип юридического процесса',
    en: 'Legal process type',
  },
  'juridical.processParts': { ro: 'Părțile Procesului', ru: 'Стороны процесса', en: 'Process parties' },
  'juridical.nameParts': { ro: 'Denumirea Părții', ru: 'Название стороны', en: 'Party name' },
  'juridical.quality': { ro: 'Calitatea', ru: 'Качество', en: 'Quality' },
  'juridical.adminAuthData': {
    ro: 'Date privind Administratorul Autorizat',
    ru: 'Данные уполномоченного руководителя',
    en: 'Data regarding the Authorized Administrator',
  },
  'juridical.insolvency': {
    ro: 'Desfășurarea procesului de insolvabilitate',
    ru: 'Проведение процесса о несостоятельности',
    en: 'Conduction of insolvency process',
  },
  'juridical.judge': { ro: 'Judecătorul', ru: 'Судья', en: 'Judge' },
  'juridical.dataJudgment': {
    ro: 'Data și ora ședinței de judecată',
    ru: 'Дата и время судебного заседания',
    en: 'Data and time of the trial',
  },
  'juridical.officeJudgment': {
    ro: 'Biroul Ședinței de Judecată',
    ru: 'Зал судебного заседания',
    en: 'Hearing office of the trial',
  },
  'juridical.judgmentResult': {
    ro: 'Rezultatul Ședinței de Judecată',
    ru: 'Результат судебного заседания',
    en: 'Trial result',
  },
  'juridical.judgmentSolution': { ro: 'Soluția instanței', ru: 'Решение инстанции', en: 'Court decision' },
  'juridical.fondType': {
    ro: 'Tipul Soluției Instanței de Fond',
    ru: 'Тип решения соответствующей инстанции',
    en: 'Type of the solution of the court',
  },
  'juridical.fondDate': {
    ro: 'Data pronunțării soluției instanței de fond',
    ru: 'Дата вынесения решения соответствующей инстанции',
    en: 'Date of the court decision',
  },
  'juridical.fondSolution': {
    ro: 'A fost contestată soluția instanței de fond',
    ru: 'Было опротестовано решение соответствующей инстанции',
    en: 'Courts decision was appealed',
  },
  'juridical.fondSolutionDesc': { ro: 'Descrierea soluției', ru: 'Описание решения', en: 'Decision description' },
  'juridical.publication': {
    ro: 'Publicațiile aferente procedurii de insolvabilitate',
    ru: 'Публикации, относящиеся к процедуре несостоятельности',
    en: 'Publications, regarding insolvency process',
  },
  'juridical.files': { ro: 'Dosare', ru: 'Дела', en: 'Case' },
  'juridical.lastModif': { ro: 'Ultima modificare', ru: 'Последнее изменение', en: 'Last modification' },
  'juridical.fileNum': { ro: 'Numărul Dosarului', ru: 'Номер дела', en: 'Case number' },
  'juridical.instance': { ro: 'Instanță', ru: 'Инстанция', en: 'Court' },
  'juridical.partsProcess': {
    ro: 'Participanții Procesului',
    ru: 'Участники процесса',
    en: 'Case participants',
  },
  'juridical.fondName': {
    ro: 'Denumirea Instanței de Fond',
    ru: 'Название соответствующей инстанции',
    en: 'Name of the court',
  },
  'juridical.judgePanel': {
    ro: 'Judecatorul/Completul de Judecată',
    ru: 'Судья/Судейский состав',
    en: 'Judge/Trial Panel',
  },
  'juridical.fondAddress': {
    ro: 'Adresa Instanței de Fond',
    ru: 'Адрес соответствующей инстанции',
    en: 'Address of the Court of First Instance',
  },
  'juridical.partName': { ro: 'Numele participant', ru: 'Фамилия участника', en: 'Name of the participant' },
  'juridical.processQuality': { ro: 'Calitatea procesuală', ru: 'Процессуальное качество', en: 'Process quality' },
  'juridical.judgeMeeting': { ro: 'Ședintele de judecată', ru: 'Заседания суда', en: 'Court hearings' },
  'juridical.judgeMeetingDate': {
    ro: 'Data și ora ședinței de judecată',
    ru: 'Дата и время судебного заседания',
    en: 'Date and time of the court hearing',
  },
  'juridical.judgeMeetingOffice': {
    ro: 'Biroul Ședinței de Judecată',
    ru: 'Зал судебного заседания',
    en: 'Hearing court hall',
  },
  'juridical.judgeMeetingResult': {
    ro: 'Rezultatul Ședinței de Judecată',
    ru: 'Результат судебного заседания',
    en: 'Court hearing decision',
  },
  'juridical.appealType': { ro: 'Tipul Contestației', ru: 'Тип опротестования', en: 'Type of the appeal' },
  'juridical.appealDate': {
    ro: 'Data depunerii contestației',
    ru: 'Дата подачи опротестования',
    en: 'Date of filing the appeal',
  },
  'juridical.solutionFond': {
    ro: 'Soluția Instanței de Fond',
    ru: 'Решение соответствующей инстанции',
    en: 'The Court of Appeal decision',
  },
  'juridical.reorganization': {
    ro: 'Informații privind reorganizarea și dizolvarea companiei',
    ru: 'Информация о реорганизации и роспуске компании',
    en: 'Information about reorganization and dissolution of the company',
  },
  'juridical.tabs.1': {
    ro: 'Reorganizarea și dizolvarea companiei',
    ru: 'Реорганизация и роспуск компании',
    en: 'Reorganization and dissolution of the company',
  },
  'juridical.tabs.2': { ro: 'Jurisprudența companiei', ru: 'Юриспруденция компании ', en: 'Company case law' },
  'juridical.tabs.3': {
    ro: 'Insolvabilitate companiei',
    ru: 'Несостоятельность компании',
    en: 'Insolvency of the company',
  },
  'juridical.solution': { ro: 'Soluția', ru: 'Решение', en: 'Decision' },
  'juridical.ads': {
    ro: 'Anunț Insolvabilitate',
    ru: 'Объявление о несостоятельности',
    en: 'Announcement of insolvency',
  },
  'juridical.judgeInstance': { ro: 'Instanța de judecata', ru: 'Судебная инстанция', en: 'Court' },
  'juridical.file': { ro: 'Dosarul nr.', ru: 'Дело №', en: 'Case nr.' },
  'juridical.time': { ro: 'Ora', ru: 'Время', en: 'Hour' },
  'juridical.jugdeName': { ro: 'Judecătorul', ru: 'Судья', en: 'Judge' },
  'juridical.publicationHeader': {
    ro: 'Publicații aferente procedurii de insolvabilitate',
    ru: 'Публикации, относящиеся к процедуре несостоятельности',
    en: 'Publications, regarding insolvency process',
  },
  'subdivision.type': { ro: 'Tipul subdiviziunii', ru: 'Тип подразделения', en: 'Subdivision type' },
  'subdivision.personal': {
    ro: 'Personalul Subdiviziunii',
    ru: 'Сотрудники подразделения',
    en: 'Subdivision employees',
  },
  prt: {
    ro: 'Asociați',
    ru: 'Партнеры',
    en: 'Partners',
  },
  adm: {
    ro: 'Administratori',
    ru: 'Администраторы',
    en: 'Administrators',
  },
  vc: {
    ro: 'VC',
    ru: 'VC',
    en: 'VC',
  },
  cxo: {
    ro: 'CXO',
    ru: 'CXO',
    en: 'CXO',
  },
  man: {
    ro: 'Manageri',
    ru: 'Менеджеры',
    en: 'Managers',
  },
  emp: {
    ro: 'Angajați',
    ru: 'Сотрудники',
    en: 'Employees',
  },
  'company.download_list': { ro: 'Descarcă', ru: 'Скачать', en: 'Download' },
  'company.download_pdf': { ro: 'Descarcă fișier PDF', ru: 'Загрузить как PDF', en: 'Download PDF file' },
  'company.export_pdf': { ro: 'Export PDF', ru: 'Экспорт PDF', en: 'Export PDF' },
  'company.download_xls': { ro: 'Descarcă fișier XLS', ru: 'Загрузить как XLS', en: 'Download XLS file' },
  'company.print': { ro: 'Printează raport', ru: 'Распечатать отчет', en: 'Print the raport' },
  'company.print_format': { ro: 'Format A4, 5 pagini', ru: 'Формат А4, 5 страниц', en: 'A4 format, 5 pages' },
  'company.export_name': { ro: 'Export XLS {name}', ru: 'Экспорт XLS {name}', en: 'Export XLS {name}' },
  'company.no_resident': { ro: 'Nu este rezident', ru: 'Не является резидентом', en: 'Not a resident' },
  'company.added_success': { ro: 'Adăugat cu succes!', ru: 'Успешно добавлен!', en: 'Added successfully!' },
  'company.active_credits': {
    ro: 'Numărul de credite disponibile',
    ru: 'Количество доступных кредитов',
    en: 'Number of credits available',
  },
  'company.credits_after': {
    ro: 'Credite disponibile după descărcare',
    ru: 'Кредиты доступные после загрузки',
    en: 'Credits available after download',
  },
  'company.summary': { ro: 'Achiziția', ru: 'Ваша покупка', en: 'Your purchase' },
  'company.summary_loading': {
    ro: 'Așteptați.. Achiziția este în curs de desfășurare',
    ru: 'Подождите.. Ваша покупка в процесе',
    en: 'Wait.. The purchase is in progress',
  },
  'company.no_description': {
    ro: 'Fără descriere',
    ru: 'Без описания',
    en: 'No description',
  },
  'company.export.general_info_title': {
    ro: 'Informații generale',
    en: 'General info',
    ru: 'Общая информация',
  },
  'company.export.including_20_vat': {
    ro: 'inclusiv, 20% TVA',
    en: 'including, 20% VAT',
    ru: 'включительно, 20% НДС',
  },
  'company.save': {
    ro: 'La achitarea facturii veţi primi acces la exportul dumneavoastră.',
    en: 'Upon payment of the invoice you will receive access to your export.',
    ru: 'После оплаты счета вы получите доступ к вашему экспорту.',
  },
  'company.export.min_sum_alert': {
    ro: 'Atenție! Suma minimă de plată este de 300 MDL. Dacă aveți o comandă mai mică, veți plăti diferența.',
    en: 'Warning! The minimum payment amount is 300 MDL. If you have a smaller order, you will pay the difference.',
    ru: 'Внимание! Минимальная сумма платежа составляет 300 леев. Если у вас меньший заказ, вы оплатите разницу.',
  },
  'company.export.increase_amount_alert': {
    ro: 'Pentru a beneficia la maxim de suma achitată, recomandăm să măriți selecția dumneavoastră.',
    en: 'In order to benefit the most from the amount paid, we recommend that you increase your selection.',
    ru: 'Чтобы получить максимальную выгоду от уплаченной суммы, мы рекомендуем вам увеличить свой выбор.',
  },
  'company.export.total_for_payment': {
    ro: 'Total spre achitare',
    en: 'Total for payment',
    ru: 'Всего к оплате',
  },
  'company.export.minimum_order': {
    ro: 'Taxă comandă minimă',
    en: 'Minimum order fee',
    ru: 'Минимальная плата за заказ',
  },
});

export default company;
