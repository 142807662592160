import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Tabs } from 'antd';
import { useInjectIntl } from '~/libs/localization';
import { Link, isRouteActive } from '~/libs/router';

const ManagementMenu = ({ provider = 'admin' }) => {
  const { t } = useInjectIntl();
  const match = useRouteMatch();
  const { id } = match.params;

  const isActive = routes => (isRouteActive(match, routes) ? 'active' : '');

  const links = {
    admin: [
      {
        text: 'page.accounts',
        route: 'AdminAccountsPage',
        subRoutes: [
          'AdminAddAccountPage',
          'AdminUpdateAccountPage',
          'AdminUsersPage',
          'AdminAddUserPage',
          'AdminUpdateUserPage',
          'AdminAccountProductPage',
          'AdminAccountRequestsPage',
          'AdminAccountMetricsPage',
        ],
      },
      {
        text: 'page.products',
        route: 'AdminProductsPage',
        subRoutes: ['AdminAddProductPage', 'AdminUpdateProductPage'],
      },
      {
        text: 'updates.title',
        route: 'AdminPlatformPage',
        params: { id: isRouteActive(match, 'AdminPlatformPage') ? match.params.id : 'unset' },
        subRoutes: ['AdminAddPlatformPage'],
      },
      {
        text: 'other.feedback',
        route: 'AdminFeedbacksPage',
        params: { type: 'lists', page: 1 },
      },
      {
        text: 'manager.supportTitle',
        route: 'AdminSupportPage',
        params: { type: 'unread', page: 1 },
      },
      {
        text: 'manager.api',
        route: 'AdminApiPage',
        params: {},
      },
      {
        text: 'manager.invoices',
        route: 'AdminInvoicesPage',
        params: {},
      },
      {
        text: 'manager.topCompany',
        route: 'TopCompanyPage',
        params: {},
      },
      {
        text: 'manager.statisticSettings',
        route: 'StatisticSettingsPage',
        params: {},
      },
    ],
    account: [
      {
        text: 'management.accountUsers',
        route: 'AdminUsersPage',
        params: { id },
        subRoutes: ['AdminAddUserPage', 'AdminUpdateUserPage'],
      },
      {
        text: 'management.settings',
        route: 'AdminUpdateAccountPage',
        params: { id },
      },
      {
        text: 'metrics.title',
        route: 'AdminAccountProductPage',
        params: { id },
      },
      {
        text: 'management.requests',
        route: 'AdminAccountRequestsPage',
        params: { id },
      },
      {
        text: 'management.metrics',
        route: 'AdminAccountMetricsPage',
        params: { id },
      },
    ],
  };

  return (
    <Tabs mode="horizontal" className="zh-profile-tabs" activeKey="">
      {links[provider].map(({ subRoutes = [], ...link }) => (
        <Tabs.TabPane
          key={link.route}
          tab={
            !link.disabled ? (
              <Link route={link.route} params={link.params} className={isActive([link.route, ...subRoutes])}>
                {t(link.text)}
              </Link>
            ) : (
              <span className="grey">{t(link.text)}</span>
            )
          }
        />
      ))}
    </Tabs>
  );
};

export default ManagementMenu;
