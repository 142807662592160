import React from 'react';
import { injectIntl } from '~/libs/localization';
import { has } from '~/libs/object';
import { Segment, Info } from '~/components/company';
import { Empty, Map } from '~/components/ui';
import { Icon } from '~/components/ui/Icon/Icon';

class CompanyInfo extends React.PureComponent {
  constructor(props) {
    super(props);

    this.refSegment = React.createRef();

    this.state = {
      mapHeight: 330,
    };
  }

  /* eslint-disable */
  componentDidMount() {
    const { current } = this.refSegment;

    if (current) {
      this.setState({ mapHeight: current.clientHeight + 20 });
    }
  }
  /* eslint-enable */

  render() {
    const { data } = this.props;
    const { t } = this.props;

    const mapLat =
      has(data, 'contact_info.address_de_facto.additional.lat') ||
      has(data, 'contact_info.address_de_facto.additional.lat');

    const mapLong =
      has(data, 'contact_info.address_de_facto.additional.long') ||
      has(data, 'contact_info.address_de_facto.additional.long');

    return (
      <Segment.Halves className="company-with-map" style={{ marginTop: this.props.noMargin ? -20 : null }}>
        <Segment title={t({ id: 'other.infoContacts' })} refs={this.refSegment}>
          {has(data, 'data.contact_info.address_de_jur.title') && (
            <Info label={t({ id: 'company.addressJuridical' })}>
              <Info.Detail icon={<Icon type="company-marker" />} title={data.data.contact_info.address_de_jur.title} />
            </Info>
          )}

          {has(data, 'data.contact_info.address_de_facto.title') && (
            <Info label={t({ id: 'company.addressFacto' })}>
              <Info.Detail
                icon={<Icon type="company-marker" />}
                title={data.data.contact_info.address_de_facto.title}
              />
            </Info>
          )}

          <Info.Halves>
            {data && (
              <Info label={t({ id: 'other.contacts' })}>
                <Info.More icon={<Icon type="company-telephone" />} data={data.contact_info.phones} />
                <Info.More icon={<Icon type="company-fax" />} data={data.contact_info.faxes} />
                <Info.More icon={<Icon type="company-mobile" />} data={data.contact_info.mobile} />
              </Info>
            )}

            {data && (
              <Info label={t({ id: 'other.email' })}>
                <Info.More icon={<Icon type="company-mail" />} data={data.contact_info.emails} textBlue />
              </Info>
            )}
          </Info.Halves>

          <Info.Halves>
            <Info label={t({ id: 'other.website' })}>
              <Info.Detail
                icon={<Icon type="company-website" />}
                title={data ? data.contact_info.sites.join(' , ') : <Empty />}
                websites={data ? data.contact_info.sites : []}
                textBlue
              />
            </Info>

            <Info label={t({ id: 'other.social' })} className="social-media">
              <Info.Media name="facebook" to={has(data, 'contact_info.facebook', undefined)} />
              <Info.Media name="linkedin" to={has(data, 'contact_info.linkedin', undefined)} />
              <Info.Media name="twitter" to={has(data, 'contact_info.twitter', undefined)} />
              <Info.Media name="youtube" to={has(data, 'contact_info.youtube', undefined)} />
            </Info>
          </Info.Halves>
        </Segment>

        <Segment className="google-map">
          {has(data, 'contact_info.address_de_facto.title', false) ||
          has(data, 'contact_info.address_de_judo.title', false) ? (
            <>
              <div className="google-map-board">
                {has(data, 'contact_info.address_de_facto.title') && (
                  <div>
                    <h2>{t({ id: 'company.addressFacto' })}</h2>

                    <p>
                      <Icon type="company-marker" />
                      {has(data, 'contact_info.address_de_facto.title')}
                    </p>
                  </div>
                )}

                {has(data, 'contact_info.address_de_jur.title') && (
                  <div>
                    <h2>{t({ id: 'company.addressJuridical' })}</h2>

                    <p>
                      <Icon type="company-marker" />
                      {has(data, 'contact_info.address_de_jur.title')}
                    </p>
                  </div>
                )}
              </div>
              <Map lat={mapLat} lng={mapLong} fromCenter={150} />
            </>
          ) : (
            <>
              {has(data, 'contact_info.no_resident_address', false) ? (
                <div className="google-map-board">
                  <h2>{t({ id: 'company.no_resident_address' })}</h2>
                  <p>
                    <Icon type="company-marker" /> {data.contact_info.no_resident_address}
                  </p>
                </div>
              ) : (
                <div className="google-map-board not-found">
                  <h2>{t({ id: 'company.addressEmpty' })}</h2>
                </div>
              )}

              <Map />
            </>
          )}
        </Segment>
      </Segment.Halves>
    );
  }
}

export default injectIntl(CompanyInfo);
