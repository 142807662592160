import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect, useDispatch, useSelector, shallowEqual } from 'react-redux';
import { getCompany, resetAvailability, setCompanyLoading } from '~/store/actions/CompanyActions';
import { getRecents } from '~/store/actions/FilterActions';

const enhance = connect(
  ({ company }) => ({ company }),
  dispatch => bindActionCreators({ setCompanyLoading, resetAvailability, getCompany, getRecents }, dispatch),
);

const withCompany = () => Component =>
  enhance(({ match, company = {}, ...props }) => {
    const onFetch = id => {
      props.setCompanyLoading(true);
      props.resetAvailability();

      return props.getCompany(id);
    };

    useEffect(() => {
      window.scrollTo(0, 0);

      if (Number(match.params.id) !== company.id) {
        onFetch(match.params.id);
      }
    }, [match.params.id, company.id]);

    useEffect(() => {
      if (Object.keys(company.data).length > 0) {
        props.getRecents();
      }
    }, [company.data]);

    return (
      <Component
        {...props}
        company={company}
        loading={company.loading}
        availability={company.availability}
        onFetch={onFetch}
      />
    );
  });

export const useCompany = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const store = useSelector(({ company }) => ({ company }), shallowEqual);

  const onFetch = id => {
    dispatch(setCompanyLoading(true));
    dispatch(resetAvailability());

    return dispatch(getCompany(id));
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (Number(params.id) !== store.company.id) {
      onFetch(params.id);
    }
  }, [params.id, store.company.id]);

  useEffect(() => {
    if (store.company.data && Object.keys(store.company.data).length > 0) {
      dispatch(getRecents());
    }
  }, [store.company.data]);

  return {
    company: store.company,
    loading: store.company.loading,
    availability: store.company.availability,
    onFetch,
  };
};

export default withCompany;
