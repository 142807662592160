import React, { useEffect, useState } from 'react';
import { Checkbox, Popover } from 'antd';
import { useStateHandlers } from '~/hooks';
import { useInjectIntl } from '~/libs/localization';
import { EmptyBlock } from '~/components/ui';
import { Icon } from '~/components/ui/Icon/Icon';
import { parseDate } from '~/libs/date';
import '~/assets/scss/components/_LegalBulletinContent.scss';

const LegalBulletinInfo = ({ detail, dataBulletins, selectedBulletins, setSelectedBulletins }) => {
  const { t } = useInjectIntl();

  const [state, setState] = useStateHandlers({
    row_Type: 'read-more-block',
    row_Id: null,
    acts_Id: null,
    bulletin_Id: null,
  });

  const [showId, setShowId] = useState(0);

  useEffect(() => {
    if (dataBulletins.data && dataBulletins?.data?.length) {
      setShowId(prevState => prevState || dataBulletins.data[0].id);
    }
  }, [dataBulletins]);

  const onChangeBulletins = id => {
    setShowId(id);
    setState({ row_Type: 'read-more-block', row_Id: null, acts_Id: null, bulletin_Id: null });
  };

  const onSelectBulletin = (e, id) => {
    if (e.target.checked === true) {
      setSelectedBulletins([...selectedBulletins, id]);
    } else {
      const newArr = selectedBulletins.filter(currentId => currentId !== id);
      setSelectedBulletins(newArr);
    }
  };

  const onChangeReadMore = (name, rowId, actsId, bulletinId) => {
    if (name === state.row_Type) {
      setState({ row_Type: 'read-more-block', row_Id: null, acts_Id: null, bulletin_Id: bulletinId });
    } else {
      setState({ row_Type: name, row_Id: rowId, acts_Id: actsId, bulletin_Id: bulletinId });
    }
  };

  return (
    <>
      {dataBulletins?.data?.length > 0 ? (
        dataBulletins.data.map(bulletin => (
          <div
            className={`bulletin${selectedBulletins.find(currentId => currentId === bulletin.id) ? ' active' : ''}`}
            key={bulletin.id}
          >
            <div className="bulletin-header" onClick={() => onChangeBulletins(bulletin.id)}>
              <div className="icon-wrap">
                <Icon type="bulletin" />
              </div>

              <div className="title">
                {bulletin.headers.name_acts} • {bulletin.headers.number_acts} • {bulletin.headers.date_acts}
                <div className="date">
                  <Icon type="clock" />
                  {bulletin.headers.date_created.slice(0, 10).replaceAll('-', '.')}
                </div>
                <ul className="bulletin-list-info">
                  <li>
                    {bulletin.headers.pages_count || 0} {t('legalBulletin.pages')}
                  </li>
                  <li>
                    <div className="list-point" /> {bulletin.headers.read_minute_count || 0}{' '}
                    {t('legalBulletin.read-time')}
                  </li>
                  <li>
                    <div className="list-point" /> {bulletin.headers.laws_count || 0} {t('legalBulletin.lege')}
                  </li>
                  <li>
                    <div className="list-point" /> {bulletin.headers.decisions_count || 0} {t('legalBulletin.guvern')}
                  </li>
                  <li>
                    <div className="list-point" /> {bulletin.headers.opinions_count || 0} {t('legalBulletin.avize')}
                  </li>
                </ul>
              </div>
            </div>

            <div className="wrap-industries">
              <div className="title">{t('legalBulletin.industryAffected')}</div>

              <div className="industries">
                <div className="industries__items">
                  {bulletin.message_text && bulletin.message_text.map(item => item)}
                </div>
                {!detail && <Checkbox onChange={e => onSelectBulletin(e, bulletin.id)} className="bulletin-checkbox" />}
              </div>
            </div>
            {bulletin.acts.map((acts, key) => (
              <div className={`pse-table ${showId !== bulletin.id ? 'pse-table-close' : ''}`} key={acts.id}>
                <div className="key">
                  <span>{key < 10 ? `0${key + 1}` : key + 1}</span>
                </div>

                <div className="wrap-items">
                  <div className="item">
                    <div className="title">{t('legalBulletin.actName')}</div>
                    <p>{acts.title}</p>

                    <div className="normative-act">
                      <div className="title">{t('legalBulletin.authority')}</div>
                      <p>{acts.authority.toUpperCase()}</p>
                    </div>
                  </div>

                  <div className="item">
                    <div className="title">{t('legalBulletin.rezume')}</div>
                    <div
                      className={
                        state.row_Id === 1 && state.acts_Id === acts.id && state.bulletin_Id === bulletin.id
                          ? state.row_Type
                          : 'read-more-block'
                      }
                    >
                      <>
                        <div dangerouslySetInnerHTML={{ __html: acts.description }} />
                        <span className="gap" />
                      </>
                    </div>

                    {acts.description.length > 200 ? (
                      <span
                        className="read-more-btn"
                        onClick={() => onChangeReadMore('first_row', 1, acts.id, bulletin.id)}
                      >
                        {state.row_Id === 1 && state.acts_Id === acts.id && state.bulletin_Id === bulletin.id
                          ? t('legalBulletin.readClose')
                          : t('legalBulletin.readMore')}
                      </span>
                    ) : null}
                  </div>

                  <div className="item">
                    <div className="title">{t('legalBulletin.affected')}</div>

                    {state.row_Id === 2 && state.acts_Id === acts.id && state.bulletin_Id === bulletin.id ? (
                      <p className="act-industries">{acts.industries_text && acts.industries_text.map(item => item)}</p>
                    ) : (
                      <Popover
                        placement="bottomLeft"
                        content={
                          <div className="industries-popover">
                            {acts.industries_text && acts.industries_text.map(item => item)}{' '}
                          </div>
                        }
                        trigger="hover"
                        overlayClassName="menu-description-popover"
                      >
                        <div className="link-list">
                          {acts.industries_text && (
                            <p className="act-industries">
                              {acts.industries_text[0]}{' '}
                              {acts.industries_text.length > 1 ? ` +${acts.industries_text.length - 1}` : ''}
                            </p>
                          )}
                        </div>
                      </Popover>
                    )}

                    <div className="normative-act">
                      {acts.datetime_start && (
                        <div className="left">
                          <div className="title">{t('legalBulletin.vigorDate')}</div>
                          <div className="date">{parseDate(acts.datetime_start)}</div>
                        </div>
                      )}
                      <div className="right">
                        <a href={acts.link} className="normative-act-link" target="_blank" rel="noopener noreferrer">
                          {t('legalBulletin.normativeAct')}
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="item">
                    <div className="title">{t('legalBulletin.actionsTaken')}</div>
                    <div
                      className={
                        state.row_Id === 2 && state.acts_Id === acts.id && state.bulletin_Id === bulletin.id
                          ? state.row_Type
                          : 'read-more-block'
                      }
                    >
                      <div dangerouslySetInnerHTML={{ __html: acts.actions && acts.actions }} />
                    </div>
                    {acts.actions.length > 200 ? (
                      <span
                        className="read-more-btn"
                        onClick={() => onChangeReadMore('second_row', 2, acts.id, bulletin.id)}
                      >
                        {state.row_Id === 2 && state.acts_Id === acts.id
                          ? t('legalBulletin.readClose')
                          : t('legalBulletin.readMore')}
                      </span>
                    ) : null}
                  </div>

                  <div className="item">
                    <div className="title">{t('legalBulletin.explication')}</div>
                    <div
                      className={
                        state.row_Id === 3 && state.acts_Id === acts.id && state.bulletin_Id === bulletin.id
                          ? state.row_Type
                          : 'read-more-block'
                      }
                    >
                      <>
                        <div dangerouslySetInnerHTML={{ __html: acts.explanation }} />
                        <span className="gap" />
                      </>
                    </div>
                    {acts.explanation.length > 200 ? (
                      <span
                        className="read-more-btn"
                        onClick={() => onChangeReadMore('third_row', 3, acts.id, bulletin.id)}
                      >
                        {state.row_Id === 3 && state.acts_Id === acts.id && state.bulletin_Id === bulletin.id
                          ? t('legalBulletin.readClose')
                          : t('legalBulletin.readMore')}
                      </span>
                    ) : null}
                  </div>

                  <div className="item">
                    <div className="title">{t('legalBulletin.concluzion')}</div>
                    <div
                      className={
                        state.row_Id === 3 && state.acts_Id === acts.id && state.bulletin_Id === bulletin.id
                          ? state.row_Type
                          : 'read-more-block'
                      }
                    >
                      <p>
                        {acts.conclusion}
                        <span className="gap" />
                      </p>
                    </div>
                    {acts.conclusion.length > 200 ? (
                      <span
                        className="read-more-btn"
                        onClick={() => onChangeReadMore('third_row', 3, acts.id, bulletin.id)}
                      >
                        {state.row_Id === 3 && state.acts_Id === acts.id && state.bulletin_Id === bulletin.id
                          ? t('legalBulletin.readClose')
                          : t('legalBulletin.readMore')}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))
      ) : (
        <EmptyBlock />
      )}
    </>
  );
};

export default LegalBulletinInfo;
