import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Input, Switch, Button, Select } from 'antd';
import { useRequest } from 'estafette';
import { useStateHandlers, useDocumentTitle } from '~/hooks';
import { Loader, Confirmation } from '~/components/ui';
import { ManagementMenu } from '~/components/management';
import { Layout } from '~/components/layouts';
import { validate } from '~/libs/object';
import { getRoute, Link } from '~/libs/router';
import { injectIntl } from '~/libs/localization';
import { managementAccounts } from '~/libs/http/api';

const AdminAddAccountPage = ({ t, ...props }) => {
  useDocumentTitle(t('page.accounts'));
  const { id } = useParams();
  const history = useHistory();

  const { request: requestDetails, loading: loadingDetails } = useRequest({ loading: Boolean(id) });
  const { request: requestSubmit, errors, loading: loadingSubmit } = useRequest();
  const { request: requestDelete, loading: loadingDelete } = useRequest();
  const { request: requestManager, data: managers, loading: loadingManagers } = useRequest();

  const [state, setState] = useStateHandlers({
    juridical: false,
    active: true,
    address: undefined,
    manager: undefined,
    name: '',
    email: '',
    idno: '',
  });

  const onSubmit = async ev => {
    ev.preventDefault();

    const options = {
      is_juridical: state.juridical,
      is_active: state.active,
      name: state.name,
      email: state.email,
      idno: state.idno,
      address: state.address,
      manager: state.manager,
    };

    id && (options.id = id);
    await requestSubmit(managementAccounts[id ? 'edit' : 'add'](options));
    history.push(getRoute('AdminAccountsPage'));
  };

  const onChangeName = ({ target }) => setState({ name: target.value });
  const onChangeEmail = ({ target }) => setState({ email: target.value });
  const onChangeAddress = ({ target }) => setState({ address: target.value });
  const onChangeIdno = ({ target }) => setState({ idno: target.value });
  const onChangeJuridical = juridical => setState({ juridical });
  const onChangeActive = active => setState({ active });
  const onChangeManagers = manager => setState({ manager });

  const onConfirm = async () => {
    await requestDelete(managementAccounts.delete({ id }));

    history.push(getRoute('AdminAccountsPage'));
  };

  const getDetails = async () => {
    if (id) {
      const res = await requestDetails(managementAccounts.getAccountById({ id }));

      setState({
        juridical: res.is_juridical,
        active: res.is_active,
        name: res.name || undefined,
        email: res.email || undefined,
        idno: res.idno || undefined,
        address: res.address || undefined,
        manager: res.manager_id || undefined,
      });
    }
  };

  const getManagers = async () => {
    requestManager(managementAccounts.managers());
  };

  useEffect(() => {
    getManagers();

    if (id) {
      getDetails();
    }
  }, [id]);

  return (
    <Layout containerClass="updatesEdit-container">
      <ManagementMenu />

      {id && <ManagementMenu provider="account" />}

      <Loader loading={loadingDetails}>
        <div className="half-form">
          <Form onSubmit={onSubmit}>
            <div className="title">{t('juridical.infoGen')}</div>

            <Form.Half>
              <Form.Item label={t('management.accountStatus')}>
                <span className="status-switch" style={{ width: 110 }}>
                  <Switch checked={state.active} size="small" onChange={onChangeActive} />
                  <span className="status-switch-title">
                    {state.active ? t('management.activated') : t('management.activated')}
                  </span>
                </span>
              </Form.Item>

              <Form.Item label={t('management.accountType')}>
                <span className="status-switch" style={{ width: 110 }}>
                  <Switch checked={state.juridical} size="small" onChange={onChangeJuridical} />
                  <span className="status-switch-title">
                    {state.juridical ? t('profile.juridical') : t('profile.notJuridical')}
                  </span>
                </span>
              </Form.Item>
            </Form.Half>

            <Form.Half>
              <Form.Item label={t('profile.companyName')} {...validate(errors, 'name')}>
                <Input placeholder={t('profile.companyName')} value={state.name} onChange={onChangeName} />
              </Form.Item>

              <Form.Item label={t('profile.companyEmail')} {...validate(errors, 'email')}>
                <Input placeholder={t('profile.companyEmail')} value={state.email} onChange={onChangeEmail} />
              </Form.Item>
            </Form.Half>

            <Form.Half>
              <Form.Item label={t('profile.companyAddress')} {...validate(errors, 'address')}>
                <Input placeholder={t('profile.companyAddress')} value={state.address} onChange={onChangeAddress} />
              </Form.Item>

              <Form.Item label={t('management.manager')} {...validate(errors, 'manager')}>
                <Select
                  allowClear
                  loading={loadingManagers}
                  placeholder={t('management.manager')}
                  placement="bottomRight"
                  dropdownClassName="responsive-table-dropdown"
                  value={state.manager}
                  onChange={onChangeManagers}
                >
                  {managers.map(manager => (
                    <Select.Option value={manager.id} key={manager.id}>
                      {manager.username}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Form.Half>

            <Form.Half>
              <Form.Item label={t('company.idno')} {...validate(errors, 'idno')}>
                <Input placeholder={t('company.idno')} value={state.idno} onChange={onChangeIdno} />
              </Form.Item>
            </Form.Half>

            <div className="half-form-footer">
              {id && (
                <div className="half-form-footer-left">
                  <Confirmation type="delete" onConfirm={onConfirm}>
                    <Button loading={loadingDelete} className="cancel-btn">
                      {t('other.delete')}
                    </Button>
                  </Confirmation>
                </div>
              )}

              <div className="half-form-footer-right">
                <Link route={id ? 'AdminUsersPage' : 'AdminAccountsPage'} params={{ id }}>
                  <Button>{t('billing.back')}</Button>
                </Link>

                <Button type="primary" htmlType="submit" loading={loadingSubmit || loadingDelete}>
                  {t('other.submit')}
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Loader>
    </Layout>
  );
};

export default injectIntl(AdminAddAccountPage);
