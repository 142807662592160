import axios from '~/libs/http/axios';

const { baseURL } = axios.defaults;

const dashboard = {
  list: (params = {}) => axios.get(`${baseURL}/api/dashboard/list`, { params }),
  info: (params = {}) => axios.get(`${baseURL}/api/dashboard/info`, { params }),
  nomenclatures: (params = {}) => axios.get(`${baseURL}/api/dashboard/nomenclatures`, { params }),
  recents: (params = {}) => axios.get(`${baseURL}/api/dashboard/visited`, { params }),
  changePackage: (params = {}) => axios.post(`${baseURL}/api/profile/set-default-package`, { params }),
};

export default dashboard;
