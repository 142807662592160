import React from 'react';
import { injectIntl } from '~/libs/localization';
import { Empty } from '~/components/ui';
import { JuridicalCard } from '~/components/company/juridical';

const JuridicalPublications = ({ t, monitorOfficial }) => (
  <div className="juridical-content">
    <h2>{t({ id: 'juridical.publicationHeader' })}</h2>

    {monitorOfficial.map(publication => (
      <JuridicalCard type="publication" key={publication.id}>
        <small>{publication.date}</small>
        <h2>{publication.type.title || <Empty />}</h2>
        <p>{publication.text.title || <Empty />}</p>
      </JuridicalCard>
    ))}
  </div>
);

export default injectIntl(JuridicalPublications);
