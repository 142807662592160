import React, { useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Dropdown, Menu, Button } from 'antd';
import { Link } from '~/libs/router';
import { changeLocalization, setLocalizationLoading } from '~/store/actions/LocalizationActions';
import { resetCompany } from '~/store/actions/CompanyActions';
import { resetEmployee } from '~/store/actions/EmployeeActions';
import { resetSubdivision } from '~/store/actions/SubdivisionActions';
import { useCurrentLanguage } from '~/hooks';
import { useInjectIntl } from '~/libs/localization';
import { LogoBlue } from '~/components/svg';
import { Icon } from '~/components/ui/Icon/Icon';

import '~/assets/scss/components/_Footer.scss';

const Footer = () => {
  const { t } = useInjectIntl();
  const dispatch = useDispatch();

  const store = useSelector(
    ({ filter, localization, authentication }) => ({
      showFilter: filter.showFilter,
      localization,
      permissions: authentication.permissions,
    }),
    shallowEqual,
  );

  const [localizationFade, setLocalizationFade] = useState(false);

  const onChangeLocalization = visible => setLocalizationFade(visible);

  const onChangeLanguage = async ({ key }) => {
    await dispatch(setLocalizationLoading(true));
    dispatch(changeLocalization(key));

    // there we have to reset company, employee and subdivisions data
    dispatch(resetCompany());
    dispatch(resetEmployee());
    dispatch(resetSubdivision());
  };

  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="container">
          <div className="left-column">
            <div className="links-list">
              <LogoBlue />
              <ul>
                <li>
                  <Link route="Search" params={{ page: 1, query: '' }}>
                    {t('footer.searchPage')}
                  </Link>
                </li>

                {/* <li>
                  <Link route="Search" params={{ page: 1, query: '' }}>
                    {t('footer.lookEmployees')}
                  </Link>
                </li> */}

                <li>
                  <Link route="SavedListsPage" params={{ page: 1 }}>
                    {t('footer.savedLists')}
                  </Link>
                </li>

                <li>
                  <Link route="SavedSearchesPage" params={{ page: 1 }}>
                    {t('footer.mySearches')}
                  </Link>
                </li>

                <li>
                  <Link route="DownloadsPage" params={{ type: 'list', page: 1 }}>
                    {t('footer.downloads')}
                  </Link>
                </li>
              </ul>
            </div>

            <div className="links-list">
              <div className="title">{t('footer.userBoard')}</div>
              <ul>
                {/* TODO: for later */}
                {/* <li>
                  <Link route="HomePage">{t('footer.howItWorks')}</Link>
                </li> */}
                <li>
                  <a href={useCurrentLanguage('ab')} target="_blank" rel="noopener noreferrer">
                    {t('footer.prices')}
                  </a>
                </li>
                <li>
                  <Link route="MyProfilePage">{t('footer.updateYourAccount')}</Link>
                </li>
                <li>
                  <a href={useCurrentLanguage('c')} target="_blank" rel="noopener noreferrer">
                    {t('footer.helpCenter')}
                  </a>
                </li>
                <li>
                  <Link route="HomePage" className="soon">
                    {t('footer.mobileApplication')} <span>*</span> {t(`footer.soon`)}
                  </Link>
                </li>
              </ul>

              <a href={useCurrentLanguage('c')} target="_blank" rel="noopener noreferrer">
                <Button className="report-problem-button">
                  <Icon type="alert-circle" />
                  {t('footer.reportProblem')}
                </Button>
              </a>
            </div>

            <div className="links-list">
              <div className="title">{t('footer.information')}</div>
              <ul>
                <li>
                  <a href={useCurrentLanguage('a')} target="_blank" rel="noopener noreferrer">
                    {t('footer.aboutInformer')}
                  </a>
                </li>
                <li>
                  <a href={useCurrentLanguage('a', 'a?anchor=team')} target="_blank" rel="noopener noreferrer">
                    {t('footer.team')}
                  </a>
                </li>
                {/* TODO: for later */}
                {/* <li>
                  <Link route="HomePage">{t('footer.careers')}</Link>
                </li>
                <li>
                  <Link route="HomePage">{t('footer.affiliates')}</Link>
                </li> */}
                <li>
                  <a href={useCurrentLanguage('n')} target="_blank" rel="noopener noreferrer">
                    {t('footer.news')}
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="right-column">
            <div className="contacts">
              <div className="mail">
                <a href="mailto:support@informer.md" target="_blank" rel="noopener noreferrer">
                  support@informer.md
                </a>
              </div>

              <div className="phone">
                <a href="tel:+37322022097">+373 22 022 097</a>
              </div>

              <div className="schedule">{t('footer.scheduleOpen')}</div>
              {/* TODO: for later */}
              {/* <div className="social">
                <ul>
                  <li>
                    <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                      <Icon type="footer-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
                      <Icon type="footer-linkedin" />
                    </a>
                  </li>
                  <li>
                    <a href="https://youtube.com" target="_blank" rel="noopener noreferrer">
                      <Icon type="footer-youtube" />
                    </a>
                  </li>
                </ul>
              </div> */}

              <div className="localization">
                <Dropdown
                  onVisibleChange={onChangeLocalization}
                  overlay={
                    <Menu onClick={onChangeLanguage}>
                      {store.localization.available.map(({ name, shortName }) =>
                        shortName !== store.localization.current.shortName ? (
                          <Menu.Item key={shortName} className="left localization-item">
                            <Icon type={`flag-${shortName}`} className="flag" />
                            {name}
                          </Menu.Item>
                        ) : (
                          <Menu.Item key={shortName} className="left localization-item">
                            <Icon type={`flag-${shortName}`} />
                            {name}
                            <Icon type="menu-check" />
                          </Menu.Item>
                        ),
                      )}
                    </Menu>
                  }
                  trigger={['click']}
                >
                  <Button className={`localization-btn ${localizationFade ? ' active' : ''}`}>
                    <span className="flag-wrap">
                      <Icon type={`flag-${store.localization.current.shortName}`} />
                    </span>
                    {store.localization.current.name}
                    <Icon type="lang-arrow-right" />
                  </Button>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="left">© 2020 Informer.</div>
          <div className="center">
            <ul className="navigation">
              {/* TODO: for later */}
              {/* <li>
                <a href="/">{t('footer.privacyPolicy')}</a>
              </li>
              <li>•</li> */}
              <li>
                <a href={useCurrentLanguage('t')} target="_blank" rel="noopener noreferrer">
                  {t('footer.termsAndConditions')}
                </a>
              </li>
            </ul>
          </div>
          {/* <div className="right">
            <span>Made by</span>
            <div>
              <img src="/assets/img/ebs.png" alt="" />
            </div>
          </div> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
