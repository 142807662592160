import React from 'react';
import { injectIntl } from '~/libs/localization';
import { rowCount } from '~/libs/number';
import { has } from '~/libs/object';
import { Empty } from '~/components/ui';
import { JuridicalCard } from '~/components/company/juridical';

const JuridicalProcessParticipants = ({ t, processParticipants }) => (
  <div className="juridical-content">
    {processParticipants.length
      ? processParticipants.map((participant, key) => (
          <JuridicalCard.Section type="rows" key={participant.id}>
            <JuridicalCard number={rowCount(key)} type="label-status-thin">
              <h2>Participant</h2>
              <p className="text-blue">{participant.title}</p>
            </JuridicalCard>

            <JuridicalCard type="label-status-thin">
              <h2>{t({ id: 'juridical.processQuality' })}</h2>
              <p>{has(participant, 'participant_role.title', <Empty />)}</p>
            </JuridicalCard>
          </JuridicalCard.Section>
        ))
      : null}
  </div>
);

export default injectIntl(JuridicalProcessParticipants);
