import React from 'react';

const Payment = ({ response }) => (
  <form action={response.acs_url || ''} method="post" name="autosubmit">
    <input type="hidden" name="PaReq" value={response.payload || ''} />

    <input type="hidden" name="TermUrl" value={response.term_url} />

    <input type="hidden" name="MD" value="madata" />
  </form>
);

export default Payment;
