import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Link, getRoute } from '~/libs/router';
import { Text } from '~/components/ui';
import { Icon } from '~/components/ui/Icon/Icon';

const Tab = props =>
  props.disabled ? (
    <Link
      route={props.route}
      params={{ id: props.id }}
      className={props.activePage(getRoute(props.route, { id: props.id }))}
    >
      {props.loading && props.active ? <Icon type="loading" /> : props.icon}
      {props.children}
    </Link>
  ) : (
    <span className="disabled">
      {props.icon}
      {props.children}
    </span>
  );

const JuridicalTabs = ({ loading, availability }) => {
  let active = false;
  const match = useRouteMatch();
  const activePage = url => {
    if (match.url === url) {
      active = true;
      return 'active';
    }

    active = false;
    return '';
  };

  return availability.juridical ? (
    <div className="juridical-tabs">
      <Tab
        icon={<Icon type="juridical-reorganization" />}
        disabled={availability.juridical.reorganization}
        route="ReorganizationPage"
        activePage={activePage}
        id={match.params.id}
        active={active}
        loading={loading}
      >
        <Text id="juridical.tabs.1" />
      </Tab>

      <Tab
        icon={<Icon type="juridical-default" />}
        disabled={availability.juridical.jurisprudence}
        route="JurisprudencePage"
        activePage={activePage}
        id={match.params.id}
        active={active}
        loading={loading}
      >
        <Text id="juridical.tabs.2" />
      </Tab>

      <Tab
        icon={<Icon type="juridical-jurisprudence" />}
        disabled={availability.juridical.insolvency}
        route="InsolvencyPage"
        activePage={activePage}
        id={match.params.id}
        active={active}
        loading={loading}
      >
        <Text id="juridical.tabs.3" />
      </Tab>
    </div>
  ) : null;
};

export default JuridicalTabs;
