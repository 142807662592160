import React from 'react';
import { useHistory } from 'react-router-dom';
import { useInjectIntl } from '~/libs/localization';
import { Layout } from '~/components/layouts';
import { EmptyBlock, Button } from '~/components/ui';
import { getRoute } from '~/libs/router';
import { useDocumentTitle } from '~/hooks';

const NotAllowedPage = () => {
  const history = useHistory();
  const { t } = useInjectIntl();
  useDocumentTitle(t('page.home'));

  return (
    <Layout>
      <EmptyBlock
        title={t({ id: 'statics.notFound' })}
        desc=" "
        button={
          <>
            <Button type="primary" onClick={() => history.push(getRoute('HomePage'))}>
              {t({ id: 'statics.home' })}
            </Button>

            <Button
              type="secondary"
              onClick={() => history.push(getRoute('SearchResultsPage', { type: 'company', mode: 'basic' }))}
            >
              {t({ id: 'home.searchCompanies' })}
            </Button>
          </>
        }
        type="notAllowed"
      />
    </Layout>
  );
};

export default NotAllowedPage;
