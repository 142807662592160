import React, { useEffect, useCallback } from 'react';
import withCompany from '~/hoc/withCompany';
import { useDocumentTitle, useStateHandlers } from '~/hooks';
import { useInjectIntl } from '~/libs/localization';
import { rowCount } from '~/libs/number';
import { has } from '~/libs/object';
import { CompanyLayout } from '~/components/layouts';
import { Segment, Info } from '~/components/company';
import { JuridicalInfo } from '~/components/company/juridical';
import { Loader, Pagination } from '~/components/ui';
import { Icon } from '~/components/ui/Icon/Icon';

const JurisprudencePage = ({ company: { data: api, loading } }) => {
  const { t } = useInjectIntl();
  useDocumentTitle(t('page.jurisprudence'));
  const [state, setState] = useStateHandlers({
    showMore: false,
    record: {},
    recordNumber: '',
    type: '',

    data: [],
    paginatedData: [],
    page: 1,
    perPage: 5,
    pages: 1,
  });

  useEffect(() => {
    const onSaveData = () => {
      if (api.juridical && api.juridical.jurisprudence) {
        const data = [];

        api.juridical.jurisprudence.forEach(({ steps, process_type: processType = {} }, index) =>
          steps.forEach((step, index2) =>
            data.push({
              ...step,
              number: `${rowCount(index)}${index2 ? `.${index2}` : ''}`,
              processType,
            }),
          ),
        );

        setState({ data });
      }
    };

    onSaveData();
  }, [api.juridical]);

  useEffect(() => {
    const onPaginate = () => {
      const indexOfLast = state.page * state.perPage;
      const indexOfFirst = indexOfLast - state.perPage;
      state.data.slice(indexOfFirst, indexOfLast);

      setState({
        paginatedData: state.data.slice(indexOfFirst, indexOfLast),
        pages: Math.ceil(state.data.length / state.perPage),
      });
    };

    onPaginate();
  }, [state.data, state.page]);

  const onChangePage = page => {
    window.scrollTo(0, 0);
    setState({ page });
  };

  const onOpenInformation = useCallback(step => {
    setState({
      showMore: true,
      record: step,
      recordNumber: step.number,
      type: step.processType.title,
    });
  }, []);

  return (
    <>
      {state.showMore && (
        <JuridicalInfo
          type={state.type}
          record={state.record}
          recordNumber={state.recordNumber}
          onClose={() => setState({ showMore: false })}
        />
      )}

      <CompanyLayout juridical>
        <Loader loading={loading}>
          {state.data.length > 0 ? (
            <>
              <Segment.Header
                className="juridical-header"
                noBorder
                title={`${t({ id: 'juridical.files' })} (${state.data.length})`}
              />

              {state.paginatedData.map(step => (
                <Segment key={step.id} number={step.number} title={step.subject.title} hasData>
                  <Info.Halves>
                    <Info label={t({ id: 'juridical.fileNum' })}>
                      <Info.Detail title={step.number} />
                    </Info>

                    <Info label={t({ id: 'juridical.instance' })}>
                      <Info.Detail title={has(step, 'instance.title', false)} />
                    </Info>

                    <Info label={t({ id: 'juridical.partsProcess' })}>
                      {step.process_participants.map(participant => (
                        <Info.Detail key={participant.id} title={participant.title} textBlue />
                      ))}
                    </Info>

                    <Info label={t({ id: 'juridical.subject' })}>
                      <Info.Detail title={has(step, 'subject.title', false)} />
                    </Info>

                    <Info label={t({ id: 'juridical.juridicalType' })}>
                      <Info.Detail title={step.processType.title} />
                    </Info>
                  </Info.Halves>

                  <div className="whole-information" onClick={() => onOpenInformation(step)} role="presentation">
                    <span>
                      {t({ id: 'other.showAll' })} <Icon type="arrow-right" />
                    </span>
                  </div>
                </Segment>
              ))}
            </>
          ) : null}

          <Pagination className="mt-20" page={state.page} total={state.pages} onChange={onChangePage} />
        </Loader>
      </CompanyLayout>
    </>
  );
};

export default withCompany()(JurisprudencePage);
