import React, { useState } from 'react';
import { Input as AntdInput } from 'antd';
import { Icon } from '~/components/ui/Icon/Icon';

const InputPassword = ({ ...props }) => {
  const [hidePassword, setHidePassword] = useState(true);

  const handlerOnClick = () => {
    setHidePassword(!hidePassword);
  };

  return (
    <AntdInput
      {...props}
      type={hidePassword ? 'password' : 'text'}
      suffix={<Icon type="public-icon-eye" onClick={handlerOnClick} />}
    />
  );
};

export default InputPassword;
