import React from 'react';
import env from '@beam-australia/react-env';
import { injectIntl } from '~/libs/localization';
import { Avatar } from '~/components/profile';
import { Time } from '~/components/other';

import '~/assets/scss/components/ui/_UserCart.scss';

const UserCart = ({ item, t }) => (
  <div className="zh-user-cart">
    <Avatar.Small img={item.photo ? `${env('API_URL') || ''}/${item.photo}` : null} alt={`${item.name}`} />

    <div className="text">
      <span>
        {item.name}
        {item.is_staff && <div className="zh-user-cart-tag site-admin">{t('profile.site_admin')}</div>}
        {!item.is_approved && <div className="zh-user-cart-tag not-approved">{t('profile.not_approved')}</div>}
      </span>

      <small>
        {item.job_title || ''}

        {item.last_activity && (
          <>
            {t({ id: 'team.registred' })} <Time date={item.date_created} format="visit" />
            <span className="span-separator">•</span>
            {t({ id: 'team.visited' })} <Time date={item.last_activity} format="visit" />
          </>
        )}
      </small>
    </div>
  </div>
);

export default injectIntl(UserCart);
