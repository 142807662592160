import { adapterIntl } from '~/libs/localization/helpers';

const unavailableData = adapterIntl({
  'unavailableData.only_subscribed_users': {
    ro: 'Aceste informații sunt disponibile numai pentru utilizatorii abonați',
    ru: 'Эта информация доступна только подписчикам',
    en: 'This information is only available to subscribed users',
  },
});

export default unavailableData;
