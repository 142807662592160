import React, { memo, useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Alert, Switch, Select, Form, Button, Input } from 'antd';
import { useRequest } from 'estafette';
import { useStateHandlers, useDocumentTitle } from '~/hooks';
import { getRoute, Link } from '~/libs/router';
import { notification } from '~/libs/notification';
import { validate } from '~/libs/object';
import { useInjectIntl } from '~/libs/localization';
import { managementProduct } from '~/libs/http/api';
import { Loader } from '~/components/ui';
import { Layout } from '~/components/layouts';
import { ManagementMenu, PermissionBlock, Permission } from '~/components/management';
import { Icon } from '~/components/ui/Icon/Icon';

const mapArrayToObject = (arr = []) => {
  if (arr.length === 0) {
    // return at least one input in list
    return { 0: '' };
  }

  const obj = {};
  arr.forEach((item, key) => (obj[key] = item));
  return obj;
};

const mapObjectToArray = (obj = {}) =>
  Object.keys(obj)
    .map(key => obj[key])
    .filter(i => i);

export const findPermissionFromState = (perms, permId) => perms.find(({ id }) => permId === id);

export const addOrRemovePermission = (perms, perm) =>
  perms.find(item => item.id === perm.id) ? perms.filter(item => item.id !== perm.id) : [...perms, { ...perm }];

const AdminAddProductPage = () => {
  const { t } = useInjectIntl();
  const { id } = useParams();
  const history = useHistory();
  const { request: requestDetails, loading: loadingDetails } = useRequest({ loading: Boolean(id) });
  const { request: requestSubmit, loading: loadingSubmit, errors } = useRequest();
  const { request: requestPermissions, data: dataPermission, loading: loadingPermissions } = useRequest({
    loading: true,
  });
  const [permissionGroups, setPermissionGroups] = useState([]);
  const [activeGroup, setActiveGroup] = useState([]);
  const [state, setState] = useStateHandlers({
    frequency: undefined,
    title: { ro: '', en: '', ru: '' },
    permissions: [],
    price: null,
    private: false,
    publicDescription: { ro: { 0: '' }, en: { 0: '' }, ru: { 0: '' } },
    requestDemo: false,
  });

  useDocumentTitle(t(`management.${id ? 'update' : 'create'}_product`));

  useEffect(() => {
    const onFetchDetails = async () => {
      requestPermissions(managementProduct.permissions());

      if (id) {
        const data = await requestDetails(managementProduct.get({ id }));

        setState({
          frequency: data.frequency,
          title: data.languages,
          permissions: data.permissions,
          price: data.price,
          private: data.private,
          publicDescription: {
            ro: mapArrayToObject(data.public_description.ro),
            en: mapArrayToObject(data.public_description.en),
            ru: mapArrayToObject(data.public_description.ru),
          },
          requestDemo: data.request_demo,
        });
      }
    };

    onFetchDetails();
  }, [id]);

  useEffect(() => {
    if (dataPermission) {
      const permissionGroup = dataPermission.reduce((prev, curr) => [...prev, curr.permission_type], []);

      const jsonObject = permissionGroup.map(JSON.stringify);

      const uniqueSet = new Set(jsonObject);

      const uniqueArray = Array.from(uniqueSet).map(JSON.parse);

      const buildGroup = uniqueArray.map(item => ({
        ...item,
        data: dataPermission.filter(d => d.permission_type.id === item.id),
      }));
      setPermissionGroups(buildGroup);
    }
  }, [dataPermission, id]);

  const onSubmit = async ev => {
    ev.preventDefault();

    const options = {
      languages: state.title,
      price: state.price,
      frequency: state.frequency,
      request_demo: state.requestDemo,
      public_description: {
        ro: mapObjectToArray(state.publicDescription.ro),
        en: mapObjectToArray(state.publicDescription.en),
        ru: mapObjectToArray(state.publicDescription.ru),
      },
      private: state.private,
      permissions: state.permissions.map(permission => ({
        active: true,
        id: permission.id,
        daily_limit: permission.daily_limit || undefined,
        limit: permission.limit || undefined,
        vas: permission.vas || undefined,
        switch_on: permission.switch_on || undefined,
      })),
    };

    id && (options.id = id);

    await requestSubmit(managementProduct[id ? 'edit' : 'add'](options));

    notification({
      title: t(`management.${id ? 'update' : 'create'}_product`),
      message: t(`notification.product.message.${id ? 'update' : 'create'}`),
    });

    history.push(getRoute('AdminProductsPage'));
  };

  const onChangePrivate = value => setState({ private: value });

  const onChangeRequestDemo = requestDemo => setState({ requestDemo });

  const onChangePrice = ({ target }) => setState({ price: target.value });

  const onChangeFrequency = frequency => setState({ frequency });

  const onChangeTitle = useCallback((lang, value) => setState({ title: { ...state.title, [lang]: value } }), [
    state.title,
  ]);

  const onChangePublicDescription = useCallback(
    (lang, key, value) => {
      setState({
        publicDescription: {
          ...state.publicDescription,
          [lang]: {
            ...state.publicDescription[lang],
            [key]: value,
          },
        },
      });
    },
    [state.publicDescription],
  );

  const onCreatePublicDescription = useCallback(
    (lang, value = '') => {
      setState({
        publicDescription: {
          ...state.publicDescription,
          [lang]: {
            ...state.publicDescription[lang],
            [Object.keys(state.publicDescription[lang]).length]: value,
          },
        },
      });
    },
    [state.publicDescription],
  );

  const onChangePermission = useCallback(
    (permId, type, value) => {
      setState({
        permissions: state.permissions.map((permission, key) =>
          permId !== permission.id ? permission : { ...permission, [type]: value },
        ),
      });
    },
    [state.permissions],
  );

  const onCreatePermission = useCallback(
    permission => {
      setState({ permissions: addOrRemovePermission(state.permissions, permission) });
    },
    [state.permissions],
  );

  const onAddCategory = useCallback(
    category => {
      setState({
        permissions: state.permissions.find(item => item.permission_type.id === category.id)
          ? state.permissions.filter(item => item.permission_type.id !== category.id)
          : [...state.permissions, ...category.data.filter(item => item.permission_type.id === category.id)],
      });
      onSelectGroup(category.id);
    },
    [state.permissions, dataPermission, permissionGroups],
  );

  useEffect(() => {
    if (state.permissions) {
      setActiveGroup(state.permissions.map(item => item.permission_type.id));
    }
  }, [state.permissions]);

  const onSelectGroup = groupId => {
    activeGroup.find(item => item.id !== groupId)
      ? setActiveGroup(prev => [...prev, groupId])
      : setActiveGroup(prev => prev.filter(currentId => currentId !== groupId));
  };

  return (
    <Layout containerClass="mn-product-edit">
      <ManagementMenu />

      <Loader loading={loadingDetails}>
        <Form onSubmit={onSubmit} className="mn-product-form">
          <div className="half-form pure">
            <div className="title">{t(`management.${id ? 'update' : 'create'}_product`)}</div>

            <Form.Half>
              <Form.Item label="Starea produsului">
                <span className="status-switch" style={{ width: 110 }}>
                  <Switch size="small" checked={state.private} onChange={onChangePrivate} />

                  <span className="status-switch-title">{t(`management.${state.private ? 'private' : 'public'}`)}</span>
                </span>
              </Form.Item>

              <Form.Item label="Book a demo">
                <span className="status-switch" style={{ width: 110 }}>
                  <Switch size="small" checked={state.requestDemo} onChange={onChangeRequestDemo} />

                  <span className="status-switch-title">{t(`other.${state.requestDemo ? 'yes' : 'no'}`)}</span>
                </span>
              </Form.Item>
            </Form.Half>
          </div>

          <Form.Half three>
            <Form.Item label="Titlu (RO)" {...validate(errors, 'languages')}>
              <Input
                placeholder="Introduceți titlul"
                value={state.title.ro}
                onChange={({ target }) => onChangeTitle('ro', target.value)}
              />
            </Form.Item>

            <Form.Item label="Title (EN)" {...validate(errors, 'languages')}>
              <Input
                placeholder="Enter title"
                value={state.title.en}
                onChange={({ target }) => onChangeTitle('en', target.value)}
              />
            </Form.Item>

            <Form.Item label="Название (RU)" {...validate(errors, 'languages')}>
              <Input
                placeholder="Введите название"
                value={state.title.ru}
                onChange={({ target }) => onChangeTitle('ru', target.value)}
              />
            </Form.Item>
          </Form.Half>

          <Form.Half three>
            <Form.Item label="Descrierea publică (RO)" {...validate(errors, 'public_description')}>
              {Object.keys(state.publicDescription.ro).map(key => (
                <Input
                  key={key}
                  placeholder={`Introduceți descrierea publică (${+key + 1})`}
                  {...(Object.keys(state.publicDescription.ro).length === +key + 1
                    ? { addonAfter: <Icon type="plus" onClick={() => onCreatePublicDescription('ro')} /> }
                    : {})}
                  value={state.publicDescription.ro[key]}
                  onChange={({ target }) => onChangePublicDescription('ro', key, target.value)}
                />
              ))}
            </Form.Item>

            <Form.Item label="Public description (EN)" {...validate(errors, 'public_description')}>
              {Object.keys(state.publicDescription.en).map(key => (
                <Input
                  key={key}
                  placeholder={`Enter public description (${+key + 1})`}
                  {...(Object.keys(state.publicDescription.en).length === +key + 1
                    ? { addonAfter: <Icon type="plus" onClick={() => onCreatePublicDescription('en')} /> }
                    : {})}
                  value={state.publicDescription.en[key]}
                  onChange={({ target }) => onChangePublicDescription('en', key, target.value)}
                />
              ))}
            </Form.Item>

            <Form.Item label="Название (RU)" {...validate(errors, 'public_description')}>
              {Object.keys(state.publicDescription.ru).map(key => (
                <Input
                  key={key}
                  placeholder={`Введите публичное описание (${+key + 1})`}
                  {...(Object.keys(state.publicDescription.ru).length === +key + 1
                    ? { addonAfter: <Icon type="plus" onClick={() => onCreatePublicDescription('ru')} /> }
                    : {})}
                  value={state.publicDescription.ru[key]}
                  onChange={({ target }) => onChangePublicDescription('ru', key, target.value)}
                />
              ))}
            </Form.Item>
          </Form.Half>

          <div className="half-form pure">
            <Form.Half>
              <Form.Item label={`${t('metrics.price')} (MDL)`} {...validate(errors, 'price')}>
                <Input placeholder={t('management.enter_price')} value={state.price} onChange={onChangePrice} />
              </Form.Item>

              <Form.Item label={t('metrics.frequency')} {...validate(errors, 'frequency')}>
                <Select
                  allowClear
                  placeholder={t('management.select_frequency')}
                  dropdownClassName="responsive-table-dropdown"
                  value={state.frequency}
                  onChange={onChangeFrequency}
                >
                  <Select.Option value="day">{t('management.frequency.day')}</Select.Option>
                  <Select.Option value="month">{t('management.frequency.month')}</Select.Option>
                  <Select.Option value="year">{t('management.frequency.year')}</Select.Option>
                </Select>
              </Form.Item>
            </Form.Half>
          </div>

          <div className="half-form pure">
            <div className="title mn-permissions-title">{t('management.permissions')}</div>
          </div>

          {errors.permissions && (
            <Alert className="mn-permissions-alert" type="warning" message={t('management.permissions.error')} />
          )}

          <Loader loading={loadingPermissions} height={307.5}>
            <PermissionBlock>
              <div className="permission-block">
                {permissionGroups.map(item => {
                  return (
                    <>
                      <div key={item.id} className="permission-container">
                        <div
                          className={`all-category-permission ${
                            activeGroup.find(currentId => currentId === item.id) ? 'active-category' : ''
                          }`}
                          onClick={() => onAddCategory(item)}
                        >
                          <span>{item.title}</span>
                          <Icon type="bulb" />
                        </div>
                        <br />
                        {item.data.map(permission => {
                          const foundPermission = findPermissionFromState(state.permissions, permission.id);

                          return (
                            <Permission
                              type="abonament"
                              switchValue={state.permissions}
                              id={permission.id}
                              loading={loadingPermissions}
                              key={permission.title}
                              title={permission.title}
                              limitPeriod={permission.limit_period}
                              dailyLimit={foundPermission ? foundPermission.daily_limit : permission.daily_limit}
                              limit={foundPermission ? foundPermission.limit : permission.limit}
                              vas={foundPermission ? foundPermission.vas : permission.vas}
                              active={foundPermission !== undefined}
                              onChange={(type, value) => onChangePermission(permission.id, type, value)}
                              onClick={() => onCreatePermission(permission)}
                            />
                          );
                        })}
                      </div>
                      <br />
                    </>
                  );
                })}
              </div>
            </PermissionBlock>
          </Loader>

          <div className="mn-product-footer">
            <Link route="AdminAccountsPage">
              <Button>{t('billing.back')}</Button>
            </Link>

            <Button type="primary" htmlType="submit" loading={loadingSubmit}>
              {t('other.submit')}
            </Button>
          </div>
        </Form>
      </Loader>
    </Layout>
  );
};

export default memo(AdminAddProductPage);
