import React, { useState, useEffect } from 'react';
import { useRequest } from 'estafette';
import { useInjectIntl } from '~/libs/localization';
import { Radio } from 'antd';
import { profile } from '~/libs/http/api';
import { ProfileLayout } from '~/components/layouts';
import { Section, Form, Loader } from '~/components/ui';
import { useDocumentTitle } from '~/hooks';

const NotificationPage = () => {
  const { t } = useInjectIntl();
  const { request: requestNotifications, data: dataNotifications, loading: loadingNotifications } = useRequest({
    data: {},
  });
  const { request: requestUpdate } = useRequest();
  const [newsletter, setNewsletter] = useState(null);

  useDocumentTitle(t('page.notification'));

  useEffect(() => {
    requestNotifications(profile.newsletterGet());
  }, []);

  useEffect(() => {
    const onSetNewsletter = () => {
      if (dataNotifications.newsletter !== undefined) {
        setNewsletter(dataNotifications.newsletter);
      }
    };

    onSetNewsletter();
  }, [dataNotifications]);

  const onChangeNewsletter = ({ target }) => {
    setNewsletter(target.value);

    requestUpdate(
      profile.newsletterEdit({
        newsletter: target.value || null,
      }),
    );
  };

  return (
    <ProfileLayout>
      <Section>
        <div className="notifications">
          <Loader loading={loadingNotifications} height={275}>
            <h1>{t({ id: 'notification.settings' })}</h1>

            <div className="notifications-block">
              <div>
                <Form.Helper large>{t({ id: 'notification.companySettings' })}</Form.Helper>

                <Form.Helper>{t({ id: 'notification.frequency' })}</Form.Helper>

                <Form.Item className="radio-block">
                  <Radio.Group onChange={onChangeNewsletter} value={newsletter || ''}>
                    <Radio value="">{t({ id: 'notification.no' })}</Radio>
                    <Radio value="daily">{t({ id: 'notification.daily' })}</Radio>
                    <Radio value="weekly">{t({ id: 'notification.weekly' })}</Radio>
                    <Radio value="real_time">{t({ id: 'notification.realTime' })}</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            </div>
          </Loader>
        </div>
      </Section>
    </ProfileLayout>
  );
};

export default NotificationPage;
