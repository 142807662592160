import { message } from 'antd';
import { company } from '~/libs/http/api';

export const getEmployee = id => async dispatch => {
  try {
    const { data } = await company.employee({ id });

    dispatch({
      type: 'GET_EMPLOYEE',
      payload: {
        id: data.id,
        data,
      },
    });
  } catch (error) {
    message.error("We're sorry! The employee is not found by your request");
  }
};

export function setEmployeeLoading(payload) {
  return {
    type: 'SET_EMPLOYEE_LOADING',
    payload,
  };
}

export const resetEmployee = () => ({
  type: 'RESET_EMPLOYEE',
});
