import React from 'react';
import { injectIntl } from '~/libs/localization';
import { generateMap } from '~/libs/string';
import { has } from '~/libs/object';
import { Empty } from '~/components/ui';
import { JuridicalCard } from '~/components/company/juridical';

const JuridicalInformationGeneral = ({ t, instance, number, judgesTitle, subjectTitle, type }) => {
  return (
    <div className="juridical-content">
      <JuridicalCard.Section>
        <JuridicalCard type="label-status">
          <h2>{t({ id: 'juridical.judgeInstance' })}</h2>
          <p>{has(instance, 'title', <Empty />)}</p>
        </JuridicalCard>

        <JuridicalCard type="label-status">
          <h2>{t({ id: 'profile.address' })}</h2>
          {has(instance, 'address.title') ? (
            <a href={generateMap(instance.address.title)} target="_blank" rel="noopener noreferrer">
              {has(instance, 'address.title')}
            </a>
          ) : (
            <Empty />
          )}
        </JuridicalCard>

        <JuridicalCard type="label-status">
          <h2>{t({ id: 'juridical.panelJudge' })}</h2>
          <p className="text-blue">{judgesTitle || <Empty />}</p>
        </JuridicalCard>
      </JuridicalCard.Section>

      <JuridicalCard.Section>
        <JuridicalCard type="label-status">
          <h2>{t({ id: 'juridical.numFile' })}</h2>
          <p>
            {t({ id: 'juridical.file' })} {has(number, <Empty />)}
          </p>
        </JuridicalCard>

        <JuridicalCard type="label-status">
          <h2>{t({ id: 'juridical.subject' })}</h2>
          <p>{subjectTitle || <Empty />}</p>
        </JuridicalCard>

        <JuridicalCard type="label-status">
          <h2>{t({ id: 'juridical.juridicalType' })}</h2>
          <p>{type || <Empty />}</p>
        </JuridicalCard>
      </JuridicalCard.Section>
    </div>
  );
};

export default injectIntl(JuridicalInformationGeneral);
