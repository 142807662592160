import React, { useState, useCallback } from 'react';
import { injectIntl } from '~/libs/localization';
import { Empty } from '~/components/ui';

const InfoMore = ({ data, icon, textBlue, max = 5, t }) => {
  const [showMore, setShowMore] = useState(false);

  const toggleShow = useCallback(() => {
    setShowMore(status => !status);
  }, []);

  const elements = Object.keys(data).length > 0 ? (max < data.length && showMore ? data : data.slice(0, max)) : null;

  return (
    <div className={`info-detail ${icon ? 'info-detail-icon' : ''}`}>
      {icon && <div className="icon">{icon}</div>}

      {elements ? (
        elements.map(item => (
          <span key={item} className={`block ${textBlue ? 'blue' : ''}`}>
            {item}
          </span>
        ))
      ) : (
        <Empty />
      )}

      {elements && max < data.length ? (
        <span className="show-more-button" onClick={toggleShow}>
          {showMore ? t('filter.placeholder.hide') : t('filter.placeholder.show')}
        </span>
      ) : null}
    </div>
  );
};

export default injectIntl(InfoMore);
