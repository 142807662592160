import React from 'react';
import { notification as alert } from 'antd';

export const notification = ({ key = 1, type = 'success', title, message, direction, duration = 3, onClick, icon }) =>
  alert[type]({
    key,
    duration,
    message: title,
    icon,
    description: (
      <div className="zh-description" style={{ paddingBottom: !direction ? 0 : null }}>
        <span className="zh-text">{message}</span>

        {direction && onClick && (
          <a onClick={onClick}>
            <b>{direction}</b>
          </a>
        )}
      </div>
    ),
  });
