import React from 'react';
import { injectIntl } from '~/libs/localization';
import { Button } from 'antd';
import { compose } from 'redux';
import { exportings } from '~/libs/http/api';
import { Table } from '~/components/ui';
import withRequest from '~/hoc/withRequest';
import { ProfileLayout } from '~/components/layouts';

import '~/assets/scss/components/_CreditsPage.scss';

class CreditsPage extends React.PureComponent {
  state = {};

  componentWillMount() {
    this.props.request(exportings.credits());
  }

  render() {
    const { t } = this.props;

    const columns = [
      {
        title: t({ id: 'other.name' }),
        dataIndex: 'name',
        render: item => <span>{item.name}</span>,
      },
      {
        title: t({ id: 'credits.used' }),
        dataIndex: 'credits.used',
        render: item => <span>{item.used_credits}</span>,
        width: 110,
      },
      {
        title: t({ id: 'credits.limit' }),
        dataIndex: 'credits.limit',
        render: item => <span>{item.total_credits}</span>,
        width: 110,
      },
    ];

    return (
      <ProfileLayout loading={this.props.api.loading}>
        <h1 className="credits-header">{t({ id: 'credits.title' })}</h1>

        <Table columns={columns} dataSource={this.props.api.data} className="credits-page-table" />

        <div className="credits-more">
          <h2>{t({ id: 'credits.moreCredits' })}</h2>

          <Button type="primary">{t({ id: 'credits.moreCreditsButton' })}</Button>
        </div>
      </ProfileLayout>
    );
  }
}

const enhance = compose(injectIntl, withRequest());

export default enhance(CreditsPage);
