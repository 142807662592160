import React from 'react';
import { Radio } from 'antd';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useInjectIntl } from '~/libs/localization';
import { resetSelectSearch } from '~/store/actions/SearchActions';
import { getRoute } from '~/libs/router';
import { Icon } from '~/components/ui/Icon/Icon';

export const formatCount = count => (count ? count.toFixed().replace(/(\d)(?=(\d{3})+(,|$))/g, '$1,') : 0);

export const FilterCounts = () => {
  const { t } = useInjectIntl();
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const { total, loading } = useSelector(
    ({ filter, searches }) => ({
      total: searches.total,
      loading: searches.loading,
    }),
    shallowEqual,
  );

  const classLoading = loading ? 'skeleton skeleton-text' : '';

  const onSwitchType = async ({ target }) => {
    dispatch(resetSelectSearch());
    history.push(getRoute('SearchResultsPage', { type: target.value, mode: match.params.mode || 'basic' }));
  };

  return (
    <div className="filter-count">
      {match.url.match('/search/company/') && <Icon type="menu-filter-bag" />}

      {match.url.match('/search/employee/') && <Icon type="menu-filter-staff" />}

      <Icon type="menu-filter-arrow" />

      <span className={classLoading}>
        {formatCount(total)}

        {match.url.match('/search/subdivision/') && <span> {t('filter.subdivisions')}</span>}

        {match.url.match('/search/company/') && <span> {t('filter.companies')}</span>}

        {match.url.match('/search/employee/') && <span> {t('filter.employees')}</span>}
      </span>

      <Radio.Group
        className="switch-group"
        onChange={onSwitchType}
        defaultValue={match.params.type}
        buttonStyle="solid"
        disabled={loading}
      >
        <Radio.Button value="company">{t('list.companies')}</Radio.Button>
        <Radio.Button value="employee">{t('company.employee')}</Radio.Button>
      </Radio.Group>
    </div>
  );
};
