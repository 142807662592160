import React, { useEffect } from 'react';
import { Button } from 'antd';
import { useRequest } from 'estafette';
import { useStateHandlers, useDocumentTitle } from '~/hooks';
import { Link } from '~/libs/router';
import { indexedFirst } from '~/libs/array';
import { managementApi } from '~/libs/http/api';
import { useInjectIntl } from '~/libs/localization';
import { Layout } from '~/components/layouts';
import { Loader, Confirmation, Pagination, Empty, HiddenContact, Table } from '~/components/ui';
import { ManagementMenu } from '~/components/management';
import { Time } from '~/components/other';
import NotFoundPage from '~/pages/NotFoundPage';
import { Icon } from '~/components/ui/Icon/Icon';

const AdminApiPage = () => {
  const { request, data, loading } = useRequest({ data: { data: [] }, loading: true });
  const { request: requestDelete, loading: loadingRemove } = useRequest();
  const [state, setState] = useStateHandlers({ page: 1 });
  const { t } = useInjectIntl();

  useDocumentTitle(t('page.list'));

  const onFetch = () => {
    request(managementApi.list({ page: state.page, per_page: 25 }), {
      concat: state.page > 1 && 'data',
    });
  };

  useEffect(() => {
    onFetch();
  }, [state.page, loadingRemove]);

  const onChangePage = async page => {
    window.scrollTo(0, window.scrollY - 25);

    setState({ page });
  };

  const onConfirm = async id => {
    requestDelete(managementApi.delete({ id }));

    onFetch();
  };

  const columns = [
    {
      title: t({ id: 'management.N' }),
      dataIndex: 'key',
      render: key => <span className="title">{key}</span>,
    },
    {
      title: t({ id: 'other.name' }),
      dataIndex: 'name',
      width: 260,
      render: (name, record) => (
        <Link route="AdminUsersPage" params={{ id: record.id_account }} className="title link">
          {name || <Empty />}
        </Link>
      ),
    },
    {
      title: t({ id: 'management.key' }),
      dataIndex: 'auth_key',
      width: 260,
      render: apiKey => <span className="title">{apiKey || <Empty />}</span>,
    },
    {
      title: t({ id: 'management.api_created_by' }),
      dataIndex: 'user',
      width: 260,
      render: userEmail => <HiddenContact value={userEmail} type="email" icon={<Icon type="company-mail" />} />,
    },
    {
      title: t({ id: 'management.created_data' }),
      dataIndex: 'date_created',
      width: 260,
      render: date => {
        return (
          <span className="title">
            <Time date={date} relative prefix />
          </span>
        );
      },
    },
    {
      width: 170,
      dataIndex: 'id_account',
      render: id => (
        <Confirmation type="delete" onConfirm={() => onConfirm(id)}>
          <Button>{t('management.delete_auth_key')}</Button>
        </Confirmation>
      ),
    },
  ];

  return (
    <Layout>
      <ManagementMenu />

      <Loader loading={loading}>
        {data.data.length ? (
          <Table
            defaultExpandAllRows
            className="table-clean table-header table-odd table-alerts-company single-line"
            fixed
            columns={columns}
            dataSource={indexedFirst(data.data)}
            rowKey="id_account"
          />
        ) : (
          <NotFoundPage />
        )}
        <Pagination className="mt-20" page={state.page} total={state.pages} onChange={onChangePage} />
      </Loader>
    </Layout>
  );
};

export default AdminApiPage;
