import React from 'react';
import { Link } from '~/libs/router';
import { Button, Select, Popover } from 'antd';
import { injectIntl } from '~/libs/localization';
import { Pagination, Confirmation, Empty, Table } from '~/components/ui';
import { TableHeader } from '~/components/table';
import { PopAction } from '~/components/profile';
import { Icon } from '~/components/ui/Icon/Icon';

const { Option } = Select;

const data = {};
data.total_results = 12;

const DisconnectionsList = ({ props, t }) => {
  const onSort = value => {
    console.log(`selected ${value}`);
  };

  const columnsLocations = [
    {
      title: '',
      className: 'collapsing',
      dataIndex: 'info',
      render: info =>
        (
          <div className="clearfix">
            <div className="pull-left">
              <div className="ico-block">
                <Icon type="marker-small" />
              </div>
            </div>
            <div className="pull-left in-two-rows">
              <span>{info.name}</span>
              <small className="address">{info.address}</small>
            </div>

            <Popover
              content={
                <>
                  <PopAction
                    icon="edit"
                    value={
                      <Link route="DisconnectionsLocationPage" params={{ id: info.id }}>
                        <span className="table-action-text cursor">{t({ id: 'other.edit' })}</span>
                      </Link>
                    }
                  />

                  <PopAction
                    icon="delete"
                    value={
                      <Confirmation type="delete" onConfirm={() => props.onDelete(info.id)}>
                        <span className="table-action-text cursor">{t({ id: 'other.delete' })}</span>
                      </Confirmation>
                    }
                  />
                </>
              }
              trigger="click"
            >
              <div className="popover-more">
                <Icon type="ellipsis" className="btn-ellipsis" />
              </div>
            </Popover>
          </div>
        ) || <Empty />,
    },
    {
      title: '',
      className: 'nowrap collapsing control-wrap control-wrap-desktop',
      dataIndex: 'delete',
      render: info => (
        <Confirmation type="delete" onConfirm={() => props.onDelete(info.id)}>
          <span className="table-action-text cursor">
            <Icon type="delete" />
            {t({ id: 'other.delete' })}
          </span>
        </Confirmation>
      ),
    },
    {
      title: '',
      className: 'nowrap collapsing control-wrap control-wrap-desktop',
      dataIndex: 'info',
      width: '1px',
      render: info => (
        <Link route="DisconnectionsLocationPage" params={{ id: info.id }}>
          <span className="table-action-text cursor">
            <Icon type="edit" />
            {t({ id: 'other.edit' })}
          </span>
        </Link>
      ),
    },
  ];

  const dataLocations = [
    {
      key: '1',
      info: {
        id: 1,
        name: 'Location 1',
        address: 'Chisinau, 170th Columna Str. Suite C, 4th floor MD2004',
      },
      edit: 1,
    },
    {
      key: '2',
      info: {
        id: 2,
        name: 'Location 2',
        address: 'Chisinau, 170th Columna Str. Suite C, 4th floor MD2004',
      },
      edit: 2,
    },
    {
      key: '3',
      info: {
        id: 3,
        name: 'Location 3',
        address: 'Chisinau, 170th Columna Str. Suite C, 4th floor MD2004',
      },
      edit: 3,
    },
    {
      key: '4',
      info: {
        id: 4,
        name: 'Location 4',
        address: 'Chisinau, 170th Columna Str. Suite C, 4th floor MD2004',
      },
    },
  ];

  return (
    <div className="locations-list">
      <Table
        rowKey="id"
        defaultExpandAllRows
        className="table-no-header basic"
        fixed
        columns={columnsLocations}
        dataSource={dataLocations}
        showHeader={false}
        title={() => (
          <TableHeader
            leftSide={
              <div className="form-inline">
                <h1>
                  {t({ id: 'disconnections.localities' })} ({data.total_results})
                </h1>

                <Button className="add-location-button">
                  <Icon type="marker-small" />
                  {t({ id: 'disconnections.addLocation' })}
                </Button>
              </div>
            }
            rightSide={
              <div className="form-inline">
                <span className="form-inline-label">{t({ id: 'disconnections.sortBy' })}</span>
                <Select
                  allowClear
                  placeholder="Date"
                  className="sort-select"
                  dropdownClassName="credit-alerts-dropdown"
                  onChange={onSort}
                  dropdownMatchSelectWidth={false}
                  defaultValue={`${t({ id: 'disconnections.city' })} / ${t({ id: 'disconnections.village' })} `}
                >
                  <Option value={t({ id: 'disconnections.city' })}>{t({ id: 'disconnections.city' })}</Option>
                  <Option value={t({ id: 'disconnections.village' })}>{t({ id: 'disconnections.village' })}</Option>
                </Select>
              </div>
            }
          />
        )}
        footer={() => (
          <>
            {5 > 1 && (
              <div className="table-above clear-fix">
                <div className="pull-right">
                  <Pagination params={{ type: 'list' }} page={2} total={10} route="DisconnectionList" />
                </div>
              </div>
            )}
          </>
        )}
      />
    </div>
  );
};

export default injectIntl(DisconnectionsList);
