import React from 'react';
import { Input } from 'antd';
import { injectIntl } from '~/libs/localization';
import { lists } from '~/libs/http/api';
import { Modal, Button, Description } from '~/components/ui';

class DublicateList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
    };
  }

  async onSubmit() {
    await lists.duplicate({ id: this.props.id, name: this.state.name });
    this.props.onClose();
  }

  onChangeName = ({ target }) => this.setState({ name: target.value });

  render() {
    const { t } = this.props;

    return (
      <Modal
        footerCenter={
          <>
            <Button onClick={() => this.props.onClose()}>{t('other.reset')}</Button>

            <Button type="primary" onClick={() => this.onSubmit()}>
              {t('other.submit')}
            </Button>
          </>
        }
        onClose={() => this.props.onClose()}
      >
        <Description
          img="/assets/img/popup/guard.png"
          title="Lorem ipsum dolor sit amet"
          text="Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
        />

        <Input className="description-input" placeholder={t('list.list_name_type')} onChange={this.onChangeName} />
      </Modal>
    );
  }
}

export default injectIntl(DublicateList);
