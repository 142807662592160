import React from 'react';
import { Tabs as TabsAnt, Popover } from 'antd';
import { useRouteMatch } from 'react-router-dom';
import { compose } from 'redux';
import { connect, useSelector, shallowEqual } from 'react-redux';
import { Link, isRouteActive, getRouteByPath } from '~/libs/router';
import { Text } from '~/components/ui';
// import { capitalize } from '~/libs/string';

const isActive = (match, route) => (isRouteActive(match, route) ? 'active' : '');

const popoverPermission = (
  <div>
    <p>Aceste informații sunt disponibile numai pentru utilizatorii abonați</p>
  </div>
);

const TabLink = props => (
  <li className={`${isActive(props.match, props.route)} ${!props.availability ? 'disabled' : ''}`}>
    {!props.availability ? (
      <span className="company-tab-disabled">{props.children}</span>
    ) : props.checking ? (
      <Link route={props.route} params={{ id: props.id }}>
        {props.children}
      </Link>
    ) : (
      <Popover
        content={popoverPermission}
        trigger="click"
        placement="bottomLeft"
        overlayClassName="tabs-popover-permission"
      >
        <a>{props.children}</a>
      </Popover>
    )}
  </li>
);

// const getJuridicalLink = availability => {
//   // Get an active link

//   // eslint-disable-next-line no-unused-vars
//   for (const key in availability) {
//     if (Object.prototype.hasOwnProperty.call(availability, key)) {
//       if (availability[key]) {
//         return key;
//       }
//     }
//   }

//   return '';
// };

const Tabs = ({ permissions, availability = {}, ...props }) => {
  const match = useRouteMatch();
  const id = Number(match.params.id || 0);

  const store = useSelector(
    ({ authentication }) => ({
      permissions: authentication.permissions,
      authenticationParams: authentication.params,
    }),
    shallowEqual,
  );

  return (
    store.permissions.length > 0 && (
      <TabsAnt className="company-tabs" mode="horizontal" activeKey={getRouteByPath(match.path)}>
        <TabsAnt.TabPane
          tab={
            <TabLink
              match={match}
              route="GeneralsPage"
              id={id}
              checking={store.permissions.includes('general_data_tab')}
              availability={permissions.includes('general_data_tab') && availability.generals}
              className={isActive('GeneralsPage')}
            >
              <Text id="company.tabs.1" />
            </TabLink>
          }
          key="GeneralsPage"
        />

        <TabsAnt.TabPane
          tab={
            <TabLink
              match={match}
              route="PersonalPage"
              id={id}
              checking={store.permissions.includes('personal_tab')}
              availability={store.permissions.includes('personal_tab') && availability.personal}
              className={isActive('PersonalPage')}
            >
              <Text id="company.tabs.2" />
            </TabLink>
          }
          key="PersonalPage"
        />

        <TabsAnt.TabPane
          tab={
            <TabLink
              match={match}
              route="SubdivisionsPage"
              id={id}
              checking={store.permissions.includes('children_tab')}
              availability={permissions.includes('children_tab') && availability.subdivisions}
            >
              <Text id="company.tabs.3" />
            </TabLink>
          }
          key="SubdivisionsPage"
        />

        <TabsAnt.TabPane
          tab={
            <TabLink
              match={match}
              route="EconomyPage"
              id={id}
              checking={store.permissions.includes('financial_data_tab')}
              availability={permissions.includes('financial_data_tab') && availability.economy}
            >
              <Text id="company.tabs.4" />
            </TabLink>
          }
          key="EconomyPage"
        />

        {/* <TabsAnt.TabPane
          tab={
            <TabLink
              match={match}
              route="PublicationsPage"
              id={id}
              checking={store.permissions.includes('publications_tab')}
              availability={permissions.includes('publications_tab') && availability.publications}
            >
              <Text id="company.tabs.5" />
            </TabLink>
          }
          key="PublicationsPage"
        />

        <TabsAnt.TabPane
          tab={
            <TabLink
              match={match}
              route={`${capitalize(getJuridicalLink(availability.juridical))}Page`}
              id={id}
              checking={store.permissions.includes('juridical_tab')}
              availability={
                permissions.includes('juridical_tab') &&
                availability.juridical &&
                (availability.juridical.insolvency ||
                  availability.juridical.jurisprudence ||
                  availability.juridical.reorganization)
              }
            >
              <Text id="company.tabs.6" />
            </TabLink>
          }
          key={`${capitalize(getJuridicalLink(availability.juridical))}Page`}
        /> */}
      </TabsAnt>
    )
  );
};

const enhance = compose(
  connect(({ authentication }) => ({
    permissions: authentication.permissions,
  })),
);

export default enhance(Tabs);
