import { useEffect, useMemo, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

const publicUrl = process.env.REACT_APP_PUBLIC_URL;

export const useCurrentLanguage = (pagePublic, anchor) => {
  const [page, setPage] = useState('index');

  useEffect(() => {
    if (pagePublic) {
      setPage(pagePublic);
    }
  }, [pagePublic]);

  const store = useSelector(
    ({ localization }) => ({
      localization,
    }),
    shallowEqual,
  );

  const pageSlugs = {
    index: {
      ro: { name: '/' },
      ru: { name: '/' },
      en: { name: '/' },
    },
    c: {
      ro: { name: '/contacte' },
      ru: { name: '/kontakti' },
      en: { name: '/contacts' },
    },
    ab: {
      ro: { name: '/abonamente' },
      ru: { name: '/aboniment' },
      en: { name: '/subscription' },
    },
    a: {
      ro: { name: '/despre-noi' },
      ru: { name: '/o-nas' },
      en: { name: '/about' },
    },
    n: {
      ro: { name: '/noutati/toate/pagina/1' },
      ru: { name: '/novosti/vse/stranitsa/1' },
      en: { name: '/news/all-topics/page/1' },
    },
    t: {
      ro: { name: '/termeni-si-conditii' },
      ru: { name: '/uslovia-i-polojenia' },
      en: { name: '/terms-and-conditions' },
    },
  };

  const currentLanguage = useMemo(() => (store.localization.current ? store.localization.current.shortName : 'ro'), [
    store.localization.current,
  ]);

  if (page !== 'index') {
    const filtered = Object.keys(pageSlugs)
      ?.filter(key => page.includes(key))
      ?.reduce((obj, key) => {
        obj[key] = pageSlugs[key];
        return obj;
      }, {});

    if (anchor) {
      return publicUrl
        ?.concat(currentLanguage)
        ?.concat(`/${page}`)
        ?.concat(filtered[page][currentLanguage].name)
        ?.concat(anchor);
    }

    if (currentLanguage === 'en' && page === 'n') {
      return publicUrl
        ?.concat('ro')
        ?.concat(`/${page}`)
        ?.concat(filtered[page].ro.name);
    }

    return publicUrl
      ?.concat(currentLanguage)
      ?.concat(`/${page}`)
      ?.concat(filtered[page][currentLanguage].name);
  }

  return publicUrl?.concat(currentLanguage);
};
