import React from 'react';
import { useHistory } from 'react-router-dom';
import { getRoute } from '~/libs/router';
// import { has } from '~/libs/object';
import { useInjectIntl } from '~/libs/localization';
// import { types } from '~/pages/ActivityPage';
import { Segment } from '~/components/company';
import { Icon } from '~/components/ui/Icon/Icon';
import loadingData from './data';

const LastActivity = ({ data = [], loading }) => {
  const { t } = useInjectIntl();
  const history = useHistory();
  // TODO: there is same code in activitypage
  // hidden for later
  // const onSelect = useCallback(async item => {
  //   if (item.parent_id) {
  //     return history.push(getRoute('SubdivisionPage', { id: item.parent_id }));
  //   }

  //   if (item.employee_id) {
  //     return history.push(getRoute('ExecutivePage', { id: item.employee_id }));
  //   }

  //   if (item.company_id) {
  //     return history.push(getRoute('GeneralsPage', { id: item.company_id }));
  //   }

  //   if (item.type === 'search') {
  //     const { data: search } = await lists.get({ id: item.id });
  //     dispatch(setActiveFilter(search.filters || {}));

  //     return history.push(getRoute('SearchResultsPage', { type: 'company', mode: 'basic' }));
  //   }

  //   if (item.type === 'lists') {
  //     return history.push(getRoute('SearchResultsPage', { type: 'company', mode: item.id }));
  //   }

  //   if (item.type === 'export') {
  //     return history.push(getRoute('DownloadsPage', { type: 'list', page: 1 }));
  //   }

  //   message.error(t('other.error_module'));

  //   return null;
  // }, []);

  const classLoading = loading ? 'skeleton skeleton-text' : '';

  const onShowLastActivity = () => history.push(getRoute('ActivityPage'));

  if (data.length === 0) {
    return null;
  }

  return (
    <>
      <Segment title={t('home.lastActivity')} noPadding>
        <div className="dashboard-last-activities">
          {[...(loading ? loadingData : data)].map(item => (
            // <div className="dashboard-last-activity" key={item.date_created} onClick={() => onSelect(item)}>
            <div className="dashboard-last-activity" key={item.date_created}>
              <div className="icon-wrap">
                <Icon type="activity-page" />
              </div>

              <div className="right">
                {/* <span
                  className={classLoading}
                  dangerouslySetInnerHTML={{
                    __html: String(item.name || has(item, 'export.name')),
                  }}
                /> */}
                <span className={classLoading}>{t(`${item.url ? `homeHistory.${item.url}` : 'home.accessed'}`)}</span>

                <p className={classLoading}>
                  <b>
                    {item.last_name} {item.first_name}
                  </b>{' '}
                  {t(`${item.url ? `home.${item.url}` : 'home.accessed'}`)}{' '}
                  {/* {item.url !== 'removed_list' && item.params?.name ? (
                    <b>{item.params?.name}</b>
                  ) : (
                    <b>{item.data && item.data.list_name}</b>
                  )} */}
                  {item.params?.companies_names && <b>{item.params.companies_names[0]}</b>}
                  {item.data?.list_name && <b>{item.data.list_name}</b>}
                  {item.params?.name && <b>{item.params.name}</b>}
                  {item.data?.company_name && item.data.company_name.slice(0, 3).map(i => <b>{i}, </b>)}
                  {item.data?.company_name && item.data.company_name.slice(0, 3).length === 3 && <b>...</b>}
                  {item.data?.search_list_name && <b>{item.data?.search_list_name}</b>}
                  {item.search_text && <b dangerouslySetInnerHTML={{ __html: item.search_text && item.search_text }} />}
                  {/* <b>{types[item.type]?.text && t(types[item.type].text)}</b> */}
                </p>

                {/* <Icon type="long-arrow" /> */}

                {/* <div className="dashboard-last-activity-tags">
                  <Time prefix date={item.date_created} format="dateTime" loading={classLoading} />
                </div> */}
              </div>
            </div>
          ))}
        </div>
      </Segment>

      <Segment.More onChange={onShowLastActivity} />
    </>
  );
};

export default LastActivity;
