import axios from '~/libs/http/axios';

const { baseURL } = axios.defaults;

const alerts = {
  getList: (params = {}) => axios.get(`${baseURL}/api/rocket/alerts/list/`, { params }),
  company: (params = {}) => axios.get(`${baseURL}/api/lists/alerts/company`, { params }),
};

export default alerts;
