import React, { useState, useEffect } from 'react';
import { useRequest } from 'estafette';
import { alerts } from '~/libs/http/api';
import { Link } from '~/libs/router';
import { Loader, Pagination } from '~/components/ui';
import { Drawer } from '~/components/other';
import { Logo } from '~/components/company';
import { Icon } from '~/components/ui/Icon/Icon';

export const AlertDrawer = ({ companyId, alertId, alert = {}, onClose, ...props }) => {
  const [page, setPage] = useState(1);
  const { request, data, loading } = useRequest({ data: { data: [] } });

  useEffect(() => {
    const onFetch = () => {
      if (alert.id_company) {
        request(alerts.company({ id: alert.id_company, page, per_page: 10 }));
      }
    };

    onFetch();
  }, [alert, page]);

  return (
    alert && (
      <Drawer onClose={onClose} placement="right" width={720}>
        <div className="alerts-drawer-wrapper">
          <div className="alerts-drawer">
            <div className="alerts-drawer-close" onClick={onClose}>
              <Icon type="alerts-close" />
            </div>

            <Link route="GeneralsPage" params={{ id: alert.id_company }}>
              <div className="alerts-drawer-header">
                <Logo name={alert.company_name} website={alert.company_url} />
              </div>
            </Link>

            <div className="alerts-drawer-actions">
              {alert.group && (
                <div className="alert-type">
                  <div className="item alert-category">
                    <span className="icon">
                      <Icon type={alert.group.code} />
                    </span>

                    <span className="text">{alert.event_name}</span>
                  </div>
                </div>
              )}
            </div>

            <Loader loading={loading} height={130}>
              <div className="alerts-drawer-content">
                <table className="alerts-drawer-table">
                  <tbody>
                    {data.data.map(item => (
                      <tr>
                        <td>
                          <b className="alerts-drawer-type">{item.field_name}</b>
                        </td>

                        <td>
                          <span className="zh-text">{item.description}</span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <Pagination page={page} total={data.pages} onChange={setPage} />
            </Loader>
          </div>
        </div>
      </Drawer>
    )
  );
};
