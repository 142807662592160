import React, { memo } from 'react';
import { Checkbox } from 'antd';
import { useInjectIntl } from '~/libs/localization';
import { Link } from '~/libs/router';
import { truncate } from '~/libs/string';
import { Empty, Table } from '~/components/ui';

const SearchExecutive = ({ allowed, employee = [], loading, selectedRow = [], ...props }) => {
  // we removed it temporarily for UX experiment
  // const selectedAll = employee.length > 0 && employee.length === selectedRow.length;

  const { t } = useInjectIntl();

  const classLoading = loading ? 'skeleton skeleton-text' : '';

  const columns = [
    {
      title: '',
      dataIndex: 'id',
      render: id => (
        <div className={classLoading}>
          <Checkbox checked={selectedRow.includes(id)} onClick={() => props.selectSearch(id)} />
        </div>
      ),
    },
    {
      title: t('other.name'),
      dataIndex: 'name',
      render: (name, { id }) => (
        <div className={classLoading}>
          <Link className="title" route="ExecutivePage" params={{ id }}>
            {allowed ? name || <Empty /> : <span className="title-disabled">{name || <Empty />}</span>}
          </Link>
        </div>
      ),
    },
    {
      title: t('profile.level'),
      dataIndex: 'function',
      render: $function => <div className={classLoading}>{$function ? truncate($function, 40) : <Empty />}</div>,
    },
    {
      title: t('statics.company'),
      dataIndex: 'company_name',
      render: (companyName, record) => (
        <div className={classLoading}>
          <Link route="GeneralsPage" params={{ id: record.company_id }}>
            {companyName || <Empty />}
          </Link>
        </div>
      ),
    },
    {
      title: t('other.email'),
      dataIndex: 'email',
      render: email => (
        <div className={classLoading}>
          {email ? (
            <a href={`mailto://${email}`} target="_blank" rel="noopener noreferrer">
              {truncate(email, 40)}
            </a>
          ) : (
            <Empty />
          )}
        </div>
      ),
    },
    {
      title: t('other.telephone'),
      dataIndex: 'phone',
      render: phone => <div className={classLoading}>{phone ? truncate(phone, 40) : <Empty />}</div>,
    },
  ];

  return (
    <Table
      rowKey="id"
      id={props.id}
      className="basic table-clean table-blue single-line fixed table-filter-search"
      fixed
      tableLayout
      columns={columns}
      dataSource={employee}
      scroll={{ y: window.innerHeight }}
    />
  );
};

export default memo(SearchExecutive);
