import routes from '~/libs/router/routes';

export const getRoute = (name, params = {}) => {
  const route = routes.find(item => item.name === name);

  if (route) {
    let { path } = Object.assign({}, route);

    Object.keys(params).map(key => (path = path.replace(`:${key}`, params[key])));

    return path;
  }

  return undefined;
};

export const getRouteByPath = (path, params) => {
  const { name } = routes.find(item => item.path === path);

  return name;
};

export const getPath = name => {
  const { path } = routes.find(item => item.name === name);

  return path;
};

export const getQueryString = params => {
  return new URLSearchParams(params).toString();
};

export const isExactRouteActive = (routeUrl, urls) => {
  if (Array.isArray(urls)) {
    return urls.filter(url => routeUrl === url).length > 0;
  }

  return routeUrl === urls;
};

export const isRouteActive = ({ url, params }, urls, defaultParams = {}) => {
  if (Array.isArray(urls)) {
    return urls.filter(routeUrl => url === getRoute(routeUrl, { ...params, ...defaultParams })).length > 0;
  }
  return url === getRoute(urls, { ...params, ...defaultParams });
};

export const isNavTabActive = navKey => {
  const { name } = routes.find(item => item.name === navKey);

  return name;
};
