import React from 'react';
import { Auth, Icon } from '~/components/ui';

const Header = () => {
  return (
    <div className="public-header">
      <div className="public-header__content">
        <Auth.Logo />

        <div className="phone-number">
          <Icon type="new-phone" />
          <a href="tel:+37322022097">+373 22 022 097</a>
        </div>
      </div>
    </div>
  );
};

export default Header;
