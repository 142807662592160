import React, { useState } from 'react';
import { Input, Button, Select, Checkbox } from 'antd';
import { useStateHandlers } from '~/hooks';
import { useInjectIntl } from '~/libs/localization';
import { Confirmation, Form } from '~/components/ui';
import { Icon } from '~/components/ui/Icon/Icon';

const DisconnectionsInfo = ({ props }) => {
  const { t } = useInjectIntl();
  const [phoneValue, setPhoneValue] = useState();
  const [mailValue, setMailValue] = useState();

  const [state, setState] = useStateHandlers({
    phones: ['+3155884', '84635431'],
    mails: ['sfsf@mail.com', 'asgdfjhdfh@gmail.com'],
  });

  const onChangePhone = e => {
    setPhoneValue(e.target.value);
  };
  const onAddPhone = e => {
    const arrayClone = [...state.phones, e.target.value];
    setState({ phones: arrayClone });
    setPhoneValue('');
  };

  const onChangeMail = e => {
    setMailValue(e.target.value);
  };
  const onAddMail = e => {
    const arrayClone = [...state.mails, e.target.value];
    setState({ mails: arrayClone });
    setMailValue('');
  };

  const onRemoveTag = (array, tag) => {
    const index = array.indexOf(tag);
    if (index > -1) {
      array.splice(index, 1);
    }
    setState({ array });
  };

  return (
    <>
      <Form className="notification-form">
        <div className="clearfix">
          <div className="pull-left">
            <div className="location-name">
              <div className="ico-block">
                <Icon type="marker-small" />
              </div>
              <span>Location 1</span>
            </div>
          </div>
          <div className="pull-right">
            <Confirmation type="delete" onConfirm={() => props.onDelete}>
              <span className="table-action-text cursor">
                <Icon type="delete" />
                {t({ id: 'other.delete' })}
              </span>
            </Confirmation>
          </div>
        </div>

        <div className="address-rows">
          <Form.Item label="Denumirea localităţiei">
            <Input type="text" placeholder="Nume standart (Localitatea 1)" />
          </Form.Item>

          <Form.Item label="Selectați localitatea">
            <Select placeholder="Selectați" className="no-right-border">
              <Select.Option key={0} value={0}>
                Test
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item label="Selectați oraș/sat">
            <Select placeholder="Selectați">
              <Select.Option key={0} value={0}>
                Test
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item label="Adresa">
            <Input type="text" placeholder="Introduceți adresa dvs" />
          </Form.Item>
        </div>

        <div className="notifications-info">
          <div className="title">{t({ id: 'disconnections.notificationSettings' })}</div>

          <div className="notifications-wrap">
            <div className="setting-rows">
              <Form.Item className="checkbox-block">
                <Form.Helper large>{t({ id: 'disconnections.disconnectNotifications' })}</Form.Helper>
                <Form.Helper>{t({ id: 'disconnections.notificationType' })}</Form.Helper>
                <Checkbox.Group>
                  <Checkbox value="">{t({ id: 'disconnections.waterDelivery' })}</Checkbox>
                  <Checkbox value="daily">{t({ id: 'disconnections.electricity' })}</Checkbox>
                  <Checkbox value="weekly">{t({ id: 'disconnections.internetServices' })}</Checkbox>
                </Checkbox.Group>
              </Form.Item>

              <Form.Item className="checkbox-block">
                <Form.Helper large>{t({ id: 'disconnections.frequencyNotifications' })}</Form.Helper>
                <Form.Helper>{t({ id: 'disconnections.frequency' })}</Form.Helper>
                <Checkbox.Group>
                  <Checkbox value="">{t({ id: 'disconnections.weekBefore' })}</Checkbox>
                  <Checkbox value="daily">{t({ id: 'disconnections.daysBefore' })}</Checkbox>
                  <Checkbox value="weekly">{t({ id: 'disconnections.dayBefore' })}</Checkbox>
                </Checkbox.Group>
              </Form.Item>

              <Form.Item className="method-wrap">
                <Form.Helper large>{t({ id: 'disconnections.methodOfNotification' })}</Form.Helper>

                <Form.Item label="Email" colon={false}>
                  <Checkbox value="" />
                  <Input
                    type="text"
                    placeholder={t({ id: 'disconnections.enterTextHere' })}
                    onPressEnter={onAddMail}
                    value={mailValue}
                    onChange={onChangeMail}
                  />

                  <div className="wrap-options">
                    {state.mails.map(item => (
                      <div>
                        {item}{' '}
                        <Icon type="close" className="remove-option" onClick={() => onRemoveTag(state.mails, item)} />
                      </div>
                    ))}
                  </div>
                </Form.Item>

                <div className="separator" />

                <Form.Item label={t({ id: 'disconnections.phone' })} colon={false}>
                  <Checkbox value="" />
                  <Input
                    type="text"
                    placeholder={t({ id: 'disconnections.enterTextHere' })}
                    onPressEnter={onAddPhone}
                    value={phoneValue}
                    onChange={onChangePhone}
                  />

                  <div className="wrap-options">
                    {state.phones.map(item => (
                      <div>
                        {item}{' '}
                        <Icon type="close" className="remove-option" onClick={() => onRemoveTag(state.phones, item)} />
                      </div>
                    ))}
                  </div>
                </Form.Item>
              </Form.Item>
            </div>
          </div>
        </div>

        <div className="form-footer">
          <Button type="reset">{t({ id: 'other.reset' })}</Button>
          <Button type="primary">{t({ id: 'other.submit' })}</Button>
        </div>
      </Form>
    </>
  );
};

export default DisconnectionsInfo;
