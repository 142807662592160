import React, { useState, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import { Button, Input } from 'antd';
import { useRequest } from 'estafette';
import { useStateHandlers, useDocumentTitle } from '~/hooks';
import { Link } from '~/libs/router';
import { managementUsers } from '~/libs/http/api';
import { useInjectIntl } from '~/libs/localization';
import { has } from '~/libs/object';
import { Layout } from '~/components/layouts';
import { Text, Status, Loader, HiddenContact, Empty, Table } from '~/components/ui';
import { ManagementMenu } from '~/components/management';
import { TableHeader } from '~/components/table';
import { Time } from '~/components/other';
import NotFoundPage from '~/pages/NotFoundPage';
import { Icon } from '~/components/ui/Icon/Icon';

const AdminUsersPage = () => {
  const { t } = useInjectIntl();
  useDocumentTitle(t('page.accounts'));
  const { id } = useParams();
  const { request, data, loading } = useRequest({ data: { data: [] }, loading: true });

  const [hasMore, setHasMore] = useState(true);
  const [filter, setFilter] = useStateHandlers({
    page: 1,
    search: undefined,
  });

  const mainLoading = useMemo(() => loading && data.data.length === 0, [loading, data]);
  const classLoading = useMemo(() => (loading ? 'skeleton skeleton-text' : ''), [loading]);

  useEffect(() => {
    const onFetch = async () => {
      if (hasMore) {
        const options = { id, page: filter.page, per_page: 25 };

        if (filter.search) {
          options.search = filter.search;
        }

        const res = await request(managementUsers.list(options), { concat: filter.page > 1 && 'data' });

        setHasMore(filter.page < Math.ceil(res.total_results / 25));
      }
    };

    onFetch();
  }, [hasMore, filter]);

  const onChangePage = async page => {
    window.scrollTo(0, window.scrollY - 25);

    setFilter({ page });
  };

  const onChangeSearch = search => {
    setHasMore(true);

    setFilter({
      page: 1,
      search,
    });
  };

  const columns = [
    {
      title: t('other.N'),
      className: 'td-key',
      render: (_, __, key) => <span className={classLoading}>{key + 1}</span>,
      width: 20,
    },
    {
      title: t('other.name'),
      dataIndex: 'first_name',
      render: (text, record) => (
        <div className={classLoading}>
          <Link route="AdminUpdateUserPage" className="title" params={{ id, userId: record.user_id }}>
            {!text && !record.last_name && <Empty />}
            {text}
            &nbsp;
            {record.last_name}
          </Link>
        </div>
      ),
      width: 170,
    },
    {
      title: t('other.email'),
      dataIndex: 'email',
      render: text => (
        <div className={classLoading}>
          <HiddenContact value={text} type="email" icon={<Icon type="company-mail" />} />
        </div>
      ),
      width: 90,
    },
    {
      title: t('other.telephone'),
      dataIndex: 'phone',
      render: text => (
        <div className={classLoading}>
          <HiddenContact value={text} type="phone" icon={<Icon type="company-telephone" />} />
        </div>
      ),
      width: 100,
    },
    {
      title: t('other.role'),
      render: text => <div className={classLoading}>{has(text, 'seniority.title') || <Empty />}</div>,
      width: 90,
    },
    {
      title: t('other.job'),
      dataIndex: 'job_title',
      render: text => <div className={classLoading}>{text || <Empty />}</div>,
      width: 90,
    },
    {
      title: t('other.status'),
      dataIndex: 'is_active',
      render: text => (
        <div className={classLoading}>{<Status status={text ? 'active' : 'inactive'} /> || <Empty />}</div>
      ),
      width: 100,
    },
    {
      title: t('other.last-activity'),
      dataIndex: 'last_activity',
      render: text => <div className={classLoading}>{<Time date={text} format="dateTime" prefix /> || <Empty />}</div>,
      width: 100,
    },
  ];

  return (
    <Layout>
      <ManagementMenu />

      <ManagementMenu provider="account" />

      <Loader loading={mainLoading}>
        <TableHeader
          leftSide={
            <h1 className="table-results">
              {t('management.results')} {data.total_results ? `(${data.total_results || 0})` : null}
            </h1>
          }
          rightSide={
            <div className="filter-header-button-group form-inline">
              <Input.Search
                placeholder={t({ id: 'searches.search' })}
                enterButton
                onSearch={onChangeSearch}
                defaultValue={filter.search}
              />

              {data.product ? (
                <Link route="AdminAddUserPage" params={{ id }}>
                  <Button>
                    <Text id="other.addInList" />
                  </Button>
                </Link>
              ) : (
                <Button disabled>
                  <Text id="other.addInList" />
                </Button>
              )}
            </div>
          }
        />
        <InfiniteScroll initialLoad={false} pageStart={1} loadMore={onChangePage} hasMore={hasMore}>
          <Table className="table-layout-fixed" columns={columns} dataSource={data.data} />

          {!data.data.length ? <NotFoundPage /> : null}
        </InfiniteScroll>
      </Loader>
    </Layout>
  );
};

export default AdminUsersPage;
