import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRequest } from 'estafette';
import { useDocumentTitle } from '~/hooks';
import { Input, Button } from 'antd';
import { injectIntl } from '~/libs/localization';
import { getPercentage, number } from '~/libs/number';
import { managementActivity, managementApi } from '~/libs/http/api';
import { capitalize } from '~/libs/string';
import { Loader, EmptyBlock, ProgressBar } from '~/components/ui';
import { Layout } from '~/components/layouts';
import { ManagementMenu } from '~/components/management';
import NotFoundPage from '~/pages/NotFoundPage';

const AccountMetricsPage = ({ t, ...props }) => {
  useDocumentTitle(t('management.metrics'));

  const { id } = useParams();
  const { request, data, loading } = useRequest({ data: { data: [] }, loading: true });
  const { request: requestApi, data: dataApi } = useRequest();
  const { request: requestDelete, loading: loadingDelete } = useRequest();

  useEffect(() => {
    request(managementActivity.metrics({ id }));
    requestApi(managementApi.accountKey({ id }));
  }, [loadingDelete]);

  const onAuthKeyDelete = () => {
    requestDelete(managementApi.delete({ id }));
  };

  return (
    <Layout containerClass="updatesEdit-container">
      <ManagementMenu />

      <ManagementMenu provider="account" />

      <Loader loading={loading}>
        <>
          <div className="form-header-title">{t('management.api_key')}:</div>
          <div className="container-flex mb-20">
            <div className="width-50">
              <Input value={dataApi.auth_key} disabled className="input-border" />
            </div>
            <div className="ml-1">
              <Button>{t('management.create_auth_key')}</Button>
            </div>
            {dataApi.auth_key && (
              <div className="ml-1">
                <Button onClick={() => onAuthKeyDelete({ id })}>{t('management.delete_auth_key')}</Button>
              </div>
            )}
          </div>
        </>

        <div className="metrics-wrapper">
          {data.metric && Object.keys(data.metric).length > 0 ? (
            <div className="metric account-metric" key={data.user_id}>
              <div className="title">{data.name}</div>

              <div className="metric-graphs">
                {Object.keys(data.metric).length > 0 ? (
                  Object.keys(data.metric).map(metric => (
                    <div key={metric}>
                      <div className="title">{capitalize(metric).replace('_', ' ')}</div>
                      <div className="sub-title">
                        Used by account: {number(data.metric[metric].account)} / {number(data.metric[metric].total)}
                      </div>
                      <div className="main-progressbar">
                        <ProgressBar
                          progress={getPercentage(data.metric[metric].account, data.metric[metric].total)}
                          progressIndicator
                        />
                      </div>
                      {data.metric[metric].users.map(user =>
                        Object.keys(user).map(key => (
                          <div className="mertic-limit" key={key}>
                            <span className="mb-0">{key}</span>

                            <p>
                              Used {metric.replace('_', ' ')} access {number(user[key])} time(-s)
                            </p>
                            <ProgressBar
                              progress={getPercentage(user[key], data.metric[metric].account)}
                              progressIndicator
                            />
                          </div>
                        )),
                      )}
                    </div>
                  ))
                ) : (
                  <EmptyBlock style={{ marginTop: 10 }} />
                )}
              </div>
            </div>
          ) : (
            <NotFoundPage />
          )}
        </div>
      </Loader>
    </Layout>
  );
};

export default injectIntl(AccountMetricsPage);
