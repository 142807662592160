import React from 'react';
import { useInjectIntl } from '~/libs/localization';
import { Icon } from '~/components/ui/Icon/Icon';

export const FilterDropItem = ({ children, loading, onChange, item: { title, open, raw } }) => {
  const { t } = useInjectIntl();

  return (
    <div className={`filter-droplist ${open || raw ? 'open' : ''} ${loading ? 'filter-droplist-loading' : ''}`}>
      <span className="filter-droplist-title" onClick={onChange} role="presentation">
        {t(title)}

        {!raw && (
          <small className="svg">
            <Icon type="menu-filter-arrow-right" />
          </small>
        )}
      </span>

      {open || raw ? <div className="filter-droplist-children">{!loading ? children : null}</div> : null}
    </div>
  );
};
