import React, { useState, useEffect } from 'react';
import { Tooltip } from 'antd';
import { indexed } from '~/libs/array';
import { spaceThousands } from '~/libs/number';

const getColor = percentage => {
  if (percentage > 75) {
    return '#7a9cc8';
  }

  if (percentage > 50) {
    return '#98aecc';
  }

  if (percentage > 25) {
    return '#becada';
  }

  return '#c1d3eb';
};

const getPercentage = (startPos, endPos, currentPos) => {
  const distance = endPos - startPos;
  return (currentPos / distance) * 100;
};

const Item = props => (
  <Tooltip title={props.score || 'Zero'}>
    <div
      className={`ratio-item ${props.positive ? 'positive' : 'negative'}`}
      style={{ height: `${Math.abs(props.percentage || 1)}%`, backgroundColor: props.color }}
    />
  </Tooltip>
);

const Ratio = ({ scores: $scores, record, ...props }) => {
  const [scores, setScores] = useState([]);

  useEffect(() => {
    const onSetScores = () => {
      const positive = Math.max(...$scores.map(score => score || 0));
      const negative = Math.min(...$scores.map(score => score || 0));

      setScores(
        indexed(
          $scores.map(score => {
            const percentage = negative && positive && score ? getPercentage(negative, positive, score) : 0;

            return {
              score,
              percentage,
              color: getColor(percentage),
            };
          }),
        ),
      );
    };

    onSetScores();
  }, [$scores, record]);

  return (
    <div className="ratio">
      {scores.map(({ key, score, percentage, color }) => (
        <Item
          score={score && spaceThousands(score)}
          percentage={percentage}
          key={key}
          positive={percentage > 0}
          color={color}
        />
      ))}
    </div>
  );
};

export default Ratio;
