import React, { useRef, useMemo, useState, useEffect } from 'react';
import { DatePicker, Radio } from 'antd';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { setFilterList } from '~/store/actions/legalBulletinActions';
import moment from '~/libs/moment';
import { useInjectIntl } from '~/libs/localization';

const dateFormat = 'YYYY-MM-DD';
const currentTime = moment();

const SidebarLegalBulletinList = () => {
  const { t } = useInjectIntl();
  const dispatch = useDispatch();
  const store = useSelector(
    ({ legalBulletin: $legalBulletin }) => ({ filterList: $legalBulletin.filterList }),
    shallowEqual,
  );

  const changedDateValue = useRef(false);
  const [dateValue, setDateValue] = useState('all');
  const [weekTime, setWeekTime] = useState(currentTime);

  useEffect(() => {
    if (changedDateValue.current) {
      const newFilters = {};
      if (['lastWeek', 'lastMonth'].includes(dateValue)) {
        const date = moment().subtract(dateValue === 'lastWeek' ? 7 : 30, 'days');
        setWeekTime(date);

        newFilters.from_date = date.format('YYYY-MM-DD');
        newFilters.to_date = currentTime.format('YYYY-MM-DD');
      }

      dispatch(
        setFilterList({
          ...newFilters,
          page: 1,
          is_read: dateValue === 'read' || null,
          all: dateValue === 'all' || null,
        }),
      );
    }
  }, [dateValue]);

  useEffect(() => {
    if (['all', 'read'].includes(dateValue)) {
      dispatch(
        setFilterList({
          page: 1,
          from_date: null,
          to_date: null,
        }),
      );
    }
  }, [dateValue]);

  const onChangeDate = ({ target }) => {
    changedDateValue.current = true;
    setDateValue(target.value);
  };

  const onChangeEndDate = date => dispatch(setFilterList({ to_date: date ? date.format('YYYY-MM-DD') : null }));

  const onChangeStartDate = date =>
    dispatch(
      setFilterList({
        from_date: date && dateValue !== 'lastWeek' && dateValue !== 'lastMonth' ? date.format('YYYY-MM-DD') : null,
      }),
    );

  const dayIntervalDateValue = useMemo(() => !!['lastWeek', 'lastMonth'].includes(dateValue), [dateValue]);

  const nowIntervalDateValue = useMemo(() => !!['all', 'read'].includes(dateValue), [dateValue]);

  return (
    <div className="wrap-sidebar-legal-bulletin">
      <div className="sidebar-legal-bulletin sidebar-legal-bulletin-list">
        <div className="main-title-2">{t('legalBulletin.period')}</div>

        {dayIntervalDateValue && store.filterList.from_date && store.filterList.to_date ? (
          <div className="date-picker-period">
            <DatePicker
              value={dayIntervalDateValue ? moment(weekTime, dateFormat) : null}
              format={dateFormat}
              placeholder={t('legalBulletin.from')}
              onChange={onChangeStartDate}
              disabled={['lastWeek', 'lastMonth'].includes(dateValue)}
            />

            <DatePicker
              value={dayIntervalDateValue ? moment(currentTime, dateFormat) : null}
              format={dateFormat}
              placeholder={t('legalBulletin.upTo')}
              onChange={onChangeEndDate}
              disabled={dayIntervalDateValue}
            />
          </div>
        ) : (
          <div className="date-picker-period">
            <DatePicker
              placeholder={t('legalBulletin.from')}
              onChange={onChangeStartDate}
              value={nowIntervalDateValue ? '' : null}
            />

            <DatePicker
              placeholder={t('legalBulletin.upTo')}
              onChange={onChangeEndDate}
              value={nowIntervalDateValue ? '' : null}
            />
          </div>
        )}

        <div className="main-title-2">{t('legalBulletin.period')}</div>

        <Radio.Group value={dateValue} onChange={onChangeDate} className="date-radio-group">
          <Radio value="lastWeek">{t('legalBulletin.lastWeek')}</Radio>
          <Radio value="lastMonth">{t('legalBulletin.lastMonth')}</Radio>
          <Radio value="read">{t('legalBulletin.read')}</Radio>
          <Radio value="all">{t('legalBulletin.all')}</Radio>
        </Radio.Group>
      </div>
    </div>
  );
};

export default SidebarLegalBulletinList;
