import React from 'react';
import { Counter } from '~/components/ui';

import '~/assets/scss/components/ui/_Rows.scss';

const Rows = ({ className = '', rows = [], ...props }) => {
  const classLoading = props.loading ? 'skeleton skeleton-text' : '';
  const getLength = ({ value }) => (typeof value !== 'object' ? value.length : 4);

  return (
    <div className={`data-rows ${className}`} style={props.style}>
      {rows.map(row =>
        row.value !== undefined ? (
          <div key={row.label} style={{ padding: rows.length > 4 ? '25px' : null }}>
            <small>{row.label}</small>

            <span className={getLength(row) > 20 ? 'data-rows-small' : ''}>
              <span className={classLoading}>
                {!row.pure && typeof row.value === 'number' ? <Counter total={row.value} /> : row.value}
              </span>
            </span>
          </div>
        ) : null,
      )}
    </div>
  );
};

export default Rows;
