import React, { useEffect } from 'react';
import { Input } from 'antd';
import { compose } from 'redux';
import withRequest from '~/hoc/withRequest';
import { useStateHandlers } from '~/hooks';
import { injectIntl } from '~/libs/localization';
import { events } from '~/libs/http/api';
import { Modal, Form, Button } from '~/components/ui';

const Events = ({ record, t, ...props }): Node => {
  const [state, setState] = useStateHandlers({
    isEdit: false,
    region: '',
    address: '',
  });

  useEffect(() => {
    const onInitData = () => {
      if (Object.keys(record).length > 0) {
        setState({
          isEdit: true,
          region: record.region,
          address: record.address,
        });
      }
    };

    onInitData();
  }, [record]);

  const onSubmit = () => {
    const options = {
      region: state.region,
      address: state.address,
    };

    state.isEdit && (options.id = record.id);

    props.request(events[state.isEdit ? 'edit' : 'add'](options)).then(() => {
      props.refetch();
      props.onClose();
    });
  };

  const onChangeRegion = ({ target }) => setState({ region: target.value });
  const onChangeAddress = ({ target }) => setState({ address: target.value });

  return (
    <Modal
      loading={props.loading}
      title={t('home.emptyAction-disconnection')}
      footerCenter={
        <>
          <Button onClick={props.onClose}>{t('other.reset')}</Button>

          <Button type="primary" onClick={onSubmit} loading={props.loading}>
            {t('other.submit')}
          </Button>
        </>
      }
      {...props}
    >
      <Form>
        <Form.Error message={props.api.errors.non_field_errors} />

        <Form.Item
          label={t('other.region')}
          {...(props.api.errors.region ? { validateStatus: 'error', help: props.api.errors.region } : null)}
        >
          <Input placeholder={t('other.regionType')} value={state.region} onChange={onChangeRegion} />
        </Form.Item>

        <Form.Item
          label={t('profile.address')}
          {...(props.api.errors.address ? { validateStatus: 'error', help: props.api.errors.address } : null)}
        >
          <Input placeholder={t('other.addressType')} value={state.address} onChange={onChangeAddress} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const enhance = compose(injectIntl, withRequest());

export default enhance(Events);
