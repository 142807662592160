import React, { useCallback } from 'react';
import MediaQuery from 'react-responsive';
import responsive from '~/libs/responsive';
import { parseDate } from '~/libs/date';
import { Time } from '~/components/other';
import { Icon } from '~/components/ui/Icon/Icon';

export const AlertCard = ({ dataList, item, onClick }) => {
  const AlertContent = useCallback(
    () => (
      <div className="alert-content" onClick={onClick}>
        <span className="title">{item.company_name}</span>

        <span className="sub-title">
          <Time prefix date={dataList.date_created} />
        </span>

        <p className="alert-description">
          Ai adaugat lista {parseDate(dataList.date_created)}, intre timp not am actualizat informatia despre companiile
          din lista ta, dupa cum urmeaza ...
        </p>

        <div className="meta">
          {item.changes.map(change => (
            <div className="item" key={change.key}>
              <span className="icon">
                <Icon type="companies" />
              </span>

              <span className="text">
                {change.key}: {change.doc_count}
              </span>
            </div>
          ))}
        </div>
      </div>
    ),
    [dataList, item, onClick],
  );

  return (
    <div className="alert-card">
      <Icon type="suitcase" />

      <MediaQuery minWidth={responsive.desktop}>
        <AlertContent />
      </MediaQuery>

      <MediaQuery maxWidth={responsive.desktop}>
        <label className="alert-card-label">
          <AlertContent />
        </label>
      </MediaQuery>
    </div>
  );
};
