import { adapterIntl } from '~/libs/localization/helpers';

const legalBulletin = adapterIntl({
  'legalBulletin.legalBulletin': {
    ro: 'Buletin juridic',
    ru: 'Юридический бюллетень',
    en: 'Legal bulletin',
  },
  'legalBulletin.list': {
    ro: 'Listă',
    ru: 'Список',
    en: 'List',
  },
  'legalBulletin.legalDocuments': {
    ro: 'Acte Juridice',
    ru: 'Юридические документы',
    en: 'Legal documents',
  },
  'legalBulletin.filter': {
    ro: 'Filtru',
    ru: 'Фильтр',
    en: 'Filter',
  },
  'legalBulletin.legalBulletins': {
    ro: 'Buletine juridice',
    ru: 'Юридические бюллетени',
    en: 'Legal bulletins',
  },
  'legalBulletin.ind_Afectate': {
    ro: 'Industrii afectate',
    ru: 'Затронутые отрасли',
    en: 'Industries affected',
  },
  'legalBulletin.searchFor': {
    ro: 'Caută',
    ru: 'Искать',
    en: 'Search for',
  },
  'legalBulletin.choosePeriod': {
    ro: 'Alege perioadă',
    ru: 'Выберите период',
    en: 'Choose period',
  },
  'legalBulletin.selectedBulletin': {
    ro: 'buletin selectat',
    ru: 'выбранный бюллетень',
    en: 'selected bulletin',
  },
  'legalBulletin.unload': {
    ro: 'Descarcă',
    ru: 'Загрузить',
    en: 'Download',
  },
  'legalBulletin.addToList': {
    ro: 'Adaugă în listă',
    ru: 'Добавить в список',
    en: 'Add to list',
  },
  'legalBulletin.actions': {
    ro: 'Acțiuni',
    ru: 'Действия',
    en: 'Actions',
  },
  'legalBulletin.pages': {
    ro: 'pagini',
    ru: 'страницы',
    en: 'pages',
  },
  'legalBulletin.read-time': {
    ro: 'minute de citit',
    ru: 'минут на чтение',
    en: 'read time',
  },
  'legalBulletin.lege': {
    ro: 'legi',
    ru: 'законы',
    en: 'laws',
  },
  'legalBulletin.guvern': {
    ro: 'Hotărari de Guvern',
    ru: 'Постановление Правительства',
    en: 'Government Decree',
  },
  'legalBulletin.avize': {
    ro: 'Avize',
    ru: 'Оповещения',
    en: 'Notification',
  },
  'legalBulletin.delete': {
    ro: 'Şterge',
    ru: 'Удалить',
    en: 'Delete',
  },
  'legalBulletin.normativeAct': {
    ro: 'Actul normativ',
    ru: 'Нормативный акт',
    en: 'Normative act',
  },
  'legalBulletin.industryAffected': {
    ro: 'Industrii afectate',
    ru: 'Затронутые отрасли',
    en: 'Industries affected',
  },
  'legalBulletin.actName': {
    ro: 'Denumirea actului',
    ru: 'Название акта',
    en: 'Title of the act',
  },
  'legalBulletin.authority': {
    ro: 'Autoritatea emitentă',
    ru: 'Орган, выдавший документ',
    en: 'Issuing authority',
  },
  'legalBulletin.rezume': {
    ro: 'Rezumat',
    ru: 'Резюме',
    en: 'Summary',
  },
  'legalBulletin.readMore': {
    ro: 'Citește mai mult',
    ru: 'Читать больше',
    en: 'Read more',
  },
  'legalBulletin.readClose': {
    ro: 'Închide',
    ru: 'Закрыть',
    en: 'Close',
  },
  'legalBulletin.affected': {
    ro: 'Afecteaza',
    ru: 'Влияет',
    en: 'Influences',
  },
  'legalBulletin.vigorDate': {
    ro: 'Data intrării în vigoare',
    ru: 'Дата вступления в силу',
    en: 'Effective date',
  },
  'legalBulletin.actionsTaken': {
    ro: 'Acțiuni de implimentat',
    ru: 'Необходимые действия',
    en: 'Actions to be done',
  },
  'legalBulletin.explication': {
    ro: 'Explicare',
    ru: 'Объяснение',
    en: 'Explanation',
  },
  'legalBulletin.concluzion': {
    ro: 'Concluzia',
    ru: 'Вывод',
    en: 'Сonclusion',
  },
  'legalBulletin.cancel': {
    ro: 'Anulează',
    ru: 'Отменить',
    en: 'Cancel',
  },
  'legalBulletin.apply': {
    ro: 'Aplică',
    ru: 'Применить',
    en: 'Apply',
  },
  'legalBulletin.editActItem': {
    ro: 'Redactează act normativ',
    ru: 'Редактировать акт',
    en: 'Edit act',
  },
  'legalBulletin.addActItem': {
    ro: 'Adaugă act normativ',
    ru: 'Добавить акт',
    en: 'Add act',
  },
  'legalBulletin.addFolder': {
    ro: 'Adaugă mapă',
    ru: 'Добавить папку',
    en: 'Add folder',
  },
  'legalBulletin.nameOfTheNormativeAct': {
    ro: 'Denumirea actului normativ',
    ru: 'Название нормативного акта',
    en: 'Name of the normative act',
  },
  'legalBulletin.lawNoRegarding': {
    ro: 'Legea nr. ... cu privire la ...',
    ru: 'Закон № ... Что касается ...',
    en: 'Law no. ... Regarding ...',
  },
  'legalBulletin.linkToTheAct': {
    ro: 'Link-ul actului',
    ru: 'Ссылка на документ',
    en: 'Link to the act',
  },
  'legalBulletin.deleteAct': {
    ro: 'Șterge actul',
    ru: 'Удалить документ',
    en: 'Delete act',
  },
  'legalBulletin.deleteCheck': {
    ro: 'Ești sigur?',
    ru: 'Уверены ли вы?',
    en: 'Are you sure?',
  },
  'legalBulletin.addList': {
    ro: 'Adaugă lista',
    ru: 'Добавить список',
    en: 'Add list',
  },
  'legalBulletin.addAct': {
    ro: 'Adauga act',
    ru: 'Добавить документ',
    en: 'Add act',
  },
  'legalBulletin.deleteFolder': {
    ro: 'Şterge lista',
    ru: 'Удалить лист',
    en: 'Delete list',
  },
  'legalBulletin.editFolder': {
    ro: 'Editează lista',
    ru: 'Редактировать лист',
    en: 'Edit list',
  },
  'legalBulletin.edit': {
    ro: 'Editează',
    ru: 'Редактировать',
    en: 'Edit',
  },
  'legalBulletin.nameList': {
    ro: 'Denumirea listei',
    ru: 'Название листа',
    en: 'List name',
  },
  'legalBulletin.sortBy': {
    ro: 'Sortează după',
    ru: 'Сортировать по',
    en: 'Sort by',
  },
  'legalBulletin.deleteFromList': {
    ro: 'Şterge din listă',
    ru: 'Удалить из списка',
    en: 'Remove from list',
  },
  'legalBulletin.currentBulletin': {
    ro: 'Buletinul curent',
    ru: 'Текущий бюллетень',
    en: 'The current bulletin',
  },
  'legalBulletin.integralBulletin': {
    ro: 'Buletin Integral',
    ru: 'Полный бюллетень',
    en: 'Integral Bulletin',
  },
  'legalBulletin.industry': {
    ro: 'Industrii',
    ru: 'Промышленность',
    en: 'Industry',
  },
  'legalBulletin.previousBulletins': {
    ro: 'Buletine anterioare',
    ru: 'Предыдущие бюллетени',
    en: 'Previous bulletins',
  },
  'legalBulletin.bulletins': {
    ro: 'buletine',
    ru: 'бюллетеней',
    en: 'bulletins',
  },
  'legalBulletin.all': {
    ro: 'Toate',
    ru: 'Все',
    en: 'All',
  },
  'legalBulletin.unread': {
    ro: 'Necitite',
    ru: 'Непрочитанные',
    en: 'Unread',
  },
  'legalBulletin.read': {
    ro: 'Citite',
    ru: 'Прочитанные',
    en: 'Read',
  },
  'legalBulletin.period': {
    ro: 'Perioada',
    ru: 'Период',
    en: 'Period',
  },
  'legalBulletin.from': {
    ro: 'De la',
    ru: 'Дата начала',
    en: 'Start date',
  },
  'legalBulletin.upTo': {
    ro: 'Până la',
    ru: 'Дата окончания',
    en: 'End date',
  },
  'legalBulletin.lastWeek': {
    ro: 'Ultima saptamană',
    ru: 'Прошлая неделя',
    en: 'Last week',
  },
  'legalBulletin.lastMonth': {
    ro: 'Ultima lună',
    ru: 'Прошлый месяц',
    en: 'Last month',
  },
  'legalBulletin.lastYear': {
    ro: 'Ultimul an',
    ru: 'Прошлый год',
    en: 'Last year',
  },
  'legalBulletin.startBrowsing': {
    ro: 'Începeți navigarea',
    ru: 'Начать просмотр',
    en: 'Start browsing',
  },
  'legalBulletin.codes': {
    ro: 'Coduri',
    ru: 'Коды',
    en: 'Codes',
  },
  'legalBulletin.laws': {
    ro: 'Legi',
    ru: 'Законы',
    en: 'Laws',
  },
});

export default legalBulletin;
