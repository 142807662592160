import React, { useState, useEffect } from 'react';
import { Form, Popover, Input, Radio, Checkbox, message } from 'antd';
import { useRequest } from 'estafette';
import { monitoring, profile } from '~/libs/http/api';
import { useInjectIntl } from '~/libs/localization';
import { Button, Loader } from '~/components/ui';
import { Icon } from '../ui/Icon/Icon';

import '~/assets/scss/components/_ListSettings.scss';

const colorListNames = [
  'blue',
  'pink',
  'violet',
  'red',
  'aqua',
  'burgundy',
  'green',
  'light-green',
  'orange',
  'yellow',
  'grey',
  'braun',
];

const ListSettings = ({ showListSettings, dataTypes, onSaveSettings, listId, refreshAfterSave }) => {
  const { t } = useInjectIntl();
  const { request: requestProfile, data: dataProfile } = useRequest();
  const { request: requestListInfo, loading: loadingListInfo, data: dataListInfo } = useRequest();
  const { request: requestAddMonitoringList, errors } = useRequest({
    data: { data: [] },
  });

  const [color, setColor] = useState('blue');
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [frequency, setFrequency] = useState('NEVER');
  const [email, setEmail] = useState(dataListInfo?.email || '');
  const [notifyKeys, setNotifyKeys] = useState([]);
  const [companiesIds, setCompaniesIds] = useState(dataListInfo?.companies_ids || []);

  useEffect(() => {
    requestProfile(profile.get());
  }, []);

  useEffect(() => {
    onFetchInfo();
  }, [listId, dataProfile]);

  useEffect(() => {
    if (listId && dataListInfo?.id) {
      setName(dataListInfo.name);
      setDescription(dataListInfo.description);
      setEmail(dataListInfo.email);
      setColor(dataListInfo.color);
      setNotifyKeys(dataListInfo.notify_keys);
      setFrequency(dataListInfo.frequency);

      const newCompaniesIds = Object.values(dataListInfo.companies).map(item => item.id);
      setCompaniesIds(newCompaniesIds);
    }
  }, [listId, dataListInfo]);

  const onFetchInfo = () => {
    if (listId && dataProfile.id) {
      const params = {
        user_id: dataProfile.id,
        list_id: listId,
      };
      requestListInfo(monitoring.getListInfo(params));
    }
  };

  const colorList = colorListNames.map(colorName => (
    <li key={colorName}>
      <span className={colorName} onClick={() => setColor(colorName)} />
    </li>
  ));

  const onChangeName = ({ target }) => setName(target.value);

  const onChangeFrequency = ({ target }) => setFrequency(target.value);

  const onChangeDescription = ({ target }) => setDescription(target.value);

  const onChangeEmail = ({ target }) => setEmail(target.value);

  const onChangeNotifyKeys = keys => setNotifyKeys(keys);

  const onReset = () => {
    setName(null);
    setDescription(null);
    setEmail('');
    setNotifyKeys([]);
    setCompaniesIds([]);
    setFrequency('NEVER');
    setColor('blue');
  };

  const onSubmit = async () => {
    try {
      const params = {
        name,
        list_type: 'companies',
        notify_keys: notifyKeys,
        frequency,
        companies_ids: companiesIds,
        color,
        description,
        email,
      };

      if (dataListInfo?.id) {
        params.list_id = dataListInfo.id;
        await requestAddMonitoringList(monitoring.editList(params));
      } else {
        await requestAddMonitoringList(monitoring.addList(params));
      }

      onSaveSettings();

      message.success(t('other.success.added'));
    } catch ({ response }) {
      message.error(t('other.error_module'));
    }
  };

  const onRefresh = () => {
    showListSettings(false, listId);
    refreshAfterSave();
  };

  if (!dataTypes) {
    return null;
  }

  return (
    <Loader loading={loadingListInfo}>
      {dataProfile.id && (
        <div className="list-settings">
          <div className="section">
            <div className="link-back-wrap">
              <span className="link-back" onClick={() => onRefresh()}>
                <Icon type="arrow-back" />
                {t('monitorig.settings.back')}
              </span>
            </div>
            <div className="title main-title">{t('monitorig.settings.listSettings')}</div>
          </div>

          <div className="section">
            <div className="form-top">
              <div className="title">{t('monitorig.settings.listDescription')}</div>

              <div className="input-group">
                <div className="name-column">
                  <Form.Item
                    label={t('monitorig.settings.nameOfList')}
                    {...(errors.name && { validateStatus: 'error', help: errors.name })}
                  >
                    <Input onChange={onChangeName} value={name} />
                  </Form.Item>
                </div>

                <div className="color-column">
                  <label>
                    {t('monitorig.settings.color')}
                    <br />
                    <div className="color-picker">
                      <div className="color">
                        <span className={color} />
                      </div>

                      <Popover
                        placement="bottomLeft"
                        content={
                          <div className="dropdown-picker">
                            <div className="color-picker-title">{t('monitorig.settings.selectColor')}</div>
                            <ul className="color-picker-list">{colorList}</ul>
                          </div>
                        }
                        trigger="click"
                      >
                        <Button className="arrow">
                          <Icon type="arrow-down-picker" />
                        </Button>
                      </Popover>
                    </div>
                  </label>
                </div>
              </div>

              <div>
                <Form.Item
                  label={t('monitorig.settings.listDescription')}
                  {...(errors.description && { validateStatus: 'error', help: errors.description })}
                >
                  <Input.TextArea rows={3} onChange={onChangeDescription} value={description} />
                </Form.Item>
              </div>
            </div>
          </div>

          <div className="section">
            <div className="title">{t('monitorig.settings.notificationSettings')}</div>
            <div className="settings-list-wrap zh-checkbox-scroll">
              <div className="column">
                <ul>
                  <Radio.Group onChange={onChangeFrequency} value={frequency}>
                    <li>
                      <label>
                        <Radio value="NEVER" />
                        <Icon type="ring-no" />
                        {t('monitorig.settings.noAlerts')}
                      </label>
                    </li>
                    <li>
                      <label>
                        <Radio value="DAILY" />
                        <Icon type="ring" />
                        {t('monitorig.settings.dailyAlerts')}
                      </label>
                    </li>
                    <li>
                      <label>
                        <Radio value="WEEKLY" />
                        <Icon type="ring" />
                        {t('monitorig.settings.weeklyAlerts')}
                      </label>
                    </li>
                    <li>
                      <label>
                        <Radio value="MONTHLY" />
                        <Icon type="ring" />
                        {t('monitorig.settings.monthlyAlerts')}
                      </label>
                    </li>
                  </Radio.Group>
                </ul>
              </div>

              <Checkbox.Group onChange={onChangeNotifyKeys} value={notifyKeys}>
                {Object.keys(dataTypes).map(typeName => (
                  <div className="column" key={typeName}>
                    <div className="title">{t(`monitorig.types.${typeName}`)}</div>
                    <ul>
                      {Object.values(dataTypes[typeName]).map(childrenType => (
                        <li key={childrenType}>
                          <label>
                            <Checkbox value={childrenType} />
                            {t(`monitorig.types.${typeName}.${childrenType}`)}
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </Checkbox.Group>
            </div>
          </div>

          <div className="section">
            <div className="notification-method">
              <div className="title">{t('monitorig.settings.notificationMethod')}</div>

              <Form.Item label="Email" {...(errors.email && { validateStatus: 'error', help: errors.email })}>
                <Input onChange={onChangeEmail} value={email} />
              </Form.Item>
            </div>
          </div>

          <div className="section">
            <div className="list-footer">
              <Button className="btn reset-btn" onClick={onReset}>
                {t('monitorig.settings.cancel')}
              </Button>
              <Button className="btn apply-btn" type="primary" onClick={onSubmit}>
                {t('monitorig.settings.apply')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </Loader>
  );
};

export default ListSettings;
