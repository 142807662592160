import React, { useEffect, useReducer, useCallback } from 'react';
import { message } from 'antd';
import { useStateHandlers, useDocumentTitle } from '~/hooks';
import { injectIntl } from '~/libs/localization';
import { getRoute } from '~/libs/router';
import { exportings } from '~/libs/http/api';
import { ProfileLayout } from '~/components/layouts';
import { Tabs, Text, EmptyBlock, Loader } from '~/components/ui';
import { DownloadsList, DownloadsHistory } from '~/components/downloads';
import { DownloadModal } from '~/components/modal/company';
import { FeedbackModal } from '~/components/modal';

import '~/assets/scss/components/_MyProfile.scss';
import '~/assets/scss/components/_DownloadsList.scss';

const TABS = [
  { label: <Text id="downloads" />, value: 'list' },
  { label: <Text id="download.title" />, value: 'history' },
];

const filterReducer = (state, action) => {
  switch (action.type) {
    case 'SET_PAGE': {
      return { ...state, page: Number(action.page) };
    }
    case 'SET_SEARCH': {
      return { ...state, page: 1, search: action.value };
    }
    case 'SET_SORT': {
      return { ...state, page: 1, ordering: action.value };
    }
    case 'SET_MODE': {
      return { ...state, mode: action.mode };
    }
    case 'RESET': {
      return { ...state, page: 1 };
    }
    default:
      return state;
  }
};

const DownloadsPage = ({ t, match, history }) => {
  useDocumentTitle(t('page.downloads'));
  const [state, setState] = useStateHandlers({
    loading: false,
    list: [],
    history: [],
    total: 0,
    download: false,
    loadingDownload: null,
    feedback: false,
    activeExport: null,
    pages: 1,
  });

  const [filter, dispatch] = useReducer(filterReducer, {
    page: match.params.page,
    ordering: '-date_created',
    mode: match.params.type,
    search: '',
  });

  const onChangePage = newPage => {
    setState({ pages: newPage });
    dispatch({ type: 'SET_PAGE', page: newPage });
  };

  useEffect(() => {
    if (match.params.page !== filter.page) dispatch({ type: 'SET_PAGE', page: match.params.page });
    if (match.params.type !== filter.mode) dispatch({ type: 'SET_MODE', mode: match.params.type });
  }, [match.params]);

  useEffect(() => {
    if (filter.mode === 'list') onFetchList();
    if (filter.mode === 'history') onFetchHistory();
  }, [filter]);

  const onFetchList = async () => {
    setState({ loading: true });
    try {
      const options = {};

      options.page = filter.page;
      filter.search && (options.search = filter.search);
      filter.ordering && (options.sort = filter.ordering);

      const { data } = await exportings.getList(options);
      setState({ list: data.data, total: data.total_results, pages: data.pages });
      setState({ loading: false });
    } catch (err) {
      message.error(t('other.error_module'));
      setState({ loading: false });
    }
  };

  const onFetchHistory = async () => {
    setState({ loading: true });
    try {
      const options = {};

      options.page = filter.page;
      filter.search && (options.search = filter.search);

      const { data } = await exportings.getHistory(options);
      setState({ history: data.data, total: data.total_results });
      setState({ loading: false });
    } catch (err) {
      message.error(t('other.error_module'));
      setState({ loading: false });
    }
  };

  const onChangeSort = value => dispatch({ type: 'SET_SORT', value });
  const onChangeSearch = value => dispatch({ type: 'SET_SEARCH', value });
  const onChangeTab = type => {
    history.push(getRoute('DownloadsPage', { ...match.params, type, page: 1 }));
  };

  const onDelete = useCallback(async id => {
    await exportings.delete({ id });
    message.success(t({ id: 'other.success.deleted' }));

    onResetFilter();
  }, []);

  const onResetFilter = () => dispatch({ type: 'RESET' });
  const onDownload = useCallback(async ({ status, id }) => {
    setState({ loadingDownload: id });

    const { data } = await exportings.download({ id });

    window.open(`${process.env.REACT_APP_API_URL || ''}${data.url}`);

    setState({ downloadLink: `${process.env.REACT_APP_API_URL || ''}${data.url}`, loadingDownload: false });
  }, []);

  const onFeedback = activeExport => {
    setState({ feedback: true, activeExport });
  };

  const onCloseFeedback = () => {
    setState({ feedback: false });
  };

  const onCloseDownload = () => setState({ download: false, downloadLink: '' });

  return (
    <ProfileLayout type="download">
      {state.download && (
        <DownloadModal
          download
          title={t({ id: 'other.export' })}
          loadingText={t({ id: 'other.download.loading' })}
          finalText={t({ id: 'other.download.text' })}
          link={state.downloadLink}
          loading={state.loadingDownload}
          onClose={onCloseDownload}
        />
      )}

      {state.feedback && <FeedbackModal activeExport={state.activeExport} onClose={onCloseFeedback} />}

      <Tabs currentTab={filter.mode} onChange={onChangeTab} data={TABS} classList="tabs-dotted" />

      {state.loading && <Loader loading />}

      {!state.loading && filter.mode === 'list' && (
        <>
          <DownloadsList
            data={state.list}
            onChangeSort={onChangeSort}
            total={state.total}
            filter={filter}
            onDelete={onDelete}
            onChangeSearch={onChangeSearch}
            onDownload={onDownload}
            onFeedback={onFeedback}
            pages={state.pages}
            loading={state.loadingDownload}
            paginationChanges={onChangePage}
          />

          {state.list.length === 0 && <EmptyBlock />}
        </>
      )}

      {!state.loading && filter.mode === 'history' ? (
        state.history.length ? (
          <DownloadsHistory data={state.history} total={state.total} filter={filter} onChangeSearch={onChangeSearch} />
        ) : (
          <EmptyBlock />
        )
      ) : null}
    </ProfileLayout>
  );
};

export default injectIntl(DownloadsPage);
