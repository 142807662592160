import React from 'react';
import { Text } from '~/components/ui';
import { Icon } from '~/components/ui/Icon/Icon';

export default ({ status }) => (
  <span className={`download-status download-status-${status}`}>
    {status === 'pending' || status === 'running' ? <Icon type="loading" /> : null}

    <Text id={`profile.status.${status}`} />
  </span>
);
