import React from 'react';
import { Button } from 'antd';
import { AddUpdate, ManagementMenu } from '~/components/management';
import { injectIntl } from '~/libs/localization';
import { Layout } from '~/components/layouts';
import { Loader } from '~/components/ui';
import { useDocumentTitle } from '~/hooks';

const AdminAddPlatformPage = ({ t, validate, ...props }) => {
  useDocumentTitle(t('page.admin'));

  const onGoBack = () => props.history.goBack();

  return (
    <Layout containerClass="updatesEdit-container">
      <ManagementMenu />

      {props.loading && <Loader loading />}

      <div className="add-wrapper">
        <Button type="primary" icon="left" onClick={onGoBack} className="back-btn">
          Back
        </Button>

        <AddUpdate validate={validate} />
      </div>
    </Layout>
  );
};

export default injectIntl(AdminAddPlatformPage);
