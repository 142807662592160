import React, { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { compose } from 'redux';
import { billing, subscription } from '~/libs/http/api';
import { getRoute } from '~/libs/router';
import { injectIntl } from '~/libs/localization';
import { parseQuery } from '~/libs/http/url';
import withRequest from '~/hoc/withRequest';
import { PaymentForm, Payment } from '~/components/cart/payment';
import { Modal } from '~/components/ui';
import { ValidationModal } from '~/components/modal';
import { Icon } from '~/components/ui/Icon/Icon';

const PaymentWrapper = ({ children, modal, onClose, loading, style = {}, ...props }) =>
  modal ? (
    <Modal onClose={onClose} style={style} className="payment-modal modal-nofooter">
      <div className="payment-layout">
        <div className="payment-wrapper public-payment-wrapper">{children}</div>
      </div>
    </Modal>
  ) : (
    <div className="payment-layout" style={style}>
      <div className="payment-wrapper public-payment-wrapper">{children}</div>
    </div>
  );

const PaymentData = ({ t, validate, header = true, ...props }) => {
  const params = useParams();
  const [error, setError] = useState('');
  const [response, setResponse] = useState('');
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const onCheckErrors = () => {
      const query = parseQuery(location.search);

      if (query.detail) {
        setError(query.detail);

        if (props.route) {
          history.push(getRoute(props.route, params));
        }
      }
    };

    onCheckErrors();
  }, [error]);

  useEffect(() => {
    if (props.api.errors.detail || props.api.errors.non_field_errors) {
      setError(props.api.errors.detail || props.api.errors.non_field_errors);
    }
  }, [props.api.errors]);

  useEffect(() => {
    if (response && response.term_url) {
      document.forms.autosubmit.submit();
    }
  }, [response]);

  const onSubmit = async data => {
    const { packageId, ref } = params;

    const options = {
      ...(ref && { ref }),
      ...(props.id && { id: props.id }),
      ...(props.users && { users: props.users }),
      ...(packageId && { package_id: packageId }),
      card_type: data.method,
      card_number: data.card,
      month: data.expiryMonth && data.expiryMonth.toString(),
      year: data.expiryYear && data.expiryYear.toString(),
      first_name: data.firstName,
      last_name: data.lastName,
      cvv: data.cvv,
      redirect_to: getRoute(props.route || props.redirect, props.redirectParams),
      redirect_fail: getRoute('OrderPage', {
        ...{},
        page: 1,
      }),
    };

    let rqs;

    props.parent === 'applications' && (rqs = subscription.upgrade(options));
    props.parent === 'cart' && (rqs = billing.payCart(options));
    props.parent === 'license' && (rqs = billing.buyUsers(options));
    props.parent === 'export' && (rqs = props.onSaveExport({ pay_data: options }));

    const resp = await props.request(rqs);

    setResponse(resp);
    props.onSuccess && props.onSuccess();
  };

  const onCloseValidation = () => setError('');

  return (
    <>
      {error ? <ValidationModal type="error" message={error} onClose={onCloseValidation} /> : null}

      <PaymentWrapper style={props.style} modal={props.modal} onClose={props.onClose}>
        <div className="info-block">
          <div className="block-title">
            <Icon type="payment-icons-lock" />
            <span>{t('billing.payment')}</span>
          </div>

          <div className="info">{t('billing.info')}</div>
        </div>

        <PaymentForm onSubmit={onSubmit} onBack={props.onClose} loading={props.api.loading} errors={props.api.errors} />
      </PaymentWrapper>

      <Payment response={response} />
    </>
  );
};

const enhance = compose(withRequest({ flatten: 'pay_data' }), injectIntl);

export default enhance(PaymentData);
