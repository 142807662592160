import React, { useState, useEffect } from 'react';
import { Radio, Tooltip } from 'antd';
import { useParams } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import { useInjectIntl } from '~/libs/localization';
import { lists } from '~/libs/http/api';
import { Modal, Form, Button } from '~/components/ui';
import { Icon } from '~/components/ui/Icon/Icon';

import '~/assets/scss/components/ui/_SaveResults.scss';

const Hint = ({ classList, type = 'hint-icons-alert', value }) => (
  <Tooltip title={<span>{value}</span>} overlayClassName="zh-tooltip-blue">
    <Icon type={type} className={classList} />
  </Tooltip>
);

const SaveResults = props => {
  const [option, setOption] = useState();
  const [hint, setHint] = useState();
  const { t } = useInjectIntl();
  const { type } = useParams();

  const { permissions, info } = useSelector(
    ({ authentication, searches }) => ({
      permissions: authentication.permissions,
      info: searches,
    }),
    shallowEqual,
  );

  const showHint = async () => {
    const { data } = await lists.getListLimits();
    const limit = data.limit_company;
    let newHint = null;
    const current = !info.selectedRow.length ? info.total : info.selectedRow.length;

    if (current > limit)
      newHint = t('list.add_list_hint', {
        limit,
        type: t(`list.${type === 'employee' ? 'employee' : 'company'}`).toLowerCase(),
      });

    setHint(newHint);
  };

  useEffect(() => {
    showHint();
  }, []);

  const onSubmit = async () => props.openResult(option);

  const onChangeOption = ({ target }) => setOption(target.value);

  return (
    <Modal
      className="save-results-modal"
      clean
      title=" "
      onClose={props.onClose}
      footerCenter={
        <>
          <Button onClick={props.onClose}>{t('other.reset')}</Button>

          <Button type="primary" disabled={option === undefined} onClick={onSubmit}>
            {t('other.submit')}
          </Button>
        </>
      }
    >
      <Form.Item>
        <div className="saved-lists">
          <Radio.Group onChange={onChangeOption}>
            <Radio value="" disabled={!permissions.includes('saved_list')}>
              <div className="saved-lists-item">
                <div className="circle">
                  <Icon type="report-lists" />

                  {hint ? <Hint classList="hint-icon" fixed={props.fixed} type="warning" value={hint} /> : null}
                </div>

                <div>
                  <h2>{t('list.save_as_list')}</h2>
                  <p>{t('list.save_as_list_desc')}</p>
                </div>
              </div>
            </Radio>

            <Radio value="search" disabled={!permissions.includes('saved_search')}>
              <div className="saved-lists-item">
                <div className="circle">
                  <Icon type="report-save" />
                </div>

                <div>
                  <h2>{t('list.save_as_search')}</h2>
                  <p>{t('list.save_as_search_desc')}</p>
                </div>
              </div>
            </Radio>
          </Radio.Group>
        </div>
      </Form.Item>
    </Modal>
  );
};

export default SaveResults;
