import React, { memo } from 'react';
import { indexed } from '~/libs/array';
import { truncate } from '~/libs/string';
import { Empty, EmptyBlock, Loader } from '~/components/ui';
import { Table } from 'antd';

const ExportPreviewTable = ({ loading, headers = [], data = [] }) => (
  <Loader loading={loading} height={175}>
    <div className="zh-export-preview">
      {data.length && headers.length ? (
        <Table
          rowKey="id"
          className="striped"
          columns={data[0].map((item, itemKey) => ({
            className: 'collapsing',
            title: headers[itemKey],
            dataIndex: itemKey,
            render: (text, record, n) => (data[n] && data[n][itemKey] ? truncate(data[n][itemKey], 25) : <Empty />),
          }))}
          dataSource={indexed(data)}
          scroll={{ x: false }}
          pagination={false}
        />
      ) : (
        <EmptyBlock />
      )}
    </div>
  </Loader>
);

export default memo(ExportPreviewTable);
