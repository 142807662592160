import { adapterIntl } from '~/libs/localization/helpers';

const monitorig = adapterIntl({
  'monitorig.sidebar.monitoredCompanies': {
    ro: 'Companii monitorizate',
    ru: 'Отслеживаемые компании',
    en: 'Monitored companies',
  },
  'monitorig.sidebar.usersMonitoredCompanies': {
    ro: 'Companiile utilizatorilor',
    ru: 'Компании пользователей',
    en: 'Users monitored companies',
  },
  'monitorig.sidebar.updates': {
    ro: 'Actualizări',
    ru: 'Обновления',
    en: 'Updates',
  },
  'monitorig.sidebar.allLists': {
    ro: 'Toate listele',
    ru: 'Все списки',
    en: 'All lists',
  },
  'monitorig.sidebar.default': {
    ro: 'Mod implicit',
    ru: 'По умолчанию',
    en: 'Default',
  },
  'monitorig.sidebar.providers': {
    ro: 'Furnizori',
    ru: 'Провайдеры',
    en: 'Providers',
  },
  'monitorig.sidebar.customers': {
    ro: 'Clienți',
    ru: 'Клиенты',
    en: 'Customers',
  },
  'monitorig.sidebar.ourCompanies': {
    ro: 'Companiile noastre',
    ru: 'Наши компании',
    en: 'Our companies',
  },
  'monitorig.sidebar.competitors': {
    ro: 'Concurenți',
    ru: 'Конкуренты',
    en: 'Competitors',
  },
  'monitorig.sidebar.liability': {
    ro: 'Datorii',
    ru: 'Задолжности',
    en: 'Liability',
  },
  'monitorig.sidebar.contractors': {
    ro: 'Contractanți',
    ru: 'Подрядчики',
    en: 'Contractors',
  },
  'monitorig.sidebar.addTheList': {
    ro: 'Adaugă lista',
    ru: 'Добавить список',
    en: 'Add list',
  },
  'monitorig.all.monitoredCompanies': {
    ro: 'Companii monitorizate',
    ru: 'Отслеживаемые компании',
    en: 'Monitored companies',
  },
  'monitorig.all.addContractors': {
    ro: 'Adaugă contractori',
    ru: 'Добавить подрядчиков',
    en: 'Add contractors',
  },
  'monitorig.delete_monitoring_list': {
    ro: 'Doriți să ștergeți lista',
    ru: 'Вы хотите удалить лист',
    en: 'Do you want to delete a list',
  },
  'monitorig.addFileContractorsList': {
    ro: 'Adăuga fișier cu lista contractanților',
    ru: 'Добавить файл со списком подрядчиков',
    en: 'Add file with contractors list',
  },
  'monitorig.chooseFile': {
    ro: 'Alege fișier',
    ru: 'Выбрать файл',
    en: 'Choose file',
  },
  'monitorig.menu': {
    ro: 'Menu',
    ru: 'Меню',
    en: 'Menu',
  },
  'monitorig.fileFormat': {
    ro: 'Format de fișier disponibil pentru încărcare: .txt',
    ru: 'Формат файла, доступный для загрузки: .txt',
    en: 'File format available for upload: .txt',
  },
  'monitorig.noteUploaded': {
    ro: 'Rețineți că fișierul încărcat trebuie să fie în codificare UTF8 sau ANSIyoucontrol',
    ru: 'Обратите внимание, что загруженный файл должен быть в кодировке UTF8 или ANSIyoucontrol.',
    en: 'Note that the uploaded file must be in UTF8 or ANSIyoucontrol encoding',
  },
  'monitorig.seeExample': {
    ro: 'Vezi examplu',
    ru: 'См. Пример',
    en: 'See example',
  },
  'monitorig.contractorListFile': {
    ro: 'Fișier cu lista contractanților',
    ru: 'Файл списка подрядчиков',
    en: 'Contractor list file',
  },
  'monitorig.apply': {
    ro: 'Aplică',
    ru: 'Применить',
    en: 'Apply',
  },
  'monitorig.pleaseCounterpart': {
    ro: 'Te rugam sa adăugați contraparte',
    ru: 'Пожалуйста, добавьте контрагента',
    en: 'Please add counterpart',
  },
  'monitorig.exampleOfLoadingVisor': {
    ro: 'Exemplu de fișier pentru încărcare',
    ru: 'Пример загрузки файла',
    en: 'Example of loading file',
  },
  'monitorig.toLoadContractors': {
    ro: 'Pentru a încărca contractorii în monitorizare, trebuie să introduceți informația într-un document text',
    ru: 'Для загрузки контрагентов в мониторинг необходимо ввести информацию в текстовом документе.',
    en: 'To load contractors into monitoring, you must enter information in a text document',
  },
  'monitorig.fileExtension': {
    ro: '(extensie de fișier - „.txt”)',
    ru: '(расширение файла - «.txt»)',
    en: '(file extension - ".txt")',
  },
  'monitorig.typesOfCodes': {
    ro: 'Tipuri de informație',
    ru: 'Типы информации',
    en: 'Types of information',
  },
  'monitorig.IDNOOfLegal': {
    ro: 'IDNO al unei persoane juridice (13 cifre)',
    ru: 'IDNO юридического лица (13 цифр)',
    en: 'IDNO of a legal entity (13 digits)',
  },
  'monitorig.IDNOOfIndividual': {
    ro: 'IDNO al unui antreprenor individual (13 cifre)',
    ru: 'IDNO индивидуального предпринимателя (13 цифр)',
    en: 'IDNO of an individual entrepreneur (13 digits)',
  },
  'monitorig.seriesAndNumberOfPassports': {
    ro: 'Seria și numărul pașapoartelor pentru antreprenorii individuali',
    ru: 'Серия и количество паспортов для ИП',
    en: 'Series and number of passports for individual entrepreneurs',
  },
  'monitorig.eachCounterpartyIsPlaced': {
    ro: 'Fiecare contraparte este plasată pe o nouă linie și nu trebuie să conțină virgule și puncte.',
    ru: 'Каждый контрагент помещается в новую строку и не должен содержать запятых и точек.',
    en: 'Each counterparty is placed on a new line and must not contain commas and periods.',
  },
  'monitorig.settings.back': {
    ro: 'înapoi',
    ru: 'назад',
    en: 'back',
  },
  'monitorig.settings.listSettings': {
    ro: 'Setarile listei',
    ru: 'Список настроек',
    en: 'List settings',
  },
  'monitorig.list_delete_question': {
    ro: 'Doriţi să ştergeţi lista cu toate companiile?',
    ru: 'Вы хотите удалить список всех компаний?',
    en: 'You want to delete the list of all companies?',
  },
  'monitorig.settings.listDescription': {
    ro: 'Descrierea listei',
    ru: 'Описание списка',
    en: 'List description',
  },
  'monitorig.settings.nameOfList': {
    ro: 'Denumirea listei',
    ru: 'Название списка',
    en: 'Name of the list',
  },
  'monitorig.settings.color': {
    ro: 'Culoarea',
    ru: 'Цвет',
    en: 'Color',
  },
  'monitorig.settings.selectColor': {
    ro: 'Selectați culoarea',
    ru: 'Выбрать цвет',
    en: 'Select color',
  },
  'monitorig.settings.notificationSettings': {
    ro: 'Setări de notificare',
    ru: 'Настройки уведомлений',
    en: 'Notification settings',
  },
  'monitorig.settings.notificationMethod': {
    ro: 'Metoda de notificare',
    ru: 'Способ уведомления',
    en: 'Notification method',
  },
  'monitorig.settings.cancel': {
    ro: 'Anulează',
    ru: 'Отменить',
    en: 'Cancel',
  },
  'monitorig.settings.apply': {
    ro: 'Aplică',
    ru: 'Применить',
    en: 'Apply',
  },
  'monitorig.settings.noAlerts': {
    ro: 'Fără alerte',
    ru: 'Без уведомлений',
    en: 'No alerts',
  },
  'monitorig.settings.dailyAlerts': {
    ro: 'Alerte zilnice',
    ru: 'Ежедневные',
    en: 'Daily alerts',
  },
  'monitorig.settings.weeklyAlerts': {
    ro: 'Alerte săptămânale',
    ru: 'Еженедельные',
    en: 'Weekly alerts',
  },
  'monitorig.settings.monthlyAlerts': {
    ro: 'Alerte lunare',
    ru: 'Ежемесячные',
    en: 'Monthly alerts',
  },
  'monitorig.types.companies': {
    ro: 'Companii',
    ru: 'Компании',
    en: 'Companies',
  },
  'monitorig.types.companies.title_title': {
    ro: 'Denumirea',
    ru: 'Название',
    en: 'Name',
  },
  'monitorig.types.companies.title_title_de_jure': {
    ro: 'Nume de jure',
    ru: 'Де-юре имя',
    en: 'De jure name',
  },
  'monitorig.types.companies.enterprise_status': {
    ro: 'Statut',
    ru: 'Статус',
    en: 'Status',
  },
  'monitorig.types.companies.enterprise_size': {
    ro: 'Numărul de angajați',
    ru: 'Количество работников',
    en: 'Number of employees',
  },
  'monitorig.types.companies.product_and_services': {
    ro: 'Produse și servicii',
    ru: 'Продукты и услуги',
    en: 'Products and services',
  },
  'monitorig.types.companies.enterprise_capital_value': {
    ro: 'Capital social',
    ru: 'Социальный капитал',
    en: 'Social capital',
  },
  'monitorig.types.companies.business_hours': {
    ro: 'Orar de lucru',
    ru: 'Рабочее время',
    en: 'Working hours',
  },
  'monitorig.types.contacts': {
    ro: 'Contacte',
    ru: 'Контакты',
    en: 'Contacts',
  },
  'monitorig.types.contacts.www_url': {
    ro: 'Website',
    ru: 'Website',
    en: 'Website',
  },
  'monitorig.types.contacts.email_email': {
    ro: 'Email',
    ru: 'Email',
    en: 'Email',
  },
  'monitorig.types.financials': {
    ro: 'Financiare',
    ru: 'Финансовые показатели',
    en: 'Financials',
  },
  'monitorig.types.financials.financial_income_net': {
    ro: 'Profit Net',
    ru: 'Прибыль',
    en: 'Profit',
  },
  'monitorig.types.financials.financial_income_sales': {
    ro: 'Cifra de afaceri',
    ru: 'Оборот',
    en: 'Turnover',
  },
  'monitorig.types.persons': {
    ro: 'Angajaţi',
    ru: 'Персонал',
    en: 'Employees',
  },
  'monitorig.types.persons.person_share_value': {
    ro: 'Cota parte',
    ru: 'Доля',
    en: 'Shared value',
  },
  'monitorig.recentUpdates': {
    ro: 'Actualizări recente',
    ru: 'Недавние обновления',
    en: 'Recent updates',
  },
  'monitorig.moreDetails': {
    ro: 'Mai multe detali',
    ru: 'Подробнее',
    en: 'More details',
  },
  'monitorig.date': {
    ro: 'Data',
    ru: 'Дата',
    en: 'Date',
  },
  'monitorig.changes': {
    ro: 'Modificare',
    ru: 'Изменения',
    en: 'Changes',
  },
  'monitorig.dateFrom': {
    ro: 'De la',
    ru: 'От',
    en: 'From',
  },
  'monitorig.dateTo': {
    ro: 'Până la',
    ru: 'До',
    en: 'To',
  },
  'monitorig.name': {
    ro: 'Denumirea',
    ru: 'Название',
    en: 'Name',
  },
  'monitorig.type': {
    ro: 'Listă',
    ru: 'Список',
    en: 'List',
  },
  'monitorig.companyProductsServices': {
    ro: 'Produsele si Serviciile Companiei',
    ru: 'Продукты и услуги компании',
    en: 'Company Products and Services',
  },
  'monitorig.errorTxt': {
    ro: 'Puteți încărca numai fișierul .txt',
    ru: 'Вы можете загружать только файл .txt',
    en: 'You can only upload .txt file',
  },
  'monitorig.lessText': {
    ro: ' Vezi mai puțin',
    ru: ' Показать меньше',
    en: ' Read less',
  },
  'monitorig.moreText': {
    ro: ' Vezi mai mult',
    ru: ' Показать больше',
    en: ' Read more',
  },
  'monitorig.errorTxtSize': {
    ro: 'Fișierul trebuie să fie mai mic de 1 MB. Limită - 1 000 linii',
    ru: 'Файл должен быть меньше 1 МБ. Лимит - 1 000 строк',
    en: 'File must smaller than 1MB. Limit - 1 000 lines',
  },
  'monitorig.numbersFound': {
    ro: 'numere IDNO găsite',
    ru: 'номера IDNO найдено',
    en: 'IDNO numbers found',
  },
  'monitorig.deleteFromList': {
    ro: 'Șterge din listă',
    ru: 'Удалить из списка',
    en: 'Delete from the list',
  },
  'monitorig.addToAnotherList': {
    ro: 'Adaugă la altă listă',
    ru: 'Добавить в другой список',
    en: 'Add to another list',
  },
  'monitorig.addCounterpart': {
    ro: 'Adăugați contrapartea',
    ru: 'Добавить контрагента',
    en: 'Add the counterpart',
  },
  'monitorig.addCounterpartList': {
    ro: 'Adăugați contrapartea in lista',
    ru: 'Добавить контрагента в список',
    en: 'Add the counterpart to the list',
  },
  'monitorig.firstAddIDNO': {
    ro: 'Mai întâi adăugați IDNO',
    ru: 'Сначала добавьте IDNO',
    en: 'First add IDNO',
  },
});

export default monitorig;
