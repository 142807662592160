import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Link, getRoute } from '~/libs/router';
import { Button, Popover, message } from 'antd';
import { useRequest } from 'estafette';
import { useStateHandlers } from '~/hooks';
import { useInjectIntl } from '~/libs/localization';
import { locations } from '~/libs/http/api';
import { Confirmation, Empty, Table, Loader, Pagination, EmptyBlock, Icon } from '~/components/ui';
import { TableFooter } from '~/components/table';
import { PopAction } from '~/components/profile';
import { LocationInfo } from '~/components/messages';

import '~/assets/scss/components/_LocationsList.scss';

const LocationsList = () => {
  const { t } = useInjectIntl();
  const history = useHistory();
  const { request: requestLocations, data: dataLocations, loading: loadingLocation } = useRequest();
  const { request: requestlocationInfo, data: locationInfo } = useRequest();
  const { request: requestDeleteLocation, loading: loadingDeleteLocation } = useRequest();

  const [locationEditData, setLocationEditData] = useState();
  const [editLocation, setEditLocation] = useState(false);
  const [switchData, setSwitchData] = useState(false);
  const [filter, setFilter] = useStateHandlers({
    page: 1,
  });
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    if (locationInfo) {
      setLocationEditData(locationInfo);
    }
  }, [locationInfo]);

  useEffect(() => {
    onFetchData();
  }, [filter]);

  const onFetchData = async () => {
    try {
      const params = {
        ...filter,
        per_page: 5,
      };

      await requestLocations(locations.getList(params));
    } catch ({ response }) {
      message.error(t('other.error_module'));
    }
  };

  const onDelete = async id => {
    try {
      setDeleteId(id);

      await requestDeleteLocation(
        locations.deleteAddress({
          id,
        }),
      );

      onFetchData();
      setEditLocation(false);
      setDeleteId(null);
    } catch (error) {
      message.error(t('other.error_module'));
    }
  };

  const onEdit = async id => {
    try {
      const params = {
        id,
      };

      await requestlocationInfo(locations.getLocationInfo(params));

      setEditLocation(true);
      setSwitchData(true);
    } catch ({ response }) {
      message.error(t('other.error_module'));
    }
  };

  const onAddLocation = () => {
    setEditLocation(true);
    setSwitchData(false);
  };

  const onCancel = () => {
    setEditLocation(false);
    onFetchData();
  };

  const onChangePage = newPage => setFilter({ page: newPage });

  const columnsLocations = [
    {
      title: '',
      className: 'collapsing',
      dataIndex: 'id',
      render: (_, record) =>
        (
          <div className="clearfix">
            <div className="pull-left">
              <div className="ico-block">
                <Icon type="marker-small" />
              </div>
            </div>
            <div className="pull-left in-two-rows">
              <span>{record.title}</span>
              <small className="address">
                {record.addresses.map((id, key) => (
                  <span key={id}>
                    {dataLocations.addresses[id]?.name || ''}
                    {key < record.addresses.length - 1 && ', '}
                  </span>
                ))}
              </small>
            </div>

            <Popover
              content={
                <>
                  <PopAction
                    icon="edit"
                    value={
                      <Link onClick={() => onEdit(record.id)}>
                        <span className="table-action-text cursor">{t({ id: 'other.edit' })}</span>
                      </Link>
                    }
                  />

                  <PopAction
                    icon="delete"
                    value={
                      <Confirmation type="delete" onConfirm={() => onDelete(record.id)}>
                        <span className="table-action-text cursor">{t({ id: 'other.delete' })}</span>
                      </Confirmation>
                    }
                  />
                </>
              }
              trigger="click"
            >
              <div className="popover-more">
                <Icon type="ellipsis" className="btn-ellipsis" />
              </div>
            </Popover>
          </div>
        ) || <Empty />,
    },
    {
      title: '',
      className: 'nowrap collapsing control-wrap control-wrap-desktop left-side-div',
      dataIndex: 'delete',
      render: (_, record) => (
        <Confirmation type="delete" onConfirm={() => onDelete(record.id)}>
          <span className="table-action-text cursor">
            <Loader.Inline loading={loadingDeleteLocation && record.id === deleteId}>
              <Icon type="trash" />
              {t({ id: 'other.delete' })}
            </Loader.Inline>
          </span>
        </Confirmation>
      ),
    },
    {
      title: '',
      className: 'nowrap collapsing control-wrap control-wrap-desktop left-side-div',
      dataIndex: 'edit',
      width: '1px',
      render: (_, record) => (
        <span className="table-action-text cursor" onClick={() => onEdit(record.id)}>
          <Icon type="edit" />
          {t({ id: 'other.edit' })}
        </span>
      ),
    },
  ];

  const backToListAll = () => {
    history.push(getRoute('Alerts', { filter: 'all', mode: 'list' }));
  };

  return (
    <div className="locations-list">
      {!editLocation ? (
        <Loader loading={!dataLocations.data || loadingLocation}>
          <div className="form-inline">
            <Button className="add-location-button" onClick={backToListAll}>
              {t('billing.back')}
            </Button>
            <Button className="add-location-button" onClick={onAddLocation}>
              <Icon type="marker-small" />
              {t('disconnections.addLocation')}
            </Button>
          </div>
          {dataLocations.data ? (
            <>
              <Table
                rowKey="id"
                defaultExpandAllRows
                className="table-no-header basic"
                fixed
                columns={columnsLocations}
                dataSource={dataLocations.data}
                showHeader={false}
              />
              <TableFooter
                leftSide={<Pagination page={filter.page} total={dataLocations.pages} onChange={onChangePage} />}
              />
            </>
          ) : (
            <EmptyBlock />
          )}
        </Loader>
      ) : (
        <LocationInfo
          setData={setLocationEditData}
          data={locationEditData}
          onCancel={onCancel}
          onDelete={onDelete}
          editLocation={switchData}
          loadingDeleteLocation={loadingDeleteLocation}
        />
      )}
    </div>
  );
};

export default LocationsList;
