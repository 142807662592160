import React, { useRef, useEffect, useState } from 'react';
import { useRequest } from 'estafette';
import { Button, Popover, message } from 'antd';
import { useInjectIntl } from '~/libs/localization';
import { useSelector } from 'react-redux';
import { Link, getRoute } from '~/libs/router';
import { useHistory } from 'react-router-dom';
import { members, accountTeam, profile } from '~/libs/http/api';
import { ProfileLayout } from '~/components/layouts';
import { PopAction, UserCart, CompanyCart } from '~/components/profile';
import { Confirmation } from '~/components/ui';
import { TeamLicenseModal } from '~/components/modal/profile';
import { TableHeader } from '~/components/table';
import { Icon } from '~/components/ui/Icon/Icon';

import '~/assets/scss/components/_MyProfile.scss';
import '~/assets/scss/components/_TeamPage.scss';

const TeamPage = () => {
  const history = useHistory();
  const { request: requestDelete, loading: loadingDelete, errors } = useRequest({ data: { data: [] } });
  const { request: requestCompanyList, data: dataCompanyList, loading: loadingCompanyList } = useRequest({
    loading: true,
  });
  const { request: requestProfile, data: dataProfile, loading: loadingProfile } = useRequest({
    loading: true,
    data: {},
  });
  const { t } = useInjectIntl();
  const popoverRef = useRef();
  const store = useSelector(({ authentication }) => ({
    permissions: authentication.permissions,
    params: authentication.params,
  }));

  const [license, setLicense] = useState(false);

  const isStaff = React.useMemo(() => store.permissions.includes('add_users') && store.params.is_staff, [
    store.permissions,
    store.params,
  ]);

  useEffect(() => {
    onCompanyFetch();
    onProfileFetch();
  }, []);

  const onCompanyFetch = () => requestCompanyList(accountTeam.companyList());

  const onProfileFetch = () => requestProfile(profile.get());

  const onEditUser = userId => history.push(getRoute('TeamChangePage', { action: 'edit', id: userId }));

  const onDelete = id => {
    requestDelete(members.delete({ id })).then(() => onCompanyFetch());

    const popover = popoverRef.current;

    const close = error => {
      error.length > 1
        ? message.error(t({ id: 'profile.deletePersonError' }))
        : message.success(t({ id: 'profile.deletePersonSuccess' }));
    };

    close(errors);
    return popover.trigger;
  };

  const onLicenseToggle = () => setLicense(s => !s);

  const dataList = React.useMemo(() => {
    if (dataProfile.seniority_id === 3) {
      return dataCompanyList;
    }

    const company = dataCompanyList.find(item => item.name === dataProfile.company_name);
    if (company !== undefined) {
      return [company];
    }

    return [];
  }, [dataProfile, dataCompanyList]);

  const popoverPermission = <span>You dont have permission</span>;

  return (
    <div className="team-page">
      {license && <TeamLicenseModal onClose={onLicenseToggle} />}

      <ProfileLayout
        className="team-container"
        empty={dataList.lenth === 0 && !loadingCompanyList && !loadingProfile}
        loading={loadingCompanyList || loadingProfile}
      >
        <TableHeader
          leftSide={
            <div className="form-inline">
              <h1>
                {t({ id: 'team.myTeam' })} ({dataCompanyList.length})
              </h1>

              {/* <Button onClick={onLicenseToggle}>{t({ id: 'team.license' })}</Button> */}
            </div>
          }
          rightSide={
            !isStaff ? null : (
              <Link
                route="AddCompanyPage"
                params={{ action: 'add', id: '' }}
                disabled={!store.permissions.includes('create_enterprise')}
              >
                {!store.permissions.includes('create_enterprise') ? (
                  <Popover
                    content={popoverPermission}
                    trigger="hover"
                    placement="bottomLeft"
                    overlayClassName="tabs-popover-permission"
                  >
                    <Button disabled={!isStaff || !store.permissions.includes('create_enterprise')}>
                      <Icon type="people-plus" />
                      {t({ id: 'team.addCompany' })}
                    </Button>
                  </Popover>
                ) : (
                  <>
                    <Button disabled={!isStaff || !store.permissions.includes('create_enterprise')}>
                      <Icon type="people-plus" />
                      {t({ id: 'team.addCompany' })}
                    </Button>
                  </>
                )}
              </Link>
            )
          }
        />

        <div className="company-container">
          {dataList.map(item => (
            <div key={item.id}>
              <div className="company-name">
                <div className="company-name-left">
                  <CompanyCart item={item} permissions={dataProfile.seniority_id} />
                </div>

                {dataProfile.seniority_id !== 1 && (
                  <div className="company-name-right">
                    <Link route="TeamChangePage" params={{ action: 'add', id: item.id }}>
                      <Button className="add-person-btn">
                        <Icon type="profile-user-plus" />
                        {t({ id: 'team.addPerson' })}
                      </Button>
                    </Link>
                  </div>
                )}
              </div>

              <div className="company-name-items">
                {item.members.map(user => (
                  <div key={user.user_id}>
                    <div className="company-name-item">
                      <div className="company-name-item-left">
                        <UserCart item={user} />
                      </div>

                      {dataProfile.seniority_id !== 1 && (
                        <div className="company-name-item-right">
                          <Popover
                            ref={popoverRef}
                            align={{ offset: [-20, 10] }}
                            content={
                              <>
                                <PopAction
                                  useRef={popoverRef}
                                  icon="edit"
                                  value={t({ id: 'team.edit' })}
                                  onClick={() => onEditUser(user.user_id)}
                                />

                                <PopAction
                                  icon="trash"
                                  loading={loadingDelete}
                                  value={
                                    <Confirmation onConfirm={() => onDelete(user.user_id)}>
                                      <span>{t({ id: 'profile.deletePerson' })}</span>
                                    </Confirmation>
                                  }
                                />
                              </>
                            }
                            trigger="click"
                          >
                            <div className="popover-more">
                              <Icon type="dot-settings" className="btn-ellipsis" />
                              <div className="popover-more--button">{t({ id: 'profile.more' })}</div>
                            </div>
                          </Popover>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </ProfileLayout>
    </div>
  );
};

export default TeamPage;
