import React from 'react';
import { injectIntl } from '~/libs/localization';

const EmptyBlock = ({ t, style, label, classList }) => (
  <div className={`empty-block-title ${classList}`} style={style}>
    {label || t('other.noData')}
  </div>
);

export default injectIntl(EmptyBlock);
