import React, { useState, useEffect, useRef } from 'react';
import { useRequest } from 'estafette';
import { Input, Select, message } from 'antd';
import { useDocumentTitle } from '~/hooks';
import { useInjectIntl } from '~/libs/localization';
import { profile } from '~/libs/http/api';
import { validate } from '~/libs/object';
import { ProfileLayout } from '~/components/layouts';
import { Avatar } from '~/components/profile';
import { Section, Form, Button, Text, InputPassword } from '~/components/ui';
import env from '@beam-australia/react-env';

import '~/assets/scss/components/_MyProfile.scss';

const MyProfilePage = () => {
  const { t } = useInjectIntl();
  const { request, loading: loadingData, data } = useRequest();
  const { request: requestSeniorities, data: dataSeniorities } = useRequest();
  const { request: requestPhoto, loading: loadingPhoto } = useRequest();
  const { request: requestSubmit, loading: loadingSubmit, errors: errorsSubmit } = useRequest();
  const profileImage = useRef();
  const [api, setApi] = useState({});
  const [preview, setPreview] = useState('');
  const [photo, setPhoto] = useState(null);
  const [errorPassword, setErrorPassword] = useState(null);

  useDocumentTitle(t('page.profile'));

  const onSubmitPhoto = photoUser => {
    const formData = new FormData();
    formData.append('photo', photoUser);

    requestPhoto(profile.edit(formData));
  };

  const clearImageInput = () => {
    const image = profileImage.current;
    return (image.value = '');
  };

  const uploadPhoto = photoUser => {
    clearImageInput();

    const reader = new FileReader();

    reader.readAsDataURL(photoUser);

    reader.onloadend = async () => {
      setPreview(reader.result);
      setApi({
        ...api,
        photoUser,
      });

      onSubmitPhoto(photoUser);
    };
  };

  const onDeletePhoto = () => {
    clearImageInput();
    setPhoto('');
    setPreview('');
    const formData = new FormData();
    formData.append('delete_photo', true);
    formData.append('is_approved', true);
    requestSubmit(profile.edit(formData));
  };

  const successSave = () => {
    message.config({ top: 80 });
    message.success(t('profile.success-save'));
  };

  const onSubmit = async () => {
    setErrorPassword(null);

    const options = {
      first_name: api.first_name,
      last_name: api.last_name,
      address: api.address,
      email: api.email,
      phone: api.phone,
    };

    if (api.new_password && !api.old_password) {
      setErrorPassword(t({ id: 'profile.mustCurrentPassword' }));
    } else if (api.old_password && api.new_password) {
      options.old_password = api.old_password;
      options.new_password = api.new_password;
    }

    if (api.job_title) options.job_title = api.job_title;
    if (api.seniority_id) options.seniority_id = api.seniority_id;

    await requestSubmit(profile.edit(options));

    successSave();

    // history.push(getRoute('TeamPage'));
  };

  useEffect(() => {
    setApi(data);
  }, [data]);

  useEffect(() => {
    request(profile.get());

    requestSeniorities(profile.profileSeniority());
  }, []);

  useEffect(() => {
    const photoNew = data.photo ? `${env('API_URL') || ''}/${data.photo || ''}` : null;
    setPhoto(photoNew);
  }, [data]);

  return (
    <ProfileLayout loading={loadingData}>
      <div className="title">{api.is_staff ? <Text id="profile.profile" /> : t({ id: 'profile.profile' })}</div>

      <div className="half-sections no-half-section section-settings">
        <Section>
          <div className="profile-avatar">
            <Avatar.Editable
              img={preview || photo}
              alt={`${api.first_name || ''} ${api.last_name || ''}`}
              onClose={onDeletePhoto}
              loading={loadingPhoto}
            />

            <h2>
              <Text id="profile.uploadAvatar" />
            </h2>

            <label className="atn-file">
              <input
                ref={profileImage}
                type="file"
                accept="image/*"
                onChange={({ target }) => uploadPhoto(target.files[0])}
              />

              <Text id="profile.uploadImage" />
            </label>

            <Button onClick={onDeletePhoto}>
              <Text id="profile.deleteImage" />
            </Button>
          </div>
        </Section>

        <Section>
          {/* <div className={`profile-approved ${!api.is_approved ? 'not-' : ''}approved`}>
            {t(`profile.${!api.is_approved ? 'not_' : ''}approved`)}
          </div> */}

          <Form>
            <>
              <Form.Header>
                {api.is_staff ? <Text id="profile.settings" /> : t({ id: 'profile.judeSettings' })}
              </Form.Header>

              {api.is_staff ? (
                <Form.Half>
                  <Form.Item label={t({ id: 'profile.companyName' })} {...validate(errorsSubmit, 'company_name')}>
                    <Input
                      type="text"
                      disabled={!api.is_staff}
                      placeholder={t({ id: 'other.typeHere' })}
                      defaultValue={api.company_name}
                      onChange={({ target }) => {
                        setApi({ ...api, company_name: target.value });
                      }}
                    />
                  </Form.Item>
                  <Form.Item label={t({ id: 'profile.companyAddress' })} {...validate(errorsSubmit, 'address')}>
                    <Input
                      type="text"
                      placeholder={t({ id: 'other.typeHere' })}
                      defaultValue={api.address}
                      onChange={({ target }) => {
                        setApi({ ...api, address: target.value });
                      }}
                    />
                  </Form.Item>
                </Form.Half>
              ) : null}

              <Form.Half>
                <Form.Item label={t({ id: 'profile.name' })} {...validate(errorsSubmit, 'first_name')}>
                  <Input
                    type="text"
                    placeholder={t({ id: 'other.typeHere' })}
                    defaultValue={api.first_name}
                    onChange={({ target }) => {
                      setApi({ ...api, first_name: target.value });
                    }}
                  />
                </Form.Item>

                <Form.Item label={t({ id: 'profile.lastName' })} {...validate(errorsSubmit, 'last_name')}>
                  <Input
                    type="text"
                    placeholder={t({ id: 'other.typeHere' })}
                    defaultValue={api.last_name}
                    onChange={({ target }) => {
                      setApi({ ...api, last_name: target.value });
                    }}
                  />
                </Form.Item>
              </Form.Half>

              <Form.Half>
                <Form.Item label={t({ id: 'profile.serviceEmail' })} {...validate(errorsSubmit, 'email')}>
                  <Input
                    disabled
                    type="text"
                    placeholder={t({ id: 'other.typeHere' })}
                    defaultValue={api.email}
                    onChange={({ target }) => {
                      setApi({ ...api, email: target.value });
                    }}
                  />
                </Form.Item>

                <Form.Item label={t({ id: 'profile.serviceTelephone' })} {...validate(errorsSubmit, 'phone')}>
                  <Input
                    type="text"
                    disabled={!api.is_staff}
                    placeholder={t({ id: 'other.typeHere' })}
                    defaultValue={api.phone}
                    onChange={({ target }) => {
                      setApi({ ...api, phone: target.value });
                    }}
                  />
                </Form.Item>
              </Form.Half>

              <Form.Half>
                <Form.Item label={t({ id: 'profile.level' })} {...validate(errorsSubmit, 'seniority_id')}>
                  <Select
                    showSearch
                    disabled={!api.is_staff}
                    placeholder={t({ id: 'profile.selectLevel' })}
                    optionFilterProp="children"
                    value={api.seniority_id}
                    onChange={value => {
                      setApi({ ...api, seniority_id: value });
                    }}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataSeniorities.map(({ id, name }) => (
                      <Select.Option key={id} value={id}>
                        {name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item label={t({ id: 'profile.job' })} {...validate(errorsSubmit, 'job_title')}>
                  <Input
                    type="text"
                    disabled={!api.is_staff}
                    placeholder={t({ id: 'other.typeHere' })}
                    defaultValue={api.job_title}
                    onChange={({ target }) => {
                      setApi({ ...api, job_title: target.value });
                    }}
                  />
                </Form.Item>
              </Form.Half>

              <Form.Half>
                <div className="password-seaction">
                  <div className="title">{t('profile.passwordTitle')}</div>

                  <div>
                    <Form.Item
                      label={t('profile.currentPassword')}
                      {...validate(errorsSubmit, 'password')}
                      {...(errorPassword ? { validateStatus: 'error', help: errorPassword } : null)}
                    >
                      <InputPassword
                        type="password"
                        placeholder={t({ id: 'profile.currentPassword' })}
                        onChange={({ target }) => {
                          setApi({ ...api, old_password: target.value });
                        }}
                      />
                    </Form.Item>

                    <Form.Item label={t('profile.newPassword')} {...validate(errorsSubmit, 'new_password')}>
                      <InputPassword
                        type="password"
                        placeholder={t({ id: 'profile.newPassword' })}
                        onChange={({ target }) => {
                          setApi({ ...api, new_password: target.value });
                        }}
                      />
                    </Form.Item>
                  </div>
                </div>
              </Form.Half>

              <Form.Half>
                <div className="form-footer">
                  <Button type="primary" onClick={() => onSubmit()} loading={loadingSubmit}>
                    {t({ id: 'other.submit' })}
                  </Button>
                </div>
              </Form.Half>
            </>
          </Form>
        </Section>
      </div>
    </ProfileLayout>
  );
};

export default MyProfilePage;
