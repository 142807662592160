import * as React from 'react';
import { Link } from '~/libs/router';
import { Icon } from '~/components/ui/Icon/Icon';

import '~/assets/scss/components/ui/_Pagination.scss';

const Pagination = ({ onChange, page: $page = 1, total = 0, perPage = 1, route, params }) => {
  const onClickHandler = newPage => {
    if (onChange !== undefined && newPage !== undefined) {
      onChange(newPage);
    }
  };

  const page = React.useMemo(() => Number($page) || 1, [$page]);
  const pages = React.useMemo(() => Math.ceil(total / perPage), [total, perPage]);

  const dataSource = React.useMemo(() => {
    const keys = Array.from(Array(pages).keys())
      .map(i => i + 1)
      .reduce((acc, current) => [...acc, { isPage: true, label: current, value: current }], []);

    const pageIndex = page - 1;
    const rangeAfter = keys.slice(pageIndex, pageIndex + 3);
    const rangeBefore = keys.slice(pageIndex > 2 ? pageIndex - 2 : 0, pageIndex);
    const lastThree = keys.slice(keys.length - 2, keys.length);

    const newDataSource = [...rangeBefore, ...rangeAfter];

    // get last 3 pages if there're no in range after
    if (
      lastThree.length > 0 &&
      rangeAfter.length > 0 &&
      !lastThree.map(i => i.value).includes(rangeAfter[rangeAfter.length - 1].value)
    ) {
      if (lastThree[0].value !== rangeAfter[rangeAfter.length - 1].value) {
        newDataSource.push({ isPage: false, label: '...' });
      }

      newDataSource.push(...lastThree);
    }

    // add first page if it's not in range before
    if (newDataSource.length > 0 && newDataSource[0].value !== 1) {
      newDataSource.unshift(
        { isPage: true, label: 1, value: 1 },
        {
          isPage: false,
          label: '...',
        },
      );
    }

    return newDataSource;
  }, [page, pages]);

  if (pages === 1) {
    return null;
  }

  return (
    <div className="pagination flex flex-between">
      {pages > 0 && (
        <div className="pages-wrapper">
          {!route ? (
            <Link
              disabled={page === 1}
              route={route}
              params={{ ...params, page: page - 1 }}
              onClick={page !== 1 ? () => onClickHandler(page - 1) : undefined}
            >
              <Icon type="left-row" className={page === 1 ? 'disabled' : ''} />
            </Link>
          ) : (
            <span onClick={page !== 1 ? () => onClickHandler(page - 1) : undefined}>
              <Icon type="left-row" className={page === 1 ? 'disabled' : ''} />
            </span>
          )}

          {dataSource.map(item =>
            item.isPage && item.value !== undefined ? (
              route && params ? (
                <Link
                  key={item.value}
                  disabled={item.disabled}
                  route={route}
                  params={{ ...params, page: item.value }}
                  className={`button ${page === item.value ? 'selected' : ''}`}
                  onClick={onChange && (() => onClickHandler(item.value))}
                >
                  {item.label}
                </Link>
              ) : (
                <span
                  key={item.value}
                  className={`button ${page === item.value ? 'selected' : ''}`}
                  onClick={() => onClickHandler(item.value)}
                >
                  {item.label}
                </span>
              )
            ) : (
              <span key={item.value}>{item.label}</span>
            ),
          )}

          {!route ? (
            <Link
              disabled={page === pages}
              route={route}
              params={{ ...params, page: page + 1 }}
              onClick={page !== pages ? () => onClickHandler(page + 1) : undefined}
            >
              <Icon
                type="right-row"
                className={page === pages ? 'disabled' : ''}
                onClick={page !== pages ? () => onClickHandler(page + 1) : undefined}
              />
            </Link>
          ) : (
            <span onClick={page !== pages ? () => onClickHandler(page + 1) : undefined}>
              <Icon
                type="right-row"
                className={page === pages ? 'disabled' : ''}
                onClick={page !== pages ? () => onClickHandler(page + 1) : undefined}
              />
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default Pagination;
