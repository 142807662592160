import axios from '~/libs/http/axios';

const { baseURL } = axios.defaults;

const lists = {
  add: (option = {}) => axios.post(`${baseURL}/api/lists/all_views`, option),
  search: (params = {}) => axios.get(`${baseURL}/api/lists/search`, { params }),
  searchCompanies: (params = {}) => axios.post(`${baseURL}/api/companies/lists`, { params }),
  delete: (params = {}) => axios.delete(`${baseURL}/api/lists/all_views`, { params }),
  duplicate: (params = {}) => axios.get(`${baseURL}/api/lists/duplicate`, { params }),
  edit: (option = {}) => axios.patch(`${baseURL}/api/lists/all_views`, option),
  get: (params = {}) => axios.get(`${baseURL}/api/lists/all_views`, { params }),
  list: (params = {}) => axios.get(`${baseURL}/api/lists/list`, { params }),
  getList: (params = {}) => axios.get(`${baseURL}/api/lists/get_list`, { params }),
  getListLimits: (params = {}) => axios.get(`${baseURL}/api/lists/get_limit`, { params }),
};

export default lists;
