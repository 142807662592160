import React, { useMemo, useState, useEffect } from 'react';
import { Timeline } from 'antd';
import { useDocumentTitle } from '~/hooks';
import { has } from '~/libs/object';
import { useInjectIntl } from '~/libs/localization';
import { indexed } from '~/libs/array';
import { commaThousands, spaceThousands } from '~/libs/number';
import { useCompany } from '~/hoc/withCompany';
import { CompanyLayout } from '~/components/layouts';
import { Loader, Ratio, Empty, Rows, Table } from '~/components/ui';
import { Segment, Chart, Info } from '~/components/company';

const EconomyPage = props => {
  const { t } = useInjectIntl();
  useDocumentTitle(t('page.economy'));
  const [financial, setFinancial] = useState([]);
  const { company: { data: api, loading } } = useCompany(); // prettier-ignore

  useEffect(() => {
    const onSetFinancialData = () => {
      const copies = {
        income_sales: t({ id: 'company.incomeSales' }),
        income_net: t({ id: 'company.incomeNet' }),
        total_active_circ: t({ id: 'company.totalActiveCirc' }),
        total_active_imobil: t({ id: 'company.totalActiveImobil' }),
        total_capital: t({ id: 'company.totalCapital' }),
        total_current_liabil: t({ id: 'company.totalCurrentLiabil' }),
        total_long_liabil: t({ id: 'company.totalLongLiabil' }),
      };

      const $data = {};
      has(api, 'financial_data.financial', []).forEach(item => {
        Object.keys(item).forEach(key => {
          if (key !== 'year') {
            $data[key] = {
              ...$data[key],
              title: copies[key],
              [`year_${item.year}`]: item[key],
            };
          }
        });
      });

      const $financial = [];
      Object.keys($data).map(key => $financial.push({ id: key, ...$data[key] }));

      setFinancial($financial);
    };

    onSetFinancialData();
  }, [api]);

  const columns = useMemo(
    () =>
      financial.length > 0
        ? Object.keys(financial[0])
            .filter(key => !['key', 'id'].includes(key))
            .map(key => {
              const isYear = key.match('year_');

              return {
                title: isYear ? key.replace('year_', '') : '',
                className: isYear ? 'center aligned' : '',
                dataIndex: key,
                render: text => <b>{(text && spaceThousands(text)) || <Empty />}</b>,
              };
            })
        : [],
    [financial],
  );

  return (
    <CompanyLayout>
      <Loader loading={loading}>
        <Rows
          rows={[
            {
              label: t({ id: 'company.statusCompany' }),
              value: has(api, 'status.title', '---'),
            },
            {
              label: t({ id: 'company.tva' }),
              value: has(api, 'financial_data.tva')
                ? `${t({ id: 'other.yes' })} (${has(api, 'financial_data.tva_number')})`
                : t({ id: 'other.no' }),
            },
            {
              label: t({ id: 'company.payer' }),
              value: has(api, 'financial_data.excise') ? t({ id: 'other.yes' }) : t({ id: 'other.no' }),
            },
            {
              label: t({ id: 'company.importExport' }),
              value: '---',
            },
            {
              label: t({ id: 'company.capitalSize' }),
              value: has(api, 'api.general_data.social_capital.value')
                ? `${api.general_data.social_capital.value.toLocaleString()} MDL`
                : '---',
            },
          ]}
        />

        {has(api, 'financial_data.intellectual_brands', []).length > 0 ||
        has(api, 'financial_data.used_brands', []).length > 0 ? (
          <Segment title={t({ id: 'company.infoIntelectual' })} textAlign="center" noPadding classContent="flexible">
            {has(api, 'financial_data.intellectual_brands', []).length > 0 ? (
              <Segment.Row>
                {indexed(api.financial_data.intellectual_brands).map(row => (
                  <Info.Halves key={row.key}>
                    <Info label={t({ id: 'company.intelectualObjects' })}>
                      <Info.Detail title={has(row, 'type_name', <Empty />)} />
                    </Info>

                    <Info label={t({ id: 'company.intelectualDescription' })}>
                      <Info.Detail title={has(row, 'title', <Empty />)} textBlue />
                    </Info>
                  </Info.Halves>
                ))}
              </Segment.Row>
            ) : null}

            <Segment.Row>
              <Info.Halves>
                <Info label={t({ id: 'company.brandsUsed' })}>
                  <Info.Detail
                    title={
                      has(api, 'financial_data.used_brands', []).length > 0
                        ? t({ id: 'other.yes' })
                        : t({ id: 'other.no' })
                    }
                  />
                </Info>

                <Info label={t({ id: 'company.brandsDesc' })}>
                  <Info.Detail title={has(api, 'financial_data.used_brands', []).join(', ') || undefined} textBlue />
                </Info>
              </Info.Halves>
            </Segment.Row>
          </Segment>
        ) : null}

        {has(api, 'financial_data.partners', []).length > 0 || has(api, 'financial_data.banks', []).length > 0 ? (
          <Segment title={t({ id: 'company.partnersInfo' })} textAlign="center" noPadding classContent="flexible">
            {has(api, 'financial_data.partners', []).length > 0 ? (
              <Segment.Row gray>
                <Info label={t({ id: 'company.clientsParents' })}>
                  {api.financial_data.partners.map(title => (
                    <Info.Detail key={title} title={title} textBlue />
                  ))}
                </Info>
              </Segment.Row>
            ) : null}

            {has(api, 'financial_data.banks', []).length > 0 ? (
              <Segment.Row gray>
                <Info label={t({ id: 'company.bankService' })}>
                  {api.financial_data.banks.map(title => (
                    <Info.Detail key={title} title={title} textBlue />
                  ))}
                </Info>
              </Segment.Row>
            ) : null}
          </Segment>
        ) : null}

        {has(api, 'general_data') && (
          <Segment.Halves>
            <Segment title={t({ id: 'company.turnover' })} classContent="relative">
              {has(api, 'general_data.turnover.last', '') ? (
                <p className="chart-info">{commaThousands(api.general_data.turnover.last)} MDL</p>
              ) : (
                <div className="chart-empty">
                  <span>{t({ id: 'company.empty' })}</span>
                </div>
              )}

              <Chart data={api.general_data.turnover.graph} />
            </Segment>

            {has(api, 'general_data.types_of_activity.activity_unlicensed', []).length > 0 ||
            has(api, 'general_data.types_of_activity.activity_licensed', []).length > 0 ? (
              <Segment
                classContent="container-flex timeline-chart"
                title={t({ id: 'company.productsServicesCompany' })}
                noPadding
                greyContent
              >
                {has(api, 'general_data.types_of_activity.activity_unlicensed', []).length > 0 ? (
                  <Segment.Section title={t({ id: 'company.unlic' })}>
                    <Timeline>
                      {api.general_data.types_of_activity.activity_unlicensed.map(row => (
                        <Timeline.Item key={row.title.id}>{has(row, 'title.title', <Empty />)}</Timeline.Item>
                      ))}
                    </Timeline>
                  </Segment.Section>
                ) : null}

                {has(api, 'general_data.types_of_activity.activity_licensed', []).length > 0 ? (
                  <Segment.Section title={t({ id: 'company.lic' })}>
                    <Timeline>
                      {api.general_data.types_of_activity.activity_licensed.map(row => (
                        <Timeline.Item key={row.title.id}>{has(row, 'title.title', <Empty />)}</Timeline.Item>
                      ))}
                    </Timeline>
                  </Segment.Section>
                ) : null}
              </Segment>
            ) : null}
          </Segment.Halves>
        )}

        {financial.length > 0 && (
          <Segment title={t({ id: 'company.financialDate' })} noPadding>
            <Table
              rowKey="id"
              columns={[
                ...columns,
                {
                  title: `${financial.length} - ${t({ id: 'other.year' })}`,
                  className: 'collapsing center aligned',
                  render: (text, record, n) => (
                    <Ratio
                      scores={Object.keys(record)
                        .filter(key => key.match('year_'))
                        .map(key => record[key])}
                    />
                  ),
                },
              ]}
              dataSource={financial}
            />
          </Segment>
        )}
      </Loader>
    </CompanyLayout>
  );
};

export default EconomyPage;
