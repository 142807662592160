import React from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useInjectIntl } from '~/libs/localization';
import { getRoute, isRouteActive } from '~/libs/router';
import { Button } from '~/components/ui';

import './ButtonsGroup.scss';

const ButtonsGroup = ({ type = '' }) => {
  const { t } = useInjectIntl();
  const match = useRouteMatch();
  const history = useHistory();

  const isActive = (matchParams, route) => (isRouteActive(matchParams, route) ? 'active' : '');

  if (!type || type === 'prospect') {
    return null;
  }

  return (
    ['download', 'saved'].includes(type) && (
      <div className="navigation-block-buttons">
        <Button
          className={isActive(match, 'SearchResultsPage') ? 'button-active' : ''}
          onClick={() => history.push(getRoute('SearchResultsPage', { type: 'company', mode: 'basic' }))}
        >
          {t('searches.newSearch')}
        </Button>

        <Button
          className={isActive(match, 'SavedListsPage') ? 'button-active' : ''}
          onClick={() => history.push(getRoute('SavedListsPage', { page: 1 }))}
        >
          {t('searches.myLists')}
        </Button>

        <Button
          className={isActive(match, 'SavedSearchesPage') ? 'button-active' : ''}
          onClick={() => history.push(getRoute('SavedSearchesPage', { page: 1 }))}
        >
          {t('searches.mySearches')}
        </Button>

        <Button
          className={isActive(match, 'DownloadsPage') ? 'button-active' : ''}
          onClick={() => history.push(getRoute('DownloadsPage', { type: 'list', page: 1 }))}
        >
          {t('searches.myDownloads')}
        </Button>
      </div>
    )
  );
};

export default ButtonsGroup;
