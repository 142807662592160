import React from 'react';
import { Modal, Checkbox, Button } from 'antd';
import { useInjectIntl } from '~/libs/localization';
import { Icon } from '../ui/Icon/Icon';

import '~/assets/scss/components/_ModalAddCompanyAnotherList.scss';

const ModalAddCompanyAnotherList = props => {
  const { t } = useInjectIntl();

  const onChangeCheckbox = () => {
    console.log('onChangeCheckbox');
  };

  return (
    <Modal
      title={t('monitorig.addCounterpartList')}
      visible={props.isModalVisible}
      className="modal-another-list"
      width={800}
      footer={null}
      onCancel={props.onClose}
    >
      <div className="section-wrap companies-list zh-checkbox-scroll">
        <ul>
          <li className="pink">
            <span className="list-company-wrap">
              <Icon type="company-list" />
            </span>

            <div className="company-info">
              <div className="name">Example.txt</div>
              <div className="meta-info">
                26 Companii <span className="dot">•</span> Creat 18.12.2021
              </div>
              <span className="checkbox-wrap">
                <Checkbox onChange={onChangeCheckbox} />
              </span>
            </div>
          </li>

          <li className="violet active">
            <span className="list-company-wrap">
              <Icon type="company-list" />
            </span>

            <div className="company-info">
              <div className="name">Example.txt</div>
              <div className="meta-info">
                26 Companii <span className="dot">•</span> Creat 18.12.2021
              </div>
              <span className="checkbox-wrap">
                <Checkbox onChange={onChangeCheckbox} checked />
              </span>
            </div>
          </li>

          <li className="red">
            <span className="list-company-wrap">
              <Icon type="company-list" />
            </span>

            <div className="company-info">
              <div className="name">Example.txt</div>
              <div className="meta-info">
                26 Companii <span className="dot">•</span> Creat 18.12.2021
              </div>
              <span className="checkbox-wrap">
                <Checkbox onChange={onChangeCheckbox} />
              </span>
            </div>
          </li>

          <li className="aqua">
            <span className="list-company-wrap">
              <Icon type="company-list" />
            </span>

            <div className="company-info">
              <div className="name">Example.txt</div>
              <div className="meta-info">
                26 Companii <span className="dot">•</span> Creat 18.12.2021
              </div>
              <span className="checkbox-wrap">
                <Checkbox onChange={onChangeCheckbox} />
              </span>
            </div>
          </li>

          <li className="purple">
            <span className="list-company-wrap">
              <Icon type="company-list" />
            </span>

            <div className="company-info">
              <div className="name">Example.txt</div>
              <div className="meta-info">
                26 Companii <span className="dot">•</span> Creat 18.12.2021
              </div>
              <span className="checkbox-wrap">
                <Checkbox onChange={onChangeCheckbox} />
              </span>
            </div>
          </li>

          <li className="purple">
            <span className="list-company-wrap">
              <Icon type="company-list" />
            </span>

            <div className="company-info">
              <div className="name">Example.txt</div>
              <div className="meta-info">
                26 Companii <span className="dot">•</span> Creat 18.12.2021
              </div>
              <span className="checkbox-wrap">
                <Checkbox onChange={onChangeCheckbox} />
              </span>
            </div>
          </li>

          <li className="purple">
            <span className="list-company-wrap">
              <Icon type="company-list" />
            </span>

            <div className="company-info">
              <div className="name">Example.txt</div>
              <div className="meta-info">
                26 Companii <span className="dot">•</span> Creat 18.12.2021
              </div>
              <span className="checkbox-wrap">
                <Checkbox onChange={onChangeCheckbox} />
              </span>
            </div>
          </li>

          <li className="purple">
            <span className="list-company-wrap">
              <Icon type="company-list" />
            </span>

            <div className="company-info">
              <div className="name">Example.txt</div>
              <div className="meta-info">
                26 Companii <span className="dot">•</span> Creat 18.12.2021
              </div>
              <span className="checkbox-wrap">
                <Checkbox onChange={onChangeCheckbox} />
              </span>
            </div>
          </li>

          <li className="purple">
            <span className="list-company-wrap">
              <Icon type="company-list" />
            </span>

            <div className="company-info">
              <div className="name">Example.txt</div>
              <div className="meta-info">
                26 Companii <span className="dot">•</span> Creat 18.12.2021
              </div>
              <span className="checkbox-wrap">
                <Checkbox onChange={onChangeCheckbox} />
              </span>
            </div>
          </li>

          <li className="purple">
            <span className="list-company-wrap">
              <Icon type="company-list" />
            </span>

            <div className="company-info">
              <div className="name">Example.txt</div>
              <div className="meta-info">
                26 Companii <span className="dot">•</span> Creat 18.12.2021
              </div>
              <span className="checkbox-wrap">
                <Checkbox onChange={onChangeCheckbox} />
              </span>
            </div>
          </li>
        </ul>
      </div>

      <div className="modal-footer">
        <div className="left">
          <Button className="add-list-button">
            <Icon type="list-plus" />
            Adaugă listă
          </Button>
        </div>

        <div className="right">
          <Button className="place-list-button">Plasați în această listă</Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalAddCompanyAnotherList;
