import React, { Fragment, useEffect, useCallback } from 'react';
import { useRequest } from 'estafette';
import { Input, Popover, Row, Col } from 'antd';
import { useRouteMatch, useHistory } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { setActiveFilter } from '~/store/actions/FilterActions';
import { useDocumentTitle, useStateHandlers } from '~/hooks';
import responsive from '~/libs/responsive';
import { lists as listsApi } from '~/libs/http/api';
import { useInjectIntl } from '~/libs/localization';
import { getRoute, Link } from '~/libs/router';
import { has } from '~/libs/object';
import { ExportModal } from '~/components/modal/export';
import { EditList, DublicateList } from '~/components/modal/lists';
import { Time } from '~/components/other';
import { TableFooter } from '~/components/table';
import { PopAction } from '~/components/profile';
import { Layout } from '~/components/layouts';
import { FeedbackModal } from '~/components/modal';
import { Segment, Pagination, Notification, Loader, EmptyBlock, Text, SeparatedList } from '~/components/ui';
import { SaveListEdit } from '~/components/lists';
import { Icon } from '~/components/ui/Icon/Icon';

import '~/assets/scss/components/_SavedListsPage.scss';

const SavedListsPage = () => {
  const { t } = useInjectIntl();
  useDocumentTitle(t('page.savedList'));
  const match = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const { request, data, loading } = useRequest({ data: { data: [] } });
  const { request: requestDelete, loading: loadingDelete } = useRequest();
  const store = useSelector(
    ({ authentication, filter }) => ({ permissions: authentication.permissions, employeesKeys: filter.employeesKeys }),
    shallowEqual,
  );
  const [state, setState] = useStateHandlers({
    key: 0,
    update: null,
    edit: false,
    dublicate: false,
    delete: false,
    perPage: 25,
    search: '',
    lists: [],
    route: '',
    scenario: false,
    selected: {},
    activeExport: null,
    loadingFeedback: false,
    errorsFeedback: {},
  });

  useEffect(() => {
    onFetch();
  }, [state.search, match.params]);

  const onSearch = search => setState({ search });

  const onFetch = page => {
    const { params, url } = match;
    const route = getRoute('SavedListsPage', params) === url ? 'SavedListsPage' : 'SavedSearchesPage';

    setState({ route });

    const isSearch = route === 'SavedSearchesPage';

    return request(
      listsApi[isSearch ? 'search' : 'list']({
        page: match.params.page,
        per_page: state.perPage,
        ...(state.search ? { search: state.search } : {}),
      }),
    );
  };

  const onModify = useCallback((key = null) => setState({ update: key }), []);

  const onOpenItem = item => {
    if (match.path === getRoute('SavedSearchesPage')) {
      return onOpenSearch(item);
    }

    return onOpenList(item);
  };

  const onOpenList = ({ id, type }) => history.push(getRoute('SearchResultsPage', { type, mode: id }));

  const onOpenSearch = ({ id, filters = {} }) => {
    dispatch(setActiveFilter(filters.must || {}));

    const type =
      Object.keys(filters.must || {}).filter(filter => store.employeesKeys.includes(filter)).length > 0
        ? 'employee'
        : 'company';

    history.push(getRoute('SearchResultsPage', { type, mode: 'basic' }));
  };

  const onSave = async selected => {
    if (match.path === getRoute('SavedSearchesPage')) {
      dispatch(setActiveFilter(selected.filters.must || {}));
    } else {
      dispatch(setActiveFilter({ list_id: selected.id }));
    }

    setState({ scenario: true, selected });
  };

  const onCloseDublicate = () => {
    setState({ dublicate: false, key: 0 });

    onFetch();
  };

  const onCloseScenario = () => {
    setState({ scenario: false, selected: {} });

    dispatch(setActiveFilter({}));
  };

  const onDelete = async id => {
    await requestDelete(listsApi.delete({ id }));

    if (Number(match.params.page) === 1) {
      onFetch();
    } else {
      onChangePage(1);
    }
  };

  const onCloseEdit = () => setState({ edit: false });
  const onFeedback = activeExport => setState({ feedback: true, activeExport });
  const onCloseFeedback = () => setState({ feedback: false });
  const onChangePage = page => history.push(getRoute(state.route, { page }));

  return (
    <>
      {state.edit && <EditList onClose={onCloseEdit} />}

      {state.dublicate && <DublicateList onClose={onCloseDublicate} id={data.data[state.key].id} />}

      {state.feedback && (
        <FeedbackModal
          loading={state.loadingfeedback}
          errors={state.errorsFeedback}
          onClose={onCloseFeedback}
          activeExport={state.activeExport}
        />
      )}

      {state.scenario && Object.keys(state.selected).length > 0 && (
        <ExportModal
          onClose={onCloseScenario}
          provider={
            state.selected.type ||
            Object.keys(has(state.selected, 'filters.must', {})).filter(filter => store.employeesKeys.includes(filter))
              .length > 0
              ? 'employee'
              : 'company'
          }
          name={state.selected.name ? `Exportul pentru ${state.selected.name}` : ''}
        />
      )}

      <Layout className="saved-list-container" type="saved">
        {!loading && data.data.length === 0 && <EmptyBlock title={<Text id="list.empty" />} />}

        {data.data.length > 0 && (
          <Loader loading={loading}>
            <Segment
              leftSide={<h1>{t(state.route === 'SavedSearchesPage' ? 'menu.my-searches' : 'menu.saved-lists')}</h1>}
              rightSide={<Input.Search placeholder={t('searches.search')} enterButton onSearch={onSearch} />}
            />

            <div className="table-clean table-no-header table-saved-lists hover">
              <div className="saved-list-wrapper">
                {data.data.map((item, key) => (
                  <Fragment key={item.id}>
                    <Row className="saved-list-wrapper-item">
                      <Col span={18} className="in-two-rows cursor" onClick={() => onOpenItem(item)}>
                        <div collapsing="true" className="saved-list-wrapper-name">
                          {state.route === 'SavedSearchesPage' ? (
                            <Icon type="search" />
                          ) : (
                            <Icon type={item.type === 'company' ? 'suitcase' : ''} />
                          )}
                        </div>

                        <div className="upper_title">
                          <span>{item.name}</span>

                          <small>
                            <SeparatedList
                              data={[
                                item.companies && `${item.companies || item.companies_count} ${t('list.companies')}`,
                                item.employees && `${item.employees} ${t('company.employee2')}`,
                                <Time date={item.date_created} format="long" />,
                              ]}
                            />
                          </small>
                        </div>
                      </Col>

                      <MediaQuery minWidth={responsive.desktop}>
                        <Col span={4} collapsing>
                          {state.route !== 'SavedSearchesPage' && store.permissions.includes('alerts') && (
                            <Notification id={item.id} />
                          )}
                        </Col>

                        <Col span={4} collapsing className="settings">
                          {state.route !== 'SavedSearchesPage' && (
                            <div className="action-modification" onClick={() => onModify(key)}>
                              <Icon type="menu-filter-industry" />
                              {t('other.modify')}
                            </div>
                          )}
                        </Col>
                      </MediaQuery>

                      <div collapsing className="wrap-popover">
                        <Popover
                          content={[
                            <PopAction
                              key="1"
                              prefix={<Icon type="download" />}
                              value={<Text id="other.toExport" />}
                              onClick={() => onSave(item)}
                            />,

                            <PopAction
                              key="2"
                              prefix={<Icon type="dublicate" />}
                              value={<Text id="list.dublication" />}
                              onClick={() => setState({ dublicate: true, key })}
                            />,

                            <PopAction
                              key="3"
                              prefix={<Icon type="filter-feedback" />}
                              value={<Text id="other.feedback" />}
                              onClick={() => onFeedback(item.id)}
                            />,

                            <PopAction
                              loading={loadingDelete}
                              key="4"
                              icon="delete"
                              value={<Text id="other.delete" />}
                              type="delete"
                              onClick={() => onDelete(item.id)}
                            />,

                            <MediaQuery maxWidth={responsive.desktop} key="5">
                              {state.route !== 'SavedSearchesPage' && store.permissions.includes('alerts') && (
                                <PopAction
                                  icon="notification"
                                  value={
                                    <Link route="AlertsListsPage" params={{ page: 1, id: item.id }}>
                                      {t({ id: 'notification' })}
                                    </Link>
                                  }
                                />
                              )}

                              {state.route !== 'SavedSearchesPage' && store.permissions.includes('alerts') && (
                                <PopAction
                                  icon="setting"
                                  value={t({ id: 'other.modify' })}
                                  onClick={() => onModify(key)}
                                />
                              )}
                            </MediaQuery>,
                          ]}
                          trigger="click"
                        >
                          <Icon type="ellipsis" className="btn-ellipsis" />
                        </Popover>
                      </div>
                    </Row>

                    {state.update === key && (
                      <div key={item.id} className={state.update === key ? 'editing' : ''}>
                        <div colSpan="5">
                          <SaveListEdit item={item} lists={state.lists} refetch={onFetch} onClose={() => onModify()} />
                        </div>
                      </div>
                    )}
                  </Fragment>
                ))}
              </div>
            </div>
            <TableFooter
              rightSide={
                <Pagination onClick={onChangePage} page={match.params.page} total={data.pages} route={state.route} />
              }
            />
          </Loader>
        )}
      </Layout>
    </>
  );
};

export default SavedListsPage;
