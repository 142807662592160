import { message } from 'antd';

import { company } from '~/libs/http/api';

export const search = filterOptions => async dispatch => {
  const restOptions = { ...filterOptions.filter };
  if (restOptions.company_has !== undefined) {
    delete restOptions.company_has;
  }

  const options = {
    filter: {
      [filterOptions.companyHasCriterion ? 'company_has_one' : 'company_has']: filterOptions.filter.company_has,
      ...restOptions,
    },
    page: filterOptions.page,
    type: filterOptions.type,
  };

  try {
    const { data } = await company[options.type === 'company' ? 'list' : 'employees']({
      page: options.page,
      filters: {
        // Only for list history info
        // must: options.filter.filters?.must || {},
        must: options.filter,
      },
    });

    dispatch({
      type: 'GET_SEARCH',
      payload: {
        page: options.page,
        total: data.total_results,
        data: data.data,
        last: !data.data.length,
      },
    });

    return Promise.resolve(data);
  } catch (error) {
    message.error("We're sorry! The companies/employees are not found by your request");

    return Promise.reject(error);
  }
};

export const setSearcherLoading = payload => ({
  type: 'SET_SEARCHER_LOADING',
  payload,
});

export const setSearchType = payload => ({
  type: 'SET_SEARCH_TYPE',
  payload,
});

export const invertSelections = () => ({
  type: 'INVERT_SELECTIONS',
});

export const selectSearch = payload => ({
  type: 'SELECT_SEARCH',
  payload,
});

export const selectAllSearch = () => ({
  type: 'SELECT_ALL_SEARCH',
});

export const resetSelectSearch = () => ({
  type: 'RESET_SELECT_SEARCH',
});
