import React from 'react';

const Truncate = ({ children, title, className, width, maxWidth }) => (
  <div className="truncated-parent" style={{ width, maxWidth }}>
    <div className={`truncated ${className || ''}`} title={title}>
      {children}
    </div>
  </div>
);

export default Truncate;
