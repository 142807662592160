import React, { useState, useCallback } from 'react';
import { Popover, Tooltip } from 'antd';
import { useInjectIntl } from '~/libs/localization';
import { Button } from '~/components/ui';
import { useHints } from '~/hooks';
import { Icon } from '~/components/ui/Icon/Icon';

import '~/assets/scss/components/ui/_FilterActions.scss';

// type: alert warning error
const Hint = ({ fixed, type = 'hint-icons-alert', value }) => (
  <Tooltip title={<span>{value}</span>} overlayClassName={`zh-tooltip-blue ${fixed ? 'zh-tooltip-fixed' : ''}`}>
    <Icon type={type} />
  </Tooltip>
);

export const FilterActions = ({ children, type, fixed, checking, ...props }) => {
  const { t } = useInjectIntl();
  const [show, setShow] = useState(false);
  const { errors } = useHints({ type });

  const action = useCallback(fn => {
    setShow(false);
    fn();
  }, []);

  const ActionItem = ({ children: title, disabled, onClick, className = '', hintType, hint }) =>
    !disabled ? (
      <span onClick={() => action(onClick)} className={`action ${className}`}>
        {title}

        {hint && <Hint fixed={fixed} type={hintType} value={hint} />}
      </span>
    ) : (
      <span className="action action-disabled">
        {title}

        {hint && <Hint fixed={fixed} type={hintType} value={hint} />}
      </span>
    );

  return (
    <Popover
      content={
        <div data-testid="filter-actions" className="zh-filter-actions">
          {props.onSaveList && (
            <ActionItem
              onClick={props.onSaveList}
              // disabled={!props.companyPage && errors.save_lists.message}
              disabled={!checking.includes('saved_list') && errors.save_lists.message}
              hint={!props.companyPage && errors.save_lists.message}
            >
              <Icon type="filter-icon-list" />

              {t('other.addInList')}
            </ActionItem>
          )}

          {props.onDownload && (
            <ActionItem
              onClick={props.onDownload}
              // disabled={errors.export.message}
              disabled={!checking.includes('export') && errors.export.message}
              hint={errors.export.message}
            >
              <Icon type="filter-icon-export" />

              {t('other.download')}
            </ActionItem>
          )}

          {/* {props.onUpdateCompany && (
            <ActionItem
              onClick={props.onUpdateCompany}
              // disabled={errors.company_update.message || props.updateCompanyDisabled}
              disabled={
                !checking.includes('company_update') && (errors.company_update.message || props.updateCompanyDisabled)
              }
              hint={errors.company_update.message}
            >
              <Icon type="filter-update" />

              {t('other.update')}
            </ActionItem>
          )} */}

          {/* {props.onExportPdf && (
            <ActionItem onClick={props.onExportPdf}>
              <Icon type="filter-export_pdf" />
              Export as PDF
            </ActionItem>
          )} */}

          {props.onSaveSearch && (
            <ActionItem
              onClick={props.onSaveSearch}
              disabled={!checking.includes('saved_search') || errors.save_search.message}
              hint={errors.save_search.message}
            >
              <Icon type="save-search" />

              {t('other.saveSearch')}
            </ActionItem>
          )}
        </div>
      }
      trigger="click"
      placement="bottomRight"
      visible={show}
      onVisibleChange={setShow}
      overlayClassName={`${fixed ? 'zh-tooltip-fixed' : ''} zh-filter-actions-tooltip`}
    >
      <Button prefix={<Icon type="filter-list-plus" />} disabled={props.disabled}>
        {children}
      </Button>
    </Popover>
  );
};
