import * as React from 'react';
import NumberFormat from 'react-number-format';
import { Input, Form } from 'antd';
import { useRequest } from 'estafette';
import { useStateHandlers } from '~/hooks';
import { notification } from '~/libs/notification';
import { feedback } from '~/libs/http/api';
import { validate } from '~/libs/object';
import { Button } from '~/components/ui';
import { Icon } from '~/components/ui/Icon/Icon';
import { useInjectIntl } from '~/libs/localization';

import '~/assets/scss/components/ui/_PopoverSignUp.scss';

export const PopoverSignUp = ({ onClose, contactFormRef, ...props }) => {
  const { t } = useInjectIntl();
  const { request, errors, loading } = useRequest();

  const [state, setState] = useStateHandlers({
    name: '',
    email: '',
    phone: '',
    company_name: '',
    message: '',
  });

  const onSubmit = async () => {
    await request(
      feedback.support({
        name: state.name,
        email: state.email,
        phone: state.phone,
        company_name: state.company_name,
        message: state.message,
      }),
    );

    notification({
      title: t('other.feedbackSuccess'),
      message: t('other.success'),
    });

    onClose();
  };

  const onChangeName = ({ target }) => setState({ name: target.value });
  const onChangeEmail = ({ target }) => setState({ email: target.value });
  const onChangePhone = ({ target }) => setState({ phone: target.value });
  const onChangeCompanyName = ({ target }) => setState({ company_name: target.value });
  const onChangeMessage = ({ target }) => setState({ message: target.value });

  return (
    <Form name="feedback-messages" className="popover-sign-up">
      <div ref={contactFormRef} className="popover-sign-up__box-flex">
        <h2 className="popover-sign-up__title">{t('other.signUpSupport')}</h2>
        <Icon type="close" onClick={onClose} />
      </div>

      <Form.Item {...validate(errors, 'name')}>
        <Input size="large" placeholder={t('other.nameFeedback')} value={state.name} onChange={onChangeName} />
      </Form.Item>
      <Form.Item {...validate(errors, 'email')}>
        <Input size="large" placeholder={t('other.email-auth')} value={state.email} onChange={onChangeEmail} />
      </Form.Item>

      <Form.Item {...validate(errors, 'phone')}>
        <NumberFormat
          className="ant-input ant-input-lg "
          format="+373########"
          placeholder={t('other.phoneFeedback')}
          prefix="+373"
          onChange={onChangePhone}
        />
      </Form.Item>
      <Form.Item {...validate(errors, 'company_name')}>
        <Input
          size="large"
          placeholder={t('profile.companyName')}
          value={state.company_name}
          onChange={onChangeCompanyName}
        />
      </Form.Item>

      <Form.Item {...validate(errors, 'message')}>
        <Input.TextArea
          placeholder={t('other.messageFeedback')}
          autosize={{ minRows: 6, maxRows: 10 }}
          onChange={onChangeMessage}
        />
      </Form.Item>
      <div className="popover-sign-up__box-flex">
        <Button key="back" onClick={onClose}>
          {t('other.reset')}
        </Button>

        <Button key="submit" type="primary" loading={loading} onClick={onSubmit}>
          {t('other.contactUs')}
        </Button>
      </div>
    </Form>
  );
};
