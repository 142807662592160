import { adapterIntl } from '~/libs/localization/helpers';

const home = adapterIntl({
  'home.support_redirect': {
    ro: 'Scrie-ne mesaj direct',
    ru: 'Напишите нам сообщение',
    en: 'Write us a direct message',
  },
  'home.info_license': {
    ro: 'Informații despre licență',
    ru: 'Информация о лицензии',
    en: 'License information',
  },
  'home.header': { ro: 'Actualizări recente', ru: 'Последние обновления', en: 'Latest updates' },
  'menu.home': { ro: 'Acasă', ru: 'Главная', en: 'Home' },
  'menu.dashboard': { ro: 'Dashboard', ru: 'Панель пользователя', en: 'Dashboard' },
  'menu.find-companies': { ro: 'Căută companii', ru: 'Поиск компаний', en: 'Find companies' },
  'menu.marketing-tools': { ro: 'Instrumente de marketing', ru: 'Инструменты маркетинга', en: 'Marketing tools' },
  'menu.opportunities': { ro: 'Oportunități', ru: 'Возможности', en: 'Opportunities' },
  'menu.market-analytics': { ro: 'Analiza pieței', ru: 'Аналитика рынка', en: 'Market analytics' },
  'menu.links': { ro: 'Legaturi', ru: 'Ссылки', en: 'Links' },
  'menu.find': { ro: 'Căutare', ru: 'Поиск', en: 'Search' },
  'menu.messages': { ro: 'Mesaje', ru: 'Сообщения', en: 'Messages' },
  'menu.exploration': { ro: 'Prospectare', ru: 'Исследование', en: 'Exploration' },
  'menu.alerts': { ro: 'Alerte', ru: 'Оповещения', en: 'Alerts' },
  'menu.monitoring': { ro: 'Monitorizare', ru: 'Мониторинг', en: 'Monitoring' },
  'menu.legal_bulletin': { ro: 'Buletin Juridic', ru: 'Юридический бюллетень', en: 'Legal Bulletin' },
  'menu.management': { ro: 'Management', ru: 'Менеджмент', en: 'Management' },
  'menu.saved-lists': { ro: 'Liste salvate', ru: 'Мои списки', en: 'Saved lists' },
  'menu.my-searches': { ro: 'Căutările mele', ru: 'Мои поиски', en: 'My searches' },
  'menu.funding-tenders': { ro: 'Finanțări și Licitații', ru: 'Финансирование и Тендеры', en: 'Funding and Auctions' },
  'home.all': { ro: 'Toate', ru: 'Все', en: 'All' },
  'home.alerts': { ro: 'Alerte', ru: 'Оповещения', en: 'Alerts' },
  'home.alerts.action': { ro: 'alertă', ru: 'оповещение', en: 'alert' },
  'home.lists': { ro: 'Salvare listă', ru: 'Сохранить список', en: 'Save list' },
  'home.lists.action': { ro: 'a salvat lista', ru: 'сохранил список', en: 'saved list' },
  'home.exportsTitle': { ro: 'Exporturile', ru: 'Экспорты', en: 'Exports' },
  'home.lookings': { ro: 'Salvare căutare', ru: 'Сохранить поиск', en: 'Save search' },
  'home.saved-search': { ro: 'Căutare companie', ru: 'Поиск компании', en: 'Company search' },
  'home.monitoring-lists': { ro: 'Monitorizare', ru: 'Отслеживание', en: 'Tracking' },
  'home.lookings.action': { ro: 'a salvat căutarea', ru: 'сохранил поиск', en: 'saved search' },
  'home.saved-search.action': { ro: 'Căutare', ru: 'Поиск', en: 'Search' },
  'home.exports': {
    ro: 'Export date',
    ru: 'Экпорт данных',
    en: 'Export data',
  },
  'home.exports.action': {
    ro: 'export PDF profil',
    ru: 'экспорт PDF профиль компании',
    en: 'exported PDF company profile',
  },
  'home.recents': { ro: 'Recente', ru: 'Предыдущие', en: 'Recents' },
  'home.disconnection': { ro: 'Deconectări', ru: 'Отключения', en: 'Disconnections' },
  'home.disconnection.action': { ro: 'deconectat', ru: 'отключился', en: 'disconnected' },
  'home.normative': { ro: 'Buletin juridic', ru: 'Юридический бюллетень', en: 'Legal bulletin' },
  'home.normative.action': { ro: 'Buletin juridic', ru: 'Юридический бюллетень', en: 'Legal bulletin' },
  'home.monitoring': { ro: 'Monitorizare', ru: 'Мониторинг', en: 'Monitoring' },
  'home.monitoring.action': { ro: 'Monitorizare', ru: 'Мониторинг', en: 'Monitoring' },
  'home.empty-default': { ro: 'Nimic nu a fost găsit', ru: 'Ничего не было найдено', en: "There're no records, yet" },
  'home.not-allowed': { ro: 'Nu aveti permis', ru: 'У вас нет доступа', en: 'You have no permission' },
  'home.empty-alert': {
    ro: 'Nu aveți nici o alertă',
    ru: 'Нет ни одного уведомления',
    en: "You don't have any alerts",
  },
  'home.emptyDesc-alert': {
    ro: 'Schimbați criterii de alerte la liste',
    ru: 'Изменить критерии уведомления в сохраненных списках',
    en: "Change alert's criteria to receive alerts",
  },
  'home.emptyDesc-alert-list': {
    ro: 'Schimbați criterii de căutarea',
    ru: 'Изменить критерии поиска',
    en: 'Change search criteria to receive alerts',
  },
  'home.emptyAction-alert': { ro: 'Modifică criterii', ru: 'Обновление критериев', en: 'Update criteria' },
  'home.empty-lists': {
    ro: 'Nu aveți nici o listă',
    ru: 'Нет ни одного сохраненного списков',
    en: "You don't have any saved lists",
  },
  'home.emptyDesc-lists': {
    ro: 'Începeți a salva companii care vă interesează într-o listă',
    ru: 'Начните сохранять интересующие вас компании в список.',
    en: 'Start to save some companies/executives to a list',
  },
  'home.emptyAction-lists': { ro: 'Salveaza companii', ru: 'Сохранить компании', en: 'Save companies' },
  'home.empty-search': {
    ro: 'Nu aveți nici o lista',
    ru: 'Нет ни одного сохраненного списка',
    en: "You don't have any saved lists",
  },
  'home.emptyDesc-search': {
    ro: 'Începeți a salva filtre intr-o listă',
    ru: 'Начните сохранять фильтры',
    en: 'Start to save your filters to a list',
  },
  'home.empty-export': {
    ro: 'Nu aveți nici un export',
    ru: 'Нет ни одного экспорта.',
    en: "You don't have any exports",
  },
  'home.emptyDesc-export': {
    ro: 'Începeți a exporta companii care vă interesează',
    ru: 'Начните экспортировать данные интересующих вас компании',
    en: 'Start to export companies/executives which are interested you',
  },
  'home.empty-disconnection': {
    ro: 'Nu aveți nici un abonament',
    ru: 'Нет ни одной подписки',
    en: "You don't have any subscriptions",
  },
  'home.emptyDesc-disconnection': {
    ro: 'Începeți a adauga o locație',
    ru: 'Начните информацию по местоположению',
    en: 'Start to add a location to receive disconnections',
  },
  'home.emptyAction-disconnection': {
    ro: 'Abonările la deconectări',
    ru: 'Подписки',
    en: 'Subscribes',
  },
  'home.empty-visit': {
    ro: 'Nu aveți nici o companie sau angajator vizitat recent',
    ru: 'Вы не открывали профиль ни в одной компании или работника',
    en: "You didn't visit any companies/executives recent",
  },
  'home.emptyDesc-visit': {
    ro: 'Începeți a vizita companii sau angajatori',
    ru: 'Начните посещение сейчас',
    en: 'Start to visit',
  },
  'home.searchEmployies': { ro: 'Caută angajați', ru: 'Поиск сотрудников', en: 'Search employees' },
  'home.searchCompanies': { ro: 'Caută companii', ru: 'Поиск компаний', en: 'Search companies' },
  'home.exportEmployies': { ro: 'Export angajați', ru: 'Экспорт сотрудников', en: 'Export employees' },
  'home.exportCompanies': { ro: 'Export companii', ru: 'Экспорт компаний', en: 'Export companies' },
  'home.companyActive': { ro: 'Companii active', ru: 'Активные компании', en: 'Active companies' },
  'home.companyUpdates': { ro: 'Companii actualizate', ru: 'Обновленные компании', en: 'Updates companies' },
  'home.companyNew': { ro: 'Companii noi', ru: 'Новые компании', en: 'New companies' },
  'home.companyClosed': { ro: 'Companii închise', ru: 'Закрытые компании', en: 'Closed companies' },
  'home.companyInformation': { ro: 'Companii total', ru: 'Всего компаний', en: 'Total companies' },
  'home.companyContacts': { ro: 'Contacte companii', ru: 'Контакты компаний', en: 'Company contacts' },
  'home.companyShareHolder': {
    ro: 'Informații ShareHolders',
    ru: 'ShareHolders информация',
    en: 'ShareHolders information',
  },
  'home.companyTotal': {
    ro: 'Companii total',
    ru: 'Всего компаний',
    en: 'Total companies',
  },
  'home.companyLiquidated': {
    ro: 'Companii lichidate',
    ru: 'Ликвидированные компании',
    en: 'Liquidated companies',
  },
  'home.companyLiquidatedLastMoth': {
    ro: 'Companii lichidate luna trecută',
    ru: 'Ликвидированные компании в прошлом месяце',
    en: 'Liquidated companies last month ',
  },
  'home.companyAddedLastMoth': {
    ro: 'Înregistrate luna trecută',
    ru: 'Зарегистрированные в прошлом месяце',
    en: 'Registered last month',
  },
  'home.financialSummary': {
    ro: 'Sumar financiar',
    ru: 'Сводные финансовые показатели',
    en: 'Financial Summary',
  },
  'home.validUntil': {
    ro: 'Valabil până la',
    ru: 'Действителен до',
    en: 'Valid until',
  },
  'home.industryInformation': {
    ro: 'Informații despre industrii',
    ru: 'Информация по индустрии',
    en: 'Industry information',
  },
  'home.employeeContacts': {
    ro: 'Contacte angajați',
    ru: 'Контакты сотрудников',
    en: 'Employee contacts',
  },
  'home.employeeInformation': {
    ro: 'Informații despre angajați',
    ru: 'Информация о сотрудниках',
    en: 'Employee information',
  },
  'home.entityType': {
    ro: 'Tipul entității',
    ru: 'Тип организации',
    en: 'Entity type',
  },
  'home.monthlyUpdates': {
    ro: 'Actualizări lunare',
    ru: 'Ежемесячные обновления',
    en: 'Monthly updates',
  },
  'home.informationAvailable': {
    ro: 'Informații disponibile',
    ru: 'Доступные данные',
    en: 'Available data',
  },
  'home.lastActivity': {
    ro: 'Ultimile acțiuni',
    ru: 'Последние действия',
    en: 'Latest activities',
  },
  'home.redirect_back_activity': {
    ro: 'Înapoi la pagina principală',
    ru: 'Вернуться на главную страницу',
    en: 'Back to main page',
  },
  'home.accessed': {
    ro: 'a accesat',
    ru: 'посетил',
    en: 'accessed',
  },

  // history

  // 'home.company_list': {
  //   ro: 'a accesat companie',
  //   ru: 'просмотрел профиль компании',
  //   en: 'viewed company profile',
  // },
  'home.saved_list': {
    ro: 'a salvat lista',
    ru: 'сохранил список',
    en: 'saved list',
  },
  'home.saved_search': {
    ro: 'a salvat căutarea',
    ru: 'сохранил поиск',
    en: 'saved search',
  },
  'home.exports_download_pdf': {
    ro: 'export PDF profil',
    ru: 'экспорт PDF профиль компании',
    en: 'exported PDF company profile',
  },
  'home.exports_download_xls': {
    ro: 'export XLS listă',
    ru: 'экспорт XSL список',
    en: 'exported XLS list',
  },
  'home.monitoring_create_list': {
    ro: 'a creat lista',
    ru: 'создал список',
    en: 'created trackinglist',
  },
  'home.monitoring_add_company_to_list': {
    ro: 'start monitorizare companie',
    ru: 'начал отслеживание компании',
    en: 'started tracking company',
  },
  'home.monitoring_remove_company_from_list': {
    ro: 'stop monitorizare companie',
    ru: 'прекратил отслеживание компании',
    en: 'stopped tracking company',
  },
  'home.removed_list': {
    ro: 'a șters lista',
    ru: 'удалил список',
    en: 'deleted list',
  },
  'home.removed_search': {
    ro: 'a șters căuarea',
    ru: 'удалил поиск',
    en: 'deleted search',
  },
  'home.exports_delete': {
    ro: 'a șters descărcarea',
    ru: 'удалил загрузку',
    en: 'deleted download',
  },

  // history

  'home.licenseInformation': {
    ro: 'a șters descărcarea',
    ru: 'удалил загрузку',
    en: 'deleted download',
  },
  'home.close': {
    ro: 'Închide',
    ru: 'Закрыть',
    en: 'Close',
  },
  'home.abonament': {
    ro: 'Închide',
    ru: 'Закрыть',
    en: 'Close',
  },
  'home.approve-abonament': {
    ro: 'Contul dvs. nu este aprobat!',
    ru: 'Ваша учетная запись не одобрена!',
    en: 'Your account is not approved!',
  },
  'home.approve-abonament-text': {
    ro: 'Trebuie să aștepți aprobarea contului de la administratorul contului.',
    ru: 'Вы должны дождаться утверждения учетной записи от администратора учетной записи.',
    en: 'You must wait for the account approval from the account administrator.',
  },
  'home.non-abonament': {
    ro: 'Nu aveți abonament!',
    ru: 'У вас нет подписки!',
    en: "You don't have a subscription!",
  },
  'home.non-abonament-text': {
    ro: 'Toate informațiile sunt disponibile numai pentru utilizatorii abonați.',
    ru: 'Вся информация доступна только подписанным пользователям.',
    en: 'All information is only available to subscribed users.',
  },
  'home.modal-package-submit': {
    ro: 'Confirmă',
    ru: 'Подтвердить',
    en: 'Confirm',
  },
  'home.modal-package-text': {
    ro:
      'Abonamentul dvs. a expirat. Contul dvs. va trece automat la pachetul Basic. Pentru a obține sau achiziționa un alt tip de abonament, completați formularul de pe site-ul nostru sau contactați direct consultanții noștri.',
    ru:
      'Срок действия вашего абонимента истек. Ваша учётная запись автоматически переходит на пакет Basic. Для получения или приобретения другого типа абонимента заполните форму на нашем сайте или свяжитесь на прямую с нашими консультантами.',
    en:
      'Your subscription has expired. Your account will automatically switch to the Basic package. To obtain or purchase another type of subscription, fill out the form on our website or contact our consultants directly.',
  },
});

export default home;
