import React, { useState, useEffect } from 'react';
import { useRequest } from 'estafette';
import { useInjectIntl } from '~/libs/localization';
import { useHistory, useParams } from 'react-router-dom';
import { getRoute } from '~/libs/router';
import { Input } from 'antd';
import env from '@beam-australia/react-env';
import { useDocumentTitle } from '~/hooks';
import { accountTeam } from '~/libs/http/api';
import { ProfileLayout } from '~/components/layouts';
import { CompanyLogo } from '~/components/profile';
import { Section, Form, Button, Text, Confirmation } from '~/components/ui';
import { validate } from '~/libs/object';
import { Icon } from '~/components/ui/Icon/Icon';

import '~/assets/scss/components/_MyProfile.scss';

const EditCompanyPage = () => {
  const { t } = useInjectIntl();
  const history = useHistory();
  const params = useParams();
  const { loading: loadingData } = useRequest();
  // const { request: requestSeniorities } = useRequest();
  const { request: requestDeleteCompany } = useRequest();
  const { request: requestCompanyProfile, data: companyProfile } = useRequest();
  const { request: requestPhoto, loading: loadingPhoto } = useRequest();
  const { request: requestSubmit, loading: loadingSubmit, errors: errorsSubmit } = useRequest();
  const [api, setApi] = useState({});
  const [preview, setPreview] = useState('');
  const [companyData, setCompanyData] = useState();
  const [companyName, setCompanyName] = useState();
  const [companyIdno, setCompanyIdno] = useState();
  const [companyAddress, setCompanyAddress] = useState();
  const [saveFax, setSaveFax] = useState([]);
  const [savePhone, setSavePhone] = useState([]);
  const [saveWeb, setSaveWeb] = useState([]);
  const [saveEmail, setSaveEmail] = useState([]);
  const [photo, setPhoto] = useState(null);

  useDocumentTitle(t('page.profile'));

  useEffect(() => {
    const getCompany = async () => {
      requestCompanyProfile(accountTeam.companyList());
    };
    getCompany();
  }, []);

  useEffect(() => {
    if (params.id) {
      setCompanyData(companyProfile.filter(item => item.id === Number(params.id)));
    }
  }, [companyProfile, params.id]);

  useEffect(() => {
    if (companyData?.length) {
      setCompanyName(companyData[0].name);
      setCompanyIdno(companyData[0].idno);
      setCompanyAddress(companyData[0].address);
      setSaveWeb(companyData[0].website ? companyData[0].website.substring(7) : null);
      setSaveEmail(companyData[0].email);
      setSavePhone(companyData[0].telephone);
      setSaveFax(companyData[0].fax);
      setPhoto(companyData[0].photo ? `${env('API_URL') || ''}/${companyData[0].photo || ''}` : null);
    }
  }, [companyData]);

  useEffect(() => {
    if (!photo) {
      setPhoto(null);
    }
  }, [photo]);

  const onDeleteCompany = id => {
    requestDeleteCompany(accountTeam.deleteCompany({ id })).then(() => {
      history.push(getRoute('TeamPage'));
    });
  };

  const onChangeFile = ({ target }) => {
    const image = target.files[0];
    const reader = new FileReader();

    reader.readAsDataURL(image);

    reader.onloadend = () => {
      setApi({
        ...api,
        image,
      });

      setPreview(reader.result);
    };

    setPhoto(image);
  };

  const onDeletePhoto = () => {
    setPhoto('');
    setPreview('');
  };

  const onSubmit = async () => {
    const http = 'http://';
    const options = {
      name: companyName,
      idno: companyIdno,
      address: companyAddress,
      email: saveEmail || null,
      telephone: savePhone || null,
      website: http.concat(saveWeb) || null,
      fax: saveFax || null,
      id: Number(params.id),
    };

    const { id } = await requestSubmit(accountTeam.editCompany({ ...options }));

    if (photo) {
      const formData = new FormData();
      formData.append('photo', photo);
      formData.append('id', id);
      formData.append('is_approved', true);
      if (photo && api.image) {
        requestPhoto(accountTeam.editCompany(formData));
      }
    } else {
      const formData = new FormData();
      formData.append('delete_photo', true);
      formData.append('id', id);
      formData.append('is_approved', true);
      requestPhoto(accountTeam.editCompany(formData));
    }

    history.push(getRoute('TeamPage'));
  };

  return (
    <ProfileLayout loading={loadingData} className="add-company-container">
      <div className="title">
        {api.is_staff ? <Text id="profile.addCompanyTitle" /> : t({ id: 'profile.editCompanyTitle' })}
      </div>

      <div className="half-sections no-half-section section-settings">
        <Section>
          <div className="profile-avatar company-logo">
            <CompanyLogo.Editable img={preview || photo} onClose={onDeletePhoto} loading={loadingPhoto} />

            <h2>
              <Text id="profile.uploadCompanyLogo" />
            </h2>

            <label className="atn-file">
              <input type="file" accept="image/*" onChange={onChangeFile} />

              <Text id="profile.uploadAnImage" />
            </label>

            <Button onClick={onDeletePhoto}>
              <Text id="profile.deleteImage" />
            </Button>
          </div>
        </Section>

        <Section>
          <Form>
            <>
              <div className="edit-company-profile-form">
                <Form.Half>
                  <div className="half-title">{t('profile.companyProfile')}</div>

                  <Form.Item label={t({ id: 'profile.companyName' })} {...validate(errorsSubmit, 'name')}>
                    <Input
                      type="text"
                      placeholder={companyName || t({ id: 'other.typeHere' })}
                      onChange={e => {
                        setCompanyName(e.target.value);
                      }}
                    />
                  </Form.Item>

                  <Form.Item label="IDNO" {...validate(errorsSubmit, 'idno')}>
                    <Input
                      type="text"
                      placeholder={companyIdno || 'IDNO'}
                      onChange={e => {
                        setCompanyIdno(e.target.value);
                      }}
                    />
                  </Form.Item>
                </Form.Half>

                <Form.Item label={t({ id: 'profile.address' })} {...validate(errorsSubmit, 'address')}>
                  <Input
                    type="text"
                    placeholder={companyAddress || t({ id: 'other.typeHere' })}
                    onChange={e => {
                      setCompanyAddress(e.target.value);
                    }}
                  />
                </Form.Item>
              </div>

              <div className="edit-contact-information-form">
                <Form.Half>
                  <div className="half-title">{t('profile.contactInformation')}</div>

                  <Form.Item label={t({ id: 'profile.website' })} {...validate(errorsSubmit, 'website')}>
                    <Input
                      type="text"
                      placeholder={saveWeb || t({ id: 'other.typeHere' })}
                      onChange={e => {
                        setSaveWeb(e.target.value);
                      }}
                    />
                  </Form.Item>

                  <Form.Item label={t({ id: 'profile.email' })} {...validate(errorsSubmit, 'email')}>
                    <Input
                      type="text"
                      placeholder={saveEmail || t({ id: 'other.typeHere' })}
                      onChange={e => {
                        setSaveEmail(e.target.value);
                      }}
                    />
                  </Form.Item>
                </Form.Half>

                <Form.Half>
                  <Form.Item label={t({ id: 'profile.phone' })} {...validate(errorsSubmit, 'phone')}>
                    <Input
                      type="text"
                      placeholder={savePhone || t({ id: 'other.typeHere' })}
                      onChange={e => {
                        setSavePhone(e.target.value);
                      }}
                    />
                  </Form.Item>
                  <Form.Item label={t({ id: 'profile.fax' })} {...validate(errorsSubmit, 'fax')}>
                    <Input
                      type="text"
                      placeholder={saveFax || t({ id: 'other.typeHere' })}
                      onChange={e => {
                        setSaveFax(e.target.value);
                      }}
                    />
                  </Form.Item>
                </Form.Half>
              </div>

              <div className="edit-form-footer">
                <div className="footer-leftside">
                  <Button type="reset" onClick={() => history.push(getRoute('TeamPage'))}>
                    {t({ id: 'other.reset' })}
                  </Button>

                  <Button
                    type="primary"
                    onClick={() => onSubmit()}
                    loading={loadingSubmit}
                    form="dynamic-input"
                    htmlType="submit"
                  >
                    {t({ id: 'other.submit' })}
                  </Button>
                </div>

                <div className="remove-selection">
                  <Icon type="trash" />
                  <Confirmation type="delete" onConfirm={() => onDeleteCompany(params.id)}>
                    <span>{t('profile.removeCompany')}</span>
                  </Confirmation>
                </div>
              </div>
            </>
          </Form>
        </Section>
      </div>
    </ProfileLayout>
  );
};

export default EditCompanyPage;
