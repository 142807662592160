import React from 'react';
import { Input } from 'antd';
import { injectIntl } from '~/libs/localization';
import { Modal, Button } from '~/components/ui';

class EditList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { t } = this.props;
    const { Fragment } = React;

    return (
      <Modal
        title={t('list.edit_list_name')}
        footerLeft={
          <Fragment>
            <Button onClick={() => this.props.onClose()}>{t('other.reset')}</Button>
            <Button type="primary">{t('other.submit')}</Button>
          </Fragment>
        }
        footerRight={<Button onClick={() => this.props.onClose()}>{t('other.delete')}</Button>}
        onClose={() => this.props.onClose()}
      >
        <Input placeholder={t('list.type_list_name')} />
      </Modal>
    );
  }
}

export default injectIntl(EditList);
