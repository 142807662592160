import React, { useEffect, useState } from 'react';

const Tabs = ({ defaultTab, currentTab, data, onChange, classList }) => {
  const [activeTab, setActiveTab] = useState(defaultTab || currentTab);
  const setCurrent = value => () => {
    setActiveTab(value);
    onChange && onChange(value);
  };

  useEffect(() => {
    currentTab && setActiveTab(currentTab);
  }, [currentTab]);

  return (
    <div className="tabs-wrapper">
      <ul className={`tabs ${classList}`}>
        {data.map(({ label, value }) => {
          const isActive = value === activeTab;
          const className = `tab ${isActive ? 'active' : ''}`;

          return (
            <li key={`${value}${isActive}`} className={className}>
              <a className="tab-content" onClick={setCurrent(value)}>
                {label}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Tabs;
