import React, { useMemo, useCallback, useReducer, useEffect } from 'react';
import { DatePicker, message } from 'antd';
import { useRequest } from 'estafette';
import { useParams, useHistory } from 'react-router-dom';
import { alerts, lists } from '~/libs/http/api';
import { useInjectIntl } from '~/libs/localization';
import { getRoute } from '~/libs/router';
import { useStateHandlers, useDocumentTitle } from '~/hooks';
import { Pagination, Loader, EmptyBlock } from '~/components/ui';
import { Layout } from '~/components/layouts';
import { AlertCard, AlertDrawer } from '~/components/alert';
import { TableHeader } from '~/components/table';

const formatDate = 'YYYY-MM-DD';

const filterReducer = (state, action) => {
  switch (action.type) {
    case 'SET_PAGE': {
      return { ...state, page: Number(action.page) };
    }
    case 'SET_DATE': {
      const fromDate = action.date.length > 0 ? action.date[0].format(formatDate) : null;
      const toDate = action.date.length > 0 ? action.date[1].format(formatDate) : null;
      return { ...state, date_from: fromDate, date_to: toDate, page: 1 };
    }
    default:
      return state;
  }
};

const AlertsListsPage = () => {
  const { t } = useInjectIntl();
  const history = useHistory();
  const params = useParams();
  useDocumentTitle(t('page.alerts'));
  const { request, data: dataList, loading: loadingList } = useRequest();

  const [state, setState] = useStateHandlers({
    loading: false,
    data: [],
    currentAlert: {},
  });

  const [filter, dispatch] = useReducer(filterReducer, {
    page: 1,
    date_from: undefined,
    date_to: undefined,
  });

  useEffect(() => {
    request(lists.getList({ id: params.id }));

    if (Number(params.page) !== filter.page) {
      dispatch({ type: 'SET_PAGE', page: params.page });
    }
  }, []);

  useEffect(() => {
    const onFetch = async () => {
      if (Number(params.page) !== filter.page) {
        history.push(getRoute('AlertsListsPage', { ...params, page: filter.page }));
      }

      setState({ loading: true });

      const options = { ...filter };
      options.id = Number(params.id);

      try {
        const { data } = await alerts.list(options);

        setState({ data: data.data, loading: false, pages: Math.ceil(data.total_results / 25) });
      } catch (err) {
        message.error(t('other.error_module'));
        setState({ loading: false });
      }
    };

    onFetch();
  }, [filter]);

  const onChangePage = page => dispatch({ type: 'SET_PAGE', page });
  const onChangeDate = date => dispatch({ type: 'SET_DATE', date });
  const onOpenDrawer = useCallback(newAlert => setState({ currentAlert: newAlert }), []);
  const onCloseDrawer = () => setState({ currentAlert: {} });

  const loading = useMemo(() => state.loading || loadingList, [state.loading, loadingList]);

  return (
    <>
      {Object.keys(state.currentAlert).length > 0 && (
        <AlertDrawer
          alertId={state.currentAlert.id}
          companyId={state.currentAlert.company_id}
          alert={state.currentAlert}
          data={state.data}
          onClose={onCloseDrawer}
        />
      )}

      <Layout>
        <div className="alerts">
          <TableHeader
            leftSide={
              <h1>
                <Loader.Inline loading={loading}>
                  {t('alerts.title')} {dataList.name}
                </Loader.Inline>
              </h1>
            }
            rightSide={
              <div className="form-inline">
                <DatePicker.RangePicker onChange={onChangeDate} />
              </div>
            }
          />

          {!loading && state.data.length === 0 && <EmptyBlock type="alert_list" />}

          <Loader loading={loading}>
            {state.data.map(item => (
              <AlertCard key={item.id_company} dataList={dataList} item={item} onClick={() => onOpenDrawer(item)} />
            ))}

            <Pagination page={filter.page} total={state.pages} onChange={page => onChangePage(page)} />
          </Loader>
        </div>
      </Layout>
    </>
  );
};

export default AlertsListsPage;
