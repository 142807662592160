import React, { useEffect } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useRequest } from 'estafette';
import { Table, DatePicker, Select } from 'antd';
import { useStateHandlers, useDocumentTitle } from '~/hooks';
import { useInjectIntl } from '~/libs/localization';
import { managementActivity } from '~/libs/http/api';
import { truncate, cleanUnderscore } from '~/libs/string';
import { getPeriodByInterval } from '~/libs/date';
import { Tag, Loader, Pagination } from '~/components/ui';
import { Layout } from '~/components/layouts';
import { Time } from '~/components/other';
import { ManagementMenu } from '~/components/management';

const formatDate = 'YYYY-MM-DD';
const formatDateTime = 'YYYY-MM-DD HH:mm';

const AdminAccountReqestsPage = () => {
  const { t } = useInjectIntl();
  const { id } = useParams();
  useDocumentTitle(t('management.requests'));
  const { request, data, loading } = useRequest({ data: { data: [], header: [] }, loading: true });
  const [state, setState] = useStateHandlers({
    interval: undefined,
    detailed: false,
    url: undefined,
    user: undefined,
    page: 1,
    detailedRecords: [],
    detailedColumns: [],
    columns: [],
    records: [],
  });

  useEffect(() => {
    const onFetch = async () => {
      const options = {
        id,
        ...state.interval,
        date_from: state.interval ? moment(state.interval.date_from).format(formatDate) : undefined,
        date_to: state.interval ? moment(state.interval.date_to).format(formatDate) : undefined,
        url: state.url,
        email: state.user,
        page: state.url || state.user ? state.page : undefined,
      };

      const detailed =
        (state.url && Object.keys(state.url).length > 0) || (state.user && Object.keys(state.user).length > 0);

      setState({ detailed });
      request(managementActivity[detailed ? 'requestDetails' : 'requests'](options));
    };

    onFetch();
  }, [state.interval, state.url, state.user, state.page]);

  useEffect(() => {
    const onChangeData = () => {
      setState({
        detailedRecords: Array.isArray(data.data) ? data.data.map((item, key) => ({ key, ...item })) : null,

        detailedColumns: [
          {
            title: (
              <Select
                allowClear
                dropdownMatchSelectWidth={false}
                {...(state.url ? { value: state.url } : null)}
                onChange={url => setState({ url })}
                dropdownClassName="email-settings-dropdown"
                placeholder="Type"
              >
                {data.header.map(url => (
                  <Select.Option value={url} key={url}>
                    {url.replace(/[-_]/g, ' ').replace(/[-_]/g, ' ')}
                  </Select.Option>
                ))}
              </Select>
            ),
            dataIndex: 'url',
            width: '175px',
            className: 'zh-selectable-table',
            render: type => cleanUnderscore(type),
          },
          {
            title: 'Params',
            dataIndex: 'params',
            render: text =>
              text ? <span className="zh-table-json">{truncate(JSON.stringify(text), 30)}</span> : '---',
          },
          {
            title: 'Created',
            dataIndex: 'date_created',
            render: date => <Time date={date} format={formatDateTime} />,
          },
        ],

        columns: [
          {
            title: t('filter.child.mail'),
            dataIndex: 'name',
            // fixed: 'left',
            render: user => (
              <span
                className="name-filtered"
                onClick={() => {
                  setState({ user, interval: undefined });
                }}
              >
                {user}
              </span>
            ),
          },
          ...data.header.map(url => ({
            title: (
              <span
                className="cursor"
                onClick={() => {
                  setState({ url });
                }}
              >
                {url.replace(/[-_]/g, ' ').replace(/[-_]/g, ' ')}
              </span>
            ),
            dataIndex: url,
            render: text => Number(text) || '---',
            align: 'center',
            className: 'table-icon',
          })),
        ],

        records:
          data.data instanceof Object
            ? Object.keys(data.data).map((name, key) => ({
                key,
                name,
                ...data.data[name],
              }))
            : [],
      });
    };

    onChangeData();
  }, [data]);

  const onChangeInterval = period => setState({ interval: { date_from: period[0], date_to: period[1] }, page: 1 });
  const onClearInterval = () => setState({ interval: undefined, page: 1 });
  const onClearUser = () => setState({ user: undefined, page: 1 });
  const onClearType = () => setState({ url: undefined, page: 1 });
  const onChangePage = page => setState({ page });

  // TODO: title has to be in state
  const setTableTitle = $interval => {
    let title = '';

    const interval = getPeriodByInterval($interval);

    if (interval instanceof Object) {
      title = Object.keys(interval)
        .map((text, key) => `${t(text)} ${moment(interval[key]).format('YYYY-MM-DD')}`)
        .join(' ');
    } else {
      title = t(interval);
    }

    return title;
  };

  return (
    <Layout containerClass="updatesEdit-container">
      <ManagementMenu />

      <ManagementMenu provider="account" />

      <Loader loading={loading}>
        <Table
          pagination={false}
          title={() => (
            <>
              <div className="head-title">
                <div className="table-title">{setTableTitle(state.interval)}</div>

                <div className="dropdowns-wrapper">
                  <div className="dropdowns-inner-container">
                    <DatePicker.RangePicker
                      value={state.interval ? [state.interval.date_from, state.interval.date_to] : null}
                      onChange={onChangeInterval}
                    />
                  </div>
                </div>
              </div>

              {state.url || state.user || (state.interval && Object.keys(state.interval).length > 0) ? (
                <div className="filtered-table">
                  {state.url && <Tag type={t('other.type')} name={cleanUnderscore(state.url)} onDelete={onClearType} />}

                  {state.user && (
                    <Tag type={t('management.user')} name={cleanUnderscore(state.user)} onDelete={onClearUser} />
                  )}

                  {state.interval && Object.keys(state.interval).length > 0 && (
                    <Tag
                      type={t('management.interval')}
                      name={`${cleanUnderscore(
                        moment(state.interval.date_from).format(formatDate),
                      )} ~ ${cleanUnderscore(moment(state.interval.date_to).format(formatDate))}`}
                      onDelete={onClearInterval}
                    />
                  )}
                </div>
              ) : null}
            </>
          )}
          columns={
            state.detailed
              ? state.user
                ? state.detailedColumns
                : [
                    {
                      title: 'Name',
                      dataIndex: 'user',
                      fixed: 'left',
                    },
                    ...state.detailedColumns,
                  ]
              : state.columns
          }
          dataSource={state.detailed ? state.detailedRecords : state.records}
          scroll={{ x: '100%' }}
          className="table table-user-activity"
          {...(data.pages > 1 && state.detailed
            ? {
                footer: () => <Pagination page={state.page} total={data.pages} onChange={page => onChangePage(page)} />,
              }
            : null)}
        />
      </Loader>
    </Layout>
  );
};

export default AdminAccountReqestsPage;
