import React, { useState, useEffect } from 'react';
import { useRequest } from 'estafette';
import { Input, Icon, Button, Popover } from 'antd';
import { useDocumentTitle } from '~/hooks';
import { useInjectIntl } from '~/libs/localization';
import { events } from '~/libs/http/api';
import { getRoute } from '~/libs/router';
import { ProfileLayout } from '~/components/layouts';
import { Avatar, PopAction, Events } from '~/components/profile';
import { Pagination, Confirmation, Table } from '~/components/ui';
import { TableFooter, TableHeader } from '~/components/table';

const EventPage = props => {
  const { request, data, loading } = useRequest({ data: { data: [] } });
  const { request: requestDelete } = useRequest({ data: { data: [] } });
  const { t } = useInjectIntl();
  const [search, setSearch] = useState('');
  const [record, setRecord] = useState({});
  const [showModal, setShowModal] = useState(false);

  useDocumentTitle(t('page.events'));

  useEffect(() => {
    onFetch();
  }, []);

  const onSearch = newSearch => {
    setSearch(newSearch);

    if (+props.match.params.page !== 1) {
      props.history.push(getRoute('EventsPage', { id: 1 }));
    } else {
      onFetch();
    }
  };

  const onDelete = async id => {
    await requestDelete(events.delete({ id }));
    onFetch();
  };

  const onFetch = (page = props.match.params.page) => request(events.list({ page, per_page: 25, search }));

  const onChangePage = newPage => onFetch(newPage);

  const onCloseMmodal = val => setShowModal(val);

  const onSetRecord = item => setRecord(item);

  const columns = [
    {
      render: (_, data1, key) => <Avatar.Small alt={`${data.region} ${data.address}`} />,
      width: 400,
    },
    {
      render: (item, data2, key) => (
        <span>
          {data2.region} {data2.address}
        </span>
      ),
      width: 100,
    },
    {
      render: (item, data3, key) => (
        <Popover
          content={
            <>
              <PopAction
                icon="edit"
                value={t('other.edit')}
                onClick={() => {
                  onCloseMmodal(true);
                  onSetRecord(data3);
                }}
              />

              <Confirmation
                onConfirm={() => {
                  onDelete(data3.id);
                }}
              >
                <PopAction icon="delete" value={t('other.delete')} />
              </Confirmation>
            </>
          }
          trigger="click"
        >
          <Icon type="ellipsis" className="btn-ellipsis" />
        </Popover>
      ),
      width: 100,
    },
  ];

  return (
    <>
      {showModal && (
        <Events
          record={record}
          refetch={() => onChangePage()}
          onClose={() => {
            onCloseMmodal(false);
            onSetRecord({});
          }}
        />
      )}

      <ProfileLayout loading={loading}>
        <TableHeader
          leftSide={
            <div className="form-inline">
              <h1>
                {t({ id: 'events.eventHeader' })} ({data.total_results})
              </h1>
              <Button
                icon="copy"
                onClick={() => {
                  onCloseMmodal(true);
                }}
              >
                {t({ id: 'events.addEvent' })}
              </Button>
            </div>
          }
          rightSide={
            <div className="form-inline">
              <Input.Search
                placeholder={t({ id: 'searches.search' })}
                enterButton
                onSearch={newSearch => onSearch(newSearch)}
              />
            </div>
          }
        />

        <Table columns={columns} dataSource={data.data} />

        <TableFooter
          rightSide={
            <Pagination page={props.match.params.page} total={data.pages} route="EventsPage" onChange={onChangePage} />
          }
        />
      </ProfileLayout>
    </>
  );
};

export default EventPage;
