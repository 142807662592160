import React from 'react';
import { SkeletonLayout, ProfileLoader, AuthLoader } from '~/components/suspense/loaders';

const SuspenseFallback = ({ type }) => {
  if (type === 'profile') {
    return <ProfileLoader />;
  }

  if (type === 'auth') {
    return <AuthLoader />;
  }

  return <SkeletonLayout />;
};

export default SuspenseFallback;
