import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
// import { Dropdown, Button } from 'antd';
import { useInjectIntl } from '~/libs/localization';
// import { Icon } from '~/components/ui/Icon/Icon';

import '~/assets/scss/components/_SearchInstruction.scss';

const SearchInstruction = () => {
  const { t } = useInjectIntl();
  const store = useSelector(
    ({ filter, localization, authentication }) => ({
      showFilter: filter.showFilter,
      localization,
      permissions: authentication.permissions,
    }),
    shallowEqual,
  );

  // const [isActiveCountryPopup] = useState(false);
  // const [country] = useState('md');

  // const onChangeCountry = visible => {
  //   console.log(visible);
  // };

  return (
    <div className="search-instruction">
      <div className="left-column">
        <img src={`/assets/img/search-info-${store.localization.current.shortName}.png`} alt="" className="note-img" />
      </div>

      <div className="right-column">
        <div className="title">{t('search.useSearch')}</div>

        {/* <div className="sub-title">{t('search.searchFunctions')}</div> */}

        {/* <div className="search-field-group search-field-group-instruction" id="search-field-group-instruction">
          <Dropdown
            onVisibleChange={onChangeCountry}
            getPopupContainer={() => document.getElementById('search-field-group-instruction')}
            overlayClassName="countries-popup-overlay"
            overlay={
              <div className="countries-popup">
                <ul className="countries-list">
                  <li className="left country-item active">
                    <Icon type="flag-ro" className="flag" />
                    Moldova
                    <Icon type="menu-check" />
                  </li>
                  <li className="left country-item">
                    <Icon type="flag-ro" className="flag" />
                    Russia
                  </li>
                  <li className="left country-item">
                    <Icon type="flag-ro" className="flag" />
                    Romania
                  </li>
                  <li className="left country-item">
                    <Icon type="flag-ro" className="flag" />
                    Ukraine
                  </li>
                  <li className="left country-item">
                    <Icon type="flag-ro" className="flag" />
                    Bulgaria
                  </li>
                  <li className="left country-item">
                    <Icon type="flag-ro" className="flag" />
                    Turkey
                  </li>
                  <li className="left country-item">
                    <Icon type="flag-ro" className="flag" />
                    Germany
                  </li>
                  <li className="left country-item">
                    <Icon type="flag-ro" className="flag" />
                    Italy
                  </li>
                  <li className="left country-item">
                    <Icon type="flag-ro" className="flag" />
                    Spain
                  </li>
                  <li className="left country-item">
                    <Icon type="flag-ro" className="flag" />
                    Moldova
                    <Icon type="menu-check" />
                  </li>
                  <li className="left country-item">
                    <Icon type="flag-ro" className="flag" />
                    Russia
                  </li>
                  <li className="left country-item">
                    <Icon type="flag-ro" className="flag" />
                    Romania
                  </li>
                </ul>
              </div>
            }
            trigger={['click']}
          >
            <Button className={`country-btn ${isActiveCountryPopup ? ' active' : ''}`}>
              <span className="flag-wrap">
                <Icon type={`flag-${country}`} />
              </span>
              Moldova
              <Icon type="lang-arrow-right" />
            </Button>
          </Dropdown>
        </div> */}

        <div className="instruction-list">
          <ul>
            <li>
              <span className="num">1</span>
              <div className="text">
                {/* {t('search.useSearch')} */}
                {/* Număr  */}
                {t('search.searchInstruction')}{' '}
                <span className="blue">
                  1002600023242
                  {/* <Icon type="menu-check" /> */}
                </span>
              </div>
            </li>
            {/* <li>
              <span className="num">2</span>
              <div className="text">
                {t('search.searchInstruction_1')} <span className="blue">Vitalie Aremescu</span>
              </div>
            </li> */}
            <li>
              <span className="num">2</span>
              <div className="text">
                {t('search.searchInstruction_2')} <span className="blue">Enterprise Business Solution SRL</span>
              </div>
            </li>
            <li>
              <span className="num">3</span>
              <div className="text">
                {t('search.searchInstruction_3')} <span className="blue">or. Chișinău str. Columna 170</span>
              </div>
            </li>
            <li>
              <span className="num">4</span>
              <div className="text">
                {t('search.searchInstruction_4')} <span className="blue">+37360333570</span>
              </div>
            </li>
            <li>
              <span className="num">5</span>
              <div className="text">{t('search.searchInstruction_5')}</div>
            </li>
          </ul>
        </div>

        <div className="notification">
          <div className="note">{t('search.note')}</div>
          {t('search.searchIsOnly')}
        </div>
      </div>
    </div>
  );
};

export default SearchInstruction;
