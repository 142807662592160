import React, { useMemo } from 'react';
import NumberFormat from 'react-number-format';
import ReactCodeInput from 'react-verification-code-input';
import { useRequest } from 'estafette';
import { useHistory, useLocation } from 'react-router-dom';
// import ReCAPTCHA from 'react-google-recaptcha';
import { getRoute } from '~/libs/router';
import { profile } from '~/libs/http/api';
import { parseQuery, parseObject } from '~/libs/http/url';
import { useInjectIntl } from '~/libs/localization';
import { validate } from '~/libs/object';
import { useDocumentTitle, useStateHandlers } from '~/hooks';
import { Header, Footer } from '~/components/public';
import { Auth, Button, Form } from '~/components/ui';
import { Icon } from '~/components/ui/Icon/Icon';

// const recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;

const SmsPage = ({ ...props }) => {
  const { t } = useInjectIntl();
  const { getFieldDecorator } = props.form;
  const [changePhone, setChangePhone] = React.useState(false);
  const [countSendPhone, setCountSendPhone] = React.useState(localStorage.getItem('countSendPhone') || 0);
  const [countEditPhone, setCountEditPhone] = React.useState(localStorage.getItem('countEditPhone') || 0);
  const [smsTimer, setSmsTimer] = React.useState(0);
  // eslint-disable-next-line i18n-text/no-en
  useDocumentTitle('SMS Verification');
  const history = useHistory();
  const location = useLocation();
  const { request, loading, errors } = useRequest();
  const hasErrors = Object.keys(errors).length > 0;
  const query = useMemo(() => parseQuery(location.search), [location.search]);
  const [state, setState] = useStateHandlers({
    phone: query.phone,
  });
  const [oldPhone, setOldPhone] = React.useState(query.phone);

  const onSendCode = async code => {
    if (code && code.length === 4) {
      const { email } = await request(profile.registerStep3({ token: code }));

      const signinQueries = { default_email: email };
      // Enable payment with card
      // query.package && (signinQueries.package = query.package);

      history.push(`${getRoute('SigninPage')}${parseObject(signinQueries)}`);
    }
  };

  const onSubmit = e => {
    e.preventDefault();

    props.form.validateFields((err, fields) => {
      if (!err && !changePhone) {
        onSendCode(fields.code);
      }
      if (!err && changePhone) {
        localStorage.setItem('countEditPhone', Number(countEditPhone) + 1);
        setCountEditPhone(Number(countEditPhone) + 1);

        localStorage.setItem('countSendPhone', 0);
        setCountSendPhone(0);

        onEditPhone();
      }
    });
  };

  const onSendPhone = async (customCount = false) => {
    onTimer();

    let count = 0;
    if (customCount) {
      count = 1;
    } else {
      count = Number(countSendPhone) + 1;
    }

    setCountSendPhone(count);
    localStorage.setItem('countSendPhone', count);

    if (count < 3) {
      await request(
        profile.resendSms({
          email: query.email,
          phone: state.phone,
        }),
      );
    }
  };

  const onEditPhone = () => {
    const queryNew = {
      phone: state.phone,
      email: query.email,
    };

    setChangePhone(false);

    onSendPhone(true);

    setOldPhone(state.phone);

    return history.push(`${getRoute('SignupSmsPage')}${parseObject(queryNew)}`);
  };

  const onTimer = () => {
    if (smsTimer === 0) {
      let time = 30;
      const interval = setInterval(() => {
        time -= 1;
        setSmsTimer(time);
        if (time < 1) {
          clearInterval(interval);
        }
      }, 1000);
    }
  };

  // useEffect(() => {
  //   localStorage.setItem('countSendPhone', 0);
  //   localStorage.setItem('countEditPhone', 0);
  // }, []);
  // to do remove

  const onChangePhone = ({ target }) => setState({ phone: target.value });

  const onHideChangePhone = () => {
    if (changePhone) {
      setState({ phone: oldPhone });
    }

    setChangePhone(!changePhone);
  };

  return (
    <>
      <Header />

      <Auth.Container
        wide
        key="container"
        header={
          <div>
            <h1>{t('statics.reg-sms-verification')}</h1>
            <span>{t('statics.reg-sms-description')}</span>
            <p />
          </div>
        }
      >
        {!changePhone ? (
          <Form onSubmit={onSubmit}>
            <div className="signup-email-validation">
              <Icon type="sign-up-sms-confirmation" />

              <span className="signup-title center">{t('signup.confirmSms')}</span>
              <span className={`signup-desc center ${hasErrors ? 'warning' : ''}`}>
                {hasErrors && t('signup.wrongSms')}
                {countEditPhone < 3 ? (
                  <>
                    {t('signup.enterSms')}
                    <a onClick={() => onHideChangePhone()}>
                      {' '}
                      {query.phone} <Icon type="edit" />
                    </a>
                  </>
                ) : (
                  <>
                    {t('signup.enterSms')}
                    {query.phone}
                  </>
                )}
              </span>

              <Form.Item>
                {getFieldDecorator('code', {
                  rules: [
                    { len: 4, message: t('signup.enterSms') },
                    { required: true, message: t('signup.requiredField') },
                  ],
                })(<ReactCodeInput fields={4} autoFocus className="signup-sms-input" loading={loading} />)}
              </Form.Item>

              {/* {recaptchaKey && (
                <Form.Item>
                  {getFieldDecorator('recaptcha', {
                    rules: [{ required: true, message: t('signup.recaptchaRequired') }],
                  })(<ReCAPTCHA className="recaptcha-field" sitekey={recaptchaKey} />)}
                </Form.Item>
              )} */}

              <Form.Item className="mt-20 sms-retrive">
                <Button type="primary" htmlType="submit" className="btn-block resize-btn">
                  {t('other.submit')}
                </Button>

                <Button
                  className="btn-block resize-btn"
                  onClick={() => onSendPhone()}
                  disabled={smsTimer > 0 || countSendPhone > 2}
                >
                  {`${t('signup.resendSms')} ${smsTimer > 0 ? `(${smsTimer})` : ''}`}
                </Button>
              </Form.Item>
            </div>
          </Form>
        ) : (
          <Form onSubmit={onSubmit}>
            <div className="signup-email-validation">
              <Icon type="sign-up-sms-confirmation" />

              <Form.Item
                label={t('other.telephone')}
                {...validate(errors, 'phone')}
                extra={t('signup.phoneNotification')}
              >
                {getFieldDecorator('phone', {
                  initialValue: state.phone,
                  rules: [
                    { required: true, message: t('signup.requiredField') },
                    {
                      pattern: new RegExp(/[+][0-9]{11}/g),
                      message: t('signup.requiredField'),
                    },
                  ],
                })(
                  <NumberFormat
                    format="+373########"
                    placeholder="+373________"
                    className="ant-input"
                    prefix="+373"
                    mask="_"
                    allowEmptyFormatting
                    onChange={onChangePhone}
                  />,
                )}
                {/* <Input placeholder={t('other.typeTelephone')} onChange={onChangePhone} value={state.phone} /> */}
              </Form.Item>

              <Form.Item className="mt-20">
                <Button
                  type="primary"
                  className="btn-block"
                  htmlType="submit"
                  disabled={smsTimer > 0 || countEditPhone > 2}
                >
                  {t('signup.applyAndSend')}
                </Button>
              </Form.Item>

              <Form.Item className="mt-20">
                <Button className="btn-block" onClick={() => onHideChangePhone()}>
                  {t('signup.cancel')}
                </Button>
              </Form.Item>
            </div>
          </Form>
        )}
      </Auth.Container>

      <div className="footer-sms-verification">
        <Footer />
      </div>
    </>
  );
};

export default Form.create()(SmsPage);
