import React from 'react';
import { Media } from '~/components/svg';
import { parseLink, parseWebsite } from '~/libs/string';
import InfoMore from './InfoMore';
import { Empty } from '~/components/ui';
import { Icon } from '~/components/ui/Icon/Icon';

const Info = props => (
  <div className={`company-info ${props.className || ''}`}>
    {props.label && <span className="company-info-label">{props.label}:</span>}

    {props.children}
  </div>
);

Info.More = InfoMore;

Info.Detail = props => (
  <div className={`info-detail ${props.icon ? 'info-detail-icon' : ''}`}>
    {props.icon && <div className="icon">{props.icon}</div>}

    <span className={props.textBlue ? 'blue' : ''}>
      {!props.websites ? props.title || <Empty /> : null}

      {props.websites ? (
        props.websites.length ? (
          props.websites.map((link, key) => [
            key >= 1 && <span key={`ln-${link}`}>, </span>,
            <a href={parseLink(link)} target="_blank" rel="noopener noreferrer" key={link}>
              {parseWebsite(link)}
            </a>,
          ])
        ) : (
          <Empty />
        )
      ) : null}
    </span>
  </div>
);

Info.Media = props =>
  props.to ? (
    <a href={props.to} target="_blank" rel="noopener noreferrer" className="company-media">
      {props.loading && <Icon type="loading" />}

      <Media name={props.name} />
    </a>
  ) : (
    <span className="company-media disabled">
      {props.loading && <Icon type="loading" />}

      <Media name={props.name} />
    </span>
  );

Info.Halves = props => <div className="company-info-halves">{props.children}</div>;

export default Info;
