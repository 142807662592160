import { adapterIntl } from '~/libs/localization/helpers';

const permissions = adapterIntl({
  'permissions.company_preview': {
    ro: 'Din păcate, nu aveți permisiunea să vizionați companiile.',
    ru: 'К сожалению, у всех вас нет разрешения на просмотр компаний.',
    en: "Unfortunately, you all don't have permission to preview companies.",
  },
});

export default permissions;
