import React, { useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { getRoute } from '~/libs/router';
import { useRequest } from 'estafette';
import { useDocumentTitle, useStateHandlers } from '~/hooks';
import { feedback } from '~/libs/http/api';
import { injectIntl } from '~/libs/localization';
import { Layout } from '~/components/layouts';
import { ManagementMenu, FeedbackDrawer } from '~/components/management';
import { Text, Tabs, Empty, Table } from '~/components/ui';
import { Time } from '~/components/other';
import { notification } from '~/libs/notification';
import NotFoundPage from '~/pages/NotFoundPage';

const TABS = [
  { label: <Text id="home.lists" />, value: 'lists' },
  { label: <Text id="home.exportsTitle" />, value: 'exports' },
];

const AdminFeedbacksPage = ({ t, ...props }) => {
  const { page, type } = useParams();
  const history = useHistory();
  useDocumentTitle(t('page.feedback'));
  const { request, data, loading } = useRequest({ data: { data: [] }, loading: true });
  const { request: requestDelete, loadingDelete } = useRequest();
  const classLoading = loading ? 'skeleton skeleton-text' : '';
  const [state, setState] = useStateHandlers({
    page: 1,
    pages: 1,
    total: 0,
    type: 'lists',
    currentDrawer: null,
  });

  useEffect(() => {
    const onFetch = async () => {
      await request(feedback.list({ content_type: type, page }));
    };

    onFetch();
  }, [page, type]);

  useEffect(() => {
    setState({ page, type });
  }, [page, type]);

  const onChangeTab = tab => {
    return history.push(getRoute('AdminFeedbacksPage', { type: tab, page: 1 }));
  };

  const openDrawer = useCallback(currentDrawer => setState({ currentDrawer }), []);
  const closeDrawer = () => setState({ currentDrawer: {} });

  const onDelete = async id => {
    await requestDelete(feedback.delete({ id }));

    notification({
      type: 'success',
      message: t('billing.success'),
      description: t({ id: 'other.success.deleted' }),
    });

    return history.push(getRoute('AdminFeedbacksPage', { type, page: 1 }));
  };

  const columns = [
    {
      title: t({ id: 'other.feedback' }),
      className: 'td-key',
      dataIndex: 'text',
      render: (record, data1) => (
        <span className={`title ${classLoading}`} onClick={() => openDrawer(data1)}>
          {record || <Empty />}
        </span>
      ),
      width: 100,
    },
    {
      title: t({ id: 'other.name' }),
      dataIndex: 'name',
      render: record => (
        <span
          className={classLoading}
          dangerouslySetInnerHTML={{
            __html: String(record || <Empty />),
          }}
        />
      ),
      width: 100,
    },
    {
      title: t({ id: 'other.date' }),
      dataIndex: 'date_created',
      render: record =>
        record ? <Time date={record} format="YYYY-MM-DD HH:mm" loading={loading} prefix /> : <Empty />,
      width: 100,
    },
  ];

  return (
    <Layout>
      {state.currentDrawer && Object.keys(state.currentDrawer).length > 0 && (
        <FeedbackDrawer id={state.currentDrawer.id} loading={loadingDelete} onClose={closeDrawer} onDelete={onDelete} />
      )}

      <ManagementMenu />

      <Tabs currentTab={state.type} onChange={onChangeTab} data={TABS} classList="tabs-dotted" />

      <Table className="table-layout-fixed" columns={columns} dataSource={data.data} />

      {!data.data.length && <NotFoundPage />}
    </Layout>
  );
};

export default injectIntl(AdminFeedbacksPage);
