import React from 'react';
import { useParams } from 'react-router-dom';
import { Input, Select } from 'antd';
import { injectIntl } from '~/libs/localization';
import { DownloadStatus } from '~/components/profile';
import { Pagination, Confirmation, Empty, Table } from '~/components/ui';
import { Time } from '~/components/other';
import { TableHeader } from '~/components/table';
import { Icon } from '~/components/ui/Icon/Icon';

const { Option } = Select;

const DownloadsList = ({
  t,
  total,
  onSearch,
  pages,
  data,
  filter,
  onChangeSort,
  onDelete,
  onChangeSearch,
  loading,
  paginationChanges,
  ...props
}) => {
  const { page } = useParams();

  const columns = [
    {
      title: t({ id: 'other.date' }),
      width: 130,
      className: 'collapsing center aligned',
      dataIndex: 'date_started',
      render: (date, record) => (
        <Time prefix date={date || record.date_finished || record.date_created} format="date" />
      ),
    },
    {
      title: t({ id: 'other.name' }),
      width: 550,
      className: 'collapsing responsive2',
      dataIndex: 'name',
      render: name =>
        (
          <span
            className="description text-blue"
            dangerouslySetInnerHTML={{
              __html: String(name),
            }}
          />
        ) || <Empty />,
    },
    {
      title: t({ id: 'other.status' }),
      width: 250,
      dataIndex: 'status',
      render: status => <DownloadStatus status={status} />,
    },
    {
      title: t({ id: 'other.actions' }),
      className: 'nowrap',
      dataIndex: 'id',
      render: id => (
        <Confirmation type="delete" onConfirm={() => onDelete(id)}>
          <span className="table-action-text cursor">
            <Icon type="delete" />
            {t({ id: 'other.delete' })}
          </span>
        </Confirmation>
      ),
    },
    {
      title: '',
      className: 'nowrap',
      render: (text, record) =>
        record.status === 'finish' ? (
          <span onClick={() => props.onDownload(record)} className="table-action-text cursor">
            {record.id === loading ? (
              <Icon type="loading" className="zh-history-spin" />
            ) : (
              <Icon type="filter-download" />
            )}
            {t({ id: 'other.download' })}
          </span>
        ) : (
          <span className="table-action-text cursor">
            <span className="disabled-link">
              <Icon type="filter-download" />
              {t({ id: 'other.download' })}
            </span>
          </span>
        ),
    },
    {
      title: '',
      className: 'nowrap',
      render: (text, record) => (
        <span onClick={() => props.onFeedback(record.id)} className="table-action-text cursor">
          <Icon type="filter-feedback" />
          {t({ id: 'other.feedback' })}
        </span>
      ),
    },
  ];

  const sorts = {
    date_created: t({ id: 'other.date-created-asc' }),
    '-date_created': t({ id: 'other.date-created-desc' }),
    name: t({ id: 'other.name-asc' }),
    '-name': t({ id: 'other.name-desc' }),
  };

  return (
    <>
      <Table
        rowKey="id"
        defaultExpandAllRows
        className="table-clean table-header basic"
        fixed
        columns={columns}
        dataSource={data}
        filterOrder={filter.ordering}
        onChangeFilter={onChangeSort}
        maxWidth={1460}
        title={() => (
          <TableHeader
            leftSide={
              <h1>
                {t({ id: 'downloads' })} ({total})
              </h1>
            }
            rightSide={
              <div className="form-inline">
                <Input.Search
                  placeholder={t({ id: 'searches.search' })}
                  enterButton
                  onSearch={onChangeSearch}
                  defaultValue={filter.search}
                />

                <Select
                  allowClear
                  placeholder="Date"
                  defaultValue={filter.ordering}
                  dropdownClassName="credit-alerts-dropdown"
                  onChange={onChangeSort}
                  dropdownMatchSelectWidth={false}
                >
                  {Object.keys(sorts).map(item => (
                    <Option value={item} key={item}>
                      {sorts[item]}
                    </Option>
                  ))}
                </Select>
              </div>
            }
          />
        )}
        footer={() => (
          <>
            {pages > 1 && (
              <div className="table-above clear-fix">
                <div className="pull-right">
                  <Pagination params={{ type: 'list' }} page={page} total={pages} route="DownloadsPage" />
                </div>
              </div>
            )}
          </>
        )}
      />
    </>
  );
};

export default injectIntl(DownloadsList);
