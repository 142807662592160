import React from 'react';
import { injectIntl } from '~/libs/localization';
import { Link } from '~/libs/router';
import { Empty } from '~/components/svg';
import { Button } from '~/components/ui';

const EmptyBlock = ({ type = 'default', t }) => {
  const types = {
    default: {
      title: 'home.empty-default',
    },
    alert: {
      title: 'home.empty-alert',
      desc: 'home.emptyDesc-alert',
      actions: (
        <Link route="SavedListsPage" params={{ page: 1 }}>
          <Button type="secondary">{t({ id: 'home.emptyAction-alert' })}</Button>
        </Link>
      ),
    },
    alert_list: {
      title: 'home.empty-alert',
      desc: 'home.emptyDesc-alert-list',
    },
    lists: {
      title: 'home.empty-lists',
      desc: 'home.emptyDesc-lists',
      actions: (
        <Link route="SavedListsPage" params={{ page: 1 }}>
          <Button type="secondary">{t({ id: 'home.emptyAction-lists' })}</Button>
        </Link>
      ),
    },
    search: {
      title: 'home.empty-search',
      desc: 'home.emptyDesc-search',
      actions: (
        <>
          <Link route="SearchResultsPage" params={{ type: 'company', mode: 'basic' }}>
            <Button type="primary">{t({ id: 'home.searchEmployies' })}</Button>
          </Link>

          <Link route="SearchResultsPage" params={{ type: 'company', mode: 'basic' }}>
            <Button type="secondary">{t({ id: 'home.searchCompanies' })}</Button>
          </Link>
        </>
      ),
    },
    export: {
      title: 'home.empty-export',
      desc: 'home.emptyDesc-export',
      actions: (
        <>
          <Link route="SearchResultsPage" params={{ type: 'company', mode: 'basic' }}>
            <Button type="primary">{t({ id: 'home.exportEmployies' })}</Button>
          </Link>

          <Link route="SearchResultsPage" params={{ type: 'company', mode: 'basic' }}>
            <Button type="secondary">{t({ id: 'home.exportCompanies' })}</Button>
          </Link>
        </>
      ),
    },
    disconnection: {
      title: 'home.empty-disconnection',
      desc: 'home.emptyDesc-disconnection',
      actions: (
        <Link route="EventsPage" params={{ page: 1 }}>
          <Button type="primary">{t({ id: 'home.emptyAction-disconnection' })}</Button>
        </Link>
      ),
    },
    visit: {
      title: 'home.empty-visit',
      desc: 'home.emptyDesc-visit',
    },
    notFound: {
      title: 'home.empty-default',
      icon: <img src="/assets/img/404.png" alt="404 page" />,
      className: 'not-found',
    },
    notAllowed: {
      title: 'home.not-allowed',
      icon: <img src="/assets/img/404.png" alt="404 page" />,
      className: 'not-found',
    },
  };

  const refType = types[type] || types.default;

  return (
    <div className="empty-block">
      <Empty className={refType.className}>{refType.icon || <Empty.Default />}</Empty>

      <h2>{t({ id: refType.title })}</h2>
      {refType.desc && <p>{t({ id: refType.desc })}</p>}
      {refType.actions && <div className="button">{refType.actions}</div>}
    </div>
  );
};

export default injectIntl(EmptyBlock);
