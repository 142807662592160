import React from 'react';
import { Drawer } from 'antd';
import MediaQuery from 'react-responsive';
import responsive from '~/libs/responsive';
import { SidebarLegalBulletinMain, SidebarLegalBulletinList, SidebarLegalBulletinActs } from '.';

import '~/assets/scss/components/_SidebarLegalBulletin.scss';

const SidebarLegalBulletin = props => {
  const Sidebar = () => {
    switch (props.type) {
      case 'main':
        return <SidebarLegalBulletinMain />;
      case 'list':
        return <SidebarLegalBulletinList />;
      case 'acts':
        return <SidebarLegalBulletinActs />;
      default:
        return <SidebarLegalBulletinMain />;
    }
  };

  return (
    <>
      <MediaQuery maxWidth={responsive.desktop}>
        <Drawer
          placement="left"
          closable={false}
          onClose={props.onCloseSidebar}
          visible={props.isDrawerVisible}
          className="drawer-legal-sidebar"
          width="auto"
        >
          <Sidebar />
        </Drawer>
      </MediaQuery>

      <MediaQuery minWidth={responsive.desktop}>
        <Sidebar />
      </MediaQuery>
    </>
  );
};

export default SidebarLegalBulletin;
