import axios from '~/libs/http/axios';

const { baseURL } = axios.defaults;

const locations = {
  getList: (params = {}) => axios.get(`${baseURL}/api/notification/address/list`, { params }),
  getDefault: (params = {}) => axios.get(`${baseURL}/api/notification/address/default`, { params }),
  getLocationInfo: (params = {}) => axios.get(`${baseURL}/api/notification/address`, { params }),
  getAddress: (params = {}) => axios.get(`${baseURL}/api/notification/address/search`, { params }),
  addAddress: (params = {}) => axios.post(`${baseURL}/api/notification/address`, params),
  editAddress: (params = {}) => axios.patch(`${baseURL}/api/notification/address`, params),
  deleteAddress: (params = {}) => axios.delete(`${baseURL}/api/notification/address`, { params }),
  searchStreet: (params = {}) => axios.get(`${baseURL}/api/notification/address/search?type=street`, { params }),
};

export default locations;
