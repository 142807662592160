import { addOrRemove } from '~/libs/array';

export const initialState = {
  loading: false,
  empty: false,
  page: 1,
  savedPages: [1],
  total: 0,
  data: [],
  selectedRow: [],
  last: false,
  search_type: false,
};

export const searches = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_SEARCH': {
      const isFirstPage = action.payload.page === 1;
      const pageAlreadyExists = state.savedPages.includes(action.payload.page);

      return Object.assign({}, state, {
        empty: !action.payload.data.length && !action.payload.data.length && action.payload.page === 1,
        page: action.payload.page,
        savedPages: isFirstPage
          ? [1]
          : pageAlreadyExists
          ? state.savedPages
          : [...state.savedPages, action.payload.page],
        total: action.payload.total,
        data: isFirstPage
          ? action.payload.data
          : pageAlreadyExists
          ? state.data
          : [...state.data, ...action.payload.data],
        last: action.payload.last,
        loading: false,
      });
    }
    case 'SET_SEARCHER_LOADING':
      return Object.assign({}, state, { loading: action.payload });
    case 'SET_SEARCH_TYPE':
      return Object.assign({}, state, { search_type: action.payload });
    case 'INVERT_SELECTIONS':
      return Object.assign({}, state, {
        selectedRow: state.data.filter(({ id }) => !state.selectedRow.includes(id)).map(({ id }) => id),
      });
    case 'SELECT_SEARCH':
      return { ...state, selectedRow: addOrRemove(state.selectedRow, action.payload) };
    case 'SELECT_ALL_SEARCH':
      return { ...state, selectedRow: state.data.map(({ id }) => id) };
    case 'RESET_SELECT_SEARCH':
      return { ...state, selectedRow: [] };
    default:
      return state;
  }
};
