export const setIndustries = payload => dispatch => {
  dispatch({
    type: 'SET_INDUSTRIES',
    payload,
  });
};

export const setIsRead = payload => dispatch => {
  dispatch({
    type: 'SET_IS_READ',
    payload,
  });
};

export const setFilterList = (payload = {}) => dispatch => {
  dispatch({
    type: 'SET_FILTER_LIST',
    payload,
  });
};

export const setActId = actId => async dispatch => {
  dispatch({
    type: 'SET_ACT_ID',
    payload: {
      actId,
    },
  });
};
