import React from 'react';
import { useInjectIntl } from '~/libs/localization';
import { useCurrentLanguage } from '~/hooks';

const Footer = ({ positionBottom }) => {
  const { t } = useInjectIntl();
  return (
    <div className={`public-footer ${positionBottom}`}>
      <div className="public-footer__content">
        <div className="leftside">
          <span>© 2020 informer.md</span>
          {/* <span>•</span> */}
          <a href={useCurrentLanguage('c')} target="_blank" rel="noopener noreferrer">
            <span>{t('statics.help-assist')}</span>
          </a>
        </div>
        {/* <div className="rightside">
          <span>
            Made with{' '}
            <span role="img" aria-label="heart">
              ❤️
            </span>{' '}
            by{' '}
          </span>
          <span>EBS Integrator</span>
        </div> */}
      </div>
    </div>
  );
};

export default Footer;
