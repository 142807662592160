import React from 'react';
import { Button, Select, Input, Icon, Radio } from 'antd';
import { Avatar } from '~/components/profile';
import { Form, Section } from '~/components/ui';
import { ProfileLayout } from '~/components/layouts';

class CompanyPage extends React.PureComponent {
  state = {
    file: [],
    preview: '',
  };

  submit(e) {
    e.preventDefault();
  }

  uploadPhoto(file: Object[]) {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onloadend = () =>
      this.setState({
        preview: reader.result,
        file,
      });
  }

  render() {
    return (
      <ProfileLayout>
        <div className="half-sections no-half-section">
          <Section>
            <div className="profile-avatar">
              <Avatar.Editable
                img={this.state.preview}
                alt="Wladimir Zhosan"
                onClose={() => this.setState({ file: [], preview: '' })}
              />

              <h2>Încărcați fotografia</h2>

              <label className="atn-file">
                <input type="file" accept="image/*" onChange={({ target }) => this.uploadPhoto(target.files[0])} />
                Încărcați o imagine
              </label>

              <Button>Ștergeți imaginea</Button>
            </div>
          </Section>

          <Section lazy>
            <Form onSubmit={e => this.submit(e)}>
              <Form.Header>Detaliile companiei</Form.Header>

              <Form.Half>
                <Form.Item label="Numele companii">
                  <Input type="text" placeholder="Introduceți text aici" />
                </Form.Item>

                <Form.Item label="Forma Juridică de Organizare">
                  <Select
                    showSearch
                    placeholder="Selectați"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Select.Option value="tom">Tom</Select.Option>
                  </Select>
                </Form.Item>
              </Form.Half>

              <Form.Half>
                <Form.Item label="Nivelul de Conducere">
                  <Select
                    showSearch
                    placeholder="Selectați"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Select.Option value="tom">Tom</Select.Option>
                  </Select>
                </Form.Item>
              </Form.Half>

              <Form.Half>
                <Form.Item label="Email">
                  <Input prefix={<Icon type="mail" />} type="text" placeholder="Introduceți text aici" />
                </Form.Item>

                <Form.Item label="Telefon corporativ">
                  <Input prefix={<Icon type="phone" />} type="text" placeholder="Introduceți text aici" />
                </Form.Item>
              </Form.Half>

              <Form.Half>
                <Form.Item label="Telefon corporativ">
                  <Input prefix={<Icon type="phone" />} type="text" placeholder="Introduceți text aici" />
                </Form.Item>

                <Form.Item label="Website">
                  <Input prefix={<Icon type="global" />} type="text" placeholder="Introduceți text aici" />
                </Form.Item>
              </Form.Half>

              <Form.Half>
                <Form.Item label="Fax">
                  <Input prefix={<Icon type="printer" />} type="text" placeholder="Introduceți text aici" />
                </Form.Item>
              </Form.Half>

              <Form.Half>
                <Form.Item label="Locația">
                  <Select
                    showSearch
                    placeholder="Selectați"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Select.Option value="tom">Tom</Select.Option>
                  </Select>
                </Form.Item>
              </Form.Half>

              <Form.Half>
                <Form.Item label="Adresa Juridica">
                  <Input type="text" placeholder="Introduceți text aici" />
                </Form.Item>

                <Form.Item label="Cod poștal">
                  <Input type="text" placeholder="Introduceți text aici" className="btn-short" />
                </Form.Item>
              </Form.Half>

              <Form.Half>
                <Form.Item label="Adresa de Facto">
                  <Input type="text" placeholder="Introduceți text aici" />
                </Form.Item>

                <Form.Item label="Cod poștal">
                  <Input type="text" placeholder="Introduceți text aici" className="btn-short" />
                </Form.Item>
              </Form.Half>

              <Form.Helper>Subdiviziuni</Form.Helper>
              <Form.Half>
                <Form.Item>
                  <Radio.Group>
                    <Radio value="false">Nu</Radio>
                    <Radio value="true">Da</Radio>
                  </Radio.Group>
                </Form.Item>
              </Form.Half>

              <Form.Half>
                <Form.Item label="Alegeți tipul subdiviziunii">
                  <Select
                    showSearch
                    placeholder="Selectați"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Select.Option value="tom">Tom</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item className="btn-form">
                  <Button>Adaugă subdiviziune</Button>
                </Form.Item>
              </Form.Half>
            </Form>
          </Section>
        </div>

        <div className="form-footer">
          <Button>Anulează</Button>
          <Button type="primary">Aplică</Button>
        </div>
      </ProfileLayout>
    );
  }
}

export default CompanyPage;
