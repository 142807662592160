import { lists } from '~/libs/http/api';

export const getSaveLimits = () => async dispatch => {
  try {
    const { data } = await lists.getListLimits();

    dispatch({
      type: 'SAVE_LISTS',
      payload: data,
    });
  } catch (error) {
    getSaveLimits();
  }
};

export const setLoading = () => dispatch => {
  dispatch({
    type: 'SET_LOADING',
  });
};
