import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Radio, message } from 'antd';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useStateHandlers } from '~/hooks';
import { injectIntl } from '~/libs/localization';
import { getRoute } from '~/libs/router';
import { exportings } from '~/libs/http/api';
import { Modal, Form } from '~/components/ui';
import { Icon } from '~/components/ui/Icon/Icon';

import '~/assets/scss/components/ui/_SaveResults.scss';

const DownloadReport = ({ t, name, id, ...props }) => {
  const history = useHistory();
  const [state, setState] = useStateHandlers({
    errors: [],
    loading: false,
    name: '',
    method: '',
    cartLoading: false,
  });

  useEffect(() => {
    if (state.method) {
      onSubmit();
    }
  }, [state.method]);

  const setLoadingState = () => setState({ loading: !state.loading });
  const setMethodState = method => setState({ method });

  const onDownloadPDF = async () => {
    try {
      await exportings.filters({
        name: `${t('company.export_pdf')} ${t('statics.company')} ${name || 'N/A'}`,
        export_type: 'company_detail_pdf',
        id,
      });
      message.success(t('company.added_success'));
      history.push(getRoute('DownloadsPage', { page: 1, type: 'list' }));
    } catch (err) {
      message.error(t('other.error_module'));
    }
  };

  const onSubmit = (ev = {}) => {
    ev.preventDefault && ev.preventDefault();

    setLoadingState();

    if (state.method === 'pdf') {
      onDownloadPDF();
    } else if (state.method === 'print') {
      props.onClose();

      setTimeout(() => window.print(), 200);
    } else if (state.method === 'export') {
      props.onExport();
      props.onClose();
    }
  };

  return (
    <>
      <Modal
        clean
        className="modal-bordered-header modal-nofooter"
        title={t('company.download_list')}
        loading={state.loading}
        onClose={props.onClose}
      >
        <Form>
          <Form.Item>
            <div className="saved-lists export-option">
              <Radio.Group onChange={({ target }) => setMethodState(target.value)} defaultValue={state.method}>
                {/* <Radio value="export">
                  <div className="saved-lists-item disabled">
                    <div className="circle">
                      <Icon type="report-csv" />
                    </div>

                    <h2>{t('company.download_xls')}</h2>
                  </div>
                </Radio> */}

                <Radio value="pdf">
                  <div className="saved-lists-item disabled">
                    <div className="circle">
                      <Icon type="report-pdf" />
                    </div>

                    <h2>{t('company.download_pdf')}</h2>
                  </div>
                </Radio>

                <Radio value="print">
                  <div className="saved-lists-item">
                    <div className="circle">
                      <Icon type="report-print" />
                    </div>

                    <h2>{t('company.print')}</h2>
                  </div>
                </Radio>
              </Radio.Group>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

const enhance = compose(
  injectIntl,
  connect(({ filter }) => ({
    activeFilter: filter.activeFilter,
  })),
);

export default enhance(DownloadReport);
