import { adapterIntl } from '~/libs/localization/helpers';

const contact = adapterIntl({
  'contact.phone': {
    ro: 'Telefon',
    ru: 'Телефон',
    en: 'Phone',
  },
  'contact.phone_mobile': {
    ro: 'Telefon mobil',
    ru: 'Мобильный телефон',
    en: 'Mobile phone',
  },
  'contact.fax': {
    ro: 'Fax',
    ru: 'Факс',
    en: 'Fax',
  },
  'contact.website': {
    ro: 'Website',
    ru: 'Сайт',
    en: 'Website',
  },
});

export default contact;
