import { message } from 'antd';
import { company } from '~/libs/http/api';

export const getCompany = id => async dispatch => {
  try {
    const { data } = await company.details({ id });

    dispatch({
      type: 'GET_COMPANY',
      payload: {
        id: data.id,
        data,
      },
    });
  } catch (error) {
    message.error("We're sorry! The company is not found by your request");
  }
};

export function setCompanyLoading(payload) {
  return {
    type: 'SET_COMPANY_LOADING',
    payload,
  };
}

export const resetCompany = () => ({
  type: 'RESET_COMPANY',
});

export const resetAvailability = () => ({
  type: 'RESET_AVAILABILITY',
});
