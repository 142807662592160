import { adapterIntl } from '~/libs/localization/helpers';

const management = adapterIntl({
  'management.users': {
    ro: 'Utilizatori',
    ru: 'Пользователи',
    en: 'Users',
  },
  'management.manager': {
    ro: 'Manager',
    ru: 'Менеджер',
    en: 'Manager',
  },
  'management.expired': { ro: 'Expiră la', ru: 'Истек в', en: 'Expired at' },
  'management.N': { ro: 'N', ru: 'Н', en: 'N' },
  'management.accountStatus': { ro: 'Starea contului', ru: 'Статус аккаунта', en: 'Account status' },
  'management.approvedStatus': { ro: 'Starea confirmării', ru: 'Статус подтверждения', en: 'Confirmation status' },
  'management.accountType': { ro: 'Tip de cont', ru: 'Тип аккаунта', en: 'Account Type' },
  'management.activated': { ro: 'Activat', ru: 'Активированный', en: 'Activated' },
  'management.suspented': { ro: 'Suspendat', ru: 'Приостановленный', en: 'Suspented' },
  'management.settings': { ro: 'Setări', ru: 'Настройки', en: 'Settings' },
  'management.accountUsers': { ro: 'Utilizatorii contului', ru: 'Пользователи аккаунта', en: 'Users of account' },
  'management.activity': { ro: 'Activitate', ru: 'Деятельность', en: 'Activity' },
  'management.lastActivity': { ro: 'Ultima activitate', ru: 'Последняя активность', en: 'Last activity' },
  'management.role': { ro: 'Rol', ru: 'Роль', en: 'Role' },
  'management.sender': { ro: 'Expeditor', ru: 'Отправитель', en: 'Sender' },
  'management.everyone': { ro: 'Toţi', ru: 'Все', en: 'Everyone' },
  'management.recipient': { ro: 'Destinatar', ru: 'Получатель', en: 'Recipient' },
  'management.sentDate': { ro: 'Data trimiterii', ru: 'Дата отправки', en: 'Sent date' },
  'management.admin': { ro: 'Administrator site', ru: 'Администратор сайта', en: 'Site admin' },
  'management.results': { ro: 'Rezultate', ru: 'Результаты', en: 'Results' },
  'management.title': { ro: 'Titlu', ru: 'Название', en: 'Title' },
  'management.request_demo': { ro: 'Cerere demo', ru: 'Запрос на презентацию', en: 'Request a demo' },
  'management.private': { ro: 'Privat', ru: 'Приватный', en: 'Private' },
  'management.public': { ro: 'Public', ru: 'Публичный', en: 'Public' },
  'management.create_account_user': { ro: 'Crearea utilizatorului', ru: 'Создание пользователя', en: 'Create user' },
  'management.update_account_user': {
    ro: 'Editarea utilizatorului',
    ru: 'Обновление пользователя',
    en: 'Update user',
  },
  'management.create_product': { ro: 'Adăugarea abonamentului', ru: 'Создание абонемента', en: 'Create subscription' },
  'management.update_product': {
    ro: 'Editarea abonamentului',
    ru: 'Обновление абонемента',
    en: 'Update subscription',
  },
  'management.enter_price': { ro: 'Introduceți prețul', ru: 'Введите цену', en: 'Enter price' },
  'management.select_frequency': { ro: 'Selectați frecvența', ru: 'Выберите частоту', en: 'Select frequency' },

  'management.frequency.never': { ro: 'Niciodată', ru: 'Никогда', en: 'Never' },
  'management.frequency.day': { ro: 'Zilnic', ru: 'Ежедневно', en: 'Daily' },
  'management.frequency.week': { ro: 'Săptămânăl', ru: 'Еженедельно', en: 'Weekly' },
  'management.frequency.month': { ro: 'Lunar', ru: 'Ежемесячно', en: 'Monthly' },
  'management.frequency.year': { ro: 'Anual', ru: 'Ежегодно', en: 'Annually' },

  'management.permissions': { ro: 'Permisiuni', ru: 'Разрешения', en: 'Permissions' },
  'management.permissions.error': {
    ro:
      'Permisiunea trebuie să conțină limite zilnice* (primul câmp), opțional limite lunare (al doilea câmp) și opțional câmpul VAS (al treilea câmp)',
    ru:
      'Разрешение должно содержать дневные лимиты* (первое поле), опционально месячные лимиты (второе поле) и опциональное поле VAS (третье поле)  ',
    en:
      'The permission has to contain Daily limits* (first field), optional Monthly limits (second field) and optional VAS field (third field)',
  },
  'management.permissions.guide': {
    ro: '<div>1. Limite zilnice*</div> <div>2. Limite lunare (opțional)</div> <div>3. Câmpul VAS (opțional)</div>',
    ru:
      '<div>1. Дневные лимиты*</div> <div>2. Месячные лимиты (опционально)</div> <div>3. Поле VAS (опционально)</div>',
    en: '<div>1. Daily limits*</div> <div>2. Monthly limits (optional)</div> <div>3. VAS field (optional)</div>',
  },

  'management.template': {
    ro: 'Șablon de abonament',
    ru: 'Шаблон абонемента',
    en: 'Subscription template',
  },
  'management.select_template_subscription': {
    ro: 'Alegeți șablon de abonament',
    ru: 'Выберите шаблон абонемента',
    en: 'Select subscription template',
  },
  'management.interval': {
    ro: 'Interval',
    ru: 'Интервал',
    en: 'Interval',
  },
  'management.user': {
    ro: 'Utilizator',
    ru: 'Пользователь',
    en: 'User',
  },
  'management.oneMonth': {
    ro: 'O lună',
    ru: 'Один месяц',
    en: 'One month',
  },
  'management.month': {
    ro: 'lună',
    ru: 'месяц',
    en: 'month',
  },
  'management.select': {
    ro: 'alege',
    ru: 'выбрать',
    en: 'choose',
  },
  'management.oneYear': {
    ro: 'Un an',
    ru: 'Один год',
    en: 'One year',
  },
  'management.today': {
    ro: 'Azi',
    ru: 'Сегодня',
    en: 'Today',
  },
  'management.tomorrow': {
    ro: 'Mâine',
    ru: 'Завтра',
    en: 'Tomorrow',
  },
  'management.yesterday': {
    ro: 'Ieri',
    ru: 'Вчера',
    en: 'Yesterday',
  },
  'management.monthAgo': {
    ro: 'Acum o lună',
    ru: 'Месяц назад',
    en: 'Month ago',
  },
  'management.nextMonth': {
    ro: 'Luna viitoare',
    ru: 'Следующий месяц',
    en: 'Next month',
  },
  date_from: {
    ro: 'De la',
    ru: 'С',
    en: 'From',
  },
  date_to: {
    ro: 'Pînă la',
    ru: 'До',
    en: 'To',
  },
  'management.requests': {
    ro: 'Cereri',
    ru: 'Запросы',
    en: 'Requests',
  },
  'management.metrics': {
    ro: 'Metricile',
    ru: 'Метрики',
    en: 'Metrics',
  },
  'management.key': {
    ro: 'Cheia',
    ru: 'Ключ',
    en: 'Key',
  },
  'management.api_created_by': {
    ro: 'Creator',
    ru: 'Создатель',
    en: 'Created by',
  },
  'management.create_auth_key': {
    ro: 'Generează',
    ru: 'Генерировать',
    en: 'Generate',
  },
  'management.delete_auth_key': {
    ro: 'Șterge',
    ru: 'Удалить',
    en: 'Remove',
  },
  'management.created_data': {
    ro: 'Data creării',
    ru: 'Дата создания',
    en: 'Creation date',
  },
  'management.api_key': {
    ro: 'Cheia API',
    ru: 'АПИ Ключ',
    en: 'API Key',
  },
  'management.invoice': {
    ro: 'Factura',
    ru: 'Накладная',
    en: 'Invoice',
  },
  'management.approve': {
    ro: 'Aprobă',
    ru: 'Подтвердить',
    en: 'Approve',
  },
  'management.export_name': {
    ro: 'Denumirea exportului',
    ru: 'Название экспорта',
    en: 'Export Name',
  },
  'management.invoice_nr': {
    ro: 'Număr de facturare',
    ru: 'Номер накладной',
    en: 'Billing Number',
  },
  'management.permissions.title': {
    ro: 'Acest cont nu are abonament, doriți să adăugați?',
    ru: 'Данный аккаунт не имеет абонемента, желатете добавить?',
    en: "This account doesn't have subscription, would you like to add one?",
  },
  'management.permissions.button': {
    ro: 'Adaugă',
    ru: 'Добавить',
    en: 'Add',
  },
});

export default management;
