import React from 'react';
import { ButtonsGroup } from '~/components/ui';
import { Header } from '~/components/header';
import { Footer } from '~/components/footer';

const Layout = ({ containerClass = '', type = '', rootClass = '', children }) => (
  <>
    <Header />
    <div className={`root ${rootClass}`}>
      <div
        className={`container ${
          type !== '' && type !== 'prospect' ? 'container-has-buttons-group' : ''
        } ${containerClass}`}
      >
        {children}
      </div>

      <ButtonsGroup type={type} />

      <Footer />
    </div>
  </>
);

export default Layout;
