import React from 'react';

const List = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19px" height="12px">
    <path d="M16.471,2.571 L16.471,0.855 C16.471,0.388 16.136,-0.000 15.721,-0.000 C15.304,-0.000 14.972,0.383 14.972,0.855 L14.972,2.571 L13.470,2.571 C13.062,2.571 12.722,2.955 12.722,3.429 C12.722,3.905 13.057,4.286 13.470,4.286 L14.972,4.286 L14.972,6.003 C14.972,6.469 15.307,6.857 15.721,6.857 C16.139,6.857 16.471,6.474 16.471,6.003 L16.471,4.286 L17.973,4.286 C18.381,4.286 18.721,3.902 18.721,3.429 C18.721,2.952 18.386,2.571 17.973,2.571 L16.471,2.571 ZM3.725,0.857 C3.725,0.384 4.066,-0.000 4.471,-0.000 L10.476,-0.000 C10.888,-0.000 11.223,0.380 11.223,0.857 C11.223,1.331 10.881,1.714 10.476,1.714 L4.471,1.714 C4.059,1.714 3.725,1.334 3.725,0.857 ZM3.725,6.000 C3.725,5.527 4.066,5.143 4.471,5.143 L10.476,5.143 C10.888,5.143 11.223,5.523 11.223,6.000 C11.223,6.473 10.881,6.857 10.476,6.857 L4.471,6.857 C4.059,6.857 3.725,6.477 3.725,6.000 ZM3.725,11.143 C3.725,10.669 4.066,10.286 4.471,10.286 L10.476,10.286 C10.888,10.286 11.223,10.666 11.223,11.143 C11.223,11.616 10.881,12.000 10.476,12.000 L4.471,12.000 C4.059,12.000 3.725,11.620 3.725,11.143 ZM0.725,0.857 C0.725,0.384 1.058,-0.000 1.475,-0.000 C1.889,-0.000 2.225,0.380 2.225,0.857 C2.225,1.331 1.892,1.714 1.475,1.714 C1.061,1.714 0.725,1.334 0.725,0.857 ZM0.725,6.000 C0.725,5.527 1.058,5.143 1.475,5.143 C1.889,5.143 2.225,5.523 2.225,6.000 C2.225,6.473 1.892,6.857 1.475,6.857 C1.061,6.857 0.725,6.477 0.725,6.000 ZM0.725,11.143 C0.725,10.669 1.058,10.286 1.475,10.286 C1.889,10.286 2.225,10.666 2.225,11.143 C2.225,11.616 1.892,12.000 1.475,12.000 C1.061,12.000 0.725,11.620 0.725,11.143 Z" />
  </svg>
);

List.Empty = ({ label }) => (
  <div className="no-results-container">
    <div className="no-results">
      <svg width="13" height="18" viewBox="0 0 13 18">
        <path
          fill="#547998"
          d="M11.832,18.000 L1.979,18.000 C1.386,18.000 0.904,17.512 0.904,16.912 L0.904,3.637 C0.904,3.000 1.386,2.512 2.016,2.512 L3.497,2.512 C3.757,2.137 4.201,1.912 4.683,1.912 L5.016,1.912 C5.201,1.912 5.350,1.762 5.350,1.575 C5.350,0.712 6.053,-0.000 6.905,-0.000 C7.757,-0.000 8.461,0.712 8.498,1.575 C8.498,1.762 8.646,1.912 8.832,1.912 L9.165,1.912 C9.647,1.912 10.054,2.137 10.350,2.512 L11.832,2.512 C12.425,2.512 12.906,3.000 12.906,3.637 L12.906,16.912 C12.906,17.512 12.425,18.000 11.832,18.000 ZM4.489,5.574 L2.897,5.574 L2.897,7.168 L4.489,7.168 L4.489,5.574 ZM4.489,8.382 L2.897,8.382 L2.897,9.976 L4.489,9.976 L4.489,8.382 ZM4.489,11.190 L2.897,11.190 L2.897,12.784 L4.489,12.784 L4.489,11.190 ZM4.489,13.960 L2.897,13.960 L2.897,15.554 L4.489,15.554 L4.489,13.960 ZM6.905,0.712 C6.498,0.712 6.202,1.050 6.202,1.425 C6.202,1.800 6.498,2.137 6.905,2.137 C7.276,2.137 7.609,1.837 7.609,1.425 C7.609,1.050 7.313,0.712 6.905,0.712 ZM10.783,6.029 L5.779,6.029 C5.589,6.029 5.399,6.181 5.399,6.409 C5.399,6.636 5.551,6.788 5.779,6.788 L10.783,6.788 C10.973,6.788 11.162,6.636 11.162,6.409 C11.162,6.181 11.011,6.029 10.783,6.029 ZM10.783,8.799 L5.779,8.799 C5.589,8.799 5.399,8.951 5.399,9.179 C5.399,9.406 5.551,9.558 5.779,9.558 L10.783,9.558 C10.973,9.558 11.162,9.406 11.162,9.179 C11.162,8.951 10.973,8.799 10.783,8.799 ZM10.783,11.607 L5.779,11.607 C5.589,11.607 5.399,11.759 5.399,11.987 C5.399,12.177 5.551,12.366 5.779,12.366 L10.783,12.366 C10.973,12.366 11.162,12.214 11.162,11.987 C11.124,11.759 10.973,11.607 10.783,11.607 ZM10.783,14.377 L5.779,14.377 C5.589,14.377 5.399,14.529 5.399,14.757 C5.399,14.985 5.551,15.136 5.779,15.136 L10.783,15.136 C10.973,15.136 11.162,14.985 11.162,14.757 C11.162,14.529 10.973,14.377 10.783,14.377 Z"
        />
      </svg>
    </div>
    {label && <h3>{label}</h3>}
  </div>
);

export default List;
