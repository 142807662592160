import axios from '~/libs/http/axios';

const { baseURL } = axios.defaults;

const events = {
  get: (params = {}) => axios.get(`${baseURL}/api/notification/address`, { params }),
  add: (option = {}) => axios.post(`${baseURL}/api/notification/address`, option),
  list: (params = {}) => axios.get(`${baseURL}/api/notification/address/list`, { params }),
  delete: (params = {}) => axios.delete(`${baseURL}/api/notification/address`, { params }),
  edit: (option = {}) => axios.patch(`${baseURL}/api/notification/address`, option),
};

export default events;
