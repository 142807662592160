import React from 'react';
import { ProfileLayout } from '~/components/layouts';
import { BillingHistory, BillingSummary } from '~/components/billing';
import { useDocumentTitle } from '~/hooks';
import { injectIntl } from '~/libs/localization';

const BillingPage = ({ t }) => {
  useDocumentTitle(t('page.billing'));

  return (
    <ProfileLayout>
      <BillingSummary />

      <BillingHistory />
    </ProfileLayout>
  );
};

export default injectIntl(BillingPage);
