import { adapterIntl } from '~/libs/localization/helpers';

const team = adapterIntl({
  'team.success': {
    ro: 'Ai adăugat un nou membru în echipa ta.',
    ru: 'Вы добавили нового участника в свою команду',
    en: "You've added a new member to your team",
  },
  'team.upload_photo': {
    ro: 'Încărcați fotografia',
    ru: 'Загрузите фотографию',
    en: 'Upload photo',
  },
  'team.upload_a_photo': {
    ro: 'Încărcați o imagine',
    ru: 'Загрузить фотографию',
    en: 'Upload a photo',
  },
  'team.delete_photo': {
    ro: 'Ștergeți imaginea',
    ru: 'Удалить фотографию',
    en: 'Delete photo',
  },
  'team.license': {
    ro: 'Licență',
    ru: 'Лицензия',
    en: 'License',
  },
  'team.users': {
    ro: 'Utilizatori',
    ru: 'Пользователи',
    en: 'Users',
  },
  'team.price-per-user': {
    ro: 'Preț pentru un utilizator',
    ru: 'Стоимость за одного пользователя',
    en: 'Price per one user',
  },
  'team.final-price': {
    ro: 'Preț total',
    ru: 'Финальная стоимость',
    en: 'Total price',
  },
  'team.buy': {
    ro: 'Cumpară',
    ru: 'Купить',
    en: 'Buy',
  },
  'team.add-to-cart': {
    ro: 'Adaugă în coș',
    ru: 'Добавить в корзину',
    en: 'Add to cart',
  },
});

export default team;
