import React from 'react';
import { compose } from 'redux';
import withCompany from '~/hoc/withCompany';
import { setDocumentTitle } from '~/hooks';
import { has } from '~/libs/object';
import { injectIntl } from '~/libs/localization';
import { CompanyLayout } from '~/components/layouts';
import { Segment, Info, Publication } from '~/components/company';
import { Loader, Empty, Table } from '~/components/ui';
import { Time } from '~/components/other';

class InsolvencyPage extends React.Component {
  state = {
    showMore: {},
  };

  componentDidMount() {
    setDocumentTitle(this.props.t('page.insolvency'));
  }

  setShowMore(key) {
    const { showMore } = Object.assign({}, this.state);
    showMore[key] = showMore[key] !== undefined ? !showMore[key] : true;
    this.setState({ showMore });
  }

  render() {
    const api = this.props.company.data;
    const { t } = this.props;

    const columns = [
      {
        title: t({ id: 'juridical.nameParts' }),
        render: (_, data1, key) => (
          <a href="/" target="_blank">
            {has(data1, 'title', <Empty />)}
          </a>
        ),
        width: 100,
      },
      {
        title: t({ id: 'juridical.quality' }),
        render: (item, data2, key) => <span>{has(data2, 'participant_role.title', <Empty />)}</span>,
        width: 100,
      },
      {
        title: t({ id: 'juridical.adminAuthData' }),
        render: (item, data3, key) => <span>{has(data3, 'participant_admin.title', <Empty />)}</span>,
        width: 100,
      },
    ];

    const columns2 = [
      {
        title: t({ id: 'juridical.judge' }),
        render: (_, data1, key) => <span>{has(data1, 'judge.title', <Empty />)}</span>,
        width: 100,
      },
      {
        title: t({ id: 'juridical.dataJudgment' }),
        render: (item, data2, key) => (
          <span>
            <Time date={data2.date} format="dateTime" />
          </span>
        ),
        width: 100,
      },
      {
        title: t({ id: 'juridical.officeJudgment' }),
        render: (item, data3, key) => <span>{has(data3, 'address.title', <Empty />)}</span>,
        width: 100,
      },
      {
        title: t({ id: 'juridical.judgmentResult' }),
        render: (item, data3, key) => (
          <span>
            <Empty />
          </span>
        ),
        width: 100,
      },
    ];

    return (
      <CompanyLayout juridical>
        <Loader loading={this.props.loading}>
          {has(api, 'juridical.insolvency', []).map((insolvency, index) =>
            has(insolvency, 'steps', []).map(step => (
              <React.Fragment key={step.id}>
                <Segment
                  title={step.number}
                  titleDesc={t({ id: 'juridical.infoGen' })}
                  textAlign="center"
                  borderBlue
                  grayContent
                  hasData
                  onClose={() => this.setShowMore(index)}
                  isOpen={this.state.showMore[index] ? this.state.showMore[index] : false}
                  number={`${(index + 1).toLocaleString('en-US', { minimumIntegerDigits: 2 })}`}
                >
                  <Info.Halves>
                    <Info label={t({ id: 'juridical.numFile' })}>
                      <Info.Detail title={step.number} />
                    </Info>

                    <Info label={t({ id: 'juridical.instanceName' })}>
                      <Info.Detail title={has(step, 'instance.title', <Empty />)} />
                    </Info>

                    <Info label={t({ id: 'juridical.panelJudge' })}>
                      <Info.Detail title={<Empty />} textBlue />
                    </Info>

                    <Info label={t({ id: 'juridical.instanceAddress' })}>
                      <Info.Detail title={has(step, 'instance.address.title', <Empty />)} textBlue />
                    </Info>
                  </Info.Halves>

                  <Info.Halves>
                    <Info label={t({ id: 'juridical.subject' })}>
                      <Info.Detail title={has(step, 'subject.title', <Empty />)} />
                    </Info>

                    <Info label={t({ id: 'juridical.subjectLevel' })}>
                      <Info.Detail title={has(step, 'level.title', <Empty />)} />
                    </Info>

                    <Info label={t({ id: 'juridical.juridicalType' })}>
                      <Info.Detail title={has(insolvency, 'process_type.title', <Empty />)} textBlue />
                    </Info>

                    <Info />
                  </Info.Halves>
                </Segment>

                {this.state.showMore[index] && (
                  <React.Fragment>
                    <Segment title={t({ id: 'juridical.processParts' })} textAlign="center" noPadding>
                      <Table
                        className="table-layout-fixed"
                        columns={columns}
                        dataSource={has(step, 'process_participants', [])}
                      />
                    </Segment>

                    {has(step, 'judicial_process_sessions.length') ? (
                      <Segment title={t({ id: 'juridical.insolvency' })} textAlign="center" noPadding>
                        <Table
                          className="table-layout-fixed"
                          columns={columns2}
                          dataSource={has(step, 'judicial_process_sessions', [])}
                        />
                      </Segment>
                    ) : null}

                    <Segment title={t({ id: 'juridical.judgmentSolution' })} textAlign="center" borderBlue grayContent>
                      <Info.Halves>
                        <Info label={t({ id: 'juridical.fondType' })}>
                          <Info.Detail
                            title="Hotărîre privind constatarea insolvabilității debitorului și intentarea procesului de insolvabilitate"
                            textBlue
                          />
                        </Info>
                        <Info label={t({ id: 'juridical.fondDate' })}>
                          <Info.Detail title="21.11.2016" textBlue />
                        </Info>
                        <Info label={t({ id: 'juridical.fondSolution' })}>
                          <Info.Detail title="Da" textBlue />
                        </Info>
                      </Info.Halves>

                      <Info.Halves>
                        <Info label={t({ id: 'juridical.fondSolutionDesc' })}>
                          <Info.Detail title="1. Se admite cererea introductivă depusă de creditorul „Varo - Inform” SRL față de debitorul ÎCS „Tirex - Petrol” SA." />
                          <Info.Detail title="2. Se constată insolvabilitatea debitorului și se intentează procedura de insolvabilitate față de debitorul ÎCS „Tirex - Petrol” SA., c/f XXXXXXXXX, cu sediul în mun. Chișinău, str. Mitr. Varlaam, 65, of. 423, Republica Moldova." />
                          <Info.Detail title="3. Se înlătură administrația debitorului ÎCS „Tirex - Petrol” SA., de la gestionarea patrimoniului debitorului și se obligă să transmită în cel mult 10 zile lucrătoare administratorului insolvabilității Alex Sasdafgvdv: gestiunea patrimoniului, documentele de constituire a societății cu toate procesele-verbale ale adunărilor" />
                        </Info>
                      </Info.Halves>
                    </Segment>

                    {has(step, 'judicial_process_monitor_official.length') ? (
                      <Segment title={t({ id: 'juridical.publication' })} noPadding borderBlue>
                        <Publication.Section>
                          {step.judicial_process_monitor_official.map(publication => (
                            <Publication
                              key={publication.id}
                              to="/"
                              createdAt={publication.date}
                              title={has(publication, 'text.title', <Empty />)}
                              description={has(publication, 'type.title', <Empty />)}
                            />
                          ))}
                        </Publication.Section>
                      </Segment>
                    ) : null}
                  </React.Fragment>
                )}

                <Segment.More onChange={() => this.setShowMore(index)} open={this.state.showMore[index]} />
              </React.Fragment>
            )),
          )}
        </Loader>
      </CompanyLayout>
    );
  }
}

const enhance = compose(injectIntl, withCompany());

export default enhance(InsolvencyPage);
