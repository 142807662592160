import React from 'react';
import { useInjectIntl } from '~/libs/localization';
import { useDocumentTitle } from '~/hooks';
import { useEmployee } from '~/hoc/withEmployee';
import { has } from '~/libs/object';
import { Link } from '~/libs/router';
import { CompanyLayout } from '~/components/layouts';
import { Segment, Info } from '~/components/company';
import { Loader, Empty, Rows, Table } from '~/components/ui';
import { Time } from '~/components/other';
import { Icon } from '~/components/ui/Icon/Icon';

const ExecutivePage = () => {
  const { t } = useInjectIntl();
  const { employee: { data: api, loading } } = useEmployee(); // prettier-ignore

  useDocumentTitle(t('page.executive'));

  const columns = [
    {
      className: 'collapsing',
      title: t({ id: 'other.data' }),
      dataIndex: 'date',
      render: date => <Time date={date} format="long" />,
    },
    {
      title: t({ id: 'other.gen' }),
      dataIndex: 'event_name',
      render: event => event,
    },
    {
      title: t({ id: 'other.type' }),
      dataIndex: 'group.title',
      render: groupTitle => groupTitle,
    },
    {
      title: t('company.formModification'),
      dataIndex: 'type',
      render: type => <span className="sbold">{type}</span>,
    },
    {
      title: t({ id: 'other.description' }),
      dataIndex: 'description',
      render: description => (
        <span
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      ),
    },
  ];

  return (
    <CompanyLayout type="employee">
      <Loader loading={loading}>
        <Rows
          rows={[
            {
              label: t({ id: 'company.company' }),
              value: has(api, 'company_name') ? (
                <Link route="GeneralsPage" params={{ id: api.company_id }}>
                  {api.company_name}
                </Link>
              ) : (
                undefined
              ),
            },
            {
              label: t({ id: 'profile.level' }),
              value: has(api, 'seniority.title'),
            },
            {
              label: t({ id: 'company.fullName' }),
              value: api.name,
            },
            {
              label: t({ id: 'company.shareSize' }),
              value: `${api.share_value || 0}%`,
            },
          ]}
        />

        <Segment title="Angajat" classTitle="title-no-border">
          <Info.Halves>
            <Info label={t({ id: 'profile.job' })}>
              <Info.Detail title={has(api, 'function.title')} />
            </Info>

            <Info label={t({ id: 'other.contacts' })}>
              <Info.Detail icon={<Icon type="company-telephone" />} title={has(api, 'phone.number')} />
            </Info>

            <Info label={t({ id: 'other.website' })}>
              <Info.Detail
                icon={<Icon type="company-website" />}
                title={api.websites ? api.websites.join(', ') : undefined}
                websites={api.websites}
                textBlue
              />
            </Info>
          </Info.Halves>

          <Info label={t({ id: 'profile.level' })}>
            <Info.Detail title={has(api, 'seniority.title', <Empty />)} />
          </Info>

          <Info.Halves>
            <Info label={t({ id: 'other.email' })}>
              <Info.Detail icon={<Icon type="company-mail" />} title={has(api, 'email')} textBlue />
            </Info>

            <Info label={t({ id: 'other.social' })} className="social-media">
              <Info.Media name="facebook" to={has(api, 'general_data.contact_info.facebook')} />
              <Info.Media name="linkedin" to={has(api, 'general_data.contact_info.linkedin')} />
              <Info.Media name="twitter" to={has(api, 'general_data.contact_info.twitter')} />
              <Info.Media name="youtube" to={has(api, 'general_data.contact_info.youtube')} />
            </Info>
          </Info.Halves>
        </Segment>
        {has(api, 'history') ? (
          <Segment title={`${t({ id: 'company.activityEmloyeeInfo' })} (${api.history.length})`} noPadding noBorder>
            <Table columns={columns} dataSource={api.history} />
          </Segment>
        ) : null}
      </Loader>
    </CompanyLayout>
  );
};

export default ExecutivePage;
