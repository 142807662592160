import React, { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { changeCurrentFilter } from '~/store/actions/FilterActions';
import { useInjectIntl } from '~/libs/localization';
import { Truncate } from '~/components/ui';
import { Icon } from '~/components/ui/Icon/Icon';

export const FilterItems = () => {
  const dispatch = useDispatch();
  const store = useSelector(({ filter }) => ({ filter: filter.filter }), shallowEqual);
  const { t } = useInjectIntl();

  const onClickCurrentFilter = useCallback(id => dispatch(changeCurrentFilter(id)), []);

  return (
    <div data-testid="filter-items" className="filter-items-wrapper">
      {store.filter.map(item => {
        const name = t(item.name);

        return (
          <div
            key={item.name}
            className={`filter-filter ${item.open ? 'active' : ''}`}
            onClick={() => onClickCurrentFilter(item.id)}
            role="presentation"
          >
            <Icon type={`menu-filter-${item.id}`} />

            <Truncate title={name}>{name}</Truncate>
          </div>
        );
      })}
    </div>
  );
};
