import React, { useState, useEffect } from 'react';
import { getLogotypeLink, getAvatarLink } from '~/libs/string';

const Logo = ({ website, name = '', ...props }) => {
  const [img, setImg] = useState('');

  useEffect(() => {
    const newImg = website ? getLogotypeLink(website) : props.img ? getAvatarLink(props.img) : '';
    setImg(newImg);
  }, []);

  return (
    <div className="logo-company-wrap">
      <div className="logo-company">
        {img ? <img src={img} alt="crushed" /> : <span>{name ? name[0].toUpperCase() : ''}</span>}
      </div>
    </div>
  );
};

export default Logo;
