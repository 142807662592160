import React, { useState } from 'react';
import { Button, Menu, Popover } from 'antd';
import { Link } from '~/libs/router';
import { Icon } from '~/components/ui/Icon/Icon';
import { Loader, EmptyBlock } from '~/components/ui';
import { Layout } from '~/components/layouts';

const ProfileLayout = ({ loading, empty, children, className = '', type }) => {
  const [visibleGuide, setVisibleGuide] = useState(false);

  const onHandleVisibleGuide = visible => setVisibleGuide(visible);

  return (
    <Layout type={type} containerClass={`profile-container ${className}`}>
      <div className="content">
        {loading && <Loader loading />}

        {empty && <EmptyBlock />}

        <div className="fade-in" style={{ display: loading || empty ? 'none' : 'block' }}>
          {children}
        </div>
      </div>

      <div className="user-guide">
        <Popover
          overlayClassName="guide-popover"
          placement="topRight"
          title={
            <div className="guide-title">
              <Icon type="user-guide-small" /> Manualul utilizatorului
            </div>
          }
          visible={visibleGuide}
          onVisibleChange={onHandleVisibleGuide}
          content={
            <Menu className="guide-menu">
              <Menu.Item>
                <Link route="HomePage">It always seems impossible until it’s done</Link>
              </Menu.Item>
              <Menu.Item>
                <Link route="HomePage">Education needs to work by pull, not push.</Link>
              </Menu.Item>
              <Menu.Item>
                <Link route="HomePage">The way to get started is to quit talking and start doing.</Link>
              </Menu.Item>
              <Menu.Item>
                <Link route="HomePage">Old age ain’t no place for sissies.</Link>
              </Menu.Item>
              <Menu.Item>
                <Link route="HomePage">I drink to make other people more interesting.</Link>
              </Menu.Item>
              <Menu.Item>
                <Link route="HomePage">It always seems impossible until it’s done</Link>
              </Menu.Item>
              <Menu.Item>
                <Link route="HomePage">Education needs to work by pull, not push.</Link>
              </Menu.Item>
              <Menu.Item>
                <Link route="HomePage">The way to get started is to quit talking and start doing.</Link>
              </Menu.Item>
              <Menu.Item>
                <Link route="HomePage">Old age ain’t no place for sissies.</Link>
              </Menu.Item>
              <Menu.Item>
                <Link route="HomePage">I drink to make other people more interesting.</Link>
              </Menu.Item>
            </Menu>
          }
          trigger="click"
        >
          <Button>{!visibleGuide ? <Icon type="user-guide" /> : <Icon type="guide-close" />}</Button>
        </Popover>
      </div>
    </Layout>
  );
};

export default ProfileLayout;
