import React, { useMemo } from 'react';
import { Menu } from 'antd';
import { Link } from '~/libs/router';
import { useSelector, shallowEqual } from 'react-redux';
import { useInjectIntl } from '~/libs/localization';
import { Icon } from '~/components/ui/Icon/Icon';

import '~/assets/scss/components/_Sidebar.scss';

const SidebarMessages = ({ data, mode }) => {
  const { t } = useInjectIntl();
  const store = useSelector(
    ({ authentication }) => ({
      permissions: authentication.permissions,
      authenticationParams: authentication.params,
    }),
    shallowEqual,
  );

  const messageQuantity = useMemo(() => (data.unread + data.read !== 0 ? data.unread + data.read : false), [
    mode,
    data.unread,
    data.read,
  ]);

  const unreadMessages = useMemo(() => (data.unread !== 0 ? data.unread : false), [mode, data.unread]);

  return (
    <div className="sidebar">
      <Menu className="messages-menu" mode="inline" defaultOpenKeys={['sub1']}>
        <Menu.SubMenu
          key="sub1"
          title={
            <>
              <Icon type="task-list-approve" />
              {t('alerts.allMessages')}
              {!messageQuantity < 1 && messageQuantity && <span className="tag">{messageQuantity}</span>}
            </>
          }
        >
          {/* <Menu.Item>
            <Link route="Alerts" params={{ filter: 'all', mode: 'list' }}>
              <Icon type="unread-list" />
              {t('alerts.allMessages')}
            </Link>
          </Menu.Item> */}
          <Menu.Item>
            <Link route="Alerts" params={{ filter: 'unread', mode: 'list' }}>
              <Icon type="unread-list" />
              {t('alerts.unread')}
              {unreadMessages && (
                <>
                  <span className="tag2">{unreadMessages}</span>{' '}
                  <span className="arrow-right">
                    <Icon type="arrow-right-way" />
                  </span>
                </>
              )}
            </Link>
          </Menu.Item>
          <Menu.Item>
            <Link route="Alerts" params={{ filter: 'read', mode: 'list' }}>
              <Icon type="read-list" />
              {t('alerts.read')}
            </Link>
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.Item>
          <Link route="Alerts" params={{ filter: 'energy', mode: 'list' }}>
            <Icon type="electricity" />
            {t('alerts.electricity')}
          </Link>
        </Menu.Item>

        <Menu.Item>
          <Link route="Alerts" params={{ filter: 'water', mode: 'list' }}>
            <Icon type="water" />
            {t('alerts.waterAndSewage')}
          </Link>
        </Menu.Item>

        <Menu.Item>
          <Link route="Alerts" params={{ filter: 'control', mode: 'list' }}>
            <Icon type="control-list" />
            {t('alerts.controls')}
          </Link>
        </Menu.Item>

        {store.permissions.includes('alert_addresses') && (
          <Menu.Item>
            <Link route="Alerts" params={{ filter: 'adresses', mode: 'settings' }}>
              <Icon type="alert-settings" />
              {t('alerts.alertSettings')}
            </Link>
          </Menu.Item>
        )}
      </Menu>
    </div>
  );
};

export default SidebarMessages;
