import React from 'react';
import { SkeletonLayout } from '.';

const ProfileLoader = () => (
  <SkeletonLayout>
    <div className="skeleton-tabs">
      {[...Array(6).keys()].map(item => (
        <div className="skeleton" key={item} />
      ))}
    </div>
  </SkeletonLayout>
);

export default ProfileLoader;
