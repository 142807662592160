import React, { useEffect, useState } from 'react';
import { Divider, message } from 'antd';
import { compose } from 'redux';
import { withRequest } from '~/hoc';
import { injectIntl } from '~/libs/localization';
import { Loader } from '~/components/ui';
import { updates } from '~/libs/http/api';
import { Time } from '~/components/other';
import { useDocumentTitle } from '~/hooks';

const UpdatesContent = ({ id, request, t, ...props }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [content, setContent] = useState();
  const [loading, setLoading] = useState(false);
  useDocumentTitle(t('page.updates'));

  const onFetchDetails = async () => {
    setLoading(true);
    try {
      const { data } = await updates.detailsPublic({ id });
      setContent(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      message.error(t('other.error_module'));
    }
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    onFetchDetails();
  }, [id]);

  return content ? (
    <div className="styled-article">
      <Loader loading={loading}>
        <h1>{content.title}</h1>

        <p style={{ fontSize: '14px', color: 'rgb(69, 80, 100)', lineHeight: 1.5 }}>
          <Time date={content.date_created} relative prefix />
        </p>

        <Divider />

        <div
          dangerouslySetInnerHTML={{
            __html: content.text,
          }}
        />
      </Loader>
    </div>
  ) : null;
};

const enhance = compose(withRequest(), injectIntl);

export default enhance(UpdatesContent);
