import React from 'react';
import { Button as OldButton } from '~/components/ui';

const Button = ({ className = '', text, ...props }) => {
  return (
    <OldButton {...props} className={`${className} button-public`}>
      {text}
    </OldButton>
  );
};

export default Button;
