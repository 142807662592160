import React, { useEffect } from 'react';
import { Button } from 'antd';
import { ProfileLayout } from '~/components/layouts';
import { injectIntl } from '~/libs/localization';
import { Orders } from '~/components/profile';
import { billing } from '~/libs/http/api';
import withRequest from '~/hoc/withRequest';
import { Link } from '~/libs/router';
import { has } from '~/libs/object';
import { useDocumentTitle } from '~/hooks';

import '~/assets/scss/components/_Billing.scss';

const OrderPage = ({ t, validate, match, ...props }) => {
  useDocumentTitle(t('page.order'));

  useEffect(() => {
    onFetch();
  }, []);

  const onFetch = () => props.request(billing.myCart());

  const onDelete = async ids => {
    await billing.delete({ ids });

    return onFetch();
  };

  return (
    <ProfileLayout>
      <Orders
        data={props.api.data}
        footer={
          Object.keys(has(props.api.data, 'summary', {})).length > 0 && (
            <Link route="OrderPaymentPage">
              <Button type="primary">{t('billing.pay')}</Button>
            </Link>
          )
        }
        loading={props.api.loading}
        onDelete={onDelete}
        isCheckable
        title={
          <div className="info">
            <div className="title">{t('profile.basket')}</div>
          </div>
        }
      />
    </ProfileLayout>
  );
};

export default withRequest()(injectIntl(OrderPage));
