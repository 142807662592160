import React from 'react';
import { Table as TableAnt } from 'antd';
import MediaQuery from 'react-responsive';
import responsive from '~/libs/responsive';
import { injectIntl } from '~/libs/localization';

import '~/assets/scss/components/ui/_Table2.scss';

const ConditionalWrapper = ({ condition, wrapperLabel, wrapperDiv, children }) =>
  condition ? wrapperLabel(children) : wrapperDiv(children);

const Table = props => {
  return (
    <div className="table-container">
      {props.dataSource && (
        <>
          <MediaQuery minWidth={props.maxWidth || responsive.smallDesktop}>
            <TableAnt {...props} pagination={false} />
          </MediaQuery>

          <MediaQuery maxWidth={props.maxWidth || responsive.smallDesktop}>
            <div className="responsive-table responsive-details-table details-table list-details-tooltip">
              <div className="result-list">
                {props?.title && props?.title()}

                {props.dataSource.map((item, key) => (
                  <ConditionalWrapper
                    key={key.toString()}
                    condition={props.conditionalWrapper}
                    wrapperLabel={children => (
                      <label
                        className={
                          props.selected && props.selected(item.id)
                            ? 'ant-checkbox-wrapper selected-item'
                            : 'ant-checkbox-wrapper'
                        }
                      >
                        {children}
                      </label>
                    )}
                    wrapperDiv={children => (
                      <div className="ant-checkbox-wrapper" key={item.id}>
                        {children}
                      </div>
                    )}
                  >
                    <>
                      <span className="item-id">{key + 1}</span>
                      {props.columns.map((column, keyColumn) => (
                        <div
                          key={keyColumn.toString()}
                          className={
                            column.dataIndex !== 'id'
                              ? `item-info ${column.className}`
                              : `item-info item-checkbox-wrap ${column.className}`
                          }
                        >
                          <div className="info-wrapper">
                            <div className="label">{column.title}</div>
                            <div className="info-content">
                              {column.render !== undefined
                                ? column.render(item[column.dataIndex], item)
                                : item[column.dataIndex]}
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  </ConditionalWrapper>
                ))}

                {props?.footer && props.footer()}
              </div>
            </div>
          </MediaQuery>
        </>
      )}
    </div>
  );
};

export default injectIntl(Table);
