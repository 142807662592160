import React from 'react';
import { Table } from 'antd';
import { Counter, LoaderBlock } from '~/components/ui';
import { Segment } from '~/components/company';
import { useInjectIntl } from '~/libs/localization';

const MonthActivity = ({ info, loading }) => {
  const { t } = useInjectIntl();

  const columns = [
    {
      dataIndex: 'name',
      render: text => <b>{text}</b>,
    },
    {
      dataIndex: 'count',
      width: 200,
      align: 'right',
      render: count => (
        <b className="dashboard-bold">{typeof count === 'number' ? <Counter total={count} /> : 'N/A'}</b>
      ),
    },
  ];

  const data =
    'fields' in info
      ? Object.keys(info.fields).map(key => ({
          key,
          name: key,
          description: info.fields[key],
          count: info.fields[key].all,
        }))
      : [];

  return (
    <Segment title={t('home.informationAvailable')} subTitle={t('home.entityType')} noPadding>
      <LoaderBlock height={348} loading={loading}>
        <Table
          className="dashboard-month-activity"
          expandedRowRender={({ description = {} }) =>
            Object.keys(description)
              .filter(key => !['all'].includes(key))
              .map(key => (
                <div key={key} className="dashboard-month-activity-desc">
                  <span>{key}</span>

                  <span>{typeof description[key] === 'number' ? <Counter total={description[key]} /> : 'N/A'}</span>
                </div>
              ))
          }
          columns={columns}
          dataSource={data}
          showHeader={false}
          pagination={false}
        />
      </LoaderBlock>
    </Segment>
  );
};

export default MonthActivity;
