import React from 'react';

export default props => (
  <div
    style={{ background: props.black ? 'rgba(0, 0, 0, 0.2)' : null }}
    className="with-mask"
    onClick={() => props.onClose()}
    role="presentation"
  />
);
