import React, { useEffect } from 'react';
import { Button } from 'antd';
import { useRequest } from 'estafette';
import { managementSupport } from '~/libs/http/api';
import { injectIntl } from '~/libs/localization';
import { Drawer, Time } from '~/components/other';
import { Loader, Status, Empty, Confirmation } from '~/components/ui';
import NotFoundPage from '~/pages/NotFoundPage';

const formatDateTime = 'YYYY-MM-DD HH:mm';

const SupportDrawer = ({ id, onClose, t, onChangeStatus, ...props }) => {
  const { request, data, loading } = useRequest();

  useEffect(() => {
    request(managementSupport.details({ id }));
  }, [id]);

  return data ? (
    <Drawer onClose={onClose} placement="right" width={720}>
      <Loader loading={loading}>
        <div className="drawer-form">
          <div className="form-part">
            <div className="title">{t('juridical.infoGen')}</div>

            <div className="form-info">
              <div>
                <span className="info-label">{t('management.sender')}</span>
                <span className="info-desc">{data.sender || <Empty />}</span>
              </div>

              <div>
                <span className="info-label">{t('management.recipient')}</span>
                <span className="info-desc">{data.receiver || <Empty />}</span>
              </div>

              <div>
                <span className="info-label">{t('other.date')}</span>
                <span className="info-desc">
                  {data.date_created ? <Time date={data.date_created} format={formatDateTime} /> : <Empty />}
                </span>
              </div>

              <div>
                <span className="info-label">{t('other.status')}</span>
                <span className="info-desc">
                  {data.status_message ? (
                    <Status
                      status={data.status_message === 'deleted' ? 'inactive' : 'active'}
                      statusText={data.status_message}
                    />
                  ) : (
                    <Empty />
                  )}
                </span>
              </div>
            </div>
          </div>

          <div className="form-part">
            <div className="title mb-0">{data.title || <Empty />}</div>
          </div>

          <div className="form-part">
            <div className="form-message">{data.text || <Empty />}</div>
          </div>

          <div className="form-footer">
            {data.status_message !== 'trash' && (
              <Confirmation type="delete" onConfirm={() => onChangeStatus(data.id, 'deleted')}>
                <Button type="danger" loading={data.status_message === 'trash' && loading}>
                  {t('alerts.move_trash')}
                </Button>
              </Confirmation>
            )}

            {data.status_message === 'trash' || data.status_message === 'unread' ? (
              <Button
                type="primary"
                onClick={() => onChangeStatus([data.id], 'read')}
                loading={data.status_message === 'read' && loading}
              >
                {t('other.read')}
              </Button>
            ) : null}

            {data.status_message === 'trash' || data.status_message === 'read' ? (
              <Button
                type="primary"
                onClick={() => onChangeStatus([data.id], 'unread')}
                loading={data.status_message === 'unread' && loading}
              >
                {t('other.unread')}
              </Button>
            ) : null}
          </div>
        </div>
      </Loader>
    </Drawer>
  ) : (
    <NotFoundPage />
  );
};

export default injectIntl(SupportDrawer);
