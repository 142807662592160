import axios from '~/libs/http/axios';

const { baseURL } = axios.defaults;

const managementApi = {
  list: (params = {}) => axios.get(`${baseURL}/api/management/auth-key`, { params }),
  create: (params = {}) => axios.post(`${baseURL}/api/management/auth-key`, params),
  delete: (params = {}) => axios.delete(`${baseURL}/api/management/auth-key`, { params }),
  accountKey: (params = {}) => axios.get(`${baseURL}/api/management/auth-key-user`, { params }),
};

export default managementApi;
