import React from 'react';
import { Select } from 'antd';
import { useInjectIntl } from '~/libs/localization';

export const SelectIdno = ({ onChange, onSelect, loading, data, disabled = false }) => {
  const { t } = useInjectIntl();

  return (
    <Select
      data-testid="select-idno"
      showSearch
      allowClear
      mode="combobox"
      filterOption={false}
      onChange={onChange}
      onSelect={onSelect}
      disabled={disabled}
      placeholder={t('signup.selectCompany')}
    >
      {loading && (
        <Select.Option value={`${t('signup.loading')} ...`} disabled>
          {t('signup.loading')} ...
        </Select.Option>
      )}

      {!loading &&
        data.map(item => (
          <Select.Option value={`${item.idno}`} title={`${item.name} - ${item.idno}`} key={item.idno}>
            {item.name} - {item.idno}
          </Select.Option>
        ))}
    </Select>
  );
};
