import axios from '~/libs/http/axios';

const { baseURL } = axios.defaults;

const feedback = {
  send: (option = {}) => axios.post(`${baseURL}/api/profile/feedback/message`, option),
  list: (params = {}) => axios.get(`${baseURL}/api/management/support/feedback/list`, { params }),
  details: (params = {}) => axios.get(`${baseURL}/api/management/support/feedback/detail`, { params }),
  support: (option = {}) => axios.post(`${baseURL}/api/public/support/form-send`, option),
};

export default feedback;
