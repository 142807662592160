import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useStateHandlers } from '~/hooks';
import { truncate } from '~/libs/string';
import { getRoute } from '~/libs/router';
import { number } from '~/libs/number';
import { parseDate } from '~/libs/date';
import { openFilterInner, openFilter } from '~/store/actions/FilterActions';
import { Tag } from '~/components/ui';
import { Icon } from '~/components/ui/Icon/Icon';

export const getPlaceholder = (key, arr) => {
  const { placeholder } = arr.find(item => item.key === key) || {};

  return placeholder || '';
};

export const getTreeValues = (values, item) => {
  const selected = [];

  values.includes(item.id) && selected.push(item.title);

  if (item.children && item.children.length) {
    item.children.map(element => selected.push(...getTreeValues(values, element)));
  }

  return selected;
};

export const formatValues = values => `${truncate(values.join(', '), 35)}`;

export const FilterTag = ({ filter, filterKey, filterData, t, listDetails, ...props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = useStateHandlers({
    title: '',
    values: [],
    filterParent: 0,
  });

  useEffect(() => {
    const onGetTags = () => {
      if (filterKey !== 'list_id') {
        filter.map((parent, parentKey) =>
          parent.children.map(item => {
            if (item.name === filterKey) {
              setState({ title: item.title, filterParent: parentKey });

              if (item.type === 'dates') {
                const localValues = [];

                Object.keys(filterData)
                  .map(element => element)
                  .map(element =>
                    localValues.push(
                      `${t(getPlaceholder(element, item.children))} ${parseDate(filterData[element]) ||
                        'is not specified'}`,
                    ),
                  );

                setState({ values: localValues });
              }

              if (item.type === 'numbers' || item.type === 'percentages' || item.type === 'turnover') {
                const localValues = [];

                Object.keys(filterData)
                  .map(element => element)
                  .map(element =>
                    localValues.push(
                      `${t(getPlaceholder(element, item.children))} ${number(filterData[element]) ||
                        'is not specified'}`,
                    ),
                  );

                setState({ values: localValues });
              }

              if (item.type === 'radiobox') {
                const { title: itemTitle, localization } = item.children.find(({ key }) => filterData === key);

                setState({ values: [itemTitle || t(localization)] });
              }

              if (item.type === 'input-search' || item.type === 'input') {
                setState({ values: [filterData] });
              }

              if (item.type === 'checkbox') {
                if (filterData.includes('select-all')) {
                  setState({
                    values: [
                      filterData.length > 1
                        ? `${t('other.selected-all-except', { elements: filterData.length - 1 })} ${t(
                            `other.selected-all-except-element${filterData.length !== 2 ? 's' : ''}`,
                          )}`
                        : t('other.selected-all'),
                    ],
                  });
                } else {
                  setState({
                    values: item.children
                      .filter(({ key }) => filterData.includes(key))
                      .map(({ title, localization }) => title || t(localization)),
                  });
                }
              }

              if (item.type === 'tree' || item.type === 'tree-popup') {
                if (filterData.includes('select-all')) {
                  setState({
                    values: [
                      filterData.length > 1
                        ? `${t('other.selected-all-except', { elements: filterData.length - 1 })} ${t(
                            `other.selected-all-except-element${filterData.length !== 2 ? 's' : ''}`,
                          )}`
                        : t('other.selected-all'),
                    ],
                  });
                } else {
                  const localValues = [];

                  item.children.map(element => localValues.push(...getTreeValues(filterData, element)));

                  setState({ values: localValues });
                }
              }
            }

            return false;
          }),
        );
      } else {
        setState({
          title: 'menu.saved-lists',
          values: listDetails.name ? [listDetails.name] : [],
        });
      }
    };

    onGetTags();
  }, [filterData, filterKey, listDetails]);

  const onDelete = async () => {
    await props.deleteTag(filterKey);

    if (filterKey === 'list_id') {
      return history.push(getRoute('SavedListsPage', { page: 1 }));
    }

    return true;
  };

  const onOpenFilter = () => {
    dispatch(openFilter());
    dispatch(openFilterInner(false));

    props.changeSubFilter(state.filterParent, filterKey);
    filter.forEach((item, parentKey) => {
      item.open = parentKey === state.filterParent;
      item.children.forEach(val => {
        val.open = filterKey === val.name;
      });
    });
  };

  return (
    <Tag
      type={state.title ? t(state.title) : null}
      name={
        state.values.length > 0 ? (
          formatValues(state.values)
        ) : (
          <span>
            Loading
            <Icon type="loading" />
          </span>
        )
      }
      onDelete={onDelete}
      onSelect={onOpenFilter}
    />
  );
};
