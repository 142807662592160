import { adapterCombiner } from '~/libs/localization/helpers';
import other from './other';
import home from './home';
import searcher from './searcher';
import list from './list';
import notification from './notification';
import searches from './searches';
import profile from './profile';
import company from './company';
import statics from './statics';
import filter from './filter';
import alerts from './alerts';
import metrics from './metrics';
import team from './team';
import updates from './updates';
import pages from './pages';
import permissions from './permissions';
import management from './management';
import signup from './signup';
import footer from './footer';
import search from './search';
import monitoring from './monitoring';
import legalBulletin from './legalBulletin';
import prospectList from './prospectList';
import contact from './contact';
import prospect from './prospect';
import bluredData from './bluredData';
import unavailableData from './unavailableData';
import homeHistory from './homeHistory';

export const localization = adapterCombiner({
  homeHistory,
  unavailableData,
  bluredData,
  prospect,
  prospectList,
  other,
  home,
  notification,
  searcher,
  list,
  searches,
  profile,
  company,
  statics,
  filter,
  alerts,
  metrics,
  team,
  updates,
  pages,
  permissions,
  management,
  signup,
  footer,
  search,
  monitoring,
  legalBulletin,
  contact,
});

export default localization;
