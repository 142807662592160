import React from 'react';
import { Spin } from 'antd';
import { Icon } from '~/components/ui/Icon/Icon';

class Section extends React.PureComponent {
  constructor(props) {
    super(props);

    this.timer = {};

    this.state = {
      loading: false,
    };
  }

  componentWillMount() {
    if (this.props.lazy && this.props.loading === true) {
      throw new Error('Props lazy === props Loading');
    } else if (this.props.lazy) {
      this.setState({ loading: true });

      const time = typeof this.props.lazy === 'number' ? this.props.lazy : 1000;
      this.timer = setTimeout(() => {
        this.setState({ loading: false });
      }, time);
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  render() {
    return (
      <Spin
        spinning={this.props.loading || this.state.loading || false}
        indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
        className={`spin-default clear-fix ${this.props.blur ? 'spin-blur' : ''}`}
      >
        <section style={{ minHeight: this.props.height || 'auto' }}>{this.props.children}</section>
      </Spin>
    );
  }
}

export default Section;
