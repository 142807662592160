import { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import cookies from 'react-cookies';
import env from '@beam-australia/react-env';

let socket = {};
let interval;

const socketURL = env('WEBSOCKET_URL');

export const useNotificationSocket = () => {
  const { isAuth } = useSelector(({ authentication }) => authentication.isAuth);

  const [data, setData] = useState(null);
  const [status, setStatus] = useState('pending');
  const $status = useRef('pending');

  useEffect(() => {
    const onConnectSocket = () => {
      if (isAuth && socketURL) {
        setStatus('pending');

        socket = new WebSocket(`${socketURL}/notifications?Token=${cookies.load('token')}`);

        socket.onmessage = ({ data: $data }) => {
          if ($data) {
            const socketData: T = JSON.parse($data);

            setData(socketData);
          }
        };

        socket.onopen = () => {
          clearInterval(interval);

          setStatus('connected');
          $status.current = 'connected';

          console.log('[SOCKET] Informer Platform has been connected');
        };

        socket.onerror = err => {
          setStatus('disconnected');
          $status.current = 'disconnected';

          console.error(err);
        };

        socket.onclose = () => {
          console.log('[SOCKET] Informer Platform has been disconnected');

          setStatus('disconnected');
          $status.current = 'disconnected';

          if (typeof interval === 'undefined') {
            interval = setInterval(() => {
              onConnectSocket();
            }, 4000);
          }
        };
      }
    };

    onConnectSocket();

    return () => {
      if (socket.close) {
        socket.close();
      }

      clearInterval(interval);
    };
  }, [isAuth]);

  return {
    data,
    status,
  };
};
