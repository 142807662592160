import { message } from 'antd';
import { company } from '~/libs/http/api';

export const getSubdivision = id => async dispatch => {
  try {
    const { data } = await company.subdivision({ id });

    dispatch({
      type: 'GET_SUBDIVISION',
      payload: {
        id: data.id,
        data,
      },
    });
  } catch (error) {
    message.error("We're sorry! The subdivision wasn't found by your request");
  }
};

export function setSubdivisionLoading(payload) {
  return {
    type: 'SET_SUBDIVISION_LOADING',
    payload,
  };
}

export const resetSubdivision = () => ({
  type: 'RESET_SUBDIVISION',
});
