import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { setCompanyName } from '~/store/actions/FilterActions';
import { useInjectIntl } from '~/libs/localization';
import { company } from '~/libs/http/api';
import { indexed } from '~/libs/array';
import { getRoute, Link } from '~/libs/router';
import { Text } from '~/components/ui';
import { SearchItem } from '~/components/search';
import { Icon } from '~/components/ui/Icon/Icon';

import '~/assets/scss/components/_SearchBox.scss';

const SearchBox = ({ placeholder, helpNode, onSetValue, value }) => {
  const dispatch = useDispatch();
  const { t } = useInjectIntl();
  const [results, setResults] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState([]);
  const [total, setTotal] = useState(0);
  const history = useHistory();
  const store = useSelector(
    ({ companies, searches }) => ({
      companies,
      searches,
    }),
    shallowEqual,
  );

  useEffect(() => {
    setTotal(0);
  }, [store.searches.search_type]);

  const onSearch = (newValue = '') => {
    setSearchKeyword(newValue);
    onSetValue(newValue);

    return newValue && store.searches.search_type
      ? company.employees({ page: 1, per_page: 3, filters: { must: { employee_name: newValue } } }).then(({ data }) => {
          setResults(indexed(data.data));
          setTotal(data.total_results);
        })
      : newValue &&
          company.list({ page: 1, per_page: 3, filters: { must: { company_name: newValue } } }).then(({ data }) => {
            setResults(indexed(data.data));
            setTotal(data.total_results);
          });
  };

  const onSelect = async (id = '', option) => {
    const allResults = option.key === 'total-value-search';
    const { name: newValue } = results.find(item => item.id.toString() === id) || {};
    if (!id) {
      dispatch(setCompanyName(newValue || ''));
      onSetValue('');
    }

    if (!store.searches.search_type) {
      history.push(
        id && !allResults ? getRoute('GeneralsPage', { id }) : getRoute('Search', { page: 1, query: searchKeyword }),
      );
    } else {
      history.push(
        id && !allResults
          ? getRoute('ExecutivePage', { page: 1, id })
          : getRoute('Search', { page: 1, query: searchKeyword }),
      );
    }
  };

  return (
    <div className="search-box search-size-default">
      <Select
        allowClear={!helpNode}
        showSearch
        mode="combobox"
        filterOption={false}
        onSearch={onSearch}
        onSelect={onSelect}
        value={value}
        placeholder={t(placeholder)}
      >
        {value &&
          results.map(result => (
            <Select.Option key={`company-${result.id.toString()}`} value={result.id.toString()}>
              <SearchItem.Fragment company={result.name} activity={result.industry} />
            </Select.Option>
          ))}

        {total && (
          <Select.Option key="total-value-search" value={value} className="search-box-total">
            <SearchItem.Total key="total-value" value={total} />
          </Select.Option>
        )}

        {!total && value && (
          <Select.Option key="empty-value" value={value} disabled>
            <SearchItem.Empty title={<Text id="filter.emptySearch" />} />
          </Select.Option>
        )}
      </Select>

      {helpNode && (
        <Link route="HomePage" className="help-link">
          <Icon type="search-help" className="zh-icon icon-size-small" />
        </Link>
      )}

      <Icon type="menu-find" className="zh-icon icon-size-small zh-icon-search-billing" />
    </div>
  );
};

export default SearchBox;
