export const mapDataExport = arr => {
  const data = {};

  arr.forEach(item => {
    if (item.match('/')) {
      const key = item.replace(/\/[a-z0-9_]+/, '');

      !data[key] && (data[key] = []);
      data[key].push(item.replace(/[a-z0-9_]+\//, ''));
    }
  });

  return data;
};
