import { adapterIntl } from '~/libs/localization/helpers';

const searches = adapterIntl({
  'searches.newSearch': { ro: 'Căutare noua', ru: 'Новый поиск', en: 'New search' },
  'searches.myLists': { ro: 'Listele mele', ru: 'Мои списки', en: 'My Lists' },
  'searches.myDownloads': { ro: 'Descărcarile mele', ru: 'Мои загрузки', en: 'My downloads' },
  'searches.empty': { ro: 'Nu aveți nici o căutarea', ru: 'Ни одного поиска', en: 'Searches empty' },
  'searches.search': { ro: 'Caută', ru: 'Поиск', en: 'Search' },
  'searches.searching': { ro: 'Căutarea', ru: 'Поиск', en: 'Searching' },
  'searches.savedLists': { ro: 'Liste sălvate', ru: 'Сохраненные списки', en: 'Saved lists' },
  'searches.mySearches': { ro: 'Căutarile mele', ru: 'Мои поиски', en: 'My searches' },
  'searches.viewAll': { ro: 'Vizualizați toate', ru: 'Посмотреть все', en: 'View all' },
  'searches.savedListsDescription': {
    ro:
      'Listele te ajută să păstrezi companiile dorite organizate într-un singur loc. Toate Companiile sălvate le vei găsi aici.',
    ru: 'Списки помогают организовать нужные компании в одном месте. Здесь вы найдете все сохраненные компании.',
    en:
      'Lists help you keep your desired companies organized in one place. You will find all the Saved Companies here.',
  },
  'searches.searchesListsDescription': {
    ro:
      'În căutările tale poți salva filtrele care le foloseşti cel mai des. Salvează căutările pentru o accesare rapidă.',
    ru:
      'При поиске вы можете сохранить наиболее часто используемые фильтры. Сохраните поисковые запросы для быстрого доступа.',
    en: 'In your searches you can save the filters you use most often. Save searches for quick access.',
  },
  'searches.remove': { ro: 'Șterge', ru: 'Удалить', en: 'Remove' },
  'searches.unload': { ro: 'Descarcă', ru: 'Загрузить', en: 'Unload' },
  'searches.downloadDate': { ro: 'Data descărcării', ru: 'Дата загрузки', en: 'Download date' },
  'searches.downloadName': { ro: 'Denumire descărcare', ru: 'Название загрузки', en: 'Download name' },
  'searches.by': { ro: 'De către', ru: 'От', en: 'By' },
  'searches.downloadStatus': { ro: 'Statut descărcare', ru: 'Статус загрузки', en: 'Download status' },
  'searches.action': { ro: 'Acțiune', ru: 'Действие', en: 'Action' },
  'searches.aaa': { ro: 'Descarcă', ru: 'aaa', en: 'aaa' },
  'searches.feedback': { ro: 'Feedback', ru: 'Feedback', en: 'Feedback' },
});

export default searches;
