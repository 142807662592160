import React, { useState, useMemo } from 'react';
import { Select } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { setCompanyName } from '~/store/actions/FilterActions';
import { useInjectIntl } from '~/libs/localization';
import { company } from '~/libs/http/api';
import { indexed } from '~/libs/array';
import { getRoute, Link } from '~/libs/router';
import { Text } from '~/components/ui';
import { SearchItem } from '~/components/search';
import { Icon } from '~/components/ui/Icon/Icon';

import '~/assets/scss/components/_SearchBox.scss';

const SearchBox = ({ placeholder, helpNode }) => {
  const dispatch = useDispatch();
  const { t } = useInjectIntl();
  const history = useHistory();

  const store = useSelector(
    ({ authentication }) => ({
      permissions: authentication.permissions,
      authenticationParams: authentication.params,
    }),
    shallowEqual,
  );

  const [results, setResults] = useState([]);
  const [total, setTotal] = useState(0);
  const [value, setValue] = useState('');

  const searchBoxPermission = useMemo(() => {
    return (
      store.authenticationParams?.permission_types &&
      store.authenticationParams.permission_types.filter(item => item.id === 2).map(perm => perm.active)[0]
    );
  }, [store.authenticationParams.permission_types]);

  const onSearch = (newValue = '') => {
    setValue(newValue);

    return searchBoxPermission
      ? newValue &&
          company.companiesSearch({ page: 1, per_page: 3, name: newValue }).then(({ data }) => {
            setResults(indexed(data.data));
            setTotal(data.total_results);
          })
      : newValue &&
          company.companiesSearch({ page: 1, per_page: 200, name: newValue }).then(({ data }) => {
            setResults(indexed(data.data));
            setTotal(data.total_results);
          });
  };

  const onSelect = async (newValue = '') => {
    const { id } = results.find(item => item.name === newValue) || {};

    if (!id) {
      dispatch(setCompanyName(newValue || ''));
      setValue('');
    }

    history.push(
      id ? getRoute('GeneralsPage', { id }) : getRoute('SearchResultsPage', { type: 'company', mode: 'basic' }),
    );
  };

  return (
    <div className="search-box search-size-default">
      <Select
        allowClear={!helpNode}
        showSearch
        mode="combobox"
        filterOption={false}
        onSearch={onSearch}
        onSelect={onSelect}
        value={value}
        placeholder={t(placeholder)}
      >
        {value &&
          results.map(result => (
            <Select.Option
              key={`company-${result.id.toString()}`}
              value={result.name}
              disabled={!store.permissions.includes('general_data_tab')}
            >
              <SearchItem.Fragment company={result.name} activity={result.industry} />
            </Select.Option>
          ))}

        {total && (
          <Select.Option
            key="total-value-search"
            value={value}
            className="search-box-total"
            disabled={!searchBoxPermission}
          >
            <SearchItem.Total key="total-value" value={total} />
          </Select.Option>
        )}

        {!total && value && (
          <Select.Option key="empty-value" value={value} disabled>
            <SearchItem.Empty title={<Text id="filter.emptySearch" />} />
          </Select.Option>
        )}
      </Select>

      {helpNode && (
        <Link route="HomePage" className="help-link">
          <Icon type="search-help" className="zh-icon icon-size-small" />
        </Link>
      )}

      <Icon type="menu-find" className="zh-icon icon-size-small zh-icon-search-billing" />
    </div>
  );
};

export default SearchBox;
