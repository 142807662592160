import cookies from 'react-cookies';

const initialState = {
  available: [
    {
      name: 'Română',
      shortName: 'ro',
    },
    {
      name: 'English',
      shortName: 'en',
    },
    {
      name: 'Русский',
      shortName: 'ru',
    },
  ],
  current: {},
  loading: true,
};

if (!cookies.load('localization')) {
  cookies.save('localization', 'ro', { path: '/' });
}

const current = cookies.load('localization');

initialState.current =
  initialState.available.filter(({ shortName }) => shortName === current)[0] || initialState.available[0];

export const localization = (state = initialState, action) => {
  switch (action.type) {
    case 'CHANGE_LOCALIZATION': {
      cookies.save('localization', action.payload, { path: '/' });

      return Object.assign({}, state, {
        current: action.payload ? state.available.filter(language => language.shortName === action.payload)[0] : null,
      });
    }
    case 'SET_LOCALIZATION_LOADING': {
      return Object.assign({}, state, { loading: action.payload });
    }
    default:
      return state;
  }
};
