import axios from '~/libs/http/axios';

const { baseURL } = axios.defaults;

const managementAccounts = {
  add: (option = {}) => axios.post(`${baseURL}/api/management/account/detail`, option),
  delete: (params = {}) => axios.delete(`${baseURL}/api/management/account/detail`, { params }),
  edit: (option = {}) => axios.patch(`${baseURL}/api/management/account/detail`, option),
  getAccountById: (params = {}) => axios.get(`${baseURL}/api/management/account/detail`, { params }),
  list: (params = {}) => axios.get(`${baseURL}/api/management/account/list`, { params }),
  managers: (params = {}) => axios.get(`${baseURL}/api/management/support/managers`, { params }),
  product: (params = {}) => axios.get(`${baseURL}/api/management/account/product`, { params }),
  addProduct: (option = {}) => axios.post(`${baseURL}/api/management/account/product`, option),
  editProduct: (option = {}, id, productId) =>
    axios.patch(
      `${baseURL}/api/management/account/product?account_id=${id}${productId ? `&product_id=${productId}` : ''}`,
      option,
    ),
  getCompanyAbonament: (params = {}) => axios.get(`${baseURL}/api/management/account/permission`, { params }),
};

export default managementAccounts;
