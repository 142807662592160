import React from 'react';
import { Button } from 'antd';
import { injectIntl } from '~/libs/localization';
import { Icon } from '~/components/ui/Icon/Icon';
import { Link } from '~/libs/router';

const Packages = ({ t, ...props }) => {
  return (
    <div className="packages-area">
      <div className="column heading-column">
        <div className="heading">
          <div className="title">{t('packages.packagesAvailable')}</div>
        </div>

        <div className="cell headinng-cell">
          <span className="circle-icon">
            <Icon type="search" />
          </span>
          {t('packages.searchView')}
          <Link route="HomePage" className="info-link">
            <Icon type="info" className="info-icon" />
          </Link>
        </div>

        <div className="cell headinng-cell">
          <span className="circle-icon">
            <Icon type="view-1" />
          </span>
          {t('packages.views')}
          <Link route="HomePage" className="info-link">
            <Icon type="info" className="info-icon" />
          </Link>
        </div>

        <div className="cell headinng-cell">
          <span className="circle-icon">
            <Icon type="megaphone-1" />
          </span>
          {t('packages.alerts')}
          <Link route="HomePage" className="info-link">
            <Icon type="info" className="info-icon" />
          </Link>
        </div>

        <div className="cell headinng-cell">
          <span className="circle-icon">
            <Icon type="menu-monitoring" />
          </span>
          {t('packages.monitoring')}
          <Link route="HomePage" className="info-link">
            <Icon type="info" className="info-icon" />
          </Link>
        </div>

        <div className="cell headinng-cell">
          <span className="circle-icon">
            <Icon type="menu-filter" />
          </span>
          {t('packages.use')}
          <Link route="HomePage" className="info-link">
            <Icon type="info" className="info-icon" />
          </Link>
        </div>

        <div className="cell headinng-cell">
          <span className="circle-icon">
            <Icon type="man" />
          </span>
          {t('packages.exploration')}
          <Link route="HomePage" className="info-link">
            <Icon type="info" className="info-icon" />
          </Link>
        </div>

        <div className="cell headinng-cell">
          <span className="circle-icon">
            <Icon type="tool-box" />
          </span>
          {t('packages.exportCompanies')}
          <Link route="HomePage" className="info-link">
            <Icon type="info" className="info-icon" />
          </Link>
        </div>

        <div className="cell headinng-cell">
          <span className="circle-icon">
            <Icon type="menu-legal-bulletin" />
          </span>
          {t('packages.legalBulletin')}
          <Link route="HomePage" className="info-link">
            <Icon type="info" className="info-icon" />
          </Link>
        </div>
      </div>

      <div className="column column-primary">
        <div className="heading">
          <div className="sticker">{t('profile.myPackage')}</div>
          <div className="plan">{t('packages.plan')}</div>
          <div className="title">{t('packages.primary')}</div>
          <div className="price">{t('packages.free')}</div>
        </div>

        <div className="cell">
          <div className="responsive-title">{t('packages.searchView')}</div>
          25 {t('packages.profiles')} <span className="tag">{t('packages.perDay')}</span>
        </div>

        <div className="cell">
          <div className="responsive-title">{t('packages.views')}</div>
          <span className="circle-icon green">
            <Icon type="package-check" />
          </span>
        </div>

        <div className="cell">
          <div className="responsive-title">{t('packages.alerts')}</div>1 {t('packages.address')}
        </div>

        <div className="cell">
          <div className="responsive-title">{t('packages.monitoring')}</div>10 {t('packages.companies')}
        </div>

        <div className="cell">
          <div className="responsive-title">{t('packages.use')}</div>1
        </div>

        <div className="cell">
          <div className="responsive-title">{t('packages.exploration')}</div>
          <span className="circle-icon red">
            <Icon type="package-close" />
          </span>
        </div>

        <div className="cell">
          <div className="responsive-title">{t('packages.exportCompanies')}</div>
          <span className="circle-icon red">
            <Icon type="package-close" />
          </span>
        </div>

        <div className="cell">
          <div className="responsive-title">{t('packages.legalBulletin')}</div>
          <span className="circle-icon red">
            <Icon type="package-close" />
          </span>
        </div>

        <div className="cell">
          <Button className="active">
            <span className="circle-icon green">
              <Icon type="package-check" />
            </span>
            {t('packages.activePackage')}
          </Button>
        </div>
      </div>

      <div className="column column-business">
        <div className="heading">
          <div className="sticker">{t('packages.theMostPopular')}</div>
          <div className="plan">{t('packages.plan')}</div>
          <div className="title">{t('packages.business')}</div>
          <div className="price">
            <span className="num">
              <sup>€</sup> 29
            </span>
            <span className="per">/ {t('packages.month')}</span>
            <div className="description">{t('packages.annualPayment')}</div>
          </div>
        </div>

        <div className="cell">
          <div className="responsive-title">{t('packages.searchView')}</div>
          100 {t('packages.profiles')} <span className="tag">{t('packages.perDay')}</span>
        </div>

        <div className="cell">
          <div className="responsive-title">{t('packages.views')}</div>
          <span className="circle-icon green">
            <Icon type="package-check" />
          </span>
        </div>

        <div className="cell">
          <div className="responsive-title">{t('packages.alerts')}</div>3 {t('packages.address')}
        </div>

        <div className="cell">
          <div className="responsive-title">{t('packages.monitoring')}</div>100 {t('packages.companies')}
        </div>

        <div className="cell">
          <div className="responsive-title">{t('packages.use')}</div>3
        </div>

        <div className="cell">
          <div className="responsive-title">{t('packages.exploration')}</div>
          <span className="circle-icon green">
            <Icon type="package-check" />
          </span>
        </div>

        <div className="cell">
          <div className="responsive-title">{t('packages.exportCompanies')}</div>200
        </div>

        <div className="cell">
          <div className="responsive-title">{t('packages.legalBulletin')}</div>
          {t('packages.yourIndustry')}
        </div>

        <div className="cell">
          <Button>
            <span className="circle-icon green">
              <Icon type="package-check" />
            </span>
            {t('packages.purchaseNow')}
          </Button>
        </div>
      </div>

      <div className="column column-enterprise">
        <div className="heading">
          <div className="sticker">{t('packages.negotiable')}</div>
          <div className="plan">{t('packages.plan')}</div>
          <div className="title">{t('packages.enterprise')}</div>
          <div className="price">
            <span className="num">
              <sup>€</sup> 200
            </span>
            <span className="per">/ {t('packages.month')}</span>
            <div className="description">{t('packages.annualPayment')}</div>
          </div>
        </div>

        <div className="cell">
          <div className="responsive-title">{t('packages.searchView')}</div>
          {t('packages.unlimited')}
        </div>

        <div className="cell">
          <div className="responsive-title">{t('packages.views')}</div>
          <span className="circle-icon green">
            <Icon type="package-check" />
          </span>
        </div>

        <div className="cell">
          <div className="responsive-title">{t('packages.alerts')}</div>50 {t('packages.address')}
        </div>

        <div className="cell">
          <div className="responsive-title">{t('packages.monitoring')}</div>
          {t('packages.unlimited')}
        </div>

        <div className="cell">
          <div className="responsive-title">{t('packages.use')}</div>
          {t('packages.unlimited')}
        </div>

        <div className="cell">
          <div className="responsive-title">{t('packages.exploration')}</div>
          <span className="circle-icon green">
            <Icon type="package-check" />
          </span>
        </div>

        <div className="cell">
          <div className="responsive-title">{t('packages.exportCompanies')}</div>5000
        </div>

        <div className="cell">
          <div className="responsive-title">{t('packages.legalBulletin')}</div>
          {t('packages.allIndustries')}
        </div>

        <div className="cell">
          <Button>
            <span className="circle-icon green">
              <Icon type="package-check" />
            </span>
            {t('packages.purchaseNow')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(Packages);
