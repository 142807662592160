import React from 'react';
import { injectIntl } from '~/libs/localization';
import { message } from 'antd';
import { getRoute, Link } from '~/libs/router';
import { auth } from '~/libs/http/api';
import { setDocumentTitle } from '~/hooks';
import { Header, Footer, Input, Button } from '~/components/public';
import { Auth, Form } from '~/components/ui';

class RecoveryPage extends React.PureComponent {
  state = {
    email: '',
    errors: {},
    loading: false,
  };

  componentDidMount() {
    setDocumentTitle(this.props.t('page.recovery'));
  }

  submit(e) {
    e.preventDefault();

    this.setState({ loading: true });

    auth
      .resetPassword({
        email: this.state.email,
        redirect_url: getRoute('ResetPage'),
      })
      .then(() => {
        message.success(`${this.props.t({ id: 'statics.successResetPassword' })} ${this.state.email}`);

        return this.props.history.push(getRoute('SigninPage'));
      })
      .catch(({ response }) => this.setState({ loading: false, errors: response.data }));
  }

  render() {
    const { t } = this.props;

    return [
      <Header />,
      <Auth.Container
        wide
        key="container"
        loading={this.state.loading}
        header={
          <div>
            <h1 className="auth-wide-h1">{t({ id: 'statics.recoveryPassword' })}</h1>
          </div>
        }
        // footer={<Link route="HomePage">{t({ id: 'statics.needHelp' })}</Link>}
      >
        <Form onSubmit={e => this.submit(e)}>
          <Form.Error message={this.state.errors.non_field_errors} />

          <Form.Item
            label={t({ id: 'other.email' })}
            {...(this.state.errors.email ? { validateStatus: 'error', help: this.state.errors.email } : null)}
          >
            <Input
              type="text"
              placeholder={t({ id: 'other.email' })}
              onChange={e => this.setState({ email: e.target.value })}
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" text={t({ id: 'statics.resetPassword' })} />
          </Form.Item>

          <Form.Item>
            <div className="reg-now">
              <Link route="SigninPage" className="reg-now__link">
                {t('statics.back_sign_in')}
              </Link>
            </div>
          </Form.Item>
        </Form>
      </Auth.Container>,
      <Footer />,
    ];
  }
}

export default injectIntl(RecoveryPage);
