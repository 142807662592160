import React from 'react';
import { injectIntl } from '~/libs/localization';
import { has } from '~/libs/object';
import { Empty } from '~/components/ui';
import { JuridicalCard } from '~/components/company/juridical';
import { Time } from '~/components/other';

const JudicialProcessSessions = ({ t, judicialProcessSessions }) => (
  <div className="juridical-schedule-block juridical-content">
    {judicialProcessSessions.length
      ? judicialProcessSessions.map(process => (
          <div className="juridical-schedule active" key={process.id}>
            <div className="juridical-schedule-time">
              <span>
                {t({ id: 'juridical.time' })} <Time date={process.date} format="hour" />
              </span>
            </div>

            <JuridicalCard.Section type="rows">
              <JuridicalCard type="label-status-thin">
                <h2>{t({ id: 'juridical.officeJudgment' })}</h2>
                <p>{has(process, 'address.title', <Empty />)}</p>
              </JuridicalCard>

              <JuridicalCard type="label-status-thin">
                <h2>{t({ id: 'juridical.judge' })}</h2>
                <p>{has(process, 'judge.title', <Empty />)}</p>
              </JuridicalCard>

              <JuridicalCard type="label-status-thin">
                <h2>{t({ id: 'juridical.judgmentResult' })}</h2>
                <p>{has(process, 'solution.title', <Empty />)}</p>
              </JuridicalCard>
            </JuridicalCard.Section>
          </div>
        ))
      : null}
  </div>
);

export default injectIntl(JudicialProcessSessions);
