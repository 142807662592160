import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { MenuFilter } from '~/components/svg';
import { FilterChild } from '~/components/filter';

const Icon = ({ type }) => {
  const Svg = MenuFilter[type];

  return <Svg />;
};

export const Filter = () => {
  const store = useSelector(({ filter }) => ({ filter: filter.filter, showFilter: filter.showFilter }), shallowEqual);

  return (
    <>
      <div className="filter-closed" style={{ transform: !store.showFilter ? 'translateX(0)' : 'translateX(-100%)' }}>
        <ul data-testid="filter-children">
          {store.filter.map(item => (
            <li key={item.name} role="presentation">
              <Icon type={item.icon} />
            </li>
          ))}
        </ul>
      </div>

      {store.showFilter && <FilterChild />}
    </>
  );
};
