import { message } from 'antd';
import { company } from '~/libs/http/api';

export const getCompanies = ({ page, perPage, name }) => async dispatch => {
  try {
    const { data } = await company.companiesSearch({ page, per_page: perPage, name });
    dispatch({
      type: 'GET_COMPANIES',
      payload: {
        data,
        loading: false,
      },
    });
  } catch (error) {
    console.error(error);
    message.error("We're sorry! The cmopanies wasn't found by your request");
  }
};

export const searchPageList = ({ page, perPage, name }) => async dispatch => {
  try {
    const { data } = await company.list({
      page: parseFloat(page),
      per_page: perPage,
      filters: { must: { company_name: name } },
    });
    dispatch({
      type: 'GET_SEARCH_LIST',
      payload: {
        data,
        loading: false,
      },
    });
  } catch (error) {
    console.error(error);
    message.error("We're sorry! The cmopanies wasn't found by your request");
  }
};

export const searchPageListEmployee = ({ page, perPage, name }) => async dispatch => {
  try {
    const { data } = await company.employees({
      page: parseFloat(page),
      per_page: perPage,
      filters: { must: { employee_name: name } },
    });
    dispatch({
      type: 'GET_SEARCH_LIST_EMPLOYEE',
      payload: {
        data,
        loading: false,
      },
    });
  } catch (error) {
    console.error(error);
    message.error("We're sorry! The cmopanies wasn't found by your request");
  }
};

export const setCompaniesPerPage = payload => async dispatch => {
  dispatch({
    type: 'SET_COMPANIES_PERPAGE',
    payload: {
      perPage: payload,
    },
  });
};

export const setCompaniesLoading = payload => async dispatch => {
  dispatch({
    type: 'SET_COMPANIES_LOADING',
    payload: {
      loading: payload,
    },
  });
};

export const resetCompanies = payload => async dispatch => {
  dispatch({
    type: 'RESET_COMPANIES',
  });
};
