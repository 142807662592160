import { adapterIntl } from '~/libs/localization/helpers';

const metrics = adapterIntl({
  'metrics.title': {
    ro: 'Abonament',
    ru: 'Абонемент',
    en: 'Subscription',
  },
  'metrics.price': {
    ro: 'Preț',
    ru: 'Цена',
    en: 'Price',
  },
  'metrics.empty': {
    ro: 'Nu aveți',
    ru: 'Нет',
    en: "Don't have",
  },
  'metrics.frequency': {
    ro: 'Frecvență',
    ru: 'Частота',
    en: 'Frequency',
  },
  'metrics.frequency-day': {
    ro: 'Zilnic',
    ru: 'Ежедневно',
    en: 'Daily',
  },
  'metrics.frequency-week': {
    ro: 'Săptămânal',
    ru: 'Ежедневно',
    en: 'Weekly',
  },
  'metrics.frequency-month': {
    ro: 'Lunar',
    ru: 'Ежемесячно',
    en: 'Monthly',
  },
  'metrics.frequency-year': {
    ro: 'Anual',
    ru: 'Ежегодно',
    en: 'Yearly',
  },
  'metrics.daily_limits': {
    ro: 'Limite zilnice',
    ru: 'Дневные лимиты',
    en: 'Daily limits',
  },
  'metrics.monthly_limits': {
    ro: 'Limite lunare',
    ru: 'Месячные лимиты',
    en: 'Monthly limits',
  },
  'metrics.last_update': {
    ro: 'Ultima actualizare',
    ru: 'Последее обновление',
    en: 'Last update',
  },
  'metrics.day_limit': {
    ro: '{min} de ori de la {max} maximă pe zi.',
    ru: '{min} раз из {max} максимума в день.',
    en: '{min} times from {max} maximum for day.',
  },
  'metrics.month_limit': {
    ro: '{min} de ori de la {max} maximă pe lună.',
    ru: '{min} раз из {max} максимума в месяц.',
    en: '{min} times from {max} maximum for month.',
  },
  'metrics.export': {
    ro: 'Exportări',
    ru: 'Экспортирований',
    en: 'Exports',
  },
  'metrics.saved_search': {
    ro: 'Salvare căutări',
    ru: 'Сохранение поисков',
    en: 'Save search',
  },
  'metrics.company_update': {
    ro: 'Actualizarea companiilor',
    ru: 'Обновление компаний',
    en: 'Company update',
  },
  'metrics.company_search': {
    ro: 'Cautarea companiilor',
    ru: 'Поиск компаний',
    en: 'Company search',
  },
  'metrics.company_preview': {
    ro: 'Previzualizarea companiilor',
    ru: 'Предварительный просмотр компаний',
    en: 'Company preview',
  },
  'metrics.export_pdf': {
    ro: 'Exportarea PDF',
    ru: 'PDF экспорт ',
    en: 'PDF export',
  },
  'metrics.export_companies': {
    ro: 'Exportarea companiilor',
    ru: 'Экспорт компаний',
    en: 'Companies export',
  },
  'metrics.export_employees': {
    ro: 'Exportarea angajaților',
    ru: 'Экспорт сотрудников',
    en: 'Employees export',
  },
  'metrics.add_users': {
    ro: 'Adaugarea utilizatorilor',
    ru: 'Добавление пользователей',
    en: 'Users add',
  },
  'metrics.saved_list': {
    ro: 'Salvarea listelor',
    ru: 'Сохранение списков',
    en: 'List save',
  },
  'metrics.alerts': {
    ro: 'Notificări',
    ru: 'Оповещения',
    en: 'Alerts',
  },
});

export default metrics;
