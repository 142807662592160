import React, { memo } from 'react';
import { Checkbox, Tooltip } from 'antd';
import { useSelector, shallowEqual } from 'react-redux';
import { useInjectIntl } from '~/libs/localization';
import { Link as RouterLink } from '~/libs/router';
import { Empty, HiddenContact, Table } from '~/components/ui';
import { Icon } from '~/components/ui/Icon/Icon';

const Link = ({ item }) =>
  item.parent_id !== null && item.parent_id !== undefined ? (
    <RouterLink className="title" route="SubdivisionPage" params={{ id: item.id }}>
      {item.name || <Empty />}
    </RouterLink>
  ) : (
    <RouterLink className="title" route="GeneralsPage" params={{ id: item.id }}>
      {item.name || <Empty />}
    </RouterLink>
  );

const SearchCompany = ({ allowed, companies = [], loading, selectedRow = [], ...props }) => {
  // we removed it temporarily for UX experiment
  // const selectedAll = companies.length > 0 && companies.length === selectedRow.length;

  const { t } = useInjectIntl();

  const classLoading = loading ? 'skeleton skeleton-text' : '';

  const store = useSelector(
    ({ authentication }) => ({
      permissions: authentication.permissions,
    }),
    shallowEqual,
  );

  const columns = [
    {
      title: '',
      dataIndex: 'id',
      render: (text, record) => (
        <span className={classLoading}>
          <Checkbox checked={selectedRow.includes(record.id)} onClick={() => props.selectSearch(record.id)} />
        </span>
      ),
    },
    {
      title: t('other.name'),
      dataIndex: 'name',
      render: (name, record) => (
        <Tooltip
          title={
            store.permissions.includes('general_data_tab')
              ? allowed && record.name.length > 20 && record.name
              : t('permissions.company_preview')
          }
        >
          <span className={classLoading}>
            {allowed && store.permissions.includes('general_data_tab') ? (
              <Link item={record} />
            ) : (
              <span className="title-disabled">{name}</span>
            )}
          </span>
        </Tooltip>
      ),
    },
    {
      title: t('searcher.industry'),
      dataIndex: 'industry',
      render: industry => (
        <Tooltip title={industry && industry.length > 20 && industry}>
          <span className={classLoading}>{industry || <Empty />}</span>
        </Tooltip>
      ),
    },
    {
      title: t('other.location'),
      dataIndex: 'location',
      render: location => (
        <Tooltip title={location && location.length > 20 && location}>
          <span className={classLoading}>{location || <Empty />}</span>
        </Tooltip>
      ),
    },
    {
      title: t('other.website'),
      dataIndex: 'website',
      render: website => (
        <span className={classLoading}>
          <HiddenContact value={website} type="website" icon={<Icon type="company-website" />} target="_blank" />
        </span>
      ),
    },
    {
      title: t('other.email'),
      dataIndex: 'email',
      render: email => (
        <span className={classLoading}>
          <HiddenContact value={email} type="email" icon={<Icon type="company-mail" />} />
        </span>
      ),
    },
    {
      title: t('other.telephone'),
      dataIndex: 'phone',
      render: phone => (
        <span className={classLoading}>
          <HiddenContact value={phone} type="phone" icon={<Icon type="company-telephone" />} />
        </span>
      ),
    },
  ];

  return (
    <Table
      rowKey="id"
      id={props.id}
      className="basic table-clean table-blue single-line fixed table-filter-search"
      fixed
      tableLayout
      columns={columns}
      dataSource={companies}
      scroll={{ y: window.innerHeight }}
      selected={id => {
        return selectedRow.includes(id);
      }}
      conditionalWrapper
    />
  );
};

export default memo(SearchCompany);
