import axios from '~/libs/http/axios';

const { baseURL } = axios.defaults;

const edms = {
  getCompanies: (option = {}) => axios.post(`${baseURL}/api/edms/sync/by-event`, option),
  syncCompanies: (option = {}) => axios.post(`${baseURL}/api/edms/companies/companies`, option),
  syncNomenclatures: (option = {}) => axios.post(`${baseURL}/api/edms/sync/nomenclatures`, option),
};

export default edms;
