import React from 'react';
import { injectIntl } from '~/libs/localization';
import { useStateHandlers } from '~/hooks';
import { has } from '~/libs/object';
import { JuridicalCard } from '~/components/company/juridical';
import { Time } from '~/components/other';
import { Empty } from '~/components/ui';
import { Icon } from '~/components/ui/Icon/Icon';

const JuridicalResult = ({ t, processSessionResult, judgesTitle }) => {
  const [state, setState] = useStateHandlers({
    sessionView: false,
  });

  const onToggleSession = () => setState({ sessionView: !state.sessionView });

  return (
    <div className="juridical-content">
      <JuridicalCard.Section type="rows-top">
        <JuridicalCard type="label-status-thin">
          <h2>{t({ id: 'juridical.fondType' })}</h2>
          <p>{has(processSessionResult, 'session_type.title', <Empty />)}</p>
        </JuridicalCard>

        <JuridicalCard type="label-status-thin">
          <h2>{t({ id: 'juridical.fondDate' })}</h2>
          <p>
            <Time date={processSessionResult.date} format="date" />
          </p>
        </JuridicalCard>

        <JuridicalCard type="label-status-thin">
          <h2>{t({ id: 'juridical.panelJudge' })}</h2>
          <p>{judgesTitle || <Empty />}</p>
        </JuridicalCard>
      </JuridicalCard.Section>

      <div className="solution-instance">
        <h2>{t({ id: 'juridical.judgmentSolution' })}</h2>
        <div>
          <pre>
            {state.sessionView
              ? processSessionResult.title || <Empty />
              : has(processSessionResult, 'solution.title', '---').substr(0, 200)}
          </pre>

          {has(processSessionResult, 'solution.title', '---').length >= 200 ? (
            <span onClick={onToggleSession} role="presentation">
              {state.sessionView ? t('other.hideAll') : t('other.showAll')}{' '}
              <Icon type={state.sessionView ? 'up' : 'down'} />
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default injectIntl(JuridicalResult);
