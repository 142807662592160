import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Tabs } from 'antd';
import { injectIntl } from '~/libs/localization';
import { Link, isRouteActive } from '~/libs/router';
import { Text } from '~/components/ui';

const UsersMenu = ({ t }) => {
  const match = useRouteMatch();
  const { id } = match.params;

  const links = [
    {
      text: 'management.accountUsers',
      route: 'AdminUsersPage',
      params: { id },
      subRoutes: ['AdminAddUsersPage', 'AdminUpdateUsersPage'],
    },
    {
      text: 'management.settings',
      route: 'AdminUpdateAccountPage',
      params: { id },
    },
    {
      text: 'metrics.title',
      route: 'AdminAccountProductPage',
      params: { id },
    },
  ];

  const isActive = routes => (isRouteActive(match, routes) ? 'active' : '');

  return (
    <Tabs activeKey={null} mode="horizontal" className="zh-profile-tabs dot-tabs">
      {links.map(({ subRoutes = [], ...link }) => (
        <Tabs.TabPane
          key={link.route}
          tab={
            !link.disabled ? (
              <Link
                route={link.route}
                params={link.params}
                className={isActive([link.route, ...(link.subRoutes || [])])}
              >
                <Text id={link.text} />
              </Link>
            ) : (
              <span className="grey">
                <Text id={link.text} />
              </span>
            )
          }
        />
      ))}
    </Tabs>
  );
};

export default injectIntl(UsersMenu);
