import React from 'react';
import { firstLetters } from '~/libs/string';
import { Text } from '~/components/ui';
import { Icon } from '~/components/ui/Icon/Icon';

const SearchAddItem = {};

SearchAddItem.Total = ({ value }) => (
  <span className="search-block-total">
    <Text id="filter.searchResult" values={{ value }} />
    <Icon type="new-arrow-right" />
  </span>
);

SearchAddItem.Empty = ({ title }) => <span className="search-block-total">{title}</span>;

SearchAddItem.Fragment = props => {
  const save = () => {
    props.onSaveDate(props.name, props.idno, props.address);
  };

  return (
    <div className="search-block" onClick={save}>
      {props.img && <img src={props.img} alt="" />}
      {!props.img && (
        <div className="search-logo">
          <span>{firstLetters(props.company)}</span>
        </div>
      )}

      <div className="search-block-text">
        <span>
          {props.company} - {props.idno}
        </span>
        {props.activity && <small>{props.activity}</small>}
      </div>
    </div>
  );
};

export default SearchAddItem;
