import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';

const Marker = ({ text }) => <img src="/assets/img/MapPin.png" alt="marker" className="map-pin" />;
const key = 'AIzaSyC_0fh12-DVjt8WuY8llmg0Q7m14wRkDsg';

export default props => {
  const [[lat, lng], setAddress] = useState([]);

  useEffect(() => {
    const onChangeAddress = () => {
      if (props.lat && props.lng) {
        setAddress([parseFloat(props.lat), parseFloat(props.lng)]);
      }
    };

    onChangeAddress();
  }, [props.lat, props.lng]);

  if (!lat || !lng) {
    return <div className="map-loader" />;
  }

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <GoogleMapReact bootstrapURLKeys={{ key }} defaultCenter={{ lat, lng }} defaultZoom={17}>
        <Marker {...{ lat, lng }} />
      </GoogleMapReact>
    </div>
  );
};
