import React, { useEffect } from 'react';
import { useRequest } from 'estafette';
import { Radio, Table } from 'antd';
import { useHistory } from 'react-router-dom';
import { useStateHandlers } from '~/hooks';
import { billing } from '~/libs/http/api';
import { getRoute } from '~/libs/router';
import { notification } from '~/libs/notification';
import { number } from '~/libs/number';
import { injectIntl } from '~/libs/localization';
import { PaymentData } from '~/components/cart/payment';
import { Button, Modal, Loader } from '~/components/ui';
import '~/assets/scss/components/ui/_TeamLicenseModal.scss';

const TeamLicenseModal = ({ type = 'success', onClose, t, ...props }) => {
  const initialLimit = '5';

  const history = useHistory();
  const { request, data, loading } = useRequest({ loading: true });
  const { request: requestOrder, loading: loadingOrder } = useRequest();
  const [state, setState] = useStateHandlers({
    changed: false,
    selected: initialLimit,
    price: null,
    tax: null,
    upgrade: false,
  });

  useEffect(() => {
    request(billing.usersPrice());
  }, []);

  const onSubmit = ev => {
    ev.preventDefault();

    setState({ upgrade: true });
  };

  const onSubmitOrder = async () => {
    await requestOrder(billing.addCart({ type: 'users', users: state.selected }));

    notification({
      title: t('team.license'),
      message: t('notification.cart.message'),
      direction: t('notification.cart.direction'),
      onClick: () => history.push(getRoute('OrderPage')),
    });

    onClose();
  };

  const onSuccess = () => {
    notification({
      title: t('team.license'),
      message: t('notification.payment.message'),
    });

    onClose();
  };

  const onClickRow = ({ users }) => ({
    onClick: () => {
      if (Number(initialLimit) <= Number(users)) {
        setSelected(users);
      }
    },
  });

  const setSelected = selected => {
    const { price, tax } = data.find(({ users }) => users === selected) || {};
    setState({ changed: initialLimit !== selected, selected, price, tax });
  };

  const onCloseUpgrade = () => setState({ upgrade: false });
  const onChangeHandler = ({ target }) => setSelected(target.value);

  const columns = [
    {
      width: '30px',
      align: 'center',
      dataIndex: 'id',
      render: (text, { users }) => (
        <Radio
          value={users}
          checked={state.selected === users}
          onChange={onChangeHandler}
          disabled={Number(initialLimit) > Number(users)}
        />
      ),
    },
    {
      title: t({ id: 'team.users' }),
      dataIndex: 'users',
      width: '33%',
      className: 'license-table--whitespace',
      render: users => `${number(users - 5)}-${number(users)}`,
    },
    {
      title: 'Preț de un \n utilizator',
      width: '35%',
      className: 'license-table--whitespace',
      render: (text, { users, price }) => (price ? Number(users - 5) / price : 0),
    },
    {
      title: t({ id: 'team.final-price' }),
      dataIndex: 'price',
      width: '32%',
      className: 'license-table--whitespace',
      render: price => (price ? `${price} MDL` : <span className="grey">FREE</span>),
    },
  ];

  return (
    <>
      {state.upgrade && (
        <PaymentData
          modal
          header={false}
          users={state.selected}
          price={state.price}
          tax={state.tax}
          onClose={onCloseUpgrade}
          onSuccess={onSuccess}
          redirect="TeamPage"
          parent="license"
        />
      )}

      <Modal
        hide={state.upgrade}
        onClose={onClose}
        className="modal-nocontent modal-has-header"
        title={t('team.license')}
        width={700}
        footerLeft={
          <>
            <Button type="primary" disabled={!state.changed || loadingOrder} onClick={onSubmit}>
              {t({ id: 'team.buy' })}
            </Button>

            <Button disabled={!state.changed} loading={loadingOrder} onClick={onSubmitOrder}>
              {t({ id: 'team.add-to-cart' })}
            </Button>
          </>
        }
        footerRight={<Button onClick={onClose}>{t('other.reset')}</Button>}
      >
        <Loader loading={loading}>
          <Table
            rowKey="id"
            columns={columns}
            dataSource={data}
            className="zh-license-table row-clicked"
            onRow={onClickRow}
            pagination={false}
            rowClassName={({ users }) =>
              `${Number(initialLimit) > Number(users) ? 'zh-license-unactive' : ''} ${
                state.selected === users ? 'zh-license-active' : ''
              }`
            }
          />
        </Loader>
      </Modal>
    </>
  );
};

export default injectIntl(TeamLicenseModal);
