import { adapterIntl } from '~/libs/localization/helpers';

const other = adapterIntl({
  'other.permission': {
    ro: 'Nu aveţi acces.',
    ru: 'У вас нет доступа.',
    en: "You don't have access.",
  },
  'other.end-subscription': {
    ro: 'Abonament',
    ru: 'Подписка',
    en: 'Subscription',
  },
  'other.year-select': {
    ro: 'Anul',
    ru: 'Год',
    en: 'Year',
  },
  'other.cancel-btn': {
    ro: 'Anulare',
    ru: 'Отмена',
    en: 'Cancel',
  },
  'other.apply-btn': {
    ro: 'Aplicați căutarea',
    ru: 'Применить поиск',
    en: 'Apply search',
  },
  'other.end-subscription-text': {
    ro: 'Abonamentul dvs. se încheie pe',
    ru: 'Ваша подписка заканчивается',
    en: 'Your subscription ends at',
  },
  'other.full_name': {
    ro: 'Numele si Prenumele',
    ru: 'Имя Фамлия',
    en: 'Full name',
  },
  'other.first_name': { ro: 'Prenumele', ru: 'Имя', en: 'First name' },
  'other.first_name_type': {
    ro: 'Introduceti prenumele',
    ru: 'Введите имя',
    en: 'Enter your first name',
  },
  'other.last_name': { ro: 'Numele', ru: 'Фамилия', en: 'Last name' },
  'other.last_name_type': {
    ro: 'Introduceti numele',
    ru: 'Введите фамилию',
    en: 'Enter your last name',
  },
  'other.typeName': {
    ro: 'Introduceti numele si prenumele',
    ru: 'Введите имя и фамилию',
    en: 'Enter your first and last name',
  },
  'other.keywords': {
    ro: 'Căutați după cuvinte cheie',
    ru: 'Поиск по ключевым словам',
    en: 'Search by keywords',
  },
  'other.fields': {
    ro: 'Câmpuri',
    ru: 'Поля',
    en: 'Fields',
  },
  'other.add_in_cart': {
    ro: 'Adaugă în сoș',
    ru: 'Добавить в корзину',
    en: 'Add in cart',
  },
  'other.empty': {
    ro: 'Nimic nu a fost gasit',
    ru: 'Ничего не было найдено',
    en: 'Nothing was found',
  },
  'other.mobile': {
    ro: 'Telefon mobil',
    ru: 'Моб. телефон',
    en: 'Mobile phone',
  },
  'other.N': { ro: 'N', ru: 'Н', en: 'N' },
  'other.users': { ro: 'Utilizatori', ru: 'Пользователи', en: 'Users' },
  'other.status': { ro: 'Status', ru: 'Статус', en: 'Status' },
  'other.last-activity': { ro: 'Ultima activitatea', ru: 'Последняя активность', en: 'Last activity' },
  'other.manager': { ro: 'Administrator', ru: 'Менеджер', en: 'Manager' },
  'other.created-at': { ro: 'Data creării', ru: 'Дата создания', en: 'Created at' },
  'other.expired-at': { ro: 'Data expiră', ru: 'Дата истекает', en: 'Expired at' },
  'other.yes': { ro: 'Da', ru: 'Да', en: 'Yes' },
  'other.no': { ro: 'Nu', ru: 'Нет', en: 'No' },
  'other.name': { ro: 'Nume', ru: 'Имя', en: 'Name' },
  'other.name2': { ro: 'Denumirea', ru: 'Название', en: 'Name' },
  'other.nameType': {
    ro: 'Introduceți denumirea',
    ru: 'Введите название',
    en: 'Enter name',
  },
  'other.example': { ro: 'Exemplu', ru: 'Пример', en: 'Example' },
  'other.total': { ro: 'Total', ru: 'Итого', en: 'Total' },
  'other.date': { ro: 'Data', ru: 'Дата', en: 'Date' },
  'other.searchBox': {
    ro: 'Nume / IDNO',
    ru: 'Название / IDNO',
    en: 'Name / IDNO',
  },
  'other.enterYourRrequest': {
    ro: 'Introduceți solicitarea dvs.',
    ru: 'Введите ваш запрос.',
    en: 'Enter your request.',
  },
  'other.save': { ro: 'Salvează', ru: 'Сохранить', en: 'Save' },
  'other.addInList': {
    ro: 'Adaugă in lista',
    ru: 'Добавить в список',
    en: 'Add to list',
  },
  'other.saveSearch': {
    ro: 'Salvează căutarea',
    ru: 'Сохранить поиск',
    en: 'Save search',
  },
  'other.saveSearchDisabled': {
    ro: 'Filtrul nu poate fi gol',
    ru: 'Фильтр не может быть пустым',
    en: 'Filter cannot be empty',
  },
  'other.email-auth': { ro: 'Adresa Dvs de e-mail', ru: 'Ваша электронная почта', en: 'Your email address' },
  'other.download': { ro: 'Descarcă', ru: 'Скачать', en: 'Download' },
  'other.downloadExample': { ro: 'Descărcare exemplu', ru: 'Скачать пример', en: 'Download example' },
  'other.location': { ro: 'Locație', ru: 'Адрес', en: 'Location' },
  'other.website': { ro: 'Website', ru: 'Сайт', en: 'Website' },
  'other.email': { ro: 'Email', ru: 'Email', en: 'Email' },
  'other.role': { ro: 'Rol', ru: 'Функция', en: 'Role' },
  'other.hidden_email': { ro: 'Email', ru: 'Электорнный адрес', en: 'Email' },
  'other.hidden_website': { ro: 'Website', ru: 'Веб-сайт', en: 'Website' },
  'other.hidden_skype': { ro: 'Skype', ru: 'Skype', en: 'Skype' },
  'other.telephone': { ro: 'Telefon', ru: 'Телефон', en: 'Telephone' },
  'other.typeTelephone': { ro: 'Introduceți telefon', ru: 'Введите телефон', en: 'Type telephone' },
  'other.hidden_phone': { ro: 'Telefon', ru: 'Телефон', en: 'Telephone' },
  'other.subject': { ro: 'Subiect', ru: 'Субьект', en: 'Subject' },
  'other.message': { ro: 'Mesaj', ru: 'Сообщение', en: 'Message' },
  'other.modify': { ro: 'Modifică', ru: 'Изменить', en: 'Modify' },
  'other.delete': { ro: 'Șterge', ru: 'Удалить', en: 'Delete' },
  'other.typeHere': {
    ro: 'Introduceți textul aici',
    ru: 'Введите текст',
    en: 'Enter here',
  },
  'other.typePassword': {
    ro: 'Introduceți parola aici',
    ru: 'Введите пароль',
    en: 'Enter your password',
  },
  'other.error_module': {
    ro: 'Ne pare rău! Acest modul nu funcționează încă.',
    ru: 'Нам очень жаль! Этот модуль пока не работает.',
    en: "We're sorry! This module is not working, yet.",
  },
  'other.firstEnterQuery': {
    ro: 'Mai întâi introduceți interogarea',
    ru: 'Сначала введите запрос',
    en: 'First enter your query',
  },
  'other.try_again': {
    ro: 'Încearcă din nou',
    ru: 'Попробовать снова',
    en: 'Try Again',
  },
  'other.search_name': {
    ro: 'Caută după denumire',
    ru: 'Искать по названию',
    en: 'Search by name',
  },
  'other.create_submit': {
    ro: 'Crearea & Aplică',
    ru: 'Создать & Применить',
    en: 'Create & Submit',
  },
  'other.submit': { ro: 'Aplică', ru: 'Применить', en: 'Apply' },
  'other.reset': { ro: 'Anulează', ru: 'Отменить', en: 'Cancel' },
  'other.confirm': { ro: 'Confirmă', ru: 'Подтвердить', en: 'Confirm' },
  'other.actions': { ro: 'Acțiuni', ru: 'Действие', en: 'Actions' },
  'other.orderBy': { ro: 'Sortează după', ru: 'Сортировать по', en: 'Sort by' },
  'other.select': { ro: 'Selectați', ru: 'Выберите', en: 'Select' },
  'other.edit': { ro: 'Editează', ru: 'Редактировать', en: 'Edit' },
  'other.data': { ro: 'Data', ru: 'Дата', en: 'Data' },
  'other.gen': { ro: 'Genul', ru: 'Вид', en: 'Type' },
  'other.type': { ro: 'Tipul', ru: 'Тип', en: 'Type' },
  'other.formOfChanges': { ro: 'Forma modificărilor', ru: 'Форма изменений', en: 'Form of changes' },
  'other.description': { ro: 'Descrierea', ru: 'Описание', en: 'Description' },
  'other.job': { ro: 'Job function', ru: 'Должность', en: 'Job function' },
  'other.contacts': {
    ro: 'Telefon/Mobil/Fax',
    ru: 'Телефон/Моб. телефон/Факс',
    en: 'Telephone/Mobile/Fax',
  },
  'other.showAll': {
    ro: 'Vezi informație completă',
    ru: 'Смотреть полную информацию',
    en: 'View full information',
  },
  'other.hideAll': {
    ro: 'Ascunde informație completă',
    ru: 'Скрыть полную информацию',
    en: 'Hide full information',
  },
  'other.repeat_password': {
    ro: 'Repetați parola',
    ru: 'Повторите пароль',
    en: 'Repeat your password',
  },
  'other.password': { ro: 'Parola', ru: 'Пароль', en: 'Password' },
  'other.year': { ro: 'ani', ru: 'лет', en: 'years' },
  'other.social': {
    ro: 'Social media',
    ru: 'Социальные сети',
    en: 'Social media',
  },
  'other.chart': { ro: 'Afaceri', ru: 'Бизнес', en: 'Business' },
  'other.infoContacts': {
    ro: 'Informație de contact',
    ru: 'Контактная информация',
    en: 'Contact details',
  },
  'other.logout': {
    ro: 'Sunteți sigur că doriți să vă deconectați?',
    en: 'Are you sure you want to log out?',
    ru: 'Вы уверены что хотите отключится?',
  },
  'other.download-alert': {
    ro: 'Sunteți sigur că doriți să descărcați?',
    ru: 'Вы уверены, что хотите скачать?',
    en: 'Are you sure you want to download?',
  },
  'other.delete-alert': {
    ro: 'Sunteți sigur că doriți să ștergeți?',
    ru: 'Вы уверены, что хотите удалить?',
    en: 'Are you sure you want to delete?',
  },
  'other.action-alert': {
    ro: 'Sunteți sigur că doriți să executați această acțiune?',
    ru: 'Вы уверены, что хотите выполнить это действие?',
    en: 'Are you sure you want to complete this action?',
  },
  'other.region': {
    ro: 'Regiunea',
    ru: 'Регион',
    en: 'Region',
  },
  'other.regionType': {
    ro: 'Introduceți regiunea (Chișinâu)',
    ru: 'Введениеți regiunea (Кишинев)',
    en: 'Enter the region (Chisinau)',
  },
  'other.addressType': {
    ro: 'Introduceți adresa (Ion Creangâ)',
    ru: 'Введите адрес (Ион Креанга)',
    en: 'Enter the address (Ion Creangâ)',
  },
  'other.invertSelection': {
    ro: 'Inversează selecția',
    ru: 'Инвертировать выбор',
    en: 'Invert Selection',
  },
  'other.markAsRead': {
    ro: 'Marchează ca citit',
    ru: 'Пометить как прочитанное',
    en: 'Mark as read',
  },
  'other.deleteItems': {
    ro: 'Șterge elementele',
    ru: 'Удалить выбор',
    en: 'Delete items',
  },
  'other.cleanSelection': {
    ro: 'Curăță selecția',
    ru: 'Очистить выбор',
    en: 'Clear selection',
  },
  'other.selections': { ro: 'selectat', ru: 'выбрано', en: 'selected' },
  'other.allAlerts': {
    ro: 'Toate alertele',
    ru: 'Все оповещения',
    en: 'All alerts',
  },
  'other.unread': { ro: 'Necitite', ru: 'Непрочитанные', en: 'Unread' },
  'other.read': { ro: 'Citite', ru: 'Прочитанные', en: 'Read' },
  'other.deleted': { ro: 'Șterse', ru: 'Удаленные', en: 'Deleted' },
  'other.similarCompanies': {
    ro: 'Companii similare',
    ru: 'Похожие компании',
    en: 'Similar companies',
  },
  'other.logout-alert': { ru: 'Вы уверены, что хотите выйти?' },
  'other.success.added': {
    ro: 'Adaugată cu succes!',
    ru: 'Успешно добавлено!',
    en: 'Successfully added!',
  },
  'other.success.changed': {
    ro: 'Modificările salvate cu succes!',
    ru: 'Изменения сохранены успешно!',
    en: 'Changes saved with success!',
  },
  'other.success.deleted': {
    ro: 'Șters cu succes!',
    ru: 'Удалено усмешно!',
    en: 'Deleted with success!',
  },
  'other.content': {
    ro: 'Conţinut',
    ru: 'Содержание',
    en: 'Content',
  },
  'other.collapse-all': {
    ro: 'Restrângeți toate',
    ru: 'Свернуть все',
    en: 'Collapse all',
  },
  'other.month': {
    ro: 'Luna',
    ru: 'Месяц',
    en: 'Month',
  },
  'other.oneyear': {
    ro: 'Anul',
    ru: 'Год',
    en: 'Year',
  },
  'other.agree': {
    ro: 'Sunt de acord cu',
    ru: 'Я согласен с',
    en: 'I agree to the',
  },
  'other.terms': {
    ro: 'Termeni și condiții',
    ru: 'условиями пользовательского соглашения',
    en: 'Terms and Conditions',
  },
  'other.download.text': {
    ro:
      'Cererea dvs. de descărcare a exportului a fost procesată! Apăsați butonul de mai jos pentru a descărca mai multe fișiere PDF.',
    ru: 'Ваш запрос о экспорте данных обработан! Нажмите кнопку "Скачать" чтобы экспортировать больше данных.',
    en: 'Your request to download export has been processed! Press the button bellow to download more PDF file.',
  },
  'other.download.loading': {
    ro: 'Cererea dvs. de descărcare a exportului a fost transmisă și este procesată. Poate dura până la un minut.',
    ru: 'Ваш запрос о выгрузке данных был отправлен и уже обрабатывается. Это может занять около минуты.',
    en: 'Your request to download export has been sent and is being processed. It can take up to a minute.',
  },
  'other.export': {
    ro: 'Export',
    ru: 'Экспорт',
    en: 'Export',
  },
  'other.toExport': {
    ro: 'Descarcă',
    ru: 'Экспортировать',
    en: 'Export',
  },
  'other.nofilters': {
    ro: 'Alegeți filtrele',
    ru: 'Выберите фильтры',
    en: 'Filters are required',
  },
  'other.addToCart': {
    ro: 'Puteți cumpăra făcând clic pe butonul de mai jos sau',
    ru: 'Вы можете купить, нажав на кнопку ниже или',
    en: 'You can buy by clicking the button below or',
  },
  'other.addOrder': {
    ro: 'adăugați comanda în coș.',
    ru: 'добавить заказ в корзину.',
    en: 'add order in your cart.',
  },
  'other.goTo': {
    ro: 'Mergi la',
    ru: 'Перейти к',
    en: 'Go to',
  },
  'other.addQueue': {
    ro: 'a fost adăugat în rînd pentru a fi descărcat.',
    ru: 'добавлен в очередь загрузки.',
    en: 'has added to the download queue.',
  },
  'other.lastUpdated': {
    ro: 'ultima actualizare',
    ru: 'последнее обновление',
    en: 'last updated',
  },
  'other.monthToDate': {
    ro: 'Rezumat lunar',
    ru: 'Сводка по месяцам',
    en: 'Month-to-date Summary',
  },
  'other.changes': {
    ro: 'Aceste modificări sunt incluse în soldul contului',
    ru: 'Эти изменения учитываются в балансе вашего счета',
    en: 'These changes are factored into your account balance',
  },
  'other.yesterday': {
    ro: 'Ieri',
    ru: 'Вчера',
    en: 'Yesterday',
  },
  'other.noData': {
    ro: 'Nu sunt rezultate',
    ru: 'Пока нет результатов',
    en: "There're no results, yet",
  },
  'other.noResults': {
    ro: 'Fără rezultate',
    ru: 'Нет результатов',
    en: 'No results',
  },
  'other.nothing': {
    ro: 'Nu sunt',
    ru: 'Нет',
    en: 'No one',
  },
  'other.feedback': {
    ro: 'Feedback',
    ru: 'Отзыв',
    en: 'Feedback',
  },
  'other.results': {
    ro: 'de rezultate',
    ru: 'результатов',
    en: 'results',
  },
  'other.feedbackInfo': {
    ro: 'Trimite-ne bug-uri, idei, reclamații, complimente, sugestii',
    ru: 'Присылайте нам сообщения об ошибках, идеи, жалобы, комплименты, предложения',
    en: 'Send us bugs, ideas, complaints, compliments, suggestions',
  },
  'other.sendFeedback': {
    ro: 'Trimite feedback',
    ru: 'Отправить отзыв',
    en: 'Send feedback',
  },
  'other.feedbackSuccess': {
    ro: 'Feedback-ul dvs. a fost expediat!',
    ru: 'Ваш отзыв отправлен!',
    en: 'Your feedback has been submitted!',
  },
  'other.successfully': {
    ro: 'Cu succes',
    ru: 'Успешно',
    en: 'Successfully',
  },
  'other.rating': {
    ro: 'Notă',
    ru: 'Оценка',
    en: 'Rating',
  },
  'other.update': { ro: 'Actualizează', ru: 'Обновить', en: 'Update' },
  'other.inSync': { ro: 'Se actualizează', ru: 'Обновляется', en: 'Updating' },
  'other.date-created-asc': {
    ro: 'Data creării crescător',
    ru: 'Дата создания по возрастанию',
    en: 'Date created ascending',
  },
  'other.date-created-desc': {
    ro: 'Data creării descrescător',
    ru: 'Дата создания по убыванию',
    en: 'Date created descending',
  },
  'other.name-asc': {
    ro: 'Denumire crescător',
    ru: 'Название по возрастанию',
    en: 'Name ascending',
  },
  'other.name-desc': {
    ro: 'Denumire descrescator',
    ru: 'Название по возрастанию',
    en: 'Name descending',
  },
  'other.select-all': {
    ro: 'Alege tot',
    ru: 'Выбрать все',
    en: 'Select all',
  },
  'other.selected-all': {
    ro: 'Toate',
    ru: 'Все',
    en: 'All',
  },
  'other.selected-all-except': {
    ro: 'Toate cu excepția {elements} ',
    ru: 'Все, кроме {elements} ',
    en: 'All, except {elements} ',
  },
  'other.selected-all-except-element': {
    ro: 'element',
    ru: 'элемента',
    en: 'element',
  },
  'other.selected-all-except-elements': {
    ro: 'elemente',
    ru: 'элементов',
    en: 'elements',
  },
  'other.except': {
    ro: 'Excluzând',
    ru: 'Исключая',
    en: 'Except',
  },
  'other.wait_payment': {
    ro: 'În așteptarea plății',
    ru: 'Ожидает оплаты',
    en: 'Awaiting payment',
  },
  'other.paid': {
    ro: 'Plătit',
    ru: 'Оплаченный',
    en: 'Paid',
  },
  'other.any': { ro: 'Oricare', ru: 'Любые', en: 'Any' },
  'other.all': { ro: 'Toate', ru: 'Все', en: 'All' },
  'other.success': { ro: 'Succes', ru: 'Успех', en: 'Success' },
  'other.failed': { ro: 'Eșuat', ru: 'Не удалось', en: 'Failed' },
  'other.processing': { ro: 'Procesare', ru: 'Обработка', en: 'Processing' },
  'other.nameFeedback': { ro: 'Numele Dvs', ru: 'Ваше имя', en: 'Your name' },
  'other.phoneFeedback': { ro: 'Numarul Dvs de telefon', ru: 'Ваш номер телефона', en: 'Your phone number' },
  'other.messageFeedback': { ro: 'Descrierea problemei', ru: 'Описание проблемы', en: 'Problem description' },
  'other.contactUs': { ro: 'Contactați-ne', ru: 'Свяжитесь с нами', en: 'Contact us' },
  'other.signUpSupport': {
    ro: 'Serviciul Suport clienţi Informer. Contactați-ne',
    ru: 'Служба поддержки клиентов Informer. Свяжитесь с нами.',
    en: 'Informer Customers Support center. Contact us.',
  },
  'other.signUpSupportContact': {
    ro: 'Probleme cu înregistrarea? ',
    ru: 'Проблемы при регистрации?',
    en: 'Problems with registration?',
  },
  'other.exportWordModal': {
    ro: 'Exportul',
    ru: 'Экспорт',
    en: 'Export',
  },
});

export default other;
