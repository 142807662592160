export const mutationByDefault = (id, state, value = {}, companyHasCriterion) => {
  const activeFilter = { ...state.activeFilter };

  if (
    (typeof value === 'string' && value.length > 0) ||
    (typeof value === 'number' && value) ||
    (value instanceof Object && Object.keys(value).length > 0) ||
    (Array.isArray(value) && value.length > 0)
  ) {
    activeFilter[id] = value;
  } else {
    delete activeFilter[id];
  }

  return {
    ...state,
    activeFilter,
    companyHasCriterion,
  };
};

export const mutationWithinChildren = (id, state, value, key) => {
  const activeFilter = { ...state.activeFilter };

  if (activeFilter[id] && activeFilter[id][key] && !value) {
    delete activeFilter[id][key];
  } else if (value) {
    activeFilter[id] = {
      ...activeFilter[id],
      [key]: value,
    };
  }

  // Object.keys(activeFilter[id]).length === 0 && delete activeFilter[id];

  return {
    ...state,
    activeFilter,
  };
};

export const synchronization = (state, data) => {
  const cloneState = JSON.parse(JSON.stringify(state));

  cloneState.loading = false;

  cloneState.filter.some((filterItem, filterKey) =>
    filterItem.children.some(({ nomenclature, type }, itemKey) => {
      if (nomenclature) {
        cloneState.filter[filterKey].children[itemKey].children = data[nomenclature];
      }

      return null;
    }),
  );

  return cloneState;
};
