import React, { useCallback } from 'react';
import { Breadcrumb } from 'antd';
import { Link } from '~/libs/router';
import { useSelector, shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useRequest } from 'estafette';
import { useStateHandlers } from '~/hooks';
import { has } from '~/libs/object';
import { useInjectIntl } from '~/libs/localization';
import { ManageListSearch, DownloadReport } from '~/components/modal/company';
import Layout from '~/components/layouts/Layout';
import { FilterActions } from '~/components/filter';
import { Logo, Tabs } from '~/components/company';
import { ExportModal } from '~/components/modal/export';
import { JuridicalTabs } from '~/components/company/juridical';
import { company as companyApi } from '~/libs/http/api';
import { Icon } from '~/components/ui/Icon/Icon';

let lastScroll = 0;

export const CompanyLayout = ({ children, type = 'company', juridical }) => {
  const { id } = useParams();
  const { t } = useInjectIntl();
  const { request } = useRequest();

  const { permissions, ...store } = useSelector(
    ({ company, employee, subdivision, authentication }) => ({
      company,
      employee,
      subdivision,
      permissions: authentication.permissions,
    }),
    shallowEqual,
  );

  const [state, setState] = useStateHandlers({
    save: false,
    download: false,
    export: false,
    inSync: false,
  });

  const { availability, loading, data: api } = store[type];

  const [mainWebsite] = has(api, 'general_data.contact_info.sites', []);

  const onCloseDownload = () => setState({ download: false });
  const onOpenDownload = () => setState({ download: true });

  const onCloseSave = () => setState({ save: false });
  const onOpenSave = () => setState({ save: true });

  const onCloseExport = () => setState({ export: false });
  const onOpenExport = () => setState({ export: true });

  const onOpenUpdate = useCallback(() => {
    if (!state.inSync) {
      request(companyApi.update({ id }));

      setState({ inSync: true });
    }
  }, [state.inSync]);

  const headerTypes = ['desktop', 'sticky'];
  const stickyRef = React.useRef(null);
  const desktopRef = React.useRef(null);

  React.useEffect(() => {
    const handleScroll = () => {
      const { current: header = {} } = stickyRef;
      const currentScroll = window.pageYOffset;

      if (header) {
        if (currentScroll <= 100) {
          header.classList.remove('scroll-up');
          return;
        }

        if (currentScroll > lastScroll && !header.classList.contains('scroll-down')) {
          // down
          header.classList.remove('scroll-up');
          header.classList.add('scroll-down');
        } else if (currentScroll < lastScroll && header.classList.contains('scroll-down')) {
          // up
          header.classList.remove('scroll-down');
          header.classList.add('scroll-up');
        }

        lastScroll = currentScroll;
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  React.useEffect(() => {
    const onChangeInSync = () => setState({ inSync: store.company.data.in_sync });

    onChangeInSync();
  }, [store.company.data.in_sync]);

  const description =
    type === 'subdivision'
      ? has(api, 'general_data.types_of_activity.products_and_services', []).length > 0
        ? api.general_data.types_of_activity.products_and_services.map(({ title }) => title.title).join(', ')
        : undefined
      : has(api, 'general_data.special_description.name');

  return (
    <>
      {state.download && <DownloadReport onClose={onCloseDownload} onExport={onOpenExport} name={api.name} id={id} />}

      {state.save && (
        <ManageListSearch type={type === 'employee' ? 'employee' : 'company'} data={[id]} onClose={onCloseSave} />
      )}

      {state.export && (
        <ExportModal
          width={475}
          name={api.name}
          provider={type === 'employee' ? 'employee_details' : 'company_details'}
          onClose={onCloseExport}
          data={[id]}
        />
      )}

      <Breadcrumb separator={<Icon type="breadcrumb-arrow" />}>
        <Breadcrumb.Item>
          <Link route="HomePage">{t('packages.exploration')}</Link>
        </Breadcrumb.Item>

        <Breadcrumb.Item>
          <Link route="HomePage">{t('profile.companyDetails')}</Link>
        </Breadcrumb.Item>
      </Breadcrumb>

      <Layout>
        {headerTypes.map(headerType => (
          <div
            className={`company-header ${headerType !== 'sticky' ? 'clear-fix' : 'sticky-header'}`}
            ref={headerType === 'sticky' ? stickyRef : desktopRef}
            key={headerType}
          >
            <div className="container-width">
              <div className="pull-left company-logo-wrap">
                <Logo
                  name={api.name || api.parent_name}
                  desc={description}
                  loading={loading}
                  status={type === 'employee' || type === 'subdivision' ? false : has(api, 'status.title')}
                  isResident={api.is_resident}
                  website={mainWebsite}
                />
              </div>

              <div className="pull-right company-logo-right">
                <FilterActions
                  checking={permissions}
                  report={api.finance_report_info}
                  type={type}
                  onSaveList={onOpenSave}
                  onDownload={onOpenDownload}
                  onUpdateCompany={onOpenUpdate}
                  updateCompanyDisabled={state.inSync}
                  companyPage
                >
                  {t('other.actions')}
                </FilterActions>
              </div>
            </div>
          </div>
        ))}

        {type === 'employee' || type === 'subdivision' ? (
          <div className="employer-line" />
        ) : (
          <Tabs availability={availability} />
        )}

        {type !== 'employee' && juridical && <JuridicalTabs availability={availability} />}

        {children}
      </Layout>
    </>
  );
};
