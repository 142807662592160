import React from 'react';
// import InfiniteScroll from 'react-infinite-scroller';
import classnames from 'classnames';
import { message, Tabs } from 'antd';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { setActiveFilter } from '~/store/actions/FilterActions';
import { setDocumentTitle } from '~/hooks';
import { injectIntl } from '~/libs/localization';
// import { has } from '~/libs/object';
import { parseQuery } from '~/libs/http/url';
import { Link, getRoute } from '~/libs/router';
import { dashboard } from '~/libs/http/api';
import withRequest from '~/hoc/withRequest';
import { TableFooter } from '~/components/table';
import { Layout } from '~/components/layouts';
import { Time } from '~/components/other';
import { Loader, EmptyBlock, Table, Icon, Pagination } from '~/components/ui';

export const types = {
  // alert: {
  //   text: 'home.alerts',
  //   action: 'home.alerts',
  //   icon: <Icon type="filter-notification" />,
  // },
  lists: { text: 'home.lists', action: 'home.lists.action', icon: <Icon type="filter-list" /> },
  saved_search: { text: 'home.lookings', action: 'home.lookings.action', icon: <Icon type="filter-search" /> },
  // search: { text: 'home.saved-search', action: 'home.saved-search.action', icon: <Icon type="filter-search" /> },
  monitoring: { text: 'home.monitoring-lists', action: 'home.monitoring.action', icon: <Icon type="filter-search" /> },
  export: { text: 'home.exports', action: 'home.exports.action', icon: <Icon type="filter-export" /> },
  // disconnection: {
  //   text: 'home.disconnection',
  //   action: 'home.disconnection.action',
  //   icon: <Icon type="filter-folder" />,
  // },
  // normative: { text: 'home.normative', action: 'home.normative.action', icon: <Icon type="filter-search" /> },
  // monitoring: { text: 'home.monitoring', action: 'home.monitoring.action', icon: <Icon type="filter-search" /> },
  // visit: { text: 'home.recents', action: 'home.accessed', icon: <Icon type="filter-clock" /> },
};

class ActivityPage extends React.PureComponent {
  state = {
    type: '',
    page: 1,
  };

  columns = [
    {
      className: 'collapsing icon-column',
      title: '',
      dataIndex: 'type',
      render: type => (
        <Icon type="circle">
          <Icon type={type} />
        </Icon>
      ),
    },
    {
      className: 'collapsing date-column',
      title: this.props.t('other.date'),
      dataIndex: 'date_created',
      render: date => <Time date={date} relative prefix />,
    },
    {
      title: this.props.t('other.name'),
      className: 'desc-column',
      render: (text, record, key) => (
        <div
          className={classnames('zh-homepage-title', {
            'title-loading': this.props.api.loading && this.state.page > 1 && this.props.api.data.length - 25 <= key,
          })}
        >
          {!this.props.location.search && types[record.type] && (
            <span className="title">{this.props.t(types[record.type].text)}</span>
          )}

          <span className="desc">
            <b>{record.first_name}</b>&nbsp;
            <b>{record.last_name}</b>&nbsp;
            {this.props.t(`${record.url ? `home.${record.url}` : 'home.accessed'}`)}&nbsp;
            {/* {record.type !== 'monitoring' && (
              <b>
                <span
                  dangerouslySetInnerHTML={{
                    __html: String(record.name || has(record, 'export.name')),
                  }}
                />
              </b>
            )} */}
            {record.params?.companies_names && <b>{record.params.companies_names[0]}</b>}
            {record.data?.list_name && <b>{record.data.list_name}</b>}
            {record.params?.name && <b>{record.params.name}</b>}
            {record.data?.company_name && record.data.company_name.slice(0, 3).map(item => <b>{item}, </b>)}
            {record.data?.company_name && record.data.company_name.slice(0, 3).length === 3 && <b>...</b>}
            {record.data?.search_list_name && <b>{record.data?.search_list_name}</b>}
            {record.search_text && <b dangerouslySetInnerHTML={{ __html: record.search_text && record.search_text }} />}
          </span>
        </div>
      ),
    },
    {
      className: 'collapsing arrow-column svg-blue',
      title: '',
      render: (record, rowIndex) => (
        <span
          onClick={e => {
            this.onSelect(rowIndex);
          }}
        >
          {/* <Icon type="arrow-long" /> */}
        </span>
      ),
    },
  ];

  componentDidMount() {
    setDocumentTitle(this.props.t('page.home'));

    const { type = '' } = parseQuery(this.props.location.search || '');

    this.onChangeType(type);
  }

  onFetch(newType) {
    this.props.request(
      dashboard.list({
        page: this.state.page,
        per_page: 25,
        type: this.state.type,
      }),
      { infinityScroll: this.state.page !== 1 },
    );
  }

  onChangeType = async type => {
    await this.setState({ type, page: 1 });
    this.onFetch();
  };

  onChangePage = async page => {
    await this.setState({ page });
    this.onFetch();
  };

  async onSelect(item) {
    if (item.parent_id) {
      return this.props.history.push(getRoute('SubdivisionPage', { id: item.id }));
    }

    if (item.employee_id) {
      return this.props.history.push(getRoute('ExecutivePage', { id: item.id }));
    }

    if (item.company_id) {
      return this.props.history.push(getRoute('GeneralsPage', { id: item.id }));
    }

    if (item.type === 'search') {
      // hidden for later
      // const { data } = await lists.searchCompanies(item.params);
      // this.props.setActiveFilter(item.params);
      // return this.props.history.push(getRoute('SearchResultsPage', { type: 'company', mode: 'basic' }));
    }

    if (item.type === 'saved_search') {
      return this.props.history.push(getRoute('DownloadsPage', { type: 'company', page: 1 }));
    }

    if (item.type === 'lists') {
      return this.props.history.push(getRoute('SearchResultsPage', { type: 'company', mode: item.id }));
    }

    if (item.type === 'export') {
      return this.props.history.push(getRoute('DownloadsPage', { type: 'list', page: 1 }));
    }

    message.error(this.props.t('other.error_module'));

    return null;
  }

  render() {
    const { api, t } = this.props;

    return (
      <Layout>
        <div className="home-back-button">
          <Link route="HomePage">&larr; {t('home.redirect_back_activity')}</Link>
        </div>

        <Tabs className="home-filter">
          <Tabs.TabPane
            tab={
              <Link route="ActivityPage" onClick={() => this.onChangeType('')}>
                <div className={`home-filter-item ${!this.state.type ? 'active' : ''}`}>
                  <Icon type="filter-folder" />
                  {t('home.all')}
                </div>
              </Link>
            }
            key="all"
          />

          {Object.keys(types).map(key => (
            <Tabs.TabPane
              tab={
                <Link route="ActivityPage" query={{ type: key }} onClick={() => this.onChangeType(key)}>
                  <div className={`home-filter-item ${this.state.type === key ? 'active' : ''}`}>
                    {types[key].icon}
                    {t(types[key].text)}
                  </div>
                </Link>
              }
              key={key}
            />
          ))}
        </Tabs>

        {api.empty && <EmptyBlock type={this.state.type} />}

        {!api.empty && (
          <Loader loading={api.loading && this.state.page === 1}>
            {/* <InfiniteScroll
              initialLoad={false}
              pageStart={1}
              loadMore={this.onChangePage}
              hasMore={!api.loading && this.state.page < api.pages}
            > */}
            <div
              className="fade-in activity-table-wrap"
              style={{ display: api.loading && this.state.page === 1 ? 'none' : 'block' }}
            >
              <Table
                className="very basic"
                columns={this.columns}
                dataSource={api.data}
                rowClassName="homepage-row"
                // on click
                // onRow={(record, rowIndex) => {
                //   return {
                //     onClick: event => {
                //       this.onSelect(record);
                //     },
                //   };
                // }}
              />

              {api.loading && this.state.page > 1 && <Loader.Inline className="table-loader" loading />}
            </div>
            {/* </InfiniteScroll> */}
            <TableFooter
              rightSide={<Pagination page={this.state.page} total={api.pages} onChange={this.onChangePage} />}
            />
          </Loader>
        )}
      </Layout>
    );
  }
}

const enhance = compose(
  injectIntl,
  connect(null, dispatch =>
    bindActionCreators(
      {
        setActiveFilter,
      },
      dispatch,
    ),
  ),
  withRequest(),
);

export default enhance(ActivityPage);
