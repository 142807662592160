import React from 'react';
import { useHistory } from 'react-router-dom';
import { Drawer } from 'antd';
import { useDispatch } from 'react-redux';
import MediaQuery from 'react-responsive';
import responsive from '~/libs/responsive';
import { ProfileLinks } from '~/components/profile';
import { Confirmation, Text } from '~/components/ui';
import { Icon } from '~/components/ui/Icon/Icon';
import { deleteToken } from '~/store/actions/AuthenticationActions';

const Sidebar = props => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onLogout = () => {
    dispatch(deleteToken());
    return history.push('/');
  };

  return (
    <div className="sidebar">
      <MediaQuery maxWidth={responsive.desktop}>
        <Drawer
          placement="left"
          closable={false}
          onClose={props.onCloseSidebar}
          visible={props.isDrawerVisible}
          className="drawer-legal-sidebar"
          width="auto"
        >
          <ProfileLinks type="profile-list" />
        </Drawer>
      </MediaQuery>

      <MediaQuery minWidth={responsive.desktop}>
        <ProfileLinks type="profile-list" />

        <li className="item item-exit">
          <Confirmation type="logout" onConfirm={onLogout}>
            <span className="link">
              <div className="svg-container">
                <Icon type="exit" />
              </div>
              <Text id="profile.logout" />
            </span>
          </Confirmation>
        </li>
      </MediaQuery>
    </div>
  );
};

export default Sidebar;
