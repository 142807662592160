import React from 'react';
import { useStateHandlers } from '~/hooks';
import moment from '~/libs/moment';
import { injectIntl } from '~/libs/localization';
import { has } from '~/libs/object';
import { Modal, Empty } from '~/components/ui';
import { JuridicalCardTabs } from '~/components/company/juridical';
import {
  JuridicalInformationGeneral,
  JuridicalProcessParticipants,
  JudicialProcessSessions,
  JuridicalResult,
  JuridicalPublications,
} from '~/components/company/juridical/info';

const JuridicalInfo = ({ record, recordNumber, type, t, ...props }) => {
  const [state, setState] = useStateHandlers({
    tab: 1,
  });

  const onChangeTab = tab => setState({ tab });

  const isActive = tab => (state.tab === tab ? 'active' : '');

  return (
    <Modal
      className="juridical-info modal-body-0"
      width="1240px"
      onClose={() => props.onClose()}
      title={
        <div>
          <span>{recordNumber}</span>
          <h1>{has(record, 'subject.title', <Empty />)}</h1>

          <p>
            {t({ id: 'juridical.lastModif' })} -{' '}
            {has(record, 'process_session_result.date') ? (
              moment(record.process_session_result.date).format('YYYY.MM.DD')
            ) : (
              <Empty text="necunoscut" />
            )}
          </p>
        </div>
      }
    >
      <JuridicalCardTabs
        isActive={isActive}
        onChangeTab={onChangeTab}
        processParticipantsLength={has(record, 'process_participants', []).length}
        juridicalProccessSessionsLength={has(record, 'judicial_process_sessions', []).length}
        processSessionResult={has(record, 'process_session_result')}
        juridicalProcessMonitorOfficialLength={has(record, 'judicial_process_monitor_official', []).length}
      />

      {isActive(1) && (
        <JuridicalInformationGeneral
          instance={record.instance}
          number={record.number}
          judgesTitle={has(record, 'judges.title')}
          subjectTitle={has(record, 'subject.title')}
          type={type}
        />
      )}

      {isActive(2) && <JuridicalProcessParticipants processParticipants={has(record, 'process_participants', [])} />}

      {isActive(3) && (
        <JudicialProcessSessions judicialProcessSessions={has(record, 'judicial_process_sessions', [])} />
      )}

      {isActive(4) && (
        <JuridicalResult
          processSessionResult={has(record, 'process_session_result', {})}
          judgesTitle={has(record, 'judges.title')}
        />
      )}

      {isActive(5) && <JuridicalPublications monitorOfficial={has(record, 'judicial_process_monitor_official', [])} />}
    </Modal>
  );
};

export default injectIntl(JuridicalInfo);
