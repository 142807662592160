import React from 'react';
import { connect } from 'react-redux';
import { Alert, Form, Input, Button } from 'antd';
import { useRequest } from 'estafette';
import { useStateHandlers, useDocumentTitle } from '~/hooks';
import { profile } from '~/libs/http/api';
import { validate } from '~/libs/object';
import { useInjectIntl } from '~/libs/localization';
import { AvatarManager } from '~/components/profile';
import { Loader } from '~/components/ui';
import UserSection from '~/components/svg/UserSection';
import { ProfileLayout } from '~/components/layouts';

import '~/assets/scss/components/_MyProfile.scss';
import '~/assets/scss/components/_Help.scss';

const initialInputs = {
  subject: '',
  message: '',
};

const HelpPage = ({ manager = {}, userLoading, ...props }) => {
  const { t } = useInjectIntl();
  useDocumentTitle(t('profile.help'));
  const { request, errors } = useRequest();
  const [state, setState] = useStateHandlers({
    sent: false,
    changed: false,
    ...initialInputs,
  });

  const onSubmit = async ev => {
    ev.preventDefault();

    await request(
      profile.helpSend({
        title: state.subject,
        text: state.message,
      }),
    );

    setState({ changed: false, sent: true, ...initialInputs });
  };

  const onCloseSent = () => setState({ sent: false, changed: false, ...initialInputs });
  const onChangeField = (key, value) => setState({ changed: true, [key]: value });
  const onChangeSubject = ({ target }) => onChangeField('subject', target.value);
  const onChangeMessage = ({ target }) => onChangeField('message', target.value);

  return (
    <ProfileLayout>
      <div className="title">{t('profile.help')}</div>

      <Form onSubmit={onSubmit} className="help-form">
        <div className="wrap-columns">
          <div className="column">
            <div className="sub-title">{t('manager.account')}</div>

            <div className="manager-info">
              <div className="profile-avatar">
                <AvatarManager data={manager} loading={userLoading} />
              </div>

              <div className="info">
                {manager.phone && (
                  <div className="item">
                    <div className="sub-title">{t('manager.phoneTitle')}:</div>
                    {manager.phone}
                  </div>
                )}

                <div className="item">
                  <div className="sub-title">Email</div>
                  <a href={`mailto://${manager.email}`} target="_blank" rel="noopener noreferrer">
                    {manager.email}
                  </a>
                </div>

                <div className="item">
                  <div className="sub-title">{t('manager.sheduleTitle')}</div>
                  {t('manager.shedule')}
                </div>

                <div className="item">
                  <div className="sub-title">{t('manager.hoursTitle')}</div>
                  08:00 - 18:00
                </div>
              </div>

              <div className="note">{t('manager.supportNote')}</div>
            </div>
          </div>

          <div className="column">
            <Loader loading={false}>
              <div className="support-form">
                <div className="sub-title">{t('manager.ask')}</div>

                {!state.sent && (
                  <>
                    {errors.detail && <Alert message={errors.detail} className="mb-20" type="warning" />}

                    <Form.Item label={t('other.subject')} {...validate(errors, 'title')}>
                      <Input name="subject" type="text" autoFocus value={state.subject} onChange={onChangeSubject} />
                    </Form.Item>

                    <Form.Item label={t('other.message')} {...validate(errors, 'text')}>
                      <Input.TextArea
                        name="message"
                        autosize={{ minRows: 4, maxRows: 6 }}
                        value={state.message}
                        onChange={onChangeMessage}
                      />
                    </Form.Item>
                  </>
                )}

                {state.sent && (
                  <div className="support-sent">
                    <div className="header-wrapper">
                      <UserSection.Success />

                      <div className="title">{t('manager.success')}</div>
                      <div className="text">{t('manager.answer')}</div>
                    </div>
                  </div>
                )}

                <div className="form-footer">
                  <Button onClick={onCloseSent} disabled={!state.changed}>
                    {t({ id: 'other.reset' })}
                  </Button>

                  <Button type="primary" htmlType="submit" disabled={!state.changed} loading={false}>
                    {t({ id: 'other.submit' })}
                  </Button>
                </div>
              </div>
            </Loader>
          </div>
        </div>
      </Form>
    </ProfileLayout>
  );
};

export default connect(({ authentication }) => ({
  userLoading: authentication.loading,
  manager: authentication.params.manager,
}))(HelpPage);
