import React from 'react';
import { useStateHandlers } from '~/hooks';
import { has } from '~/libs/object';

const withRequest = (options = {}) => Component => props => {
  const [api, setApiState] = useStateHandlers({
    data: [],
    empty: false,
    errors: {},
    loading: options.loading || false,
    pages: 1,
    total: 0,
  });

  const validate = key =>
    api.errors[key]
      ? {
          validateStatus: 'error',
          help: api.errors[key],
        }
      : null;

  const request = async (fn, params = {}) => {
    setApiState({ empty: false, loading: true });

    try {
      const { data } = await fn;
      setApiState({
        data: Array.isArray(data)
          ? data
          : params.infinityScroll
          ? [...api.data, ...has(data, 'data', [])]
          : data.data
          ? has(data, 'data', [])
          : data,
        empty: Array.isArray(data) ? data.length === 0 : has(data, 'data', []).length === 0,
        errors: {},
        loading: false,
        pages: has(data, 'pages', 1),
        total: has(data, 'total_results', 0),
      });

      return Promise.resolve(data);
    } catch (errors) {
      global.console.log(errors);

      setApiState({
        errors: {
          ...has(errors, 'response.data', {}),
          // ...has(errors, `response.data.${options.flatten || ''}`),
        },
        loading: false,
      });

      return Promise.reject(errors);
    }
  };

  return <Component {...props} api={api} setApiState={setApiState} request={request} validate={validate} />;
};

export default withRequest;
