import React, { useState, useEffect, useMemo } from 'react';
import { Menu } from 'antd';
import { Link } from '~/libs/router';
import { useInjectIntl } from '~/libs/localization';
import { Icon } from '~/components/ui/Icon/Icon';

import '~/assets/scss/components/_Sidebar.scss';

const SidebarAlerts = ({ mode, data }) => {
  const { t } = useInjectIntl();

  const [unreadMessageOnly, setUnreadMessageOnly] = useState(false);

  useEffect(() => {
    if (mode === 'messages') {
      setUnreadMessageOnly(data.unread);
    }
  }, [mode, data]);

  const messageQuantity = useMemo(() => (mode === 'messages' ? data.unread + data.read : false), [mode, data]);

  return (
    <div className="sidebar">
      <Menu className="messages-menu" mode="inline" defaultOpenKeys={['sub1']}>
        <Menu.SubMenu
          key="sub1"
          title={
            <>
              <Icon type="task-list-approve" />
              {t('alerts.allMessages')}
              {!messageQuantity < 1 && messageQuantity && <span className="tag">{messageQuantity}</span>}
            </>
          }
        >
          <Menu.Item>
            <Link route="Messages" params={{ filter: 'unread', mode: 'messages' }}>
              <Icon type="unread-list" />
              {t('alerts.unread')}
              {!unreadMessageOnly < 1 && unreadMessageOnly !== 0 && (
                <>
                  <span className="tag2">{unreadMessageOnly}</span>
                  <span className="arrow-right">
                    <Icon type="arrow-right-way" />
                  </span>
                </>
              )}
            </Link>
          </Menu.Item>
          <Menu.Item>
            <Link route="Messages" params={{ filter: 'read', mode: 'messages' }}>
              <Icon type="read-list" />

              {t('alerts.read')}
            </Link>
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.Item key="menu-alerts">
          <Link route="Messages" params={{ filter: 'all', mode: 'alerts' }}>
            <Icon type="menu-alerts" />
            {t('alerts.alerts')}
          </Link>
        </Menu.Item>

        <Menu.Item key="menu-monitoring">
          <Link route="Messages" params={{ filter: 'all', mode: 'monitoring' }}>
            <Icon type="menu-monitoring" />
            {t('alerts.monitoring')}
          </Link>
        </Menu.Item>

        <Menu.Item key="menu-legal-bulletin">
          <Link route="Messages" params={{ filter: 'all', mode: 'legal-bulletin' }}>
            <Icon type="menu-legal-bulletin" />
            {t('alerts.legalBulletin')}
          </Link>
        </Menu.Item>

        {/* <Menu.Item>
          <Link route="HomePage">
            <Icon type="app-window-refresh" />
            Updates
          </Link>
        </Menu.Item>

        <Menu.Item>
          <Link route="HomePage">
            <Icon type="newspaper-fold" />
            Blog / Noutăți
          </Link>
        </Menu.Item> */}
      </Menu>
    </div>
  );
};

export default SidebarAlerts;
