import axios from '~/libs/http/axios';

const { baseURL } = axios.defaults;

const subscription = {
  metrics: (params = {}) => axios.get(`${baseURL}/api/subscription/metrics`, { params }),
  packages: (params = {}) => axios.get(`${baseURL}/api/subscription/packages`, { params }),
  upgrade: ({ id, ...option } = {}) => axios.post(`${baseURL}/api/subscription/packages/upgrade/${id}`, option),
};

export default subscription;
