import * as React from 'react';
import { clearClassName } from '~/libs/node';
import { UnavailableData } from '~/components/ui';
import { useInjectIntl } from '~/libs/localization';

const ContactComponent = ({ className = '', icon, type, value }) => {
  const { t } = useInjectIntl();

  const placeholders = {
    phone: t('contact.phone'),
    mobile: t('contact.phone_mobile'),
    fax: t('contact.fax'),
    email: 'Email',
    website: t('contact.website'),
  };

  return (
    <div
      className={clearClassName(`
          contacts 
          contacts-${value ? 'not-empty' : 'empty'} 
          ${className}
        `)}
    >
      {icon && <div className="contacts-icon">{icon}</div>}

      <span>{placeholders[type]}</span>
    </div>
  );
};

const Contact = props =>
  props.value ? (
    <UnavailableData placement="topLeft">
      <div>
        <ContactComponent {...props} />
      </div>
    </UnavailableData>
  ) : (
    <ContactComponent {...props} />
  );

export default Contact;
