import React, { useState } from 'react';
import { useRequest } from 'estafette';
import { useInjectIntl } from '~/libs/localization';
import { useHistory } from 'react-router-dom';
import { getRoute } from '~/libs/router';
import { Input } from 'antd';
import env from '@beam-australia/react-env';
import { useDocumentTitle } from '~/hooks';
import { accountTeam } from '~/libs/http/api';
import { ProfileLayout } from '~/components/layouts';
import { CompanyLogo, SearchAddCompanyBox } from '~/components/profile';
import { Section, Form, Button, Text } from '~/components/ui';
import { validate } from '~/libs/object';
import { Icon } from '~/components/ui/Icon/Icon';
// import { AddInputField } from '~/components/addInputField';

import '~/assets/scss/components/_MyProfile.scss';

const AddCompanyPage = () => {
  const { t } = useInjectIntl();
  const history = useHistory();
  const { loading: loadingData, data } = useRequest();
  const { request: requestPhoto, loading: loadingPhoto } = useRequest();
  const { request: requestSubmit, loading: loadingSubmit, errors: errorsSubmit } = useRequest();
  // const { request: requestSeniorities } = useRequest();
  const [api, setApi] = useState({});
  const [preview, setPreview] = useState('');
  const [companyName, setCompanyName] = useState();
  const [companyIdno, setCompanyIdno] = useState();
  const [companyAddress, setCompanyAddress] = useState();
  const [saveFax, setSaveFax] = useState([]);
  const [savePhone, setSavePhone] = useState([]);
  const [saveWeb, setSaveWeb] = useState([]);
  const [saveEmail, setSaveEmail] = useState([]);
  const [savePhoto, setSavePhoto] = useState(null);

  const photo = data.photo ? `${env('API_URL') || ''}/${data.photo || ''}` : null;

  useDocumentTitle(t('page.profile'));

  const onChangeFile = ({ target }) => {
    const image = target.files[0];
    const reader = new FileReader();

    reader.readAsDataURL(image);

    reader.onloadend = () => {
      setApi({
        ...api,
        image,
      });

      setPreview(reader.result);
    };

    setSavePhoto(image);
  };

  const onDeletePhoto = () => {
    setApi({ ...api, photo: '' });
    setPreview('');
  };

  const onSubmit = async () => {
    const http = 'http://';

    let options = {
      name: companyName,
      idno: companyIdno,
      address: companyAddress,
      email: saveEmail || '',
      website: http.concat(saveWeb) || '',
    };

    if (saveFax.length) {
      options = { ...options, fax: saveFax };
    }

    if (savePhone.length) {
      options = { ...options, telephone: savePhone };
    }

    const { id } = await requestSubmit(accountTeam.addCompany(options));

    if (preview) {
      const formData = new FormData();
      formData.append('photo', savePhoto);
      formData.append('id', id);
      formData.append('is_approved', true);
      requestPhoto(accountTeam.editCompany(formData));
    }

    history.push(getRoute('TeamPage'));
  };

  const onSaveCompanyFilds = (name, idno, address) => {
    setCompanyName(name);
    setCompanyIdno(idno);
    setCompanyAddress(address);
  };

  const clearInputs = () => {
    setCompanyName(null);
    setCompanyIdno(null);
    setCompanyAddress(null);
  };

  return (
    <ProfileLayout loading={loadingData} className="add-company-container">
      <div className="title">
        {api.is_staff ? <Text id="profile.addCompanyTitle" /> : t({ id: 'profile.addCompanyTitle' })}
      </div>

      <div className="half-sections no-half-section section-settings">
        <Section>
          <div className="profile-avatar company-logo">
            <CompanyLogo.Editable img={preview || photo} onClose={onDeletePhoto} loading={loadingPhoto} />

            <h2>
              <Text id="profile.uploadCompanyLogo" />
            </h2>

            <label className="atn-file">
              <input type="file" accept="image/*" onChange={onChangeFile} />

              <Text id="profile.uploadAnImage" />
            </label>

            <Button onClick={onDeletePhoto}>
              <Text id="profile.deleteImage" />
            </Button>
          </div>
        </Section>

        <Section>
          {/* <div className={`profile-approved ${!api.is_approved ? 'not-' : ''}approved`}>
            {t(`profile.${!api.is_approved ? 'not_' : ''}approved`)}
          </div> */}

          <Form>
            <>
              <div className="half-title">{t({ id: 'profile.selectCompany' })}</div>

              <Form.Half>
                <SearchAddCompanyBox placeholder="other.searchBox" saveFilds={onSaveCompanyFilds} />
              </Form.Half>

              <div className="company-profile-form">
                <Form.Half>
                  <div className="half-title">
                    {t('profile.companyProfile')}

                    {companyName && (
                      <span className="remove-selection" onClick={clearInputs}>
                        <Icon type="trash" />
                        {t('profile.removeSelection')}
                      </span>
                    )}
                  </div>

                  <Form.Item label={t({ id: 'profile.companyName' })} {...validate(errorsSubmit, 'name')}>
                    <Input
                      type="text"
                      disabled
                      placeholder={companyName || t({ id: 'other.typeHere' })}
                      onChange={() => {
                        setApi({ ...api, name: companyName });
                      }}
                    />
                  </Form.Item>

                  <Form.Item label="IDNO" {...validate(errorsSubmit, 'idno')}>
                    <Input
                      type="text"
                      disabled
                      placeholder={companyIdno || 'IDNO'}
                      onChange={() => {
                        setApi({ ...api, idno: companyIdno });
                      }}
                    />
                  </Form.Item>
                </Form.Half>

                <Form.Item label={t({ id: 'profile.address' })} {...validate(errorsSubmit, 'address')}>
                  <Input
                    type="text"
                    disabled
                    placeholder={companyAddress || t({ id: 'other.typeHere' })}
                    onChange={() => {
                      setApi({ ...api, address: companyAddress });
                    }}
                  />
                </Form.Item>
              </div>

              <div className="contact-information-form">
                <Form.Half>
                  <div className="half-title">{t('profile.contactInformation')}</div>

                  <Form.Item label={t({ id: 'profile.website' })} {...validate(errorsSubmit, 'website')}>
                    <Input
                      type="text"
                      placeholder={t({ id: 'other.typeHere' })}
                      onChange={e => {
                        setSaveWeb(e.target.value);
                      }}
                    />
                  </Form.Item>

                  <Form.Item label={t({ id: 'profile.email' })} {...validate(errorsSubmit, 'email')}>
                    <Input
                      type="text"
                      placeholder={t({ id: 'other.typeHere' })}
                      onChange={e => {
                        setSaveEmail(e.target.value);
                      }}
                    />
                  </Form.Item>
                  {/* <Form.Item label={t({ id: 'profile.website' })} {...validate(errorsSubmit, 'website')}>
                    <Input.Group className="add-field-group" id="website">
                      <AddInputField label={t({ id: 'other.typeHere' })} onSaveFax={setSaveWeb} />
                    </Input.Group>
                  </Form.Item>

                  <Form.Item label={t({ id: 'profile.email' })} {...validate(errorsSubmit, 'email')}>
                    <Input.Group className="add-field-group" id="email">
                      <AddInputField label={t({ id: 'other.typeHere' })} onSaveFax={setSaveEmail} />
                    </Input.Group>
                  </Form.Item> */}
                </Form.Half>

                <Form.Half>
                  <Form.Item label={t({ id: 'profile.phone' })} {...validate(errorsSubmit, 'phone')}>
                    <Input
                      type="text"
                      placeholder={t({ id: 'other.typeHere' })}
                      onChange={e => {
                        setSavePhone(e.target.value);
                      }}
                    />
                  </Form.Item>
                  <Form.Item label={t({ id: 'profile.fax' })} {...validate(errorsSubmit, 'fax')}>
                    <Input
                      type="text"
                      placeholder={t({ id: 'other.typeHere' })}
                      onChange={e => {
                        setSaveFax(e.target.value);
                      }}
                    />
                  </Form.Item>
                  {/* <Form.Item label={t({ id: 'profile.phone' })} {...validate(errorsSubmit, 'telephone')}>
                    <Input.Group className="add-field-group" id="telephone">
                      <AddInputField label={t({ id: 'other.typeHere' })} onSaveFax={setSavePhone} />
                    </Input.Group>
                  </Form.Item>

                  <Form.Item label={t({ id: 'profile.fax' })} {...validate(errorsSubmit, 'fax')}>
                    <Input.Group className="add-field-group" id="fax">
                      <AddInputField label={t({ id: 'other.typeHere' })} onSaveFax={setSaveFax} />
                    </Input.Group>
                  </Form.Item> */}
                </Form.Half>
              </div>

              <Form.Half>
                <div className="form-footer">
                  <Button type="reset" onClick={() => history.push(getRoute('TeamPage'))}>
                    {t({ id: 'other.reset' })}
                  </Button>

                  <Button
                    type="primary"
                    onClick={() => onSubmit()}
                    loading={loadingSubmit}
                    form="dynamic-input"
                    htmlType="submit"
                  >
                    {t({ id: 'other.submit' })}
                  </Button>
                </div>
              </Form.Half>
            </>
          </Form>
        </Section>
      </div>
    </ProfileLayout>
  );
};

export default AddCompanyPage;
