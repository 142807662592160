import { combineReducers } from 'redux';
import { companies } from './companies';
import { filter } from './filter';
import { localization } from './localization';
import { authentication } from './authentication';
import { company } from './company';
import { searches } from './searches';
import { subdivision } from './subdivision';
import { employee } from './employee';
import { limits } from './limits';
import { legalBulletin } from './legalBulletin';
import { monitoringSidebar } from './monitoringSidebar';

export const rootReducer = combineReducers({
  filter,
  monitoringSidebar,
  localization,
  authentication,
  company,
  searches,
  employee,
  limits,
  subdivision,
  companies,
  legalBulletin,
});
