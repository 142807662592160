import React from 'react';
import { injectIntl } from '~/libs/localization';
import { Link } from '~/libs/router';
import { Icon } from '~/components/ui/Icon/Icon';

const Notification = injectIntl(({ id, t }: { status: boolean } & InjectIntlType) => (
  <span className="notification-status">
    <Link route="AlertsListsPage" params={{ page: 1, id }}>
      <Icon type="notification-normal" />

      {t({ id: 'notification' })}
    </Link>
  </span>
));

export default Notification;
