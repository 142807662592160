import React from 'react';
import IntlTelInput from 'react-intl-tel-input';

import 'react-intl-tel-input/dist/main.css';
import './InputPhone.scss';

export const InputPhone = ({ onChangePhone, onAddPhone }) => {
  return (
    <IntlTelInput
      containerClassName="phone-input"
      autoPlaceholder={false}
      separateDialCode
      defaultCountry="md"
      allowDropdown={false}
      placeholder="63 735 432"
      onPhoneNumberChange={onChangePhone}
      onPhoneNumberBlur={onAddPhone}
    />
  );
};
