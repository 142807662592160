import React, { useEffect, useState } from 'react';
import MediaQuery from 'react-responsive';
import { useRouteMatch } from 'react-router-dom';
import { Button, Drawer } from 'antd';
import { useRequest } from 'estafette';
import { useInjectIntl } from '~/libs/localization';
import { useStateHandlers } from '~/hooks';
import responsive from '~/libs/responsive';
import { messages, alerts, monitoring, legalBulletin } from '../libs/http/api';
import { Layout } from '~/components/layouts';
import { Icon } from '~/components/ui/Icon/Icon';
import { MessagesArea, SidebarMessages } from '../components/messages';

import '~/assets/scss/components/_Monitoring.scss';

const initialFilter = {
  page: 1,
  is_read: undefined,
  date_start: undefined,
  date_end: undefined,
};

const Messages = () => {
  const { t } = useInjectIntl();
  const match = useRouteMatch();
  const { request: requestData, data, loading } = useRequest();

  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const [filter, setFilter] = useStateHandlers(JSON.parse(JSON.stringify(initialFilter)));

  useEffect(() => {
    setFilter(JSON.parse(JSON.stringify(initialFilter)));
  }, [match.params.mode]);

  useEffect(() => {
    onFetchData();
  }, [filter]);

  useEffect(() => {
    if (match.params.filter === 'read' || match.params.filter === 'unread') {
      setFilter({
        is_read: match.params.filter === 'read',
      });
    }
  }, [match.params.filter]);

  const onChangeDateStart = date => setFilter({ date_start: date });

  const onChangeDateEnd = date => setFilter({ date_end: date });

  const onChangePage = page => setFilter({ page });

  const onShowSidebar = () => setIsDrawerVisible(true);

  const onCloseSidebar = () => setIsDrawerVisible(false);

  const onFetchData = async () => {
    const params = {
      ...filter,

      industry: [1],
      per_page: 15,
    };

    let api = messages.getList;

    if (match.params.mode === 'alerts') {
      params.water = true;
      params.energy = true;
      params.control = true;
      params.is_read = undefined;
      api = alerts.getList;
    }

    if (match.params.mode === 'monitoring') {
      params.event_type = [];
      params.is_read = undefined;
      api = monitoring.getNotifications;
    }

    if (match.params.mode === 'legal-bulletin') {
      params.is_read = undefined;
      api = legalBulletin.getBulletins;
    }

    requestData(api(params));
  };

  return (
    <Layout containerClass="monitoring-container messages-container">
      <MediaQuery maxWidth={responsive.desktop}>
        <Button type="primary" className="filter-btn" onClick={onShowSidebar}>
          <Icon type="menu-navigation" />
          {t('monitorig.menu')}
        </Button>
      </MediaQuery>

      <MediaQuery maxWidth={responsive.desktop}>
        <Drawer
          placement="left"
          closable={false}
          onClose={onCloseSidebar}
          visible={isDrawerVisible}
          className="drawer-legal-sidebar"
          width="auto"
        >
          <SidebarMessages data={data} mode={match.params.mode} />
        </Drawer>
      </MediaQuery>

      <MediaQuery minWidth={responsive.desktop}>
        <SidebarMessages data={data} mode={match.params.mode} />
      </MediaQuery>

      <MessagesArea
        page={filter.page}
        data={data}
        loading={loading}
        onChangeDateStart={onChangeDateStart}
        onChangeDateEnd={onChangeDateEnd}
        onChangePage={onChangePage}
        mode={match.params.mode}
      />
    </Layout>
  );
};

export default Messages;
