import React from 'react';
import { useInjectIntl } from '~/libs/localization';
import { Timeline, Col } from 'antd';
import { useSubdivision } from '~/hoc/withSubdivision';
import { useDocumentTitle } from '~/hooks';
import { has } from '~/libs/object';
import { Link } from '~/libs/router';
import { CompanyLayout } from '~/components/layouts';
import { Segment, Info } from '~/components/company';
import { Loader, Map, Empty, Rows, Table } from '~/components/ui';
import { Icon } from '~/components/ui/Icon/Icon';

const SubdivisionPage = () => {
  const { t } = useInjectIntl();
  const { subdivision: { data: api, loading } } = useSubdivision(); // prettier-ignore

  useDocumentTitle(t('page.subdivision'));

  const columns = [
    {
      className: 'td-key',
      render: (_, data1, key) => <span textAlign="center">{data1.title}</span>,
      width: 40,
    },
    {
      render: (item, data2, key) => <Col>{data2.value}</Col>,
      width: 100,
    },
  ];

  const columns2 = [
    {
      title: t({ id: 'profile.lastName' }),
      render: () => (
        <span>
          <a href="/">Ştefan cel Mare şi Sfânt</a>
        </span>
      ),
      width: 100,
    },
    {
      title: t({ id: 'profile.level' }),
      render: () => <span>VC</span>,
      width: 100,
    },
    {
      title: t({ id: 'profile.job' }),
      render: () => <span>Manager Penuștiuce</span>,
      width: 100,
    },
    {
      title: t({ id: 'other.telephone' }),
      render: () => (
        <span>
          <Icon type="company-telephone" />
          (022) 26-54-00
        </span>
      ),
      width: 100,
    },
    {
      title: t({ id: 'other.email' }),
      render: () => (
        <span>
          <a href="/">
            <Icon type="company-mail" />
            stefan-mare@andys-pizza.com
          </a>
        </span>
      ),
      width: 90,
    },
  ];

  return (
    <CompanyLayout type="subdivision">
      <Loader loading={loading}>
        <Rows
          rows={[
            {
              label: t({ id: 'company.fond' }),
              value: has(api, 'parent_name') ? (
                <Link route="GeneralsPage" params={{ id: api.parent_id }}>
                  {api.parent_name}
                </Link>
              ) : null,
            },
            {
              label: t({ id: 'subdivision.type' }),
              value: '',
            },
            {
              label: t({ id: 'company.employeeCount' }),
              value: has(api, 'personal.length'),
            },
            {
              label: t({ id: 'company.employee' }),
              value: has(api, 'general_data.size.name'),
            },
          ]}
        />

        <Segment.Halves>
          <Segment
            title={t({ id: 'other.infoContacts' })}
            classTitle="title-no-border"
            classContent="contact-info-w-map"
          >
            <Info.Halves>
              <Info label={t({ id: 'company.addressFacto' })}>
                <Info.Detail title={has(api, 'general_data.contact_info.address_de_facto.title')} />
              </Info>

              <Info label={t({ id: 'company.addressJuridical' })}>
                <Info.Detail title={has(api, 'general_data.contact_info.address_de_jur.title')} />
              </Info>
            </Info.Halves>

            <Info.Halves>
              <Info label={t({ id: 'other.telephone' })}>
                <Info.More
                  icon={<Icon type="company-telephone" />}
                  data={has(api, 'general_data.contact_info.phones', [])}
                />
                <Info.More icon={<Icon type="company-fax" />} data={has(api, 'general_data.contact_info.faxes', [])} />
                <Info.More
                  icon={<Icon type="company-mobile" />}
                  data={has(api, 'general_data.contact_info.mobile', [])}
                />
              </Info>

              <Info label={t({ id: 'other.email' })}>
                <Info.More
                  icon={<Icon type="company-mail" />}
                  data={has(api, 'general_data.contact_info.emails', [])}
                  textBlue
                />
              </Info>
            </Info.Halves>

            <Info.Halves>
              <Info label={t({ id: 'other.website' })}>
                <Info.Detail
                  icon={<Icon type="company-website" />}
                  title={has(api, 'general_data.contact_info.sites', []).join(', ') || <Empty />}
                  websites={has(api, 'general_data.contact_info.sites', [])}
                  textBlue
                />
              </Info>

              <Info label={t({ id: 'other.social' })} className="social-media">
                <Info.Media name="facebook" to={has(api, 'general_data.contact_info.facebook')} />
                <Info.Media name="linkedin" to={has(api, 'general_data.contact_info.linkedin')} />
                <Info.Media name="twitter" to={has(api, 'general_data.contact_info.twitter')} />
                <Info.Media name="youtube" to={has(api, 'general_data.contact_info.youtube')} />
              </Info>
            </Info.Halves>

            <div className="google-map google-subdivision-map">
              <div className="google-map-board">
                <p>
                  <Icon type="marker-default" /> {has(api, 'general_data.contact_info.address_de_facto.title')}
                </p>
              </div>

              <Map
                lat={has(api, 'general_data.contact_info.address_de_facto.additional.lat')}
                lng={has(api, 'general_data.contact_info.address_de_facto.additional.long')}
                zoom={9}
                fromCenter={35000}
              />
            </div>
          </Segment>

          <div className="segment-vertical">
            {has(api, 'general_data.business_hours.length') ? (
              <Segment title={t({ id: 'company.schedule' })} noPadding>
                <Table
                  columns={columns}
                  dataSource={api.general_data.business_hours}
                  className="wout-hover no-border"
                />
              </Segment>
            ) : null}

            {has(api, 'financial_data') && (
              <Segment title={t({ id: 'company.bankService' })} grayContent borderBlue>
                <Info>
                  {api.financial_data.banks.map((title, key) => (
                    <Info.Detail key={title} title={title} textBlue />
                  ))}
                </Info>
              </Segment>
            )}
          </div>
        </Segment.Halves>

        {has(api, 'personal.length') ? (
          <Segment title={t({ id: 'subdivision.personal' })} noPadding>
            <Table columns={columns2} className="wout-hover no-border" />
          </Segment>
        ) : null}

        {has(api, 'general_data.types_of_activity.products_and_services.length') &&
        has(api, 'general_data.types_of_activity.activity_unlicensed.length') &&
        has(api, 'general_data.types_of_activity.activity_licensed.length') ? (
          <Segment title={t({ id: 'company.economy' })} classContent="container-flex" noPadding>
            {has(api, 'general_data.types_of_activity.products_and_services.length') ? (
              <Segment.Section
                title={`${t({ id: 'company.productsServices' })} (${
                  api.general_data.types_of_activity.products_and_services.length
                })`}
              >
                <Timeline>
                  {api.general_data.types_of_activity.products_and_services.map(row => (
                    <Timeline.Item key={row.title.id}>{has(row, 'title.title', <Empty />)}</Timeline.Item>
                  ))}
                </Timeline>
              </Segment.Section>
            ) : null}

            {has(api, 'general_data.types_of_activity.activity_unlicensed.length') ? (
              <Segment.Section
                title={`${t({ id: 'company.activityUnlicType' })} (${
                  api.general_data.types_of_activity.activity_unlicensed.length
                })`}
              >
                <Timeline>
                  {api.general_data.types_of_activity.activity_unlicensed.map(row => (
                    <Timeline.Item key={row.title.id}>{has(row, 'title.title', <Empty />)}</Timeline.Item>
                  ))}
                </Timeline>
              </Segment.Section>
            ) : null}

            {has(api, 'general_data.types_of_activity.activity_licensed.length') ? (
              <Segment.Section
                title={`${t({ id: 'company.activityLicType' })} (${
                  api.general_data.types_of_activity.activity_licensed.length
                })`}
              >
                {api.general_data.types_of_activity.activity_licensed.map(row => (
                  <Timeline.Item key={row.title.id}>{has(row, 'title.title', <Empty />)}</Timeline.Item>
                ))}
              </Segment.Section>
            ) : null}
          </Segment>
        ) : null}
      </Loader>
    </CompanyLayout>
  );
};

export default SubdivisionPage;
