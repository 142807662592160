import * as React from 'react';
import { Button as AntdButton } from 'antd';

const Button = ({ disabled, loading = false, ...props }) => (
  <AntdButton disabled={disabled || loading} {...props}>
    {props.children}
  </AntdButton>
);

export default Button;
