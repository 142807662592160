import React from 'react';
import { Text, Empty } from '~/components/ui';
import { Icon } from '~/components/ui/Icon/Icon';

const Segment = props => (
  <div
    ref={props.refs}
    className={`company-segment ${props.className || ''} ${props.noPadding ? 'no-padding' : ''} ${
      props.noMargin ? 'segment-margins' : ''
    } ${props.noBorder ? 'no-border' : ''} ${props.borderTop ? 'segment-border-top' : ''} ${
      props.borderBlue ? 'segment-border-bottom' : ''
    }`}
  >
    {props.header
      ? props.header
      : props.title && (
          <h2
            className={`title ${props.gray ? 'gray' : ''} ${props.number ? 'title-number' : ''} ${props.classTitle ||
              ''}`}
            style={{ textAlign: props.textAlign || null }}
          >
            {props.number && <span className="title-number-span">{props.number}</span>}

            {props.title}

            {props.titleDesc && <span className="title-desc">{props.titleDesc}</span>}

            {props.loading && <Icon type="loading" />}
            {!props.loading && props.onClose && props.hasData ? (
              <small onClick={() => props.onClose()} role="presentation">
                <Icon type="menu-filter-arrow-right" open={props.isOpen} />
              </small>
            ) : null}
          </h2>
        )}

    {props.header ? props.header : props.subTitle && <h3 className="sub-title">{props.subTitle}</h3>}

    <div className={`content ${props.classContent || ''} ${props.grayContent ? 'content-grey' : ''}`}>
      {props.children}
    </div>

    {props.footerDesc && <span className="footer-desc">{props.footerDesc}</span>}
  </div>
);

Segment.Section = props => (
  <section className="company-segment-section" style={{ background: props.gray ? '#fafbfd' : null }}>
    <h2>{props.title || <Empty />}</h2>
    {props.children}
  </section>
);

Segment.Halves = props => (
  <div style={props.style} className={`company-segment-halves ${props.className ? props.className : ''}`}>
    {props.children}
  </div>
);

Segment.Row = props => (
  <section
    className={`company-segment-row ${props.noBorder ? 'row-noborder' : ''}`}
    style={{ background: props.gray ? '#fafbfd' : null }}
  >
    {props.children}
  </section>
);

Segment.Header = props => (
  <section className={`company-segment-header ${props.noBorder ? 'header-noborder' : ''} ${props.className || ''}`}>
    <h2>{props.title}</h2>

    <div className="pull-right">{props.rightSide}</div>
  </section>
);

Segment.More = ({ onChange, open, className = '' }) => (
  <div className={`segment-more ${className}`} onClick={() => onChange()} role="presentation">
    {open && (
      <span>
        <Icon type="arrow-left" /> <Text id="other.hideAll" />
      </span>
    )}

    {!open && (
      <span>
        <Text id="other.showAll" /> <Icon type="arrow-right" />
      </span>
    )}
  </div>
);

export default Segment;
