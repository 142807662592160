import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useInjectIntl } from '~/libs/localization';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { getSaveLimits, setLoading } from '~/store/actions/LimitsActions';

export const useHints = options => {
  const { t } = useInjectIntl();
  const dispatch = useDispatch();
  const params = useParams();

  const store = useSelector(
    ({ limits, searches, authentication, filter }) => ({
      limits: limits.limits,
      loading: limits.loading,
      searches,
      permissions: authentication.permissions,
      activeFilter: filter.activeFilter,
    }),
    shallowEqual,
  );

  const [errors, setErrors] = useState({
    save_lists: { type: null, message: null },
    save_search: { type: null, message: null },
    export: { type: null, message: null },
    company_update: { type: null, message: null },
  });

  useEffect(() => {
    const onGetLimits = () => {
      // get save_lists limits
      if (!store.loading && store.limits.save_lists === null) {
        dispatch(setLoading());
        dispatch(getSaveLimits());
      }
    };

    onGetLimits();
  }, [store.loading, store.limits]);

  useEffect(() => {
    const onCheckSaveListsErrors = () => {
      const { save_lists: limit } = store.limits;
      const current = store.searches.selectedRow.length || store.searches.total;

      let type = null;
      let message = null;

      if (current > limit) {
        type = 'alert';
        message = t('list.add_list_hint', {
          limit,
          type: t(`list.${store.type === 'employee' ? 'employee' : 'company'}`).toLowerCase(),
        });
      }

      if (!store.permissions.includes('saved_list')) {
        type = 'error';
        message = t('other.permission');
      }

      onChangeError('save_lists', {
        type,
        message,
      });
    };

    onCheckSaveListsErrors();
  }, [store.limits, store.permissions, store.searches]);

  useEffect(() => {
    const onCheckSaveSearchErrors = () => {
      let type = null;
      let message = null;

      if (Object.keys(store.activeFilter).length === 0) {
        type = 'error';
        message = t('other.saveSearchDisabled');
      }

      onChangeError('save_search', {
        type,
        message,
      });
    };

    onCheckSaveSearchErrors();
  }, [store.activeFilter]);

  useEffect(() => {
    const onCheckExportErrors = () => {
      let type = null;
      let message = null;

      const exportType = `export_${
        params.type === 'employee' || options.type === 'employee' ? 'employees' : 'companies'
      }`;

      if (!store.permissions.includes(exportType)) {
        type = 'error';
        message = t('other.permission');
      }

      onChangeError('export', {
        type,
        message,
      });
    };

    onCheckExportErrors();
  }, [store.permissions]);

  useEffect(() => {
    const onCheckCompanyUpdateErrors = () => {
      let type = null;
      let message = null;

      if (!store.permissions.includes('company_update')) {
        type = 'error';
        message = t('other.permission');
      }

      onChangeError('company_update', {
        type,
        message,
      });
    };

    onCheckCompanyUpdateErrors();
  }, [store.permissions]);

  const onChangeError = (target, error = {}) => {
    let newError = {};

    if (error && typeof error === 'function') {
      newError = error(errors[target]);
    } else {
      newError = error;
    }

    if ('type' in newError && 'message' in newError)
      setErrors(prevErrors => ({
        ...prevErrors,
        [target]: newError,
      }));
  };

  const requestSaveLimits = () => dispatch(getSaveLimits());

  return { errors, onChangeError, requestSaveLimits };
};
