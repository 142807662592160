import React, { memo, useState, useEffect } from 'react';

let timer;
const ProgressBar = ({ progress, progressIndicator }) => {
  const [percentage, setProgress] = useState(0);

  useEffect(() => {
    timer = setTimeout(() => setProgress(progress >= 100 ? 100 : progress), 1000);

    return () => {
      timer && clearTimeout(timer);
    };
  }, [progress]);

  return (
    <div className="progress-bar-container">
      <div className="progress-bar-wrapper">
        <div className="progress" style={{ width: `${percentage}%` }} />
      </div>

      {progressIndicator ? (
        <span className="progress-indicator">{`${progress >= 10 ? progress.toFixed(2) : progress.toFixed(1)}%`}</span>
      ) : null}
    </div>
  );
};

export default memo(ProgressBar);
