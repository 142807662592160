import React from 'react';
import classnames from 'classnames';

export default props => (
  <div className={classnames('table-above clear-fix', props.className || '')}>
    {props.noSide}

    {props.leftSide && (
      <div className="pull-left" style={{ width: props.leftSideWidth }}>
        {props.leftSide}
      </div>
    )}

    {props.rightSide && (
      <div className="pull-right" style={{ width: props.rightSideWidth }}>
        {props.rightSide}
      </div>
    )}
  </div>
);
