import React from 'react';
import { Tree, Input, Alert } from 'antd';
import { injectIntl } from '~/libs/localization';
import { number } from '~/libs/number';
import { has } from '~/libs/object';
import { Loader } from '~/components/ui';
import { ExportPreviewTable } from '~/components/tables';
import { providers } from './ExportModal';
import { Icon } from '~/components/ui/Icon/Icon';

const minSum = 300;

const ExportSearchModal = ({ renderTotal, renderTreeNodes, provider, limitLoading, limits = {}, t, ...props }) => (
  <div className="export-columns-wrapper">
    <div className="export-left-column">
      <div className="export-title">{t('company.download_list')}</div>

      <div className="modal-content">
        {!props.name && (
          <>
            <div className="export-subtitle">{t('other.name')}</div>
            <Input placeholder={t('other.nameType')} onChange={props.onChangeName} />
          </>
        )}

        <div className="export-subtitle">{t('other.fields')}</div>

        <Loader loading={props.loading} height={163}>
          <div className="export-fields">
            <Tree checkable checkedKeys={props.dataExport} onCheck={props.onChangeExport}>
              {renderTreeNodes(props.fields)}
            </Tree>
          </div>

          <div className="export-subtitle">{t('other.example')}</div>

          <ExportPreviewTable
            loading={limitLoading}
            headers={has(limits, 'preview.headers', [])}
            data={has(limits, 'preview.data', [])}
          />
        </Loader>
      </div>
    </div>

    <div className="export-right-column">
      <span className="title">{t('company.summary')}</span>

      <div className="sum-wrapper">
        <ul className="credits-list">
          {has(limits, `fields.${providers[provider].header}`) && (
            <>
              <span className="sum-title">{t(`company.export.general_info_title`)}</span>
              <span className={`sum ${limitLoading ? 'grey' : ''}`}>
                {number(limits.total[providers[provider].header]) || 0}
              </span>
            </>
          )}

          {limitLoading && Object.keys(has(limits, 'fields', {})).length === 0 ? (
            <li className="grey zh-iconed-text">
              <Icon type="loading" />
              {t('company.summary_loading')}
            </li>
          ) : null}

          {renderTotal()}
        </ul>

        {props.paymentRequired ? (
          <>
            <div className="sum-payment">
              {limits.total_price <= minSum && (
                <div className="price price-note">
                  <span>*{t('company.export.minimum_order')}</span>

                  <span>
                    {number(limits.min_total_amount_fee)} {limits.currency}
                  </span>
                </div>
              )}
              <div className="price">
                <span>{t('company.export.including_20_vat')}</span>

                <span>{limits.total_tva} MDL</span>
              </div>

              {limits.total_price <= minSum && (
                <div className="wrap-alerts">
                  <Alert message={t('company.export.min_sum_alert')} type="error" />
                  <Alert message={t('company.export.increase_amount_alert')} type="success" />
                </div>
              )}
              <div className="price">
                <span>{t('company.export.total_for_payment')}</span>

                <span>
                  {limits.total_price <= minSum ? minSum : number(limits.total_price)} {limits.currency}
                </span>
              </div>
            </div>

            {/* <div className="modal-footer-alert">
              <span>
                {t('other.addToCart')}&nbsp;
                <span className="link bold" onClick={props.onAddCart}>
                  {t('other.addOrder')}
                </span>
              </span>
            </div> */}
          </>
        ) : null}
      </div>
    </div>
  </div>
);

export default injectIntl(ExportSearchModal);
