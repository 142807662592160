// Breakpoints for React media queries
const responsive = {
  mobile: 320,
  smallTablet: 480,
  tablet: 640,
  smallDesktop: 768,
  desktop: 1024,
  wide: 1280,
  extraWide: 1366,
};

export default responsive;
