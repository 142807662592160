import React from 'react';
import { Modal } from 'antd';
import { Section, Loader } from '~/components/ui';

export default ({ footer = true, ...props }) =>
  !props.hide ? (
    <Modal
      wrapClassName={`${props.clean ? 'modal-clean' : ''} ${props.className || ''}`}
      visible
      onCancel={() => props.onClose()}
      title={props.title}
      width={props.width || '700px'}
      style={props.style}
      footer={
        footer ? (
          <>
            {props.footerLeft && (
              <div className="pull-left clear-fix modal-footer-left" key="footer-left">
                {props.footerLeft}
              </div>
            )}

            {props.footerCenter && (
              <div className="clear-fix modal-footer-center" key="footer-center">
                {props.footerCenter}
              </div>
            )}

            {props.footerRight && (
              <div className="pull-right clear-fix modal-footer-right" key="footer-right">
                {props.footerRight}
              </div>
            )}

            {footer && <div key="footer">{footer}</div>}
          </>
        ) : null
      }
    >
      {props.headerRight && <div className="modal-header-right">{props.headerRight}</div>}

      <Loader loading={props.loading}>
        <Section lazy={props.lazy}>{props.children}</Section>
      </Loader>
    </Modal>
  ) : null;
