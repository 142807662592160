import axios from '~/libs/http/axios';

const { baseURL } = axios.defaults;

const members = {
  add: (option = {}) => axios.post(`${baseURL}/api/profile/members`, option),
  delete: (params = {}) => axios.delete(`${baseURL}/api/profile/members`, { params }),
  edit: (option = {}) => axios.patch(`${baseURL}/api/profile/members`, option),
  get: (params = {}) => axios.get(`${baseURL}/api/profile/members`, { params }),
  list: (params = {}) => axios.get(`${baseURL}/api/profile/members/list`, { params }),
  companyDetails: (params = {}) => axios.patch(`${baseURL}/api/management/account/detail`, params),
  companyDetailId: (params = {}) => axios.get(`${baseURL}/api/management/account/detail`, { params }),
};

export default members;
