import React from 'react';
import { Icon } from '~/components/ui/Icon/Icon';

const PopAction = ({ onClick, loading, ...props }) => (
  <div {...(onClick !== undefined ? { onClick: () => onClick() } : null)} role="presentation" className="pop-action">
    {loading ? (
      <Icon type="loading" />
    ) : (
      <>
        {props.prefix}
        {props.icon && <Icon type={props.icon} />}
      </>
    )}

    <span>{props.value}</span>
  </div>
);

export default PopAction;
