/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { Modal, Tag, Upload, message } from 'antd';
import { useRequest } from 'estafette';
import { useInjectIntl } from '~/libs/localization';
import { monitoring } from '~/libs/http/api';
import { Icon } from '../ui/Icon/Icon';
import { Loader, Button } from '~/components/ui';
import { SearchBox } from '~/components/monitoring';

import '~/assets/scss/components/_ModalAddCounterparty.scss';

const ModalAddCounterparty = ({
  onClose,
  idnos: idnosFile,
  onSetIdnos,
  onApplyCounterparty,
  loadingAddMonitoringList,
  updateData,
  modalAction,
  listId,
  setListType,
}) => {
  const { t } = useInjectIntl();
  const [idnoList, setIdnoList] = useState([]);

  const { request: requestFileIdno, loading: loadingFileIdno, data: dataFileIdno } = useRequest();

  const [isExampleVisible, setIsExampleVisible] = useState(false);
  const [fileUpload, setFileUpload] = useState(null);

  useEffect(() => {
    if (dataFileIdno?.length) {
      onSetIdnos(dataFileIdno);
    }
  }, [dataFileIdno]);

  useEffect(() => {
    if (idnoList.length) {
      const newArr = idnoList.map(item => item.id);
      onSetIdnos(newArr);
    }
  }, [idnoList]);

  const onSubmitIdno = () => {
    setIdnoList([]);
    onApplyCounterparty();
    modalAction(true);
    updateData(listId);
    setListType('companiesFromList');
  };

  const onSetIdno = ({ id, idno }) => {
    const newObj = { idno, id };

    if (!idnoList.find(item => item.id === id)) {
      setIdnoList([...idnoList, newObj]);
    }
  };

  const onCloseTagHandle = tag => {
    // setIdnoList([]);
    // const newArr = idnoList.filter(item => item.id !== tag);
    // setIdnoList(newArr);
    setIdnoList(prevIdnoList => prevIdnoList.filter(item => item.id !== tag));
  };

  const onFadeExample = () => setIsExampleVisible(!isExampleVisible);

  const onUploadFile = file => {
    setIdnoList([]);

    const formData = new FormData();
    formData.append('file', file);

    try {
      requestFileIdno(monitoring.parseIdno(formData));
    } catch {
      message.error(t('other.error_module'));
    }
  };

  const onChangeFile = ({ file }) => {
    const isTxt = file.type === 'text/plain';
    if (!isTxt) {
      message.error(t('monitorig.errorTxt'));
    }

    const size = file.size / 1024 / 1024 < 1;

    if (!size) {
      message.error(t('monitorig.errorTxtSize'));
    }

    if (isTxt && size) {
      setFileUpload(file);
      onUploadFile(file);
    }
  };

  const onRemoveFile = () => {
    onSetIdnos([]);
    setFileUpload(null);
  };

  const handleCancel = () => {
    onClose(false);
  };

  return (
    <Modal
      title={t('monitorig.addCounterpart')}
      visible
      className="modal-add-counterparty"
      width={800}
      footer={null}
      onCancel={handleCancel}
    >
      {!fileUpload && (
        <>
          <div className="section-wrap">
            <div className="search-field-group" id="search-field-group">
              <SearchBox placeholder="other.enterYourRrequest" onSetIdno={onSetIdno} />
            </div>

            {idnoList.map(tag => (
              <Tag closable onClose={() => onCloseTagHandle(tag.id)} key={tag.id}>
                {tag.idno}
              </Tag>
            ))}
          </div>

          <div className="section-wrap filelist-wrap">
            <div className="left">
              <div className="title">{t('monitorig.addFileContractorsList')}</div>
              <div className="button-wrap">
                <Upload
                  onChange={onChangeFile}
                  listType="text"
                  showUploadList={false}
                  beforeUpload={() => {
                    return false;
                  }}
                >
                  <Button>
                    <Icon type="list-plus-modal" />
                    {t('monitorig.chooseFile')}
                  </Button>
                </Upload>
              </div>
              <div className="description">
                {t('monitorig.fileFormat')}
                <br />
                {t('monitorig.noteUploaded')}
              </div>
            </div>

            <div className="right">
              <span className={`example-button${isExampleVisible ? ' active' : ''}`} onClick={onFadeExample}>
                <Icon type="task-list-check" />
                {t('monitorig.seeExample')}
              </span>
            </div>
          </div>
        </>
      )}

      {fileUpload && (
        <div className="section-wrap filelist-wrap">
          <div className="left">
            <div className="title">{t('monitorig.contractorListFile')}</div>
          </div>

          <div className="right">
            <span className={`example-button${isExampleVisible ? ' active' : ''}`} onClick={onFadeExample}>
              <Icon type="task-list-check" />
              {t('monitorig.seeExample')}
            </span>
          </div>

          <div className="files-list">
            <ul>
              <li>
                <span className="list-counterparty-wrap">
                  <Icon type="list-counterparty" />
                </span>
                <div className="file-info">
                  <div className="name">{fileUpload.name}</div>
                  <div className="meta-info">
                    {fileUpload.size} Bytes <span className="dot">•</span>{' '}
                    <Loader.Inline loading={loadingFileIdno}>
                      {dataFileIdno?.length} {t('monitorig.numbersFound')}
                    </Loader.Inline>
                  </div>
                  <span className="remove-file" onClick={onRemoveFile}>
                    <Icon type="trash" />
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      )}

      <div className="section-wrap apply-wrap">
        <div className="left">
          <Button
            className="apply-button"
            onClick={onSubmitIdno}
            loading={loadingAddMonitoringList}
            disabled={idnoList.length === 0 && idnosFile.length === 0}
          >
            <Icon type="apply-check" />
            {t('monitorig.apply')}
          </Button>
        </div>

        <div className="right">{t('monitorig.pleaseCounterpart')}</div>
      </div>

      <div className={`section-example${isExampleVisible ? ' active' : ''}`}>
        <div className="left">
          <div className="title-1">{t('monitorig.exampleOfLoadingVisor')}</div>
          <div className="note-1">{t('monitorig.toLoadContractors')}</div>
          <div className="note-2">{t('monitorig.fileExtension')}</div>

          <div className="title-2">{t('monitorig.typesOfCodes')}</div>
          <ul className="check-list">
            <li>
              <Icon type="check-example" />
              {t('monitorig.IDNOOfLegal')};
            </li>
            <li>
              <Icon type="check-example" />
              {t('monitorig.IDNOOfIndividual')};
            </li>
            <li>
              <Icon type="check-example" />
              {t('monitorig.seriesAndNumberOfPassports')};
            </li>
          </ul>
          <div className="note-1">{t('monitorig.eachCounterpartyIsPlaced')}</div>
        </div>

        <div className="right">
          <img src="/assets/img/example-numbers.png" alt="" className="example-screen" />
        </div>
      </div>
    </Modal>
  );
};

export default ModalAddCounterparty;
