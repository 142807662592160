import { adapterIntl } from '~/libs/localization/helpers';

const alerts = adapterIntl({
  'alerts.title': {
    ro: 'Alerte listei',
    ru: 'Оповещения списка',
    en: 'List alerts',
  },
  'alerts.empty': {
    ro: 'Nu aveți nici o alertă în această categorie',
    ru: 'У вас нет уведомлений в данной категории',
    en: 'You do not have any alerts for this category',
  },
  'alerts.send_email': {
    ro: 'Trimite prin e-mail',
    ru: 'Отправить по email',
    en: 'Send via email',
  },
  'alerts.move_trash': {
    ro: 'În сoș',
    ru: 'Переместить в корзину',
    en: 'Move to trash',
  },
  'alerts.allMessages': {
    ro: 'Toate mesajele',
    ru: 'Все сообщения',
    en: 'All messages',
  },
  'alerts.unread': {
    ro: 'Necitite',
    ru: 'Непрочитанные',
    en: 'Unread',
  },
  'alerts.read': {
    ro: 'Citite',
    ru: 'Прочитанные',
    en: 'Read',
  },
  'alerts.electricity': {
    ro: 'Energie Electrică',
    ru: 'Электроэнергия',
    en: 'Electricity',
  },
  'alerts.waterAndSewage': {
    ro: 'Apă și canalizare',
    ru: 'Вода и канализация',
    en: 'Water and sewage',
  },
  'alerts.controls': {
    ro: 'Controale',
    ru: 'Проверки',
    en: 'Controls',
  },
  'alerts.alertSettings': {
    ro: 'Setări de alerte',
    ru: 'Настройки уведомлений',
    en: 'Alert settings',
  },
  'alerts.name': {
    ro: 'Denumirea',
    ru: 'Название',
    en: 'Name',
  },
  'alerts.lastEdited': {
    ro: 'Ultima modificare',
    ru: 'Последнее редактирование',
    en: 'Last edited',
  },
  'alerts.dateOfPublishing': {
    ro: 'Data publicării',
    ru: 'Дата публикации',
    en: 'Date of publishing',
  },
  'alerts.moreDetails': {
    ro: 'Mai multe detalii',
    ru: 'Подробнее',
    en: 'More details',
  },
  'alerts.new': {
    ro: 'Nou',
    ru: 'Новый',
    en: 'New',
  },
  'alerts.choosePeriod': {
    ro: 'Alege perioada',
    ru: 'Выберите период',
    en: 'Choose period',
  },
  'alerts.messages': {
    ro: 'mesaje',
    ru: 'сообщений',
    en: 'messages',
  },
  'alerts.startDate': {
    ro: 'Data de început',
    ru: 'Дата начала',
    en: 'Start date',
  },
  'alerts.endDate': {
    ro: 'Data de încheiere',
    ru: 'Дата окончания',
    en: 'End date',
  },
  'alerts.localitions': {
    ro: 'Localitățile mele',
    ru: 'Мои местоположения',
    en: 'My localities',
  },
  'alerts.addLocation': {
    ro: 'Adaugă locație',
    ru: 'Добавить местоположение',
    en: 'Add location',
  },
  'alerts.alerts': {
    ro: 'Alerte',
    ru: 'Оповещения',
    en: 'Alerts',
  },
  'alerts.monitoring': {
    ro: 'Monitorizare',
    ru: 'Мониторинг',
    en: 'Monitoring',
  },
  'alerts.legalBulletin': {
    ro: 'Buletin Juridic',
    ru: 'Юридический бюллетень',
    en: 'Legal Bulletin',
  },
  'alerts.nameOfLocality': {
    ro: 'Denumirea localităţiei',
    ru: 'Название населенного пункта',
    en: 'Name of locality',
  },
  'alerts.standardName': {
    ro: 'Nume standard (Localitatea 1)',
    ru: 'Стандартное название (Расположение 1)',
    en: 'Standard name (Location 1)',
  },
  'alerts.selectTheCity': {
    ro: 'Selectați localitatea',
    ru: 'Выберите город',
    en: 'Select the city',
  },
  'alerts.select': {
    ro: 'Selectați',
    ru: 'Выбрать',
    en: 'Select',
  },
  'alerts.address': {
    ro: 'Adresa',
    ru: 'Адрес',
    en: 'Address',
  },
  'alerts.enterYourAddress': {
    ro: 'Introduceți adresa dvs',
    ru: 'Введите ваш адрес',
    en: 'Enter your address',
  },
  'alerts.addTheAddress': {
    ro: 'Adăugați adresa',
    ru: 'Добавьте адрес',
    en: 'Add the address',
  },
  'alerts.selectTheRegion': {
    ro: 'Selectați regiunea',
    ru: 'Выберите регион',
    en: 'Select the region',
  },
  'alerts.street': {
    ro: 'Strada',
    ru: 'Улица',
    en: 'Street',
  },
  'alerts.nr': {
    ro: 'Nr',
    ru: 'Нр',
    en: 'Nr',
  },
  'alerts.emailWrong': {
    ro: 'Email greșit',
    ru: 'Неправильный Email',
    en: 'Wrong Email',
  },
  'alerts.phoneWrong': {
    ro: 'Acest număr de telefon este greșit',
    ru: 'Неправильный номер телефона',
    en: 'Wrong phone number',
  },
  'alerts.maximumPhones': {
    ro: 'Puteți adăuga maximum 1 număr de telefon',
    ru: 'Можно добавить максимум 1 номер телефона',
    en: 'You can add a maximum of 1 phone number',
  },
  'alerts.maximumEmails': {
    ro: 'Puteți adăuga maximum 3 email',
    ru: 'Можно добавить максимум 3 email',
    en: 'You can add a maximum of 3 emails',
  },
  'alerts.requiredField': {
    ro: 'Acest câmp este obligatoriu',
    ru: 'Это поле обязательно',
    en: 'This field is required',
  },
  'alerts.type': {
    ro: 'Tip',
    ru: 'Тип',
    en: 'Type',
  },
});

export default alerts;
