import React, { useEffect, useState } from 'react';
import { Menu, Tree, Input } from 'antd';
import { useRequest } from 'estafette';
import { useDispatch } from 'react-redux';
import { setIndustries, setIsRead } from '~/store/actions/legalBulletinActions';
import { useInjectIntl } from '~/libs/localization';
import { dashboard, legalBulletin } from '~/libs/http/api';
import { Link } from '~/libs/router';
import { Icon } from '~/components/ui/Icon/Icon';
import { Loader } from '~/components/ui';

const SidebarLegalBulletinMain = () => {
  const { t } = useInjectIntl();

  const dispatch = useDispatch();

  const { request: requestIndustries, data: dataIndustries } = useRequest();
  const { request: requestLastBulletins, data: dataLastBulletins, loading: loadingLastBulletins } = useRequest();

  const [dataIndustriesList, setDataIndustriesList] = useState({});
  const [checkedKeysInd, setCheckedKeysInd] = useState([]);
  const [hideNodes, setHideNodes] = useState(false);

  useEffect(() => {
    onFetchLastBulletins();
  }, []);

  const onFetchLastBulletins = () => {
    const params = {
      page: 1,
      per_page: 5,
    };
    requestLastBulletins(legalBulletin.getBulletins(params));
  };

  const onSelect = (selectedKeys, info) => console.log('selected', selectedKeys, info);

  const onCheck = checkedKeys => setCheckedKeysInd(checkedKeys);

  const onSetIsRead = newIsRead => dispatch(setIsRead(newIsRead));

  const getNodes = (arrKeys, arr, val) => {
    // eslint-disable-next-line array-callback-return
    arr.map(item => {
      if (item.children) {
        arrKeys.concat(getNodes(arrKeys, item.children, val));
      }

      if (item.title.toLowerCase().indexOf(val) > -1) {
        arrKeys.push(item.key);
      }
    });
    return arrKeys;
  };

  const filterList = (array, expandedKeys) => {
    // eslint-disable-next-line array-callback-return
    array.map(item => {
      if (expandedKeys.indexOf(item.key) > -1) {
        array.checked = true;
      }

      if (item.children) {
        filterList(item.children, expandedKeys);
      }
    });
    setDataIndustriesList(array);
  };

  const onChangeIndustries = async ({ target }) => {
    const { value } = target;

    const expandedKeys = getNodes([], dataIndustriesList, value.toLowerCase());

    filterList(dataIndustriesList, expandedKeys);

    setCheckedKeysInd(expandedKeys);

    dispatch(setIndustries(expandedKeys));

    if (value) {
      setHideNodes(true);
    } else {
      setHideNodes(false);
      setCheckedKeysInd([]);
      dispatch(setIndustries([]));
    }
  };

  useEffect(() => {
    requestIndustries(dashboard.nomenclatures());
  }, []);

  useEffect(() => {
    if (dataIndustries?.branch) {
      setDataIndustriesList(dataIndustries.branch);
    }
  }, [dataIndustries]);

  useEffect(() => {
    dispatch(setIndustries(checkedKeysInd));
  }, [checkedKeysInd]);

  return (
    <div className="wrap-sidebar-legal-bulletin">
      <div className="sidebar-legal-bulletin">
        <div className="main-title">{t('legalBulletin.currentBulletin')}</div>

        <Menu className="legal-menu" mode="inline">
          <Menu.Item>
            <Link route="LegalBulletin">
              <Icon type="bulletin-list" />
              {t('legalBulletin.integralBulletin')}
            </Link>
          </Menu.Item>

          <Menu.SubMenu
            key="sub1"
            title={
              <>
                <Icon type="bulletin-gear" />
                {t('legalBulletin.industry')}
              </>
            }
            className="industry-list"
          >
            <Menu.Item>
              <div className="inner-list">
                <Input.Group compact>
                  <Input.Search onChange={onChangeIndustries} />
                </Input.Group>

                <div className={`checks-wrap${hideNodes ? ' hide-nodes' : ''}`}>
                  <div className="zh-tree-scroll" style={{ maxHeight: 250 }}>
                    {dataIndustriesList && (
                      <Tree
                        checkable
                        onSelect={onSelect}
                        onCheck={onCheck}
                        treeData={dataIndustriesList}
                        checkedKeys={checkedKeysInd}
                        allowDrop
                      />
                    )}
                  </div>
                </div>
              </div>
            </Menu.Item>
            {/* <Menu.Item className="add-list-item">
              <Link route="HomePage" className="link-list">
                <Icon type="plus" />
                {t('monitorig.sidebar.addTheList')}
              </Link>
            </Menu.Item> */}
          </Menu.SubMenu>
        </Menu>

        <div className="previous-bulletins">
          <div className="main-title">{t('legalBulletin.previousBulletins')}</div>
          <Loader loading={loadingLastBulletins}>
            <ul className="previous-bulletins-list">
              {dataLastBulletins.data &&
                dataLastBulletins.data.map(bulletin => (
                  <li key={bulletin.id}>
                    <a href={bulletin.link} className="link-list">
                      {bulletin.headers.name_acts} • {bulletin.headers.number_acts} • {bulletin.headers.date_acts}
                      <br />
                      {bulletin.headers.date_created.slice(0, 10).replaceAll('-', '.')}
                    </a>
                  </li>
                ))}
            </ul>
          </Loader>
        </div>
      </div>

      <div className="sidebar-legal-bulletin-sub">
        <div className="main-title">
          {dataLastBulletins.total_results} {t('legalBulletin.bulletins')}
        </div>

        <Menu mode="inline" className="menu">
          <Menu.Item>
            <div className="link-list" onClick={() => onSetIsRead(null)}>
              <Icon type="bulletin-menu-all" />
              {dataLastBulletins.total_results} {t('legalBulletin.all')}
              <Icon type="bulletin-arrow-right" />
            </div>
          </Menu.Item>

          <Menu.Item>
            <div className="link-list" onClick={() => onSetIsRead(false)}>
              <Icon type="unread-list" />
              {t('legalBulletin.unread')}
              <Icon type="bulletin-arrow-right" />
            </div>
          </Menu.Item>

          <Menu.Item>
            <div className="link-list" onClick={() => onSetIsRead(true)}>
              <Icon type="read-list" />
              {t('legalBulletin.read')}
              <Icon type="bulletin-arrow-right" />
            </div>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
};

export default SidebarLegalBulletinMain;
