import React from 'react';

const LoaderBlock = ({ children, loading, height, className = '', ...props }: Object) => (
  <>
    {loading || loading === undefined ? (
      <div className={`loader-block ${className}`} style={{ height, ...props }}>
        <div className="lds-ring">
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    ) : null}

    {children ? (
      <div className="fade-in" style={{ display: loading ? 'none' : 'block' }}>
        {children}
      </div>
    ) : null}
  </>
);

export default LoaderBlock;
