import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { Select, Input, Alert, notification } from 'antd';
import { useRequest } from 'estafette';
import { useHistory, useLocation } from 'react-router-dom';
import { useDocumentTitle, useStateHandlers } from '~/hooks';
import { profile } from '~/libs/http/api';
import { parseObject, parseQuery } from '~/libs/http/url';
import { getRoute } from '~/libs/router';
import { validate } from '~/libs/object';
import { useInjectIntl } from '~/libs/localization';
import { Auth, Button, Form } from '~/components/ui';
import Header from '~/components/public/Header';

const EmailPage = ({ ...props }) => {
  const { t } = useInjectIntl();
  const { getFieldDecorator } = props.form;
  // eslint-disable-next-line i18n-text/no-en
  useDocumentTitle(t('signup.personalData'));
  const history = useHistory();
  const location = useLocation();
  const { request, loading, errors, setErrors } = useRequest();
  const { request: requestSeniority, data: dataSeniority, loading: loadingSeniority } = useRequest();

  const [state, setState] = useStateHandlers({
    firstName: '',
    lastName: '',
    phone: '',
    seniority: undefined,
    seniorities: [],
    query: {},
  });

  const [packageId, setPackageId] = useState(null);

  useEffect(() => {
    if (location.search) {
      const packageNumber = location.search.split('=').pop();

      setPackageId(Number(packageNumber));
    }
  }, [location.search]);

  useEffect(() => {
    const onSaveQuery = () => {
      const { name, ...rest } = parseQuery(location.search);
      setState({ query: { ...rest, name: decodeURI(name) } });
    };

    onSaveQuery();

    requestSeniority(profile.profileSeniority());
  }, []);

  const onSubmit = ev => {
    ev.preventDefault();

    props.form.validateFields(err => {
      if (!err) {
        const registerRequest = async () => {
          const userData = await request(
            profile.registerStep2({
              token: state.query.token,
              seniority_id: state.seniority,
              first_name: state.firstName,
              last_name: state.lastName,
              phone: state.phone,
              idno: state.query.idno,
              name: state.query.name,
              package: packageId || null,
            }),
          );

          const query = {
            phone: state.phone,
            email: userData.email,
          };
          state.query.package && (query.package = state.query.package);

          return history.push(`${getRoute('SignupSmsPage')}${parseObject(query)}`);
        };
        registerRequest();
      }
    });
  };

  const onChangeFirstName = ({ target }) => setState({ firstName: target.value });
  const onChangeLastName = ({ target }) => setState({ lastName: target.value });
  const onChangePhone = ({ target }) => setState({ phone: target.value });
  const onChangeSeniority = seniority => setState({ seniority });

  if (Object.keys(errors).length > 0) {
    Object.keys(errors).forEach(key => notification.error({ message: errors[key] }));

    setErrors({});

    // hidden for later
    // return history.push(getRoute('SignupEmailPage'));
  }

  return (
    <>
      <Header />

      <Auth.Container
        wide
        key="container"
        header={
          <div>
            <h1>{t('statics.signup')}</h1>
            <p />
          </div>
        }
      >
        <Form onSubmit={onSubmit}>
          {state.query.name ? (
            <>
              <span className="signup-title">{t('signup.selectedCompany')}:</span>
              <span className="signup-company-desc">
                {state.query.name}

                {state.query.idno ? (
                  <>
                    {state.query.name && ' - '}
                    <b className="blue">{state.query.idno}</b>
                  </>
                ) : null}
              </span>
            </>
          ) : (
            <>
              <span className="signup-title">{t('signup.companyNotFound')}</span>
              <span className="signup-desc">
                {t('signup.companyAvailableON')}{' '}
                <b className="blue">
                  {moment()
                    .endOf('month')
                    .format('DD-MM-YYYY')}
                </b>
              </span>
            </>
          )}

          {errors.detail && <Alert message={errors.detail} className="mb-20" type="warning" />}

          <Form.Half>
            <Form.Item label={t('other.first_name')} {...validate(errors, 'first_name')}>
              {getFieldDecorator('first_name', {
                initialValue: state.firstName,
                rules: [{ required: true, message: t('signup.requiredField') }],
              })(<Input placeholder={t('other.first_name_type')} type="text" onChange={onChangeFirstName} />)}
            </Form.Item>

            <Form.Item label={t('other.last_name')} {...validate(errors, 'last_name')}>
              {getFieldDecorator('last_name', {
                initialValue: state.lastName,
                rules: [{ required: true, message: t('signup.requiredField') }],
              })(<Input placeholder={t('other.last_name_type')} type="text" onChange={onChangeLastName} />)}
            </Form.Item>
          </Form.Half>

          <Form.Item label={t('other.telephone')} extra={t('signup.phoneNotification')}>
            {getFieldDecorator('phone', {
              initialValue: state.phone,
              rules: [
                { required: true, message: t('signup.requiredField') },
                {
                  pattern: new RegExp(/[+][0-9]{11}/g),
                  message: t('signup.requiredField'),
                },
              ],
            })(
              <NumberFormat
                format="+373########"
                placeholder="+373________"
                className="ant-input"
                prefix="+373"
                mask="_"
                allowEmptyFormatting
                onChange={onChangePhone}
              />,
            )}
          </Form.Item>

          <Form.Item label={t({ id: 'profile.level' })} {...validate(errors, 'seniority_id')}>
            <Select
              showSearch
              placeholder={t({ id: 'profile.selectLevel' })}
              optionFilterProp="children"
              defaultValue={state.seniority_id}
              onChange={onChangeSeniority}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {loadingSeniority && (
                <Select.Option value="none" disabled>
                  {t('signup.loading')} ...
                </Select.Option>
              )}

              {dataSeniority.map(({ id, name: label }) => (
                <Select.Option key={id} value={id}>
                  {label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item className="mt-20">
            <Button type="primary" htmlType="submit" className="btn-block" loading={loading} disabled={loading}>
              {t('signup.continue')}
            </Button>
          </Form.Item>
        </Form>
      </Auth.Container>
    </>
  );
};

export default Form.create()(EmailPage);
