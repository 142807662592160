import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Menu, Dropdown, message } from 'antd';
import { useRequest } from 'estafette';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useDocumentTitle, useEventListener } from '~/hooks';
import { useSearch } from '~/hoc/withSearch';
import { getRoute, Link } from '~/libs/router';
import { lists, exportings } from '~/libs/http/api';
import { useInjectIntl } from '~/libs/localization';
import { deleteTag, setActiveFilter, openFilter, closeFilter, changeSubFilter } from '~/store/actions/FilterActions';
import { selectSearch, selectAllSearch, resetSelectSearch } from '~/store/actions/SearchActions';
import { Layout } from '~/components/layouts';
import { Button, Loader, EmptyBlock, Text, Confirmation, Table } from '~/components/ui';
import { TableHeader } from '~/components/table';
import { SearchCompany, SearchExecutive } from '~/components/search';
import { ManageListSearch, DownloadModal } from '~/components/modal/company';
import { FeedbackModal } from '~/components/modal';
import { ExportModal } from '~/components/modal/export';
import { Filter, FilterActive, FilterActions } from '~/components/filter';
import ListDetailsSelected from '~/components/other/ListDetailsSelected';
import { Icon } from '~/components/ui/Icon/Icon';
import { Time } from '~/components/other';
import { DownloadStatus } from '~/components/profile';

let timer;

const SearchResultsPage = () => {
  const { params, url } = useRouteMatch();
  const { search: $search, loading } = useSearch();
  const { request: requestSearchtList, data: dataSearchList, loading: loadingSearchList } = useRequest({
    data: { data: [] },
  });
  const { request: requestSavedList, data: dataSavedList, loading: loadingSavedList } = useRequest({
    data: { data: [] },
  });
  const { request: requestDownloadsList, data: dataDownloadsList } = useRequest({
    data: { data: [] },
  });
  const { request: requestDeleteSearchList } = useRequest();
  const { request: requestDeleteSavedList } = useRequest();
  const { request: requestDeleteDownloadList } = useRequest();

  const { t } = useInjectIntl();
  useDocumentTitle(t('page.search'));
  const dispatch = useDispatch();
  const history = useHistory();

  const loadMoreRef = useRef(false);

  const [listDetails, setListDetails] = useState({});
  const [scenario, setScenario] = useState(false);
  const [result, setResult] = useState(false);
  const [previewAllowed, setPreviewAllowed] = useState(false);
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [selectedCount, setSelectedCount] = useState(false);
  const [feedback, setFeedback] = useState(false);
  const [activeExport, setActiveExport] = useState(null);
  const [download, setDownload] = useState(false);
  const [downloadLink, setDownloadLink] = useState(null);

  const store = useSelector(
    ({ filter, searches, authentication }) => ({
      showFilter: filter.showFilter,
      permissions: authentication.permissions,
      activeFilter: filter.activeFilter,
      filter: filter.filter,
      searches,
    }),
    shallowEqual,
  );

  useEventListener(
    'scroll',
    e => {
      clearTimeout(timer);

      loadMoreRef.current = true;

      timer = setTimeout(() => {
        const { target } = e;

        if (target.scrollTop + target.offsetHeight + target.scrollHeight * 0.3 >= target.scrollHeight) {
          onChangePage(store.searches.page + 1);

          loadMoreRef.current = false;
        }
      }, 150);
    },
    document.getElementById('table-scroll-load')
      ? document.getElementById('table-scroll-load').getElementsByClassName('ant-table-body')[0]
      : null,
  );

  useEffect(() => {
    const onGetList = async () => {
      dispatch(openFilter());
      window.scrollTo(0, 0);

      if (params.mode !== 'basic') {
        await dispatch(setActiveFilter({ list_id: params.mode }));

        const { data } = await lists.getList({ id: params.mode });
        setListDetails(data);
      }
    };

    onGetList();

    return () => {
      dispatch(closeFilter());

      if (params.mode !== 'basic') {
        dispatch(setActiveFilter({}));
        dispatch(resetSelectSearch());
      }
    };
  }, [params.mode]);

  useEffect(() => {
    const onFetch = async () => {
      if ((params.mode !== 'basic' && Object.keys(store.activeFilter).length > 0) || params.mode === 'basic') {
        $search({ type: params.type });
      }
    };

    onFetch();
  }, [params.type, params.mode, store.activeFilter]);

  useEffect(() => {
    const onChangeName = () => {
      const { newName } = listDetails;

      setName(newName);
    };

    onChangeName();
  }, [listDetails]);

  useEffect(() => {
    const onPreviewAllowed = () => setPreviewAllowed(store.permissions.includes('company_preview'));

    onPreviewAllowed();
  }, [store.permissions]);

  useEffect(() => {
    const onSelectedCount = () => setSelectedCount(store.searches.selectedRow.length);
    onSelectedCount();
  }, [store.searches]);

  const onChangePage = page => {
    if (!store.searches.loading && !store.searches.last) {
      return $search({ page });
    }

    return false;
  };

  const onSelectAll = () => {
    const { data } = store.searches;

    // TODO: can be bug -> try to select all after that filter something and select all again.
    const selectedAll = data.length > 0 && data.length === store.searches.selectedRow.length;

    if (selectedAll) {
      dispatch(resetSelectSearch());
    } else {
      dispatch(selectAllSearch());
    }
  };

  const onCloseAll = () => setResult(false);

  const onCloseScenario = () => setScenario(false);
  const onDownload = () => setScenario(true);
  const onDeleteTag = keys => dispatch(deleteTag(keys));
  const onChangeSubFilter = (...args) => dispatch(changeSubFilter(...args));
  const onClearAll = () => dispatch(resetSelectSearch());

  const onCloseManageList = () => setResult(false);
  const onSelectSearch = id => dispatch(selectSearch(id));

  const onOpenSaveList = useCallback(newType => {
    setType(newType);
    setResult(true);
  });

  const onOpenItem = (item, listType) => {
    if (listType === 'search') {
      return onOpenSearch(item);
    }

    return onOpenList(item);
  };

  const onOpenList = ({ id, type: listType }) =>
    history.push(getRoute('SearchResultsPage', { type: listType, mode: id }));

  const onOpenSearch = ({ id, filters = {} }) => {
    dispatch(setActiveFilter(filters.must || {}));

    history.push(getRoute('SearchResultsPage', { type: params.type, mode: 'basic' }));
  };

  const onSave = async (selected, listType) => {
    if (selected?.filters) {
      dispatch(setActiveFilter(selected.filters.must || {}));
    } else {
      dispatch(setActiveFilter({ list_id: selected.id }));
    }

    onOpenItem(selected, listType);
    setScenario(true);
  };

  const onFetchSearchList = () => {
    try {
      requestSearchtList(
        lists.search({
          page: 1,
          per_page: 4,
        }),
      );
    } catch {
      message.error(t('other.error_module'));
    }
  };

  const onFetchSavedList = listType => {
    try {
      requestSavedList(
        lists.list({
          page: 1,
          per_page: 4,
          type: listType,
        }),
      );
    } catch {
      message.error(t('other.error_module'));
    }
  };

  const onFetchDownloadsList = async () => {
    try {
      requestDownloadsList(exportings.getList({ page: 1, per_page: 4 }));
    } catch {
      message.error(t('other.error_module'));
    }
  };

  const onFeedbackList = id => {
    setFeedback(true);
    setActiveExport(id);
  };

  const onDownloadList = useCallback(async id => {
    const { data } = await exportings.download({ id });

    window.open(`${process.env.REACT_APP_API_URL || ''}${data.url}`);

    setDownloadLink(`${process.env.REACT_APP_API_URL || ''}${data.url}`);
  }, []);

  const onCloseFeedback = () => setFeedback(false);

  const onCloseDownload = () => {
    setDownload(false);
    setDownloadLink('');
  };

  const onDeleteSavedList = useCallback(async id => {
    await requestDeleteSavedList(lists.delete({ id }));
    onFetchSavedList(params.type);
  }, []);

  const onDeleteSearchList = useCallback(async id => {
    await requestDeleteSearchList(lists.delete({ id }));
    onFetchSearchList('search');
  }, []);

  const onDeleteDownloadList = useCallback(async id => {
    await requestDeleteDownloadList(exportings.delete({ id }));
    onFetchDownloadsList();
  }, []);

  useEffect(() => {
    onFetchSearchList('search');
  }, []);

  useEffect(() => {
    onFetchSavedList(params.type);
  }, []);

  useEffect(() => {
    onFetchDownloadsList();
  }, []);

  const columnsMyDownloads = [
    {
      title: t('searches.downloadDate'),
      className: 'column-date',
      dataIndex: 'date_started',
      render: (date, record) => (
        <div className="clearfix">
          <Time date={date || record.date_finished || record.date_created} format="date" />
        </div>
      ),
    },
    {
      title: t('searches.downloadName'),
      className: '',
      dataIndex: 'name',
      render: (_, render) => (
        <span
          dangerouslySetInnerHTML={{
            __html: String(render.name),
          }}
        />
      ),
      // render: name =>
      //   (
      //     <span
      //       className="description text-blue"
      //       dangerouslySetInnerHTML={{
      //         __html: String(name),
      //       }}
      //     />
      //   ) || <Empty />,
    },
    {
      title: t('searches.by'),
      className: '',
      dataIndex: 'by',
      render: (_, render) => render.user_name,
    },
    {
      title: t('searches.downloadStatus'),
      className: '',
      dataIndex: 'status',
      render: status => (
        <DownloadStatus status={status} />
        // <>
        //   <span className="tag tag-success">{t('other.success')}</span>
        //   <span className="tag tag-failed">{t('other.failed')}</span>
        //   <span className="tag tag-processing">{t('other.processing')}</span>
        // </>
      ),
    },
    {
      title: t('searches.action'),
      className: 'column-tools',
      dataIndex: 'tools',
      render: (_, record) => (
        <div className="links-tool">
          <Confirmation type="delete" onConfirm={() => onDeleteDownloadList(record.id)}>
            <Link>
              <Icon type="trash" />
              {t('searches.remove')}
            </Link>
          </Confirmation>

          <Link onClick={() => onDownloadList(record.id)}>
            <Icon type="download" />
            {t('searches.unload')}
          </Link>

          <Link onClick={() => onFeedbackList(record.id)}>
            <Icon type="feedback" />
            {t('searches.feedback')}
          </Link>
        </div>
      ),
    },
  ];

  const isFiltered = React.useMemo(() => Object.keys(store.activeFilter).length > 0, [store.activeFilter]);

  return (
    <>
      {store.showFilter && <Filter />}

      {scenario && (
        <ExportModal
          onClose={onCloseScenario}
          data={store.searches.selectedRow}
          provider={params.type}
          name={name ? `Export CSV ${name}` : ''}
        />
      )}

      {download && (
        <DownloadModal
          download
          title={t({ id: 'other.export' })}
          loadingText={t({ id: 'other.download.loading' })}
          finalText={t({ id: 'other.download.text' })}
          link={downloadLink}
          onClose={onCloseDownload}
        />
      )}

      {feedback && <FeedbackModal activeExport={activeExport} onClose={onCloseFeedback} />}

      <Layout
        filter
        rootClass={store.showFilter ? 'filter-open' : ''}
        type={isFiltered ? 'prospect' : ''}
        containerClass={`filter-container ${!isFiltered ? 'filter-list-container' : ''}`}
      >
        {!isFiltered && (
          <>
            <h1 className="title">{t('packages.exploration')}</h1>

            <div className="exploration-info">
              <div className="wrap-list">
                <div className="title">
                  <Icon type="search-saved-lists" />
                  {t('searches.savedLists')}
                </div>

                <Loader loading={loadingSavedList}>
                  {dataSavedList.data.length ? (
                    <>
                      <div className="saved-list">
                        <ul>
                          {dataSavedList.data.map(item => (
                            <li key={item.id}>
                              <span className="list-counterparty-wrap">
                                <Icon type="search-list-item" />
                              </span>

                              <div className="list-info">
                                <div className="name">
                                  <Link route="SearchResultsPage" params={{ type: item.type, mode: item.id }}>
                                    {item.name}
                                  </Link>

                                  <Dropdown
                                    overlay={
                                      <Menu className="saved-list-tools">
                                        <Menu.Item onClick={() => onDeleteSavedList(item.id)}>
                                          <span>
                                            <Icon type="trash" />
                                            {t('searches.remove')}
                                          </span>
                                        </Menu.Item>
                                        <Menu.Item onClick={() => onSave(item, 'list')}>
                                          <Icon type="download" />
                                          {t('searches.unload')}
                                        </Menu.Item>
                                      </Menu>
                                    }
                                    placement="bottomRight"
                                    trigger="click"
                                  >
                                    <div>
                                      <Icon type="dots" />
                                    </div>
                                  </Dropdown>
                                </div>
                                <div className="meta-info">
                                  {item.companies} {t('list.companies')} <span className="dot">•</span> Creat{' '}
                                  <Time date={item.date_created} format="long" />
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>

                      <div className="list-footer">
                        <Link route="SavedListsPage" params={{ page: 1 }}>
                          {t('searches.viewAll')}
                          <Icon type="arrow-right" />
                        </Link>
                      </div>
                    </>
                  ) : (
                    <div className="no-list">
                      <span className="wrap-loop">
                        <Icon type="searches-loop" />
                      </span>

                      <div className="sub-title">{t('searches.savedLists')}</div>
                      <div className="description">{t('searches.savedListsDescription')}</div>
                    </div>
                  )}
                </Loader>
              </div>

              <div className="wrap-list">
                <div className="title">
                  <Icon type="search-my-searches" />
                  {t('searches.mySearches')}
                </div>

                <Loader loading={loadingSearchList}>
                  {dataSearchList.data.length ? (
                    <>
                      <div className="saved-list">
                        <ul>
                          {dataSearchList.data.map(item => (
                            <li key={item.id}>
                              <span className="list-counterparty-wrap">
                                <Icon type="search-list-item" />
                              </span>

                              <div className="list-info">
                                <div className="name">
                                  <Link onClick={() => onOpenItem(item, 'search')}>{item.name}</Link>

                                  <Dropdown
                                    overlay={
                                      <Menu className="saved-list-tools">
                                        <Menu.Item onClick={() => onDeleteSearchList(item.id)}>
                                          <span>
                                            <Icon type="trash" />
                                            {t('searches.remove')}
                                          </span>
                                        </Menu.Item>
                                        <Menu.Item onClick={() => onSave(item, 'search')}>
                                          <Icon type="download" />
                                          {t('searches.unload')}
                                        </Menu.Item>
                                      </Menu>
                                    }
                                    placement="bottomRight"
                                    trigger="click"
                                  >
                                    <div>
                                      <Icon type="dots" />
                                    </div>
                                  </Dropdown>
                                </div>
                                <div className="meta-info">
                                  {item.companies_count} {t('list.companies')} <span className="dot">•</span> Creat{' '}
                                  <Time date={item.date_created} format="long" />
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>

                      <div className="list-footer">
                        <Link route="SavedSearchesPage" params={{ page: 1 }}>
                          {t('searches.viewAll')}
                          <Icon type="arrow-right" />
                        </Link>
                      </div>
                    </>
                  ) : (
                    <div className="no-list">
                      <span className="wrap-loop">
                        <Icon type="searches-loop" />
                      </span>

                      <div className="sub-title">{t('searches.mySearches')}</div>
                      <div className="description">{t('searches.searchesListsDescription')}</div>
                    </div>
                  )}
                </Loader>
              </div>
            </div>

            <div className="exploration-info">
              <div className="wrap-list mr-0">
                <div className="title">
                  <Icon type="my-downloads" />
                  {t('searches.myDownloads')}
                </div>

                <div className="saved-list">
                  <Table
                    rowKey="id"
                    className="table-no-header basic"
                    fixed
                    columns={columnsMyDownloads}
                    dataSource={dataDownloadsList.data}
                  />
                </div>

                <div className="list-footer">
                  <Link route="DownloadsPage" params={{ type: 'list', page: 1 }}>
                    {t('searches.viewAll')}
                    <Icon type="arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
          </>
        )}

        {isFiltered && (
          <Loader loading={store.searches.data.length === 0 && !store.searches.empty}>
            {result && (
              <ManageListSearch
                type={type}
                data={store.searches.selectedRow}
                onClose={onCloseManageList}
                onCloseAll={onCloseAll}
              />
            )}

            <TableHeader
              className="zh-search-header"
              noSide={
                !selectedCount ? (
                  <FilterActive listDetails={listDetails} deleteTag={onDeleteTag} changeSubFilter={onChangeSubFilter} />
                ) : (
                  <ListDetailsSelected
                    counter={selectedCount}
                    // onSaveList={() => this.setState({ result: true })}
                    // onDownload={() => this.setState({ scenario: true })}
                    // onInvertSelections={() => this.props.invertSelections()}
                    onClearAll={onClearAll}
                  />
                )
              }
              rightSide={
                <div className="filter-header-button">
                  <FilterActions
                    checking={store.permissions}
                    type={params.type}
                    onSaveSearch={() => onOpenSaveList('search')}
                    onSaveList={() => onOpenSaveList(params.type)}
                    onDownload={onDownload}
                  >
                    <span>{t('other.actions')}</span>
                  </FilterActions>
                </div>
              }
            />
            {store.searches.empty && <EmptyBlock title={<Text id="searcher.empty" />} />}

            <div className="fade-in" style={{ display: !store.searches.data.length ? 'none' : 'block' }}>
              {url.match(getRoute('SearchResultsPage', { ...params, type: 'company' })) ||
              url.match(getRoute('SearchResultsPage', { ...params, type: 'subdivision' })) ? (
                <div className="table-filter-search-wrapper">
                  <SearchCompany
                    allowed={previewAllowed}
                    companies={store.searches.data}
                    onSelectAll={onSelectAll}
                    selectSearch={onSelectSearch}
                    selectedRow={store.searches.selectedRow}
                    loading={loading}
                    id="table-scroll-load"
                  />

                  {store.searches.data.length !== 0 && !store.searches.empty && (
                    <div className="view-more">
                      <Button
                        aqua
                        onClick={() => {
                          onChangePage(store.searches.page + 1);
                        }}
                        disabled={store.searches.last}
                      >
                        {t('filter.button.treePopupMore')}
                      </Button>
                    </div>
                  )}
                </div>
              ) : null}

              {url.match(getRoute('SearchResultsPage', { ...params, type: 'employee' })) && (
                <SearchExecutive
                  allowed={previewAllowed}
                  employee={store.searches.data}
                  onSelectAll={onSelectAll}
                  selectSearch={onSelectSearch}
                  selectedRow={store.searches.selectedRow}
                  loading={loading}
                  id="table-scroll-load"
                />
              )}
            </div>
          </Loader>
        )}
      </Layout>
    </>
  );
};

export default SearchResultsPage;
