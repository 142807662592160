import axios from '~/libs/http/axios';
import { setCancelToken } from '~/libs/http/cancellation';

const { baseURL } = axios.defaults;

const legalBulletin = {
  getBulletins: (params = {}) => axios.get(`${baseURL}/api/rocket/juridical/`, { params }),
  addToUserList: (params = {}) => axios.post(`${baseURL}/api/rocket/juridical/user-list/bulk/`, params),
  getUserList: (params = {}) => axios.get(`${baseURL}/api/rocket/juridical/user-list/`, params),
  deleteFromUserList: (params = {}) => axios.delete(`${baseURL}/api/rocket/juridical/user-list/bulk/`, { params }),
  getFolderList: (params = {}) => axios.get(`${baseURL}/api/rocket/acts/types/`, { params }),
  addFolder: (params = {}) => axios.post(`${baseURL}/api/rocket/acts/types/`, params),
  editFolder: (params = {}) => axios.patch(`${baseURL}/api/rocket/acts/types/${params.id}/`, { title: params.title }),
  deleteFolder: (params = {}) => axios.delete(`${baseURL}/api/rocket/acts/types/${params.id}/`),
  getActList: (params = {}) => axios.get(`${baseURL}/api/rocket/acts/`, { params }),
  filterActItemList: (params = {}) => axios.get(`${baseURL}/api/rocket/acts/`, { params }),
  addActItem: (params = {}) => axios.post(`${baseURL}/api/rocket/acts/`, params),
  editActItem: (params = {}) =>
    axios.patch(`${baseURL}/api/rocket/acts/${params.id}/`, { title: params.title, link: params.link }),
  deleteActItem: (params = {}) => axios.delete(`${baseURL}/api/rocket/acts/delete/`, { params }),

  filterUserList: (params = {}) =>
    axios.get(`${baseURL}/api/rocket/juridical/user-list/`, {
      params,
      ...setCancelToken('legalBulletin.filterUserList'),
    }),

  // getCompanies: (params = {}) => axios.get(`${baseURL}/api/monitoring/companies/`, { params }),
  // getNotifications: (params = {}) => axios.get(`${baseURL}/api/monitoring/notifications/users/lists/`, { params }),
  // getCompany: (params = {}) => axios.get(`${baseURL}/api/monitoring/company`, { params }),
  // getTypes: (params = {}) => axios.get(`${baseURL}/api/monitoring/event-types/`, { params }),
  // add: (params = {}) => axios.post(`${baseURL}/api/monitoring/`, params),
};

export default legalBulletin;
