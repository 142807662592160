import { adapterIntl } from '~/libs/localization/helpers';

const updates = adapterIntl({
  'updates.title': {
    ro: 'Actualizări',
    ru: 'Обновления',
    en: 'Updates',
  },
});

export default updates;
