import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Button, Rate } from 'antd';
import { useRequest } from 'estafette';
import { has } from '~/libs/object';
import { getRoute } from '~/libs/router';
import { feedback } from '~/libs/http/api';
import { useInjectIntl } from '~/libs/localization';
import { Drawer, Time } from '~/components/other';
import { ExportModal } from '~/components/modal/export';
import { Loader, Empty, Confirmation } from '~/components/ui';
import { setActiveFilter } from '~/store/actions/FilterActions';
import NotFoundPage from '~/pages/NotFoundPage';

const FeedbackDrawer = ({ id, onClose, onDelete, ...props }) => {
  const history = useHistory();
  const { t } = useInjectIntl();
  const dispatch = useDispatch();
  const store = useSelector(({ filter }) => ({ employeesKeys: filter.employeesKeys }), shallowEqual);
  const { request, data, loading } = useRequest();
  const [scenario, setScenario] = useState(false);

  useEffect(() => {
    request(feedback.details({ id }));
  }, [id]);

  const classLoading = loading ? 'skeleton skeleton-text' : '';

  const onOpenItem = () => {
    if (data.content_type === 'lists' && data.type !== 'search') {
      return onOpenList();
    }

    return onOpenSearch();
  };

  const onOpenList = () => {
    history.push(getRoute('SearchResultsPage', { type: data.type, mode: data.object_id }));
  };

  const onOpenSearch = () => {
    dispatch(setActiveFilter(data.filters.must || {}));
    history.push(getRoute('SearchResultsPage', { type: 'company', mode: 'basic' }));
  };

  const onExport = () => {
    if (data.content_type === 'lists' && data.type === 'search') {
      dispatch(setActiveFilter(data.filters.must || {}));
    } else {
      dispatch(setActiveFilter({ list_id: data.object_id }));
    }
    setScenario(true);
  };

  const onCloseScenario = () => {
    setScenario(false);
    dispatch(setActiveFilter({}));
  };

  return data ? (
    <Drawer onClose={onClose} placement="right" width={720}>
      {scenario && (
        <ExportModal
          onClose={onCloseScenario}
          provider={
            data.type ||
            Object.keys(has(data, 'filters.must', {})).filter(filter => store.employeesKeys.includes(filter)).length > 0
              ? 'employee'
              : 'company'
          }
          name={data.name || ''}
        />
      )}

      <Loader loading={loading}>
        <div className="drawer-form">
          <div className="form-part">
            <div className="title">{t('juridical.infoGen')}</div>

            <div className="form-info">
              <div>
                <span className="info-label">{t('other.date')}</span>
                <span className="info-desc">
                  {data.date_created ? (
                    <Time date={data.date_created} format="YYYY-MM-DD HH:mm" loading={loading} prefix />
                  ) : (
                    <Empty />
                  )}
                </span>
              </div>
              <div>
                <span className="info-label">{t('other.rating')}</span>
                <span className="info-desc">
                  {data.rating ? <Rate disabled defaultValue={data.rating} /> : <Empty />}
                </span>
              </div>
            </div>
          </div>
          <div className="form-part">
            <div
              className={`title mb-0 ${classLoading}`}
              onClick={onOpenItem}
              dangerouslySetInnerHTML={{
                __html: String(data.name || <Empty />),
              }}
            />

            <div className={`form-message ${classLoading}`}>{data.text || <Empty />}</div>
          </div>

          <div className="form-footer">
            <Confirmation type="delete" onConfirm={() => onDelete(data.id)}>
              <Button type="danger" loading={loading}>
                {t('other.delete')}
              </Button>
            </Confirmation>

            {data.content_type === 'exports' ? (
              <a href={data.link} target="_blank" rel="noopener noreferrer">
                <Button type="primary" disabled={loading}>
                  <span>{t('other.download')}</span>
                </Button>
              </a>
            ) : (
              <Button type="primary" onClick={() => onExport()} loading={loading}>
                {t('other.toExport')}
              </Button>
            )}
          </div>
        </div>
      </Loader>
    </Drawer>
  ) : (
    <NotFoundPage />
  );
};

export default FeedbackDrawer;
