import axios from '~/libs/http/axios';

const { baseURL } = axios.defaults;

const auth = {
  signin: (option = {}) => axios.post(`${baseURL}/api/auth`, option),
  resetPassword: (option = {}) => axios.post(`${baseURL}/api/auth/reset`, option),
  confirmResetPassword: (option = {}) => axios.post(`${baseURL}/api/auth/reset/confirm`, option),
  logout: (option = {}) => axios.post(`${baseURL}/api/logout`, option),
};

export default auth;
