import { adapterIntl } from '~/libs/localization/helpers';

const search = adapterIntl({
  'search.theLargestDatabase': {
    ro: 'Ajutăm companiile din Republica Moldova<br />să își crească sustenabil afacerea și să-și reducă riscurile.',
    ru: 'Помогаем компаниям Республики Молдовы<br />маштабировать собственный бизнес и минимизировать возможные риски.',
    en: "We help Republic of Moldova's companies<br />upscale their business and minimize potential risks.",
  },
  'search.searchFrom': {
    ro: 'Caută din',
    ru: 'Искать среди',
    en: 'Search from',
  },
  'search.searchInstruction': {
    ro: 'IDNO:',
    ru: 'IDNO:',
    en: 'IDNO:',
  },
  'search.searchInstruction_1': {
    ro: 'Numele fondatorului sau administratorului:',
    ru: 'Имя основателя или администратора:',
    en: 'Founder or administrator name:',
  },
  'search.searchInstruction_2': {
    ro: 'Denumirea companiei:',
    ru: 'Название компании:',
    en: 'Company name:',
  },
  'search.searchInstruction_3': {
    ro: 'Adresa juridică:',
    ru: 'Юрилический адресс:',
    en: 'Legal Address:',
  },
  'search.searchInstruction_4': {
    ro: 'Număr de telefon:',
    ru: 'Номер телефона:',
    en: 'Phone number:',
  },
  'search.searchInstruction_5': {
    ro: 'Căutarea include și date istorice',
    ru: 'Поиск включает и исторические данные о компаниях',
    en: 'The search also includes historical data about companies',
  },
  'search.ofCompanies': {
    ro: 'de companii',
    ru: 'компаний',
    en: 'of companies',
  },
  'search.search': {
    ro: 'Căutare',
    ru: 'Поиск',
    en: 'Search',
  },
  'search.companySearch': {
    ro: 'Caută în companii',
    ru: 'Искать среди компаний',
    en: 'Search company',
  },
  'search.companyEmployee': {
    ro: 'Caută în administratori',
    ru: 'Искать среди администраторов',
    en: 'Search in company shareholders',
  },
  'search.add': {
    ro: 'Adăuga',
    ru: 'Добавить',
    en: 'Add',
  },
  'search.popularSearches': {
    ro: 'Populare: Agricultură şi silvicultură, Fabricarea produselor alimentare, Articole din tutun.',
    ru: 'Популярные: Сельское и лесное хозяйство, Производство продуктов питания, Табачные изделия.',
    en: 'Popular: Agriculture and forestry, Food manufacturing, Tobacco goods.',
  },
  'search.useSearch': {
    ro: 'Pentru a iniția o căutare, utilizați unul din următoarele tipuri de cuvinte-cheie:',
    ru: 'Для начала поиска, используйте один из указанных типов ключевых слов:',
    en: 'To start a search, use one of the following types of keywords:',
  },
  'search.searchFunctions': {
    ro: 'Funcții de căutare în registrele țărilor:',
    ru: 'Функции поиска в реестрах стран:',
    en: 'Search functions in country registers:',
  },
  'search.note': {
    ro: 'Notă!',
    ru: 'Заметка!',
    en: 'Note!',
  },
  'search.searchIsOnly': {
    ro: 'Căutarea este disponibilă numai în limba română sau rusă.',
    ru: 'Поиск доступен только на румынском или русском языках.',
    en: 'The search is only available in Romanian or Russian.',
  },
  'search.companies': {
    ro: 'companii',
    ru: 'компании',
    en: 'companies',
  },
  'search.employees': {
    ro: 'administratori',
    ru: 'администраторов',
    en: 'admins',
  },
  'search.alreadyMonitoring': {
    ro: 'Deja monitorizez!',
    ru: 'Уже отслеживается!',
    en: 'Already monitoring!',
  },
  'search.address': {
    ro: 'Adresă',
    ru: 'Адрес',
    en: 'Address',
  },
  'search.registrationNumber': {
    ro: 'Numar de înregistrare',
    ru: 'Регистрационный номер',
    en: 'Registration number',
  },
  'search.status': {
    ro: 'Status',
    ru: 'Статус',
    en: 'Status',
  },
  'search.active': {
    ro: 'Activă',
    ru: 'Активный',
    en: 'Active',
  },
  'search.inactive': {
    ro: 'Inactiv',
    ru: 'Неактивен',
    en: 'Inactive',
  },
  'search.removed': {
    ro: 'Șters',
    ru: 'Удалено',
    en: 'Removed',
  },
  'search.deleted': {
    ro: 'Șters',
    ru: 'Удалено',
    en: 'Removed',
  },
  'search.dateOfEstablishment': {
    ro: 'Data înfiinţării',
    ru: 'Дата создания',
    en: 'Date of establishment',
  },
  'search.age': {
    ro: 'Vărsta (ani)',
    ru: 'Возраст (лет)',
    en: 'Age (years)',
  },
  'search.nrByTheEmployees': {
    ro: 'Nr. de angajați',
    ru: '№ сотрудниками',
    en: 'Nr. by the employees',
  },
  'search.turnover': {
    ro: 'Cifra da afaceri',
    ru: 'Оборот',
    en: 'Turnover',
  },
  'search.industry': {
    ro: 'Industria',
    ru: 'Промышленность',
    en: 'Industry',
  },
  'search.contacts': {
    ro: 'Contacte',
    ru: 'Контакты',
    en: 'Contacts',
  },
  'search.phone': {
    ro: 'Telefon',
    ru: 'Телефон',
    en: 'Phone',
  },
  'search.foundersAndDelegates': {
    ro: 'Fondatori și personae delegate',
    ru: 'Учредители и делегаты',
    en: 'Founders and delegates',
  },
  'search.others': {
    ro: 'alții',
    ru: 'других',
    en: 'others',
  },
  'search.monitoring': {
    ro: 'Monitorizează',
    ru: 'Отслеживать',
    en: 'Monitoring',
  },
});

export default search;
