import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDocumentTitle } from '~/hooks';
import { parseQuery } from '~/libs/http/url';
import { getRoute } from '~/libs/router';
import { useInjectIntl } from '~/libs/localization';
import { PaymentData } from '~/components/cart/payment';

const SubscriptionPage = () => {
  const { t } = useInjectIntl();
  useDocumentTitle(t('page.signIn'));
  const history = useHistory();
  const location = useLocation();
  const query = parseQuery(location.search);

  const onSuccess = () => history.push(getRoute('MetricsPage'));

  return (
    <>
      <PaymentData
        style={{ margin: '30px auto' }}
        id={query.package}
        parent="applications"
        redirect="MetricsPage"
        onSuccess={onSuccess}
      />
    </>
  );
};

export default SubscriptionPage;
