import React, { useState } from 'react';
import { Modal, Input as AntdInput, Alert } from 'antd';
import { useRequest } from 'estafette';
import { legalBulletin } from '~/libs/http/api';
import { useInjectIntl } from '~/libs/localization';
import { Button } from '~/components/ui';

const https = 'https://';

export const LegalBulletinModalEditAct = ({ onRefetch, handleCancel, type, actEditValue }) => {
  const { t } = useInjectIntl();
  const { request, loading, errors } = useRequest();

  const [actName, setActName] = useState('');
  const [actLink, setActLink] = useState('');

  const onSubmit = async () => {
    if (type.length === 1) {
      const id = { ...type };

      const editItem = {
        title: actName || actEditValue.title,
        link: actLink ? `${https}${actLink.replace(https, '')}` : actEditValue.link,
        id: id[0],
      };

      await request(legalBulletin.editActItem(editItem));

      handleCancel();
      onRefetch();
    }
  };

  const onChangeActName = ({ target }) => setActName(target.value);
  const onChangeActLink = ({ target }) => setActLink(target.value);

  return (
    <Modal
      title={t('legalBulletin.editActItem')}
      width="700px"
      visible
      onCancel={handleCancel}
      footer={
        <>
          <Button type="primary" onClick={onSubmit} loading={loading}>
            {t('legalBulletin.apply')}
          </Button>

          <Button onClick={handleCancel}>{t('legalBulletin.cancel')}</Button>
        </>
      }
      className="normative-act-modal"
    >
      {errors.detail || errors.non_field_errors ? (
        <Alert message={errors.detail || errors.non_field_errors} className="mb-20" type="warning" />
      ) : null}
      <div className="normative-act-form">
        <label>
          <div>{t('legalBulletin.nameOfTheNormativeAct')}</div>
          <AntdInput
            onChange={onChangeActName}
            defaultValue={!actEditValue ? t('legalBulletin.lawNoRegarding') : actEditValue.title}
          />
          {errors.title ? <span className="modal-error-messages">{errors.title}</span> : null}
        </label>

        <label>
          <div>{t('legalBulletin.linkToTheAct')}</div>
          <AntdInput defaultValue={!actEditValue ? 'www.legis.md/...' : actEditValue.link} onChange={onChangeActLink} />
          {errors.link ? <span className="modal-error-messages">{errors.link}</span> : null}
        </label>
      </div>
    </Modal>
  );
};
