import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import NotAllowedPage from '~/pages/NotAllowedPage';

export const PermissionChecker = ({ children, permission }) => {
  const store = useSelector(
    ({ authentication }) => ({
      authenticationParams: authentication.params,
    }),
    shallowEqual,
  );

  const linkAccess = React.useMemo(
    () =>
      store.authenticationParams.permissions_group
        ? store.authenticationParams.permissions_group.map(item => item.codename)
        : [],
    [store.authenticationParams.permissions_group],
  );

  const secondAccess = React.useMemo(
    () =>
      store.authenticationParams.permissions_group
        ? store.authenticationParams.permissions_group.map(item => item.function)
        : [],
    [store.authenticationParams.permissions_group],
  );

  const linkPerms = linkAccess.flat();

  linkPerms.push('dashboard');

  const allPerms = secondAccess.flat().concat(linkPerms);

  if (store.authenticationParams.is_superuser) {
    allPerms.push('managment');
  }

  if (!allPerms.includes(permission)) {
    return <NotAllowedPage />;
  }

  return children;
};
