import React, { useEffect } from 'react';
// import { Button } from 'antd';
import { useRequest } from 'estafette';
import { useSelector, shallowEqual } from 'react-redux';
import { useInjectIntl } from '~/libs/localization';
// import { Icon } from '~/components/ui/Icon/Icon';
import { subscription } from '~/libs/http/api';
import { Loader } from '~/components/ui';

const PackageInfo = ({ onChangePackage }) => {
  const { t } = useInjectIntl();
  const store = useSelector(
    ({ authentication }) => ({
      product: authentication.product,
    }),
    shallowEqual,
  );

  const { request: requestMetrics, data: dataMetrics, loading: loadingMetrics } = useRequest({
    loading: true,
  });

  const dateFormat = dateCreated => {
    const d = new Date(dateCreated);
    const fullDate = new Intl.DateTimeFormat({
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    }).format(d);
    return fullDate;
  };

  useEffect(() => {
    const getMetrics = () => requestMetrics(subscription.metrics());
    getMetrics();
  }, []);

  return (
    <Loader loading={loadingMetrics}>
      <div className="package-info">
        <div className="title">
          {t('profile.myPackage')}
          {/* to do: billing */}
          {/* <Button className="edit-package-ntn" onClick={() => onChangePackage(true)}>
            <Icon type="pencil" />
            {t('packages.edit')}
          </Button> */}
        </div>

        <div className="rows">
          <div>
            <div className="sub-title">{t('profile.packageName')}</div>
            <div className="info">{store.product.title}</div>
          </div>

          <div>
            <div className="sub-title">{t('profile.packagePrice')}</div>
            <div className="info">
              {store.product.price} <small>MDL / {t(`packages.${store.product.frequency}`)}</small>
            </div>
          </div>

          <div>
            <div className="sub-title">{t('profile.frequency')}</div>
            <div className="info">
              {t(`profile.${store.product.frequency}ly`)}{' '}
              <small>
                {t('profile.From')} {dateFormat(store.product.date_created)}
              </small>
            </div>
          </div>

          <div>
            <div className="sub-title">{t('profile.billingDate')}</div>
            <div className="info">{dateFormat(store.product.next_requiring)}</div>
          </div>
        </div>

        <div className="title">{t('profile.metric')}</div>

        <div className="rows metrics-rows">
          {dataMetrics &&
            dataMetrics.map(item => (
              <div key={item.name}>
                <div className="sub-title">
                  {t('profile.available')} <strong>{item.limit.used}</strong> {t('profile.from')}{' '}
                  <strong>{item.limit.total}</strong>
                </div>

                <div className="line">
                  <span style={{ width: `${item.limit.used}%` }} />
                </div>

                <div className="info">{item.name}</div>
              </div>
            ))}
        </div>
      </div>
    </Loader>
  );
};

export default PackageInfo;
