import axios from '~/libs/http/axios';

const { baseURL } = axios.defaults;

const monitoring = {
  getList: (params = {}) => axios.get(`${baseURL}/api/monitoring/list/`, { params }),
  getUsersLists: (params = {}) => axios.get(`${baseURL}/api/monitoring/users/lists/`, { params }),
  getListInfo: (params = {}) => axios.get(`${baseURL}/api/monitoring/`, { params }),
  getCompanyNotifications: (params = {}) =>
    axios.get(`${baseURL}/api/monitoring/notifications/users/lists/detail/`, { params }),
  addList: (params = {}) => axios.post(`${baseURL}/api/monitoring/`, params),
  editList: (params = {}) => axios.patch(`${baseURL}/api/monitoring/`, params),
  getCompanies: (params = {}) => axios.get(`${baseURL}/api/monitoring/companies/`, { params }),
  getNotifications: (params = {}) => axios.get(`${baseURL}/api/monitoring/notifications/users/lists/`, { params }),
  getCompany: (params = {}) => axios.get(`${baseURL}/api/monitoring/company`, { params }),
  getTypes: (params = {}) => axios.get(`${baseURL}/api/monitoring/event-types/`, { params }),
  parseIdno: (params = {}) => axios.post(`${baseURL}/api/monitoring/company/parse-file/`, params),
  deleteFromList: (params = {}) => axios.delete(`${baseURL}/api/monitoring/remove/`, { params }),
  deleteMonitoringList: (params = {}) => axios.delete(`${baseURL}/api/monitoring/`, { params }),
  getHistory: (params = {}) => axios.get(`${baseURL}/api/companies/history/employees`, { params }),
};

export default monitoring;
