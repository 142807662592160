import { dashboard } from '~/libs/http/api';

export const getNomenclatures = () => async dispatch => {
  try {
    const { data } = await dashboard.nomenclatures();

    dispatch({
      type: 'GET_NOMENCLATURES',
      payload: data,
    });
  } catch (error) {
    getNomenclatures();
  }
};

export const getRecents = () => async dispatch => {
  const { data } = await dashboard.recents();

  dispatch({
    type: 'GET_RECENTS',
    payload: data,
  });
};

export function changeCurrentFilter(payload) {
  return {
    type: 'CHANGE_FILTER',
    payload,
  };
}

export function changeSubFilter(key, name) {
  return {
    type: 'CHANGE_SUB_FILTER',
    payload: { key, name },
  };
}

export const openFilter = amount => ({
  type: 'OPEN_FILTER',
  payload: amount,
});

export const closeFilter = () => {
  return {
    type: 'CLOSE_FILTER',
  };
};

export const closeFilterInner = () => {
  return {
    type: 'CLOSE_FILTER_INNER',
  };
};

export const openFilterInner = () => {
  return {
    type: 'OPEN_FILTER_INNER',
  };
};

export function deleteTag(key) {
  return {
    type: 'DELETE_TAG',
    payload: key,
  };
}

export function setCompanyName(payload) {
  return {
    type: 'SET_COMPANY_NAME',
    payload,
  };
}

export function setActiveFilter(payload) {
  return {
    type: 'SET_ACTIVE_FILTER',
    payload,
  };
}

export function onChangeFilter(type, name, value, key, switchValue) {
  return {
    type: `MUTATE_FILTER_${type.replace('-', '_').toUpperCase()}`,
    payload: {
      name,
      value,
      key,
      companyHasCriterion: switchValue,
    },
  };
}

export const onSelectProspect = (selectedKeys, selected) => ({
  type: 'ON_SELECT_PROSPECT',
  payload: {
    selectedKeys,
    selected,
  },
});
